import { FC } from 'react';

import Button from '@components/core/inputs/Button';
import Modal from '@components/Modal';

import style from './DeleteSkillModal.module.scss';

interface IDeleteItemModal {
    isOpen: boolean,
    onClose: () => void,
    onDelete: () => void,
    isLoading: boolean,
    itemName?: string
}

const DeleteSkillModal: FC<IDeleteItemModal> = ({ isOpen, onClose, isLoading, onDelete }) => {

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title="Удалить компетенцию?"
        >
            <p className={style.modal__text}>
                Данное действие нелья будет отменить
            </p>
            <div className={style.button_wrapper}>
                <Button
                    onClick={onClose}
                    btnType={'secondary'}
                >
                    Отмена
                </Button>
                <Button
                    onClick={onDelete}
                    btnType={'danger'}
                    disabled={isLoading}
                    typeIcon={isLoading ? 'loading' : undefined}
                >
                    Удалить
                </Button>
            </div>
        </Modal>
    );
};

export default DeleteSkillModal;
