import React, { LegacyRef, useEffect } from 'react';

import cx from 'classnames';

import Checkbox from '@components/core/inputs/Checkbox';
import { MultiSelectProps, SingleSelectProps } from '@components/core/inputs/Select/NewSelect/NewSelect';
import Loader from '@components/core/Loader/Loader';
import useOnScreen from '@hooks/useOnScreen';
import { selectInfiniteScrollSelectByName } from '@store/features/infiniteSelectScrollSlice';
import { useAppSelector } from '@store/hooks';

import styles from './OptionsList.module.scss';


interface OptionsListBaseProps {
    search: string;
    isInfinite?: boolean;
    measureRef?: React.LegacyRef<HTMLDivElement>;
    className?: string;
    isLoading?: boolean;
    selectName?: string;
    loadMore?: () => void;
}

type OptionsListProps =
    Pick<SingleSelectProps, 'value' | 'onChange' | 'options' | 'isMulti'>
    | Pick<MultiSelectProps, 'value' | 'onChange' | 'options' | 'isMulti'>;

export const OptionsList: React.FC<OptionsListBaseProps & OptionsListProps> = ({
    options,
    value,
    onChange,
    isMulti,
    search,
    className = '',
    selectName = '',
    loadMore
}) => {
    const { measureRef, isIntersecting, observer } = useOnScreen();
    const { isInfiniteScroll, hasMore, isLoading } = useAppSelector(state => selectInfiniteScrollSelectByName(state, selectName));

    const renderLabel = (label: string): React.ReactNode => {
        const lowerCaseLabel = label.toLowerCase();
        const lowerCaseSearchValue = search.toLowerCase();

        const matchPosition = lowerCaseLabel.search(lowerCaseSearchValue);

        if (matchPosition === -1) {
            return <span className={styles.searchable}>{label}</span>;
        }

        const beforeString = label.slice(0, matchPosition);
        const markString = label.slice(matchPosition, matchPosition + search.length);
        const afterString = label.slice(matchPosition + search.length);

        return (
            <span className={styles.searchable}>
                {beforeString}
                <mark>{markString}</mark>
                {afterString}
            </span>
        );
    };

    const getSelectedStatus = (target: string) => {
        if (isMulti) {
            return value.includes(target);
        }
        return value === target;
    };

    const handleChange = (target: string) => {
        if (isMulti) {
            if (value.includes(target)) {
                onChange(value.filter(el => el !== target));
            } else {
                onChange([...value, target]);
            }
        } else {
            onChange(target);
        }
    };

    useEffect(() => {
        if (isInfiniteScroll && isIntersecting && hasMore) {
            if (loadMore) {  
                loadMore();
            }

            observer?.disconnect();
        }
    }, [isIntersecting, loadMore, hasMore]);

    return (
        <div className={cx(styles.option_list_wrapper, className)}>
            {options.length ? options.map((option, index) => (
                <div
                    key={option.value}
                    className={cx(
                        styles.root,
                        styles['option--hover'],
                        {
                            [styles.active]: !isMulti && getSelectedStatus(option.value)
                        }
                    )}
                    onClick={() => handleChange(option.value)}
                    ref={(isInfiniteScroll && (index === options.length - 1) ? measureRef as LegacyRef<HTMLDivElement> : null)}
                >
                    {isMulti ? (
                        <span onClick={event => event.preventDefault()}>
                            <Checkbox checked={getSelectedStatus(option.value)} label={renderLabel(option.label)} containerClassName={styles.checkbox} />
                        </span>
                    ) : renderLabel(option.label)}
                </div>
            )) : (
                <div className={styles.empty} ref={measureRef as LegacyRef<HTMLDivElement>}>
                    Список пуст
                </div>
            )}
            {isInfiniteScroll && isLoading && <Loader />}
        </div>
    );
};
