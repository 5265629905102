import { FC } from 'react';

import { checkRole } from '@components/RoleCheck';
import { LectureBlocksLecture } from '@store/api/apiTypes';
import { Role } from '@store/Roles';
import style from '@views/student/LecturesPreview/LecturesSidebar/LecturesSidebar.module.scss';

import LecturePanelItems from './LecturePanelItems';


interface ILecturePanel {
    lectures: LectureBlocksLecture[];
}

const LecturePanel: FC<ILecturePanel> = ({ lectures }) => {

    return (
        <ul className={style.lecture__list}>
            {lectures.map((lecture, lectureIndex) => {
                if (checkRole(Role.Student) && !lecture.chapters.length) return null;
                return <LecturePanelItems key={lectureIndex} lecture={lecture} />;
            })}
        </ul>
    );
};

export default LecturePanel;
