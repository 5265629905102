import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JwtUser } from '@store/api/apiTypes';
import { User } from '@type/users';

import type { RootState } from '@store/store';

interface IUserState {
    jwtUser: JwtUser| null
    apiUser: User | null;
}

const initialState: IUserState = {
    jwtUser: null,
    apiUser: null
};

export const userSlice = createSlice({
    initialState,
    name: 'userSlice',
    reducers: {
        clearUser: () => initialState,
        setApiUser: (state, action: PayloadAction<User>) => {
            state.apiUser = action.payload;
        },
        setJwtUser: (state, action: PayloadAction<JwtUser>) => {
            state.jwtUser = action.payload;
        }
    },
    extraReducers: {
        'authSlice/logOut': () => {
            return initialState;
        }
    }
});

export default userSlice.reducer;

export const { clearUser, setApiUser, setJwtUser } = userSlice.actions;

export const selectApiUser = (state: RootState) => state.userSlice.apiUser;
export const selectJwtUser = (state: RootState) => state.userSlice.jwtUser;
