import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { selectAuth } from '@store/features/authSlice';
import { selectJwtUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';

import { LoginStep } from './LoginStep';
import { RecoverStep } from './RecoverStep/RecoverStep';

export const LoginPage = () => {
    const [recoverPassword, setRecoverPassword] = useState<boolean>(false);
    const navigate = useNavigate();
    const isAuth = useAppSelector(selectAuth);
    const user = useAppSelector(selectJwtUser);

    const { state } = useLocation();

    const whereToRedirect = () => {
        if (state) {
            navigate(state as string, { replace: true });
            return;
        }
        switch (user?.UserRole) {
            case Role.Methodist:
                navigate('/materials', { replace: true });
                break;

            case Role.Inspector:
                navigate('/materials', { replace: true });
                break;

            case Role.Student:
                navigate('/education', { replace: true });
                break;

            case Role.Teacher:
                navigate('/teaching', { replace: true });
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        if (isAuth) {
            whereToRedirect();
        }
    }, [isAuth]);

    return (
        <>
            {recoverPassword ? (
                <RecoverStep setRecoverPassword={setRecoverPassword} />
            ) : (
                <LoginStep setRecoverPassword={setRecoverPassword} />
            )}
        </>
    );
};
