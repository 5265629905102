import { Outlet, useParams } from 'react-router-dom';

import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { useGetStudyProgrammeByUuidGroupQuery } from '@store/api/programmesApi';
import { StudyProgramm } from '@type/studyProgrammes';

export type IProgrammContext = {
    programnInfo: StudyProgramm;
} 


const StudyProgrammesLayout = () => {
    const { uuidGroup } = useParams();

    const { data: programmData, isLoading: isGetProgrammLoading } = useGetStudyProgrammeByUuidGroupQuery(uuidGroup as string);

    if (isGetProgrammLoading) return <NewLoader />;

    return <Main>
        <Outlet context={{ programnInfo: programmData }} />
    </Main>;
};

export default StudyProgrammesLayout;
