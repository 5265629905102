import React, { FC, useState, useEffect } from 'react';

import cx from 'classnames';
import { useWizard } from 'react-use-wizard';
import Webcam, { WebcamProps } from 'react-webcam';

import SelfieExample from '@assets/image/selfie-example.jpg';
import Button from '@components/core/inputs/Button';
import Loader from '@components/core/Loader';
import Modal from '@components/Modal';
import { useAuthenticate } from '@hooks/useAuthenticate';

import style from './AuthPersonaCamera.module.scss';


export const AuthPersonaCamera:FC = () => {
    const { nextStep } = useWizard();

    const [ errorCamera, setErrorCamera ] = useState(false);
    const { loadAuthenticationImage, setPictureState } = useAuthenticate('', () => !errorCamera && nextStep());

    const [isLoading, setIsLoading] = useState(true);
    const [showStartTestModal, setShowStartTestModal] = useState(false);
    const [picture, setPicture] =  useState('');
    const [isPictureLoading, setIsPictureLoading] = useState(false);


    const webcamRef = React.useRef<Webcam>(null);

    const capture = React.useCallback(() => {

        if(!webcamRef.current) return;

        const imageSrc = webcamRef.current.getScreenshot() || '';
        setPicture(imageSrc);

    },[webcamRef]);

    const onUserMediaError = (error: string | DOMException) => setErrorCamera(true);
    const onUserMedia = (stream: MediaStream) => {
        if(stream.active) {
            setIsLoading(false);
        }
    };

    const webCameraProps: WebcamProps = {
        audio: false,
        height: 520,
        screenshotFormat: 'image/jpeg',
        onUserMediaError,
        forceScreenshotSourceSize: true,
        imageSmoothing: true,
        mirrored: false,
        onUserMedia,
        screenshotQuality: 1
    };

    const takePicture = (e: React.MouseEvent<HTMLButtonElement>) => {
        if(picture) {
            setPicture('');
        } else {
            capture();
        }
    };

    const onSubmit = async () => {
        setShowStartTestModal(false);

        setIsPictureLoading(true);
        await loadAuthenticationImage();
        setIsPictureLoading(false);
    };

    useEffect(() => {
        setPictureState(picture);
    }, [picture]);


    return <div className={style.container}>
        <div className={style.leftColumn}>
            <div className={style.columnItem}>
                <h3 className={style.title}>Сделайте селфи с&nbsp;паспортом</h3>
                <p className={style.description}>
                    Постарайтесь найти освещенное место&nbsp;&mdash; например,
                    встаньте лицом к&nbsp;окну и&nbsp;держите раскрытый паспорт рядом с&nbsp;собой
                </p>

                <p className={style.description}>
                    Примерно вот так должно получиться:
                </p>

                <div className={style.example}>
                    <img alt="Пример селфи" src={SelfieExample} />
                </div>
            </div>

            <div className={style.columnItem}>
                <Button
                    btnType="success"
                    onClick={() => setShowStartTestModal(true)}
                    disabled={!picture || isPictureLoading}
                >
                    Далее
                </Button>
            </div>
        </div>

        <div className={cx(style.rightColumn, !errorCamera && style.isCamera)}>
            { (isLoading && !errorCamera ) && <div className={style.loader}><Loader /></div> }
            {
                <div className={style.innerCamera}>
                    <Webcam ref={webcamRef} {...webCameraProps}  />
                </div>
            }

            { picture ? <div className={style.screenShoot}>
                <img src={picture} alt="" />
            </div> : null}

            <div className={style.cameraUI}>
                {
                    !picture ? (
                        <Button
                            typeIcon="camera-dark"
                            btnType="white"
                            style={{ width: '454px' }}
                            onClick={takePicture}>Сделать фото</Button>
                    ) : (
                        <Button
                            typeIcon="camera-dark"
                            btnType="white"
                            style={{ width: '454px' }}
                            onClick={takePicture}
                            disabled={isPictureLoading}
                        >
                            Переснять
                        </Button>
                    )
                }
            </div>
        </div>
        <Modal
            onClose={() => setShowStartTestModal(false)}
            isOpen={showStartTestModal}
            title="Подтверждение аутентификации"
        >
            <p className={style.p}>
                Обратите внимание!
                <br />
                <br />
                Администрация института оставляет за&nbsp;собой право аннулировать полученные результаты
                экзаменов/зачетов в&nbsp;случае нарушения правил обучения или
                предоставления заведомо ложных или нечитаемых сведений, подтверждающих личность.
                <br />
                <br />
                Перед завершением аутентификации удостоверьтесь, что ваше фото
                с&nbsp;паспортом отвечает всем указанным требованиям.
            </p>
            <div className={ cx(style.button_wrapper, style.button_wrapper_end) }>
                <Button
                    onClick={ () => {
                        setPicture('');
                        setShowStartTestModal(false);
                    }}
                    btnType="secondary"
                >
                    Переснять
                </Button>
                <Button
                    onClick={onSubmit}
                >
                    Подтвердить
                </Button>
            </div>
        </Modal>
    </div>;
};
