import { FC, useEffect } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import NewTreeSelectControl, { transformDataToOptions } from '@components/core/inputs/NewTreeSelectControl';
import SwitcherControl from '@components/core/inputs/Switcher/SwitcherControl';
import Paper from '@components/core/styledWrappers/Paper';
import TinyEditor from '@components/methodist/TinyEditor';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { GroupTree } from '@store/api/apiTypes';
import { Role } from '@store/Roles';
import { CreatePageFormData } from '@views/methodist/Materials/Course/CreatePageForm/type';


import style from './CreatePageForm.module.scss';

export const createPageFormSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    content: yup.string().default(''),
    accessLimitation: yup.boolean(),
    blockedGroups: yup.array().of(yup.string().required()).when('accessLimitation', {
        is: true,
        then: yup.array().min(1, 'Выберите группу')
    })
});

interface ICreatePageForm {
    submitFormHandler: (formData: CreatePageFormData) => void;
    defaultValues: CreatePageFormData;
    groups?: GroupTree;
}

const CreatePageForm: FC<ICreatePageForm> = ({ defaultValues, submitFormHandler, groups }) => {
    const { uuidSection, uuidPage } = useParams();

    const navigate = useNavigate();
    const { name, content, accessLimitation, blockedGroups } = defaultValues;
    const methods = useForm<CreatePageFormData>({
        defaultValues,
        resolver: yupResolver(createPageFormSchema)
    });

    const { control, handleSubmit, setValue, watch } = methods;

    const onSubmit = handleSubmit((formData) => {
        if (!formData.accessLimitation) {
            delete formData.blockedGroups;
        }
        submitFormHandler(formData);
    });

    const cancelHandler = () => {
        if (uuidPage) {
            navigate(uuidSection ? './../../..' : './../..');
        } else {
            navigate(uuidSection ? './../..' : './..');
        }
    };

    const watchAccessLimitation = watch('accessLimitation');

    useEffect(() => {
        setValue('name', name);
        setValue('content', content);
        setValue('accessLimitation', accessLimitation);
        setValue('blockedGroups', blockedGroups);
    }, [defaultValues]);

    return (
        <Grid>
            <Column size={10}>
                <Paper className={style.paper}>
                    <form onSubmit={onSubmit}>
                        <FormProvider {...methods}>
                            <div>
                                <div className={cx(style.fieldset, style['fieldset--border_line'])}>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <label htmlFor="field-title-id">
                                            Название*
                                        </label>
                                        <InputControl
                                            name="name"
                                            placeholder="Название"
                                            id="field-title-id"
                                            disabled={!checkRole(Role.Methodist)}
                                        />
                                    </div>
                                    <div className={style.input_wrapper}>
                                        <p>
                                            Описание
                                        </p>
                                        <TinyEditor
                                            name="content"
                                            control={control}
                                            disabled={!checkRole(Role.Methodist)}
                                        />
                                    </div>
                                </div>
                                <div className={style.fieldset} style={{ paddingTop: '24px' }}>
                                    {
                                        !!groups && 
                                        <div className={style.input_wrapper}>
                                            <p>Доступ ограничен</p>
                                            <div className={style.permission_block_wrapper}>
                                                <SwitcherControl
                                                    name="accessLimitation"
                                                    control={control}
                                                    disabled={!checkRole(Role.Methodist)}
                                                />
                                                {watchAccessLimitation && (
                                                    <div className={style.select_list_wrapper}>
                                                        <NewTreeSelectControl
                                                            className={style.select}
                                                            name="blockedGroups"
                                                            isMulti
                                                            options={groups ? transformDataToOptions(groups.childs, {
                                                                value: 'UUID',
                                                                label: 'name',
                                                                children: 'childs'
                                                            }) : []}
                                                            placeholder="Выберите группу"
                                                            disabled={!checkRole(Role.Methodist)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }

                                    <RoleCheck roles={Role.Methodist}>
                                        <div className={style.input_wrapper}>
                                            <div></div>
                                            <div className={style.form_content__button_wrapper}>
                                                <Button type="submit">Сохранить</Button>
                                                <Button
                                                    btnType="secondary"
                                                    onClick={cancelHandler}
                                                >
                                                    Отмена
                                                </Button>
                                            </div>
                                        </div>
                                    </RoleCheck>
                                </div>
                            </div>
                        </FormProvider>
                    </form>
                </Paper>
            </Column>
        </Grid>
    );
};

export default CreatePageForm;
