import { FC, useEffect, useState } from 'react';

import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-icon.svg';
import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import Checkbox from '@components/core/inputs/Checkbox';
import InputControl from '@components/core/inputs/InputControl';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLoginUserMutation } from '@store/api/authApi';
import { selectBlocked } from '@store/features/authSlice';
import { useAppSelector } from '@store/hooks';

import style from './login_step.module.scss';


const loginSchema = yup.object({
    email: yup
        .string()
        .email('Пожалуйста, укажите корректный адрес')
        .required('Пожалуйста, введите E-mail')
        .trim(),
    password: yup.string().required('Пожалуйста, введите пароль').trim(),
    rememberMe: yup.boolean()
});

export type LoginInput = yup.InferType<typeof loginSchema>;

interface ILoginStep {
    setRecoverPassword: (value: boolean) => void;
}

export const LoginStep: FC<ILoginStep> = ({ setRecoverPassword }) => {
    const methods = useForm<LoginInput>({
        resolver: yupResolver(loginSchema),
        mode: 'onSubmit'
    });

    const isBlockedAlready = useAppSelector(selectBlocked);

    const { handleSubmit, setError, control } = methods;

    const [loginUser, { isLoading, isError, error, isSuccess }] = useLoginUserMutation();

    const [isBlocked, setBlocked] = useState(isBlockedAlready);

    const onSubmit: SubmitHandler<LoginInput> = data => {
        loginUser(data);
    };

    useEffect(() => {
        if (isError && error) {
            if ('status' in error) {
                if (error.status == 409) {
                    setBlocked(true);
                } else {
                    setError('password', { type: 'custom', message: 'Проверьте ваш логин и пароль' });
                }
            } else {
                setError('password', { type: 'custom', message: 'Проверьте ваш логин и пароль' });
            }
        }
    }, [isLoading, isSuccess, isError]);


    if (isBlocked) {
        return (
            <>
                <Link
                    onClick={() => setBlocked(false)}
                    className={style.back_link}
                    to={'#'}
                >
                    <ArrowIcon style={{ transform: 'rotate(90deg)' }} />
                    Вернуться назад
                </Link>
                <div className={style.notification_error}>
                    <div className={style.notification_error__title_wrapper}>
                        <IconUI typeIcon="attention-error" />
                        <h3 className={style.notification_error__title}>
                            Ваша учетная запись заблокирована
                        </h3>
                    </div>
                    <div className={style.notification_error__content_wrapper}>
                        <p>Возможные причины блокироваки:</p>
                        <ul className={style.list}>
                            <li className={style.list__item}>
                                <h4 className={style.notification_error__subtitle}>Задержка по оплате за обучение</h4>
                                <p>
                                    Если была допущена задержка оплаты обучения - обратитесь
                                    в бухгалтерию для уточнения суммы задолженности или воспользуйтесь&nbsp;
                                    <a
                                        className={style.notification_error__link}
                                        target="_blank"
                                        download
                                        href="/files/payment_receipt.doc"
                                    >
                                        реквизитами для оплаты
                                    </a>, если сумма известна.
                                </p>
                            </li>
                            <li className={style.list__item}>
                                <h4 className={style.notification_error__subtitle}>Отчисление с курса</h4>
                                <p>
                                    Отчисление происходит при академических задолженностях или после завершения обучения.
                                    Обратитесь к инспектору вашей программы.
                                </p>
                            </li>
                            <li className={style.list__item}>
                                <h4 className={style.notification_error__subtitle}>Нарушение правил по работе с сервисом</h4>
                                <p>
                                    Более подробную информацию вы можете получить обратившись в поддержку.
                                    Контакты сотрудников Института вы можете увидеть&nbsp;на&nbsp;
                                    <a target="_blank" rel="noreferrer" href="https://eeip.ru/kontakty" className={style.notification_error__link}>нашем
                                        сайте</a>
                                </p>
                            </li>
                        </ul>
                        <p>
                            Более подробную информацию вы можете получить
                            обратившись в поддержку.
                        </p>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <h1 className={style.title}>Вход в систему</h1>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormProvider {...methods}>
                    <div className={style.input_layout}>
                        <InputControl
                            name="email"
                            type="email"
                            placeholder="E-mail"
                        />
                        <InputControl
                            name="password"
                            type="password"
                            placeholder="Пароль"
                        />
                        <Controller
                            name="rememberMe"
                            control={control}
                            defaultValue={false}
                            render={({ field: { value, onChange } }) => (
                                <Checkbox
                                    label="Запомнить меня"
                                    checked={value}
                                    onChange={onChange}
                                />
                            )}
                        />

                    </div>
                    <Button
                        type={'submit'}
                        fullWidth={true}
                        className={style.button_submit}
                        disabled={isLoading}
                    >
                        Войти
                    </Button>
                </FormProvider>
            </form>
            <div className={style.recover_password}>
                <span className={style.recover_password__text}>
                    Забыли пароль?
                </span>
                <Link
                    onClick={() => setRecoverPassword(true)}
                    className={style.recover_password__link}
                    to={'#'}
                >
                    Восстановить
                </Link>
            </div>
        </>
    );
};
