import { useEffect, useState, FC } from 'react';

import Countdown, { CountdownRenderProps, CountdownTimeDelta } from 'react-countdown';


type ICounter = {
    onSubmit: () => void;
    passedTime: number;
    timeLimit: number;
    onTick?: (countProps: CountdownTimeDelta) => void;
}

const Counter: FC<ICounter> = ({ onSubmit, passedTime, timeLimit, onTick }) => {
    const [deadline, setDeadline] = useState<number>();

    const countDeadline = () => {
        const testEnd = new Date();
        testEnd.setSeconds(testEnd.getSeconds() + (timeLimit * 60 - (passedTime)));
        setDeadline(testEnd.getTime());
    };

    const renderer = ({ hours, minutes, seconds, days, formatted }: CountdownRenderProps) => {
        if (days) return `${formatted.days}:${formatted.hours}:${formatted.minutes}:${formatted.seconds}`;
        if (hours) return `${hours}:${formatted.minutes}:${formatted.seconds}`;
        if (minutes) return `${minutes}:${formatted.seconds}`;
        return `${seconds}`;
    };

    useEffect(() => {
        countDeadline();
    }, [passedTime, timeLimit]);


    return (
        <Countdown
            date={deadline}
            renderer={renderer}
            daysInHours={true}
            key={deadline}
            onComplete={onSubmit}
            onTick={onTick}
        />
    );
};

export default Counter;
