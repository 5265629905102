import { FC, useState } from 'react';

import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import { DateTime } from 'ts-luxon';

import CalendarLayout from '@components/core/Calendar/CalendarLayout';
import Monitor from '@components/core/Calendar/Monitor';
import { CalendarProvider } from '@components/EventsFilters/context/provider';
import { Helmet } from '@components/Helmet';

import style from './calendar.module.scss';

export interface NavigateState {
    pathname: string;
    today?: string;
}

export interface ICalendarProps {
    children?: React.ReactNode
}

const Calendar: FC<ICalendarProps> = () => {
    const location = useLocation();

    const navigationState = location.state as NavigateState || null;
    const initialToday = navigationState?.today ? DateTime.fromJSDate(new Date(navigationState.today)) : DateTime.now().setLocale('ru');

    const [today, setToday] = useState(initialToday);
    const startDay = today.startOf('month').startOf('week');

    const prevHandler = () => setToday(prev => prev.minus({ month: 1 }));
    const todayHandler = () => setToday(DateTime.now());
    const nextHandler = () => setToday(prev => prev.plus({ month: 1 }));

    const getTotalDays = () => {
        const startWeekDay = today.startOf('month').weekday;
        const endMonthDay = today.endOf('month');
        const endWeekDay = endMonthDay.weekday;

        return startWeekDay - 1 + endMonthDay.day + 7 - endWeekDay;
    };

    return (
        <>
            <Helmet title="Мероприятия – Актуальные" />
            
            <CalendarProvider>
                <div className={cx(style.wrapper)}>

                    <Monitor
                        today={today}
                        prevHandler={prevHandler}
                        todayHandler={todayHandler}
                        nextHandler={nextHandler} />

                    <CalendarLayout
                        startDay={startDay}
                        today={today}
                        totalDays={getTotalDays()} />
                </div>
            </CalendarProvider>
        </>
    );
};

export default Calendar;
