import { Link } from 'react-router-dom';

import { Column, Grid } from '@components/core/grid';
import IconUI from '@components/core/IconUI';
import { DataList, DataListCell } from '@components/DataList';
import { Helmet } from '@components/Helmet';
import { Label } from '@components/Label';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { ITeacherDiscipline, useGradesContext } from '@views/teacher/TeacherMain/TeacherMain';

import style from './teaching_disciplines.module.scss';

const getEmptyMessage = () => {
    return (
        <div className={style.empty_message}>
            <div>
                <IconUI typeIcon='teacher-disciplines-plug' />
            </div>
            <div className={style.empty_message_header}>
                Преподаваемых <br /> дисциплин нет
            </div>
            <div className={style.empty_message_text}>
                Дисциплины появятся после <br /> назначения методистом
            </div>
        </div>
    );
};

const TeachingDisciplines = () => {
    const { disciplines } = useGradesContext();

    const lgUp = useBreakpoint('lg', 'up');
    const userAccount = useAppSelector(selectApiUser);

    const renderLabel = (discipline: ITeacherDiscipline) => {
        return !!discipline.doubles.filter(target => target.uuid === userAccount?.uuid).length &&  <Label color='gray' caption='Дублёр' />;
    };

    return (
        <>
            <Helmet title="Преподавание – Преподаваемые дисциплины" />
            {!Object.values(disciplines).length ? (
                <div className={style.empty_message}>{getEmptyMessage()}</div>
            ) : (
                <DataList className={style.list_wrapper}>
                    {
                        lgUp && (
                            <Grid gridTemplateColumns={'89% 9%'} className={style.data_list__header}>
                                <Column>
                                    <DataListCell className={style.column_label}>Дисциплина</DataListCell>
                                </Column>

                                <Column className={style.flex_end}>
                                    <DataListCell className={style.column_label}>На проверку</DataListCell>
                                </Column>
                            </Grid>
                        )
                    }
                    {
                        Object.values(disciplines).map((discipline) => {
                            return (
                                <div key={discipline.disciplineUuid} className={style.list_item}>
                                    <Grid className={style.list_item__grid}>
                                        <Column>
                                            <Link className={style.link} to={`/teaching/${discipline.disciplineUuid}`}>{discipline.disciplineName}</Link>
                                            { renderLabel(discipline) }
                                        </Column>

                                        <Column className={style.flex_end}>
                                            <span className={style.list_item__work_count}>
                                                {!lgUp && 'На проверку: '}
                                                {discipline.worksCount}
                                            </span>
                                        </Column>
                                    </Grid>
                                </div>
                            );
                        })
                    }
                </DataList>
            )}
        </>
    );
};

export default TeachingDisciplines;
