import React, { FC } from 'react';

import cx from 'classnames';

import { TypeIcon } from '@components/core/IconUI/type';
import Button from '@components/core/inputs/Button';
import { useBreakpoint } from '@hooks/useBreakpoint';

import style from '../Pagination.module.scss';

interface IPaginationControlProps {
    type?: string;
    selected? : boolean;
    disabled?: boolean;
}

interface IBtnData {
    caption?: string;
    icon?: TypeIcon;
}

export const PaginationControl: FC<IPaginationControlProps> = (props) => {
    const lgUp = useBreakpoint('lg', 'up');

    const normalize = (name: string | undefined) => {
        switch(name) {
            case 'next':
                return { caption: 'Дальше', icon: 'pagination-next' } as IBtnData;
            case 'previous':
                return { caption: 'Назад', icon: 'pagination-prev' } as IBtnData;
            default:
                return {};
        }
    };

    const { type, selected, disabled, ...btnProps } = props;

    const btnData = normalize(type);

    return <React.Fragment>
        <Button
            btnType="pagination"
            typeIcon={btnData.icon}
            {...btnProps}
            isIconPrev={type === 'previous'}
            className={cx(style.btn, disabled && style.btn_hide)}
        >
            { lgUp && btnData.caption }
        </Button>
    </React.Fragment>;
};
