import React, { FC } from 'react';

import cx from 'classnames';

import IconUI from '@components/core/IconUI';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { TUploadFile } from '@components/core/inputs/FileUpload/type';
import { isStorageFile } from '@helpers/typeCheck';
import { IStorageFile } from '@store/api/apiTypes';

import style from './FilesList.module.scss';

interface IFilesList {
    files: TUploadFile[] | IStorageFile[];
    deleteFile: (indexFile: number | undefined) => void;
    withoutCheckControl?: boolean;
}

const FilesList: FC<IFilesList> = ({ files, deleteFile, withoutCheckControl = false }) => {
    return (
        <div className={cx(style.list_wrapper, files.length > 3 && style['list_wrapper--hidden'])}>
            <ul className={cx(style.list, files.length > 3 && style['list--hidden'])}>
                {
                    files?.map((file, index) => {
                        return (
                            <li key={index} className={style.list__item}>
                                <div className={style.list_item__wrapper}>
                                    <IconUI typeIcon={'clip'} className={style.list_item__icon} />
                                    <span className={style.list_item__name}>{isStorageFile(file) ? file.title : file?.name}</span>
                                    {!withoutCheckControl && <IconUI typeIcon={'check'} />}
                                </div>
                                <ButtonIcon
                                    typeIcon={'close'}
                                    className={style.list_item__button}
                                    onClick={() => deleteFile(index)}
                                />
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

export default FilesList;
