import { FC } from 'react';

import IconUI from '@components/core/IconUI';

import style from './SaveState.module.scss';

export interface ISaveStateProps {
    isSaving: boolean;
}

export const SaveState:FC<ISaveStateProps> = ({
    isSaving = true
}) => {

    return <div className={style.container}>

        <div className={style.content}>
            {
                isSaving ? 
                    ('Сохраненно')
                    :
                    ('Сохраняем...')
            }
        </div>

        <div className={style.icon}>
            {
                isSaving ? 
                    (
                        <IconUI typeIcon='check' />
                    ) 
                    : 
                    (
                        <IconUI className={style.loading_icon} typeIcon='loading' />
                    )
            }
        </div>
    </div>;
};