import React, { MouseEvent, useEffect, useState } from 'react';

import cx from 'classnames';

import IconUI from '@components/core/IconUI';
import Checkbox from '@components/core/inputs/Checkbox';
import { useTreeSelect } from '@components/core/inputs/Select/NewTreeSelect/context';
import { useToggle } from '@hooks/useToggle.hook';
import { Collapse } from '@mui/material';

import { TreeSelectOption } from '../../NewTreeSelect';
import style from './Option.module.scss';

interface OptionProps {
    option: TreeSelectOption;
    search: string;
    level?: number;
}

export const Option: React.FC<OptionProps> = ({ option, search, level = 0 }) => {
    const [isOpen, { toggle, on }] = useToggle();

    const {
        isMulti,
        onChange,
        value,
        onClose,
        optionsMapWithParents,
        optionsMapWithEndChildrens,
        handleSelect,
        selectedMultiValue,
        selectableParent
    } = useTreeSelect();

    const [defaultOpened, setDefaultOpened] = useState(false);

    const handeLocalSelect = () => {
        if (!isMulti) {
            onChange(option.value);
            onClose();
        } else {
            handleSelect(option.value);
        }
    };

    const getSelectedStatus = (): boolean => {
        if (!isMulti) {
            return value === option.value;
        } else {
            const allCarrentChilds = optionsMapWithEndChildrens?.[option.value];

            if (!allCarrentChilds?.endChildren.length) {
                return selectedMultiValue.includes(option.value);
            } else {
                const countValuesOfOption = selectedMultiValue.filter(el => allCarrentChilds.endChildren.includes(el)).length;
                return countValuesOfOption === allCarrentChilds.endChildren.length;
            }
        }
    };

    const getOpenedStatus = (): boolean => {
        if (search) return true;
        if (!isMulti) {
            return optionsMapWithParents[value]?.parents.includes(option.value);
        }
        const currentOptionWithChilds = optionsMapWithEndChildrens?.[option.value];
        return Boolean(currentOptionWithChilds?.endChildren.some(el => selectedMultiValue.includes(el)));
    };

    const opened = getOpenedStatus();

    useEffect(() => {
        if (opened && !isOpen) {
            on();
            setDefaultOpened(true);
        }
    }, [opened]);

    useEffect(() => {
        if (defaultOpened) {
            setDefaultOpened(false);
        }
    }, [defaultOpened]);

    const renderLabel = (label: string): React.ReactNode => {
        const lowerCaseLabel = label.toLowerCase();
        const lowerCaseSearchValue = search.toLowerCase();

        const matchPosition = lowerCaseLabel.search(lowerCaseSearchValue);

        if (matchPosition === -1) {
            return <span className={style.searchable}>{label}</span>;
        }

        const beforeString = label.slice(0, matchPosition);
        const markString = label.slice(matchPosition, matchPosition + search.length);
        const afterString = label.slice(matchPosition + search.length);

        return (
            <span className={style.searchable}>
                {beforeString}
                <mark>{markString}</mark>
                {afterString}
            </span>
        );
    };

    const handleParent = () => {
        if (selectableParent && !isMulti) {
            onChange(option.value);
            onClose();
        } else {
            toggle();
        }
    };

    const handleMore = (event: MouseEvent<HTMLDivElement>) => {
        if (selectableParent && !isMulti) {
            event.stopPropagation();
            toggle();
        }
    };

    if (!option.children.length) {
        return (
            <div
                className={cx(
                    style.root,
                    style['option--hover'],
                    {
                        [style.active]: !isMulti && getSelectedStatus()
                    }
                )}
                style={{ '--group-level': level } as any}
                onClick={handeLocalSelect}
            >
                {isMulti ? (
                    <span onClick={event => event.preventDefault()}>
                        <Checkbox checked={getSelectedStatus()} label={renderLabel(option.label)} containerClassName={style.checkbox} />
                    </span>
                ) : renderLabel(option.label)}
            </div>
        );
    }

    return (
        <div>
            <div onClick={handleParent} className={cx(style.label, {
                [style.active]: !isMulti && getSelectedStatus()
            })} style={{ '--group-level': level } as any}>
                <div onClick={handleMore} className={cx(style.label__icon, isOpen && style['label__icon--active'])}>
                    <IconUI typeIcon={'arrow'} />
                </div>
                {isMulti ? (
                    <span onClick={(event) => {
                        event.stopPropagation();
                        handleSelect(option.value);
                    }}>
                        <Checkbox checked={getSelectedStatus()} label={renderLabel(option.label)} containerClassName={style.checkbox} />
                    </span>
                ) : renderLabel(option.label)}
            </div>
            <Collapse
                in={isOpen}
                timeout={defaultOpened ? 0 : 300}
            >
                {option.children.map(item => (
                    <Option key={item.value} search={search} option={item} level={level + 1} />
                ))}
            </Collapse>
        </div>
    );
};
