import { useEffect } from 'react';

import { soundManager } from '@helpers/sound';
import { selectSoundOn } from '@store/features/settingsLSSlice';
import { useAppSelector } from '@store/hooks';
import { TypeSound } from '@type/sound';

interface UseSound {
    play: () => void;
}

export const useSound = (type: TypeSound): UseSound => {
    const soundOn = useAppSelector(selectSoundOn);

    useEffect(() => {
        soundManager.init();
    }, []);

    const play = () => {
        if (soundOn) {
            soundManager.play(type);
        }
    };

    return {
        play
    };
};
