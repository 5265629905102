import React from 'react';

import { useParams } from 'react-router-dom';

import { ReactComponent as BookIcon } from '@assets/icons/book-icon.svg';
import Loader from '@components/core/Loader';
import { useToggle } from '@hooks/useToggle.hook';
import { useGetDisciplineMaterialsListQuery } from '@store/api/materialsApi';
import { CommonSidebar } from '@views/student/StudentCourse/Sidebars/CommonSidebar';

import Materials from '../../Materials';
import style from './MaterialsSidebar.module.scss';


interface MaterialsSidebarProps {
    className?: string;
}

export const MaterialsSidebar: React.FC<MaterialsSidebarProps> = () => {
    const [isOpen, { on, off }] = useToggle();

    const { uuidDiscipline } = useParams();
    const { data: materialsList, isSuccess, isLoading } = useGetDisciplineMaterialsListQuery(uuidDiscipline as string);

    return (
        <CommonSidebar isOpen={isOpen} on={on} off={off} title="Учебные материалы" icon={<BookIcon />} containerClassName={style.materials_content}>
            {isLoading && <Loader />}
            {isSuccess && (
                <ul>
                    {
                        materialsList?.map(material => (
                            <li key={material.uuid}>
                                <Materials.AdditionalMaterial
                                    material={material}
                                    className={style.material}
                                />
                            </li>
                        ))
                    }
                </ul>
            )}
        </CommonSidebar>
    );
};
