import { FC, useEffect, useState } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import Modal from '@components/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateUserDataMutation } from '@store/api/usersApi';
import { Role } from '@store/Roles';

import style from './UserProfileDataModal.module.scss';


const schema = yup.object({
    lastName: yup.string().required('Добавьте фамилию'),
    firstName: yup.string().required('Добавьте имя'),
    patronymic: yup.string().required('Добавьте отчество'),
    countryId: yup.number(),
    recordBookNumber: yup.string(),
    phone: yup.string(),
    cityId: yup.number()
});

type IUserProfileDataModalForm = yup.InferType<typeof schema>;

interface IUserProfileDataModalProps {
    isOpen: boolean,
    onClose: () => void,
    defaultValues: IUserProfileDataModalForm,
    userRole?: Role
}

export const UserProfileDataModal: FC<IUserProfileDataModalProps> = ({ isOpen, onClose, defaultValues, userRole }) => {
    const [updateData] = useUpdateUserDataMutation();
    const { userId } = useParams();
    const isStudent = userRole === Role.Student;

    const methods = useForm<IUserProfileDataModalForm>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: { ...defaultValues }
    });

    const { handleSubmit, reset, setValue, control } = methods;

    const onSubmit = async (formData: IUserProfileDataModalForm) => {
        updateData({
            ...formData,
            uuid: userId || '',
            phone: formData.phone || '',
            countryId: formData.countryId || 0,
            cityId: formData.cityId || 0
        });

        reset();
        onClose();
    };

    useEffect(() => {
        setValue('lastName', defaultValues.lastName);
        setValue('firstName', defaultValues.firstName);
        setValue('patronymic', defaultValues.patronymic);
        setValue('countryId', defaultValues.countryId);
        setValue('phone', defaultValues.phone);
        setValue('cityId', defaultValues.cityId);
        setValue('recordBookNumber', defaultValues.recordBookNumber);

    }, [defaultValues]);

    return (
        <>
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                title="Личные данные"
            >
                <FormProvider {...methods}>
                    <form onSubmit={(handleSubmit(onSubmit))} className={style.container}>
                        <div className={style.field}>
                            <InputControl  label="Фамилия*" name="lastName" placeholder="Фамилия"   />
                        </div>

                        <div className={style.field}>
                            <InputControl  label="Имя*" name="firstName" placeholder="Имя"   />
                        </div>

                        <div className={style.field}>
                            <InputControl  label="Отчество*" name="patronymic" placeholder="Отчество"   />
                        </div>

                        <div className={style.field}>
                            <InputControl  label="Телефон" name="phone" placeholder="Телефон"   />
                        </div>

                        {
                            isStudent && <div className={style.field}>
                                <InputControl  label="Номер зачетки" name="recordBookNumber" placeholder="Номер зачетки"   />
                            </div>
                        }

                        {/* <div className={style.field}>
                            <InputControl  label="Страна" name="country" placeholder="Страна"   />

                            <div>Страна: </div>
                                <SelectStyled name='country' placeholder='Страна' control={control} options={[
                                { label: 'Россия', value: '1' },
                                { label: 'Франция', value: '2' },
                                { label: 'Германия', value: '3' },
                                { label: 'Польша', value: '4' },
                                { label: 'Китай', value: '5' }
                            ]}  />
                        </div> */}

                        {/* <div className={style.field}>
                            <InputControl  label="Город" name="city" placeholder="Город"   />
                        </div> */}

                        <div className={style.actions}>
                            <Button btnType="secondary" onClick={onClose}>Отмена</Button>
                            <Button btnType="success" onClick={handleSubmit(onSubmit)}>Сохранить</Button>
                        </div>
                    </form>


                </FormProvider>
            </Modal>
        </>

    );
};
