import { FC } from 'react';

import Button from '@components/core/inputs/Button';
import Modal from '@components/Modal';


import style from './SetEditTermsMode.module.scss';

interface ISetEditTermsMode{
    setEditMode: (isSetupMode: boolean) => void;
    onClose: (isOpen: boolean) => void;
    isOpen: boolean;
    title: string;
}

const SetEditTermsMode: FC<ISetEditTermsMode> = ({ setEditMode, onClose, isOpen, title }) => {

    return (
        <>
            <Modal
                onClose={() => onClose(false)}
                isOpen={isOpen}
                title={title}
            >
                <div className={style.button_wrapper}>
                    <Button
                        onClick={() => setEditMode(true)}
                    >
                        Установить сроки сдачи
                    </Button>
                    <Button
                        onClick={() => setEditMode(false)}
                        btnType="secondary"
                        typeIcon="basket"
                    >
                        Удалить сроки сдачи
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default SetEditTermsMode;
