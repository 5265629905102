import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { dateConvert } from '@helpers/date';
import { datePrepareToSend } from '@helpers/date';
import { getFlatGroupsWithEndsChildren } from '@helpers/groups';
import { useNavigationState } from '@hooks/useNavigationState';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { SubscribeDiscipline } from '@store/api/apiTypes';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import {
    useGetGroupsTreeQuery,
    useGetSubscribedDisciplinesQuery,
    useSubscribeToDisciplinesMutation,
    useSubscribeStudentsToDisciplinesMutation,
    useGetSubscribedAdditionalDisciplinesQuery
} from '@store/api/groupsApi';
import { addDefaultItems, selectedItems } from '@store/features/subscribeSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import style from './Subscribe.module.scss';
import { SubscribeCard } from './SubscribeCard';
import SubscribeMassEdit from './SubscribeMassEdit';


export const Subscribe = () => {
    const { activeGroup, studentId } = useParams();

    const { state } = useNavigationState<{ currentGroup: string, openedGroups: string[] }>();
    const { navigate } = useNavigationState<{ currentGroup: string, openedGroups: string[] }>(state);

    const dispatch = useAppDispatch();

    const isAdditionalDisciplines = Boolean(studentId);

    const { categories, isCatsLoading } = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000', {
        selectFromResult: ({ data, isLoading }) => ({
            categories: data ?? [],
            isCatsLoading: isLoading
        })
    });
    const groupsTreeQueryData = useGetGroupsTreeQuery('');

    const groupMapWithEndChildren = groupsTreeQueryData.data ? getFlatGroupsWithEndsChildren(groupsTreeQueryData.data.childs) : null;
    const currentGroup = groupMapWithEndChildren?.[activeGroup as string];
    const endChildren = currentGroup?.endChildren || [];

    const subscribedDisciplines = useGetSubscribedDisciplinesQuery(activeGroup ? [activeGroup] : skipToken);
    const subscribedStudentDisciplines = useGetSubscribedAdditionalDisciplinesQuery(studentId || skipToken);

    const [subscribe] = useSubscribeToDisciplinesMutation();
    const [subscribeStudent] = useSubscribeStudentsToDisciplinesMutation();

    const itemsToSub = useAppSelector(selectedItems);

    useEffect(() => {
        if (!isAdditionalDisciplines) {
            if (subscribedDisciplines.isSuccess) {
                const defaultData = subscribedDisciplines.data.map(item => ({
                    uuid: item.disciplineUUID,
                    session: item.session,
                    closeType: item.closeType,
                    startDate: dateConvert(item.startDate).toFormat('yyyy-LL-dd HH:mm:ss'),
                    disabled: isAdditionalDisciplines
                }));

                dispatch(addDefaultItems(defaultData));
            }
        } else {
            if (subscribedDisciplines.isSuccess && subscribedStudentDisciplines.isSuccess) {
                const defaultGroupData = subscribedDisciplines.data.map(item => ({
                    uuid: item.disciplineUUID,
                    session: item.session,
                    closeType: item.closeType,
                    startDate: dateConvert(item.startDate).toFormat('yyyy-LL-dd HH:mm:ss'),
                    disabled: isAdditionalDisciplines
                }));

                const defaultStudentData = subscribedStudentDisciplines.data.map(item => ({
                    uuid: item.disciplineUUID,
                    session: item.session,
                    closeType: item.closeType,
                    startDate: dateConvert(item.startDate).toFormat('yyyy-LL-dd HH:mm:ss')
                }));

                const defaultData = [...defaultGroupData, ...defaultStudentData];
                dispatch(addDefaultItems(defaultData));
            }
        }

    }, [
        subscribedDisciplines.isFetching,
        subscribedDisciplines.isSuccess,
        isAdditionalDisciplines,
        subscribedStudentDisciplines.data,
        subscribedStudentDisciplines.isSuccess
    ]);

    const subscribeDisciplinesByGroup = async () => {
        const disciplines: SubscribeDiscipline[] = [];

        Object.entries(itemsToSub).forEach(([uuid, item]) => {
            disciplines.push({
                disciplineUUID: uuid,
                session: item.session,
                closeType: item.closeType,
                startDate: datePrepareToSend(item.startDate) || '',
                progress: 0
            });

        });

        const data = {
            groupUUID: activeGroup as string,
            disciplines: disciplines
        };

        await subscribe(data);

        // Если есть флаг наследования у текущей группы и есть кому наследовать
        if (currentGroup?.isInherit && endChildren.length) {
            await Promise.all(endChildren.map(childGroup => {
                return subscribe({
                    groupUUID: childGroup.UUID,
                    disciplines: disciplines
                });
            }));
        }
    };

    const subscribeDisciplinesByStudent = async () => {
        const disciplines: SubscribeDiscipline[] = [];

        Object.entries(itemsToSub).forEach(([uuid, item]) => {
            if (!item?.disabled) {

                disciplines.push({
                    disciplineUUID: uuid,
                    session: item.session,
                    closeType: item.closeType,
                    startDate: datePrepareToSend(item.startDate) || ''
                });
            }
        });

        await subscribeStudent({
            uuidUser: [studentId as string],
            disciplines
        });
    };

    const subscribeDisciplines = () => {
        if (!isAdditionalDisciplines) {
            return subscribeDisciplinesByGroup();
        }
        return subscribeDisciplinesByStudent();
    };

    return (
        <Main>
            <div className={style.header}>
                <div className={style.title_wrapper}>
                    <ButtonIcon
                        typeIcon="arrowLink"
                        onClick={() => navigate(!isAdditionalDisciplines ? './../..' : './../../../editGroup')}
                    />
                    <h1>Подписать на {!isAdditionalDisciplines ? 'курс' : 'дополнительную дисциплину'}</h1>
                </div>
            </div>

            <div className={style.cards}>
                {
                    isCatsLoading ? <NewLoader /> : <SubscribeCard items={categories} onSubmit={subscribeDisciplines} />
                }
            </div>

            <SubscribeMassEdit />
        </Main>
    );
};
