import { FC } from 'react';

import classNames from 'classnames/bind';

import { IRenderUpload, TCaptionType } from '@components/core/inputs/FileUpload/type';

import styles from './filecaption.module.scss';

const cx = classNames.bind(styles);

interface IFileCaptionProps {
    type?: TCaptionType;
    size: string;
    children?: React.ReactNode;
}

export const FileCaption: FC<IFileCaptionProps> = ({ type = 'none', size, children }) => {
    const fileCaptionRender: IRenderUpload = {
        inline: (
            <span>
                Форматы: PDF, DOC, XLS, SVG максимум: {size} Мб
            </span>
        ),
        column: (
            <span>
                Форматы: PDF, DOC, XLS, SVG, PNG, JPG. <br />
                Максимум: {size} МБ
            </span>
        )
    };

    if (children) return <div className={cx('root__caption', type && `root--${type}`)}>{children}</div>;

    return (
        <>
            { 
                type === 'none' ? <div className={cx('root__caption', type && `root--${type}`)}>
                    {fileCaptionRender[type]}
                </div> : null 
            }
        </>
    );
};
