import { toast } from 'react-toastify';

import { CalendarEventRequest, CreateEvent, EditEvent, Event, EventsByUuid, EventsByUuidRequest, PassEventRequest } from './apiTypes';
import { emptyBaseApi } from './emptyBaseApi';

export const usersApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getCalendarEvents: build.query<Event[], CalendarEventRequest>({
            query(data) {
                return {
                    url: 'events/calendar',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения мероприятий');
                }
            },
            transformResponse: (result: { events: Event[] }) => {
                return result.events;
            },
            providesTags: (result, error, arg) => [{ type:'Events', id: `List_${arg.fromDate}_${arg.toDate}` }]
        }),

        getStudentCalendarEvents: build.query<Event[], CalendarEventRequest>({
            query(data) {
                return {
                    url: `events/calendar/student?fromDate=${data.fromDate}&toDate=${data.toDate}`,
                    method: 'GET'
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения мероприятий');
                }
            },
            transformResponse: (result: { events: Event[] }) => {
                return result.events;
            },
            providesTags: (result, error, arg) => [{ type:'Events', id: `List_${arg.fromDate}_${arg.toDate}` }]
        }),

        getPastEvents: build.query<Event[], PassEventRequest>({
            query(data) {
                return {
                    url: `events/past?limit=${data.limit}&offset=${data.offset}`,
                    method: 'GET'
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения прошедших мероприятий');
                }
            },
            transformResponse: (result: { events: Event[] }) => {
                return result.events;
            },
            providesTags: (result, error, arg) => [{ type:'PastEvents', id: `List_${arg.limit}_${arg.offset}` }]
        }),

        getStudentPastEvents: build.query<Event[], PassEventRequest>({
            query(data) {
                return {
                    url: `events/past/student?limit=${data.limit}&offset=${data.offset}`,
                    method: 'GET'
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения прошедших мероприятий');
                }
            },
            transformResponse: (result: { events: Event[] }) => {
                return result.events;
            },
            providesTags: (result, error, arg) => [{ type:'PastEvents', id: `List_${arg.limit}_${arg.offset}` }]
        }),

        createEvent: build.mutation<boolean, CreateEvent>({
            query(data) {
                return {
                    url: 'events/create',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось создать мероприятие');
                }
            },
            transformResponse: (result: { result: boolean }) => {
                return result.result;
            },
            invalidatesTags: (result, error, arg) => {
                return ['Events', 'PastEvents'];
            }
        }),

        editEvent: build.mutation<boolean, EditEvent>({
            query(data) {
                return {
                    url: 'events/edit',
                    method: 'PUT',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось изменить мероприятие');
                }
            },
            transformResponse: (result: { result: boolean }) => {
                return result.result;
            },
            invalidatesTags: (result, error, arg) => {
                return ['Events', 'PastEvents'];
            }
        }),

        getEventByUuid: build.query<EventsByUuid[], EventsByUuidRequest>({
            query(data) {
                return {
                    url: 'events/get-by-uuids',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось получить мероприятие');
                }
            },
            transformResponse: (result: { events: EventsByUuid[] }) => {
                return result.events;
            },
            providesTags: (result, error, arg) => [ { type: 'Events', id: arg.uuids[0] } ]
        }),

        deleteEvent: build.mutation<void, string>({
            query(uuidEvent) {
                return {
                    url: 'events/delete',
                    method: 'POST',
                    body: { uuids: [uuidEvent] }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Мероприятие удалено',
                    error: 'Ошибка удаления!'
                });
            },
            invalidatesTags: ['Events', 'PastEvents']
        }),

        getTeacherPastEvents: build.query<Event[], PassEventRequest>({
            query(data) {
                return {
                    url: `events/past/teacher?limit=${data.limit}&offset=${data.offset}`,
                    method: 'GET'
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения прошедших мероприятий');
                }
            },
            transformResponse: (result: { events: Event[] }) => {
                return result.events;
            },
            providesTags: (result, error, arg) => [{ type:'PastEvents', id: `List_${arg.limit}_${arg.offset}` }]
        }),

        getTeacherCalendarEvents: build.query<Event[], CalendarEventRequest>({
            query(data) {
                return {
                    url: `events/calendar/teacher?fromDate=${data.fromDate}&toDate=${data.toDate}`,
                    method: 'GET'
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения мероприятий');
                }
            },
            transformResponse: (result: { events: Event[] }) => {
                return result.events;
            },
            providesTags: (result, error, arg) => [{ type:'Events', id: `List_${arg.fromDate}_${arg.toDate}` }]
        })
    })
});

export const {
    useGetCalendarEventsQuery,
    useGetStudentCalendarEventsQuery,
    useGetPastEventsQuery,
    useGetStudentPastEventsQuery,
    useCreateEventMutation,
    useGetEventByUuidQuery,
    useEditEventMutation,
    useDeleteEventMutation,
    useGetTeacherPastEventsQuery,
    useGetTeacherCalendarEventsQuery
} = usersApi;
