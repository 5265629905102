import { FC, ReactNode } from 'react';

import classNames from 'classnames/bind';

import { ReactComponent as ArrowSVG, ReactComponent as ArrowLeftSVG } from '@assets/icons/arrow-icon.svg';
import { ReactComponent as PrevSVG } from '@assets/icons/arrow-left.svg';
import { ReactComponent as ArrowLinkSVG } from '@assets/icons/arrow-link-icon.svg';
import { ReactComponent as PaginationNextSVG } from '@assets/icons/arrow-pagination-next.svg';
import { ReactComponent as PaginationPrevSVG } from '@assets/icons/arrow-pagination-prev.svg';
import { ReactComponent as NextSVG } from '@assets/icons/arrow-right.svg';
import { ReactComponent as AttentionErrorSVG } from '@assets/icons/attention-error.svg';
import { ReactComponent as BasketSVG } from '@assets/icons/basket-Icon.svg';
import { ReactComponent as BookSVG } from '@assets/icons/book-icon.svg';
import { ReactComponent as CalendarLightSVG } from '@assets/icons/calendar-light.svg';
import { ReactComponent as CalendarSVG } from '@assets/icons/calendar.svg';
import { ReactComponent as CameraDarkSVG } from '@assets/icons/camera-dark.svg';
import { ReactComponent as CameraSVG } from '@assets/icons/camera-icon.svg';
import { ReactComponent as ChatClipSVG } from '@assets/icons/chat-clip-icon.svg';
import { ReactComponent as CheckboxSVG } from '@assets/icons/check-value-icon.svg';
import { ReactComponent as ClipGreenSVG } from '@assets/icons/clip-icon-green.svg';
import { ReactComponent as ClipSVG } from '@assets/icons/clip-icon.svg';
import { ReactComponent as ClockSVG } from '@assets/icons/clock-icon.svg';
import { ReactComponent as ClockRedSVG } from '@assets/icons/clock-red-icon.svg';
import { ReactComponent as CloseSVG } from '@assets/icons/close-icon.svg';
import { ReactComponent as Competencies } from '@assets/icons/competencies.svg';
import { ReactComponent as ConfirmErrorSVG } from '@assets/icons/confirm-error-icon.svg';
import { ReactComponent as PageSVG } from '@assets/icons/content.svg';
import { ReactComponent as DateSVG } from '@assets/icons/date.svg';
import { ReactComponent as DefaultFileSVG } from '@assets/icons/defaultFile.svg';
import { ReactComponent as DOCSVG } from '@assets/icons/doc.svg';
import { ReactComponent as DocumentSVG } from '@assets/icons/document-icon.svg';
import { ReactComponent as DotsSVG } from '@assets/icons/dots.svg';
import { ReactComponent as DownloadSVG } from '@assets/icons/download.svg';
import { ReactComponent as DragAndDropSVG } from '@assets/icons/drag-and-drop-icon.svg';
import { ReactComponent as DzenSVG } from '@assets/icons/dzen-icon.svg';
import { ReactComponent as EditSVG } from '@assets/icons/edit-Icon.svg';
import { ReactComponent as ExamSVG } from '@assets/icons/exam.svg';
import { ReactComponent as HatGraduationSVG } from '@assets/icons/hat-graduation-svg.svg';
import { ReactComponent as InstructionSVG } from '@assets/icons/instruction-icon.svg';
import { ReactComponent as LectureSVG } from '@assets/icons/lecture.svg';
import { ReactComponent as LinkSVG } from '@assets/icons/link.svg';
import { ReactComponent as LoadingSVG } from '@assets/icons/loading.svg';
import { ReactComponent as LychkaSVG } from '@assets/icons/lychka-icon.svg';
import { ReactComponent as LectureMenuSVG } from '@assets/icons/menu-1.svg';
import { ReactComponent as CheckRoundSVG } from '@assets/icons/notfication-recover-success.svg';
import { ReactComponent as NoticeSVG } from '@assets/icons/notice-icon.svg';
import { ReactComponent as NotificationInfoSVG } from '@assets/icons/notification-info-icon.svg';
import { ReactComponent as NotifyBarSVG } from '@assets/icons/notify-bar.svg';
import { ReactComponent as OkSVG } from '@assets/icons/ok-icon.svg';
import { ReactComponent as PDFSVG } from '@assets/icons/pdf.svg';
import { ReactComponent as PlayVideoSVG } from '@assets/icons/play-video-icon.svg';
import { ReactComponent as PlusSVG } from '@assets/icons/plus.svg';
import { ReactComponent as PlusCircleSVG } from '@assets/icons/pluscircle.svg';
import { ReactComponent as PptxSVG } from '@assets/icons/ppt.svg';
import { ReactComponent as PptSVG } from '@assets/icons/pptx.svg';
import { ReactComponent as RuTubeSVG } from '@assets/icons/rutube-svg.svg';
import { ReactComponent as SecondEditSVG } from '@assets/icons/second-edit-icon.svg';
import { ReactComponent as SendButtonSVG } from '@assets/icons/send-button.svg';
import { ReactComponent as SettingsSVG } from '@assets/icons/settings.svg';
import { ReactComponent as StarSVG } from '@assets/icons/star.svg';
import { ReactComponent as SuccessSVG } from '@assets/icons/success-icon.svg';
import { ReactComponent as TelegramSVG } from '@assets/icons/telegram-icon.svg';
import { ReactComponent as TestSVG } from '@assets/icons/test.svg';
import { ReactComponent as UserSVG } from '@assets/icons/user-icon.svg';
import { ReactComponent as VideoSVG } from '@assets/icons/video.svg';
import { ReactComponent as VKSVG } from '@assets/icons/vk-icon.svg';
import { ReactComponent as WarningTriangleSVG } from '@assets/icons/warning-triangle.svg';
import { ReactComponent as XLSSVG } from '@assets/icons/xls.svg';
import { ReactComponent as YouTubeSecondSVG } from '@assets/icons/youtube-svg.svg';
import { ReactComponent as YouTubeSVG } from '@assets/icons/youtube-svgrepo-com.svg';
import { ReactComponent as ZipSVG } from '@assets/icons/zip.svg';
import { ReactComponent as TeacherDisciplinesPlugSVG } from '@assets/image/no_teacher_disciplines.svg';
import { ReactComponent as TeacherTasksPlugSVG } from '@assets/image/teacher-tasks.svg';

import style from './icon.module.scss';
import { TypeIcon } from './type';

const cx = classNames.bind(style);

export interface IconUIProps {
    className?: string;
    iconElement?: ReactNode;
    typeIcon?: TypeIcon;
}

const IconUI: FC<IconUIProps> = ({ iconElement, typeIcon, className }) => {
    const renderIcon = () => {
        switch (typeIcon) {
            case 'add':
                return <PlusSVG />;
            case 'addCircle':
                return <PlusCircleSVG />;
            case 'arrowLink':
                return <ArrowLinkSVG className={style.arrow_link} />;
            case 'edit':
                return <EditSVG />;
            case 'settings':
                return <SettingsSVG />;
            case 'second-edit':
                return <SecondEditSVG />;
            case 'basket':
                return <BasketSVG />;
            case 'drag':
                return <DragAndDropSVG />;
            case 'document':
                return <DocumentSVG />;
            case 'download':
                return <DownloadSVG />;
            case 'link':
                return <LinkSVG />;
            case 'menu':
                return <DotsSVG />;
            case 'playVideo':
                return <PlayVideoSVG className={style.play_video} />;
            case 'youTube':
                return <YouTubeSVG />;
            case 'clip':
                return <ClipSVG />;
            case 'clip-green':
                return <ClipGreenSVG />;
            case 'next':
                return <NextSVG />;
            case 'prev':
                return <PrevSVG />;
            case 'arrow':
                return <ArrowSVG />;
            case 'left-arrow':
                return <ArrowLeftSVG className={cx(style['root__icon--black'], style.left_arrow)} />;
            case 'right-arrow':
                return <ArrowLeftSVG className={cx(style['root__icon--black'], style.right_arrow)} />;
            case 'camera':
                return <CameraSVG />;
            case 'camera-dark':
                return <CameraDarkSVG />;
            case 'close':
                return <CloseSVG />;
            case 'check':
                return <CheckboxSVG />;
            case 'page':
                return <PageSVG />;
            case 'fileDoc':
                return <DOCSVG />;
            case 'taskExam':
                return <ExamSVG />;
            case 'taskTst':
                return <TestSVG />;
            case 'videoLecture':
                return <VideoSVG />;
            case 'lecture':
                return <LectureSVG />;
            case 'filePdf':
                return <PDFSVG />;
            case 'fileXls':
                return <XLSSVG />;
            case 'filePpt':
                return <PptSVG />;
            case 'filePptx':
                return <PptxSVG />;
            case 'fileZip':
                return <ZipSVG />;
            case 'defaultFile':
                return <DefaultFileSVG />;
            case 'clock':
                return <ClockSVG />;
            case 'vk':
                return <VKSVG />;
            case 'telegram':
                return <TelegramSVG />;
            case 'youTubeSecond':
                return <YouTubeSecondSVG />;
            case 'ruTube':
                return <RuTubeSVG />;
            case 'ok':
                return <OkSVG />;
            case 'dzen':
                return <DzenSVG />;
            case 'calendar-light':
                return <CalendarLightSVG />;
            case 'calendar':
                return <CalendarSVG />;
            case 'book':
                return <BookSVG />;
            case 'user':
                return <UserSVG />;
            case 'lychka':
                return <LychkaSVG />;
            case 'instruction':
                return <InstructionSVG />;
            case 'date':
                return <DateSVG />;
            case 'star':
                return <StarSVG />;
            case 'hat-graduation':
                return <HatGraduationSVG />;
            case 'notification-info-icon':
                return <NotificationInfoSVG />;
            case 'attention-error':
                return <AttentionErrorSVG />;
            case 'check-round':
                return <CheckRoundSVG />;
            case 'notify-bar':
                return <NotifyBarSVG />;
            case 'clock-red':
                return <ClockRedSVG />;
            case 'teacher-disciplines-plug':
                return <TeacherDisciplinesPlugSVG />;
            case 'teacher-tasks-plug':
                return <TeacherTasksPlugSVG />;
            case 'confirm-error':
                return <ConfirmErrorSVG />;
            case 'send-button':
                return <SendButtonSVG />;
            case 'loading':
                return <LoadingSVG />;
            case 'success-icon':
                return <SuccessSVG />;
            case 'menu-2':
                return <LectureMenuSVG />;
            case 'pagination-next':
                return <PaginationNextSVG />;
            case 'pagination-prev':
                return <PaginationPrevSVG />;
            case 'notice':
                return <NoticeSVG />;
            case 'warning-triangle':
                return <WarningTriangleSVG />;
            case 'chat-clip':
                return <ChatClipSVG />;
            case 'competencies':
                return <Competencies />;
            default:
                return iconElement;
        }
    };

    const marginCl = typeIcon === 'menu' ? 'root--mr' : null;

    const renderContainer = () => {
        return iconElement || typeIcon ? (
            <div className={cx('root', className, marginCl)}>
                {renderIcon()}
            </div>
        ) : null;
    };

    return <>{renderContainer()}</>;
};

export default IconUI;
