import { useEffect, useMemo, useState } from 'react';

import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import Main from '@components/Main';
import { IProgrammContext } from '@layouts/Methodist/StudyProgrammesLayout/StudyProgrammesLayout';
import { EnumMaterialsType, Page } from '@store/api/apiTypes';
import { useUpdateProgrammePageBlockMutation } from '@store/api/programmesApi';
import { IUpdateProgrammPage } from '@type/studyProgrammes';
import CreatePageForm from '@views/methodist/Materials/Course/CreatePageForm';
import { CreatePageFormData } from '@views/methodist/Materials/Course/CreatePageForm/type';

import style from './StudyProgrammesEditPage.module.scss';


const StudyProgrammesEditPage = () => {
    const navigate = useNavigate();
    const { uuidSection, uuidPage } = useParams();
    const { programnInfo: { sections, uuidGroup } } = useOutletContext<IProgrammContext>();
    const [updatePage, { isSuccess }] = useUpdateProgrammePageBlockMutation();

    const [defaultValuesForm, setDefaultValuesForm] = useState<CreatePageFormData>({
        name: '',
        content: '',
        accessLimitation: false,
        blockedGroups: []
    });

    const actualPage = useMemo(() => {
        const actualSection = sections.find(section => section.uuid === uuidSection);
        // TODO: remove text transform
        return actualSection?.materials.find(material => material.type.toLowerCase() === EnumMaterialsType.Page.toLowerCase() 
            && material.uuid === uuidPage) as Page;
    }, [uuidSection, uuidPage]);

    useEffect(() => {
        if (actualPage) {
            setDefaultValuesForm({
                name: actualPage.name,
                content: actualPage.content,
                accessLimitation: false,
                blockedGroups: []
            });
        }
    }, []);

    const submitFormHandler = (formData: CreatePageFormData) => {
        const transformedData: IUpdateProgrammPage = {
            uuid: uuidPage as string,
            uuidSection: uuidSection,
            name: formData.name,
            content: formData.content,
            // TODO: remove position
            position: actualPage.position,
            uuidGroup: uuidGroup
        };

        updatePage(transformedData);
    };

    useEffect(() => {
        if (isSuccess)
            navigate('./../../..');
    }, [isSuccess]);

    return (
        <Main>
            <div className={style.title_wrapper}>
                <ButtonIcon
                    typeIcon='arrowLink'
                    onClick={() => navigate('./../../..')}
                />
                <h1>Редактировать страницу</h1>
            </div>
            <CreatePageForm
                defaultValues={defaultValuesForm}
                submitFormHandler={submitFormHandler}
            />
        </Main>
    );
};

export default StudyProgrammesEditPage;
