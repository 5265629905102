import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { DateTime, Duration } from 'ts-luxon';

import { Column, Grid } from '@components/core/grid';
import IconUI from '@components/core/IconUI';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import GoBackLink from '@components/student/GoBackLink';
import { dateConvert, checkExpires, getDateDiff } from '@helpers/date';
import { getBorderScore } from '@helpers/scores';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useNavigationHistory } from '@hooks/useNavigationHistory';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { useGetSubscribedDisciplinesQuery } from '@store/api/groupsApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import EducationalMaterials from '@views/student/StudentCourse/EducationalMaterials';
import SectionsList from '@views/student/StudentCourse/SectionsList/SectionsList';
import { SkillsList } from '@views/student/StudentCourse/SkillsList';
import TeachingStaffCard from '@views/student/StudentCourse/TeachingStaffCard';

import DisciplineSticker from './DisciplineSticker';
import { MaterialsSidebar, SkillsSidebar, TeachersSidebar } from './Sidebars';
import style from './StudentCourse.module.scss';

const StudentCourse = () => {
    const lgDown = useBreakpoint('lg', 'down');
    const mdDown = useBreakpoint('md', 'down');

    const { uuidDiscipline } = useParams();
    //TODO: заменить на useGetDisciplineWithGradesByIdQuery
    const { data: disciplineData, isFetching, isSuccess } = useGetDisciplineByIdQuery(uuidDiscipline as string);
    const user = useAppSelector(selectApiUser);
    const deadlineExpiresDaysGap = { days: 7 };

    const getDefaultBackUrl = (): string => {
        switch (user?.role) {
            case Role.Teacher:
                return '/teaching';
            case Role.Student:
                return '/education';
            default: {
                if (disciplineData) {
                    return `/materials/${disciplineData.uuidCategory}`;
                }
                return '/materials';
            }
        }
    };

    const { toForBack } = useNavigationHistory(getDefaultBackUrl());

    //TODO: убрать запрос useGetSubscribedDisciplinesQuery, всю инфу получать из useGetDisciplineWithGradesByIdQuery
    const subscribedDisciplines = useGetSubscribedDisciplinesQuery((user && checkRole(Role.Student)) ? user.groupUUID : skipToken);
    const currentGroupDiscipline = subscribedDisciplines?.data?.find(discipline => discipline.disciplineUUID === disciplineData?.uuid);

    const localDeadline = currentGroupDiscipline?.deadline === '2100-01-01 01:01:01' ? '' : currentGroupDiscipline?.deadline;

    if (isFetching || subscribedDisciplines.isFetching) return <NewLoader />;

    const getPageTitle = () => {
        const disciplineName = disciplineData ? disciplineData.name : '';
        if (checkRole(Role.Methodist)) return `Предпросмотр курса – ${disciplineName}`;
        if (checkRole(Role.Student)) return `Обучение – ${disciplineName}`;
        if (checkRole(Role.Teacher)) return `Преподавание – Преподаваемые дисциплины: ${disciplineName}`;
    };

    const getMainColumnSize = () => {
        if (mdDown) return 12;
        if (lgDown) return 8;
        return 9;
    };

    const getSideColumnSize = () => {
        if (mdDown) return 0;
        if (lgDown) return 4;
        return 3;
    };

    const renderDeadlineAlert = () => {
        if (!currentGroupDiscipline) return null;

        const now = DateTime.now().toMillis();
        const isDeadlineEnd = now > dateConvert(currentGroupDiscipline.deadline).toMillis();
        const isDisciplineSuccess = currentGroupDiscipline.score && currentGroupDiscipline.score >= getBorderScore(currentGroupDiscipline.closeType);
        const isDeadlineExpires = user && checkExpires(currentGroupDiscipline.deadline, deadlineExpiresDaysGap);

        if (isDeadlineEnd && isDisciplineSuccess) {
            return (
                <div className={cx(style.deadline_alert, style['deadline_alert--success'])}>
                    <div className={style.deadline_alert__icon}>
                        <IconUI typeIcon="calendar" />
                    </div>
                    <div className={style.deadline_alert__description}>
                        <div className={style.deadline_alert__header}>
                            Срок сдачи завершен!
                        </div>
                        <div>
                            За подробностями обратитесь к методисту курса
                        </div>
                    </div>
                </div>
            );
        }

        if (isDeadlineEnd && !isDisciplineSuccess) {
            return (
                <div className={cx(style.deadline_alert, style['deadline_alert--doubt'])}>
                    <div className={style.deadline_alert__icon}>
                        <IconUI typeIcon="warning-triangle" />
                    </div>
                    <div>
                        <div className={style.deadline_alert__header}>
                            Срок сдачи завершен!
                        </div>
                        <div className={style.deadline_alert__description}>
                            У вас есть задолженность! За подробностями обратитесь к методисту курса.
                        </div>
                    </div>
                </div>
            );
        }

        if (isDeadlineExpires) {
            const timeLeft = Duration.fromMillis(
                getDateDiff(currentGroupDiscipline.deadline)).toFormat('dд. hч.');
            return (
                <div className={cx(style.deadline_alert, style['deadline_alert--expires'])}>
                    <div className={style.deadline_alert__icon}>
                        <IconUI typeIcon="warning-triangle" />
                    </div>
                    <div>
                        <div className={style.deadline_alert__header}>
                            До конца срока сдачи: {timeLeft}
                        </div>
                        <div className={style.deadline_alert__description}>
                            Не затягивайте с обучением
                            <br />
                            и не откладывайте отправку ответов на проверку на последний день .
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    };

    if (!isSuccess || (checkRole(Role.Student) && !subscribedDisciplines.isSuccess)) return null;

    return (
        <Main className={style.main} title={getPageTitle()}>
            <GoBackLink to={toForBack}>
                Назад
            </GoBackLink>

            <h1 className={style.title}>{disciplineData?.name}</h1>

            <RoleCheck roles={Role.Student}>
                {!!currentGroupDiscipline && (
                    <div className={style.stickers}>
                        {
                            localDeadline &&
                            <div className={style.sticker}>
                                <IconUI className={style.deadline_sticker} typeIcon="clock" />
                                {currentGroupDiscipline?.deadline ? 'Срок сдачи до:  ' +
                                    dateConvert(localDeadline).toFormat('dd.LL.yyyy') : ''}
                            </div>
                        }

                        {
                            !!currentGroupDiscipline.score &&
                            <DisciplineSticker
                                closeType={currentGroupDiscipline.closeType}
                                score={currentGroupDiscipline.score}
                                className={style.sticker}
                            />
                        }
                    </div>
                )}
            </RoleCheck>

            {mdDown && (
                <div className={style['sidebar-wrapper']}>
                    <RoleCheck roles={Role.Student}>
                        {renderDeadlineAlert()}
                    </RoleCheck>

                    <div className={style['sidebar-buttons-wrapper']}>
                        <TeachersSidebar
                            metodists={disciplineData.metodists}
                            teachers={disciplineData.teachers}
                            secondTeachers={disciplineData.doubles}
                        />
                        <MaterialsSidebar />
                        <SkillsSidebar uuidsDiscipline={disciplineData.uuid} />
                    </div>
                </div>
            )}

            <Grid className={style.content_wrapper} gridTemplateColumns="repeat(12, 1fr)" columnGap={!mdDown ? '32px' : 0}>
                <Column size={getMainColumnSize()}>
                    <SectionsList className={cx(style.section_list)} />
                </Column>

                {!mdDown && (
                    <Column size={getSideColumnSize()}>
                        <RoleCheck roles={Role.Student}>
                            {renderDeadlineAlert()}
                        </RoleCheck>

                        <div className={style.dashboard}>
                            {
                                disciplineData && (
                                    <TeachingStaffCard
                                        metodists={disciplineData.metodists}
                                        teachers={disciplineData.teachers}
                                        secondTeachers={disciplineData.doubles}
                                    />
                                )
                            }
                            <EducationalMaterials />
                            <SkillsList uuidsDiscipline={disciplineData.uuid} />
                        </div>
                    </Column>
                )}
            </Grid>
        </Main>
    );
};

export default StudentCourse;
