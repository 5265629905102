import React, { useMemo } from 'react';

import Checkbox from '@components/core/inputs/Checkbox';
import { SessionTypesWithLocale } from '@store/api/apiTypes';

import styles from './SemesterStep.module.scss';

interface SemesterStepProps {
    selectedSemesters: string[];
    setSemesters: (value: string[]) => void;
}

export const SemesterStep: React.FC<SemesterStepProps> = ({ selectedSemesters, setSemesters }) => {
    const semesters = useMemo(() => Object.entries(SessionTypesWithLocale).map(([value, label]) => ({
        value,
        label
    })), []);

    const onClick = (value: string) => {
        if (selectedSemesters.includes(value)) {
            setSemesters(selectedSemesters.filter(semester => semester !== value));
        } else {
            setSemesters([...selectedSemesters, value]);
        }
    };

    return (
        <div className={styles.container}>
            {semesters.map((semester) => (
                <div key={semester.value} className={styles.item} onClick={() => onClick(semester.value)}>
                    <div className={styles['checkbox-wrapper']}>
                        <Checkbox
                            checked={selectedSemesters.includes(semester.value)}
                            containerClassName={styles.checkbox}
                        />
                    </div>
                    <p>{semester.label}</p>
                </div>
            ))}

        </div>
    );
};
