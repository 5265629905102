import React, { createContext, FC, useContext, useState } from 'react';

export type DropMenuType = boolean;

type DropMenuContextType = {
    toggleMenu: DropMenuType;
    setToggleMenu: (toggleMenu: DropMenuType) => void;
};

const DropMenuContext = createContext<DropMenuContextType | null>(null);

interface IProps {
    children: React.ReactNode;
}

const DropMenuProvider: FC<IProps> = ({ children }) => {
    const [toggleMenu, setToggleMenu] = useState<DropMenuType>(false);

    return <DropMenuContext.Provider value={{ toggleMenu, setToggleMenu }}>{children}</DropMenuContext.Provider>;
};

const useDropMenuContext = () => {
    const context = useContext(DropMenuContext);
    if (!context) {
        throw new Error('Error in creating the context');
    }
    return context;
};

export { useDropMenuContext, DropMenuProvider };
