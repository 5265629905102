import { FC } from 'react';

import cx from 'classnames';

import { Linkify } from '@components/Linkify';

import style from './CSVPreview.module.scss';

interface ICSVPreview {
    content: string | undefined;
    className?: string;
}

const CSVPreview: FC<ICSVPreview> = ({ content, className }) => {
    if (!content) return null;

    return (
        <div className={cx(style.root, className)}>
            <Linkify content={content} />
        </div>
    );
};

export default CSVPreview;
