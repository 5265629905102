import { FC, useEffect, useState } from 'react';

import cx from 'classnames';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { SelectCheckbox } from '@components/core/inputs/SelectCheckbox';
import { OptionType } from '@components/core/inputs/SelectStyled/type';
import Loader from '@components/core/Loader';
import { yupResolver } from '@hookform/resolvers/yup';
import useDidUpdateEffect from '@hooks/useDidUpdate.hook';
import { IGroupDeadlinesApiResponse } from '@store/api/apiTypes';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { useGetDisciplineScoringMaterialsListQuery } from '@store/api/materialsApi';
import { addDeadlineItem, removeDeadlineItem } from '@store/features/deadlinesSlice';
import { useAppDispatch } from '@store/hooks';

import { DataListSelect } from '../DataListSelect';
import style from './DataListItem.module.scss';
export interface IDataListItemprops {
    defaultDeadlines: IGroupDeadlinesApiResponse[]
    disciplineUUID: string;
    isSetupMode: boolean;
}

export const DataListItem: FC<IDataListItemprops> = ({ defaultDeadlines, disciplineUUID, isSetupMode }) => {
    const dispatch = useAppDispatch();

    const disciplineData = useGetDisciplineByIdQuery(disciplineUUID);
    const materialsData = useGetDisciplineScoringMaterialsListQuery(disciplineUUID);

    const [checkedState, setChecked] = useState(false);
    const [defaultUpdate, setDefaultUpdate] = useState(false);

    const defaultData = materialsData.isSuccess ? materialsData.data.reduce((result, mat) => {
        if (defaultDeadlines?.find(item => item.materialUUID === mat.uuid)) {
            result.push({
                label: mat.name,
                value: mat.uuid
            } as OptionType);
        }
        return result;
    }, [] as OptionType[]) : [];


    const schema = yup.object({
        scoringMaterials: yup.array()
    });

    const methods = useForm({
        defaultValues: {
            scoringMaterials: isSetupMode ? defaultData : []
        },
        resolver: yupResolver(schema)
    });

    const { control, reset, watch } = methods;
    const watchMats = watch('scoringMaterials');

    useEffect(() => {
        if (!defaultUpdate && defaultData.length && isSetupMode) {
            reset({ scoringMaterials: defaultData });
            setDefaultUpdate(true);
        }
    }, [defaultData]);

    useDidUpdateEffect(() => {
        if (watchMats.length) {
            dispatch(addDeadlineItem({
                disciplineUUID: disciplineUUID,
                materials: watchMats.map((item: OptionType) => ({ disciplineUUID:  disciplineUUID, materialUUID: item.value as string }) )
            }));
        }

        if (!checkedState || !watchMats.length) {
            dispatch(removeDeadlineItem(disciplineUUID));
        }
    }, [watchMats, checkedState]);

    if (materialsData.isFetching) return <Loader />;
    if (!materialsData.isSuccess) return null;

    const selectOptions = isSetupMode ? materialsData.data.map(mat => {
        return {
            label: mat.name,
            value: mat.uuid
        } as OptionType;
    }) : defaultData;

    return <>
        {
            // If there is no materials with deadlines in discipline, then do not show discipline(in delete terms mode)
            (isSetupMode || defaultData.length) ?
                <div className={cx(style.item, checkedState && style.checked)}>
                    <div className={style.select}>
                        <DataListSelect onChange={(event) => setChecked(event.target.checked)} />
                    </div>

                    <div className={style.content}>
                        <div className={style.title}>{disciplineData.data?.name}</div>
                        {
                            checkedState && <div className={style.control}>
                                <SelectCheckbox
                                    isMulti
                                    control={control}
                                    options={selectOptions ? selectOptions : [] }
                                    placeholder="Выбрать: "
                                    name="scoringMaterials" />
                            </div>
                        }
                    </div>
                </div> : null
        }
    </>;
};
