import { FC, useState } from 'react';

import clsx from 'classnames';

import Button from '@components/core/inputs/Button';
import InputBase from '@components/core/inputs/InputBase/InputBase';
import Modal from '@components/Modal';
import { TestGetQuestionsFromBankForQuestion, TestWithQuestions } from '@store/api/apiTypes';
import { useGetQuestionsFromBankForQuestionQuery } from '@store/api/testsApi';

import style from './random_question_modal.module.scss';

interface IRandomQuestionModalProps {
    title?: string;
    options?: any;
    showModal: boolean;
    test: TestWithQuestions,
    handleSetQuestionsNum?: (num: number) => void,
    setShowModal: (flag: boolean) => void;
    course_id: string;
}

export const RandomQuestionModal: FC<IRandomQuestionModalProps> = ({
    title,
    showModal,
    setShowModal,
    test,
    handleSetQuestionsNum,
    course_id
}) => {

    const [questionsStr, setQuestionsStr] = useState(test.test.questionsNum.toString());
    const [error, setError] = useState<string | null>(null);
    const [questionsNum, setQuestionsNum] = useState(test.test.questionsNum);

    const questionsReq: TestGetQuestionsFromBankForQuestion = {
        filters: {
            limit: 0,
            offset: 0,
            search: ''
        },
        uuid: course_id,
        questionId: test.test.uuid
    };

    const { data: questionsData } = useGetQuestionsFromBankForQuestionQuery(questionsReq);

    const handleSetValue = (val: any) => {
        setQuestionsStr(val.target.value);

        const num = parseInt(val.target.value);
        if (isNaN(num)) {
            setError('Значение должно быть числом');
            setQuestionsNum(-1);
        } else if (num > (questionsData?.length || 0)) {
            setError(`Максимальное количество вопросов ${questionsData?.length}`);
        } else {
            setError(null);
            setQuestionsNum(num);
        }
    };

    const handleSetNum = () => {


        if (questionsNum != -1) {
            setShowModal(false);
            if (handleSetQuestionsNum != null)
                handleSetQuestionsNum(questionsNum);
        }
    };

    return (
        <Modal
            onClose={() => setShowModal(false)}
            isOpen={showModal}
            title={title}
        >
            <div className={style.container}>
                <div className={style.caption}>Количество вопросов</div>
                <InputBase
                    name="random-modal-input"
                    placeholder="Количество вопросов"
                    value={questionsStr}
                    error={!!error}
                    onInput={handleSetValue}
                />
                {!!error && <div className={clsx(style.caption, style.attention)}>{error}</div>}
            </div>

            <div className={style.footer_container}>
                <div className={style.btn_container}>
                    <Button
                        onClick={() => setShowModal(false)}
                        btnType={'secondary'}>
                        Отмена
                    </Button>

                    <Button type={'submit'} onClick={handleSetNum} disabled={!!error}>Добавить</Button>
                </div>
            </div>
        </Modal>
    );
};
