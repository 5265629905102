import { FC, useMemo } from 'react';

import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import Paper from '@components/core/styledWrappers/Paper';
import { capitalizeFirstLetter } from '@helpers/text';
import { Section } from '@store/api/apiTypes';
import { EnumMaterialsType } from '@store/api/apiTypes';
import { StudyProgrammeSection } from '@type/studyProgrammes';
import MaterialsItem from '@views/student/StudentCourse/Materials/MaterialsItem';

import style from './SectionCard.module.scss';

interface ISectionCard {
    section: Section | StudyProgrammeSection
    type?: 'course' | 'programmStudy'
}

const SectionCard: FC<ISectionCard> = ({ section, type = 'course' }) => {
    const { name, materials, description } = section;

    // TODO: shouldn't backend return already sorted sections?
    const orderedItems = useMemo(() => {
        if (type === 'programmStudy') {
            const sections = materials ? [...materials] : [];
            // @ts-ignore:next-line
            return sections.sort((a, b) => a.position && b.position ? a.position - b.position : 0);
        }

        return materials;
        
    }, [materials]);

    return (
        <li>
            <Paper className={style.paper}>
                <div className={style.info_wrapper}>
                    <h2>{name}</h2>
                    <CSVPreview content={description} />
                </div>
                {
                    orderedItems?.map((material, index) => {
                        const nextItem = orderedItems[index + 1];
                        const nextAdditional =
                            capitalizeFirstLetter(nextItem?.type) === EnumMaterialsType.File ||
                            capitalizeFirstLetter(nextItem?.type) === EnumMaterialsType.Link ||
                            capitalizeFirstLetter(nextItem?.type) === EnumMaterialsType.Page;

                        return (
                            <MaterialsItem
                                key={material.uuid}
                                material={material}
                                nextAdditional={nextAdditional}
                                type={type}
                            />
                        );
                    })
                }
            </Paper>
        </li>
    );
};

export default SectionCard;
