import { Navigate, Outlet, useOutlet } from 'react-router-dom';

const Reports = () => {
    const outlet = useOutlet();

    return (
        <>
            {
                outlet ? <Outlet /> : <Navigate to={'/'} />
            }
        </>
    );
};

export default Reports;
