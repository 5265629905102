import { FC, PropsWithChildren } from 'react';

import cx from 'classnames';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Paper from '@components/core/styledWrappers/Paper';
import { Column as IColumn } from '@components/ScoreCard/ScoreCard';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { DisciplineScoreItem } from '@type/scoreCard';
import { notFoundComponents } from '@views/student/RecordBook/RecordNotFound';

import { ScorecardList } from './ScorecardList';
import style from './UserRecordPanelTable.module.scss';


export interface TUserRecord {
    tab: {
        title: string;
        type: string;
    }
    items?: DisciplineScoreItem[]
}
export interface IUserRecordPanelTable {
    data?: TUserRecord[];
    columns: IColumn[];
    isStudent?: boolean;
}

const TabWrapper: FC<PropsWithChildren<{isStudent?: boolean}>> = ({ isStudent, children }) => {
    return isStudent
        ? <div className={style['tabs-wrapper']}>{children}</div>
        : <>{children}</>;
};

export const UserRecordPanelTable: FC<IUserRecordPanelTable> = ({
    data,
    columns,
    isStudent
}) => {
    const lgDown = useBreakpoint('lg', 'down');

    const renderTabs = () => {
        return data?.map((tab, index) => {
            return <Tab key={`${index}-tab`}>{tab.tab.title}</Tab>;
        });
    };

    const renderPanels = () => {

        return data?.map((unit, index) => {

            if (!unit.items || !unit.items.length) {
                return (
                    <TabPanel key={`panel-${index}`} className={style.notFound}>
                        {notFoundComponents[unit.tab.type]}
                    </TabPanel>
                );
            }

            return (
                <TabPanel key={`panel-${index}`}>
                    <ScorecardList
                        columns={columns}
                        data={unit.items}
                        isStudent={isStudent}
                    />
                </TabPanel>
            );
        });
    };

    return <div className={style.container}>
        <Tabs className={style.tabs}>
            <TabWrapper isStudent={isStudent}>
                <TabList className={cx(style.tabList, isStudent && style.studentTabList)}>
                    {renderTabs()}
                </TabList>
            </TabWrapper>

            {isStudent && !lgDown ? (
                <Paper className={style.paper}>
                    {renderPanels()}
                </Paper>
            ) : renderPanels()}
        </Tabs>
    </div>;
};
