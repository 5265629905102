import { Option } from '../index';

type OptionsMap = Record<Option['value'], Option>

export const getOptionsMap = (options: Option[]): OptionsMap => {
    const optionsMap: OptionsMap = {};

    options.forEach(option => {
        optionsMap[option.value] = option;
    });

    return optionsMap;
};
