import { FC } from 'react';

import { useParams } from 'react-router-dom';

import IconUI from '@components/core/IconUI';
import Loader from '@components/core/Loader';
import { ProgressIndicator } from '@components/core/ProgressBar/ProgressBar';
import { checkRole } from '@components/RoleCheck';
import { useGetStudentSkillsQuery  } from '@store/api/skillsApi';
import { Role } from '@store/Roles';

import { SkillCard } from './SkillCard';
import style from './skills.module.scss';

// Если передается uuidStudent => запрос производится методистом
const Skills: FC = () => {
    const { userId } = useParams();
    const isMethodist = checkRole([Role.Methodist]);

    const { data: skills, isLoading } = useGetStudentSkillsQuery(isMethodist && userId ? { uuidStudent: userId } : undefined);

    if (isLoading) return <Loader />;

    return (
        <div className={style.wrapper}>
            <h1 className={style.title}>Освоение образовательной программы</h1>
            <div className={style.skills_container}>
                <div className={style.board}>
                    <div className={style.name}>
                        <div>{isMethodist ? 'Компетенции' : 'Освоенные компетенции'}</div>
                    </div>
                    {skills?.overallProgress !== undefined && (
                        <div className={style.progress}>
                            <div className={style.name_progress}>
                                <IconUI typeIcon='hat-graduation' />
                                {`Освоено ${skills.overallProgress}%`}
                            </div>
                            <ProgressIndicator completed={skills.overallProgress} />
                        </div>
                    )}
                </div>
                <div className={style.cards_wrapper}>
                    {skills?.skills.map(skill => (
                        <SkillCard key={skill.uuid} skill={skill} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Skills;
