import { count } from 'console';

import React, { FC } from 'react';

import cx from 'classnames';

import { Grid } from '@components/core/grid';
import { IGridProps } from '@components/core/grid/type';

import style from './DataListHeader.module.scss';
export interface IDataListHeaderProps extends IGridProps {
    children?: React.ReactNode;
    checked?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    isHeaderLine?: boolean;
}

export const DataListHeader: FC<IDataListHeaderProps> = ({ 
    children, 
    className, 
    isHeaderLine, 
    ...props 
}) => {

    const columnsCount = React.Children.count(children);

    return <Grid 
        className={cx(style.header, isHeaderLine && style.isHeaderLine, className)} 
        gridTemplateColumns={`repeat(${columnsCount}, 1fr)`}
        {...props}>
        { 
            children
        }
    </Grid>;
};