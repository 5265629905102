import { FC, useEffect, useMemo, useState } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import { OptionType } from '@components/core/inputs/SelectStyled/type';
import Modal from '@components/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetDisciplinesByCategoryQuery, useLazyGetDisciplinesByIdQuery } from '@store/api/disciplinesApi';
import { useCreatePresetMutation } from '@store/api/skillsApi';
import { useGetSkillDisciplinesQuery } from '@store/api/skillsApi';

import style from './AddPresetModal.module.scss';

export const updatePresetSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    uuidsDiscipline: yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    }))
});

interface UpdatePresetModalProps {
    isOpen: boolean,
    closeModal: () => void
}

const AddPresetModal: FC<UpdatePresetModalProps> = ({ isOpen, closeModal }) => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const {
        data: disciplinesByCategory,
        isFetching: isDisciplinesByCategoryLoading,
        isError: isDisciplinesByCategoryError
    } = useGetDisciplinesByCategoryQuery(selectedCategory, { skip: !selectedCategory });
    const { data: getSkillDisciplinesData } = useGetSkillDisciplinesQuery(undefined);
    const [getDisciplinesByIdQuery, { isFetching: isDisciplinesByIdLoading, isError: isDisciplinesByIdError }] = useLazyGetDisciplinesByIdQuery();
    const methods = useForm({
        resolver: yupResolver(updatePresetSchema)
    });

    const { control, handleSubmit, watch } = methods;

    const watchedUuidsDiscipline = watch('uuidsDiscipline');

    const [createPreset, {
        isLoading: createPresetIsLoading,
        isSuccess: createPresetIsSuccess
    }] = useCreatePresetMutation();

    useEffect(()=>{
        if(createPresetIsSuccess) toast.success('Пресет успешно изменён');
    },[createPresetIsSuccess]);

    const onSubmit = async (formData: any) => {
        const transformedFormData = {
            name: formData.name,
            uuidsDiscipline: formData.uuidsDiscipline?.map((item:OptionType) => item.value)
        };
        await createPreset(transformedFormData);
        closeModal();
    };

    const disciplines = useMemo(() => getSkillDisciplinesData?.filter((discipline) => {
        if (!selectedCategory) {
            return true;
        }
        const index = disciplinesByCategory?.findIndex((categoryDiscipline) =>  discipline.uuidsRealDisciplines.includes(categoryDiscipline.uuid)) ?? -1;
        return index !== -1;

    }), [selectedCategory, disciplinesByCategory, getSkillDisciplinesData]);

    const isSelectDisabled = isDisciplinesByCategoryLoading || isDisciplinesByIdLoading || isDisciplinesByCategoryError || isDisciplinesByIdError;

    useEffect(() => {
        if (watchedUuidsDiscipline?.length && !selectedCategory) {
            const selectedDiscipline = getSkillDisciplinesData?.find((discipline) => discipline.uuid === watchedUuidsDiscipline[0].value);
            if (selectedDiscipline) {
                const realDisciplines = selectedDiscipline.uuidsRealDisciplines;
                getDisciplinesByIdQuery(realDisciplines).then((res) => setSelectedCategory(res.data?.[0].uuidCategory ?? ''));
            }
        }
        if (selectedCategory && watchedUuidsDiscipline?.length === 0) {
            setSelectedCategory('');
        }
    }, [watchedUuidsDiscipline]);

    return (
        <Modal
            onClose={closeModal}
            isOpen={isOpen}
            title="Добавить пресет"
        >

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className={style.container}>

                    <div className={style.list}>

                        <div className={style.input}>
                            <label htmlFor="name">Название</label>
                            <InputControl
                                name="name"
                                placeholder="Введите название"
                                id="name"
                            />
                        </div>

                        {disciplines &&
                            <div className={style.input}>
                                <label htmlFor="uuidsDiscipline">Плановые дисциплины</label>
                                <SelectStyled
                                    control={control}
                                    name="uuidsDiscipline"
                                    options={disciplines.map(discipline => ({ label:discipline.name, value: discipline.uuid }))}
                                    isMulti
                                    isDisabled={isSelectDisabled}
                                    placeholder="Выберите плановые дисциплины"
                                />
                            </div>
                        }
                    </div>

                    <div className={style.button_wrapper}>
                        <Button
                            onClick={closeModal}
                            btnType={'secondary'}
                        >
                            Отмена
                        </Button>
                        <Button
                            type='submit'
                            disabled={createPresetIsLoading}
                            typeIcon={createPresetIsLoading ? 'loading' : undefined}
                        >
                            Сохранить
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default AddPresetModal;













































