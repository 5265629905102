import React, { FC, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { Controller, FormProvider, useForm, useFormState } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import DuplicateEventPopup from '@components/core/Calendar/DuplicateEventPopup';
import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { FileUploadButtonControl } from '@components/core/inputs/FileUpload/FileUploadButtonControl';
import { TUploadFile } from '@components/core/inputs/FileUpload/type';
import HelperText from '@components/core/inputs/HelperText/HelperText';
import InputControl from '@components/core/inputs/InputControl';
import NewTreeSelectControl, { transformDataToOptions } from '@components/core/inputs/NewTreeSelectControl';
import { TreeSelectOptions } from '@components/core/inputs/Select/type';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import { StringOptionType } from '@components/core/inputs/SelectStyled/type';
import SwitcherControl from '@components/core/inputs/Switcher/SwitcherControl';
import Paper from '@components/core/styledWrappers/Paper';
import VideoPreview from '@components/core/VideoPreview/VideoPreview';
import { DPControl } from '@components/DatePicker/DPControl';
import Main from '@components/Main';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { maxSizeUploadFile } from '@constants/constants';
import { getBase64 } from '@helpers/base64';
import { dateConvert, datePrepareToSend } from '@helpers/date';
import { isStorageFile } from '@helpers/typeCheck';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { fileTypes, ICategory, IStorageFile } from '@store/api/apiTypes';
import { useDeleteEventMutation } from '@store/api/eventApi';
import { useUploadFileMutation } from '@store/api/storageApi';
import { CreateEventFormData } from '@views/methodist/Events/CreateEvent/CreateBasicEvent/CreateEventForm/type';
import { NavigationEventState } from '@views/methodist/Events/CreateEvent/CreateEvent';
import UploadVideoBlock from '@views/methodist/Materials/Course/VideoLectures/UploadVideoBlock';

import style from './CreateEventForm.module.scss';


export const createEventSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    discipline: yup.string().required(''),
    teacher: yup.object({ label: yup.string().required(), value: yup.string().required() }),
    event: yup.object({ label: yup.string(), value: yup.string() }),
    date: yup.string().required(''),
    hours: yup.number().typeError('').min(0, '').nullable(),
    minutes: yup.number().typeError('').max(59, '').min(0, '').nullable(),
    fileUpload: yup.array(yup.mixed().label('File')).max(10, 'Максимальное кол-во файлов 10'),
    link: yup.string().url('Введите ссылку!').nullable(),
    videoLink: yup.string(),
    accessLimitation: yup.boolean(),
    blockedGroups: yup.array().of(yup.string().required()).when('accessLimitation', {
        is: true,
        then: yup.array().min(1, 'Выберите группу')
    })
});

interface ICreateEventActiveForm {
    defaultValues: CreateEventFormData;
    getFormData: (formData: CreateEventFormData) => void;
    teacherOptions: StringOptionType[];
    groupOptions: TreeSelectOptions;
    categoriesData: ICategory[];
}

const CreateEventForm: FC<ICreateEventActiveForm> = ({
    defaultValues,
    getFormData,
    teacherOptions,
    groupOptions,
    categoriesData
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const navigateState = location.state as NavigationEventState;

    const disciplineOptions = useMemo(() => transformDataToOptions(categoriesData, {
        value: 'uuid',
        label: 'name',
        children: 'children'
    }), [categoriesData]);

    const {
        name,
        discipline,
        event,
        fileUpload,
        hours,
        link,
        videoLink,
        minutes,
        accessLimitation,
        blockedGroups,
        teacher,
        date
    } = defaultValues;

    const [uploadFile] = useUploadFileMutation();
    const [deleteEvent, { isError: deleteErr, isSuccess: deleteSuc }] = useDeleteEventMutation();
    const [isOpenVideoBlock, setIsOpenVideoBlock] = useState<boolean>(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const methods = useForm({
        defaultValues,
        resolver: yupResolver(createEventSchema)
    });

    const { control, setValue, handleSubmit, reset, watch, trigger } = methods;
    const { errors } = useFormState({ control });
    const watchVideoLink = watch('videoLink');

    useEffect(() => {
        setValue('name', name);
        setValue('blockedGroups', blockedGroups);
        setValue('discipline', discipline);
        setValue('event', event);
        setValue('fileUpload', fileUpload);
        setValue('hours', hours);
        setValue('minutes', minutes);
        videoLink && setValue('videoLink', videoLink);
        setValue('link', link);
        setValue('accessLimitation', accessLimitation);
        setValue('teacher', teacher);
        setValue('date', date);
    }, [defaultValues]);

    const onBack = () => {
        if (location.state) {
            const newNavigateOptions = navigateState.today ? { state: { today: navigateState.today } } : {};
            navigate(navigateState.pathname, newNavigateOptions);
        } else {
            navigate('/events');
        }
    };

    const onSubmit = async (formData: CreateEventFormData) => {
        let transformedData = { ...formData };

        const uuidsFile: string[] = [];
        const uploadFileHandler = async (file: IStorageFile | TUploadFile) => {
            if (file) {
                if (!isStorageFile(file)) {
                    const b64 = await getBase64(file);
                    const uploadedFile = await uploadFile({
                        file: b64 as string,
                        meta: {
                            filename: file.name,
                            type: fileTypes.Public
                        }
                    }).unwrap();
                    uuidsFile.push(uploadedFile.uuid);
                } else {
                    uuidsFile.push(file.uuid);
                }
            }
        };

        if (formData.fileUpload) {
            for await (const file of formData.fileUpload) {
                await uploadFileHandler(file);
            }
        }

        if (formData.date !== date) {
            transformedData = {
                ...transformedData, date: datePrepareToSend(formData.date) || formData.date
            };
        }

        transformedData = {
            ...transformedData, fileUpload: uuidsFile
        };

        if (!transformedData.accessLimitation) {
            delete transformedData.blockedGroups;
        }

        getFormData(transformedData);
        onBack();
    };

    useEffect(() => {
        if (deleteSuc) {
            onBack();
        }
    }, [deleteSuc]);


    const resetFormHandler = () => {
        reset(defaultValues);
        onBack();
    };

    const watchAccessLimitation = watch('accessLimitation');

    const onDateSubmit = (dateToSubmit: string) => {
        setValue('date', dateToSubmit, { shouldValidate: true });
    };

    const setVideoLinkHandler = async (linkToRecords: string) => {
        setValue('videoLink', linkToRecords);
        await trigger('videoLink');
        if (!errors?.videoLink) setIsOpenVideoBlock(false);
    };

    const duplicateEventHandler = () => {
        const newNavigateState = {
            state: {
                pathname: location.pathname,
                currentEvent: navigateState?.currentEvent,
                actionType: 'duplicate'
            }
        };

        if (navigateState.actionType === 'edit') {
            navigate('.', newNavigateState);
            setShowDuplicateModal(false);
        } else {
            navigate('create-event', newNavigateState);
        }
    };

    return (
        <Main>
            <Grid>
                <Column size={8}>
                    <div className={style.title_wrap}>
                        <div className={style.title_wrapper}>
                            <ButtonIcon
                                typeIcon="arrowLink"
                                onClick={onBack}
                            />
                            <h1>{name || 'Добавить мероприятие'}</h1>
                        </div>
                        {
                            navigateState?.actionType === 'edit' && (
                                <ButtonIcon
                                    className={style.delete_btn}
                                    typeIcon={'basket'}
                                    onClick={() => setShowDeleteModal(true)}
                                />
                            )
                        }
                    </div>
                    <Paper className={style.paper}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormProvider {...methods}>
                                <div>
                                    <div className={cx(style.fieldset, style['fieldset--border_line'])}>
                                        <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                            <label htmlFor="field-title-id">
                                                Название*
                                            </label>
                                            <InputControl
                                                name="name"
                                                placeholder="Название"
                                                id="field-name-id"
                                            />
                                        </div>
                                        <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                            <p>Дисциплина</p>

                                            <NewTreeSelectControl
                                                className={style['tree-select']}
                                                classes={{
                                                    input: style['tree-select__input'],
                                                    inputIcon: style['tree-select__icon']
                                                }}
                                                name="discipline"
                                                options={disciplineOptions}
                                                placeholder="Укажите дисциплину"
                                            />
                                        </div>
                                        <div className={cx(
                                            style.input_wrapper,
                                            style.input_wrapper_section_end,
                                            style['input_wrapper--center_content']
                                        )}>
                                            <p>Преподаватель</p>
                                            <SelectStyled
                                                name="teacher"
                                                control={control}
                                                options={teacherOptions}
                                                placeholder="Укажите преподавателя"
                                            />
                                        </div>
                                        <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                            <p>Тип события</p>
                                            <SelectStyled
                                                name="event"
                                                control={control}
                                                options={[{ label: 'Вебинар', value: 'webinar' }]}
                                                isSearchable={false}
                                                styled={{ width: '200px' }}
                                                isDisabled={true}
                                            />
                                        </div>
                                        <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                            <p>Дата и время проведения</p>
                                            <div className={style.date_wrapper}>
                                                <DPControl
                                                    onSubmit={onDateSubmit}
                                                    defaultValue={date ? dateConvert(date).toFormat('yyyy-LL-dd HH:mm:ss') : ''}
                                                    variant="date"
                                                    isButton={false}
                                                />
                                            </div>
                                        </div>
                                        <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                            <label htmlFor="field-hours-id">
                                                Продолжительность
                                            </label>
                                            <div className={style.duration_wrapper}>
                                                <div className={style.short_input}>
                                                    <InputControl
                                                        type="number"
                                                        name="hours"
                                                        id="field-hours-id"
                                                    />
                                                </div>
                                                <p>часов</p>
                                                <div className={style.short_input}>
                                                    <InputControl
                                                        type="number"
                                                        name="minutes"
                                                    />
                                                </div>
                                                <p>минут</p>
                                            </div>
                                        </div>
                                        <div className={cx(
                                            style.input_wrapper,
                                            style.input_wrapper_section_end,
                                            style['input_wrapper--center_content']
                                        )}>
                                            <p>Прикрепленный файл</p>
                                            <FileUploadButtonControl
                                                name="fileUpload"
                                                control={control}
                                                withoutCheckControl
                                                isMulti={true}
                                                maxSizeFile={maxSizeUploadFile}
                                                className={style.file_wrapper}
                                            />
                                        </div>
                                        <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                            <label htmlFor="field-link-id">
                                                Ссылка на онлайн-встречу
                                            </label>
                                            <InputControl
                                                name="link"
                                                placeholder="Добавить ссылку"
                                                id="field-link-id"
                                            />
                                        </div>
                                        <div className={cx(
                                            style.input_wrapper,
                                            style.video_wrapper,
                                            style['input_wrapper--center_content']
                                        )}>
                                            <label htmlFor="field-title-id">
                                                Видео
                                            </label>
                                            <div className={style.preview_video_wrapper}>
                                                {
                                                    (watchVideoLink && !isOpenVideoBlock && !errors?.videoLink) ? (
                                                        <>
                                                            <VideoPreview
                                                                className={style.preview_video}
                                                                link={watchVideoLink}
                                                                setPlay={true}
                                                                videoName={name}
                                                            />
                                                            <div className={style.preview_button_wrapper}>
                                                                <ButtonIcon
                                                                    typeIcon="edit"
                                                                    onClick={() => setIsOpenVideoBlock(true)}
                                                                    className={style.preview_button}
                                                                />
                                                                <ButtonIcon
                                                                    typeIcon="basket"
                                                                    onClick={() => setValue('videoLink', '')}
                                                                    className={style.preview_button}
                                                                />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Controller
                                                                render={({ field: { value } }) => (
                                                                    <UploadVideoBlock
                                                                        setVideoLinkHandler={setVideoLinkHandler}
                                                                        defaultValue={value || ''}
                                                                        error={!!errors?.videoLink}
                                                                    />
                                                                )}
                                                                name="videoLink"
                                                                control={control}
                                                            />
                                                            {errors?.videoLink && <HelperText type={'error'} message={errors?.videoLink?.message || ''} />}
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.fieldset} style={{ paddingTop: '24px' }}>
                                        <div className={style.input_wrapper}>
                                            <p>Доступ ограничен</p>
                                            <div className={style.permission_block_wrapper}>
                                                <SwitcherControl
                                                    name="accessLimitation"
                                                    control={control}
                                                />
                                                {watchAccessLimitation && (
                                                    <div className={style.select_list_wrapper}>
                                                        <NewTreeSelectControl
                                                            className={style.select}
                                                            name="blockedGroups"
                                                            options={groupOptions}
                                                            isMulti={true}
                                                            placeholder="Выберите группы"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={style.input_wrapper}>
                                            <div></div>
                                            <div className={style.form_content__button_wrapper}>
                                                <div className={style.form_content__btn_wrapper}>
                                                    <Button type="submit">Сохранить</Button>
                                                    <Button
                                                        btnType="secondary"
                                                        onClick={resetFormHandler}
                                                    >
                                                        Отмена
                                                    </Button>
                                                </div>
                                                {
                                                    navigateState?.actionType === 'edit' && (
                                                        <Button
                                                            btnType="outline"
                                                            onClick={() => setShowDuplicateModal(true)}
                                                        >
                                                            Дублировать
                                                        </Button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FormProvider>
                        </form>
                    </Paper>
                </Column>
            </Grid>
            <DeleteItemModal
                isOpen={showDeleteModal}
                onClose={() => {
                    setShowDeleteModal(false);
                }}
                onDelete={() => deleteEvent(navigateState?.currentEvent?.eventUuid as string)}
                itemName={name}
                itemType="event"
            />
            <DuplicateEventPopup
                isOpen={showDuplicateModal}
                onClose={() => {
                    setShowDuplicateModal(false);
                }}
                onDuplicate={duplicateEventHandler}
            />
        </Main>
    );
};

export default CreateEventForm;
