import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { UpdatePageData } from '@store/api/apiTypes';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useGetPageByIdQuery, useUpdatePageMutation } from '@store/api/pageApi';
import CreatePageForm from '@views/methodist/Materials/Course/CreatePageForm';
import { CreatePageFormData } from '@views/methodist/Materials/Course/CreatePageForm/type';

import style from './EditPage.module.scss';


const EditPage = () => {
    const navigate = useNavigate();
    const { uuidPage, uuidSection } = useParams();
    const pageData = useGetPageByIdQuery(uuidPage ? uuidPage : skipToken);
    const [fetchUpdatePage, { isSuccess }] = useUpdatePageMutation();
    const groupsTree = useGetGroupsTreeQuery('');

    const [defaultValuesForm, setDefaultValuesForm] = useState<CreatePageFormData>({
        name: '',
        content: '',
        accessLimitation: false,
        blockedGroups: []
    });

    useEffect(() => {
        if (!pageData.isFetching && pageData.isSuccess) {
            setDefaultValuesForm({
                name: pageData.data.name,
                content: pageData.data.content,
                accessLimitation: pageData.data.blockedGroups && pageData.data.blockedGroups.length > 0,
                blockedGroups: pageData.data.blockedGroups || []
            });
        }
    }, [pageData.isSuccess, pageData.isFetching]);

    const submitFormHandler = (formData: CreatePageFormData) => {

        const transformedData: UpdatePageData = {
            uuid: uuidPage as string,
            uuidParent: pageData.isSuccess ? pageData.data.uuidParent : '',
            name: formData.name,
            content: formData.content,
            blockedGroups: formData.blockedGroups || []
        };
        fetchUpdatePage(transformedData);
    };

    useEffect(() => {
        if (pageData.isError) {
            navigate(uuidSection ? './../../..' : './../..');
        }
    }, [pageData.isError]);

    useEffect(() => {
        if (isSuccess)
            navigate(uuidSection ? './../../..' : './../..');
    }, [isSuccess]);

    if (groupsTree.isFetching || pageData.isFetching) return <NewLoader />;

    return (
        <Main>
            <div className={style.title_wrapper}>
                <ButtonIcon
                    typeIcon={'arrowLink'}
                    onClick={() => navigate(uuidSection ? './../../..' : './../..')}
                />
                <h1>Редактировать страницу</h1>
            </div>
            <CreatePageForm
                defaultValues={defaultValuesForm}
                submitFormHandler={submitFormHandler}
                groups={groupsTree.data}
            />
        </Main>
    );
};

export default EditPage;
