import React, { PropsWithChildren, useEffect } from 'react';

import { Helmet } from 'react-helmet';

import { useChat } from '@components/Chat/context';
import { joinRoomsIds } from '@helpers/chat';
import { selectRoomsWithNewMessage } from '@store/features/chatSlice';
import { useAppSelector } from '@store/hooks';

interface HelmetComponentProps {
    title?: string;
}

export const HelmetComponent: React.FC<PropsWithChildren<HelmetComponentProps>> = ({ title, children }) => {
    const label = title ? `${title} | ВЕИП` : 'ВЕИП';

    const { roomsWithNewMessages } = useChat();
    const storeRoomsWithNewMessages = useAppSelector(selectRoomsWithNewMessage);
    const joinedRoomsWithNewMessages = joinRoomsIds(storeRoomsWithNewMessages, roomsWithNewMessages);

    const renderLabel = () => {
        if (joinedRoomsWithNewMessages.length) {
            const getMessagesText = () => {
                if (joinedRoomsWithNewMessages.length === 1) return '(1 непрочитанный диалог)';
                if (joinedRoomsWithNewMessages.length > 1 && joinedRoomsWithNewMessages.length < 5) {
                    return `(${joinedRoomsWithNewMessages.length} непрочитанных диалога)`;
                }
                return `(${joinedRoomsWithNewMessages.length} непрочитанных диалогов)`;
            };
            return `${getMessagesText()} ${label}`;
        }
        return label;
    };

    useEffect(() => {
        document.title = renderLabel();
    }, [joinedRoomsWithNewMessages]);

    return (
        <Helmet>
            <title>{renderLabel()}</title>
            {children}
        </Helmet>
    );
};
