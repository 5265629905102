import { FC, useState } from 'react';

import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import Loader from '@components/core/Loader';
import Modal from '@components/Modal';

import style from './ExportFileModal.module.scss';


interface IExportFileModal {
    isOpen: boolean;
    isErr: boolean;
    isSuccess: boolean;
    itemName: string;
    onClose: () => void;
    stopExport: () => void;
}

const ExportFileModal: FC<IExportFileModal> = ({ isOpen, onClose, isErr, itemName, isSuccess, stopExport }) => {
    const [isStopped, setIsStopped] = useState(false);

    const abort = () => {
        onClose();
        setIsStopped(true);
        stopExport();
    };

    const renderContent = () => {
        if (isErr && !isStopped) return (
            <div className={style.content}>
                <IconUI typeIcon="attention-error" />
                <p className={style.modal__text}>
                    Во время экспорта произошла ошибка.
                    <br />
                    Пожалуйста, попробуйте ещё раз.
                </p>
            </div>
        );

        if (isSuccess) return (
            <>
                <div className={style.content}>
                    <IconUI typeIcon="check-round" />
                    <p className={style.modal__text}>
                        Экспорт успешно завершен.
                    </p>
                </div>
            </>
        );

        return (
            <>
                <div className={style.content}>
                    <Loader className={style.loader} />
                    <p className={style.modal__text}>
                        Экспорт займет некоторое время.
                        <br />
                        Пожалуйста, не обновляйте страницу.
                    </p>
                </div>
                <div className={style.button_wrapper}>
                    <Button
                        onClick={abort}
                        btnType="secondary"
                    >
                        Отмена
                    </Button>
                </div>
            </>
        );
    };

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title={isErr ? 'Экспорт не завершен!' : `Экспорт ${itemName}`}
        >
            {renderContent()}
        </Modal>
    );
};

export default ExportFileModal;
