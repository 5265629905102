import { FC } from 'react';

import cx from 'classnames';

import IconUI from '@components/core/IconUI';
import Checkbox from '@components/core/inputs/Checkbox';
import Radio from '@components/core/inputs/Radio';
import SelectBase from '@components/core/inputs/SelectStyled/SelectBase';
import { StringOptionType } from '@components/core/inputs/SelectStyled/type';
import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import { questionAnswerHeaderByType, isChecked, getToMathAnswersOptions } from '@helpers/test';
import { EnumTestAnswer, StudentTestQuestionAnswerCombined, StudentTestQuestionWithAnswers, passTestRequest } from '@store/api/apiTypes';

import style from './NewTestQuestion.module.scss';


type INewTestQuestion = {
    studQuestion: StudentTestQuestionWithAnswers;
    index: number;
    testSnapshot: passTestRequest[];
    setTestSnapshot: (results: passTestRequest[]) => void;
    markQuestions: string[];
    setMarkQuestions: (questions: string[]) => void;
};

const NewTestQuestion: FC<INewTestQuestion> = ({ studQuestion, index, testSnapshot, setTestSnapshot, markQuestions, setMarkQuestions }) => {

    // Выбор ответа типа SINGLE или MULTIPLY
    const addSimpleAnswer = (e: React.ChangeEvent<HTMLInputElement>, questionSnapshot: passTestRequest, answerUuid: string) => {
        let currentQuestion = {} as passTestRequest;

        if (questionSnapshot.type === EnumTestAnswer.SINGLE) {
            currentQuestion = { ...questionSnapshot, uuidsAnswers: [answerUuid] };
        } else {
            currentQuestion = {
                ...questionSnapshot,
                uuidsAnswers: e.target.checked ?
                    [...questionSnapshot.uuidsAnswers!, answerUuid] :
                    questionSnapshot.uuidsAnswers?.filter(answer => answer !== answerUuid)
            };
        }

        setTestSnapshot(testSnapshot.map(question => {
            return currentQuestion.uuidQuestion === question.uuidQuestion ? currentQuestion : question;
        }));
    };

    // Выбор ответа типа TO_MATCH
    const addSelectAnswer = (selectAnswer: StringOptionType, questionSnapshot: passTestRequest, subAnswerUuid: string) => {
        const currentQuestion = {
            ...questionSnapshot,
            toMatchAnswers: questionSnapshot.toMatchAnswers?.map((answer) => {
                return answer.uuidAnswer === subAnswerUuid ? { ...answer, subAnswer: selectAnswer.label } : answer;
            }) || questionSnapshot.toMatchAnswers
        };

        setTestSnapshot(testSnapshot.map(question => {
            return currentQuestion.uuidQuestion === question.uuidQuestion ? currentQuestion : question;
        }));
    };

    // Получение выбранных ответов TO_MATCH
    const selectedSubAnswers = (questionSnapshot: passTestRequest, subAnswerUuid: string) => {
        const currentSubAnswer = questionSnapshot?.toMatchAnswers?.find(subAnswer => subAnswer.uuidAnswer === subAnswerUuid);

        if (currentSubAnswer?.uuidAnswer && currentSubAnswer?.subAnswer) {
            return { label: currentSubAnswer.subAnswer, value: currentSubAnswer.subAnswer };
        }
        return null;
    };

    // Получить определенный вопрос из снепшота по его uuid
    const getQuestionFromSnapshot = (uuidQuestion: string) => {
        return testSnapshot.find(question => question.uuidQuestion === uuidQuestion) || {} as passTestRequest;
    };

    // Отмечаем ответы, согласно снепшоту
    const getInputs = (testQuestions: StudentTestQuestionAnswerCombined[], type: EnumTestAnswer, questionSnapshot: passTestRequest) => {
        switch(type) {
            case EnumTestAnswer.MULTIPLY:
                return testQuestions.map((testQuestion, inputIndex) => {
                    return (
                        <div key={inputIndex} className={style.answers_input}>
                            <Checkbox
                                checked={isChecked(questionSnapshot,  testQuestion.answerMultiple.uuid)}
                                onChange={(e) => addSimpleAnswer(e, questionSnapshot, testQuestion.answerMultiple.uuid)}
                                label={testQuestion.answerMultiple.text}
                            />
                        </div>
                    );
                });

                break;

            case EnumTestAnswer.SINGLE:
                return testQuestions.map((testQuestion, inputIndex) => {
                    return (
                        <div key={inputIndex} className={style.answers_input}>
                            <Radio
                                checked={isChecked(questionSnapshot, testQuestion.answerSimple.uuid)}
                                onChange={(e) => addSimpleAnswer(e, questionSnapshot, testQuestion.answerSimple.uuid)}
                                group={questionSnapshot.uuidQuestion}
                            >
                                { testQuestion.answerSimple.text }
                            </Radio>
                        </div>
                    );
                });

                break;

            case EnumTestAnswer.TO_MATCH:
                return (
                    testQuestions.map((testQuestion, inputIndex) => {
                        return (
                            <div key={inputIndex} className={cx(style.answers_input, style.answers_select_wrapper)}>
                                <p>{testQuestion.answerToMatch.subQuestion}</p>
                                <SelectBase
                                    className={style.answers_select}
                                    theme="dark"
                                    name="select"
                                    options={getToMathAnswersOptions(testQuestions)}
                                    onChange={(subAnswer: StringOptionType) =>
                                        addSelectAnswer(subAnswer, questionSnapshot, testQuestion.answerToMatch.uuid)
                                    }
                                    placeholder="Выберите вариант"
                                    defaultValue={selectedSubAnswers(questionSnapshot, testQuestion.answerToMatch.uuid)}
                                />
                            </div>
                        );
                    })
                );

            default:
                return null;
        }
    };

    // Добавить закладку
    const toggleMark = (markUuid: string) => {
        setMarkQuestions(markQuestions.includes(markUuid)
            ? markQuestions.filter(qUuid => qUuid !== markUuid)
            : [...markQuestions, markUuid]
        );
    };

    return (
        <div id={`${(studQuestion.question.uuid)}`} key={studQuestion.question.uuid}
            className={cx(
                style.question,
                markQuestions.includes(studQuestion.question.uuid) && style.question_active
            )}
        >
            <div className={style.question_header}>
                Вопрос { ++index }
            </div>
            <div className={style.question_description}>
                <CSVPreview content={studQuestion.question.question} className={style['csv-preview']} />
            </div>
            <div>
                <div className={style.answers_header}>
                    { questionAnswerHeaderByType(studQuestion.question.type as EnumTestAnswer) }
                </div>
                <div className={style.answers_wrapper}>
                    {
                        getInputs(
                            studQuestion.answers,
                            studQuestion.question.type as EnumTestAnswer,
                            getQuestionFromSnapshot(studQuestion.question.uuid)
                        )
                    }
                </div>
            </div>
            <div onClick={() => toggleMark(studQuestion.question.uuid)}>
                <IconUI typeIcon="star" className={style.question_icon} />
            </div>
        </div>
    );
};

export default NewTestQuestion;
