import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { LinkUI } from '@components/core/LinkUI';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useGetSectionByIdQuery, useUpdateSectionMutation } from '@store/api/sectionsApi';
import SectionForm from '@views/methodist/Materials/Course/EditCourse/Structure/SectionForm';
import { publishedSelectData } from '@views/methodist/Materials/Course/EditCourse/Structure/SectionForm/SectionForm';
import { SectionFormDataType } from '@views/methodist/Materials/Course/EditCourse/Structure/SectionForm/types';

import style from './editSection.module.scss';


const EditSection = () => {
    const navigate = useNavigate();
    const { uuidSection } = useParams();
    const sectionRequest = useGetSectionByIdQuery(uuidSection!);
    const [fetchUpdateSection, { data, isLoading, isError, error, isSuccess }] = useUpdateSectionMutation();

    const [defaultValuesForm, setDefaultValuesForm] = useState<SectionFormDataType>({
        name: '',
        description: '',
        published: publishedSelectData[0],
        accessLimitation: false,
        blockedGroups: []
    });
    const groupsTree = useGetGroupsTreeQuery('');

    useEffect(() => {
        if (sectionRequest.isSuccess)
            setDefaultValuesForm({
                name: sectionRequest.data.name,
                description: sectionRequest.data.description,
                published: sectionRequest.data.published ? publishedSelectData[0] : publishedSelectData[1],
                accessLimitation: sectionRequest.data.blockedGroups.length > 0,
                blockedGroups: sectionRequest.data.blockedGroups
            });
    }, [sectionRequest.isSuccess, sectionRequest.isFetching]);

    const submitFormHandler = (formData: SectionFormDataType) => {
        const updateSectionData = {
            uuid: uuidSection!,
            name: formData.name!,
            description: formData.description!,
            published: formData.published.value!,
            position: sectionRequest.data?.position,
            blockedGroups: formData.blockedGroups || []
        };

        // ToDo: здесь нужно погуглить, как в схеме для формы отрубать undefined и null, при её создании
        fetchUpdateSection(updateSectionData);
    };

    useEffect(() => {
        if (isSuccess) {
            navigate('./../..');
        }
    }, [isSuccess]);

    if (isLoading || sectionRequest.isFetching || groupsTree.isFetching) return <NewLoader />;

    return (
        <Main>
            <div className={style.title_wrapper}>
                <LinkUI
                    to={'./../..'}
                    typeIcon={'arrowLink'}
                />
                <h1>Редактировать раздел</h1>
            </div>
            <SectionForm
                submitFormHandler={submitFormHandler}
                defaultValues={defaultValuesForm}
                groups={groupsTree.data}
            />
        </Main>
    );
};

export default EditSection;
