interface GetIndexesReturned {
    start: number;
    end: number
}

export const getIndexes = (page: number, total: number, rowsCount: number): GetIndexesReturned => {
    const start = (page - 1) * rowsCount;
    const end = page * rowsCount;

    return { start, end };
};
