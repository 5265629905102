import { useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'ts-luxon';
import * as yup from 'yup';

import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import NewTreeSelectControl, { transformDataToOptions, TreeSelectOption } from '@components/core/inputs/NewTreeSelectControl';
import NewSelect from '@components/core/inputs/Select/NewSelect';
import Loader from '@components/core/Loader/Loader';
import { DataList, DataListCell } from '@components/DataList';
import { DPRangeControl } from '@components/DatePicker/DPRangeControl';
import { IRangeDates } from '@components/DatePicker/DPRangeControl/DPRangeControl';
import { Pagination, usePagination } from '@components/Pagination';
import { dateConvert, datePrepareToSend } from '@helpers/date';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupTree } from '@store/api/apiTypes';
import { useGetArchiveNoticeQuery } from '@store/api/archiveApi';
import { useLazyGetEventsLogQuery } from '@store/api/eventsLogApi';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useLazyGetUsersAllQuery, useLazyGetUsersByGroupQuery, useLazyGetUsersByUuidsQuery } from '@store/api/usersApi';
import { addOrUpdateSelect } from '@store/features/infiniteSelectScrollSlice';
import { Role } from '@store/Roles';
import { IEventLogNote } from '@type/eventsLog';
import { User } from '@type/users';
import materialStyle from '@views/methodist/Materials/MaterialsPage/MaterialsList/materialsList.module.scss';


import style from './EventsLog.module.scss';
import EventsLogBar from './EventsLogBar/EventsLogBar';


type IUserChartBar = User & {
    group?: GroupTree
};

type IUsersCharBarInfo = {
    [key: string]: IUserChartBar
}

export const actionTypesInfo = [
    {
        type: {
            name: 'Контрольные работы',
            value: 'controlWorks'
        },
        actions: [
            {
                label: 'Скачивание контрольной',
                value: 'downloadTask'
            },
            {
                label: 'Сдача контрольной',
                value: 'passTask'
            },
            {
                label: 'Прохождение идентификации',
                value: 'identificationTask'
            }
        ]
    },
    {
        type: {
            name: 'Тесты',
            value: 'tests'
        },
        actions: [
            {
                label: 'Сдача тестов',
                value: 'passTest'
            },
            {
                label: 'Прохождение идентификации',
                value: 'identificationTest'
            }
        ]
    },
    {
        type: {
            name: 'Лекции',
            value: 'lectures'
        },
        actions: [
            {
                label: 'Чтение главы лекций',
                value: 'readHeadLecture'
            },
            {
                label: 'Скачивание лекций',
                value: 'downloadLecture'
            }
        ]
    },
    {
        type: {
            name: 'Авторизация',
            value: 'auth'
        },
        actions: [
            {
                label: 'Авторизация',
                value: 'auth'
            }
        ]
    },
    {
        type: {
            name: 'Мероприятия',
            value: 'events'
        },
        actions: [
            {
                label: 'Клик на ссылку на онлайн участие',
                value: 'clickOnEventLink'
            }
        ]
    }
];

export type IActionTypesInfo = typeof actionTypesInfo[0];

const eventsLogSchema = yup.object({
    users: yup.array().of(yup.string().required()).required(),
    groups: yup.array().of(yup.string().required()).required(),
    types: yup.array().of(yup.string().required()).required(),
    actions: yup.array().of(yup.string().required()).required(),
    startDate: yup.string().required(),
    endDate: yup.string().required()
});

type IEventLogSchema = yup.InferType<typeof eventsLogSchema>;

const usersLimit = 25;

const EventsLog = () => {
    const navigate = useNavigate();
    const { handleSliceData, setTotal, paginationProps } = usePagination({});
    const dispatch = useDispatch();
    const [eventsData, setEventsData] = useState<IEventLogNote[]>([]);

    useEffect(() => {
        setTotal(eventsData?.length || 0);
    }, [eventsData]);

    const [usersOptions, setUsersOptions] = useState<TreeSelectOption[]>([]);
    const [usersOffset, setUsersOffset] = useState(0);
    const [usersSearch, setUsersSearch] = useState('');
    const [hasMoreUsers, setHasMoreUsers] = useState(false);

    const [usersInfo, setUsersInfo] = useState<IUsersCharBarInfo>({});
    const [flattenGroup, setFlattenGroup] = useState<GroupTree[]>([] as GroupTree[]);

    const [isAnyGroupSelected, setIsAnyGroupSelected] = useState(false);

    const { data: groupsTree, isLoading: isGroupsLoading } = useGetGroupsTreeQuery('');
    const [getEventsLog, { isLoading: isEventsLoading }] = useLazyGetEventsLogQuery();
    const [getUsers, { isFetching: isUsersFetching, isLoading: isUsersLoading }] = useLazyGetUsersAllQuery();
    const [getUsersByUuids, { isLoading: isUsersByUuids }] = useLazyGetUsersByUuidsQuery();
    const { data: isArchiveNotice } = useGetArchiveNoticeQuery();

    const flattenArray = (obj: GroupTree): GroupTree[] => {
        if (!obj.childs || obj.childs.length === 0) {
            return [obj];
        }

        return [obj, ...obj.childs.flatMap(flattenArray)];
    };

    useEffect(() => {
        if (groupsTree) {
            setFlattenGroup(flattenArray(groupsTree));
        }

    }, [groupsTree]);


    const [getUsersByGroup] = useLazyGetUsersByGroupQuery();

    const logTypeOptions: TreeSelectOption[] = useMemo(() => {
        return actionTypesInfo.map(typeOption => ({
            label: typeOption.type.name,
            value: typeOption.type.value,
            children: typeOption.actions.map(action => ({
                ...action,
                children: []
            }))
        })) || [];
    }, []);

    const logAllEvents = useMemo(() => {
        return actionTypesInfo.map(actionType => actionType.actions).flat();
    }, []);

    type ILogAllEvents = typeof logAllEvents;

    const groupOptions = useMemo(() => {

        const allOptions = groupsTree
            ? [
                ...transformDataToOptions(groupsTree.childs, {
                    value: 'UUID',
                    label: 'name',
                    children: 'childs'
                })
            ]
            : [];

        return allOptions;
    }, [groupsTree]);

    const methods = useForm<IEventLogSchema>({
        resolver: yupResolver(eventsLogSchema),
        defaultValues: {
            startDate: DateTime.local().minus({ day: 14 }).toFormat('yyyy-LL-dd HH:mm:ss'),
            endDate: DateTime.local().toFormat('yyyy-LL-dd HH:mm:ss'),
            types: [],
            groups: [],
            users: [],
            actions: []
        }
    });

    const { watch, setValue, control } = methods;

    const watchAllFields = watch();

    const onDateSubmit = (rangeDatesToSubmit: IRangeDates) => {
        setValue('startDate', rangeDatesToSubmit.startDate, { shouldValidate: true });
        setValue('endDate', rangeDatesToSubmit.endDate, { shouldValidate: true });
    };

    const getSelectedEventsTypes = (actions: string[]) => {
        const filteredEvents = actionTypesInfo.filter(event => actionTypesInfo.find(() => {
            return event.actions.find(item => actions.includes(item.value));
        }));

        return filteredEvents.map(evt => evt.type.value);
    };

    const getSelectedEventsActions = (allEvents: ILogAllEvents, actions: string[]) => {
        const filteredEvents = allEvents.filter(event => actions.find(action => action === event.value));
        return filteredEvents.map(event => event.label);
    };

    const handleBarClick = (eventTypeName: string) => {
        const currentActionTypeInfo = actionTypesInfo.find(actionTypeInfo => actionTypeInfo.type.name === eventTypeName);
        if (!currentActionTypeInfo) return;

        const currentType = currentActionTypeInfo.type.value;
        setValue('types', [currentType]);
    };

    const loadMore = useCallback(() => {
        setUsersOffset((offset) => offset + usersLimit);
    }, []);

    const removeDuplicateActions = (actions: { label: string, value: string }[]) => {
        return actions.filter((actionType, index, actionTypes) =>
            actionTypes.findIndex(actionTypesToCompare => (actionTypesToCompare.label === actionType.label)) === index);
    };

    const prepareActionOptionsToSet = (types: string[], allActionTypesInfo: IActionTypesInfo[]) => {
        const filteredTypesInfo = types.length ? allActionTypesInfo.filter(actionTypeInfo => types.includes(actionTypeInfo.type.value)) : actionTypesInfo;
        const filteredActionsByType = filteredTypesInfo.map(actionTypeInfo => actionTypeInfo.actions).flat();

        const actionsByTypeNoDuplicates = removeDuplicateActions(filteredActionsByType);

        const eventsToSet = actionsByTypeNoDuplicates.map(eventOption => ({
            label: eventOption.label,
            value: eventOption.value,
            children: [] as TreeSelectOption[]
        }));

        return eventsToSet;
    };

    useEffect(() => {
        if (watchAllFields.groups && watchAllFields.groups.length) {
            setIsAnyGroupSelected(true);
            return;
        }
        setIsAnyGroupSelected(false);

    }, [watchAllFields.groups]);

    useEffect(() => {
        setUsersOffset(0);
    }, [usersSearch]);

    useEffect(() => {
        dispatch(addOrUpdateSelect({
            name: 'users',
            isInfiniteScroll: isAnyGroupSelected ? false : true,
            hasMore: hasMoreUsers,
            isLoading: !isAnyGroupSelected && (isUsersLoading || isUsersFetching)
        }));
    }, [isAnyGroupSelected, hasMoreUsers, isUsersLoading, isUsersFetching]);

    useEffect(() => {
        const callGetUsers = async () => {
            if (isAnyGroupSelected || watchAllFields.groups.length) {
                const usersData = await getUsersByGroup(watchAllFields.groups);

                if (!usersData.data) {
                    return;
                }

                const usersToSet = usersData.data.map(user => ({
                    label: `${user.lastName} ${user.firstName} ${user.patronymic}`,
                    value: user.uuid,
                    children: [] as TreeSelectOption[]
                }));

                setUsersOptions(usersToSet);
            } else {
                const usersData = await getUsers({
                    limit: usersLimit,
                    offset: usersOffset,
                    search: usersSearch,
                    role: Role.Student
                });

                if (!usersData.data?.users) {
                    return;
                }

                const usersToSet = usersData.data.users.map(user => ({
                    label: `${user.lastName} ${user.firstName} ${user.patronymic}`,
                    value: user.uuid,
                    children: [] as TreeSelectOption[]
                }));

                setHasMoreUsers(usersData.data.total > usersOptions.length);

                const areUsersCrossing = usersOptions.some(user => usersToSet.find(userToSet => userToSet.value === user.value));

                if (areUsersCrossing || !usersOffset) {
                    setUsersOptions(usersToSet);
                    return;
                }

                setUsersOptions([...usersOptions, ...usersToSet]);
            }
        };
        callGetUsers();

    }, [usersOffset, watchAllFields.groups, isAnyGroupSelected, usersSearch]);

    useEffect(() => {
        const areUsers = !!watchAllFields.users.length;

        const setEventsLogFromApi = async () => {
            const logs = await getEventsLog({
                uuidsUser: watchAllFields.users,
                uuidsGroup: areUsers ? [] : watchAllFields.groups,
                names: getSelectedEventsActions(logAllEvents, watchAllFields.actions),
                types: getSelectedEventsTypes(watchAllFields.actions),
                startDate: datePrepareToSend(watchAllFields.startDate),
                endDate: datePrepareToSend(watchAllFields.endDate)
            });

            setEventsData(logs.data?.notes || []);

            const userUuids = logs.data?.notes.map(note => note.uuidUser);
            const users = await getUsersByUuids({ uuids: userUuids || [] });

            const usersData = users?.data?.reduce((result: IUsersCharBarInfo , item) => {

                result[item.uuid] = {
                    ...item,
                    group: flattenGroup.find(group => group.UUID === item.groupUUID[0])
                };

                return result;
            }, {}) || {};

            setUsersInfo(usersData);
        };

        if (watchAllFields.groups.length || watchAllFields.users.length) {
            setEventsLogFromApi();
        }


    }, [watchAllFields.groups,
        watchAllFields.types,
        watchAllFields.endDate,
        watchAllFields.startDate,
        watchAllFields.users,
        watchAllFields.actions
    ]);

    if (isGroupsLoading || isEventsLoading) return <Loader />;

    return (
        <div className={style.container}>
            <h2 className={style.title}>Журнал событий</h2>
            <div className={style.controls_container}>
                <div className={style.form}>
                    <FormProvider {...methods}>
                        <Controller
                            render={({ field: { value, onChange } }) => (
                                <NewSelect
                                    options={usersOptions}
                                    placeholder="Выберите пользователя"
                                    isMulti
                                    value={value}
                                    onChange={onChange}
                                    className={style.filter}
                                    dropdownWidth={260}
                                    renderLabel={(action) => action.length === 1 ? action[0]?.label : `Выбрано пользователей: ${action.length}`}
                                    selectName="users"
                                    loadMore={isAnyGroupSelected ? undefined : loadMore}
                                    onSearch={isAnyGroupSelected ? undefined : setUsersSearch}
                                />
                            )}
                            name="users"
                            control={control}
                        />

                        <NewTreeSelectControl
                            className={style.filter}
                            classes={{
                                input: materialStyle['select__input'],
                                inputIcon: materialStyle['select__icon']
                            }}
                            options={groupOptions}
                            name="groups"
                            placeholder="Выберите группу"
                            selectableParent
                            isMulti={true}
                        />

                        <NewTreeSelectControl
                            className={style.filter}
                            name="actions"
                            options={logTypeOptions}
                            isMulti={true}
                            placeholder="Укажите событие"
                            selectableParent={false}
                        />


                        <div className={style.datePicker}>
                            <DPRangeControl
                                onSubmit={onDateSubmit}
                                variant="date"
                                isButton={false}
                                defaultStartDate={watchAllFields.startDate}
                                defaultEndDate={watchAllFields.endDate}
                            />
                        </div>
                    </FormProvider>
                </div>
                <Button
                    onClick={() => navigate('../archive')}
                    className={cx(style.archive_btn, isArchiveNotice && style['archive_btn--notify'])}
                >Архив</Button>
            </div>
            {!(watchAllFields.groups.length || watchAllFields.users.length) ? (
                <div className={style.alertContainer}>Выберите пользователя или группу для отображения событий</div>
            ) : eventsData.length > 0 ? (
                <>
                    <div className={style.chartContainer}>
                        <h2 className={style.sub_title}>Динамика событий пользователя</h2>
                        <div className={style.chart}>
                            <EventsLogBar onBarClick={handleBarClick} graphNotes={eventsData} />
                        </div>
                    </div>
                    <div>
                        <DataList className={style['list-container']}>
                            <Grid
                                gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                                className={style.header}>
                                <Column>
                                    <DataListCell className={style.column_label}>Событие</DataListCell>
                                </Column>
                                <Column>
                                    <DataListCell className={style.column_label}>Действие</DataListCell>
                                </Column>
                                <Column>
                                    <DataListCell className={style.column_label}>Пользователь</DataListCell>
                                </Column>
                                <Column>
                                    <DataListCell className={style.column_label}>Группа</DataListCell>
                                </Column>
                                <Column>
                                    <DataListCell className={style.column_label}>Дата</DataListCell>
                                </Column>
                            </Grid>

                            {handleSliceData(eventsData).map((event: any) => {
                                return (
                                    <div key={event.uuid}>
                                        <Grid
                                            gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                                            className={cx('row--selectable', style.row)}
                                        >
                                            <Column>
                                                {actionTypesInfo.find(eventType => eventType.type.value === event.type)?.type.name}
                                            </Column>
                                            <Column>
                                                {event.name}
                                            </Column>
                                            <Column>
                                                {`${usersInfo[event.uuidUser]?.lastName} ${usersInfo[event.uuidUser]?.firstName} ${usersInfo[event.uuidUser]?.patronymic}`}
                                            </Column>
                                            <Column>
                                                {usersInfo[event.uuidUser]?.group?.name || ''}
                                            </Column>
                                            <Column>
                                                {dateConvert(event.createdAt.replace(/ \+0000 UTC/, '')).toFormat('dd.LL.yyyy, HH:mm')}
                                            </Column>
                                        </Grid>
                                    </div>
                                );
                            })}
                        </DataList>
                        <Pagination className={style.pagination} {...paginationProps} />
                    </div>
                </>
            ) : <div className={style.alertContainer}>Нет данных для отображения</div>}
        </div>
    );
};

export default EventsLog;
