import React, { FC } from 'react';

import cx from 'classnames';

import Button from '@components/core/inputs/Button';
import ButtonDrop from '@components/core/inputs/Button/ButtonDrop';
import { LinkUI } from '@components/core/LinkUI';
import { DropMenuItem } from '@components/core/styledWrappers/DropMenu';
import { IStorageFile } from '@store/api/apiTypes';

import style from './FilesListMenu.module.scss';

interface IFilesListMenu {
    files: IStorageFile[] | undefined;
    isLink?: boolean;
    className?: string;
    onClick?: () => void;
}

const FilesListMenu: FC<IFilesListMenu> = ({ files, isLink = false, className, onClick }) => {

    if (!files || files.length === 0 || !files[0]) return null;

    const renderFiles = () => {
        if (isLink && files.length === 1) {
            return (
                <LinkUI
                    key={files[0].uuid}
                    to={files[0].path}
                    typeLink="default"
                    download={true}
                    target="_blank"
                    onClick={onClick}
                >
                    {files[0].title}
                </LinkUI>
            );
        }

        if (files.length === 1) {
            return (
                <Button
                    typeIcon="clip"
                    btnType="download"
                    onClick={event => {
                        event.stopPropagation();
                        onClick && onClick();
                        window.open(files[0].path, '_blank');
                    }}
                >
                    <p className={style.button_content}>
                        <span className={style.title}>{files[0].title}</span>
                        {/* В title уже есть расширение */}
                        {/* <span className={style.extension}>.{files[0].extension}</span> */}
                    </p>
                </Button>
            );
        }

        return (
            <ButtonDrop
                btnType="clear"
                typeIcon="clip"
                buttonChild={`Прикреплено файлов (${files.length})`}
                dropMenuProps={{ classNameWrapper: style.drop_menu }}
                className={style.button_drop}
            >
                {
                    files.map((file, index) => (
                        <DropMenuItem key={index}>
                            <a
                                className={style.drop_menu_item__link}
                                href={file.path}
                                download={true}
                                target="_blank"
                                rel="noreferrer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClick && onClick();
                                    return true;
                                }}
                            >
                                <span>{file.title}</span>
                            </a>
                        </DropMenuItem>
                    ))
                }
            </ButtonDrop>
        );
    };

    return (
        <div className={cx(style.root, className)}>
            {
                renderFiles()
            }
        </div>
    );
};

export default FilesListMenu;
