import { FC, useEffect, useMemo, useState } from 'react';

import { useForm, FormProvider, useController } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import { OptionType } from '@components/core/inputs/SelectStyled/type';
import Modal from '@components/Modal';
import { flatCategoriesToOptions, getCategoriesDict } from '@helpers/categories';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { SessionTypesWithLocale, SkillDiscipline, WithUUID } from '@store/api/apiTypes';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import { useGetDisciplinesAllQuery, useGetDisciplinesByCategoryQuery } from '@store/api/disciplinesApi';
import { useUpdateSkillDisciplineMutation, useGetPresetsQuery, useGetSkillsQuery } from '@store/api/skillsApi';

import style from './UpdateSkillDisciplineModal.module.scss';

const sessions = Object.keys(SessionTypesWithLocale).map(key => ({
    label: SessionTypesWithLocale[key as  keyof typeof SessionTypesWithLocale],
    value: key
}));

export const addSkillSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    semester: yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    }).required('Поле обязательно для заполнения!'),
    uuidsPresets: yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    })),
    uuidCategory: yup.object({
        label: yup.string(),
        value: yup.string()
    }),
    uuidsRealDisciplines: yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    })),
    uuidsSkills: yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    }))
});

interface AddSkillDisciplineModalProps {
    skillDiscipline: WithUUID<SkillDiscipline>
    isOpen: boolean,
    closeModal: () => void
}

const UpdateSkillDisciplineModal: FC<AddSkillDisciplineModalProps> = ({ skillDiscipline, isOpen, closeModal }) => {
    const [selectedCategory, setSelectedCategory] = useState<string>();

    const { data: skills } = useGetSkillsQuery(undefined);
    const { data: presets } = useGetPresetsQuery(undefined);
    const { categories } = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000', {
        selectFromResult: ({ data: categoriesData }) => ({
            categories: categoriesData ?? []
        })
    });

    const { data: allDisciplines } = useGetDisciplinesAllQuery({});
    const { data: disciplinesByCategory } = useGetDisciplinesByCategoryQuery(selectedCategory ? selectedCategory : skipToken);

    const disciplines = selectedCategory ? disciplinesByCategory : allDisciplines;

    const categoriesDict = useMemo(() => getCategoriesDict(categories), [categories]);
    const categoriesOptions = useMemo(() => flatCategoriesToOptions(categories), [categories]);

    const selectedPresets = useMemo(() => presets ? presets.reduce((result:OptionType[], preset) => {
        if (skillDiscipline.uuidsPresets?.find(item => item === preset.uuid)) {
            result.push({ label: preset.name, value: preset.uuid });
        }
        return result;
    }, []) : [], [presets]);

    const selectedRealDisciplines = useMemo(() => allDisciplines ? allDisciplines.reduce((result:OptionType[], discipline) => {
        if (skillDiscipline.uuidsRealDisciplines?.find(item => item === discipline.uuid)) {
            result.push({ label: discipline.name, value: discipline.uuid, parent: categoriesDict[discipline.uuidCategory] });
        }
        return result;
    }, []) : [], [allDisciplines]);

    const selectedSkills = useMemo(() => skills ? skills.reduce((result:OptionType[], skill) => {
        if (skillDiscipline.uuidsSkills?.find(item => item === skill.uuid)) {
            result.push({ label: skill.name, value: skill.uuid });
        }
        return result;
    }, []) : [], [skills]);

    const methods = useForm({
        resolver: yupResolver(addSkillSchema),
        defaultValues: {
            name: skillDiscipline.name,
            semester: { label: SessionTypesWithLocale[skillDiscipline.semester as  keyof typeof SessionTypesWithLocale], value:skillDiscipline.semester },
            uuidCategory: { label: categoriesDict[skillDiscipline.uuidCategory ?? ''], value: skillDiscipline.uuidCategory },
            uuidsPresets: selectedPresets,
            uuidsRealDisciplines: selectedRealDisciplines,
            uuidsSkills: selectedSkills
        }
    });

    const { control, handleSubmit, setValue } = methods;
    const { field: { value: selectedFormCategory } } = useController({ control, name: 'uuidCategory' });

    useEffect(() => {
        setValue('name', skillDiscipline.name);
        setValue('semester', {
            label: SessionTypesWithLocale[skillDiscipline.semester as  keyof typeof SessionTypesWithLocale],
            value:skillDiscipline.semester
        });
        setValue('uuidsPresets', selectedPresets);
        setValue('uuidsRealDisciplines', selectedRealDisciplines);
        setValue('uuidsSkills', selectedSkills);
    },[skillDiscipline, selectedPresets, selectedRealDisciplines, selectedSkills]);


    const [updateDiscipline, {
        isLoading: createDisciplineIsLoading
    }] = useUpdateSkillDisciplineMutation();

    const onSubmit = async (formData: any) => {
        const transformedFormData = {
            uuid: skillDiscipline.uuid,
            name: formData.name,
            semester: formData.semester.value,
            uuidsPresets: formData.uuidsPresets?.map((item:{label:string,value:string}) => item.value),
            uuidsRealDisciplines: formData.uuidsRealDisciplines?.map((item:{label:string,value:string}) => item.value),
            uuidsSkills: formData.uuidsSkills?.map((item:{label:string,value:string}) => item.value)
        };
        updateDiscipline(transformedFormData);
        closeModal();
    };

    useEffect(() => {
        if (selectedFormCategory) {
            setSelectedCategory(selectedFormCategory.value);
        }
    }, [selectedFormCategory]);

    return (
        <Modal
            onClose={closeModal}
            isOpen={isOpen}
            className={style.modal}
            title="Редактировать плановую дисциплину"
        >

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className={style.container}>

                    <div className={style.list}>

                        <div className={style.input}>
                            <label htmlFor="name">Название</label>
                            <InputControl
                                name="name"
                                placeholder="Введите название"
                                id="name"
                            />
                        </div>

                        {skills &&
                            <div className={style.input}>
                                <label htmlFor="uuidsSkills">Привязанные компетенции</label>
                                <SelectStyled
                                    control={control}
                                    name="uuidsSkills"
                                    options={skills.map(skill => ({ label:skill.name, value: skill.uuid }))}
                                    isMulti
                                    placeholder="Выберите компетенции"

                                />
                            </div>
                        }
                        {categories &&
                          <div className={style.input}>
                              <label htmlFor="uuidCategory">Выбранная категория</label>
                              <SelectStyled
                                  control={control}
                                  name="uuidCategory"
                                  options={categoriesOptions}
                                  placeholder="Выберите категорию"
                              />
                          </div>
                        }
                        <div className={style.input}>
                            <label htmlFor="uuidsRealDisciplines">Привязанные дисциплины</label>
                            <SelectStyled
                                control={control}
                                name="uuidsRealDisciplines"
                                options={
                                    disciplines?.map(discipline => ({
                                        label:discipline.name,
                                        value:discipline.uuid,
                                        parent: categoriesDict[discipline.uuidCategory]
                                    })) ?? []
                                }
                                isMulti
                                isHierarchy
                                isDisabled={!selectedCategory || !disciplines}
                                placeholder="Выберите дисциплины"

                            />
                        </div>
                        {presets &&
                            <div className={style.input}>
                                <label htmlFor="uuidsRealDisciplines">Привязанные пресеты</label>
                                <SelectStyled
                                    control={control}
                                    name="uuidsPresets"
                                    options={presets.map(preset => ({ label:preset.name, value:preset.uuid }))}
                                    isMulti
                                    placeholder="Выберите пресеты"
                                />
                            </div>
                        }
                        <div className={style.input}>
                            <label htmlFor="semester">Семестр</label>
                            <SelectStyled
                                control={control}
                                name="semester"
                                options={sessions}
                                placeholder="Выберите семестр"
                            />
                        </div>
                    </div>

                    <div className={style.button_wrapper}>
                        <Button
                            onClick={closeModal}
                            btnType={'secondary'}
                        >
                    Отмена
                        </Button>
                        <Button
                            type='submit'
                            disabled={createDisciplineIsLoading}
                            typeIcon={createDisciplineIsLoading ? 'loading' : undefined}
                        >
                            Сохранить
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default UpdateSkillDisciplineModal;






















