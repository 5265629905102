import { FC, ReactNode } from 'react';

import { Control, useController } from 'react-hook-form';

import { FileUploadPhoto, type FileUploadPhotoProps } from '../FileUploadPhoto/FileUploadPhoto';

type FileControlProps = {
    name: string;
    control: Control<any>;
    children?:ReactNode;
};

const FileUploadPhotoControl: FC<FileControlProps & Omit<FileUploadPhotoProps, 'value' | 'onChange'>> = ({ control, name, children,  ...props }) => {
    const {
        field: { onChange, value },
        fieldState: { error }
    } = useController({ name, control });

    return (
        <FileUploadPhoto
            name={name}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            {...props}
        >
            {children}
        </FileUploadPhoto>
    );
};

export { FileUploadPhotoControl };
