import { TUploadFile } from '@components/core/inputs/FileUpload/type';

export const getBase64 = (file: NonNullable<TUploadFile>) => new Promise((resolve, reject) =>{
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
        const base64String = reader.result?.toString().replace('data:', '').replace(/^.+,/, '');
        resolve(base64String);
    };

    reader.onerror = function (error) {
        console.error(error);
        reject(error);
    };
});

export const fromBase64 = (b64: string, filename: string) => {

    const arrB64 = b64.split(','),
        mime = arrB64[0].match(/:(.*?);/)![1] || '',
        bstr = atob(arrB64[1] || '');

    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

export const encodeUnicode = (str: string) => {
    // First we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
            // @ts-ignore
            return String.fromCharCode('0x' + p1);
        })
    );
};

export const decodeUnicode = (str: string) => {
    // Going backward: from byte-stream to percent-encoding, to original string.
    return decodeURIComponent(
        atob(str)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
};

export const canParseJson = (str: string): Record<string, any> | null => {
    try {
        return JSON.parse(str);
    } catch (error) {
        return null;
    }
};
