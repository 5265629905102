import { useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'ts-luxon';

import { useChat } from '@components/Chat/context';
import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import { DataList, DataListCell } from '@components/DataList';
import Main from '@components/Main';
import { MenuItem, TableMenu } from '@components/Menu';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import ImportUsersModal from '@components/methodist/ImportUsersModal';
import { Pagination, usePagination } from '@components/Pagination';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import Search from '@components/Search';
import { dateConvert } from '@helpers/date';
import { useSearch } from '@hooks/useSearch';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useChangeUserStatusByUuidMutation, useGetUsersAllQuery, useResetUserPasswordMutation, useSoftUsersDeleteMutation } from '@store/api/usersApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role, roleNames } from '@store/Roles';
import { User, UserStatus } from '@type/users';

import style from './users_list.module.scss';


const cx = classNames.bind(style);

const UsersList = () => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const apiUser = useAppSelector(selectApiUser);
    const navigate = useNavigate();

    const { writeToUser } = useChat();

    const [currentUser, setCurrentUser] = useState<User>();

    const { limit, start, setTotal, paginationProps } = usePagination({});

    const { search, ...searchInputProps } = useSearch();

    const allUsersResponse = useGetUsersAllQuery({
        offset:  start,
        limit: limit,
        search
    });

    useEffect(() => {
        setTotal(allUsersResponse.data?.total || 0);
    }, [allUsersResponse.data?.total]);


    const [deleteUser] = useSoftUsersDeleteMutation();
    const [resetPassword] = useResetUserPasswordMutation();
    const [changeStatus] = useChangeUserStatusByUuidMutation();

    const { isGroupsLoading } = useGetGroupsTreeQuery('', {
        selectFromResult: ({ isLoading: isGroupsLoadingData }) => ({
            isGroupsLoading: isGroupsLoadingData
        }) }
    );

    //Modal
    const openDeleteModal: (user: User) => void = (user) => {
        setShowDeleteModal(true);
        setCurrentUser(user);
    };

    if (allUsersResponse.isLoading && isGroupsLoading) return <NewLoader />;

    //Prepare date
    const prepareUserDate = (timeWithZOne: string) => {
        if (!timeWithZOne) return 'Не был активен';

        const convertedTime = dateConvert(timeWithZOne.replace(/ \+0000 UTC/, ''));
        const today = dateConvert(DateTime.now().toFormat('yyyy-LL-dd')).toFormat('yyyyLLdd');

        const isYeasterday = convertedTime.plus({ days: 1 }).toFormat('yyyyLLdd') === today;
        if (isYeasterday) return 'Вчера';

        const isToday = convertedTime.toFormat('yyyyLLdd') === today;
        if (isToday) return convertedTime.toFormat('Сегодня в HH:mm');

        return convertedTime.toFormat('HH:mm, dd.LL.yyyy');
    };

    const sendPassword = (user: User): void => {
        resetPassword([user.uuid]);
    };

    const toggleUserStatus = (user: User): void => {
        const status = user.status === UserStatus.Active ? UserStatus.Blocked : UserStatus.Active;

        changeStatus({
            uuids: [user.uuid],
            status
        });
    };

    const users = allUsersResponse.data?.users || [];

    const deleteMaterialHandler = async (user: User) => {
        await deleteUser([user.uuid]);
        setShowDeleteModal(false);
    };

    return (
        <Main title="Управление пользователями – Пользователи">
            <div>
                <h1 className={style.title}>Пользователи</h1>
            </div>

            <div className={style.table_container}>
                <RoleCheck roles={Role.Methodist}>
                    <div className={style.table_control}>
                        <Button onClick={() => setShowImportModal(true)} btnType="success" style={{ height: 34 }}>Импорт</Button>
                    </div>
                </RoleCheck>

                <div className={cx(style.table_control, style.table_control_search)}>
                    <Search {...searchInputProps} />
                </div>
            </div>

            <DataList className={style['list-container']}>
                <Grid
                    gridTemplateColumns="minmax(328px, 33fr) minmax(274px, 28fr) minmax(94px, 10fr) minmax(136px, 13fr) minmax(126px, 16fr)"
                    className={style.header}>
                    <Column>
                        <DataListCell>Пользователь</DataListCell>
                    </Column>
                    <Column>
                        <DataListCell>Email</DataListCell>
                    </Column>
                    <Column>
                        <DataListCell>Статус</DataListCell>
                    </Column>
                    <Column>
                        <DataListCell>Был активен</DataListCell>
                    </Column>
                    <Column>
                        <DataListCell>Роль</DataListCell>
                    </Column>
                </Grid>

                {users.map(user => (
                    <div key={user.uuid} onClick={() => navigate(`${user.uuid}/user`)}>
                        <Grid
                            gridTemplateColumns="minmax(328px, 33fr) minmax(274px, 28fr) minmax(94px, 10fr) minmax(136px, 13fr) minmax(126px, 16fr)"
                            className={cx('row--selectable', style.row)}
                        >
                            <Column>
                                {`${user.lastName} ${user.firstName} ${user.patronymic}`}
                            </Column>
                            <Column>
                                {user.email}
                            </Column>
                            <Column>
                                {user.status === UserStatus.Active ? 'Активен' : 'Не активен'}
                            </Column>
                            <Column>
                                {prepareUserDate(user.lastActivity)}
                            </Column>
                            <Column className={style.relative}>
                                {roleNames[user.role]}
                                <div className={style.menu}
                                    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }}
                                >
                                    <TableMenu>
                                        <MenuItem to={`${user.uuid}/user`}>
                                            Редактировать
                                        </MenuItem>
                                        {
                                            (apiUser?.uuid !== user.uuid) && checkRole(Role.Methodist) && <MenuItem onClick={() => toggleUserStatus(user)}>
                                                {user.status === UserStatus.Active ? 'Заблокировать' : 'Разблокировать'}
                                            </MenuItem>
                                        }
                                        {
                                            (apiUser?.uuid !== user.uuid) &&
                                            <MenuItem onClick={() => writeToUser(user)}>Отправить сообщение</MenuItem>
                                        }
                                        <RoleCheck roles={Role.Methodist}>
                                            <MenuItem onClick={() => sendPassword(user)}>Отправить пароль</MenuItem>
                                        </RoleCheck>
                                        {
                                            (apiUser?.uuid !== user.uuid) && checkRole(Role.Methodist) &&
                                            <MenuItem onClick={() => openDeleteModal(user)} danger>Удалить</MenuItem>
                                        }
                                    </TableMenu>
                                </div>
                            </Column>
                        </Grid>
                    </div>
                ))}
            </DataList>
            <Pagination className={style.pagination} {...paginationProps} />

            <ImportUsersModal
                isOpen={showImportModal}
                onClose={() => setShowImportModal(false)}
            />

            <DeleteItemModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onDelete={() => deleteMaterialHandler(currentUser as User) }
                itemName={currentUser?.firstName as string}
                itemType="user"
            />
        </Main>
    );
};

export default UsersList;
