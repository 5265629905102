import React, { FC, useEffect, useState } from 'react';

import cx from 'classnames';

import { ReactComponent as DeleteIcon } from '@assets/icons/delete-icon-default.svg';
import { ReactComponent as DownloadIcon } from '@assets/icons/dovnload-portfolio-file-icon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon-default.svg';
import { Column } from '@components/core/grid';
import IconButton from '@components/core/styledWrappers/IconButton';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { checkRole } from '@components/RoleCheck';
import { dateConvert } from '@helpers/date';
import { useToggle } from '@hooks/useToggle.hook';
import { usePortfolioFilesChangeMutation } from '@store/api/portfolioApi';
import { Role } from '@store/Roles';
import { PortfolioFile } from '@type/portfolio';
import EditFileModal from '@views/student/StudentProfile/Portfolio/EditFileModal';

import style from './FileListItem.module.scss';


interface IProps {
    file: PortfolioFile;
}

const getFileFormat = (link: string) => {
    const regex = /\.(\w+)$/;
    const format = link?.match(regex);
    return format ? format[1] : '';
};

const FileListItem: FC<IProps> = ({
    file
}) => {
    const [isOpenedDeleteModal, { on: onDeleteModal, off: offDeleteModal }] = useToggle();
    const [isOpenEditFileModal, setIsOpenEditFileModal] = useState<boolean>(false);
    const [fetchChangeFile, { isSuccess: isSuccessChangeFile }] = usePortfolioFilesChangeMutation();
    const onOpenDeleteModal = () => {
        onDeleteModal();
    };

    const onOpenEditModal = () => {
        setIsOpenEditFileModal(true);
    };

    const onDeleteFile = () => {
        fetchChangeFile({ uuid: file.uuid, active: false });
    };

    useEffect(() => {
        if (isSuccessChangeFile) {
            offDeleteModal();
            setIsOpenEditFileModal(false);
        }
    }, [isSuccessChangeFile]);

    return (
        <React.Fragment>
            <div className={style.root}>
                <a
                    className={style.link}
                    href={file.fileName}
                    target="_blank"
                    download={true}
                    rel="noreferrer"
                >
                    <Column className={style.column}>
                        {file.uName}
                    </Column>

                    <Column className={cx(style.column, style.format)}>
                        <span>{getFileFormat(file.fileName)}</span>
                    </Column>

                    <Column className={style.column}>
                        {dateConvert(file.createdAt.replace(/ \+0000 UTC/, '')).toFormat('dd.LL.yyyy')}
                    </Column>
                </a>
                <div className={style.root__list_btn}>
                    <a
                        className={cx(style.btn_icon, style.link_icon)}
                        href={file.fileName}
                        target="_blank"
                        download={true}
                        rel="noreferrer"
                    >
                        <DownloadIcon />
                    </a>
                    {checkRole([Role.Methodist, Role.Student]) && (
                        <>
                            <IconButton
                                className={style.btn_icon}
                                onClick={onOpenEditModal}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                className={style.btn_icon}
                                onClick={onOpenDeleteModal}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )}
                </div>
            </div>
            {isOpenEditFileModal && (
                <EditFileModal
                    isOpen={isOpenEditFileModal}
                    onClose={setIsOpenEditFileModal}
                    file={file}
                />
            )}
            {isOpenedDeleteModal && (
                <DeleteItemModal
                    isOpen={isOpenedDeleteModal}
                    onClose={offDeleteModal}
                    onDelete={onDeleteFile}
                    itemName={file.uName}
                />
            )}
        </React.Fragment>
    );
};

export default FileListItem;
