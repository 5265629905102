import { FC, useMemo } from 'react';

import { DateTime } from 'ts-luxon';

import { Column, Grid } from '@components/core/grid';
import { NewLoader } from '@components/core/NewLoader';
import { dateConvert } from '@helpers/date';
import { getBorderScore } from '@helpers/scores';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { SessionTypes, SessionTypesWithLocale } from '@store/api/apiTypes';
import { useGetSubscribedDisciplinesQuery } from '@store/api/groupsApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import DisciplinesItem from '@views/student/StudentMain/DisciplinesItem';

import style from './DisciplinesList.module.scss';
import { notFoundComponents } from './DisciplinesNotFound';


interface IDisciplinesList {
    filter?: 'all' | 'active' | 'arrears' | 'completed'
}

const DisciplinesList: FC<IDisciplinesList> = ({ filter }) => {
    const lgUp = useBreakpoint('lg', 'up');
    const mdUp = useBreakpoint('md', 'up');
    const smUp = useBreakpoint('sm', 'up');

    const user = useAppSelector(selectApiUser);
    const today = DateTime.now().toMillis();

    const subscribedDisciplines = useGetSubscribedDisciplinesQuery(user ? user.groupUUID : skipToken);

    const sessions = Object.values(SessionTypes).reverse();

    const filterDisciplines = useMemo(() => {
        switch (filter) {
            case 'all':
                return subscribedDisciplines.data;
            case 'active': {
                return subscribedDisciplines.data?.filter(discipline => {
                    const isAfterStartDate = discipline.startDate ? dateConvert(discipline.startDate).toMillis() <= today : true;
                    const isBeforeStartDate = discipline.deadline ? today <= dateConvert(discipline.deadline).toMillis() : true;
                    return isAfterStartDate && isBeforeStartDate;

                });
            }
            case 'arrears': {
                return subscribedDisciplines.data?.filter(discipline => {
                    const borderScore = getBorderScore(discipline.closeType);

                    return discipline.score! < borderScore  &&
                        today >= dateConvert(discipline.deadline).toMillis();
                });
            }
            case 'completed': {
                return subscribedDisciplines.data?.filter(discipline => {
                    const borderScore = getBorderScore(discipline.closeType);

                    return discipline.score! >= borderScore &&
                        today >= dateConvert(discipline.deadline).toMillis();
                });
            }

            default:
                return subscribedDisciplines.data;
        }

    }, [subscribedDisciplines, filter]);

    const filterDisciplinesWithSessions = useMemo(() => {
        return sessions.map(session => {
            return {
                session: SessionTypesWithLocale[session],
                disciplines: filterDisciplines ?
                    filterDisciplines.filter(filterDiscipline => filterDiscipline.session === session) : []
            };
        }).filter(session => session.disciplines.length);
    }, [filterDisciplines]);

    const getGridTemplateOptions = () => {
        if (lgUp) {
            return {
                gridTemplateColumns: 'repeat(4, 1fr)',
                columnGap: '32px',
                rowGap: '32px'
            };
        } else if (mdUp) {
            return {
                gridTemplateColumns: 'repeat(3, 1fr)',
                columnGap: '32px',
                rowGap: '32px'
            };
        } else if (smUp) {
            return {
                gridTemplateColumns: 'repeat(2, 1fr)',
                columnGap: '32px',
                rowGap: '28px'
            };
        } else {
            return {
                gridTemplateColumns: 'repeat(2, 1fr)',
                columnGap: '16px',
                rowGap: '20px'
            };
        }
    };

    const gridOptions = getGridTemplateOptions();

    if (subscribedDisciplines.isFetching || !subscribedDisciplines.isSuccess) return <NewLoader />;

    // ToDo: Разные состояния если нет активных\задолжностей и тд
    return (
        <div className={style.main}>
            {
                filterDisciplinesWithSessions.length ?
                    filterDisciplinesWithSessions.map(session => (
                        <div key={session.session} className={style.session}>
                            <h2 className={style.session_name}>
                                {session.session}
                            </h2>
                            <Grid gridTemplateColumns={gridOptions.gridTemplateColumns} columnGap={gridOptions.columnGap} rowGap={gridOptions.rowGap}>
                                {session.disciplines.map(discipline => (
                                    <Column className={style.column} key={discipline.disciplineUUID} size={1}>
                                        <DisciplinesItem key={discipline.disciplineUUID} discipline={discipline} />
                                    </Column>
                                ))}
                            </Grid>
                        </div>
                    ))

                    : notFoundComponents[filter!]
            }
        </div>
    );
};

export default DisciplinesList;

