import { useLocation, useNavigate } from 'react-router-dom';

interface NavigateOptions {
    replace?: boolean;
    state?: Record<string, any>;
}

interface UseNavigationStateReturned <T>{
    navigate: (to: string, options?: NavigateOptions) => void;
    state: T | undefined;
    clearState: () => void;
}

/**
 * Хук можно использовать для передачи данных в навигации
 *
 * @example
 * На странице откуда надо передать данные использовать
 * const { navigate } = useNavigationState<T>(data)
 * navigate("/task")
 *
 * На странице "Task"
 * const { state } = useNavigationState<T>()
*/
export const useNavigationState = <T>(state?: T): UseNavigationStateReturned<T> => {
    const navigateRoot = useNavigate();
    const location = useLocation();

    const locationState = location.state as T | undefined;

    const navigate = (to: string) => {
        navigateRoot(to, { state });
    };

    const clearState = () => {
        navigateRoot(location, { state: null, replace: true });
    };

    return {
        navigate,
        state: locationState,
        clearState
    };
};
