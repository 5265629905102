import { toast } from 'react-toastify';

import { 
    ICreateProgrammFile, 
    ICreateProgrammLink, 
    ICreateProgrammPage, 
    ICreateSectionData, 
    IDeleteProgrammItem, 
    IProgrammFile, 
    IProgrammLink, 
    IUpdatePositionsSectionProgrammData, 
    IUpdateProgrammFile, 
    IUpdateProgrammLink, 
    IUpdateProgrammPage, 
    IUpdateSectionData, 
    StudyProgramm, 
    StudyProgrammeSection, 
    StudyProgrammGroupInfo, 
    StudyProgrammsInfo
} from '@type/studyProgrammes';

import { emptyBaseApi } from './emptyBaseApi';

export const studyProgrammeApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        // For methodist
        getStudyProgrammeByUuidGroup: build.query<StudyProgramm, string>({
            query(uuidGroup) {
                return {
                    url: 'programmes/get',
                    method: 'POST',
                    body: { uuidGroup: uuidGroup }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить учебную программу');
                }
            },
            transformResponse: (result: StudyProgramm) => {
                return result;
            },
            providesTags: (result, error, arg) => [{ type: 'StudyProgramme', id: `List_${arg}` }]
        }),
        getStudyProgrammeGroupsInfo: build.query<StudyProgrammGroupInfo[], string[]>({
            query(groupsUuids) {
                return {
                    url: 'programmes/info',
                    method: 'POST',
                    body: { uuidsGroup: groupsUuids }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить информацию по группам');
                }
            },
            transformResponse: (result: StudyProgrammsInfo) => {
                return result.groups;
            },
            providesTags: (result, error, arg) => [{ type: 'StudyProgramme' }]
        }),
        // For students
        getStudyProgramme: build.query<StudyProgramm, void>({
            query: () => 'programmes/get',
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить учебную программу');
                }
            },
            transformResponse: (result: StudyProgramm) => {
                return result;
            },
            providesTags: (result, error, arg) => [{ type: 'StudyProgramme', id: `List_${arg}` }]
        }),
        createStudyProgramme: build.mutation<Omit<StudyProgramm, 'sections'>, string>({
            query(uuidGroup) {
                return {
                    url: 'programmes/create',
                    method: 'POST',
                    body: { uuidGroup: uuidGroup }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Учебная программа создана',
                    error: 'Ошибка создания!'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type: 'StudyProgramme', id: `List_${arg}` }]
        }),
        updateStudyProgramme: build.mutation<Omit<StudyProgramm, 'sections'>, Pick<StudyProgramm, 'uuid' | 'uuidGroup'>>({
            query(data) {
                return {
                    url: 'programmes/update',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Учебная программа обновлена',
                    error: 'Ошибка обновления!'
                });
            },
            invalidatesTags: (result, error, arg) => result ? [{ type: 'StudyProgramme', id: `List_${result.uuidGroup}` }] : ['StudyProgramme']
        }),
        deleteStudyProgramme: build.mutation<void, string>({
            query(uuidProgramme) {
                return {
                    url: 'programmes/delete',
                    params: { uuid: uuidProgramme },
                    method: 'DELETE'
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Программа удалена',
                    error: 'Ошибка удаления!'
                });
            },
            invalidatesTags: (result, error, arg) => result
                ? [{ type: 'StudyProgramme' as const, id: arg }, 'StudyProgramme']
                : ['StudyProgramme']
        }),
        createStudyProgrammeSection: build.mutation<StudyProgrammeSection, ICreateSectionData>({
            query(data) {
                return {
                    url: 'programmes/sections/create',
                    method: 'POST',
                    body: { ...data, uuidGroup: undefined }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Раздел создан',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuidGroup ? [{ type: 'StudyProgramme', id: `List_${arg.uuidGroup}` }] : ['StudyProgramme']
        }),
        updateStudyProgrammeSection: build.mutation<StudyProgrammeSection, IUpdateSectionData>({
            query(data) {
                return {
                    url: 'programmes/sections/update',
                    method: 'POST',
                    body: { ...data, uuidGroup: undefined }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Раздел обновлен',
                    error: 'Ошибка обновления!'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuidGroup ? [{ type: 'StudyProgramme', id: `List_${arg.uuidGroup}` }] : ['StudyProgramme']
        }),
        deleteStudyProgrammeSection: build.mutation<void, IDeleteProgrammItem>({
            query(data) {
                return {
                    url: 'programmes/sections/delete',
                    method: 'DELETE',
                    params: { uuid: data.uuidItem }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Раздел удален',
                    error: 'Ошибка удаления!'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuidGroup ? [{ type: 'StudyProgramme', id: `List_${arg.uuidGroup}` }] : ['StudyProgramme']
        }),
        createProgrammeLinkBlock: build.mutation<IProgrammLink, ICreateProgrammLink>({
            query(data) {
                return {
                    url: 'programmes/blocks/link/create',
                    method: 'POST',
                    body: { ...data, uuidGroup: undefined }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Ссылка создана',
                    error: 'Ошибка создания!'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuidGroup ? [{ type: 'StudyProgramme', id: `List_${arg.uuidGroup}` }] : ['StudyProgramme']
        }),
        updateProgrammeLinkBlock: build.mutation<IProgrammLink, IUpdateProgrammLink>({
            query(data) {
                return {
                    url: 'programmes/blocks/link/update',
                    method: 'POST',
                    body: { ...data, uuidGroup: undefined }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Ссылка обновлена',
                    error: 'Ошибка обновления!'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuidGroup ? [{ type: 'StudyProgramme', id: `List_${arg.uuidGroup}` }] : ['StudyProgramme']
        }),
        createProgrammeFileBlock: build.mutation<IProgrammFile, ICreateProgrammFile>({
            query(data) {
                return {
                    url: 'programmes/blocks/file/create',
                    method: 'POST',
                    body: { ...data, uuidGroup: undefined }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Файл создан',
                    error: 'Ошибка создания!'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuidGroup ? [{ type: 'StudyProgramme', id: `List_${arg.uuidGroup}` }] : ['StudyProgramme']
        }),
        updateProgrammeFileBlock: build.mutation<IProgrammFile, IUpdateProgrammFile>({
            query(data) {
                return {
                    url: 'programmes/blocks/file/update',
                    method: 'POST',
                    body: { ...data, uuidGroup: undefined }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Файл обновлен',
                    error: 'Ошибка обновления!'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuidGroup ? [{ type: 'StudyProgramme', id: `List_${arg.uuidGroup}` }] : ['StudyProgramme']
        }),
        createProgrammePageBlock: build.mutation<IProgrammFile, ICreateProgrammPage>({
            query(data) {
                return {
                    url: 'programmes/blocks/page/create',
                    method: 'POST',
                    body: { ...data, uuidGroup: undefined }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Страница создана',
                    error: 'Ошибка создания!'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuidGroup ? [{ type: 'StudyProgramme', id: `List_${arg.uuidGroup}` }] : ['StudyProgramme']
        }),
        updateProgrammePageBlock: build.mutation<IProgrammFile, IUpdateProgrammPage>({
            query(data) {
                return {
                    url: 'programmes/blocks/page/update',
                    method: 'POST',
                    body: { ...data, uuidGroup: undefined }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Страница обновлена',
                    error: 'Ошибка обновления!'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuidGroup ? [{ type: 'StudyProgramme', id: `List_${arg.uuidGroup}` }] : ['StudyProgramme']
        }),
        deleteProgrammeBlock: build.mutation<void, IDeleteProgrammItem>({
            query(data) {
                return {
                    url: 'programmes/blocks/delete',
                    method: 'DELETE',
                    params: { uuid: data.uuidItem }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Материал удален',
                    error: 'Ошибка удаления!'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuidGroup ? [{ type: 'StudyProgramme', id: `List_${arg.uuidGroup}` }] : ['StudyProgramme']
        }),
        updateProgrammeSectionsPosition: build.mutation<void, IUpdatePositionsSectionProgrammData>({
            query(data) {
                return {
                    url: 'programmes/sections/positions',
                    method: 'POST',
                    body: { ...data, uuidGroup: undefined }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Ошибка обновления!'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuidGroup ? [{ type: 'StudyProgramme', id: `List_${arg.uuidGroup}` }] : ['StudyProgramme']
        }),
        updateProgrammeBlocksPosition: build.mutation<void, IUpdatePositionsSectionProgrammData>({
            query(data) {
                return {
                    url: 'programmes/blocks/positions',
                    method: 'POST',
                    body: { ...data, uuidGroup: undefined }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Ошибка обновления!'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuidGroup ? [{ type: 'StudyProgramme', id: `List_${arg.uuidGroup}` }] : ['StudyProgramme']
        })
    })
});

export const {
    useGetStudyProgrammeByUuidGroupQuery,
    useGetStudyProgrammeGroupsInfoQuery,
    useLazyGetStudyProgrammeGroupsInfoQuery,
    useCreateStudyProgrammeMutation,
    useGetStudyProgrammeQuery,
    useUpdateStudyProgrammeMutation,
    useDeleteStudyProgrammeMutation,
    useCreateStudyProgrammeSectionMutation,
    useCreateProgrammeLinkBlockMutation,
    useDeleteProgrammeBlockMutation,
    useCreateProgrammeFileBlockMutation,
    useCreateProgrammePageBlockMutation,
    useUpdateProgrammePageBlockMutation,
    useUpdateProgrammeLinkBlockMutation,
    useUpdateProgrammeFileBlockMutation,
    useUpdateStudyProgrammeSectionMutation,
    useDeleteStudyProgrammeSectionMutation,
    useUpdateProgrammeSectionsPositionMutation,
    useUpdateProgrammeBlocksPositionMutation
} = studyProgrammeApi;
