import React, { FC, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';

import Button from '@components/core/inputs/Button';
import Checkbox from '@components/core/inputs/Checkbox';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';

import style from './AuthPersonaPolicy.module.scss';


type TCheckEvent = React.ChangeEvent<HTMLInputElement>;

export const AuthPersonaPolicy: FC = () => {
    const { nextStep } = useWizard();

    const [allowPolicy, setPolicy] = useState(false);
    const [allowCamera, setCamera] = useState(false);

    const togglePolicy = (e: TCheckEvent) => setPolicy(e.target.checked);
    const toggleCamera = (e: TCheckEvent) => setCamera(e.target.checked);

    const userUuid = useAppSelector(selectApiUser)?.uuid;

    const onClick = () => {

        if (allowCamera && allowPolicy) {
            localStorage.setItem(`PolicyChecked:${userUuid}`, JSON.stringify(true));
            nextStep();
        }
    };

    useEffect(() => {
        if (localStorage.getItem(`PolicyChecked:${userUuid}`)) {
            nextStep();
        }

    }, []);

    return <div className={style.container}>
        <h3 className={style.title}>Для продолжения необходимо пройти аутентификацию личности</h3>

        <p className={style.description}>
            Пожалуйста, прочитайте и примите правила <Link className={style.link} to="/privacy-policy" target="_blank">
            политики конфиденциальности</Link>,
            разрешите системе доступ к веб-камере и записи экрана.
        </p>

        <div className={style.checkboxes}>
            <Checkbox onChange={togglePolicy}>
                Я принимаю условия <Link className={style.link} to="/privacy-policy" target="_blank">
                политики конфиденциальности.</Link>
            </Checkbox>

            <Checkbox onChange={toggleCamera}>
                Я разрешаю доступ к веб-камере, а также к записи экрана.
            </Checkbox>
        </div>

        <div className={style.next}>
            <Button
                btnType="success"
                disabled={!(allowCamera && allowPolicy)}
                onClick={onClick}
                fullWidth={true}
            >
                Далее
            </Button>
        </div>
    </div>;
};
