import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { TUploadFile } from '@components/core/inputs/FileUpload/type';
import { LinkUI } from '@components/core/LinkUI';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { getBase64 } from '@helpers/base64';
import { isStorageFile } from '@helpers/typeCheck';
import { CreateTaskData, fileTypes, IStorageFile } from '@store/api/apiTypes';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useUploadFileMutation } from '@store/api/storageApi';
import { useCreateTaskMutation } from '@store/api/taskApi';
import TaskForm from '@views/methodist/Materials/Course/EditCourse/Structure/TaskForm';
import { TaskFormData } from '@views/methodist/Materials/Course/EditCourse/Structure/TaskForm/type';

import style from './createTask.module.scss';


const CreateTask = () => {
    const { uuidSection } = useParams();
    const navigate = useNavigate();
    const [defaultValuesForm] = useState<TaskFormData>({
        name: '',
        content: '',
        files: [],
        maxRating: '',
        accessLimitation: false,
        needPlagiarismCheck: false,
        minUniquenessPercent: 0,
        blockedGroups: []
    });

    const [fetchCreateTask, { isSuccess }] = useCreateTaskMutation();
    const [uploadFile] = useUploadFileMutation();
    const groupsTree = useGetGroupsTreeQuery('');

    const submitFormHandler = async (formData: TaskFormData) => {
        const uuidsFile: string[] = [];
        const uploadFileHandler = async (file: IStorageFile | TUploadFile) => {
            if (!isStorageFile(file) && file) {
                const b64 = await getBase64(file as File);
                const uploadedFile = await uploadFile({
                    file: b64 as string,
                    meta: {
                        filename: file.name,
                        type: fileTypes.Public
                    }
                }).unwrap();
                uuidsFile.push(uploadedFile.uuid);
            }
        };

        if (formData.files) {
            for await (const file of formData.files) {
                await uploadFileHandler(file);
            }

            const transformedData: CreateTaskData = {
                name: formData.name,
                content: formData.content,
                uuidsFile: uuidsFile,
                maxRating: Number(formData.maxRating),
                uuidParent: uuidSection ? uuidSection : '',
                blockedGroups: formData.blockedGroups || [],
                position: 1,
                needPlagiarismCheck: !!formData.needPlagiarismCheck,
                minUniquenessPercent: formData?.minUniquenessPercent ? formData.minUniquenessPercent : 0
            };
            fetchCreateTask(transformedData);
        }
    };

    useEffect(() => {
        if (isSuccess)
            navigate('./../..');
    }, [isSuccess]);

    if (groupsTree.isFetching) return <NewLoader />;

    return (
        <Main>
            <div className={style.title_wrapper}>
                <LinkUI
                    to={'./../..'}
                    typeIcon={'arrowLink'}
                />
                <h1>Добавить контрольную работу</h1>
            </div>
            <TaskForm
                groups={groupsTree.data}
                submitFormHandler={submitFormHandler}
                defaultValues={defaultValuesForm}
            />
        </Main>
    );
};

export default CreateTask;
