import { toast } from 'react-toastify';

import {
    WithUUID,
    ResponseMessage,
    CreateSkillRequestMessage,
    BaseSkill,
    Skill,
    BasePreset,
    Preset,
    BaseSkillDiscipline,
    SkillDiscipline,
    ISkillsResponse, StudentSkill
} from './apiTypes';
import { emptyBaseApi } from './emptyBaseApi';

export const skillsApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({

        // skills

        createSkill: build.mutation<ResponseMessage<BaseSkill>, CreateSkillRequestMessage>({
            query: (data) => {
                return {
                    url: 'skills/create',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось создать компетенцию');
                }
            },
            invalidatesTags: ['Skills', 'SkillDisciplines']
        }),

        getSkills: build.query<ResponseMessage<Skill>[], { uuids: string[] } | undefined>({
            query: (data) => {
                return {
                    url: 'skills/get',
                    method: 'POST',
                    body: data
                };
            },
            transformResponse: (results: { skills:ResponseMessage<Skill>[] }) => {
                return results.skills;
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось получить компетенции');
                }
            },
            providesTags: ['Skills']
        }),

        updateSkill: build.mutation<ResponseMessage<BaseSkill>, WithUUID<CreateSkillRequestMessage>>({
            query: (data) => {
                return {
                    url: 'skills/update',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось изменить компетенцию');
                }
            },
            invalidatesTags: ['Skills', 'SkillDisciplines']
        }),

        deleteSkill: build.mutation<void, string>({
            query: (skillUUID) => {
                return {
                    url: 'skills/delete',
                    method: 'DELETE',
                    body: { uuid: skillUUID }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось удалить компетенцию');
                }
            },
            invalidatesTags: ['Skills', 'SkillDisciplines']
        }),

        getStudentSkills: build.query<ISkillsResponse, { uuidStudent: string } | undefined > ({
            query(data) {
                return {
                    url: 'skills/student/get',
                    method: 'POST',
                    body: data ? { ...data } : undefined
                };
            }
        }),

        // disciplines

        createSkillDisciplines: build.mutation<ResponseMessage<BaseSkillDiscipline>, SkillDiscipline>({
            query: (data) => {
                return {
                    url: 'skills/disciplines/create',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Плановая дисциплина успешно создана');
                } catch (error) {
                    toast.error('Не удалось создать плановую дисциплину');
                }
            },
            invalidatesTags: ['Skills', 'SkillDisciplines', 'Presets']
        }),

        getSkillDisciplines: build.query<ResponseMessage<SkillDiscipline>[], { uuidsDiscipline: string[] } | undefined>({
            query: (data) => {
                return {
                    url: 'skills/disciplines/get',
                    method: 'POST',
                    body: data
                };
            },
            transformResponse: (results: { disciplines:ResponseMessage<SkillDiscipline>[] }) => {
                return results.disciplines;
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось получить базовые дисциплины');
                }
            },
            providesTags: ['SkillDisciplines']
        }),

        updateSkillDiscipline: build.mutation<ResponseMessage<BaseSkillDiscipline>, WithUUID<SkillDiscipline>>({
            query: (data) => {
                return {
                    url: 'skills/disciplines/update',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Плановая дисциплина успешно изменена');
                } catch (error) {
                    toast.error('Не удалось изменить плановую дисциплину');
                }
            },
            invalidatesTags: ['Skills', 'SkillDisciplines', 'Presets']
        }),

        deleteSkillDiscipline: build.mutation<void, string>({
            query: (uuid) => {
                return {
                    url: 'skills/disciplines/delete',
                    method: 'DELETE',
                    body: { uuid }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось удалить базовую дисциплину');
                }
            },
            invalidatesTags: ['Skills', 'SkillDisciplines', 'Presets']
        }),

        // presets

        createPreset: build.mutation<ResponseMessage<BasePreset>, Preset>({
            query: (data) => {
                return {
                    url: 'skills/presets/create',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось создать пресет');
                }
            },
            invalidatesTags: ['SkillDisciplines', 'Presets']
        }),

        getPresets: build.query<ResponseMessage<Preset>[], { uuidsPreset: string[] } | undefined>({
            query: (data) => {
                return {
                    url: 'skills/presets/get',
                    method: 'POST',
                    body: data
                };
            },
            transformResponse: (results: { presets:ResponseMessage<Preset>[] }) => {
                return results.presets;
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось получить пресеты');
                }
            },
            providesTags: ['Presets']
        }),

        updatePreset: build.mutation<ResponseMessage<BasePreset>, WithUUID<Preset>>({
            query: (data) => {
                return {
                    url: 'skills/presets/update',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось изменить пресет');
                }
            },
            invalidatesTags: ['SkillDisciplines', 'Presets']
        }),

        deletePreset: build.mutation<void, string>({
            query: (uuid) => {
                return {
                    url: 'skills/presets/delete',
                    method: 'DELETE',
                    body: { uuid }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось удалить пресет');
                }
            },
            invalidatesTags: ['SkillDisciplines', 'Presets']
        }),

        getStudentSkillByDiscipline: build.query<ResponseMessage<StudentSkill[]>, { uuidsDiscipline: string }>({
            query: (data) => {
                return {
                    url: 'skills/get/real/disciplines',
                    method: 'POST',
                    body: { uuid: data.uuidsDiscipline }
                };
            },
            transformResponse: (results: { skills: ResponseMessage<StudentSkill[]> }) => {
                return results.skills;
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось получить список компетенций');
                }
            },
            providesTags: ['Skills', 'SkillDisciplines']
        })
    })
});

export const {
    useCreateSkillMutation,
    useGetSkillsQuery,
    useUpdateSkillMutation,
    useDeleteSkillMutation,
    useGetStudentSkillsQuery,
    useGetStudentSkillByDisciplineQuery,
    //
    useCreateSkillDisciplinesMutation,
    useGetSkillDisciplinesQuery,
    useUpdateSkillDisciplineMutation,
    useDeleteSkillDisciplineMutation,
    //
    useCreatePresetMutation,
    useGetPresetsQuery,
    useUpdatePresetMutation,
    useDeletePresetMutation
} = skillsApi;
