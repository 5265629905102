import { FC, useState } from 'react';

import IconUI from '@components/core/IconUI';
import { ProgressIndicator } from '@components/core/ProgressBar/ProgressBar';
import { ImgWithDummy } from '@components/ImgWithDummy';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { StudentSkill } from '@store/api/apiTypes';
import { useGetFileQuery } from '@store/api/storageApi';
import { SkillModal } from '@views/student/StudentProfile/StudentProfileInfo/Skills/SkillModal';

import style from './skills.module.scss';

export const SkillCard: FC<{ skill: StudentSkill }> = ({ skill }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { data } = useGetFileQuery(skill.uuidPicture ? skill.uuidPicture : skipToken);
    const imagePath = data?.files?.[0]?.path;

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const closeModal = () => setIsModalOpen(false);

    return (
        <>
            <div className={style.card_item} onClick={toggleModal}>
                <ImgWithDummy
                    width={34}
                    src={imagePath}
                    alt="Изображение компетенции"
                />
                <div
                    className={`${skill.progress !== 100 && style.card_title_blocked} ${style.card_title}`}
                >
                    {skill.name}
                </div>
                <div className={style.progress}>
                    <div className={style.name_progress}>
                        <IconUI typeIcon='hat-graduation' />
                        {`Освоено ${skill.progress ?? 0}%`}
                    </div>
                    <ProgressIndicator completed={skill.progress ?? 0} />
                </div>
                <div className={`${style.card_sticker} ${skill.progress !== 100 && style.card_sticker_disabled}`} />
            </div>

            <SkillModal skill={skill} isOpen={isModalOpen} close={closeModal} imagePath={imagePath} />
        </>
    );
};
