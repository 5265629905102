import { FileMessage, MessageType, TextMessage } from '@type/chat';

type SendingMessage = (TextMessage | FileMessage) & { key: string | null, loading: boolean };
type AdaptedMessage = (TextMessage | FileMessage) & { key: string | null, loading: boolean, id: string };
type SaveToLocal = (message: SendingMessage) => void;
type WsHandler = (message: SendingMessage) => void;

interface MessageQueue {
    id: string;
    message: SendingMessage,
    handler: WsHandler
}

const MAX_TEXT_SIZE = 2000;

export class MessageAdapter {
    private _messages: MessageQueue[] = [];

    private _splitText(text: string): string[] {
        const returnedTexts: string[] = [];

        const textsBySymbols = text.split(/([\f\n\r\s\t\v])/);

        let textForChunk = '';

        textsBySymbols.forEach(textItem => {
            // строка с учетом нового элемента
            const newTextForChunk = textForChunk + textItem;

            if (newTextForChunk.length > MAX_TEXT_SIZE) {
                if (!textForChunk.length) {
                    // когда новый элемент сам больше максимального размера
                    const numbersOfShares = Math.ceil(textItem.length / MAX_TEXT_SIZE);
                    new Array(numbersOfShares)
                        .fill(null)
                        .map((_, index) => {
                            const textShares = textItem.substring(index * MAX_TEXT_SIZE, index * MAX_TEXT_SIZE + MAX_TEXT_SIZE);
                            returnedTexts.push(textShares);
                        });
                } else {
                    returnedTexts.push(textForChunk);
                    textForChunk = textItem;
                }
            } else {
                textForChunk = newTextForChunk;
            }
        });

        if (textForChunk.length) {
            returnedTexts.push(textForChunk);
        }

        return returnedTexts.map(el => el.trim()).filter(el => el);
    }

    private _splitMessageByChunk(message: SendingMessage): AdaptedMessage[] {
        const chunks = this._splitText(message.text ?? '');
        return chunks
            .map((text) => {
                return {
                    ...message,
                    id: `${message.id}`,
                    text
                };
            })
            .filter(chunk => chunk.text);
    }

    pushMessage(message: SendingMessage, saveToLocal: SaveToLocal, wsHandler: WsHandler) {
        const chunksOfMessage = this._splitMessageByChunk(message);

        chunksOfMessage.forEach((chunk, index) => {
            if (index === 0) {
                saveToLocal(chunk);
                wsHandler(chunk);
            } else {
                this._messages.push({ id: chunk.id, message: chunk, handler: wsHandler });
            }
        });
    }

    receiveMessage(message: AdaptedMessage, saveToLocal: SaveToLocal): void {
        saveToLocal(message);
        if (message.type === MessageType.File) {
            return;
        }
        const chunks = this._messages.filter(({ id }) => id.split('-')[0] === message.id.split('-')[0]);

        if (chunks.length) {
            const splitedMessageId = message.id.split('-');
            const currentChunk = splitedMessageId[splitedMessageId.length - 1];
            const nextChunk = chunks.find(chunk => {
                const splitedChunkId = chunk.message.id?.split('-');
                return Number(splitedChunkId[splitedChunkId.length - 1]) === Number(currentChunk) + 1;
            }) || chunks[0];

            nextChunk.handler(nextChunk.message);
            this._messages = this._messages.filter((chunk) => chunk.message.id !== nextChunk.message.id);
        }
    }
}
