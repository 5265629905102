import { ReactComponent as FirstCompetence } from '@assets/image/firstCompetence.svg';
import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import SwitchFade from '@components/SwitchFade';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useGetSkillsQuery } from '@store/api/skillsApi';

import SkillCard from './SkillCard';
import styles from './SkillsSettings.module.scss';

const SkillsSettings = () => {
    const { data: skills, isLoading } = useGetSkillsQuery(undefined);

    const [animationParent] = useAutoAnimate();

    return (
        <>
            <h1 className={styles.header}>Kомпетенции</h1>
            <SwitchFade
                className={styles.fullWidth}
                slotIndex={isLoading ? 0 : skills?.length ? 1 : 2}
                slots={[
                    <NewLoader key={0} />
                    ,
                    <div className={styles.grid} key={1}>
                        <Button typeIcon='add' className={styles.button} link to="add">Добавить компетенцию</Button>
                        <div className={styles.cards} ref={animationParent}>
                            {skills?.map(item => <SkillCard key={item.uuid} iconUUID={item.uuidPicture} name={item.name} UUID={item.uuid} />)}
                        </div>
                    </div>
                    ,
                    <div className={styles.empty} key={2}>
                        <FirstCompetence />
                        <p className={styles.text}>Создайте свою первую компетенцию</p>
                        <Button typeIcon='add' className={styles.button} link to="add">Добавить компетенцию</Button>
                    </div>
                ]}
            />
        </>
    );
};

export default SkillsSettings;
