import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { LinkUI } from '@components/core/LinkUI';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { findCategory } from '@helpers/categories';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useGetMaterialsSectionsListQuery } from '@store/api/materialsApi';
import { useCreateSectionMutation } from '@store/api/sectionsApi';
import SectionForm from '@views/methodist/Materials/Course/EditCourse/Structure/SectionForm';
import { publishedSelectData } from '@views/methodist/Materials/Course/EditCourse/Structure/SectionForm/SectionForm';
import { SectionFormDataType } from '@views/methodist/Materials/Course/EditCourse/Structure/SectionForm/types';

import style from './CreateSection.module.scss';


const CreateSection = () => {
    const navigate = useNavigate();
    const { uuidDiscipline, uuidCategory } = useParams();
    const [defaultValuesForm] = useState<SectionFormDataType>({
        name: '',
        description: '',
        published: publishedSelectData[0],
        accessLimitation: false,
        blockedGroups: []
    });
    const { data: sectionsList } = useGetMaterialsSectionsListQuery(uuidDiscipline!);
    const [createSection, { isLoading, isError, isSuccess }] = useCreateSectionMutation();
    const groupsTree = useGetGroupsTreeQuery('');

    const categoriesQueryData = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000');
    const category = findCategory(categoriesQueryData.data, uuidCategory);
    const { data: disciplineData } = useGetDisciplineByIdQuery(uuidDiscipline as string);

    const submitFormHandler = (formData: SectionFormDataType) => {

        const createSectionData = {
            uuidDiscipline: uuidDiscipline,
            name: formData.name,
            description: formData.description,
            published: formData.published.value!,
            position: sectionsList && sectionsList.length + 1 | 0,
            blockedGroups: formData.blockedGroups || []
        };
        // здесь нужно погуглить, как в схеме для формы отрубать undefined и null, при её создании
        // @ts-ignore
        createSection(createSectionData);
    };

    useEffect(() => {
        if (isSuccess) {
            navigate('./..');
        }
    }, [isSuccess]);

    if (isLoading || groupsTree.isFetching) return <NewLoader />;

    return (
        <Main title={category && disciplineData && `Учебные материалы – ${category.name}: ${disciplineData.name}, Структура, Добавление раздела`}>
            <div className={style.title_wrapper}>
                <LinkUI
                    to={'./..'}
                    typeIcon={'arrowLink'}
                />
                <h1>Добавить раздел</h1>
            </div>
            <SectionForm submitFormHandler={submitFormHandler} defaultValues={defaultValuesForm} groups={groupsTree.data} />
        </Main>
    );
};

export default CreateSection;
