import { useState } from 'react';

import IconUI from '@components/core/IconUI';
import { LinkUI } from '@components/core/LinkUI';

import style from './SocialNetwork.module.scss';

const initialState = [
    { name: 'Вконтакте', link: 'https://vk.com/institute_of_psychoanalysis?roistat_visit=447165', icon: <IconUI typeIcon={'vk'} /> },
    { name: 'Телеграм', link: 'https://t.me/eeipru?roistat_visit=447165', icon: <IconUI typeIcon={'telegram'} /> },
    { name: 'YouTube', link: 'https://www.youtube.com/c/EeipRu', icon: <IconUI typeIcon={'youTubeSecond'} /> },
    { name: 'RuTube', link: 'https://rutube.ru/channel/23853410/?roistat_visit=447165', icon: <IconUI typeIcon={'ruTube'} /> },
    { name: 'Одноклассники', link: 'https://ok.ru/group/62411828625625?roistat_visit=447165', icon: <IconUI typeIcon={'ok'} /> },
    { name: 'Dzen', link: 'https://zen.yandex.ru/eeipru', icon: <IconUI typeIcon={'dzen'} /> }
];

const SocialNetwork = () => {
    const [socialNetworkList] = useState(initialState);

    return (
        <ul className={style.list}>
            {
                socialNetworkList.map((item, index) => (
                    <li key={index}>
                        <LinkUI typeLink='default' target='_blank' to={item.link} className={style.link}>{item.icon}</LinkUI>
                    </li>
                ))
            }
        </ul>
    );
};

export default SocialNetwork;
