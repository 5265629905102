import { TestQuestion } from '@store/api/apiTypes';

export type TestQuestionWithName = {
    name: string,
    uuid: string,
    type: string
}

export const getQuestionHumanType = (questionType: string) =>{
    switch (questionType)
    {
        case 'SINGLE':
        {
            return 'Одиночный выбор';

        }
        case 'MULTIPLE':
        {
            return 'Множественный выбор';

        }

        case 'TO_MATCH':
        {
            return 'Сопоставление';

        }
        default:
            return questionType;
    }
};
