import React from 'react';

import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { useBreakpoint } from '@hooks/useBreakpoint';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Event } from '@store/api/apiTypes';
import { InfoEvent } from '@type/eventCalendar';

import { EventInfo } from '../EventInfo';
import style from './EventSideBar.module.scss';

interface EventSideBarProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    eventInfo?: Event;
    setInfoEvent: (obj: InfoEvent) => void;
}

export const EventSideBar: React.FC<EventSideBarProps> = ({ isOpen, onOpen, onClose, eventInfo, setInfoEvent }) => {
    const smDown = useBreakpoint('sm', 'down');

    if (!eventInfo) return null;

    return (
        <SwipeableDrawer anchor={smDown ? 'bottom' : 'right'} open={isOpen} onOpen={onOpen} onClose={onClose} classes={{ paper: style.paper }}>
            <div className={style.container}>
                {smDown && <div className={style.container_handle} />}
                {!smDown && (
                    <>
                        <h1>Мероприятия</h1>

                        <ButtonIcon className={style.close} onClick={onClose}>
                            <CloseIcon />
                        </ButtonIcon>
                    </>
                )}


                <div className={style.content}>
                    <EventInfo eventInfo={eventInfo} setInfoEvent={setInfoEvent} />
                </div>
            </div>
        </SwipeableDrawer>
    );
};
