import { OptionsMapWithEndChildren, OptionsMapWithParent } from '@components/core/inputs/Select/NewTreeSelect/types';
import { TreeSelectOption, TreeSelectOptions } from '@components/core/inputs/Select/type';
import { GroupTree } from '@store/api/apiTypes';

// Это вродь негде не юзается, над спросить.
// export const getFlatGroup = (groups: GroupTree[] | undefined) => {
//     return groups && groups.map(g => [{ label: g.name, value: g.UUID }, ...getFlatGroup(g.childs)]).flat();
// };

export const getFlatTreeSelectOptions = (groups: TreeSelectOptions): TreeSelectOptions => {
    return groups && groups.map(g => [{ ...g }, ...getFlatTreeSelectOptions(g.children)]).flat();
};

export const getTransformGroupData = (groups: GroupTree[]): TreeSelectOptions => {
    const recursiveCallback = (option: GroupTree): TreeSelectOption => ({
        value: option.UUID,
        label: option.name,
        children: option.childs.map(recursiveCallback)
    });
    return groups?.map(recursiveCallback);
};

export const findDefaultValueOption = (groups: TreeSelectOptions, blockedGroups: string[]): string[] => {
    const findDefaultOptions = getFlatTreeSelectOptions(groups)?.filter(groupOption => {
        return blockedGroups.find(group => group === groupOption.value);
    });
    return findDefaultOptions.reduce((result: string[], item) => {
        result.push(item.value);
        return result;
    }, []);
};

export interface FlatGroupWithEndsChildren extends GroupTree {
    endChildren: GroupTree[]
}

export type GroupsFlatMapWithEndsChildren  = Record<string, FlatGroupWithEndsChildren>;


export const getFlatGroupsWithEndsChildren = (groups: GroupTree[]): GroupsFlatMapWithEndsChildren => {
    const groupsMap: GroupsFlatMapWithEndsChildren = {};

    groups.forEach((group) => {
        if (group.childs.length) {
            const childs = getFlatGroupsWithEndsChildren(group.childs);
            const childsArray = Object.values(childs);

            groupsMap[group.UUID] = {
                ...group,
                endChildren: childsArray
                    ?.map((childOption) => !childOption.endChildren.length ? childOption : null)
                    ?.filter((childOption) => childOption !== null) as GroupTree[]
            };

            Object.values(childs)?.forEach((el) => {
                groupsMap[el.UUID] = {
                    ...el,
                    endChildren: el.endChildren?.map((childOption) => childOption)
                };
            });
        } else {
            groupsMap[group.UUID] = {
                ...group,
                endChildren: []
            };
        }
    });

    return groupsMap;
};
