import React from 'react';

import { ReactComponent as UserIcon } from '@assets/icons/user-valid.svg';
import IconUI from '@components/core/IconUI';

import style from './NoMessages.module.scss';

interface NoMessagesProps {
    type: 'messages' | 'contacts';
    onClose?: () => void;
}

const NoMessages: React.FC<NoMessagesProps> = ({ type, onClose }) => {

    const title = type === 'messages'
        ? 'Выберите диалог, чтобы начать общение'
        : 'Список диалогов пуст';

    return (
        <div className={style.container}>
            {type === 'messages' && (
                <div className={style.closeIcon} onClick={onClose || (() => null)}>
                    <IconUI typeIcon='close' />
                </div>
            )}

            <UserIcon />
            <h3>{title}</h3>
            <p>Начните переписку с другими пользователями через страницу курса или через раздел контакты</p>
        </div>
    );
};

export default NoMessages;
