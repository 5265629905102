import React, { useState, FC, useEffect } from 'react';

import { formatISO9075 } from 'date-fns';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import Checkbox from '@components/core/inputs/Checkbox';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import Switcher from '@components/core/inputs/Switcher';
import { DPControl } from '@components/DatePicker/DPControl';
import { dateConvert } from '@helpers/date';
import { yupResolver } from '@hookform/resolvers/yup';
import useDidUpdateEffect from '@hooks/useDidUpdate.hook';
import { CloseTypes, IDiscipline, SessionTypes } from '@store/api/apiTypes';
import {
    addItem, addToMassEdit, removeFromMassEdit, removeItem,
    selectedItemById, selectMassPing, selectMassValues, selectResetPing
} from '@store/features/subscribeSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';

import style from './SubscribeItem.module.scss';

export interface ISubscribeItemProps {
    item: IDiscipline;
}

const subscribeItemSchema = yup.object({
    session: yup.object(),
    closeType: yup.object(),
    startDate: yup.string().required()
});

const sessionMap = {
    [SessionTypes.Semester1]: { label: 'Семестр 1', value: SessionTypes.Semester1 },
    [SessionTypes.Semester2]: { label: 'Семестр 2', value: SessionTypes.Semester2 },
    [SessionTypes.Semester3]: { label: 'Семестр 3', value: SessionTypes.Semester3 },
    [SessionTypes.Semester4]: { label: 'Семестр 4', value: SessionTypes.Semester4 },
    [SessionTypes.Semester5]: { label: 'Семестр 5', value: SessionTypes.Semester5 },
    [SessionTypes.Semester6]: { label: 'Семестр 6', value: SessionTypes.Semester6 },
    [SessionTypes.Semester7]: { label: 'Семестр 7', value: SessionTypes.Semester7 },
    [SessionTypes.Semester8]: { label: 'Семестр 8', value: SessionTypes.Semester8 }
};

const closeMap = {
    [CloseTypes.Assessment]: { label: 'Зачет', value: CloseTypes.Assessment },
    [CloseTypes.AssessmentWithScore]: { label: 'Зачет с оценкой', value: CloseTypes.AssessmentWithScore },
    [CloseTypes.Exam]: { label: 'Экзамен',value: CloseTypes.Exam },
    [CloseTypes.NoMark]: { label: 'Без оценки', value: CloseTypes.NoMark }
};

export const SubscribeItem: FC<ISubscribeItemProps> = ({
    item
}) => {
    const [isInit, setInit] = useState(true);
    const [isSwitch, setSwitch] = useState(false);
    const [checked, setChecked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useAppDispatch();

    const massPing = useAppSelector(selectMassPing);
    const massValues = useAppSelector(selectMassValues);
    const resetPing = useAppSelector(selectResetPing);
    const itemFromStore = useAppSelector((state) => selectedItemById(state, item.uuid));

    const methods = useForm({
        defaultValues: {
            session: { label: 'Семестр 1', value: SessionTypes.Semester1 },
            closeType: { label: 'Зачет', value: CloseTypes.Assessment },
            startDate: ''
        },
        resolver: yupResolver(subscribeItemSchema)
    });

    const { control, formState, setValue, getValues } = methods;

    const watchAllFields = useWatch({
        control
    });

    useEffect(() => {
        if (isInit && itemFromStore) {
            setInit(false);
            setIsOpen(true);
            setValue('session', sessionMap[itemFromStore.session]);
            setValue('closeType', closeMap[itemFromStore.closeType]);
            setValue('startDate', itemFromStore.startDate, { shouldValidate: true });
        }
    }, [itemFromStore]);

    const getFormItem = () => {
        const fromValues = getValues();

        return {
            uuid: item.uuid,
            session: fromValues.session.value,
            closeType: fromValues.closeType.value,
            startDate: fromValues.startDate,
            disabled: itemFromStore?.disabled
        };
    };

    useEffect(() => {
        if (formState.isValid && isSwitch) {
            dispatch(addItem(getFormItem()));
        }
    }, [watchAllFields]);


    useDidUpdateEffect(() => {
        if (checked) {
            setValue('session', sessionMap[massValues.session]);
            setValue('closeType', closeMap[massValues.closeType]);
            setValue('startDate', massValues.startDate, { shouldValidate: true });
        }
    }, [massPing]);

    useDidUpdateEffect(() => {
        if (checked) {
            setChecked(false);
        }
    }, [resetPing]);

    const onSelectWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);

        if (event.target.checked) {
            setIsOpen(true);
            dispatch(addToMassEdit(item.uuid));
        } else {
            dispatch(removeFromMassEdit(item.uuid));
            dispatch(removeItem(item.uuid));
        }
    };

    const onSwitchWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSwitch(event.target.checked);

        if (event.target.checked) {
            dispatch(addItem(getFormItem()));
        } else {
            dispatch(removeItem(item.uuid));
        }
    };

    const onDateSubmit = (date: string) => {
        setValue('startDate', date, { shouldValidate: true });
    };

    useEffect(() => {
        if (formState.isValid) {
            setSwitch(true);
            dispatch(addItem(getFormItem()));
        }
    }, [formState.isValid]);

    const renderForms = (
        <div className={style.forms}>
            <div className={style.control}>
                <SelectStyled
                    styled={{ width: '100%' }}
                    name="session"
                    control={control}
                    options={[
                        { label: 'Семестр 1', value: SessionTypes.Semester1 },
                        { label: 'Семестр 2', value: SessionTypes.Semester2 },
                        { label: 'Семестр 3', value: SessionTypes.Semester3 },
                        { label: 'Семестр 4', value: SessionTypes.Semester4 },
                        { label: 'Семестр 5', value: SessionTypes.Semester5 },
                        { label: 'Семестр 6', value: SessionTypes.Semester6 },
                        { label: 'Семестр 7', value: SessionTypes.Semester7 },
                        { label: 'Семестр 8', value: SessionTypes.Semester8 }
                    ]}
                    isDisabled={itemFromStore?.disabled}
                />
            </div>

            <div className={style.control}>
                <SelectStyled
                    styled={{ width: '100%' }}
                    name="closeType"
                    control={control}
                    options={[
                        { label: 'Зачет', value: CloseTypes.Assessment },
                        { label: 'Зачет с оценкой', value: CloseTypes.AssessmentWithScore },
                        { label: 'Экзамен', value: CloseTypes.Exam },
                        { label: 'Без оценки', value: CloseTypes.NoMark }
                    ]}
                    isDisabled={itemFromStore?.disabled}
                />
            </div>

            <div className={style.control}>
                <DPControl
                    onSubmit={onDateSubmit}
                    defaultValue={getFormItem().startDate}
                    variant='date'
                    isButton={false}
                    style={{ height: 34 }}
                    disabled={itemFromStore?.disabled}
                />
            </div>
        </div>
    );

    return <>
        <div className={style.subscribeItem}>
            <div className={style.subscribeItemHeader}>
                <div className={style.headerWrapper}>
                    <div className={style.checkbox}>
                        <Checkbox checked={checked} onChange={onSelectWrapper} disabled={itemFromStore?.disabled} />
                    </div>

                    <div onClick={() => setIsOpen(!isOpen)} className={style.caption}>
                        { item?.name }
                    </div>
                </div>

                <div className={style.switcher}>
                    <Switcher value={isSwitch} disabled={!formState.isValid || itemFromStore?.disabled} onChange={onSwitchWrapper} />
                </div>
            </div>

            { isOpen && renderForms }
        </div>

    </>;
};
