import React, { FC, useCallback, useEffect, useState } from 'react';

import clx from 'classnames';

import { useLazyGetBiblioClubSSOSignQuery, useLazyGetUraitSSOQuery } from '@store/api/ssoApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';

import { generateBiblioLink } from './helper';


export interface ISSOLinkProps {
    href: string;
    children?: React.ReactNode;
    className?: string;
    value: string | React.ReactNode;
    domain: string;
}

type LinksComponent = { [key: string]: JSX.Element }

export const SSOLink:FC<ISSOLinkProps> = ({ href, value, domain, children, className }) => {

    const user                  = useAppSelector(selectApiUser);
    const [triggerUraitSSOLink] = useLazyGetUraitSSOQuery();
    const [tiggerBiblioSSoLink] = useLazyGetBiblioClubSSOSignQuery();

    const aChildren = value || href || children; 

    const onClickUrait = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement> ) => {
            const TIME = Math.round(Date.now() / 1000).toString();
    
            if (!user) return;

            const windowReference = window.open();
    
            triggerUraitSSOLink({
                email: user.email,
                first_name: user.firstName,
                last_name: user.lastName,
                redirect_url: href,
                time: TIME,
                second_name: user.patronymic                
            }).then((res) => {
                if (!res.data || !windowReference) return;
                windowReference.location = res.data.url;
            });
        },
        [href, user]
    );

    const onClickBiblio = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement> ) => {           
            if (!user) return;

            const time = Math.round(Date.now() / 1000);
            const windowReference = window.open();

            tiggerBiblioSSoLink({
                time,
                user_uuid: user.uuid
            }).then((res) => {
                if (!res.data || !windowReference) return;
                windowReference.location = generateBiblioLink(href, user, res.data);
            });
        },
        [href, user]
    );


    const renderLinks = useCallback(() => {

        const linkComponents: LinksComponent = {
            'urait.ru': <span onClick={onClickUrait} onAuxClick={onClickUrait} className={clx({ 'link': !children })}>{aChildren}</span>,
            'biblioclub.ru': <span onClick={onClickBiblio} onAuxClick={onClickBiblio} className={clx({ 'link': !children })}>{aChildren}</span>
        };
        return linkComponents[domain] || null;
    }, [href, value, user, domain, className, children]);

    return <>{renderLinks()}</>;
};