import { FC } from 'react';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { RoleCheck } from '@components/RoleCheck';
import UserAvatar from '@components/UserAvatar';
import { Role } from '@store/Roles';
import { User }  from '@type/users';
import style from '@views/methodist/Materials/Course/CreateCourse/CreateCourseForm/create_course_form.module.scss';

type FieldIndex = number

interface IListItem {
    user: User;
    fieldIndex: FieldIndex;
    deleteUser: (fieldIndex: FieldIndex) => void;
    caption?: string
}

const ListItem: FC<IListItem> = ({ user, fieldIndex, deleteUser, caption }) => {
    return (
        <>
            {
                user ? <li className={style.content_list__item}>
                    <p>
                        {
                            caption || (user.role === Role.Methodist ? 'Методист' : (
                                user.role === Role.Teacher ? 'Преподаватель' : ''
                            ))
                        }
                    </p>

                    <div className={style.content_list__user_name}>
                        <UserAvatar className={style.avatar_color}  name={user?.firstName} lastName={user?.lastName} avatar={user?.picture?.path} />
                        <p>{`${user.lastName} ${user.firstName} ${user.patronymic}`}</p>
                    </div>

                    <RoleCheck roles={Role.Methodist}>
                        <div className={style.content_list__button}>
                            <ButtonIcon
                                className={style.content_list__button_hover}
                                typeIcon={'basket'}
                                onClick={() => deleteUser(fieldIndex)}
                            />
                        </div>
                    </RoleCheck>
                </li> : null
            }
        </>
    );
};

export default ListItem;
