import React, { useState } from 'react';

import { Breadcrumbs } from '@components/Breadcrumbs';
import IconUI from '@components/core/IconUI';

import styles from './HierarchyItem.module.scss';

type TProps = {
    options: string[];
    onDeleteClick?: () => void;
    isOption?: boolean;
};

const HierarchyItem = ({ options, onDeleteClick, isOption }: TProps) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <div className={styles.discipline} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <Breadcrumbs>
                {options.filter(Boolean).map((name, index, arr) => (
                    <span
                        key={name}
                        className={`${index === arr.length - 1 && styles.lastElement} ${styles.element} ${isOption && styles.option}`}
                    >
                        {name}
                    </span>
                ))}
            </Breadcrumbs>
            {onDeleteClick && (
                <div onClick={onDeleteClick} className={styles.closeButton} style={{ opacity: isHover ? 1 : 0 }}>
                    <IconUI typeIcon='close' />
                </div>
            )}
        </div>
    );
};

export default HierarchyItem;
