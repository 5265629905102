import { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import classNames from 'classnames/bind';

import styles from './toltip.module.scss';

const cx = classNames;

interface IProps extends ButtonHTMLAttributes<HTMLDivElement> {
    className?: string;
    children?: ReactNode;
    display?: 'flex' | 'none';
}

const Tooltip: FC<IProps> = ({ className, children, ...props }) => {
    return (
        <div className={cx(styles.root, className)} {...props}>
            <span>{children}</span>
        </div>
    );
};

export default Tooltip;
