import { toast } from 'react-toastify';

import {
    ChapterLectureBlockProgress,
    CreateLectureChapterRequest,
    CreateLectureRequest,
    CreateLectureTopicRequest,
    DeleteLectureRequest,
    Lecture,
    LectureBlock,
    LectureBlockCreateData,
    LectureBlocksContent,
    LectureChapter,
    LectureTopic,
    UpdateLectureChapterRequest,
    UpdateLectureRequest,
    UpdateLectureTopicRequest,
    UpdatePositionsLectureTopicData,
    Uuid
} from '@store/api/apiTypes';

import { emptyBaseApi } from './emptyBaseApi';

export const lecturesApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        // getLectureBlocksList: build.query<LectureBlock[], string>({
        //     query: (sectionId: string) => `lectures/blocks/list/${sectionId}`,
        //     async onQueryStarted(args, { queryFulfilled }) {
        //         try {
        //             await queryFulfilled;
        //         } catch (error) {
        //             toast.error('Не смогли получить лекции');
        //         }
        //     },
        //     transformResponse: (result: { blocks: LectureBlock[] }) => {
        //         return result.blocks;
        //     },
        //     providesTags: ['LectureBlocks']
        // }),

        // getLectureBlockById: build.query<LectureTopic[], string>({
        //     query: (blockId: string) => `lectures/blocks/find/${blockId}`,
        //     async onQueryStarted(args, { queryFulfilled }) {
        //         try {
        //             await queryFulfilled;
        //         } catch (error) {
        //             toast.error('Не смогли получить блок лекций');
        //         }
        //     },
        //     transformResponse: (result: { topics: { topics: LectureTopic[]} }) => {
        //         return result.topics.topics;
        //     },
        //     providesTags: ['LectureBlocks']
        // }),

        createLectureBlock: build.mutation<LectureBlock, LectureBlockCreateData>({
            query(data) {
                const requestData = {
                    name: data.name,
                    uuidParent: data.uuidParent
                };

                return {
                    url: 'lectures/blocks/create',
                    method: 'POST',
                    body: requestData
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Создан блок лекций',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['LectureBlocks', 'SectionList']
        }),

        deleteLectureBlock: build.mutation<void, string>({
            query(uuidBlock) {
                return {
                    url: 'lectures/blocks/delete',
                    method: 'POST',
                    body: {
                        uuids: [uuidBlock]
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Удалено',
                    error: 'Ошибка удаления!'
                });
            },
            invalidatesTags: ['LectureBlocks', 'SectionList']
        }),

        getLectureTopicsByBlock: build.query<LectureTopic[], string>({
            query: (uuidBlock: string) => `lectures/topics/list/${uuidBlock}`,
            transformResponse: (result: { topics: LectureTopic[] }) => {
                return result.topics;
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список тем');
                }
            },
            providesTags: ['Topics']
        }),

        getLectureBlockProgress: build.query<ChapterLectureBlockProgress[], string>({
            query: (uuidBlock: string) => `lectures/blocks/progress/${uuidBlock}`,
            transformResponse: (result: { chapters: ChapterLectureBlockProgress[] }) => {
                return result.chapters;
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить прогресс глав');
                }
            },
            providesTags: (result, error, arg) => [{ type: 'ChaptersProgress', id: arg }]
        }),

        createLectureTopic: build.mutation<LectureTopic, CreateLectureTopicRequest>({
            query(data) {
                const requestData = {
                    name: data.name,
                    uuidBlock: data.uuidBlock,
                    position: data.position
                };

                return {
                    url: 'lectures/topics/create',
                    method: 'POST',
                    body: requestData
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Тема создана',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Topics']
        }),

        deleteLectureTopic: build.mutation<void, string>({
            query(topicUuid) {
                const requestData = {
                    uuids: [topicUuid]
                };
                return {
                    url: 'lectures/topics/delete',
                    method: 'POST',
                    body: requestData
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Тема удалена',
                    error: 'Ошибка удаления!'
                });
            },
            invalidatesTags: ['Topics']
        }),

        updateLectureTopic: build.mutation<LectureTopic, UpdateLectureTopicRequest>({
            query(data) {
                const requestData = {
                    name: data.name,
                    uuid: data.uuid
                };

                return {
                    url: 'lectures/topics/update',
                    method: 'POST',
                    body: requestData
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Сохранено',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Topics']
        }),

        updateLectureTopicsPositions: build.mutation<void, UpdatePositionsLectureTopicData>({
            query(data) {
                return {
                    url: 'lectures/topics/positions',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Сохранено',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Topics']
        }),

        getLecturesByTopic: build.query<Lecture[], string>({
            query: (topicId: string) => `lectures/lectures/list/${topicId}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить лекции');
                }
            },
            transformResponse: (result: { lectures: Lecture[] }) => {
                return result.lectures;
            },
            providesTags: (result, error, arg) => [{ type: 'Lectures_LIST', id: arg }]
        }),

        getLectureById: build.query<Lecture, string>({
            query: (lectureId: string) => `lectures/lectures/find/${lectureId}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить лекцию');
                }
            },
            transformResponse: (result: Lecture) => {
                return result;
            },
            providesTags: (result, error, arg) => result ?
                [...result.chapters.map(chapter => ({ type: 'Chapters' as const, id: chapter.uuid })), { type: 'Lectures', id: arg }] :
                [{ type: 'Lectures', id: arg }]
        }),

        createLecture: build.mutation<Lecture, CreateLectureRequest>({
            query(data) {
                const requestData = {
                    name: data.name,
                    uuidTopic: data.uuidTopic,
                    position: data.position
                };

                return {
                    url: 'lectures/lectures/create',
                    method: 'POST',
                    body: requestData
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Лекция создана',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Lectures_LIST', id: arg.uuidTopic }]
        }),

        updateLecture: build.mutation<Lecture, UpdateLectureRequest>({
            query(data) {
                const requestData = {
                    name: data.name,
                    uuid: data.uuid
                };

                return {
                    url: 'lectures/lectures/update',
                    method: 'POST',
                    body: requestData
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Лекция обновлена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Lectures_LIST', id: arg.uuidTopic }]
        }),

        deleteLecture: build.mutation<void, DeleteLectureRequest>({
            query(data) {
                const requestData = {
                    uuids: [data.uuid]
                };
                return {
                    url: 'lectures/lectures/delete',
                    method: 'POST',
                    body: requestData
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Лекция удалена',
                    error: 'Ошибка удаления!'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Lectures_LIST', id: arg.uuidTopic }]
        }),

        updateLecturePositions: build.mutation<void, UpdatePositionsLectureTopicData>({
            query(data) {
                return {
                    url: 'lectures/lectures/positions',
                    method: 'POST',
                    body: { positions: data.positions }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Сохранено',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Lectures_LIST', id: arg.parentUuid }]
        }),

        // getChaptersByLecture: build.query<LectureChapter[], string>({
        //     query: (lectureId: string) => `lectures/chapters/list/${lectureId}`,
        //     async onQueryStarted(args, { queryFulfilled }) {
        //         try {
        //             await queryFulfilled;
        //         } catch (error) {
        //             toast.error('Не смогли получить список глав');
        //         }
        //     },
        //     transformResponse: (result: { chapters: LectureChapter[] }) => {
        //         return result.chapters;
        //     },
        //     providesTags: ['Chapters']
        // }),

        getChapterById: build.query<LectureChapter, string>({
            query: (chapterId: string) => `lectures/chapters/find/${chapterId}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить главу');
                }
            },
            providesTags: (result, error, arg) => result ? [{ type: 'Chapters', id: result?.uuid }] : ['Chapters']
        }),

        createLectureChapter: build.mutation<LectureChapter, CreateLectureChapterRequest>({
            query(data) {
                const requestData = {
                    name: data.name,
                    content: data.content,
                    uuidLecture: data.uuidLecture,
                    position: data.position
                };

                return {
                    url: 'lectures/chapters/create',
                    method: 'POST',
                    body: requestData
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Глава создана',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Lectures', id: arg.uuidLecture }]
        }),

        updateLectureChapter: build.mutation<LectureChapter, UpdateLectureChapterRequest>({
            query(data) {
                const requestData = {
                    name: data.name,
                    content: data.content,
                    uuid: data.uuid
                };

                return {
                    url: 'lectures/chapters/update',
                    method: 'POST',
                    body: requestData
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Chapters', id: arg.uuid }]
        }),

        deleteLectureChapter: build.mutation<void, string>({
            query(uuidChapter) {
                const requestData = {
                    uuids: [uuidChapter]
                };

                return {
                    url: 'lectures/chapters/delete',
                    method: 'POST',
                    body: requestData
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Глава удалена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Chapters', id: arg }]
        }),

        updateLectureChaptersPositions: build.mutation<void, UpdatePositionsLectureTopicData>({
            query(data) {
                return {
                    url: 'lectures/chapters/positions',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Сохранено',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Chapters']
        }),

        getLecturesBlocksContentById: build.query<LectureBlocksContent, Uuid>({
            query: (uuidBlock: string) => `lectures/blocks/content/${uuidBlock}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить блок лекций');
                }
            },
            transformResponse: (result: LectureBlocksContent) => {
                return result;
            }
        })
    })
});

export const {
    // useGetLectureBlocksListQuery,
    // useGetLectureBlockByIdQuery,
    useGetLectureBlockProgressQuery,
    useCreateLectureBlockMutation,
    useDeleteLectureBlockMutation,
    useGetLectureTopicsByBlockQuery,
    useCreateLectureTopicMutation,
    useDeleteLectureTopicMutation,
    useUpdateLectureTopicMutation,
    useGetLectureByIdQuery,
    useGetLecturesByTopicQuery,
    useCreateLectureMutation,
    useUpdateLectureMutation,
    useDeleteLectureMutation,
    // useGetChaptersByLectureQuery,
    useGetChapterByIdQuery,
    useCreateLectureChapterMutation,
    useUpdateLectureChapterMutation,
    useDeleteLectureChapterMutation,
    useUpdateLectureTopicsPositionsMutation,
    useUpdateLecturePositionsMutation,
    useUpdateLectureChaptersPositionsMutation,
    useGetLecturesBlocksContentByIdQuery

} = lecturesApi;
