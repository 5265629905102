
import { ScoreCard } from '@components/ScoreCard';

import style from './RecordBook.module.scss';

const RecordBook = () => {
    return (
        <>
            <div className={style.container}>
                <h1 className={style.title}>Моя зачетка</h1>

                <ScoreCard />
            </div>
        </>
    );
};

export default RecordBook;
