import { FC } from 'react';

import { Control, useController } from 'react-hook-form';

import Switcher from '@components/core/inputs/Switcher';

type FileControlProps = {
    name: string;
    control: Control<any>;
    disabled?: boolean
};

export const SwitcherControl: FC<FileControlProps> = ({ control, name, ...props }) => {
    const {
        field: { onChange, value }
    } = useController({ name, control });
    return <Switcher name={name} value={value} onChange={onChange} {...props} />;
};

export default SwitcherControl;
