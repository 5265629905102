import { FC, ReactNode } from 'react';

import { SwipeableDrawer } from '@mui/material';

import style from './swipe_drawer.module.scss';


interface ISwipeDrawer {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    children?: ReactNode;
}

const SwipeDrawer: FC<ISwipeDrawer> = ({ isOpen, onClose, onOpen, children }) => {

    return (
        <SwipeableDrawer
            open={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            anchor="bottom"
            PaperProps={{
                sx: {
                    borderRadius: '16px 16px 0px 0px',
                    overflow: 'hidden',
                    boxShadow: '0px -12px 24px rgba(53, 53, 53, 0.06);'
                }
            }}
            ModalProps={{
                BackdropProps: {
                    sx: {
                        backgroundColor: 'rgba(34, 38, 38, 0.2)'
                    }
                }
            }}
        >
            <div className={style.drawer_content}>
                <div className={style.drawer_handle} />
                <div>
                    {children}
                </div>
            </div>
        </SwipeableDrawer>
    );
};

export default SwipeDrawer;
