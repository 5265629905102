import { FC, useMemo } from 'react';

import { useForm, FormProvider, useController } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import Modal from '@components/Modal';
import { flatCategoriesToOptions, getCategoriesDict } from '@helpers/categories';
import { yupResolver } from '@hookform/resolvers/yup';
import { SessionTypesWithLocale } from '@store/api/apiTypes';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import { useGetDisciplinesByCategoryQuery } from '@store/api/disciplinesApi';
import { useCreateSkillDisciplinesMutation, useGetPresetsQuery, useGetSkillsQuery } from '@store/api/skillsApi';

import style from './AddSkillDisciplineModal.module.scss';

const sessions = Object.keys(SessionTypesWithLocale).map(key => ({
    label: SessionTypesWithLocale[key as  keyof typeof SessionTypesWithLocale],
    value: key
}));

export const addSkillSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    semester: yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    }).required('Поле обязательно для заполнения!'),
    uuidsPresets: yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    })),
    uuidCategory: yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    }).required('Поле обязательно для заполнения!'),
    uuidsRealDisciplines: yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    })),
    uuidsSkills: yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    }))
});

interface AddSkillDisciplineModalProps {
    isOpen: boolean,
    closeModal: () => void
}

const AddSkillDisciplineModal: FC<AddSkillDisciplineModalProps> = ({ isOpen, closeModal }) => {
    const methods = useForm({
        resolver: yupResolver(addSkillSchema)
    });

    const { control, handleSubmit } = methods;
    const { field: { value: selectedCategory } } = useController({ control, name: 'uuidCategory' });

    const { data: skills } = useGetSkillsQuery(undefined);
    const { data: disciplines } = useGetDisciplinesByCategoryQuery(selectedCategory?.value, { skip: !selectedCategory });
    const { data: presets } = useGetPresetsQuery(undefined);
    const { categories } = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000', {
        selectFromResult: ({ data: categoriesData }) => ({
            categories: categoriesData ?? []
        })
    });

    const categoriesDict = useMemo(() => getCategoriesDict(categories), [categories]);
    const categoriesOptions = useMemo(() => flatCategoriesToOptions(categories), [categories]);

    const [createDiscipline, {
        isLoading: createDisciplineIsLoading
    }] = useCreateSkillDisciplinesMutation();

    const onSubmit = async (formData: any) => {
        const transformedFormData = {
            name: formData.name,
            semester: formData.semester.value,
            uuidCategory: formData.uuidCategory.value,
            uuidsPresets: formData.uuidsPresets?.map((item:{label:string,value:string}) => item.value),
            uuidsRealDisciplines: formData.uuidsRealDisciplines?.map((item:{label:string,value:string}) => item.value),
            uuidsSkills: formData.uuidsSkills?.map((item:{label:string,value:string}) => item.value)
        };
        createDiscipline(transformedFormData);
        closeModal();
    };

    const disciplinesOptions = disciplines?.map(discipline => ({
        label: discipline.name,
        value: discipline.uuid,
        parent: categoriesDict[discipline.uuidCategory]
    })) ?? [];

    return (
        <Modal
            onClose={closeModal}
            isOpen={isOpen}
            className={style.modal}
            title="Добавить плановую дисциплину"
        >

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className={style.container}>

                    <div className={style.list}>

                        <div className={style.input}>
                            <label htmlFor="name">Название</label>
                            <InputControl
                                name="name"
                                placeholder="Введите название"
                                id="name"
                            />
                        </div>

                        {skills &&
                            <div className={style.input}>
                                <label htmlFor="uuidsSkills">Привязанные компетенции</label>
                                <SelectStyled
                                    control={control}
                                    name="uuidsSkills"
                                    options={skills.map(skill => ({ label:skill.name, value: skill.uuid }))}
                                    isMulti
                                    placeholder="Выберите компетенции"

                                />
                            </div>
                        }
                        {categories &&
                            <div className={style.input}>
                                <label htmlFor="uuidCategory">Выбранная категория</label>
                                <SelectStyled
                                    control={control}
                                    name="uuidCategory"
                                    options={categoriesOptions}
                                    placeholder="Выберите категорию"
                                />
                            </div>
                        }
                        {disciplines &&
                            <div className={style.input}>
                                <label htmlFor="uuidsRealDisciplines">Привязанные дисциплины</label>
                                <SelectStyled
                                    control={control}
                                    name="uuidsRealDisciplines"
                                    options={disciplinesOptions}
                                    isMulti
                                    placeholder="Выберите дисциплины"
                                    isHierarchy
                                />
                            </div>
                        }
                        {presets &&
                            <div className={style.input}>
                                <label htmlFor="uuidsRealDisciplines">Привязанные пресеты</label>
                                <SelectStyled
                                    control={control}
                                    name="uuidsPresets"
                                    options={presets.map(preset => ({ label:preset.name, value:preset.uuid }))}
                                    isMulti
                                    placeholder="Выберите пресеты"
                                />
                            </div>
                        }
                        <div className={style.input}>
                            <label htmlFor="semester">Семестр</label>
                            <SelectStyled
                                control={control}
                                name="semester"
                                options={sessions}
                                placeholder="Выберите семестр"
                            />
                        </div>
                    </div>

                    <div className={style.button_wrapper}>
                        <Button
                            onClick={closeModal}
                            btnType={'secondary'}
                        >
                    Отмена
                        </Button>
                        <Button
                            type='submit'
                            disabled={createDisciplineIsLoading}
                            typeIcon={createDisciplineIsLoading ? 'loading' : undefined}
                        >
                            Сохранить
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default AddSkillDisciplineModal;






















