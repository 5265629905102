import React, { FC } from 'react';

import { components, OptionProps } from 'react-select';

import Checkbox from '../../Checkbox';
import style from './CheckboxesContainer.module.scss';


export const CheckboxesContainer:FC<OptionProps> = (props) => {

    return <div className={style.container}>
        <components.Option {...props}>
            <Checkbox checked={props.isSelected} label={props.label} onChange={() => null} />
        </components.Option>
    </div>;
};