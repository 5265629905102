import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-right-small.svg';
import IconUI from '@components/core/IconUI';
import { LinkUI } from '@components/core/LinkUI';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { ITask } from '@views/student/StudentCourse/Materials/type';

import style from './Task.module.scss';

const Task: FC<ITask> = ({ task }) => {
    const lgDown = useBreakpoint('lg', 'down');

    const navigate = useNavigate();

    const user = useAppSelector(selectApiUser);
    const { uuid, name, maxRating } = task;

    const handeClickCard = () => {
        if (lgDown) {
            navigate(`task/${uuid}`);
        }
    };

    return (
        <div className={style.card} onClick={handeClickCard}>
            <div className={style.title_wrapper}>
                <div><IconUI typeIcon={'taskExam'} /></div>
                <div className={style.wrapper}>
                    {lgDown ? <h3>{name}</h3> : (
                        <LinkUI
                            to={`task/${uuid}`}
                            className={style.link}
                            disabled={user?.role === Role.Teacher}
                        >
                            {name}
                        </LinkUI>
                    )}

                    <span className={style.maximum_score}>Максимум {maxRating} баллов</span>
                </div>

            </div>
            {lgDown && <ArrowIcon />}
        </div>
    );
};

export default Task;
