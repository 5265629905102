import { IJSXRenderClear } from '@type/common';

import { ActiveDisciplines } from './ActiveDisciplines';
import { AvailableDisciplines } from './AvailableDisciplines';
import { CompletedDisciplines } from './CompletedDisciplines';
import { DebtDisciplines } from './DebtDisciplines';

export const notFoundComponents: IJSXRenderClear = {
    'all': <AvailableDisciplines />,
    'active': <ActiveDisciplines />,
    'arrears': <DebtDisciplines />,
    'completed': <CompletedDisciplines />
};
