import { ReactComponent as PictureAllSVG } from '@assets/icons/picture-all.svg';

import style from './RecordNotFound.module.scss';


export const ActiveDiscipline = () => {

    return <div className={style.container}>
        <div className={style.picture}>
            <PictureAllSVG />
        </div>

        <div className={style.title}>Активных дисциплин нет</div>
        <div className={style.description}>
            Дисциплины еще не назначены
            или уже сданы и переведены
            в завершенные
        </div>
    </div>;
};