import { FC, useState } from 'react';

import cx from 'classnames';

import Title from '@components/core/DNDWrapper/DNDChildren/TopicCard/Title/Title';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { LinkUI } from '@components/core/LinkUI';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { Lecture } from '@store/api/apiTypes';
import { useDeleteLectureMutation, useUpdateLectureMutation } from '@store/api/lecturesApi';
import { Role } from '@store/Roles';

import style from './topicCardItem.module.scss';

interface ITopicCardItem {
    lecture: Lecture;
}

const TopicCardItem: FC<ITopicCardItem> = ({ lecture }) => {

    const [updateLecture] = useUpdateLectureMutation();

    const [deleteLecture] = useDeleteLectureMutation();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return (
        <>
            <div className={cx(style.lecture_item, !checkRole(Role.Methodist) && style['lecture_item--no-editable'])} key={lecture.uuid}>
                <Title
                    title={lecture.name}
                    editHandler={(elemId, newTitle) => updateLecture({ uuid: lecture.uuid, name: newTitle, uuidTopic: lecture.uuidTopic })}
                    elemId={lecture.uuid}
                    renderComponent={(propsText: string) => (
                        <LinkUI className={style.link} to={`topic/${lecture.uuidTopic}/lecture/${lecture.uuid}`}>
                            {propsText}
                        </LinkUI>
                    )}
                    disabled={!checkRole(Role.Methodist)}
                />

                <RoleCheck roles={Role.Methodist}>
                    <ButtonIcon
                        className={style.delete_button}
                        typeIcon={'basket'}
                        onClick={() => setShowDeleteModal(true)}
                    />
                </RoleCheck>
            </div>

            <DeleteItemModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onDelete={() => deleteLecture({ uuid: lecture.uuid, uuidTopic: lecture.uuidTopic })}
                itemName={lecture.name}
                itemType={'lecture'}
            />
        </>
    );
};

export default TopicCardItem;
