import { useEffect, useState } from 'react';

import { useToggle } from '@hooks/useToggle.hook';
import { useChangeDisciplineCategoryMutation } from '@store/api/disciplinesApi';

export const useChangeCategory = () => {
    const [isOpen, { on, off }] = useToggle();

    const [currentMaterialId, setCurrentMaterialId] = useState('');

    const [changeCourseCategory, { isError: changeErr, isSuccess: changeSuccess }] = useChangeDisciplineCategoryMutation();

    const openCategoryModal = (uuid: string): void => {
        on();
        setCurrentMaterialId(uuid);
    };

    useEffect(() => {
        if (changeSuccess) {
            off();
            setCurrentMaterialId('');
        }
    }, [changeSuccess]);

    return {
        isOpenChangeModal: isOpen,
        openCategoryModal,
        closeCategoryModal: off,
        changeCourseCategory,
        currentMaterialId,
        changeSuccess
    };
};
