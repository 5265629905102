import React, { ReactNode, useEffect, useState } from 'react';

import {
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    KeyboardSensor,
    MouseSensor,
    rectIntersection,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import SortableItem from './SortableItem';

export type DNDGridItem<T> = ( T & { id: string } )

interface IDNDGridProps<T> {
    items: DNDGridItem<T>[];
    children: (item: DNDGridItem<T> | undefined) => ReactNode;
    onOrder: (reorderVideoList: DNDGridItem<T>[]) => void;
    className?: string;
}

function DNDGrid<T>({
    items,
    children,
    onOrder,
    className
}: IDNDGridProps<T>) {
    const [activeId, setActiveId] = useState<UniqueIdentifier>('');
    const [localItems, setLocalItems] = useState<DNDGridItem<T>[]>([]);

    useEffect(() => {
        setLocalItems(items);
    }, [JSON.stringify(items)]);

    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 5
        }
    });
    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: {
            distance: 5
        }
    });

    const sensors = useSensors(
        mouseSensor,
        touchSensor,
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const handleDragStart = (event: DragStartEvent) => {
        setActiveId(event.active.id);
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            setLocalItems((elem) => {
                const oldIndex = elem.findIndex(item => item.id === active.id);
                const newIndex = elem.findIndex(item => item.id === over.id);

                return arrayMove(elem, oldIndex, newIndex);
            });

            if (!onOrder) {
                return;
            } else {
                const oldIndex = localItems.findIndex(item => item.id === active.id);
                const newIndex = localItems.findIndex(item => item.id === over.id);

                const reorderData = arrayMove(localItems, oldIndex, newIndex);
                onOrder(reorderData);
            }
        }
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={rectIntersection}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
        >
            <div className={className}>
                <SortableContext items={localItems.map(item => ({ id: item.id }))} strategy={rectSortingStrategy}>
                    {localItems.map((item, index) => (
                        <SortableItem
                            key={item.id}
                            id={item.id}
                            handle={true}
                        >{children(item)}</SortableItem>
                    ))}
                    <DragOverlay>
                        {activeId ? (
                            <SortableItem
                                key={activeId}
                                id={activeId}
                                handle={true}
                            >{children(localItems.find((item) => item.id === activeId))}</SortableItem>
                        ) : null}
                    </DragOverlay>
                </SortableContext>
            </div>
        </DndContext>
    );
}

export default DNDGrid;
