import React, { useState } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import Checkbox from '@components/core/inputs/Checkbox';
import Paper from '@components/core/styledWrappers/Paper';
import { Label } from '@components/Label';
import Main from '@components/Main';
import UserAvatar from '@components/UserAvatar';

import style from './totaltest.module.scss';

export const TotalTest = () => {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <Tabs>
            <Main>
                <div className={style.title}>
                    <ButtonIcon
                        typeIcon={'arrowLink'}
                        onClick={() => navigate('./../')}
                    />

                    <h1>Итоговое тестирование</h1>
                </div>

                <div className={style.container}>

                    <div className={style.author_container}>
                        <Paper>
                            <div className={style.author_inner}>
                                <div className={style.leftcolumn}>
                                    <div className={style.author}>
                                        <UserAvatar name='Александрова' lastName='Ивановна' avatar='' />
                                        <div className={style.author_name}>
                                            Александрова Мария Геннадьевна

                                            <div className={style.author_breadcrumb}>
                                                <div className={style.breadcrumb_item}>Бакалавриат</div>
                                                <div className={style.breadcrumb_item}>1 курс 2019</div>
                                                <div className={style.breadcrumb_item}>Вечернее отделение</div>
                                                <div className={style.breadcrumb_item}>1 поток</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={style.samples}>
                                        <TabList selectedIndex={tabIndex} onSelect={(index: any) => setTabIndex(index)}>
                                            <Tab><Button className={style.tabs_btn} btnType='secondary'>Попытка 1</Button></Tab>
                                            <Tab><Button className={style.tabs_btn} btnType='secondary'>Попытка 2</Button></Tab>
                                            <Tab><Button className={style.tabs_btn} btnType='secondary'>Попытка 3</Button></Tab>
                                        </TabList>
                                    </div>
                                </div>

                                <div className={style.rightcolumn}>
                                    <div className={style.estimation_label}>
                                        <Label color={'green'} caption={'Лучшая попытка'} />
                                    </div>

                                    <div className={style.estimation}>  Оценка: 45 из 50 баллов </div>
                                </div>
                            </div>
                        </Paper>
                    </div>

                    <div className={style.question_container}>
                        <TabPanel>
                            <Grid>
                                <Column size={9}>
                                    <div className={style.question_card}>
                                        <Paper>
                                            <h3 className={style.question_header}>Вопрос 1</h3>

                                            <div className={style.question_sub_header}>
                                        Одним из критериев, которым руководствуется клинический психолог, 
                                        при проведении медико-педагогической экспертизы является уровень 
                                        знаний и навыков, соответствующих возрастному развитию.
                                            </div>

                                            <div className={style.quiz_container}>
                                                <div className={style.quiz_caption}>
                                            Выберите один ответ
                                                </div>

                                                <ul className={style.quiz_list}>
                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие отклонений от нормы</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания 2</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={style.quiz_correct_answer}>
                                        Правильный ответ: Наличие заболевания
                                            </div>
                        
                                        </Paper>
                                    </div>

                                    <div className={style.question_card}>
                                        <Paper>
                                            <h3 className={style.question_header}>Вопрос 1</h3>

                                            <div className={style.question_sub_header}>
                                        Одним из критериев, которым руководствуется клинический психолог, 
                                        при проведении медико-педагогической экспертизы является уровень 
                                        знаний и навыков, соответствующих возрастному развитию.
                                            </div>

                                            <div className={style.quiz_container}>
                                                <div className={style.quiz_caption}>
                                            Выберите один ответ
                                                </div>

                                                <ul className={style.quiz_list}>
                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие отклонений от нормы</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>
                                                
                                                        <div className={style.quiz_label}>Наличие заболевания</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания 2</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={style.quiz_correct_answer}>
                                        Правильный ответ: Наличие заболевания
                                            </div>
                        
                                        </Paper>
                                    </div>

                                    <div className={style.question_card}>
                                        <Paper>
                                            <h3 className={style.question_header}>Вопрос 1</h3>
                                            <div className={style.question_sub_header}>Одним из критериев, которым руководствуется клинический психолог, при проведении медико-педагогической экспертизы является уровень знаний и навыков, соответствующих возрастному развитию.</div>


                                            <div className={style.quiz_container}>
                                                <div className={style.quiz_caption}>
                                            Выберите один ответ
                                                </div>

                                                <ul className={style.quiz_list}>
                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие отклонений от нормы</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>
                                                
                                                        <div className={style.quiz_label}>Наличие заболевания</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания 2</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={style.quiz_correct_answer}>
                                        Правильный ответ: Наличие заболевания
                                            </div>
                        
                                        </Paper>
                                    </div>

                                    <div className={style.question_card}>
                                        <Paper>
                                            <h3 className={style.question_header}>Вопрос 1</h3>
                                            <div className={style.question_sub_header}>Одним из критериев, которым руководствуется клинический психолог, при проведении медико-педагогической экспертизы является уровень знаний и навыков, соответствующих возрастному развитию.</div>


                                            <div className={style.quiz_container}>
                                                <div className={style.quiz_caption}>
                                            Выберите один ответ
                                                </div>

                                                <ul className={style.quiz_list}>
                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие отклонений от нормы</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>
                                                
                                                        <div className={style.quiz_label}>Наличие заболевания</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания 2</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={style.quiz_correct_answer}>
                                        Правильный ответ: Наличие заболевания
                                            </div>
                        
                                        </Paper>
                                    </div>
                                </Column>

                                <Column size={3}>
                                    <div className={style.quiz_popup}>
                                        <div className={style.quiz_samples}>
                                            <div className={style.quiz_samples_heading}>Попытка 1</div>
                                            <div className={style.quiz_samples_caption}>Завершено 27.02.2022, 18:10</div>
                                            <div className={style.quiz_samples_rows}>
                                                <div className={style.quiz_row}>
                                                    <div className={style.quiz_row_item}>1</div>
                                                    <div className={style.quiz_row_item}>2</div>
                                                    <div className={style.quiz_row_item}>3</div>
                                                    <div className={style.quiz_row_item}>4</div>
                                                    <div className={style.quiz_row_item}>5</div>
                                                    <div className={style.quiz_row_item}>6</div>
                                                    <div className={style.quiz_row_item}>7</div>
                                                </div>

                                                <div className={style.quiz_row}>
                                                    <div className={style.quiz_row_item}>8</div>
                                                    <div className={style.quiz_row_item}>9</div>
                                                    <div className={style.quiz_row_item}>10</div>
                                                    <div className={style.quiz_row_item}>11</div>
                                                    <div className={style.quiz_row_item}>12</div>
                                                    <div className={style.quiz_row_item}>13</div>
                                                    <div className={style.quiz_row_item}>14</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Column>
                            </Grid>
                        </TabPanel>

                        <TabPanel>
                            <Grid>
                                <Column size={9}>
                                    <div className={style.question_card}>
                                        <Paper>
                                            <h3 className={style.question_header}>Вопрос 5</h3>

                                            <div className={style.question_sub_header}>
                                        Одним из критериев, которым руководствуется клинический психолог, 
                                        при проведении медико-педагогической экспертизы является уровень 
                                        знаний и навыков, соответствующих возрастному развитию.
                                            </div>

                                            <div className={style.quiz_container}>
                                                <div className={style.quiz_caption}>
                                            Выберите один ответ
                                                </div>

                                                <ul className={style.quiz_list}>
                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие отклонений от нормы</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания 2</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={style.quiz_correct_answer}>
                                        Правильный ответ: Наличие заболевания
                                            </div>
                        
                                        </Paper>
                                    </div>

                                    <div className={style.question_card}>
                                        <Paper>
                                            <h3 className={style.question_header}>Вопрос 1</h3>

                                            <div className={style.question_sub_header}>
                                        Одним из критериев, которым руководствуется клинический психолог, 
                                        при проведении медико-педагогической экспертизы является уровень 
                                        знаний и навыков, соответствующих возрастному развитию.
                                            </div>

                                            <div className={style.quiz_container}>
                                                <div className={style.quiz_caption}>
                                            Выберите один ответ
                                                </div>

                                                <ul className={style.quiz_list}>
                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие отклонений от нормы</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>
                                                
                                                        <div className={style.quiz_label}>Наличие заболевания</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания 2</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={style.quiz_correct_answer}>
                                        Правильный ответ: Наличие заболевания
                                            </div>
                        
                                        </Paper>
                                    </div>

                                    <div className={style.question_card}>
                                        <Paper>
                                            <h3 className={style.question_header}>Вопрос 1</h3>
                                            <div className={style.question_sub_header}>Одним из критериев, которым руководствуется клинический психолог, при проведении медико-педагогической экспертизы является уровень знаний и навыков, соответствующих возрастному развитию.</div>


                                            <div className={style.quiz_container}>
                                                <div className={style.quiz_caption}>
                                            Выберите один ответ
                                                </div>

                                                <ul className={style.quiz_list}>
                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие отклонений от нормы</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>
                                                
                                                        <div className={style.quiz_label}>Наличие заболевания</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания 2</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={style.quiz_correct_answer}>
                                        Правильный ответ: Наличие заболевания
                                            </div>
                        
                                        </Paper>
                                    </div>

                                    <div className={style.question_card}>
                                        <Paper>
                                            <h3 className={style.question_header}>Вопрос 1</h3>
                                            <div className={style.question_sub_header}>Одним из критериев, которым руководствуется клинический психолог, при проведении медико-педагогической экспертизы является уровень знаний и навыков, соответствующих возрастному развитию.</div>


                                            <div className={style.quiz_container}>
                                                <div className={style.quiz_caption}>
                                            Выберите один ответ
                                                </div>

                                                <ul className={style.quiz_list}>
                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие отклонений от нормы</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>
                                                
                                                        <div className={style.quiz_label}>Наличие заболевания</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания 2</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={style.quiz_correct_answer}>
                                        Правильный ответ: Наличие заболевания
                                            </div>
                        
                                        </Paper>
                                    </div>
                                </Column>

                                <Column size={3}>
                                    <div className={style.quiz_popup}>
                                        <div className={style.quiz_samples}>
                                            <div className={style.quiz_samples_heading}>Попытка 1</div>
                                            <div className={style.quiz_samples_caption}>Завершено 27.02.2022, 18:10</div>
                                            <div className={style.quiz_samples_rows}>
                                                <div className={style.quiz_row}>
                                                    <div className={style.quiz_row_item}>1</div>
                                                    <div className={style.quiz_row_item}>2</div>
                                                    <div className={style.quiz_row_item}>3</div>
                                                    <div className={style.quiz_row_item}>4</div>
                                                    <div className={style.quiz_row_item}>5</div>
                                                    <div className={style.quiz_row_item}>6</div>
                                                    <div className={style.quiz_row_item}>7</div>
                                                </div>

                                                <div className={style.quiz_row}>
                                                    <div className={style.quiz_row_item}>8</div>
                                                    <div className={style.quiz_row_item}>9</div>
                                                    <div className={style.quiz_row_item}>10</div>
                                                    <div className={style.quiz_row_item}>11</div>
                                                    <div className={style.quiz_row_item}>12</div>
                                                    <div className={style.quiz_row_item}>13</div>
                                                    <div className={style.quiz_row_item}>14</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Column>
                            </Grid>
                        </TabPanel>

                        <TabPanel>
                            <Grid>
                                <Column size={9}>
                                    <div className={style.question_card}>
                                        <Paper>
                                            <h3 className={style.question_header}>Вопрос 9</h3>

                                            <div className={style.question_sub_header}>
                                        Одним из критериев, которым руководствуется клинический психолог, 
                                        при проведении медико-педагогической экспертизы является уровень 
                                        знаний и навыков, соответствующих возрастному развитию.
                                            </div>

                                            <div className={style.quiz_container}>
                                                <div className={style.quiz_caption}>
                                            Выберите один ответ
                                                </div>

                                                <ul className={style.quiz_list}>
                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие отклонений от нормы</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания 2</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={style.quiz_correct_answer}>
                                        Правильный ответ: Наличие заболевания
                                            </div>
                        
                                        </Paper>
                                    </div>

                                    <div className={style.question_card}>
                                        <Paper>
                                            <h3 className={style.question_header}>Вопрос 1</h3>

                                            <div className={style.question_sub_header}>
                                        Одним из критериев, которым руководствуется клинический психолог, 
                                        при проведении медико-педагогической экспертизы является уровень 
                                        знаний и навыков, соответствующих возрастному развитию.
                                            </div>

                                            <div className={style.quiz_container}>
                                                <div className={style.quiz_caption}>
                                            Выберите один ответ
                                                </div>

                                                <ul className={style.quiz_list}>
                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие отклонений от нормы</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>
                                                
                                                        <div className={style.quiz_label}>Наличие заболевания</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания 2</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={style.quiz_correct_answer}>
                                        Правильный ответ: Наличие заболевания
                                            </div>
                        
                                        </Paper>
                                    </div>

                                    <div className={style.question_card}>
                                        <Paper>
                                            <h3 className={style.question_header}>Вопрос 1</h3>
                                            <div className={style.question_sub_header}>Одним из критериев, которым руководствуется клинический психолог, при проведении медико-педагогической экспертизы является уровень знаний и навыков, соответствующих возрастному развитию.</div>


                                            <div className={style.quiz_container}>
                                                <div className={style.quiz_caption}>
                                            Выберите один ответ
                                                </div>

                                                <ul className={style.quiz_list}>
                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие отклонений от нормы</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>
                                                
                                                        <div className={style.quiz_label}>Наличие заболевания</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания 2</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={style.quiz_correct_answer}>
                                        Правильный ответ: Наличие заболевания
                                            </div>
                        
                                        </Paper>
                                    </div>

                                    <div className={style.question_card}>
                                        <Paper>
                                            <h3 className={style.question_header}>Вопрос 1</h3>
                                            <div className={style.question_sub_header}>Одним из критериев, которым руководствуется клинический психолог, при проведении медико-педагогической экспертизы является уровень знаний и навыков, соответствующих возрастному развитию.</div>


                                            <div className={style.quiz_container}>
                                                <div className={style.quiz_caption}>
                                            Выберите один ответ
                                                </div>

                                                <ul className={style.quiz_list}>
                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие отклонений от нормы</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>
                                                
                                                        <div className={style.quiz_label}>Наличие заболевания</div>
                                                    </li>

                                                    <li className={style.quiz_control}>
                                                        <div className={style.quiz_checkbox}>
                                                            <Checkbox />
                                                        </div>

                                                        <div className={style.quiz_label}>Наличие заболевания 2</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={style.quiz_correct_answer}>
                                        Правильный ответ: Наличие заболевания
                                            </div>
                        
                                        </Paper>
                                    </div>
                                </Column>

                                <Column size={3}>
                                    <div className={style.quiz_popup}>
                                        <div className={style.quiz_samples}>
                                            <div className={style.quiz_samples_heading}>Попытка 1</div>
                                            <div className={style.quiz_samples_caption}>Завершено 27.02.2022, 18:10</div>
                                            <div className={style.quiz_samples_rows}>
                                                <div className={style.quiz_row}>
                                                    <div className={style.quiz_row_item}>1</div>
                                                    <div className={style.quiz_row_item}>2</div>
                                                    <div className={style.quiz_row_item}>3</div>
                                                    <div className={style.quiz_row_item}>4</div>
                                                    <div className={style.quiz_row_item}>5</div>
                                                    <div className={style.quiz_row_item}>6</div>
                                                    <div className={style.quiz_row_item}>7</div>
                                                </div>

                                                <div className={style.quiz_row}>
                                                    <div className={style.quiz_row_item}>8</div>
                                                    <div className={style.quiz_row_item}>9</div>
                                                    <div className={style.quiz_row_item}>10</div>
                                                    <div className={style.quiz_row_item}>11</div>
                                                    <div className={style.quiz_row_item}>12</div>
                                                    <div className={style.quiz_row_item}>13</div>
                                                    <div className={style.quiz_row_item}>14</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Column>
                            </Grid>
                        </TabPanel>
                    </div>
                </div>

                <Outlet />
            </Main>
        </Tabs>
    );
};