import { toast } from 'react-toastify';

import { IStorageFile, uploadFileData } from './apiTypes';
import { emptyBaseApi } from './emptyBaseApi';

export const storageApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        uploadFile: build.mutation<IStorageFile, uploadFileData>({
            query({ file, meta }) {
                return {
                    url: process.env.REACT_APP_UPLOAD_ENDPONT + 'file/upload',
                    method: 'POST',
                    body: { file, meta }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                if (!args.toastDisabled) {
                    toast.promise(queryFulfilled, {
                        pending:  'Загружаем файл...',
                        success: 'Файл загружен',
                        error: 'Не удалось загрузить файл'
                    });
                }
            }
        }),
        getFile: build.query<{files:IStorageFile[]}, string>({
            query(uuid) {
                return {
                    url: 'storage/get',
                    method: 'POST',
                    body: { uuids: [uuid] }
                };
            }
        })
    })
});

export const { useUploadFileMutation, useGetFileQuery, useLazyGetFileQuery } = storageApi;
