import { useParams } from 'react-router-dom';

import { TestUpdateData } from '@store/api/apiTypes';
import { useUpdateTestMutation } from '@store/api/testsApi';

import TestForm from '../../TestForm';
import { TestFormType } from '../../TestForm/TestForm';
import { useTestContext } from '../EditTest';

const Basic = () => {
    const { uuidSection, uuidDiscipline } = useParams();

    const { testContext, groups, blockedGroups } = useTestContext();

    const [updateTest] = useUpdateTestMutation();

    const testDataFromApi: TestFormType = {
        title: testContext.test.name,
        editor: testContext.test.description,
        select: testContext.test.needEvaluate ? { label: 'Да', value: true } : { label: 'Нет', value: false },
        score: testContext.test.maximumRating,
        time: testContext.test.timeLimit,
        countAttempts: testContext.test.numberAttempts,
        accessLimitation: !!blockedGroups.length,
        blockedGroups: blockedGroups
    };

    const onSubmit = (formData: TestFormType) => {
        console.log('🚀 ~ file: BasicTest.tsx ~ line 115 ~ onSubmit ~ formData', formData);

        const transformedData: TestUpdateData = {
            uuid: testContext.test.uuid,
            testData: {
                uuidDiscipline: uuidDiscipline!,
                uuidSection: uuidSection!,
                name: formData.title,
                description: formData.editor,
                maximumRating: formData.score,
                timeLimit: formData.time,
                numberAttempts: formData.countAttempts,
                needEvaluate: formData.select.value, //TODO
                accessRestricted: formData.blockedGroups || []
            }

        };
        console.log('🚀 ~ file: BasicTest.tsx ~ line 52 ~ onSubmit ~ transformedData', transformedData);

        updateTest(transformedData);
    };

    return (
        <>
            <TestForm groups={groups} submitFormHandler={onSubmit} defaultValues={testDataFromApi} />
        </>
    );
};

export default Basic;
