import { createContext, useContext } from 'react';

import { Centrifuge, Subscription } from 'centrifuge';

import { MessageType, RecipientUser } from '@type/chat';

interface SendFileData {
    text?: string;
    recipients?: RecipientUser[];
    uuidsFile?: string[] | null;
    type: MessageType;
    uuid: string;
}

export interface WebSocketContext {
    subscription: Subscription | null;
    sendMessage: (data: SendFileData) => void;
    connection: Centrifuge | null;
}

export const webSocketContextContext = createContext<WebSocketContext | null>(null);

export function useWebSocketContext() {
    const ctx = useContext(webSocketContextContext);
    if (ctx === null) throw new Error('webSocketContext must be defined');
    return ctx;
}
