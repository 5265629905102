import React from 'react';

import { AuthPersona } from '@views/student/AuthPersona';
import { useTest } from '@views/student/StudentTest/StudentTest';

export const StudentTestAuth = () => {
    const { testData, discipine } = useTest();

    const pageTitle = `${discipine.name}: ${testData.test.name}, Аутентификация`;

    return <AuthPersona title={pageTitle} type="test" />;
};
