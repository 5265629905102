import { CSSProperties, useLayoutEffect, useState } from 'react';

import placeholderSource from '@assets/icons/logo-veip-default.svg';

type ImgType = {
    src?: string;
    alt: string;
    width?: number;
    height?: number;
    title?: string;
    style?: CSSProperties;
    className?: string;
};

export function ImgWithDummy({ src, alt, ...other }: ImgType) {
    const [source, setSource] = useState(placeholderSource);

    useLayoutEffect(() => {
        if (src !== undefined) {
            const image = new Image();
            image.src = src;
            image.onload = () => {
                setSource(src);
            };
        }
    }, [src]);

    return <img src={source} alt={alt} {...other} />;
}
