import React, { FC } from 'react';

import IconUI from '@components/core/IconUI';
import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import FilesListMenu from '@components/core/styledWrappers/FilesListMenu';
import { GetTaskDataResponse } from '@store/api/apiTypes';
import { useSetEventToLogMutation } from '@store/api/eventsLogApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';

import style from './Task.module.scss';

interface ITask {
    task: GetTaskDataResponse | undefined;
}

const Task: FC<ITask> = ({ task }) => {
    const [writeActionToEventLog] = useSetEventToLogMutation();
    const user = useAppSelector(selectApiUser);

    const onTaskDownload = () => {
        writeActionToEventLog({
            uuidUser: user?.uuid || '',
            name: 'Скачивание контрольной',
            type: 'controlWorks'
        });
    };

    return (
        <div className={style.content}>
            <CSVPreview content={task?.content} />
            <FilesListMenu files={task?.files} onClick={onTaskDownload} />
            <div className={style.info}>
                <IconUI typeIcon={'calendar-light'} className={style.info__icon} />
                <p>
                    Проверка работы осуществляется в течение двух рабочих недель.
                    В случае загрузки работы в последнюю неделю срок проверки увеличивается.
                </p>
            </div>
        </div>
    );
};

export default Task;
