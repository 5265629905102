import React, { useEffect, useState } from 'react';

import { DNDItem } from '@components/core/DNDWrapper/DNDItem';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';


export type DNDItems<T> = T & { uuid: string, position: number };

interface IDNDProps<T> {
    items: DNDItems<T>[];
    onOrder?: (list: T[]) => void;
    children: React.ReactNode;
    setCurrentOrder?: (startIndex: number, endIndex: number) => void;
    isDefaultDraggableHandler?: boolean;
}

export function DNDWrapper<T>(
    {
        items,
        onOrder,
        children,
        isDefaultDraggableHandler,
        setCurrentOrder

    }: IDNDProps<T>) {

    const [localItems, setLocalItems] = useState<DNDItems<T>[]>([]);
    const [localChildren, setLocalChildren] = useState<React.ReactNode[]>([]);

    useEffect(() => {
        setLocalItems(items ?? []);
        setLocalChildren(React.Children.toArray(children));

    }, [JSON.stringify(items)]);

    const reorder = <U, >(list: U[], startIndex: number, endIndex: number): U[] => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const reorderData = reorder<DNDItems<T>>(
            localItems,
            result.source.index,
            result.destination.index
        ).map((item, index) => ({ uuid: item.uuid, position: index + 1 } as DNDItems<T>));

        const reorderedChildren = reorder<React.ReactNode>(
            localChildren,
            result.source.index,
            result.destination.index
        );

        setLocalItems(reorderData);
        setLocalChildren(reorderedChildren);

        onOrder && onOrder(reorderData);
        setCurrentOrder && setCurrentOrder(result.source.index, result.destination.index);

    };

    return (
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
            <Droppable droppableId="droppable" direction="vertical">
                {(providedDroppable) => (
                    <div
                        {...providedDroppable.droppableProps}
                        ref={providedDroppable.innerRef}
                    >
                        {
                            localItems.map((item, index) => (
                                <DNDItem
                                    key={item.uuid}
                                    draggableId={item.uuid}
                                    index={index}
                                    isDefaultDraggableHandler={isDefaultDraggableHandler}> {localChildren[index]}
                                </DNDItem>
                            ))
                        }

                        {providedDroppable.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );

}
