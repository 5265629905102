import { FC } from 'react';

import { MultiValue, SingleValue } from 'react-select';

import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg';
import { OptionType } from '@components/core/inputs/SelectStyled/type';
import IconButton from '@components/core/styledWrappers/IconButton';

import { OptionContainer } from '../OptionContainer';
import style from './multi_value_container.module.scss';

interface IMultiValueContainer {
    selectedOption: MultiValue<OptionType> | SingleValue<OptionType>;
    selectedOptionHandle: (indexElem: number) => void;
    isAvatar?: boolean;
}

const MultiValueContainer: FC<IMultiValueContainer> = ({ selectedOption, selectedOptionHandle, isAvatar = false }) => {
    return Array.isArray(selectedOption) && selectedOption?.length ? <div className={style.root}>
        <ul className={style.list}>
            {selectedOption !== null &&
                    // @ts-ignore
                    selectedOption?.map((item, index) => {
                        return (
                            <li key={index}>
                                <div className={style.list__item_wrapper}>
                                    <OptionContainer option={item} isAvatar={isAvatar} />

                                    <IconButton
                                        className={style.close}
                                        onClick={() => {
                                            selectedOptionHandle(index);
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </li>
                        );
                    })}
        </ul>
    </div> : null;
};

export default MultiValueContainer;
