import { useEffect } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import { FileUploadPhotoControl } from '@components/core/inputs/FileUpload/FileUploadPhotoControl';
import InputControl from '@components/core/inputs/InputControl';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import TinyEditor from '@components/methodist/TinyEditor';
import { getBase64 } from '@helpers/base64';
import { isStorageFile } from '@helpers/typeCheck';
import { yupResolver } from '@hookform/resolvers/yup';
import { IStorageFile, ResponseMessage, Skill, SkillDiscipline, fileTypes, WithUUID, CreateSkillRequestMessage } from '@store/api/apiTypes';
import {  useUpdateSkillMutation } from '@store/api/skillsApi';
import { useUploadFileMutation } from '@store/api/storageApi';

import styles from '../SkillEditor.module.scss';

export const addSkillSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    content: yup.string().required('Поле обязательно для заполнения!'),
    picture: yup.mixed().label('File').required('Поле обязательно для заполнения!'),
    uuidsDiscipline: yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
        parent: yup.string()
    })).required('Поле обязательно для заполнения!').min(1, 'Поле обязательно для заполнения!')
});

interface EditSkillFormProps {
    skillUUID:string;
    skill:ResponseMessage<Skill>
    picture?:{
        files: IStorageFile[];
    };
    skillDisciplines: ResponseMessage<SkillDiscipline>[];
    realDisciplinesDict: { [uuid: string]: string };
    categoriesDict: { [uuid: string]: string };
}

const EditSkillForm = ({ skillUUID, skill, picture, skillDisciplines, realDisciplinesDict, categoriesDict }:EditSkillFormProps) => {
    const navigate = useNavigate();

    const methods = useForm({
        resolver: yupResolver(addSkillSchema),
        defaultValues: {
            name: skill.name,
            content: skill.content,
            picture: picture?.files[0],
            uuidsDiscipline: skill.disciplines.map((discipline) => {
                const realDisciplineUuid = skillDisciplines.find((skillDiscipline) => skillDiscipline.uuid === discipline.uuid)?.uuidsRealDisciplines[0] ?? '';
                return {
                    label: discipline.name,
                    value: discipline.uuid,
                    parent: categoriesDict[realDisciplinesDict?.[realDisciplineUuid] ?? '']
                };
            })
        }
    });

    const { control, handleSubmit } = methods;

    const [uploadFile, {
        isLoading: fileIsLoading
    }] = useUploadFileMutation();

    const [updateSkill, {
        isLoading: createSkillIsLoading,
        isSuccess: createSkillIsSuccess
    }] = useUpdateSkillMutation();

    useEffect(()=>{
        if(createSkillIsSuccess) toast.success('Компетенция обновлена');
    },[createSkillIsSuccess]);

    const onSubmit = async (formData: any) => {
        const transformedFormData: WithUUID<CreateSkillRequestMessage> = {
            uuid: skillUUID,
            name:formData.name,
            content:formData.content,
            uuidsDiscipline: formData.uuidsDiscipline?.map((item:{label:string,value:string}) => item.value)
        };

        if (formData.picture) {
            let coverUuid = '';
            if (isStorageFile(formData.picture)) {
                coverUuid = formData.picture.uuid;
            } else {
                const b64 = await getBase64(formData.picture);
                const uploadedFile = await uploadFile({
                    file: b64 as string,
                    meta: {
                        filename: formData.picture.name,
                        type: fileTypes.Public,
                        contentType: formData.picture.type
                    }
                }).unwrap();
                coverUuid = uploadedFile.uuid;
            }

            transformedFormData.uuidPicture = coverUuid;
        }

        await updateSkill(transformedFormData);
        navigate('../skills');
    };

    const cancelHandler = () => {
        navigate('..');
    };

    const disciplinesOptions = skillDisciplines.map((discipline) => ({
        label: discipline.name,
        value: discipline.uuid,
        parent: categoriesDict[realDisciplinesDict?.[discipline.uuidsRealDisciplines[0]] ?? '']
    }));

    return (

        <form onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
                <div className={styles.content}>
                    <div className={styles.inputs}>
                        <p>Название*</p>
                        <InputControl name='name' />

                        <p>Описание*</p>
                        <TinyEditor name='content' control={control} />

                        <div className={styles.description}>
                            <p>Иконка</p>
                            <p className={styles.grayText}>
                                        Форматы: PNG, SVG<br />
                                        Размер: 120*120 px
                            </p>
                        </div>

                        <FileUploadPhotoControl
                            name="picture"
                            control={control}
                        >
                                    Добавить иконку
                        </FileUploadPhotoControl>

                        <p>Привязанные плановые дисциплины*</p>

                        <SelectStyled
                            control={control}
                            name="uuidsDiscipline"
                            options={disciplinesOptions}
                            isMulti
                            isHierarchy
                            showOptionHierarchy
                            placeholder="Выберите плановые дисциплины"
                        />

                    </div>

                    <div className={styles.buttons}>
                        <Button
                            type="submit"
                            disabled={createSkillIsLoading || fileIsLoading}
                            typeIcon={createSkillIsLoading ? 'loading' : undefined }
                        >
                                    Сохранить
                        </Button>
                        <Button onClick={cancelHandler} btnType='secondary'>Отмена</Button>
                    </div>
                </div>
            </FormProvider>
        </form>

    );
};

export default EditSkillForm;
