import React, { FC, memo, useState } from 'react';

import cx from 'classnames';

import videoPreview from '@assets/image/video-preview.png';
import { LinkUI } from '@components/core/LinkUI';
import VideoIframe from '@components/core/VideoPreview/VideoIframe';

import style from './video_preview.module.scss';

const YOUTUBE_PLAYER = 'https://www.youtube.com/embed/';
const YOUTUBE_PREVIEW = 'https://img.youtube.com/vi/';

const RUTUBE_PLAYER = 'https://rutube.ru/play/embed/';
const RUTUBE_PREVIEW = 'https://rutube.ru/api/video/';

const VK_DIRECT_LINK = 'https://vk.com/video_ext.php';

export type TVideoType = {
    type: string,
    embedLink: string,
    player: string,
    previewImg: string,
    videoId: string
}

const youtubeParser = (url: string) => {
    const regExp = /^.*((youtu.be\/)|(www.youtube.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[8].length == 11) ? match[8] : '';
};

const parseLink = (link: string, setPlay: boolean) : TVideoType => {
    const linkSplit = link.split('/');
    const youtubeVideoId = youtubeParser(link);
    switch (true) {
        case Boolean(youtubeVideoId): {
            return {
                type: 'video',
                embedLink: `${YOUTUBE_PLAYER}${youtubeVideoId}?autoplay=1`,
                player: YOUTUBE_PLAYER,
                previewImg: `${YOUTUBE_PREVIEW}${youtubeVideoId}/hqdefault.jpg`,
                videoId: youtubeVideoId
            } as TVideoType;
        }
        case link.includes('rutube.ru'): {
            const videoId = linkSplit[linkSplit.length - 2];
            return {
                type: 'video',
                embedLink: `${RUTUBE_PLAYER}${videoId}?autoplay=true`,
                player: RUTUBE_PLAYER,
                previewImg: `${RUTUBE_PREVIEW}${videoId}/thumbnail/?redirect=1`,
                videoId: videoId
            } as TVideoType;
        }
        case !setPlay: {
            return { type: 'link', embedLink: link, player: '', previewImg: '', videoId: '' } as TVideoType;
        }
        case link.includes('vk.com'): {
            const isDirectLink = link.includes('.php');
            let vkLink = link;
            if (!isDirectLink) {
                const [, videoId = ''] = link.split('video-');
                const [oid, id] = videoId.split('_');
                vkLink = `${VK_DIRECT_LINK}?oid=-${oid}&id=${id}&hd=2`;
            }
            return { type: 'video', embedLink: vkLink, player: 'default', previewImg: '', videoId: '' } as TVideoType;
        }
        case link.includes('drive.google.com'): {
            const [url] = link.split('view');
            const videoUrl = `${url}preview`;
            return { type: 'video', embedLink: videoUrl, player: 'default', previewImg: '', videoId: '' } as TVideoType;
        }
        case link.includes('vimeo.com'): {
            const hashWithParams = link.split('/').at(-1) ?? '';
            const videoId = link.split('/').at(-2);
            const [hash] = hashWithParams.split('?');
            const videoLink = `https://player.vimeo.com/video/${videoId}?h=${hash}`;
            return { type: 'video', embedLink: videoLink, player: 'default', previewImg: '', videoId: '' } as TVideoType;
        }
        default:
            return { type: 'link', embedLink: link, player: '', previewImg: '', videoId: '' } as TVideoType;
    }
};

interface VideoPreviewProps {
    link: string;
    videoName: string;
    className?: string;
    setPlay?: boolean;
}

const VideoPreview: FC<VideoPreviewProps> = ({ className, link, setPlay, videoName }) => {
    const [videoData] = useState<TVideoType>({
        ...parseLink(link, !!setPlay)
    });

    return (
        <div className={cx(style.root, className)}>
            {videoData.type === 'video' && <VideoIframe videoData={videoData} setPlay={setPlay} />}
            {
                videoData.type === 'link' && (
                    <div className={style.link_wrapper}>
                        <LinkUI
                            typeLink="default"
                            to={videoData.embedLink}
                            className={style.link}
                            target="_blank"
                        >
                            <img src={videoPreview} alt={videoName} className={style.preview} />
                        </LinkUI>
                    </div>
                )
            }
        </div>
    );
};

export default memo(VideoPreview);
