import { FC } from 'react';

import UserAvatar from '@components/UserAvatar';

import { HierarchyItem } from '../HierarchyItem';
import { OptionType } from '../type';
import style from './OptionContainer.module.scss';

export interface IOptionContainerProps {
    option: OptionType,
    isAvatar?: boolean;
    isHierarchy?: boolean;
}

export const OptionContainer:FC<IOptionContainerProps> = ({
    option,
    isAvatar = false,
    isHierarchy
}) => {

    const renderAvatar = () => {

        if (isAvatar) {
            const userNames = option.label.split(' ');
            return <div className={style.picture}>
                <UserAvatar name={userNames[1]} lastName={userNames[0]} avatar={option.picture} />
            </div>;
        }

        return null;
    };

    return <div className={style.container}>
        {
            renderAvatar()
        }

        {isHierarchy ? (
            <HierarchyItem options={[option.parent ?? '', option.label]} isOption />
        ) : (
            <div className={style.content}>
                <div className={style.label}>{option.label}</div>
            </div>
        )}
    </div>;
};
