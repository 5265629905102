import { FC } from 'react';

import cx from 'classnames';

import { ReactComponent as DownloadIcon } from '@assets/icons/dovnload-portfolio-file-icon.svg';
import { Grid } from '@components/core/grid';
import { Column } from '@components/core/grid/Column';
import { NewLoader } from '@components/core/NewLoader';
import IconButton from '@components/core/styledWrappers/IconButton';
import { DataList, DataListCell } from '@components/DataList';
import { StatusLabel } from '@components/Status';
import { dateConvert } from '@helpers/date';
import { useLazyGetArchiveQuery, useLazySetArchiveLoadedQuery } from '@store/api/archiveApi';

import style from './ArchiveList.module.scss';

export interface IArchiveListProps {
    users: any[];
}

export const ArchiveList: FC<IArchiveListProps> = ({ users }) => {
    const [getUserArchive, { isLoading: isLoadingUserArchive }] = useLazyGetArchiveQuery();
    const [setArchiveLoaded] = useLazySetArchiveLoadedQuery();

    const onDownloadArchive = (uuid: string) => async (e: any) => {
        const link = document.getElementById(uuid) as HTMLLinkElement;
        const getArchive = await getUserArchive(uuid);

        if (link && getArchive?.data) {
            link.href = getArchive.data.file.path;
            link.click();
            await setArchiveLoaded(getArchive.data.uuid);
        }
    };

    if (isLoadingUserArchive) return <NewLoader />;

    return (
        <DataList className={style.wrapper}>
            <Grid gridTemplateColumns="28% 18% 23% 13% 12%" className={style.data_list__header}>
                <>
                    <Column className={style.column_label}>
                        <DataListCell>Пользователь</DataListCell>
                    </Column>
                    <Column className={style.column_label}>
                        <DataListCell>Группа</DataListCell>
                    </Column>
                    <Column className={style.column_label}>
                        <DataListCell>E-mail</DataListCell>
                    </Column>
                    <Column className={style.column_label}>
                        <DataListCell>Дата создания</DataListCell>
                    </Column>
                    <Column className={style.column_label}>
                        <DataListCell>Период архивирования</DataListCell>
                    </Column>
                </>
            </Grid>
            {users &&
                    users.map((user, index) => {
                        return (
                            <Grid key={`${user.uuid}${index}`} gridTemplateColumns="28% 18% 23% 13% 12%" className={style.list_item}>
                                <Column>
                                    {`${user.lastName} ${user.firstName} ${user.patronymic}`}
                                    {user.deleted &&
                                        <StatusLabel type={'danger'} className={style.statusLabel}>Удалён</StatusLabel>
                                    }
                                </Column>
                                <Column>{user.groups}</Column>
                                <Column>
                                    {user.deleted ? user.email.slice(41) : user.email}
                                </Column>
                                <Column>{dateConvert(user.archiveCreatedAt.replace(/ \+0000 UTC/, '')).toFormat('dd.LL.yyyy')}</Column>
                                <Column>
                                    {user.archiveFirstNoteDate && user.archiveLastNoteDate ? (
                                        <>
                                            {dateConvert(user.archiveFirstNoteDate.replace(/ \+0000 UTC/, '')).toFormat('dd.LL.yyyy')}-
                                            {dateConvert(user.archiveLastNoteDate.replace(/ \+0000 UTC/, '')).toFormat('dd.LL.yyyy')}
                                        </>
                                    ) : (
                                        'Не определен'
                                    )}
                                </Column>
                                <div className={style.list_item__download_btn_wrapper}>
                                    <IconButton className={cx(style.list_item__btn, style.btn_icon)} onClick={onDownloadArchive(user.uuid)}>
                                        <DownloadIcon />
                                    </IconButton>
                                    <a id={user.uuid} className={style.hidden} href=" " target="_blank" download={true} rel="noreferrer">
                                    </a>
                                </div>
                            </Grid>
                        );
                    })}
        </DataList>
    );
};
