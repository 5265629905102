import { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as DeleteIcon } from '@assets/icons/delete-icon-default.svg';
import { ReactComponent as SkillIcon } from '@assets/icons/skill.svg';
import Loader from '@components/core/Loader';
import IconButton from '@components/core/styledWrappers/IconButton';
import { ImgWithDummy } from '@components/ImgWithDummy';
import { useToggle } from '@hooks/useToggle.hook';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDeleteSkillMutation } from '@store/api/skillsApi';
import { useGetFileQuery } from '@store/api/storageApi';

import DeleteSkillModal from '../DeleteSkillModal';
import styles from './SkillCard.module.scss';

interface SkillCardProps {
    iconUUID?: string,
    name: string,
    UUID: string,
}

const SkillCard = ({ iconUUID, name, UUID }: SkillCardProps) => {

    const {
        data: file,
        isError: getFileIsError,
        isFetching: getFileIsFetching
    } = useGetFileQuery(iconUUID ? iconUUID : skipToken);

    const [deleteSkill, {
        isLoading:deleteSkillIsLoading,
        isSuccess:deleteSkillIsSuccess
    }] = useDeleteSkillMutation();

    const [isOpen, { on:openModal, off:closeModal }] = useToggle(false);

    const removeSkill = () => {
        deleteSkill(UUID);
    };

    useEffect(()=>{
        if(deleteSkillIsSuccess){
            closeModal();
            toast.success(`Компетенция "${name}" удалена`);
        }
    },[deleteSkillIsSuccess]);

    const renderIcon = () => {
        if(getFileIsFetching){
            return <Loader />;
        }

        if(getFileIsError){
            return <SkillIcon />;
        }

        return <ImgWithDummy src={ file && file.files.length ? file.files[0].path : undefined} alt={name} />;
    };

    return (
        <>
            <Link to={`/additional-settings/skills/edit/${UUID}`} className={styles.card}>

                <div className={styles.icon}>
                    {renderIcon()}
                </div>

                <p>{name}</p>

                <IconButton className={styles.delete} onClick={(e)=>{openModal();e.preventDefault();}}>
                    <DeleteIcon />
                </IconButton>
            </Link>
            <DeleteSkillModal isOpen={isOpen} onClose={closeModal} isLoading={deleteSkillIsLoading} onDelete={removeSkill} />
        </>
    );
};

export default SkillCard;
