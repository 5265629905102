import React from 'react';

import { components, DropdownIndicatorProps, ValueContainerProps } from 'react-select';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-icon.svg';
import { OptionType } from '@components/core/inputs/SelectStyled/type';

import style from './SelectCheckbox.module.scss';

export const CustomValueContainer = ({ children, ...props }: ValueContainerProps<OptionType>) => {

    //@ts-ignore
    const isAll = props.selectProps.value?.length === props.options.length;
    //@ts-ignore
    const count = props.selectProps.value?.length as Array;

    return (
        <components.ValueContainer {...props}>
            <div className={style.placeholder}>
                { 
                    !isAll 
                        ?
                        count > 0 ? <span>Заданий: <strong>{count} выбрано</strong> </span> : <span>Выбрать задания</span> 
                        : 
                        <span>Заданий: <strong>Все</strong> </span> 
                }
            </div>
        </components.ValueContainer>
    );
};

export const CustomDropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowIcon />
        </components.DropdownIndicator>
    );
};
