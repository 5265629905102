import { FC } from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LogoMedium } from '@assets/icons/logo-veip-medium.svg';
import { ReactComponent as Logotype } from '@assets/icons/logo-veip.svg';
import { useBreakpoint } from '@hooks/useBreakpoint';

import style from './logo.module.scss';

interface IProps {
    className?: string;
    isLarge?: boolean
}

const Logo: FC<IProps> = ({ className, isLarge = false }) => {
    const navigate = useNavigate();
    const lgUp = useBreakpoint('lg', 'up');

    return (
        <div className={cx(style.wrapper, className)} onClick={() => navigate('/')}>
            {lgUp || isLarge ? <Logotype /> : <LogoMedium />}
        </div>
    );
};

export default Logo;
