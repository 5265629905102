import { useEffect, useState } from 'react';

import { useToggle } from '@hooks/useToggle.hook';
import { useDeleteDisciplineMutation } from '@store/api/disciplinesApi';

type OpenDeleteModal = (uuid: string, name: string) => void

export const useDelete = () => {
    const [isOpen, { on, off }] = useToggle();

    const [currentMaterialName, setCurrentMaterialName] = useState('');
    const [currentMaterialId, setCurrentMaterialId] = useState('');

    const [deleteCourse, { isError: deleteErr, isSuccess: deleteSuccess }] = useDeleteDisciplineMutation();

    const openDeleteModal: OpenDeleteModal = (uuid, name) => {
        on();
        setCurrentMaterialId(uuid);
        setCurrentMaterialName(name);
    };

    useEffect(() => {
        if (deleteSuccess) {
            off();
            setCurrentMaterialName('');
            setCurrentMaterialId('');
        }
    }, [deleteSuccess]);

    return {
        isOpenDeleteModal: isOpen,
        openDeleteModal,
        closeDeleteModal: off,
        deleteCourse,
        currentMaterialName,
        currentMaterialId,
        deleteSuccess
    };
};
