import React from 'react';

import { useNavigate } from 'react-router-dom';

import { BottomDrawer } from '@components/BottomDrawer';
import Button from '@components/core/inputs/Button';
import Modal from '@components/Modal';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { DisciplineScoreItem, IScoreMaterials } from '@type/scoreCard';
import { UserGradeCell } from '@views/methodist/Users/UserProfile/UserRecordBook/UserRecordPanelTable/UserGradeCell';

import { Item } from './components/Item';
import styles from './DetailsModal.module.scss';

interface DetailsModalProps {
    opened: boolean;
    onOpen: () => void;
    onClose: () => void;
    data: DisciplineScoreItem | null;
}

export const DetailsModal: React.FC<DetailsModalProps> = ({ opened, onOpen, onClose, data }) => {
    const navigate = useNavigate();
    const smDown = useBreakpoint('sm', 'down');

    const disciplineQueryData = useGetDisciplineByIdQuery(data?.default.disciplineUUID || skipToken);

    const user = useAppSelector(selectApiUser);

    const isStudent = user?.role === Role.Student;

    const goDiscipline = () => {
        if (isStudent) {
            navigate(`/education/${data?.default.disciplineUUID}`);
        } else {
            navigate(`/materials/${disciplineQueryData.data?.uuidCategory}/course/${data?.default.disciplineUUID}/scores`);
        }
    };

    const goDisciplineHref = isStudent
        ? `/education/${data?.default.disciplineUUID}`
        : `/materials/${disciplineQueryData.data?.uuidCategory}/course/${data?.default.disciplineUUID}/scores`;

    const renderItems = () => {

        if (!data) return null;

        const itemsData = [...data.default.materials].sort((a: IScoreMaterials, b: IScoreMaterials) => b.grade - a.grade);

        return <div className={styles.wrapper}>
            {
                itemsData.map(material => (
                    <Item
                        key={material.materialUUID}
                        className={styles.offset}
                        title={material.materialName}
                        grade={material.grade}
                        description={material.teacherReview}
                        material={material}
                    />
                ))
            }
        </div>;
    };

    const renderContent = () => {
        return (
            <>
                {renderItems()}

                {!!data?.grade && data.grade <= 100 && (
                    <div className={styles.total}>
                        <p>Итоговая оценка</p>
                        <UserGradeCell score={data.grade} closeType={data.default.closeType} />
                    </div>
                )}
                <div className={styles.footer}>
                    <Button btnType="outline" link to={goDisciplineHref}>
                        Перейти к дисциплине
                    </Button>
                </div>
            </>
        );
    };

    if (!smDown) {
        return (
            <Modal
                onClose={onClose}
                isOpen={opened}
                title={data?.discipline}
                className={styles.container}
            >
                {renderContent()}
            </Modal>
        );
    } else {
        return (
            <BottomDrawer open={opened} onOpen={onOpen} onClose={onClose} contentClassName={styles['drawer-content']}>
                <h3 className={styles.title}>{data?.discipline}</h3>
                {renderContent()}
            </BottomDrawer>
        );
    }


};
