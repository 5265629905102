import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from '@store/baseQuery';

export const chatBaseApi = createApi({
    baseQuery: baseQueryWithReauth('chat'),
    reducerPath: 'chatApi',
    tagTypes: [
        'Rooms', 'Messages'
    ],
    endpoints: () => ({ })
});

