import React, { FC, useState } from 'react';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import FilesListMenu from '@components/core/styledWrappers/FilesListMenu';
import VideoPreview from '@components/core/VideoPreview/VideoPreview';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { dateConvert } from '@helpers/date';
import { VideoLectureResponce } from '@store/api/apiTypes';
import { useDeleteVideoLectureMutation } from '@store/api/videosApi';

import style from './VideoCard..module.scss';

export type TVideoProps = {
    isOpen?: boolean,
    defaultValues?: VideoLectureResponce,
    actionType?: string,
    videoId?: string
}
interface IVideoCardProps {
    video: VideoLectureResponce;
    setStateVideoModal: (props: TVideoProps) => void;
    deleteVideoHandler?: (currentVideo: VideoLectureResponce) => void;
    disabled?: boolean;
}

const VideoCard: FC<IVideoCardProps> = ({ video, setStateVideoModal, disabled }) => {
    const [deleteVideo] = useDeleteVideoLectureMutation();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const convertTime = (date: string) => {
        // 'yyyy-MM-dd HH:mm:ss +0000 UTC'
        const dt = dateConvert(date.replace(/ \+0000 UTC/, ''));
        return dt.toFormat('dd.LL.yyyy');
    };

    return (
        <>
            <div key={video.uuid} className={style.video_card} id={video.uuid}>
                <VideoPreview link={video.linkFile} videoName={video.name} />
                <p>{video.name}</p>
                {
                    video.files.length ?
                        <FilesListMenu
                            className={style.video_card__files}
                            isLink={true}
                            files={video.files}
                        /> : null
                }
                <span className={style.video_card__date}>
                    {convertTime(video.updatedAt)}
                </span>
                {!disabled && (
                    <div className={style.video_card__wrapper_btn}>
                        <ButtonIcon
                            typeIcon="edit"
                            onClick={() => setStateVideoModal({
                                isOpen: true,
                                defaultValues: video,
                                actionType: 'edit',
                                videoId: video.uuid
                            })}
                        />
                        <ButtonIcon
                            typeIcon="basket"
                            onClick={() => setShowDeleteModal(true)}
                        />
                    </div>
                )}
            </div>
            {
                showDeleteModal && <DeleteItemModal
                    isOpen={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    onDelete={() => {
                        deleteVideo(video?.uuid ?? '');
                        setShowDeleteModal(false);
                    }}
                    itemName={video?.name}
                    itemType="videoLecture"
                />
            }
        </>
    );
};

export default VideoCard;
