import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from '@store/baseQuery';

export const emptyBaseApi = createApi({
    baseQuery: baseQueryWithReauth('main'),
    reducerPath: 'mainApi',
    tagTypes: [
        'Disciplines', 'Material', 'SectionList', 'Videos', 'LectureBlocks',
        'Topics', 'Lectures', 'Lectures_LIST', 'Chapters', 'Users_List', 'Group_List',
        'GroupSubs', 'Tests', 'TestQuestions', 'StudentTest', 'FinishStudentTest',
        'Events', 'PastEvents', 'TestAttempt', 'Authentication', 'Grades', 'ChaptersProgress',
        'GroupDeadlines', 'Page', 'TeacherGrades', 'TaskRetakes', 'User', 'GradeScores',
        'Notices', 'TeacherReports', 'StudentsDeadlines', 'Methodists', 'GroupDebtors', 'ExtraSession',
        'StudentsSubs', 'Journal', 'Portfolio', 'PortfolioFilesList', 'StudyProgramme',
        'Skills', 'SkillDisciplines', 'Presets'
    ],
    endpoints: () => ({})
});

