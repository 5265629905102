import { toast } from 'react-toastify';

import { CreateTaskData, CreateTaskDataResponse, GetTaskDataResponse, UpdateTaskData, UpdateTaskDataResponse, Uuid } from '@store/api/apiTypes';

import { emptyBaseApi } from './emptyBaseApi';

export const taskApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getTaskById: build.query<GetTaskDataResponse, Uuid>({
            query: (uuidTask: Uuid) => `disciplines/materials/task/find/${uuidTask}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить контрольную');
                }
            },
            transformResponse: (result: GetTaskDataResponse) => {
                return result;
            },
            providesTags: (result, error, arg) => [{ type: 'Material', id: arg }]
        }),
        createTask: build.mutation<CreateTaskDataResponse, CreateTaskData>({
            query(data) {
                return {
                    url: 'disciplines/materials/task/create',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Контрольная работа создана',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => ['SectionList', { type: 'Material', id: `List_${arg.uuidParent}` }]
        }),
        updateTask: build.mutation<UpdateTaskDataResponse, UpdateTaskData>({
            query(data) {
                return {
                    url: `disciplines/materials/task/update/${data.uuid}`,
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Контрольная работа обновлена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => ['SectionList', { type: 'Material', id: `List_${arg.uuid}` }, { type: 'Material', id: arg.uuid }]
        })
    })
});

export const { useGetTaskByIdQuery, useCreateTaskMutation, useUpdateTaskMutation } = taskApi;
