import React, { FC } from 'react';

import { useController, UseControllerProps, useFormContext } from 'react-hook-form';

import TreeSelect, { NewTreeSelectProps } from '@components/core/inputs/Select/NewTreeSelect';

type ITreeSelectControl = Omit<NewTreeSelectProps, 'value' | 'onChange'> & UseControllerProps

const TreeSelectControl: FC<ITreeSelectControl> = ({
    name,
    options,
    error,
    ...props
}) => {
    const { control, formState: { errors } } = useFormContext();

    const {
        field: { onChange, value }
    } = useController({ name, control });

    return (
        <TreeSelect
            options={options}
            {...props}
            onChange={onChange}
            value={value}
            error={error ? error : errors[name]}
        />
    );
};

export default TreeSelectControl;
