import { FC } from 'react';

import cx from 'classnames';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';

import { checkRole } from '@components/RoleCheck';
import { LectureBlocksChapter } from '@store/api/apiTypes';
import { Role } from '@store/Roles';

import style from './ChapterItem.module.scss';


interface IChapterItem {
    chapter: LectureBlocksChapter
}

const ChapterItem: FC<IChapterItem> = ({ chapter }) => {
    const resolved = useResolvedPath(chapter.uuid);
    const match = useMatch({ path: resolved.pathname, end: true });

    return (
        <li>
            <div className={cx(style.link, match && style['link--active'])}>
                <NavLink
                    // @ts-ignore
                    className={cx(chapter.disabled && checkRole(Role.Student) && style.disabled_link, match && style['disabled_link--active'])}
                    to={`${chapter.uuid}`}
                >
                    {chapter.name}
                </NavLink>
            </div>
        </li>
    );
};

export default ChapterItem;
