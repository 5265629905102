import { FC, useEffect, useMemo } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import { OptionType } from '@components/core/inputs/SelectStyled/type';
import Modal from '@components/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { Preset, ResponseMessage, SkillDiscipline, WithUUID } from '@store/api/apiTypes';
import { useGetDisciplinesByCategoryQuery, useGetDisciplinesByIdQuery } from '@store/api/disciplinesApi';
import { useUpdatePresetMutation } from '@store/api/skillsApi';

import style from './UpdatePresetModal.module.scss';

export const updatePresetSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    uuidsDiscipline: yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    }))
});

interface UpdatePresetModalProps {
    preset: WithUUID<Preset>
    skillDisciplines: ResponseMessage<SkillDiscipline>[] | undefined;
    isOpen: boolean,
    closeModal: () => void
}

const UpdatePresetModal: FC<UpdatePresetModalProps> = ({ preset, isOpen, closeModal, skillDisciplines }) => {
    const selectedDisciplines = useMemo(() => skillDisciplines ? skillDisciplines.reduce((result:OptionType[], discipline) => {
        if (preset.uuidsDiscipline?.find(item => item === discipline.uuid)) {
            result.push({ label: discipline.name, value: discipline.uuid });
        }
        return result;
    }, []) : [], [preset.uuidsDiscipline, skillDisciplines]);

    const methods = useForm({
        resolver: yupResolver(updatePresetSchema),
        defaultValues: {
            name: preset.name,
            uuidsDiscipline: selectedDisciplines
        }
    });

    const { control, handleSubmit, setValue, watch } = methods;

    const watchedSelectedDisciplines = watch('uuidsDiscipline');

    const selectedDisciplineRealUuids = skillDisciplines
        ?.find((discipline) => discipline.uuid === watchedSelectedDisciplines[0]?.value)?.uuidsRealDisciplines ?? [];

    const {
        data: realDisciplines,
        isFetching: isDisciplinesByIdLoading,
        isError: isDisciplinesByIdError
    } = useGetDisciplinesByIdQuery(selectedDisciplineRealUuids, { skip: !isOpen || !selectedDisciplineRealUuids.length });
    const {
        data: disciplinesByCategory,
        isFetching: isDisciplinesByCategoryLoading,
        isError: isDisciplinesByCategoryError
    } = useGetDisciplinesByCategoryQuery(realDisciplines?.[0]?.uuidCategory ?? '', { skip: !realDisciplines?.[0] || !isOpen });

    useEffect(()=> {
        if (isOpen) {
            setValue('name', preset.name);
            setValue('uuidsDiscipline', selectedDisciplines);
        }
    },[selectedDisciplines, isOpen]);

    const [updatePreset, {
        isLoading: updatePresetIsLoading,
        isSuccess: updatePresetIsSuccess
    }] = useUpdatePresetMutation();

    useEffect(()=>{
        if(updatePresetIsSuccess) toast.success('Пресет успешно изменён');
    },[updatePresetIsSuccess]);

    const onSubmit = async (formData: any) => {
        const transformedFormData = {
            ...preset,
            name: formData.name,
            uuidsDiscipline: formData.uuidsDiscipline?.map((item:OptionType) => item.value)
        };
        updatePreset(transformedFormData);
    };

    const disciplines = useMemo(() => skillDisciplines?.filter((discipline) => {
        if (!watchedSelectedDisciplines?.length || selectedDisciplineRealUuids.length === 0) {
            return true;
        }
        const index = disciplinesByCategory?.findIndex((categoryDiscipline) =>  discipline.uuidsRealDisciplines.includes(categoryDiscipline.uuid)) ?? -1;
        return index !== -1;
    }), [watchedSelectedDisciplines?.length, disciplinesByCategory, skillDisciplines]);
    const isSelectDisabled = isDisciplinesByCategoryLoading || isDisciplinesByIdLoading || isDisciplinesByCategoryError || isDisciplinesByIdError;

    const isShowModal = isOpen && (isSelectDisabled || !!disciplines?.length);

    useEffect(() => {
        if (isOpen && !isShowModal) {
            toast.error('Не смогли получить список дисциплин');
        }
    }, [isShowModal, isOpen]);

    return (
        <Modal
            onClose={closeModal}
            isOpen={isShowModal}
            title="Редактировать пресет"
        >

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className={style.container}>

                    <div className={style.list}>

                        <div className={style.input}>
                            <label htmlFor="name">Название</label>
                            <InputControl
                                name="name"
                                placeholder="Введите название"
                                id="name"
                            />
                        </div>

                        {disciplines &&
                            <div className={style.input}>
                                <label htmlFor="uuidsDiscipline">Плановые дисциплины</label>
                                <SelectStyled
                                    control={control}
                                    name="uuidsDiscipline"
                                    options={disciplines.map(discipline => ({ label:discipline.name, value: discipline.uuid }))}
                                    isMulti
                                    isDisabled={isSelectDisabled}
                                    placeholder="Выберите плановые дисциплины"
                                />
                            </div>
                        }
                    </div>

                    <div className={style.button_wrapper}>
                        <Button
                            onClick={closeModal}
                            btnType={'secondary'}
                        >
                            Отмена
                        </Button>
                        <Button
                            type='submit'
                            disabled={updatePresetIsLoading}
                            typeIcon={updatePresetIsLoading ? 'loading' : undefined}
                        >
                            Сохранить
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default UpdatePresetModal;






















