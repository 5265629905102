import React from 'react';

import { NewLoader } from '@components/core/NewLoader';
import ChangeCategoryModal from '@components/methodist/ChangeCategoryModal';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { useChangeCategory } from '@components/methodist/hooks/useChangeCategory';
import { useDelete } from '@components/methodist/hooks/useDelete';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetDisciplinesByCategoryQuery, useGetDisciplinesByMethodistQuery } from '@store/api/disciplinesApi';
import { DisciplineItem } from '@views/methodist/Materials/MaterialsPage/MaterialsList/components/DisciplineItem';

import styles from './DistiplinesList.module.scss';


interface DistiplinesListProps {
    categoryUuid?: string;
    methodistUuid?: string;
}

export const DistiplinesList: React.FC<DistiplinesListProps> = ({ categoryUuid, methodistUuid }) => {
    const {
        isOpenDeleteModal,
        openDeleteModal,
        closeDeleteModal,
        deleteCourse,
        currentMaterialName,
        currentMaterialId
    } = useDelete();

    const {
        isOpenChangeModal,
        openCategoryModal,
        closeCategoryModal,
        changeCourseCategory,
        currentMaterialId: selectedDisciplineId
    } = useChangeCategory();

    const { data, isFetching } = useGetDisciplinesByCategoryQuery(categoryUuid || skipToken);
    const { data: dataMethodist, isFetching: isFetchingMethodist } = useGetDisciplinesByMethodistQuery(methodistUuid || skipToken);

    const currentData = methodistUuid ? dataMethodist : data;
    const dataForRender = currentData || [];

    if (isFetching || isFetchingMethodist) {
        return <NewLoader />;
    }

    if (!dataForRender || !dataForRender.length) {
        return null;
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    Название
                </div>

                {dataForRender.map((discipline) => (
                    <DisciplineItem
                        key={discipline.uuid}
                        {...discipline}
                        openCategoryModal={openCategoryModal}
                        openDeleteModal={openDeleteModal} />
                ))}
            </div>

            <DeleteItemModal
                isOpen={isOpenDeleteModal}
                onClose={closeDeleteModal}
                onDelete={() => deleteCourse(currentMaterialId)}
                itemName={currentMaterialName}
                itemType="discipline"
            />
            <ChangeCategoryModal
                isOpen={isOpenChangeModal}
                onClose={closeCategoryModal}
                onChange={changeCourseCategory}
                currentMaterialId={selectedDisciplineId}
            />
        </>
    );
};
