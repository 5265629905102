import React, { FC, ReactNode, useRef } from 'react';

import cx from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

import Button from '@components/core/inputs/Button';
import { IButtonProps } from '@components/core/inputs/Button/Button';
import { DropMenu } from '@components/core/styledWrappers/DropMenu';
import { IDropMenuProps } from '@components/core/styledWrappers/DropMenu/DropMenu';
import { DropMenuProvider, useDropMenuContext } from '@components/core/styledWrappers/DropMenu/dropMenuContext';

import style from './button_drop.module.scss';

interface IProps extends IButtonProps {
    buttonChild?: ReactNode;
    dropMenuProps?: IDropMenuProps;
    margin?: boolean;
    inlineStyle?: React.CSSProperties;
}

const ButtonDrop: FC<IProps> = ({
    children,
    className,
    buttonChild,
    dropMenuProps,
    inlineStyle,
    ...props
}) => {
    const DropMenuContainer = () => {
        const { toggleMenu, setToggleMenu } = useDropMenuContext();
        //! Quick fix
        const dropRef = useRef<HTMLDivElement>(null);

        useOnClickOutside(dropRef, () => {
            if (toggleMenu) {
                setToggleMenu(false);
            }
        });

        return (
            <div
                className={cx(
                    style.root_wrapper,
                    'btn-drop',
                    toggleMenu && 'btn-drop--dropped'
                )}
                //! Quick fix
                ref={dropRef}
                style={inlineStyle}
            >
                <Button
                    className={cx(style.root, className)}
                    //! Quick fix
                    // innerRef={dropRef}
                    onClick={(e) => {
                        e.preventDefault();
                        setToggleMenu(!toggleMenu);
                    }}
                    {...props}
                >
                    {buttonChild}
                </Button>

                {toggleMenu && <DropMenu {...dropMenuProps}>{children}</DropMenu>}
            </div>
        );
    };

    return (
        <DropMenuProvider>
            <DropMenuContainer />
        </DropMenuProvider>
    );
};

export default ButtonDrop;
