import { Navigate } from 'react-router-dom';

import Loader from '@components/core/Loader';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';

const RoleIndexRoute = () => {
    const user = useAppSelector(selectApiUser);

    if (user) {
        switch (user.role) {
            case Role.Methodist:
                return <Navigate to="/materials" replace />;

            case Role.Inspector:
                return <Navigate to="/materials" replace />;

            case Role.Student:
                return <Navigate to="/education" replace />;

            case Role.Teacher:
                return <Navigate to="/teaching" replace />;

            default:
                break;
        }
    }

    return (<Loader />);
};

export default RoleIndexRoute;
