import React from 'react';

import Main from '@components/Main';
import GoBackLink from '@components/student/GoBackLink';

import style from './TrainingRulesPage.module.scss';

const TrainingRulesPage = () => {
    return (
        <Main title="Правила обучения" className={style.container}>
            <GoBackLink to={'/'}>
                Назад
            </GoBackLink>

            <h1 className={style.main_title}>
                Правила
                реализации профессиональных образовательных программ с использованием дистанционных образовательных технологий
                в Восточно-Европейском институте психоанализа
            </h1>

            <div className={style.main_content}>
                <ul>
                    <li>
                        1. Общие положения
                        <ol>
                            <li>
                                <p>
                                    1.1 Получение образования с использованием дистанционных образовательных технологий (далее – ДОТ) предполагает, что
                                    обучающиеся
                                    осознают
                                    ответственность за свое обучение и работают в своем темпе на удобном для себя месте. Система ДОТ позволяет обучающемуся
                                    осваивать
                                    материалы
                                    в любое
                                    удобное время и доступна пользователям 24/7.
                                </p>
                            </li>
                            <li>
                                <p>
                                    1.2 В основе образования с использованием ДОТ лежит принцип обучения, ориентированный на самостоятельную работу обучающихся.
                                    Обучающийся
                                    обязан
                                    самостоятельно отслеживать информацию в ходе процесса обучения в электронной информационно-образовательной среде.
                                </p>
                            </li>
                            <li>
                                <p>
                                    1.3 Доступ для освоения учебных дисциплин определяется установленным календарным планом. Поддержка обучения осуществляется
                                    сотрудниками
                                    Института –
                                    специалистами ДОТ. За каждым специалистом закрепляются определенные учебные дисциплины (электронные курсы). Обучающиеся
                                    могут
                                    обращаться к
                                    специалистам через систему личных сообщений и по телефону «Горячей линии» 8 (800) 3333776 (выбор в голосовом меню 2 «Вы наш
                                    студент», далее
                                    – 4 «По
                                    вопросам дистанционного обучения»). Ответ в сообщениях поступает в течение рабочего дня с 10:00 до 18:00 (в пятницу – до
                                    17:00).
                                    Обращаем
                                    внимание,
                                    что мы работаем по Московскому времени!
                                </p>
                            </li>
                            <li>
                                <p>
                                    1.4 В Восточно-Европейском институте психоанализа (ВЕИП) используется собственная электронная информационно-образовательная
                                    среда
                                    ДОТ (далее
                                    – ЭИОС
                                    ДОТ). Указанная среда предлагает широкий спектр возможностей для поддержки процесса обучения с использованием ДОТ:
                                    разнообразные
                                    способы
                                    представления учебного материала, проверки знаний и контроля успеваемости. Система ДОТ ВЕИП оснащена различными
                                    дополнительными
                                    опциями,
                                    разработанными для повышения комфорта и эффективности обучения: усовершенствованной системой обмена сообщениями, электронной
                                    зачёткой
                                    обучающегося,
                                    системой отслеживания успеваемости и др.
                                </p>
                            </li>
                            <li>
                                <p>
                                    1.5 Для обеспечения качества содержательного наполнения учебных курсов в ЭИОС ДОТ принят базовый комплект
                                    электронно-методического
                                    комплекса
                                    (ЭМК).
                                    Материалы, входящие в ЭМК позволяют усвоить предложенный материал и успешно подготовиться к промежуточной и итоговой
                                    аттестации.
                                    Обучаясь с
                                    использованием ДОТ, обучающиеся имеют возможность взаимодействовать с преподавателями online (в формате вебинаров) и offline
                                    (в
                                    формате
                                    личных
                                    сообщений через специалиста).
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            2. Рекомендации по началу работы в системе дистанционного обучения ВЕИП
                        </p>
                        <ol>
                            <li>
                                <p>
                                    2.1. Перед началом работы в системе дистанционного обучения ВЕИП:
                                </p>
                                <ol>
                                    <li>
                                        <p>
                                            2.1.1 Проверьте наличие стабильного высокосортного Интернет-соединения, которое необходимо для адекватного
                                            отображения
                                            учебных материалов, просмотра
                                            видеолекций, участия в учебных онлайн-встречах (вебинарах). Для участия в вебинарах рекомендуется использовать
                                            гарнитуру
                                            закрытого типа (наушники +
                                            микрофон). Требования к составу технических средств и программного обеспечения, необходимых для участия в
                                            дистанционном
                                            обучении, отражены в
                                            Приложении 1.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            2.1.2 Создайте отдельную электронную почту для регистрации в ЭИОС ДОТ. В процессе обучения на почту может приходить
                                            достаточно много новостных
                                            рассылок и другой системной информации, что может затруднять работу с электронным ящиком. Также рекомендуется
                                            проверить
                                            настройки фильтров спама на
                                            электронной почте – сообщения ЭИОС ДОТ могут попадать в «спам», так как отсылаются системой.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            2.1.3 Определите время для работы в системе. Следует иметь в виду значительный объём учебных материалов , и быть
                                            готовым
                                            к
                                            регулярной учебной
                                            нагрузке, которую рекомендуется планировать заранее, ориентируясь на календарный план.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p>2.2. Войдя в ЭИОС ДОТ:</p>
                                <ol>
                                    <li>
                                        <p>
                                            2.2.1 Внимательно ознакомьтесь с интерфейсом главной страницы системы. Ознакомьтесь с контактной информацией,
                                            структурой
                                            электронных курсов. Все
                                            курсы имеют одинаковую структуру.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            2.2.2 Ознакомьтесь с содержанием информационного раздела электронного курса, в котором размещены аннотация,
                                            программа,
                                            литература и рекомендации.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            2.2.3 Ознакомьтесь с календарным планом подготовки. Изучите календарный план на текущий учебный год – это позволит
                                            спланировать и составить личный
                                            план самостоятельной работы по изучению учебного материала.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            2.2.4 Загрузите фотографию. Загрузка фотографии связана с необходимостью идентификации обучающихся в процессе
                                            обучения,
                                            промежуточных аттестационных
                                            мероприятий и итоговой аттестации. Сотрудники отдела дистанционного обучения и преподаватели хотят видеть и знать
                                            своих
                                            обучающихся и, насколько это
                                            возможно, персонифицировать и индивидуализировать общение.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            2.2.5 Сохраните логин и пароль. Получить доступ к ЭИОС ДОТ можно только введя свои логин и пароль. После первого
                                            входа в
                                            систему, обучающемуся может
                                            быть предложено изменить пароль. Пожалуйста, сохраняйте и запоминайте свою парольную информацию ! И помните, что в
                                            ЭИОС
                                            ДОТ
                                            хранятся все личные
                                            данные успеваемости обучающегося – не следует давать логин и пароль другим людям!
                                        </p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>3. Календарный план</p>
                        <ol>
                            <li>
                                <p>
                                    3.1 Календарный план является основным документом, регламентирующим учебный процесс, в котором отражены дисциплины для
                                    изучения
                                    и
                                    сроки их открытия. Учебные дисциплины, открытые в соответствии с календарным планом, доступны обучающемуся для изучения в
                                    течение всего
                                    учебного
                                    года.
                                </p>
                            </li>
                            <li>
                                <p>
                                    3.2 В течение учебного года обучающемуся предлагается изучить дисциплины, сгруппированные в семестры (модули). Каждый
                                    семестр
                                    открывается и
                                    закрывается в определенные сроки. Обращаем Ваше внимание, что июль и август – период летних каникул для преподавательского
                                    состава и
                                    сотрудников.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>4. Освоение теоретического материала</p>
                        <ol>
                            <li>
                                <p>
                                    4.1 Учебный процесс с использованием ДОТ организован так, чтобы обеспечить выполнение требований ФГОС ВО (при их наличии).
                                    Обучение
                                    ведется по
                                    утвержденным образовательным программам.
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.2 Учебный план – основной документ образовательной программы, в соответствии с которым осуществляется организация учебного
                                    процесса.
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.3 Объем основного содержания учебной дисциплины определяется исходя из учебных планов и календарных программ дисциплин.
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.4 В ЭИОС ДОТ учебные дисциплины представлены в формате электронных курсов и имеют определённую структуру.
                                </p>
                            </li>
                            <li>
                                <p>4.5 Дисциплины включают в себя:</p>
                                <ul>
                                    <li>
                                        - Аннотацию и программу (содержание) учебного курса;
                                    </li>
                                    <li>
                                        - Рекомендации по самостоятельному изучению учебного курса;
                                    </li>
                                    <li>
                                        - Лекционный материал по темам курса;
                                    </li>
                                    <li>
                                        - Список рекомендуемой литературы;
                                    </li>
                                    <li>
                                        - Вопросы для самопроверки;
                                    </li>
                                    <li>
                                        - Элементы оценивания: итоговые тесты по учебному курсу и / или контрольные задания (эссе, реферат, творческое задание,
                                        практическое
                                        задание, ситуационная задача и др. – необходимость данного вида работ определяется преподавателем);
                                    </li>
                                    <li>
                                        - Дополнительные материалы (видеозаписи лекций, вебинаров, дополнительная литература и пр.).
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    4.6 При изучении материала дисциплины следует руководствоваться программой и методическими рекомендациями по
                                    самостоятельному
                                    изучению учебного
                                    курса. Программа указывает, что должен изучить обучающийся, а методические указания разъясняют, как надо изучать материал и
                                    выполнять задания.
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.7 Изучение дисциплины следует планировать, учитывая время работы над материалом, количество и сроки представления
                                    контрольных
                                    работ, прохождения
                                    тестирований; сроки сдачи экзаменов (зачетов; дифференцированных зачетов). Для начинающих самостоятельно заниматься –
                                    расписание
                                    личных занятий
                                    рекомендуется составлять на месяц. Получив некоторый опыт, можно перейти к планированию работы на полугодие или на весь
                                    учебный
                                    год. Обучающийся
                                    должен ежедневно выделять 2-3 часа для учебной работы.
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.8 Рекомендуется изучать одновременно несколько дисциплин, но в расписание следует включать не более 2-х дисциплин в день,
                                    при
                                    этом, на первые часы
                                    планировать дисциплины более трудные для усвоения. Работа с комплексом материалов учебного курса должна строиться по
                                    стратегии
                                    последовательного
                                    овладения темами курса (не рекомендуется переход к следующим темам, минуя предыдущие).
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.9 Приступая к изучению выбранной дисциплины, необходимо, в первую очередь, составить план занятий, то есть разделить
                                    материал
                                    на последовательно
                                    изучаемые темы, пользуясь учебной программой, методическими указаниями и контрольными заданиями, которые в значительной мере
                                    помогут организовать
                                    самостоятельную работу и облегчат усвоение материала.
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.10 Продумав последовательность изучения дисциплины, обучающийся должен наметить сроки работы над каждой темой. Конечно, в
                                    процессе учебы они могут
                                    изменяться в зависимости от трудности того или иного материала, но в основе занятий должен быть план. Работа без плана
                                    приводит
                                    к нерациональному
                                    расходу времени, в результате чего у обучающегося к концу года могут появиться академические задолженности.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            5. Сопровождение обучения
                        </p>
                        <ol>
                            <li>
                                <p>
                                    5.1 Организаторами учебной деятельности обучающихся выступают преподаватели Института и методисты, закреплённые за каждой
                                    дисциплиной. Основной
                                    задачей преподавателя является сопровождение учебного процесса на основе заранее подготовленных учебных материалов
                                    (электронных
                                    курсов).
                                </p>
                            </li>
                            <li>
                                <p>
                                    5.2 Учебный процесс осуществляется, как правило, в период с сентября по июнь. Каникулы определяются календарным учебным
                                    графиком.
                                </p>
                            </li>
                            <li>
                                <p>
                                    5.3 Взаимодействие преподавателя с обучающимся происходит дистанционно через веб-сайт системы.
                                </p>
                            </li>
                            <li>
                                <p>
                                    5.4 Основными функциями преподавателя являются:
                                </p>
                                <ul>
                                    <li>
                                        - ответы на вопросы обучающихся в режимах online (вебинар) и offline (через методистов);
                                    </li>
                                    <li>
                                        - проверка контрольных заданий, выставление оценок.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    5.5 Преподаватель отвечает на вопросы обучающихся в период изучения учебного курса в соответствии календарным планом.
                                    Вопросы
                                    преподавателям можно
                                    задавать через специалиста соответствующей дисциплины.
                                </p>
                            </li>
                            <li>
                                <p>
                                    5.6 Ответы на вопросы и проверка контрольных работ осуществляется в течение четырнадцати календарных дней с момента их
                                    размещения в
                                    системе
                                    дистанционного обучения. В случае загрузки работы в последнюю неделю модуля срок проверки увеличивается.
                                </p>
                            </li>
                            <li>
                                <p>
                                    5.7 Основной задачей специалистов является курирование и сопровождение учебного процесса, а также координация взаимодействия
                                    обучающихся и
                                    преподавателей. Специалисты закреплены за каждым электронным курсом. Имена и фамилии специалистов, курирующих ту или иную
                                    дисциплину, отражаются в
                                    календарных планах и на первой странице учебного курса. Специалист поддерживает тесную связь обучающихся с преподавателем и
                                    отвечает на вопросы,
                                    касающиеся организации учебного процесса.
                                </p>
                            </li>
                            <li>
                                <p>
                                    5.8 Обращаем внимание: специалист отвечает именно за организационную сторону обучения. По всем вопросам содержания
                                    дисциплины
                                    отвечает преподаватель
                                    (все вопросы передаются через специалиста курса)!
                                </p>
                            </li>
                            <li>
                                <p>
                                    5.9 Связь с специалистом курса осуществляется через ЭИОС ДОТ (личное сообщение) или по телефону «Горячей линии» 8 (800)
                                    3333776
                                    (звонок бесплатный
                                    на территории РФ). Для иностранных обучающихся: 8 (812) 4261413.
                                </p>
                            </li>
                            <li>
                                <p>
                                    5.10 Техническая поддержка процесса обучения состоит в оказании обучающемуся консультаций по установке и настройке
                                    программного
                                    обеспечения,
                                    необходимого для правильного функционирования процесса дистанционного обучения и осуществляется техническими сотрудниками
                                    Института. Также
                                    техническая поддержка организует онлайн – встречи обучающихся с преподавателями (вебинары).
                                </p>
                            </li>
                            <li>
                                <p>
                                    5.11 Обращаем Ваше внимание, что техническая поддержка Института оказывают консультативную помощь в решении технических
                                    проблем
                                    при работе в ЭИОС
                                    ДОТ, и не оказывают помощь в настройке персонального компьютера обучающихся и программ, не имеющих отношения к ДОТ.
                                </p>
                            </li>
                            <li>
                                <p>
                                    5.12 Обращайтесь к специалистам технической поддержки через личные сообщения в системе или по телефону «Горячей линии» 8
                                    (800)
                                    3333776 (доб. 911).
                                </p>
                            </li>
                            <li>
                                <p>
                                    5.13 Сотрудники деканата Института отвечает за всю учебную документацию, оформление справок, перевод обучающихся, оформление
                                    отчисления,
                                    восстановления и оформления академического отпуска. Для связи с инспектором деканата можно написать личное сообщение в ЭИОС
                                    ДОТ
                                    или позвонить по
                                    телефону «Горячей линии» 8 (800) 3333776 или прямому номер: 8 (812) 2351139
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>6. Форма контроля</p>
                        <ol>
                            <li>
                                <p>6.1 Видами итоговой аттестации являются экзамен, зачет и дифференцированный зачет (зачет с оценкой):</p>
                                <ul>
                                    <li>Зачет. Для сдачи зачета необходимо набрать за курс 60 баллов и более (из 100 возможных) по курсу.</li>
                                    <li>
                                        Экзамен. Для сдачи экзамена необходимо набрать за курс 70 баллов и более. Критерии итоговой оценки:
                                        <ol>
                                            <li>• «5 (отлично)» – от 90 – до 100 баллов</li>
                                            <li>• «4 (хорошо)» – от 80 – до 89,9 баллов</li>
                                            <li>• «3 (удовлетворительно)» – от 70 – до 79,9 баллов</li>
                                        </ol>
                                    </li>
                                    <li>
                                        Дифференцированный зачет (зачет с оценкой). Для сдачи зачета с оценкой необходимо набрать не менее 70 баллов за курс.
                                        Критерии итоговой оценки:
                                        <ol>
                                            <li>• «5 (отлично)» – от 90 – до 100 баллов</li>
                                            <li>• «4 (хорошо)» – от 80 – до 89,9 баллов</li>
                                            <li>• «3 (удовлетворительно)» – от 70 – до 79,9 баллов</li>
                                        </ol>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    6.2 Оценка при изучении учебной дисциплины балльная и накопительная.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            7. Элементы оценивания
                        </p>
                        <ol>
                            <li>
                                <p>
                                    7.1 Перед каждым прохождением итогового тестирования и перед каждой загрузкой контрольной работы в ЭИОС ДОТ обучающийся
                                    обязан
                                    пройти идентификацию
                                    личности. Для прохождения идентификации обучающемуся требуется сделать фото, держа документ, удостоверяющий личность
                                    (паспорт
                                    гражданина) у лица.
                                    Без прохождения идентификации личности ЭИОС ДОТ не допускает обучающегося к прохождению тестирования, загрузке контрольной
                                    работы.
                                </p>
                            </li>
                            <li>
                                <p>
                                    7.2 Онлайн-экзамен
                                    Онлайн-экзамен проводится в режиме реального времени и предполагает непосредственное взаимодействие с преподавателем. Для
                                    участия в онлайн-экзамене
                                    требуется использование аудио и видеосвязи. Обучающийся заранее (за 5 минут до начала экзамена) получает билет с вопросом и
                                    отвечает устно.
                                    Преподаватель задаёт дополнительные вопросы. Ответ оценивается в баллах.
                                </p>
                            </li>
                            <li>
                                <p>
                                    7.3 Тестирование
                                    Время прохождения тестирования ограничено и зависит от количества вопросов. В течение семестра для прохождения итогового
                                    тестирования обучающимся
                                    предоставляется 3 (три) попытки. Во время дополнительной сессии обучающимся становится доступна еще 1 (одна) попытка.
                                </p>
                                <ol>
                                    <li>
                                        <p>
                                            7.3.1 Тренировочные (проверочные) тестирования. В ряде электронных курсов присутствуют тренировочные тестирования
                                            для
                                            самопроверки. Тестовые вопросы
                                            представлены в обучающем режиме и являются вопросами для самопроверки усвоения теоретического материала. Время
                                            прохождения тренировочного теста и
                                            количество попыток ограничено.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            7.3.2 Итоговое тестирование. Итоговый тест дисциплины является элементом контроля усвоения теоретического материала.
                                            Доступ к контрольному
                                            тестированию открывается спустя две недели после открытия доступа к учебному курсу в соответствии с календарным
                                            планом.
                                            В итоговом тестировании не
                                            предусмотрена возможность просмотра верных и неверных ответов на вопросы.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            7.3.3 Обращаем внимание, что формулировки вопросов тестирования обучающимся не предоставляются.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p>
                                    7.4 Контрольная работа
                                    Контрольная работа (эссе, реферат, творческое задание, практическое задание, ситуационная задача и др.) загружается на
                                    проверку
                                    в ЭИОС ДОТ
                                    преподавателю один раз. Переписать контрольную работу можно только в случае, если обучающийся не справился с ней в первый
                                    раз, и
                                    контрольная не была
                                    принята преподавателем.
                                </p>
                            </li>
                            <li>
                                <p>
                                    7.5 Контрольные работы (эссе, реферат, творческое задание, практическое задание, ситуационная задача и др.) выполняются
                                    каждым
                                    обучающимся
                                    дистанционной формы обучения в соответствии с календарным планом.
                                </p>
                            </li>
                            <li>
                                <p>7.6 Цели выполнения контрольной работы:</p>
                                <ul>
                                    <li>
                                        - углубить, систематизировать и закрепить теоретические знания;
                                    </li>
                                    <li>
                                        - проверить степень усвоения темы или вопроса;
                                    </li>
                                    <li>
                                        - выработать умения и навыки поиска и отбора необходимой литературы, самостоятельной обработки, обобщения и краткого,
                                        систематизированного изложения
                                        материала.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    7.7 Задания и методика по выполнению контрольных работ составляются преподавателем и размещаются в дисциплине.
                                </p>
                            </li>
                            <li>
                                <p>
                                    7.8 Контрольная работа должна быть структурно четко выстроена, демонстрировать логическую последовательность излагаемого
                                    материала, краткость и
                                    четкость формулировок. Работа должна отражать собственное понимание обучающимся существа вопроса, способность самостоятельно
                                    использовать
                                    литературные источники, умение связывать теоретические положения с их практическим применением, формулировать и обосновывать
                                    выводы.
                                </p>
                            </li>
                            <li>
                                <p>
                                    7.9 Доступ к выполнению контрольной работы открывается спустя две недели после открытия доступа к учебному курсу, который
                                    определен календарным
                                    планом.
                                </p>
                            </li>
                            <li>
                                <p>
                                    7.10 Контрольная работа должна быть выполнена в сроки изучения дисциплины, прописанные в календарном плане.
                                </p>
                            </li>
                            <li>
                                <p>
                                    7.11 Каждая контрольная работа проверяется на плагиат. В случае обнаружения плагиата, работа преподавателем не принимается.
                                    В случае обнаружения во время проверки двух и/или более письменных работ, 
                                    имеющих схожее или идентичное содержание (плагиат), преподаватель оставляет за собой право 
                                    аннулировать баллы всех письменных работ, включая те, где ранее уже был выставлен балл в зачетку студента.
                                </p>
                            </li>
                            <li>
                                <p>
                                    7.12 Ответ преподавателя на контрольную работу предоставляется в течение 14 календарных дней с момента размещения в ЭИОС
                                    ДОТ.
                                </p>
                            </li>
                            <li>
                                <p>
                                    7.13 Рекомендации по оформлению контрольной работы: контрольная работа представляется в машинописном виде; размер шрифта 14,
                                    Times New Roman; сноски
                                    печатаются 10 шрифтом. В учебных курсах также могут быть указаны дополнительные требования к оформлению работы (отчеты по
                                    практике и др.) Объем
                                    контрольной работы определяется преподавателем и прописывается в задании. Контрольная работа загружается в ЭИОС ДОТ
                                    исключительно в формате .pdf.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            8. Академические задолженности
                        </p>
                        <ol>
                            <li>
                                <p>
                                    8.1 Обучающийся, без уважительной причины не набравший минимального количества баллов по завершении учебного семестра
                                    (модуля),
                                    считается имеющим
                                    академическую задолженность. Академические задолженности по учебным дисциплинам обучающийся может ликвидировать в период
                                    дополнительной сессии
                                    (повторно пройти промежуточную аттестацию). В случае если обучающийся не сдал промежуточную аттестацию по дисциплине,
                                    назначается аттестация с
                                    участием комиссии. Отрицательные результаты сдачи аттестации с участием комиссии влекут за собой отчисление обучающегося из
                                    Института.
                                </p>
                            </li>
                            <li>
                                <p>
                                    8.2 Повысить оценку по промежуточной аттестации возможно только при освоении основных профессиональных образовательных
                                    программ
                                    на последнем году
                                    обучения, при условии, что обучающийся претендует на диплом с отличием, путем пересдачи промежуточной аттестации (экзамен,
                                    дифференцированный
                                    зачет), но не более чем по трём дисциплинам.
                                </p>
                            </li>
                            <li>
                                <p>
                                    8.3 Обучающиеся, не ликвидировавшие академические задолженности текущего учебного года, не допускаются: к учебным материалам
                                    следующего учебного
                                    года; к сдаче экзаменов (зачетов, дифференцированных зачетов) и защите выпускной квалификационной работы (магистерской
                                    диссертации), и отчисляются
                                    по неуспеваемости приказом ректора Института.
                                </p>
                            </li>
                            <li>
                                <p>
                                    8.4 Обучающиеся, отчисленные за неуспеваемость, могут восстановиться или продолжить обучение повторно по решению ректора
                                    Института. Восстановление
                                    на тот же курс возможно только в течение пяти лет после отчисления.
                                </p>
                            </li>
                            <li>
                                <p>
                                    8.5 На основе действующего законодательства обучающийся имеет право на академический отпуск. Академический отпуск
                                    предоставляется обучающемуся в
                                    связи с невозможностью освоения образовательной программы по медицинским показаниям, семейным и иным обстоятельствам на
                                    период
                                    времени, не
                                    превышающий двух лет.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            9. Обучающие вебинары
                        </p>
                        <ol>
                            <li>
                                <p>
                                    9.1 Вебинар – это семинар, проводимый онлайн средствами через Интернет в режиме реального времени. Каждый вебинар посвящен
                                    определенной теме и
                                    предназначен для повышения подготовленности, умений и навыков его участников.
                                </p>
                            </li>
                            <li>
                                <p>
                                    9.2 Вебинары бывают различного формата: открытые, закрытые, вебинары – лекции, вебинары – дайджесты и пр.
                                </p>
                            </li>
                            <li>
                                <p>
                                    9.3 Расписание вебинаров размещается в разделе «Мероприятия» для каждой группы обучающихся. Об отмене вебинара также
                                    сообщается
                                    обучающимся.
                                </p>
                            </li>
                            <li>
                                <p>
                                    9.4 Продолжительность вебинара обычно составляет 1,5 часа. Начало – в 19:00 по МСК времени. Вебинары проводятся по будним
                                    дням.
                                    Отмена вебинара
                                    может состояться по причине болезни преподавателя, а также ввиду технических проблем.
                                </p>
                            </li>
                            <li>
                                <p>
                                    9.5 Для участия в вебинаре обучающемуся рекомендуется использовать гарнитуру закрытого типа (микрофон и наушники).
                                    Предварительно необходимо
                                    проверить стабильность Интернет-соединения. Перед первым участием в вебинаре обучающемуся необходимо внимательно
                                    ознакомиться с
                                    правила проведения и
                                    проверить техническое оснащение.
                                </p>
                            </li>
                            <li>
                                <p>
                                    9.6 В случае возникновения каких-либо затруднений или вопросов заблаговременно обратится к сотрудникам технической поддержки
                                    или
                                    ответственному
                                    методисту. Обращаем внимание: все организационные и технические вопросы следует решать минимум за 2-3 часа до начала
                                    вебинара!
                                </p>
                            </li>
                            <li>
                                <p>
                                    9.7 Если все предварительные проверки были произведены, но у обучающегося возникли затруднения при подключении к вебинару
                                    непосредственно перед его
                                    началом или в процессе, то следует позвонить по телефону «Горячей линии» 8(800) 3333776 (добавочный 911) – в отдел
                                    технической
                                    поддержки.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            10. Оплата
                        </p>
                        <ol>
                            <li>
                                <p>
                                    10.1 Оплата обучения производится в соответствии с приказом о стоимости оплаты обучения. Оплата вносится через любое
                                    отделение
                                    банка (рекомендуем
                                    оплату через Сбербанк), или онлайн-банк.
                                </p>
                            </li>
                            <li>
                                <p>
                                    10.2 Оплата может производиться по семестрам или в рассрочку:
                                </p>
                                <ul>
                                    <li>
                                        - В случае оплаты по семестрам – следует вносить оплату до 1 сентября и до 1 февраля;
                                    </li>
                                    <li>
                                        - В случае рассрочки – платёж производится в соответствии с графиком платежей, который прописан в Дополнительном
                                        соглашении
                                        (сумма оплаты обучения
                                        за один учебный год делится на 8 равных частей).
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    10.3 В случае возникновения каких-либо вопросов по оплате обучения, а также для обучающихся, оплачивающих обучение за
                                    пределами
                                    РФ, следует
                                    обращаться в бухгалтерию Института: 8 (812) 2355810, 8 (812) 4493041, 8 (800) 3333776 (доб. 2-3) или написать на e-mail:
                                    olga.ryabenko@eeip.ru
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            11. Доступ к ЭИОС ДОТ
                        </p>
                        <ol>
                            <li>
                                <p>
                                    11.1 Доступ к ЭИОС ДОТ предоставляется обучающимся на протяжении всего периода обучения. После окончания обучения и
                                    получения
                                    документа о его
                                    завершении, доступ к электронным курсам закрывается, учетная запись обучающегося блокируется.
                                </p>
                            </li>
                            <li>
                                <p>
                                    11.2 Все материалы, размещенные в ЭИОС ДОТ, являются собственностью института. Незаконное овладение информацией, ее
                                    копирование,
                                    распространение, а
                                    также нарушение авторских и других смежных прав является административным и уголовным правонарушением, за которое
                                    предусмотрены
                                    соответствующие
                                    наказания.
                                </p>
                            </li>
                            <li>
                                <p>
                                    11.3 Администрация института оставляет за собой право удалять из чатов оскорбительные, содержащие ненормативную лексику,
                                    жаргонные выражения, не
                                    относящиеся содержательно к учебному процессу, оскорбляющие честь и достоинство Института и его сотрудников. Институт
                                    оставляет
                                    за собой право
                                    ограничить доступ к чатам обучающихся, которые не выполняют данные правила, а также применять к ним другие дисциплинарные
                                    меры,
                                    определенные пунктом
                                    6.6 Устава института.
                                </p>
                            </li>
                            <li>
                                <p>
                                    11.4 По всем возникающим вопросам обращайтесь в отдел Дистанционного обучения ВЕИП:
                                </p>
                                <ul>
                                    <li>
                                        - Центральная почта отдела ДО: do@eeip.ru
                                    </li>
                                    <li>
                                        - Центральная почта технического отдела: support@eeip.ru
                                    </li>
                                    <li>
                                        - Телефон «Горячей линии» 8 (800) 3333776 (звонок бесплатный на территории РФ)
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                </ul>
                <p className={style.rejection_text}>Отказ от ответственности</p>
                <p>
                    Любая информация, размещенная в открытых разделах на веб-сайте https://eeip.online/, предназначена для свободного изучения пользователями
                    сайта.
                    Доступ в систему обучения (закрытые разделы Сайта) получают пользователи, заключившие договор на обучение, предоставившие все необходимые
                    документы
                    для зачисления на выбранную образовательную программу и оплатившие обучение.
                </p>
                <p>
                    Администрация сайта прилагает все усилия для того, чтобы представить на сайте достоверную и полезную информацию, которая отвечает на вопросы
                    пользователей, дает знания, развитие навыков и умений по избранной образовательной программе. Но при этом мы не исключаем возможности
                    наличия
                    ошибок
                    (опечаток) на сайте.
                </p>
                <p>
                    Ни при каких обстоятельствах Администрация ВЕИП и администрация сайта Дистанционного обучения ВЕИП (https://eeip.online/) не несет
                    ответственности
                    за то, что человек, оплативший обучение, обязательно получит положительную оценку за контрольную работу, экзамен или получит зачет, отлично
                    сдаст
                    сессию, будет переведен на следующий курс и в итоге получит документ об окончании обучения.
                </p>
                <p>
                    Ни при каких обстоятельствах Администрация ВЕИП и администрация сайта Дистанционного обучения ВЕИП (https://eeip.online/) не несет
                    ответственности
                    за то, что человек, который прошел обучение по образовательным программам, добьется таких же результатов, как и другие люди, обучающиеся на
                    подобных
                    курсах, программах и тренингах;
                </p>
                <p>
                    Ни при каких обстоятельствах Администрация ВЕИП и администрация сайта Дистанционного обучения ВЕИП (https://eeip.online/) не несет
                    ответственности
                    за то, что человек, который закончил обучение по образовательной программе и получил документ об окончании ВЕИП, сохраняет доступ ко всем
                    материалам
                    обучения. Доступ может быть предоставлен лишь на усмотрение администрации.
                </p>
                <p>
                    Ни при каких обстоятельствах Администрация ВЕИП и администрация сайта Дистанционного обучения ВЕИП (https://eeip.online/) не несет
                    ответственности
                    за удовлетворение требований человека, который оплатил обучение, получил доступ в систему обучения и посчитал необходимым обратиться с
                    требованием
                    разработать специально под его пожелания какой-либо дистанционный курс по выбранной теме. Учебный план образовательных программ основан на
                    соответствии Федеральному образовательному стандарту, а не индивидуальным пожеланиям обучающегося.
                </p>
                <p>
                    Заключая договор, оплачивая обучение и получая доступ в ЭИОС ДОТ, обучающийся обязуется самостоятельно выполнять контрольные работы, сдавать
                    зачеты,
                    а не прибегать к бесплатной и/или платной помощи третьих лиц. Мы не несем ответственности за результативность подобного подхода к обучению и
                    в
                    случае установления факта нарушения не несем ответственности за сохранение доступа в систему обучения.
                </p>
                <p>
                    Ни при каких обстоятельствах Администрация ВЕИП и администрация сайта Дистанционного обучения ВЕИП (https://eeip.online/) не несет
                    ответственности
                    за все отзывы (хорошие или плохие), которые оставляют люди в Интернете на разных ресурсах о Сайте и программах обучения, которые на нем
                    расположены.
                </p>
                <p>
                    На этом сайте, как и на любом другом, могут находиться ссылки на сторонние сайты. Нахождение таких ссылок на нашем сайте носит справочный
                    характер.
                    Это не значит, что мы согласны или не согласны в той или иной степени с содержанием сторонних сайтов. Мы ни в коем случае не несем
                    ответственности
                    за содержание этих сайтов и за изменения, которые на них происходят. Также мы не несем ответственности за причиненный ущерб, который мог
                    получить
                    пользователь за посещение сторонних сайтов.
                </p>
                <p>
                    Ни при каких обстоятельствах Администрация Сайта не несет ответственности за какой-либо прямой, непрямой, особый или иной косвенный ущерб в
                    результате использования информации на этом Сайте или на любом другом сайте, на который имеется гиперссылка с нашего cайта, возникновение
                    зависимости, снижения продуктивности, увольнения или прерывания трудовой активности, а равно и отчисления из учебных учреждений, за любую
                    упущенную
                    выгоду, приостановку хозяйственной деятельности, потерю программ или данных в Ваших информационных системах или иным образом, возникшие в
                    связи
                    с
                    доступом, использованием или невозможностью использования Сайта, Содержимого или какого-либо связанного Интернет-сайта, или
                    неработоспособностью,
                    ошибкой, упущением, перебоем, дефектом, простоем в работе или задержкой в передаче, компьютерным вирусом или системным сбоем, даже если
                    администрация будет явно поставлена в известность о возможности такого ущерба.
                </p>
                <p>
                    Используя данный Сайт https://eeip.online/, Вы выражаете свое согласие с «Отказом от ответственности» и установленными Правилами,
                    зафиксированными в
                    правовых документах, расположенных на сайте, и принимаете всю ответственность, которая может быть на Вас возложена.

                </p>
                <p>
                    Администрация <a href="https://eeip.online">https://eeip.online</a>
                </p>
                <p>
                    Центр Дистанционного Обучения Восточно-Европейского Института психоанализа
                </p>
            </div>
        </Main>
    );
};

export default TrainingRulesPage;
