import { useNavigate } from 'react-router-dom';

import { ReactComponent as NotFoundSVGSm } from '@assets/image/not-found-picture-sm.svg';
import { ReactComponent as NotFoundSVG } from '@assets/image/not-found-picture.svg';
import Button from '@components/core/inputs/Button';
import Main from '@components/Main';
import { useBreakpoint } from '@hooks/useBreakpoint';

import style from './NotFound.module.scss';

const NotFound = () => {
    const smDown = useBreakpoint('sm', 'down');
    const navigate = useNavigate();

    return (
        <Main className={style.main}>
            {!smDown ? <NotFoundSVG className={style.picture} /> : <NotFoundSVGSm className={style.picture} />}
            <p className={style.text}>Страница, которую вы ищете, не найдена.
                    Пожалуйста, вернитесь на главную.</p>
            <Button onClick={() => navigate('/')} className={style.button}>На главную</Button>
        </Main>
    );
};

export default NotFound;
