import { FC } from 'react';

import Button from '@components/core/inputs/Button';
import Modal from '@components/Modal';

import style from './DuplicateEventPopup.module.scss';

interface IDeleteItemModal {
    isOpen: boolean,
    onClose: () => void
    onDuplicate: () => void
    itemName?: string
}

const DuplicateEventPopup: FC<IDeleteItemModal> = ({ isOpen, onClose, onDuplicate }) => {

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title="Дублировать мероприятие?"
        >
            <p className={style.modal__text}>
                При дублировании будет создана копия данного мероприятия
            </p>
            <div className={style.button_wrapper}>
                <Button
                    onClick={onClose}
                    btnType={'secondary'}
                >
                    Отмена
                </Button>
                <Button
                    onClick={onDuplicate}
                    btnType={'success'}
                >
                    Дублировать
                </Button>
            </div>
        </Modal>
    );
};

export default DuplicateEventPopup;
