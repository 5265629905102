import { useCallback, useState } from 'react';

const useThrottle = <T extends (...args: any[]) => any>(callback: T, delay: number): T => {
    const [isThrottled, setIsThrottled] = useState(false);

    const throttledCallback = useCallback(
        async (...args: Parameters<T>) => {
            if (isThrottled) return;

            setIsThrottled(true);
            await callback(...args);
            setTimeout(() => {
                setIsThrottled(false);
            }, delay);
        },
        [callback, delay, isThrottled]
    );

    return throttledCallback as T;
};

export default useThrottle;
