import { FC, useMemo, useState } from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import { DNDWrapper } from '@components/core/DNDWrapper';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { checkRole } from '@components/RoleCheck';
import { EnumMaterialsType, Materials, MaterialsItem, PositionsItemsList, UpdateFileData, UpdateLinkData } from '@store/api/apiTypes';
import { Role } from '@store/Roles';
import EditFileModal from '@views/methodist/Materials/Course/EditFileModal';
import EditLinkModal from '@views/methodist/Materials/Course/EditLinkModal';

import style from './list.module.scss';
import { ListItem } from './ListItem';

interface IListProp {
    materials: Materials,
    disciplineUuid?: string //for tests
    deleteMaterial: (materialUuid: string, sectionUuid: string) => void
    deleteVideoBlock: (materialUuid: string) => void
    deleteLectureBlock: (materialUuid: string) => void
    updateLink: (linkData: UpdateLinkData) => void
    updateFile: (formData: UpdateFileData) => void
    isDraggable?: boolean
    onOrder?: (reorderSectionsList: PositionsItemsList) => void
}

export const List: FC<IListProp> = ({ 
    materials, 
    disciplineUuid, 
    deleteMaterial, 
    deleteVideoBlock, 
    deleteLectureBlock, 
    updateLink,
    updateFile,
    isDraggable = false,
    onOrder
}) => {
    const navigate = useNavigate();

    const [isOpenEditLinkModal, setIsOpenEditLinkModal] = useState<boolean>(false);
    const [materialsData, setMaterialsData] = useState<MaterialsItem | any>();

    const [isOpenEditFileModal, setIsOpenEditFileModal] = useState<boolean>(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentMaterial, setCurrentMaterial] = useState<MaterialsItem>();

    // TODO: shouldn't backend return already sorted sections?
    const orderedItems = useMemo(() => {
        const sections = materials ? [...materials] : [];
        // @ts-ignore:next-line
        return sections.sort((a, b) => a.position && b.position ? a.position - b.position : 0);
    }, [materials]);

    const editMaterialHandler = (material: MaterialsItem) => {
        if (material.type === EnumMaterialsType.File) {
            setIsOpenEditFileModal(true);
            setMaterialsData(material);
            return;
        }

        // TODO: Remove all condiiton block
        if (material.type.toLowerCase() === EnumMaterialsType.File.toLowerCase()) {
            setIsOpenEditFileModal(true);
            setMaterialsData(material);
            return;
        }

        if (material.type === EnumMaterialsType.Task) {
            navigate(`${material.uuidParent}/edit-task/${material.uuid}`);
            return;
        }

        if (material.type === EnumMaterialsType.Video) {
            navigate(`${material.uuidParent}/video-lectures/${material.uuid}`);
            return;
        }

        if (material.type === EnumMaterialsType.Lecture) {
            navigate(`${material.uuidParent}/lectures-block/${material.uuid}`);
            return;
        }

        if (material.type === EnumMaterialsType.Test) {
            navigate(`${material.sectionUuid}/edit-test/${material.uuid}`);
            return;
        }

        
        if (material.type === EnumMaterialsType.Page) {
            navigate(`${material.uuidParent || material.uuidSection}/edit-page/${material.uuid}`);
            return;
        }

        // TODO: Remove all condiiton block
        if (material.type.toLowerCase() === EnumMaterialsType.Page.toLowerCase()) {
            navigate(`${material.uuidParent || material.uuidSection}/edit-page/${material.uuid}`);
            return;
        }

        // TODO: Remove text transform
        if (material.type.toLowerCase() === EnumMaterialsType.Link.toLowerCase()) {
            setIsOpenEditLinkModal(true);
            setMaterialsData({ 
                name: material.name, 
                link: material.link, 
                uuid: material.uuid, 
                position: material.position, 
                uuidSection: material.uuidSection 
            });
            return;
        }
    };

    const deleteMaterialHandler = async (material: MaterialsItem) => {
        if (material.type === EnumMaterialsType.Video) {
            deleteVideoBlock(material.uuid);
            setShowDeleteModal(false);
            return;
        }

        if (material.type === EnumMaterialsType.Lecture) {
            deleteLectureBlock(material.uuid);
            setShowDeleteModal(false);
            return;
        }

        if (material.type === EnumMaterialsType.Test && disciplineUuid) {
            deleteMaterial(material.uuid, disciplineUuid);
            setShowDeleteModal(false);
            return;
        }

        if (material.type !== EnumMaterialsType.Test && material.uuidParent) {
            deleteMaterial(material.uuid, material.uuidParent);
            setShowDeleteModal(false);
            return;
        }

        deleteMaterial(material.uuid, '');
        setShowDeleteModal(false);
    };

    return (
        <>
            <div className={style.container}>
                {
                    isDraggable ?
                        // @ts-ignore:next-line
                        <DNDWrapper items={orderedItems} onOrder={onOrder}>
                            {orderedItems.map(material => {

                                // Check file extension
                                const ext = material.type == 'File' ? material.file?.extension : undefined;

                                return <ListItem
                                    key={material.uuid}
                                    name={material.name}
                                    type={material.type}
                                    extension={ext}
                                    onEdit={() => editMaterialHandler(material)}
                                    onDelete={() => {
                                        setShowDeleteModal(true);
                                        setCurrentMaterial(material);
                                    }}
                                    noEditable={!checkRole(Role.Methodist)}
                                    className={cx({ [style.draggble_item]: isDraggable })}
                                />;
                            })}
                        </DNDWrapper>
                        :
                        materials.map(material => {

                            // Check file extension
                            const ext = material.type == 'File' ? material.file?.extension : undefined;

                            return <ListItem
                                key={material.uuid}
                                name={material.name}
                                type={material.type}
                                extension={ext}
                                onEdit={() => editMaterialHandler(material)}
                                onDelete={() => {
                                    setShowDeleteModal(true);
                                    setCurrentMaterial(material);
                                }}
                                noEditable={!checkRole(Role.Methodist)}
                            />;
                        })
                }
            </div>
            {
                isOpenEditLinkModal && (
                    <EditLinkModal
                        isOpen={isOpenEditLinkModal}
                        onClose={setIsOpenEditLinkModal}
                        linkData={materialsData}
                        updateLink={updateLink}
                    />
                )
            }
            {
                isOpenEditFileModal && (
                    <EditFileModal
                        isOpen={isOpenEditFileModal}
                        onClose={setIsOpenEditFileModal}
                        materialFile={materialsData}
                        updateFile={updateFile}
                    />
                )
            }
            {
                showDeleteModal && <DeleteItemModal
                    isOpen={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    onDelete={() => deleteMaterialHandler(currentMaterial as MaterialsItem) }
                    itemName={currentMaterial?.name as string}
                    itemType="material"
                />
            }
        </>

    );
};

