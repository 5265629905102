import { CSSProperties, FC, useEffect, useState } from 'react';

import cx from 'classnames';
import Select, { ActionMeta, MultiValue, PropsValue, SingleValue } from 'react-select';

import { CustomDropdownIndicator, CustomValueContainer } from '@components/core/inputs/SelectStyled/customComponents';
import MultiValueContainer from '@components/core/inputs/SelectStyled/MultiValueContainer/MultiValueContainer';
import { themeLight, themeDark } from '@components/core/inputs/SelectStyled/style';
import { OptionType } from '@components/core/inputs/SelectStyled/type';

import style from './SelectBase.module.scss';

export type SelectBaseType = {
    options: OptionType[];
    name: string;
    isMulti?: boolean;
    className?: string;
    placeholder?: string;
    isClearable?: boolean;
    isSearchable?: boolean;
    styled?: CSSProperties;
    defaultValue?: PropsValue<OptionType>;
    onChange: (value: any) => void;
    theme: 'light' | 'dark';
    value?: OptionType;
    isDisabled?: boolean;
};

const SelectBase: FC<SelectBaseType> = (
    {
        className,
        options,
        isMulti,
        placeholder,
        isClearable,
        isSearchable,
        styled,
        defaultValue,
        theme,
        onChange,
        isDisabled = false,
        ...props
    }) => {

    const [selectedOption, setSelectedOption] = useState<MultiValue<OptionType> | SingleValue<OptionType>>([]);

    const [localValue, setLocalValue] = useState(defaultValue);

    const deleteOptionValueHandle = (indexElem: number) => {
        setSelectedOption((state) => {
            return [
                // @ts-ignore
                ...state.filter(
                    // @ts-ignore
                    (item, index) => index !== indexElem
                )
            ];
        });
    };

    useEffect(() => {
        if (isMulti) {
            onChange(selectedOption);
        }
    }, [selectedOption]);

    useEffect(() => {
        setLocalValue(defaultValue);
    }, [defaultValue]);

    const getTheme = () => {
        if (theme === 'light') {
            return themeDark;
        }

        return themeLight;
    };

    return (
        <div
            className={cx(style.select_wrapper, className)}
            style={styled}
        >
            <Select
                value={localValue}
                options={options}
                styles={getTheme()}
                components={{
                    ValueContainer: CustomValueContainer,
                    DropdownIndicator: CustomDropdownIndicator
                }}
                onChange={(
                    newValue:
                    | MultiValue<OptionType>
                    | SingleValue<OptionType>,
                    actionMeta: ActionMeta<OptionType>
                ) => {
                    isMulti ? setSelectedOption(newValue) : onChange(newValue);
                    setLocalValue(newValue);
                }}
                isMulti={isMulti}
                placeholder={placeholder}
                isClearable={isClearable}
                isSearchable={isSearchable}
                isDisabled={isDisabled}
                {...props}
            />
            {isMulti && (
                <MultiValueContainer
                    selectedOption={selectedOption}
                    selectedOptionHandle={deleteOptionValueHandle}
                />
            )}
        </div>
    );
};

export default SelectBase;
