import AdditionalMaterial from '@views/student/StudentCourse/Materials/AdditionalMaterial';
import Lecture from '@views/student/StudentCourse/Materials/Lecture';
import Task from '@views/student/StudentCourse/Materials/Task';
import { IAdditionalMaterial, ILecture, ITask, ITest, IVideoLectures } from '@views/student/StudentCourse/Materials/type';
import VideoLectures from '@views/student/StudentCourse/Materials/VideoLectures';

import Test from './Test/Test';

const Materials = {
    Lecture: ({ lecture }: ILecture) => <Lecture lecture={lecture} />,
    VideoLectures: ({ videosSection }: IVideoLectures) => <VideoLectures videosSection={videosSection} />,
    AdditionalMaterial: (props: IAdditionalMaterial) => <AdditionalMaterial {...props} />,
    Task: ({ task }: ITask) => <Task task={task} />,
    Test: ({ test }: ITest) => <Test test={test} />
};

export default Materials;

