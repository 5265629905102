import React, { FC } from 'react';

import cx from 'classnames';

import style from './DataListBody.module.scss';

export interface IDataListBodyProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    children?: React.ReactNode;
    scrolled?: boolean;

    gridTemplateColumns?: string;
}

export const DataListBody: FC<IDataListBodyProps> = ({ children, className, scrolled, ...props }) => {

    return <div className={cx(style.body, scrolled && style.scrolled, className)}
        style={{ ...props.style }}
        {...props}>
        {children}
    </div>;
};
