import { FC, useEffect } from 'react';

import IMask from 'imask';
import { useIMask } from 'react-imask';

import { ReactComponent as ClockIcon } from '@assets/icons/clock-icon.svg';

import style from './DPTime.module.scss';

export interface IDPTime {
    onTimeAccepted: (value: string) => void;
    time?: string;
}

const timeRegExp = /^([01]?\d|2[0-3]):[0-5]\d$/g;

export const DPTime:FC<IDPTime> = ({ onTimeAccepted, time = '' }) => {

    const { ref, value, setValue } = useIMask({
        overwrite: true,
        autofix: true,
        mask: 'HH:MM',
        blocks: {
            HH: {
                mask: IMask.MaskedRange,
                placeholderChar: 'HH',
                from: 0,
                to: 23,
                maxLength: 2
            },
            MM: {
                mask: IMask.MaskedRange,
                placeholderChar: 'MM',
                from: 0,
                to: 59,
                maxLength: 2
            }
        }
    });

    const onChangeWrapper = () => {
        if( Array.isArray( value.match(timeRegExp)) ) {
            onTimeAccepted(value);
        } else {
            onTimeAccepted('');
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        onChangeWrapper();
    }, [value]);


    useEffect(() => {
        setValue(time);
    }, []);

    return <div className={style.container}>
        <div className={style.icon}>
            <ClockIcon />
        </div>

        {
            <input
                defaultValue={time}
                ref={ref}
                style={{
                    width: 60,
                    height: 34
                }}
                onChange={handleChange}
                className={style.input}
                name='time-picker'
            />
        }
    </div>;
};
