import React, { ReactNode } from 'react';

import clsx from 'classnames';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-right-small.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg';
import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import style from './CommonSidebar.module.scss';

export function CommonSidebar({ title, icon, on, off, isOpen, containerClassName, children }: {
    title: string,
    icon: ReactNode,
    on: () => void;
    off: () => void;
    isOpen: boolean;
    containerClassName?: string,
    children: ReactNode
}) {
    return (
        <>
            <Button onClick={on} btnType="outline" className={style.button}>
                <span>
                    {icon}
                    {title}
                </span>
                <ArrowIcon />
            </Button>
            <SwipeableDrawer anchor="right" open={isOpen} onOpen={on} onClose={off} disableSwipeToOpen>
                <div className={clsx(style.container, containerClassName)}>
                    <h3>{title}</h3>

                    <ButtonIcon className={style.close} onClick={off}>
                        <CloseIcon />
                    </ButtonIcon>

                    <div className={style.content}>
                        {children}
                    </div>
                </div>

            </SwipeableDrawer>
        </>
    );
}
