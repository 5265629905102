import { useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import { OptionType, StringOptionType } from '@components/core/inputs/SelectStyled/type';
import { DPRangeControl } from '@components/DatePicker/DPRangeControl';
import { IRangeDates } from '@components/DatePicker/DPRangeControl/DPRangeControl';
import { Helmet } from '@components/Helmet';
import ExportFileModal from '@components/methodist/ExportFileModal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToggle } from '@hooks/useToggle.hook';
import { useGetTeacherReportsExportFileMutation } from '@store/api/reportsApi';
import { useGetUsersByRoleQuery } from '@store/api/usersApi';
import { Role } from '@store/Roles';
import { User } from '@type/users';

import style from './TeacherReports.module.scss';
import TeacherReportsList from './TeacherReportsList';


const teacherSchema = yup.object({
    teacher: yup.object({ label: yup.string().default(''), value: yup.string().default('') })
});

export type AddTeacherForm = yup.InferType<typeof teacherSchema>;

const TeacherReports = () => {
    const { teachers } = useGetUsersByRoleQuery(Role.Teacher, {
        selectFromResult: ({ data }) => ({
            teachers: data ?? []
        })
    });
    const [getXlsFile, { isSuccess }] = useGetTeacherReportsExportFileMutation();

    type IRequest = ReturnType<typeof getXlsFile>;

    const [teacher, setTeacher] = useState<string>();
    const [beginDate, setBeginDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [isErr, setIsErr] = useState(false);
    const [isOpen, { on, off }] = useToggle();
    const [exportRequest, setExportRequest] = useState<IRequest>();

    const startExport = () => {
        if (teacher) {
            on();

            setExportRequest(getXlsFile({
                uuidTeacher: teacher === 'all' ? undefined : teacher,
                beginDate: beginDate,
                endDate: endDate
            }));
        }
    };

    const getXLSFile = async () => {
        if (exportRequest) {
            try {
                const file = await exportRequest.unwrap();
                window.open(file.path);
            } catch (error) {
                setIsErr(true);
            }
        }
    };

    const stopExport = () => {
        if (exportRequest) {
            exportRequest.abort();
        }
    };

    useEffect(() => {
        getXLSFile();
    }, [exportRequest]);

    const methods = useForm<AddTeacherForm>({
        resolver: yupResolver(teacherSchema),
        defaultValues: {
            teacher: {
                label: 'Все преподаватели',
                value: 'all',
                picture: ''
            } as StringOptionType
        }
    });

    const { control, watch } = methods;

    const watchTeacher = watch('teacher');

    const onDateSubmit = (rangeDatesToSubmit: IRangeDates) => {
        setBeginDate(rangeDatesToSubmit.startDate);
        setEndDate(rangeDatesToSubmit.endDate);
    };

    const filterOptions = useMemo(() => {
        const options: OptionType[] = teachers?.map((user: User) => (
            {
                label: `${user.lastName} ${user.firstName} ${user.patronymic}`,
                value: user.uuid,
                picture: user.picture?.path || ''
            }
        ));
        options.unshift({
            label: 'Все преподаватели',
            value: 'all',
            picture: ''
        });
        return options;
    }, [teachers]);

    useEffect(() => {
        setTeacher(watchTeacher?.value || '');
    }, [watchTeacher]);

    return (
        <>
            <Helmet title="Отчеты – отчеты по преподавателям" />
            <h2 className={style.title}>
                Отчет по преподавателям
            </h2>
            <div className={style.controls_container}>
                <div className={style.form}>
                    <FormProvider {...methods}>
                        <SelectStyled
                            className={style.teacher_filter}
                            control={control}
                            name="teacher"
                            options={filterOptions}
                            isMulti={false}
                            placeholder="Выбрать преподавателя"
                            // isAvatarOnItem
                        />
                        <DPRangeControl
                            onSubmit={onDateSubmit}
                            variant="date"
                            isButton={false}
                        />
                    </FormProvider>
                </div>

                <Button size="small" disabled={!teacher || !beginDate || !endDate} onClick={startExport}>
                    Экспорт в .xls
                </Button>
            </div>
            <TeacherReportsList teacher={teacher || ''} beginDate={beginDate} endDate={endDate} />
            <ExportFileModal
                isOpen={isOpen}
                isErr={isErr}
                isSuccess={isSuccess}
                onClose={off}
                itemName="отчета"
                stopExport={stopExport}
            />
        </>
    );
};

export default TeacherReports;
