import { useEffect, useState, FC } from 'react';

import cx from 'classnames';

import Button from '@components/core/inputs/Button';
import Modal from '@components/Modal';
import SwipeDrawer from '@components/SwipeDrawer';
import { useBreakpoint } from '@hooks/useBreakpoint';

import style from './StartTestModal.module.scss';

type IStartTestModal = {
    isOpen: boolean;
    closeModal: () => void;
    openModal: () => void;
    onSubmit: () => void;
    testTime: number;
}

const StartTestModal: FC<IStartTestModal> = ({ isOpen, closeModal, openModal, onSubmit, testTime }) => {
    const smUp = useBreakpoint('sm', 'up');

    return (
        <>
            {
                smUp ?
                    <Modal
                        onClose={closeModal}
                        isOpen={isOpen}
                        title="Начать тестирование?"
                    >
                        <p>
                            Время на попытку {testTime} минут.
                            <br />
                            Вы уверены, что хотите начать тестирование?
                        </p>

                        <div className={ cx(style.button_wrapper, style.button_wrapper_end) }>
                            <Button
                                onClick={closeModal}
                                btnType="secondary"
                            >
                                Отмена
                            </Button>
                            <Button onClick={() => {
                                closeModal();
                                onSubmit();
                            }}>
                                Начать тестирование
                            </Button>
                        </div>
                    </Modal>

                    :

                    <SwipeDrawer
                        isOpen={isOpen}
                        onClose={closeModal}
                        onOpen={openModal}
                    >
                        <h3 className={style.drawer_title}>Начать тестирование?</h3>
                        <p>
                            Время на попытку {testTime} минут.
                            <br />
                            Вы уверены, что хотите начать тестирование?
                        </p>
                        <div className={style.button_wrapper}>
                            <Button
                                onClick={() => {
                                    closeModal();
                                    onSubmit();
                                }}
                                fullWidth={true}
                            >
                                Начать тестирование
                            </Button>
                        </div>
                    </SwipeDrawer>
            }
        </>
    );
};

export default StartTestModal;
