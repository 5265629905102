import React, { forwardRef, ForwardRefRenderFunction, useEffect, useMemo } from 'react';

import SelectSearch from '@components/core/inputs/Select/SelectSearch';
import useOnScreen from '@hooks/useOnScreen';
import { useSearch } from '@hooks/useSearch';
import { useToggle } from '@hooks/useToggle.hook';

import { OptionsList } from './parts/OptionsList';
import { SelectRoot } from './parts/SelectRoot';

export interface RenderValue {
    value: string;
    label: string;
}

export interface Option {
    value: string;
    label: string
}

export interface Classes {
    input?: string;
    inputIcon?: string;
}

export interface NewSelectBaseProps {
    className?: string;
    options: Option[];
    classes?: Classes;
    error?: string;
    placeholder?: string;
    disabled?: boolean;
    isMulti?: boolean;
    dropdownWidth?: number;
    loadMore?: () => void;
    onSearch?: (query: string) => void;
    selectName?: string;
}

export interface SingleSelectProps extends NewSelectBaseProps {
    isMulti?: false;
    value: string;
    onChange: (value: string) => void;
    renderLabel?: (value: RenderValue) => React.ReactNode;
}

export interface MultiSelectProps extends NewSelectBaseProps {
    isMulti: true;
    value: string[];
    onChange: (value: string[]) => void;
    renderLabel?: (value: RenderValue[]) => React.ReactNode;
}

export type NewSelectProps = SingleSelectProps | MultiSelectProps;

const NewSelect: ForwardRefRenderFunction<HTMLDivElement, NewSelectProps>  = ({
    className,
    options,
    classes,
    value,
    error,
    placeholder,
    renderLabel,
    disabled,
    isMulti,
    onChange,
    dropdownWidth,
    selectName = '',
    loadMore,
    onSearch
}, ref) => {
    const [isOpen, { on, off }] = useToggle();

    const { search, clear, ...searchInputProps } = useSearch();

    const filteredOptions = useMemo(() => {
        return options.filter(option => option.label.toLowerCase().includes(search.toLowerCase()));
    }, [options, search]);

    useEffect(() => {
        if (!isOpen) {
            clear();
        }
        
    }, [isOpen]);
    
    return (
        <div className={className} ref={ref}>
            {/*@ts-expect-error не понятно как передать пропсы, чтобы imMulti=undefined не ругалось с isMulti=true*/}
            <SelectRoot
                options={options}
                isOpen={isOpen}
                onOpen={on}
                onClose={off}
                value={value}
                error={error}
                placeholder={placeholder}
                renderLabel={renderLabel}
                classes={classes}
                disabled={disabled}
                isMulti={isMulti}
                dropdownWidth={dropdownWidth}
            >
                <SelectSearch {...searchInputProps} clearInput={clear} onSearch={onSearch} />
                {/*@ts-expect-error не понятно как передать пропсы, чтобы imMulti=undefined не ругалось с isMulti=true*/}
                <OptionsList value={value} onChange={onChange} options={filteredOptions} isMulti={isMulti} search={search} selectName={selectName} loadMore={loadMore} />
            </SelectRoot>
        </div>
    );
};

export default forwardRef(NewSelect);
