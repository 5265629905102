import { HTMLAttributes, ReactNode } from 'react';

import cx from 'classnames';

import IconUI from '@components/core/IconUI';
import Checkbox from '@components/core/inputs/Checkbox';
import IconButton from '@components/core/styledWrappers/IconButton';
import { useToggle } from '@hooks/useToggle.hook';

import styles from './AccordeonItem.module.scss';

interface AccordeonItemProps extends HTMLAttributes<HTMLDivElement> {
    label:string;
    children: ReactNode;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked:boolean;
    contentClassName?:string;
}

const AccordeonItem = ({ label, children, onChange, checked, contentClassName, ...props }: AccordeonItemProps) => {
    const [isOpen, { toggle }] = useToggle();

    return <div className={cx(styles.accordeonItem, { [styles.open]: isOpen })} {...props}>
        <div className={styles.accordeonHeader} onClick={toggle}>
            <Checkbox label={label} containerClassName={styles.bold} onChange={onChange} checked={checked} />
            <IconButton className={styles.arrow}>
                <IconUI typeIcon='arrow' />
            </IconButton>
        </div>
        <div className={styles.accordeonContent}>
            <div className={cx(styles.content,  contentClassName)}>
                {children}
            </div>
        </div>
    </div>;
};

export default AccordeonItem;