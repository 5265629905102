import { FC } from 'react';

import cx from 'classnames';

import style from './user_avatar.module.scss';

export interface IUserAvatar {
    className?: string;
    avatar?: string;
    name: string;
    lastName: string;
    size?: number;
}

const UserAvatar: FC<IUserAvatar> = ({ avatar, name, lastName, className }) => {

    return (
        <div className={cx(style.user_avatar, className)}>
            {
                avatar ? (
                    <img src={avatar} alt="avatar" />
                ) : (
                    `${name?.charAt(0)}${lastName?.charAt(0)}`
                )
            }
        </div>
    );
};

export default UserAvatar;
