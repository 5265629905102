import { FC, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import Paper from '@components/core/styledWrappers/Paper';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdatePasswordMutation } from '@store/api/authApi';

import { useUser } from './../StudentProfile';
import style from './change_password.module.scss';


interface IProps {
    className?: string;
}

const regex = /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/g;

const schema = yup.object({
    oldPassword: yup.string().required('Введите текущий пароль'),
    newPassword: yup.string().required('Введите новый пароль'),
    repeatPassword: yup.string()
        .required('Повторите новый пароль')
        .matches(regex,
            ' - Пароль должен содержать не менее 8 символов \n - Прописные и строчные буквы \n - Хотя бы одну цифру'
        )
        .oneOf([yup.ref('newPassword'), null], 'Пароли не совпадают')
        .notOneOf([yup.ref('oldPassword'), null], 'Новый пароль совпадает со старым')
});

type TUserProfileInfoForm = yup.InferType<typeof schema>;

const ChangePassword: FC<IProps> = () => {
    const user = useUser();
    const [updatePassword, { isLoading, isError: isPasswordError, error, isSuccess }] = useUpdatePasswordMutation();

    const methods = useForm<TUserProfileInfoForm>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            newPassword: '',
            oldPassword: '',
            repeatPassword: ''
        }
    });

    const { watch, handleSubmit, setError, reset } = methods;

    const watchOldPassword = watch('oldPassword');
    const watchNewPassword = watch('newPassword');
    const watchRepeatPassword = watch('repeatPassword');

    const isShowBtns = watchOldPassword || watchNewPassword || watchRepeatPassword;

    const onSubmit = async (formData: TUserProfileInfoForm) => {

        await updatePassword({
            password: formData.oldPassword,
            newPassword: formData.newPassword
        });
    };

    useEffect(() => {
        if (isSuccess) {
            reset();
        }

        if (isPasswordError) {
            setError('oldPassword', { type: 'custom', message: 'Неверный пароль' });
        }
    }, [isLoading, isSuccess, isPasswordError]);

    return (
        <>
            <Paper className={style.profile}>
                <h2 className={style.subtitle}>
                    Смена пароля
                </h2>

                <div className={style.form}>

                    <FormProvider {...methods}>
                        <div className={style.inputs_wrapper}>
                            <div className={style.field}>
                                <div className={style.label}>Текущий пароль</div>
                                <div className={style.content}>
                                    <InputControl
                                        name="oldPassword"
                                        placeholder="Введите текущий пароль"
                                        type="password"
                                        classNameWrapper={style.input}
                                        passwordStayHide={true}
                                        variant="errorWarning"
                                    />
                                </div>
                            </div>

                            <div className={style.field}>
                                <div className={style.label}>Новый пароль</div>
                                <div className={style.content}>
                                    <InputControl
                                        name="newPassword"
                                        placeholder="Введите новый пароль"
                                        type="password"
                                        classNameWrapper={style.input}
                                        passwordStayHide={true}
                                        variant="errorWarning"
                                    />
                                </div>
                            </div>

                            <div className={style.field}>
                                <div className={style.label}>Новый пароль еще раз</div>
                                <div className={style.content}>
                                    <InputControl
                                        name="repeatPassword"
                                        placeholder="Повторите новый пароль"
                                        type="password"
                                        classNameWrapper={style.input}
                                        passwordStayHide={true}
                                        variant="errorWarning"
                                    />
                                </div>
                            </div>
                        </div>

                        {isShowBtns &&
                            <div className={style.btns_wrapper}>
                                <Button btnType="success" onClick={handleSubmit(onSubmit)} >
                                    Изменить пароль
                                </Button>
                                <Button btnType="secondary" onClick={() => reset()} >
                                    Отмена
                                </Button>
                            </div>}
                    </FormProvider>
                </div>
            </Paper>
        </>
    );
};

export default ChangePassword;
