import { useState } from 'react';

import { ImgWithDummy } from '@components/ImgWithDummy';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { StudentSkill } from '@store/api/apiTypes';
import { useGetFileQuery } from '@store/api/storageApi';
import { SkillModal } from '@views/student/StudentProfile/StudentProfileInfo/Skills/SkillModal';

import styles from './SkillListCard.module.scss';


export function SkillListCard({ card }:{ card: StudentSkill }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data } = useGetFileQuery(card.uuidPicture ? card.uuidPicture : skipToken);
    const imagePath = data?.files?.[0]?.path;

    const openModal = () => setIsModalOpen(true);

    const closeModal = () => setIsModalOpen(false);

    return (
        <>
            <div className={styles.card} onClick={openModal}>
                <div className={styles.img_container}>
                    {imagePath && <ImgWithDummy src={imagePath} alt={card.name} />}
                </div>
                <div className={styles.name}>
                    {card.name}
                </div>
            </div>
            <SkillModal skill={card} isOpen={isModalOpen} close={closeModal} imagePath={imagePath} />
        </>
    );
}
