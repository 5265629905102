import { FC, useEffect, useMemo } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import NewTreeSelectControl, { transformDataToOptions } from '@components/core/inputs/NewTreeSelectControl';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import SwitcherControl from '@components/core/inputs/Switcher/SwitcherControl';
import Paper from '@components/core/styledWrappers/Paper';
import TinyEditor from '@components/methodist/TinyEditor';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { GroupTree } from '@store/api/apiTypes';
import { Role } from '@store/Roles';

import style from './test_form.module.scss';

const schema = yup.object().shape({
    title: yup.string().required('Поле обязательно для заполнения').max(250, 'Максимум 250 символов'),
    editor: yup.string(),
    select: yup.object(),
    score: yup.number().required('Поле обязательно для заполнения').max(100, 'Максимальная оценка 100 баллов').typeError('Укажите число'),
    time: yup.number().typeError('Укажите число').min(1, 'Как минимум одна минута').max(120, 'Максимум времени - два часа'),
    countAttempts: yup.number().typeError('Укажите число').min(1, 'Как минимум одна попытка').max(4, 'Максимум - 4 попытки'),
    accessLimitation: yup.boolean(),
    blockedGroups: yup.array().of(yup.string().required()).when('accessLimitation', {
        is: true,
        then: yup.array().min(1, 'Выберите группу')
    })
});


export type TestFormType = {
    title: string;
    editor: string;
    select: {
        label: string;
        value: boolean;
    };
    score: number;
    time: number;
    countAttempts: number;
    accessLimitation: boolean;
    blockedGroups: string[]
}

interface ICreateTestForm {
    submitFormHandler: (formData: TestFormType) => void;
    defaultValues: TestFormType;
    groups?: GroupTree;
}


const TestForm: FC<ICreateTestForm> = ({ submitFormHandler, defaultValues, groups }) => {
    const { title, editor, select, score, time, accessLimitation, countAttempts, blockedGroups } = defaultValues;

    const navigate = useNavigate();
    const { uuidCategory, uuidDiscipline } = useParams();

    const methods = useForm<TestFormType>({
        defaultValues,
        resolver: yupResolver(schema)
    });

    const groupsOptions = useMemo(() => groups
        ? transformDataToOptions(groups.childs, {
            value: 'UUID',
            label: 'name',
            children: 'childs'
        })
        : [], [groups]);

    const { control, handleSubmit, reset, watch, setValue } = methods;

    useEffect(() => {
        setValue('title', title);
        setValue('editor', editor);
        setValue('select', select);
        setValue('score', score);
        setValue('time', time);
        setValue('accessLimitation', accessLimitation);
        setValue('countAttempts', countAttempts);
        setValue('blockedGroups', blockedGroups);
    }, [defaultValues]);

    const resetFormHandler = () => {
        reset({
            title: title,
            editor: editor,
            select: select,
            score: score,
            time: time,
            countAttempts: countAttempts,
            blockedGroups: blockedGroups
        });
        navigate(`/materials/${uuidCategory}/course/${uuidDiscipline}/structure`);
    };

    const watchAccessLimitation = watch('accessLimitation');

    const onSubmit = handleSubmit((formData) => {
        if (!formData.accessLimitation) {
            formData.blockedGroups = [];
        }
        submitFormHandler(formData);
    });

    return (
        <Grid>
            <Column size={10}>
                <Paper className={style.paper}>
                    <h2 className={style.title}>Основное</h2>
                    <form onSubmit={onSubmit}>
                        <FormProvider {...methods}>
                            <div>
                                <div className={cx(style.fieldset, style['fieldset--border_line'])}>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <label htmlFor="field-title-id">
                                            Название*
                                        </label>
                                        <InputControl
                                            name="title"
                                            placeholder="Название"
                                            id="field-title-id"
                                            disabled={!checkRole(Role.Methodist)}
                                        />
                                    </div>
                                    <div className={style.input_wrapper}>
                                        <p>
                                            Описание
                                        </p>
                                        <TinyEditor
                                            name="editor"
                                            control={control}
                                            disabled={!checkRole(Role.Methodist)}
                                        />
                                    </div>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <p>Оценивать</p>
                                        <SelectStyled
                                            name="select"
                                            control={control}
                                            options={[{ label: 'Да', value: true }, { label: 'Нет', value: false }]}
                                            isSearchable={false}
                                            styled={{ width: '100px' }}
                                            isDisabled={!checkRole(Role.Methodist)}
                                        />
                                    </div>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <label htmlFor="field-score-id">
                                            Максимальная оценка*
                                        </label>
                                        <div className={style.input_score_wrapper}>
                                            <InputControl
                                                name="score"
                                                type="number"
                                                id="field-score-id"
                                                styled={{ width: '100px' }}
                                                disabled={!checkRole(Role.Methodist)}
                                            />
                                            <span>Баллов</span>
                                        </div>
                                    </div>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <label htmlFor="field-time-id">
                                            Ограничение по времени
                                        </label>
                                        <div className={style.input_score_wrapper}>
                                            <InputControl
                                                name="time"
                                                type="number"
                                                id="field-time-id"
                                                styled={{ width: '100px' }}
                                                disabled={!checkRole(Role.Methodist)}
                                            />
                                            <span>Минут</span>
                                        </div>
                                    </div>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <label htmlFor="field-time-id">
                                            Число попыток
                                        </label>
                                        <div className={style.input_score_wrapper}>
                                            <InputControl
                                                name="countAttempts"
                                                type="number"
                                                id="field-time-id"
                                                styled={{ width: '100px' }}
                                                disabled={!checkRole(Role.Methodist)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={style.fieldset} style={{ paddingTop: '24px' }}>
                                    <div className={style.input_wrapper}>
                                        <p>Доступ ограничен</p>
                                        <div className={style.permission_block_wrapper}>
                                            <SwitcherControl
                                                name="accessLimitation"
                                                control={control}
                                                disabled={!checkRole(Role.Methodist)}
                                            />
                                            {watchAccessLimitation && (
                                                <div className={style.select_list_wrapper}>
                                                    <NewTreeSelectControl
                                                        className={style.select}
                                                        name="blockedGroups"
                                                        options={groupsOptions}
                                                        isMulti={true}
                                                        placeholder="Выберите группы"
                                                        disabled={!checkRole(Role.Methodist)}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <RoleCheck roles={Role.Methodist}>
                                        <div className={style.input_wrapper}>
                                            <div></div>
                                            <div className={style.form_content__button_wrapper}>
                                                <Button type="submit">Сохранить</Button>
                                                <Button
                                                    btnType="secondary"
                                                    onClick={resetFormHandler}
                                                >
                                                    Отмена
                                                </Button>
                                            </div>
                                        </div>
                                    </RoleCheck>
                                </div>
                            </div>
                        </FormProvider>
                    </form>
                </Paper>
            </Column>
        </Grid>
    );
};

export default TestForm;
