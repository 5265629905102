import { useState, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { TUploadFile } from '@components/core/inputs/FileUpload/type';
import { fromBase64, getBase64 } from '@helpers/base64';
import { isStorageFile } from '@helpers/typeCheck';
import { fileTypes, IStorageFile } from '@store/api/apiTypes';
import { usePassAuthenticationMutation } from '@store/api/authApi';
import { useUploadFileMutation } from '@store/api/storageApi';
import { addAuthenticationUuid, removeAuthenticationUuid, selectAuthenticationData } from '@store/features/authenticationSlice';
import { selectDeviceUuid } from '@store/features/authSlice';
import { selectApiUser } from '@store/features/userSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export const useAuthenticate = (picture: string, onAuth: () => void) => {
    const { uuidTest, uuidTask } = useParams();

    const dispatch = useAppDispatch();
    const uuidDevice = useAppSelector(selectDeviceUuid);
    const userApi = useAppSelector(selectApiUser)?.uuid || '';
    const authenticationData = useAppSelector(selectAuthenticationData).find(a => uuidTest ? a.uuidTest === uuidTest : a.uuidTest === uuidTask);

    const [pictureState, setPictureState] = useState(picture);

    const [uploadFile] = useUploadFileMutation();
    const [passAuthentication] = usePassAuthenticationMutation();

    const loadAuthenticationImage = useCallback(async () => {
        const uuidsFile: string[] = [];
        const fileData = [fromBase64(pictureState, 'authentication.jpeg')];

        const uploadFileHandler = async (file: IStorageFile | TUploadFile) => {
            if (file) {
                if (!isStorageFile(file)) {
                    const b64 = await getBase64(file);
                    const uploadedFile = await uploadFile({
                        file: b64 as string,
                        meta: {
                            filename: 'authentication.jpeg',
                            type: fileTypes.Public
                        }
                    }).unwrap();
                    uuidsFile.push(uploadedFile.uuid);
                } else {
                    uuidsFile.push(file.uuid);
                }
            }
        };

        if (fileData) {
            for await (const file of fileData) {
                await uploadFileHandler(file);
            }
        }

        const authenticationResult = await passAuthentication({
            uuidAuth: uuidsFile[0],
            uuidMaterial: uuidTest || uuidTask || '',
            uuidUser: userApi,
            uuidDevice: uuidDevice || ''
        }).unwrap();

        dispatch(removeAuthenticationUuid(authenticationData?.uuidAuthentication || ''));
        dispatch(addAuthenticationUuid({
            uuidAuthentication: authenticationResult.uuid,
            uuidTest: uuidTest || uuidTask || ''
        }));

        if (authenticationResult) onAuth();
    }, [pictureState]);

    return { loadAuthenticationImage, setPictureState };
};
