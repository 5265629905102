import React, { FC, useMemo } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { OptionType } from '@components/core/inputs/SelectStyled/type';
import { useGetUsersByRoleQuery } from '@store/api/usersApi';
import { Role } from '@store/Roles';
import { User }  from '@type/users';
import AddTeachingStaffModal, { AddTeacherForm } from '@views/methodist/Materials/Course/AddTeachingStaffModal/AddTeachingStaffModal';
import ListItem from '@views/methodist/Materials/Course/TeachersCard/ListItem';

interface IProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
}

const MethodistList: FC<IProps> = ({ showModal, setShowModal }) => {
    const { methodists } = useGetUsersByRoleQuery(Role.Methodist, {
        selectFromResult: ({ data }) => ({
            methodists: data ?? []
        })
    });
    const { control } = useFormContext();
    const { fields, remove, append } = useFieldArray({
        name: 'uuidsMetodist',
        control
    });

    const setSelectedTeacherValue = (selectData: AddTeacherForm) => {
        const transformedSelectData = selectData.select.reduce((uuids: { uuid: string }[], item: { value: string, label: string }) => {
            uuids.push({ uuid: item.value });
            return uuids;
        }, []);
        append(transformedSelectData);
    };

    const deleteUserHandler = (fieldIndex: number | number[]) => {
        remove(fieldIndex);
    };

    const filterOptions = useMemo(() => {
        // @ts-ignore
        const filterTeachers = methodists?.filter((item: User) => fields?.every((field: any) => field.uuid !== item.uuid));

        return filterTeachers?.map((user: User) => (
            {
                label: `${user.lastName} ${user.firstName} ${user.patronymic}`,
                value: user.uuid,
                picture: user.picture
            }
        ));

    }, [fields, methodists]);

    return (
        <>
            <ul>
                {fields.map((field, index) => {
                    // @ts-ignore
                    const selectedUser = methodists.find((user) => user.uuid === field.uuid) as User;
                    return <ListItem
                        key={selectedUser?.uuid}
                        user={selectedUser}
                        fieldIndex={index}
                        deleteUser={deleteUserHandler}
                    />;
                })}
            </ul>
            <AddTeachingStaffModal
                title={'Добавить методиста'}
                type={'methodist'}
                options={filterOptions as OptionType[]}
                showModal={showModal}
                setShowModal={setShowModal}
                setSelectedTeacherValue={setSelectedTeacherValue}
            />
        </>
    );
};

export default MethodistList;
