import { Outlet } from 'react-router-dom';

import RouterTabs from '@components/RouterTabs/RouterTabs';

import style from './AdditionalSettingsTabs.module.scss';

const tabs = [
    { name: 'Компетенции', path: 'skills' },
    { name: 'Плановые дисциплины', path: 'disciplines' },
    { name: 'Пресеты', path: 'presets' },
    { name: 'Портфолио', path: '' }
];

const AdditionalSettingsTabs = () => {
    return (
        <>
            <div className={style.tabs_wrapper}>
                <RouterTabs className={style.tabs} tabs={tabs} />
            </div>

            <Outlet />
        </>
    );
};

export default AdditionalSettingsTabs;
