import { FC, useEffect, useState } from 'react';

import { Breadcrumbs } from '@components/Breadcrumbs';
import Paper from '@components/core/styledWrappers/Paper';
import { renderTabList } from '@components/Tabs/Tabs';
import UserAvatar from '@components/UserAvatar';
import { getPaths } from '@helpers/findNestedPath';
import { findRootCurrentGroup, makeGroupTreeFlat } from '@helpers/groupTree';
import { GroupTree } from '@store/api/apiTypes';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { User } from '@type/users';

import style from './AttemptUserInfo.module.scss';


interface ITest {
    user: User;
}

export const AttemptUserInfo: FC<ITest> = ({ user }) => {
    const groupsData = useGetGroupsTreeQuery('');

    const [pathTo, setPathTo] = useState<{uuid: string, name: string}[]>([]);
    const [rootGroupUuid, setRootGroupUuid] = useState<string>('');

    useEffect(() => {
        if (groupsData.data && groupsData.isSuccess) {
            //Получение текущей группы студента
            const currentGroup = makeGroupTreeFlat(groupsData.data.childs).find(g => g.UUID === user.groupUUID[0]);
            // Нахождение рут группы студента
            currentGroup && setRootGroupUuid(findRootCurrentGroup(currentGroup, groupsData.data).UUID);
        }
    }, [groupsData.isLoading, groupsData.isSuccess]);

    // Создание хлебных крошек на основании текущей и рутовой групп студента
    useEffect(() => {
        if (groupsData.isSuccess) {
            const pathToGroup = getPaths<GroupTree, 'childs', 'UUID'>(
                groupsData.data.childs,
                'childs',
                'UUID',
                (group) => group.UUID === (user.groupUUID[0] ?? rootGroupUuid)
            );

            setPathTo(pathToGroup ?? []);
        }
    }, [groupsData.isSuccess, rootGroupUuid]);

    return (
        <div className={style.info}>
            <div className={style.user_info}>
                <UserAvatar name={user.firstName} lastName={user.lastName} className={style.avatar} />
                <div>
                    <p className={style.user_name}>{user.lastName} {user.firstName} {user.patronymic}</p>
                    <Breadcrumbs>
                        {
                            pathTo.map((group) => <span className={style.breadcrumbs_item} key={group.uuid}>{group.name}</span>)
                        }
                    </Breadcrumbs>
                </div>
            </div>
        </div>
    );
};
