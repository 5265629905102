import React from 'react';

import { useParams } from 'react-router-dom';

import { skipToken } from '@reduxjs/toolkit/query';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { useGetTaskByIdQuery } from '@store/api/taskApi';
import { AuthPersona } from '@views/student/AuthPersona';

export const StudentTaskAuth = () => {
    const { uuidDiscipline, uuidTask } = useParams();
    const { data: disciplineData } = useGetDisciplineByIdQuery(uuidDiscipline || skipToken);

    const taskData = useGetTaskByIdQuery(uuidTask ?? skipToken);

    const pageTitle = disciplineData && taskData.data ? `${disciplineData.name}: ${taskData.data.name}, Аутентификация` : '';

    return <AuthPersona title={pageTitle} type="task" />;
};
