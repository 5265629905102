import React, { FC, useEffect, useState } from 'react';

import cx from 'classnames';

import { ReactComponent as Loader } from '@assets/icons/bars-rotate-loader.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg';
import { MarkdownPreview } from '@components/MarkdownPreview';


import DateFormat from '../DateFormat';
import style from './MessageText.module.scss';


export interface IMessageTextPros {
    time?: string;
    own: boolean;
    children: string;
    loading?: boolean;
    className?: string
}

const MessageText:FC<IMessageTextPros> = ({
    time,
    own,
    children,
    loading,
    className
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [sendingError, setSendingError] = useState(false);

    useEffect(() => {
        if (loading) {
            const timerForLoading = setTimeout(() => {
                setIsLoading(true);
            }, 200);

            const timerForError = setTimeout(() => {
                setSendingError(true);
            }, 1000 * 60);

            return () => {
                clearTimeout(timerForLoading);
                clearTimeout(timerForError);
            };
        } else {
            setIsLoading(false);
        }
    }, [loading]);

    return (
        <div className={cx(style.container, className)}>
            <div className={style.bubbleContent}>
                <div className={style.messageContent}>
                    <MarkdownPreview source={children} />
                </div>
                {time && <DateFormat time={time} theme={own ? 'own' : 'default'} />}
            </div>
            {!sendingError && isLoading && <Loader className={cx(style['status-icon'], style.loading)} />}
            {sendingError && <CloseIcon className={cx(style['status-icon'], style.error)} />}
        </div>
    );
};

export default MessageText;
