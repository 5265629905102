import { refreshTokens } from '@store/features/authSlice';
import { store } from '@store/store';

// success - Запрос прошел успешно
// error - Запрос с ошибкой
// loading - Идет запрос
// errorWorker - Ошибка в файле sharedWorkerUpdating
interface IMessageUpdateSuccess {
    type: 'success',
    data: {
        access: string,
        refresh: string
    }
}

interface IMessageUpdateError {
    type: 'error',
    data: {
        access: string,
        refresh: string
    }
}

interface IMessageUpdateLoading {
    type: 'loading',
    data: null,
}

interface IMessageUpdateLockWorker {
    type: 'lock'
}

interface IRefreshTokensResponse {
    access: string;
    refresh: string;
}

export type TMessageResponse = IMessageUpdateSuccess | IMessageUpdateError | IMessageUpdateLoading | IMessageUpdateLockWorker;

const fetchRefreshToken = async (
    url: string,
    authToken: string | null,
    refresh: string | null,
    deviceUuid: string | null,
    sessionId?: string | null
): Promise<IRefreshTokensResponse> => {
    const headers = new Headers();
    headers.append('Session', `${sessionId}`);

    if (authToken) {
        headers.append('Authorization', `${authToken}`);
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ refresh, uuidDevice: deviceUuid })
    });

    return await response.json();
};

class BroadcastChannelUpdating {
    private broadcastChannel = new BroadcastChannel('tokens_channel');
    public status = '';
    public isLocked = false;

    constructor() {
        this.broadcastChannel.onmessage = (event: MessageEvent<TMessageResponse>) => {
            const { type } = event.data;
            if (type === 'lock') {
                this.isLocked = true;
            } else {
                if (type === 'success') {
                    const newTokens = {
                        access: event.data.data.access,
                        refresh: event.data.data.refresh
                    };
                    store.dispatch(refreshTokens(newTokens));
                }
                this.status = type;
                this.isLocked = false;
            }
        };
    }

    updateToken = (url: string, authToken: string | null, refresh: string | null, deviceUuid: string | null, sessionId?: string | null): void => {
        this.isLocked = true;
        this.broadcastChannel.postMessage({ type: 'lock' });
        fetchRefreshToken(url, authToken, refresh, deviceUuid, sessionId)
            .then((response) => {
                if (response.access) {
                    this.status = 'success';
                    this.broadcastChannel.postMessage({ type: 'success', data: response });
                    store.dispatch(refreshTokens({ access: response.access, refresh: response.refresh }));
                } else {
                    throw response;
                }
            })
            .catch((error) => {
                this.status = 'error';
                this.broadcastChannel.postMessage({ type: 'error', data: error instanceof String ? error : JSON.stringify(error) });
            })
            .finally(() => {
                this.isLocked = false;
            });
    };

    async waitForUnlock(): Promise<void> {
        return new Promise<void>((resolve) => {
            const checkInterval = setInterval(() => {
                if (!this.isLocked) {
                    clearInterval(checkInterval);
                    resolve();
                }
            }, 100);
        });
    }

}

export const broadcastChannelUpdating = new BroadcastChannelUpdating();
