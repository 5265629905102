import React, { FC, useState } from 'react';

import cx from 'classnames';
import { render } from 'react-dom';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as TaskVerifiedIcon } from '@assets/icons/control-work-verified-icon.svg';
import { ReactComponent as CourseIcon } from '@assets/icons/course.svg';
import { ReactComponent as EventIcon } from '@assets/icons/event.svg';
import { ReactComponent as NotifyIcon } from '@assets/icons/notify.svg';
import { ReactComponent as TaskIcon } from '@assets/icons/task.svg';
import { ReactComponent as WebinarAddedIcon } from '@assets/icons/vebinar-added-icon.svg';
import { ReactComponent as VideoAddedIcon } from '@assets/icons/video-lecture-added-icon.svg';
import { dateConvert } from '@helpers/date';
import useDidUpdateEffect from '@hooks/useDidUpdate.hook';
import { useHover } from '@hooks/useHover';
import { NoticePayload, NoticeTypes, Notices } from '@type/notifications';

import style from './NotifyItem.module.scss';

export interface INotifyItemProps {
    title: string;
    type: NoticeTypes;
    date: string;
    isReaded?: boolean;
    payload?: NoticePayload;
    originData: Notices;
    readFunc: (uuid: string) => void;
    onCloseDrawer: () => void;
}

export type IRenderNotifyIcon = Record<NoticeTypes, React.ReactNode>

export const NotifyItem: FC<INotifyItemProps> = ({
    title,
    type,
    date,
    isReaded = true,
    originData,
    payload,
    readFunc,
    onCloseDrawer
}) => {
    const navigate = useNavigate();

    const [hoverRef, isHovered] = useHover<HTMLDivElement>();
    const [wasHovered, setWasHovered] = useState(false);

    const renderIcon: IRenderNotifyIcon = {
        [NoticeTypes.eventStartSoon]: <EventIcon />,
        [NoticeTypes.eventStartSoon15min]: <EventIcon />,
        [NoticeTypes.disciplineOpened]: <CourseIcon />,
        [NoticeTypes.disciplineAdded]: <CourseIcon />,
        [NoticeTypes.controlWorkVerified]: <TaskVerifiedIcon />,
        [NoticeTypes.webinarAdded]: <WebinarAddedIcon />,
        [NoticeTypes.videoLectureAdded]: <VideoAddedIcon />,
        [NoticeTypes.controlWorkAdded]: <TaskIcon />
    };

    useDidUpdateEffect(() => {
        if (!wasHovered && isHovered && !isReaded) {
            readFunc(originData.baseUUID);
        }

        setWasHovered(true);
    }, [isHovered]);


    const getLabel = () => {
        switch (originData.noticeType) {
            case NoticeTypes.disciplineOpened:
            case NoticeTypes.videoLectureAdded:
            case NoticeTypes.controlWorkVerified:
                return <div className={style.link}>{title}</div>;
            case NoticeTypes.eventStartSoon:
            case NoticeTypes.eventStartSoon15min:
                return <div className={style.link}>{title}</div>;
            default:
                return title;
        }
    };

    const renderContent = (
        <div ref={hoverRef} className={cx(style.container, !isReaded && style.isNew)}>
            
            <div className={style.icon}>
                {renderIcon[type] || <NotifyIcon />}
            </div>

            <div className={style.content}>
                <div className={style.title}>{getLabel()}</div>
                <div className={style.date}>{dateConvert(date).toFormat('dd.LL.yyyy г., в HH:mm')}</div>
            </div>
        
        </div>
    );

    return <>
        {
            payload?.link 
                ?
                <a href={payload.link} rel="noreferrer" >
                    { renderContent }
                </a> 
                :
                renderContent
        }
    </>;
};
