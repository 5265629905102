import React, { FC, useEffect } from 'react';

import classNames from 'classnames/bind';

import { useIdHook } from '@hooks/useId.hook';

import style from './checkbox.module.scss';

const cx = classNames.bind(style);

export interface ICheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
    label?: string | React.ReactNode;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    innerRef?: React.RefObject<HTMLInputElement>;
    theme?: 'light' | 'dark';
    checked?: boolean;
    className?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    containerClassName?: string;
}

const Checkbox: FC<ICheckboxProps> = ({
    label,
    onChange,
    innerRef,
    theme = 'light',
    checked = false,
    children,
    disabled = false,
    containerClassName,
    ...props
}) => {
    const id = useIdHook('checkbox-');
    const [checkedState, setChecked] = React.useState(checked);

    useEffect(() => {
        setChecked(checked);
    }, [checked]);

    const onChangeWrapp = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(!checkedState);

        if (!onChange) return;
        onChange(event);
    };

    return (
        <div className={cx('root', disabled && 'root--disabled', containerClassName)}>
            <input
                onChange={onChangeWrapp}
                id={id}
                type="checkbox"
                ref={innerRef}
                className={cx('root__input', props.className)}
                checked={checkedState}
                {...props}
            />

            <label htmlFor={id} className={cx('root__label', theme && `root--theme-${theme}`)}>
                { label && <span>{label}</span> }
                { children && <span>{children}</span> }
            </label>
        </div>
    );
};

export default Checkbox;
