import React, { FC, ReactNode, useState } from 'react';

import cx from 'classnames';

import style from './tooltip.module.scss';



interface IProps {
    children: ReactNode;
    title: string;
    className?: string;
}

export const Tooltip:FC<IProps> = ({ children, title, className }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className={cx(style['tooltip-container'], className)} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
            {children}
            {isVisible && <div className={style['tooltip-box']}>{title}</div>}
        </div>
    );
};
