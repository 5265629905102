import React, { useMemo } from 'react';

import { Outlet } from 'react-router-dom';

import { WebSocketContext, webSocketContextContext } from '@components/WebSocket/context/index';
import { useWebSocketConnect } from '@hooks/useWebSocketConnect';


export const WebSocketProvider = () => {
    const { sendMessage, subscription, connection } = useWebSocketConnect();

    const value: WebSocketContext = useMemo(() => ({
        subscription,
        sendMessage,
        connection
    }), [
        subscription,
        sendMessage,
        connection
    ]);

    return (
        <webSocketContextContext.Provider value={value}>
            <Outlet />
        </webSocketContextContext.Provider>
    );
};
