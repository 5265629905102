import { FC, useEffect, useRef, useState } from 'react';

import classNames from 'classnames/bind';

import HelperText from '@components/core/inputs/HelperText/HelperText';
import { IStorageFile } from '@store/api/apiTypes';

import { FileCaption } from '../parts/FileCaption/FileCaption';
import { FilePlaceholder } from '../parts/FilePlaceholder';
import { IPlaceholderBackendData, TUploadFile } from '../type';
import { FileAreaStage } from './FileAreaStage';
import style from './fileuploadarea.module.scss';
import { acceptAreaTypes } from './validation';

const cx = classNames.bind(style);

export type FileUploadAreaProps = {
    name?: string;
    onChange?: (file: TUploadFile | IStorageFile) => void;
    value?: TUploadFile | IStorageFile;
    helperText?: string | null;
    withoutCheckControl?: boolean;
    maxSizeFile?: number;
    disabled?: boolean;
    className?: string;
};

const FileUploadArea: FC<FileUploadAreaProps> = ({
    name,
    value,
    helperText,
    withoutCheckControl = false,
    onChange,
    maxSizeFile,
    disabled,
    className
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [api, setApi] = useState<IPlaceholderBackendData | null>({
        file: null,
        stage: 'default',
        isDragOver: false,
        inputRef,
        acceptTypes: acceptAreaTypes
    });

    const [firstUpdate, setFirstUpdate] = useState(true);

    const deleteFile = () => {
        if (api?.inputRef?.current) {
            api.inputRef.current.value = '';
        }

        setApi({ ...api, file: null, stage: 'default' });
    };


    // Set value form
    useEffect(() => {
        onChange && onChange(api?.file);
    }, [api?.file, onChange]);

    // Default value form
    useEffect(() => {
        if (value && firstUpdate) {
            setApi({ ...api, file: value, stage: 'uploading' });
            setFirstUpdate(false);
        }
    }, [value]);

    return (
        <div
            role="presentation"
            className={cx(
                'root',
                className,
                {
                    [style['root--disabled']]: disabled
                }
            )}
        >
            <div
                className={cx(
                    'root__inner',
                    {
                        ['root--select']: api?.isDragOver,
                        ['root--droped']: api?.file
                    }
                )}
            >
                <FilePlaceholder
                    api={api}
                    setApi={setApi}
                    name={name}
                    maxSizeFile={maxSizeFile}
                    disabled={disabled}
                    droppable
                >
                    <FileAreaStage
                        withoutCheckControl={withoutCheckControl}
                        api={api}
                        deleteFile={deleteFile}
                        disabled={disabled}
                    />
                </FilePlaceholder>
            </div>

            <FileCaption size="200" />
            {
                helperText && <HelperText message={helperText} />
            }
        </div>
    );
};

export { FileUploadArea };
