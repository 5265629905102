import { FC } from 'react';

import cx from 'classnames';

import { ReactComponent as LoaderSVG } from '@assets/icons/loader.svg';

import style from './loader.module.scss';

interface ILoader {
    className?: string;
}

const Loader: FC<ILoader> = ({ className }) => {

    return (
        <div className={cx(style.wrapper, className)}>
            <div className={style.loader}>
                <LoaderSVG  />
            </div>
        </div>
    );
};

export default Loader;
