import { FC, useEffect, useState } from 'react';

import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';

import Paper from '@components/core/styledWrappers/Paper';
import { Helmet } from '@components/Helmet';
import GoBackLink from '@components/student/GoBackLink';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { selectAuthenticationAttempt } from '@store/features/authenticationSlice';
import { useAppSelector } from '@store/hooks';

import style from './AuthPersona.module.scss';
import { AuthConfirm } from './AuthPersonaSteps/AuthConfirm/AuthConfirm';
import { AuthPersonaCamera } from './AuthPersonaSteps/AuthPersonaCamera';
import { AuthPersonaSuccess } from './AuthPersonaSteps/AuthPersonaSuccess';
import { AuthPersonaPolicy } from './AuthPersonaSteps/AuthPesonaPolicy';
import { AuthPesonaSetupCamera } from './AuthPersonaSteps/AuthPesonaSetupCamera';
import { AuthRecommendation } from './AuthPersonaSteps/AuthRecommendation';

interface AuthPersonaProps {
    title: string;
    type: 'task' | 'test';
}

export const AuthPersona: FC<AuthPersonaProps> = ({ title, type }) => {
    const { uuidTest } = useParams();
    const testAttempt = useAppSelector(selectAuthenticationAttempt).find(a => uuidTest === a.uuidTest);
    const navigate = useNavigate();
    const lgUp = useBreakpoint('lg', 'up');
    const [ authPhoto, setAuthPhoto] =  useState('');
    const [ isPaperFit, setIsPaperFit] =  useState(false);

    useEffect(() => {
        if (testAttempt) {
            navigate('../');
        }
    }, []);

    return <div className={style.content_wrapper}>
        <Helmet title={title} />
        <div className={style.title_wrapper}>
            <GoBackLink to="../">
                Назад
            </GoBackLink>

            <h1>Аутентификация личности</h1>
        </div>

        <Paper className={cx(style.container, isPaperFit && style.paper_fit)}>
            {
                lgUp ?
                    <Wizard>
                        <AuthPersonaPolicy />
                        <AuthPesonaSetupCamera />
                        <AuthPersonaCamera />
                        <AuthPersonaSuccess type={type} />
                    </Wizard>
                    :
                    <Wizard>
                        <AuthPersonaPolicy />
                        <AuthRecommendation setAuthPhoto={setAuthPhoto} />
                        <AuthConfirm picture={authPhoto} setIsPaperFit={setIsPaperFit} />
                        <AuthPersonaSuccess type={type} />
                    </Wizard>
            }
        </Paper>
    </div>;
};
