import { FC } from 'react';

import { Navigate, Outlet, useOutlet, useOutletContext, useParams } from 'react-router-dom';

import { GroupTree } from '@store/api/apiTypes';

const findCategory = (arr: GroupTree[] | undefined, id: string | undefined): GroupTree | undefined => {
    if (arr) {
        for (const obj of arr) {
            if (obj.UUID === id) return obj;
            // const nestedObj = findCategory(obj.children, id);
            // if (nestedObj) return nestedObj;
        }
    }
};

const Users: FC = () => {
    const { uuidGroup } = useParams();
    const outlet = useOutlet();

    return (
        <>
            {
                outlet ? <Outlet /> : <Navigate to={uuidGroup!} />
            }
        </>
    );
};

export default Users;

