import React, { FC, useEffect, useRef } from 'react';

import cx from 'classnames';
import _debounce from 'lodash/debounce';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import * as yup from 'yup';

import InputControl from '@components/core/inputs/InputControl';
import TinyEditor from '@components/methodist/TinyEditor';
import { checkRole } from '@components/RoleCheck';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetLectureByIdQuery, useUpdateLectureChapterMutation } from '@store/api/lecturesApi';
import { Role } from '@store/Roles';

import style from './edit_chapter_form.module.scss';

const editChapterSchema = yup.object({
    title: yup.string().required('Поле обязательно для заполнения!'),
    content: yup.string(),
    uuid: yup.string().required()
});

export type EditChapterFormData = yup.InferType<typeof editChapterSchema>;

const EditChapterForm: FC = () => {
    const {  uuidLecture, uuidChapter } = useParams();

    const { chapterData } = useGetLectureByIdQuery(uuidLecture as string, {
        selectFromResult: ({ data }) => ({
            chapterData: data?.chapters.find((chapter) => chapter.uuid === uuidChapter)
        })
    });

    const [updateChapter] = useUpdateLectureChapterMutation();

    const methods = useForm<EditChapterFormData>({
        resolver: yupResolver(editChapterSchema)
    });

    const { control, handleSubmit, reset, getFieldState } = methods;
    const setIsSaving = useOutletContext<React.Dispatch<React.SetStateAction<boolean>>>();



    const onSubmit = (async (data: EditChapterFormData) => {
        setIsSaving(false);

        const request = {
            uuid: data.uuid,
            name: data.title,
            content: data.content ? data.content : ''
        };

        updateChapter(request).then(() => setIsSaving(true));
    });

    const debouncedSave = useRef(
        _debounce(async () => {

            const needToSave = getFieldState('content').isDirty || getFieldState('title').isDirty;

            if (needToSave) {
                handleSubmit(onSubmit)();
            }
        }, 2000)
    ).current;

    useEffect(() => {
        if (chapterData) {
            const needToSave = getFieldState('content').isDirty || getFieldState('title').isDirty;

            if (needToSave) {
                handleSubmit(onSubmit)();
            }

            reset({
                title: chapterData.name,
                content:  chapterData.content,
                uuid: chapterData.uuid
            });
        }
    }, [chapterData?.uuid]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={style['full_height']}>
            <FormProvider {...methods}>
                <div className={style['full_height']}>
                    <div className={cx(style.fieldset, style['full_height'])}>
                        <InputControl
                            name={'title'}
                            placeholder={'Название'}
                            id={'field-title-id'}
                            onChange={debouncedSave}
                            disabled={!checkRole(Role.Methodist)}
                        />

                        <TinyEditor
                            name={'content'}
                            control={control}
                            onChange={debouncedSave}
                            disabled={!checkRole(Role.Methodist)}
                            className={style.editor}
                            height="100%"
                        />
                    </div>
                </div>
            </FormProvider>
        </form>
    );
};

export default EditChapterForm;
