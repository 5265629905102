import React from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import { ReactComponent as InheritIcon } from '@assets/icons/inherit.svg';
import { ReactComponent as ProgrammIcon } from '@assets/icons/programm.svg';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { MenuItem, TableMenu } from '@components/Menu';
import { RoleCheck } from '@components/RoleCheck';
import { useNavigationState } from '@hooks/useNavigationState';
import { Collapse } from '@mui/material';
import { GroupTree } from '@store/api/apiTypes';
import { Role } from '@store/Roles';
import { StudyProgrammGroupInfo } from '@type/studyProgrammes';
import AccordionTableRow from '@views/methodist/Users/UsersGroups/AccordionTableRow';

import style from './GroupItemCollapseElement.module.scss';

interface GroupItemCollapseElementProps {
    group: GroupTree;
    openGroupModal: (group: GroupTree) => void;
    openDeleteModal: (group: GroupTree) => void;
    openInheritModal: (group: GroupTree) => void;
    groupsProgramms: StudyProgrammGroupInfo[];
    columnsStyles?: React.CSSProperties[];
    level?: number;
    openedGroups: string[];
    setOpenedGroups: (groupId: string) => void;
    currentGroup?: string
}

export const GroupItemCollapseElement: React.FC<GroupItemCollapseElementProps> = ({
    group,
    openGroupModal,
    openDeleteModal,
    openInheritModal,
    groupsProgramms,
    columnsStyles,
    level  = 0,
    openedGroups,
    setOpenedGroups,
    currentGroup
}) => {
    const { navigate } = useNavigationState({ openedGroups, currentGroup });
    const haveProgramme = groupsProgramms.find(info => info.uuidGroup === group.UUID)?.uuidProgramme;

    const onSubscribeClick = (e: React.MouseEvent<Element>) => {
        e.stopPropagation();
        navigate(`/users/groups/${group.UUID}/StudyProgrammes`);
    };

    const onProgrammClick = (e: React.MouseEvent<Element>) => {
        e.stopPropagation();
        navigate(`/users/groups/${group.UUID}/StudyProgrammes`);
    };


    const goToProgramme = () => {
        if (haveProgramme) {
            navigate(`/users/groups/${group.UUID}/StudyProgrammes`);
        } else {
            navigate(`/users/groups/${group.UUID}/StudyProgrammes/create`);
        }
    };

    const goToSkillBinding = () => {
        navigate(`/users/groups/${group.UUID}/skills-binding`);
    };

    const prepareGroupToTable = () => [
        <div key={group.UUID} className={style['title-wrapper']}>
            {group.name}
            {haveProgramme && <ProgrammIcon onClick={onProgrammClick} />}
            {group.isInherit && <InheritIcon />}
        </div>,
        `${group.countUsers || '–'}`
    ];

    const haveChilds = group.childs.length > 0;

    const handleClickMore = () => {
        setOpenedGroups(group.UUID);
    };

    const isOpen = openedGroups.includes(group.UUID);

    return (
        <>
            <div className={style.item} style={{ '--group-level': level } as any}>
                {haveChilds && (
                    <ButtonIcon
                        typeIcon="next"
                        className={cx(
                            style['button-arrow'],
                            {
                                [style['button-arrow--active']]: isOpen
                            }
                        )}
                        onClick={handleClickMore}
                    />
                )}
                <AccordionTableRow
                    columnsStyles={columnsStyles}
                    contents={prepareGroupToTable()}
                    onClick={() => navigate(`/users/groups/${group.UUID}/editGroup`)}
                >
                    <RoleCheck roles={Role.Methodist}>
                        <TableMenu stopPropagation={true}>
                            <MenuItem
                                onClick={(e: React.MouseEvent<Element>) => {
                                    e.stopPropagation();
                                    openGroupModal(group);
                                }}
                            >
                                Редактировать
                            </MenuItem>
                            <MenuItem
                                onClick={() => navigate(`course-subscribe/${group.UUID}`)}
                            >
                                Подписать на курс
                            </MenuItem>
                            <MenuItem
                                onClick={goToProgramme}
                            >
                                Страница &quot;Образование&quot;
                            </MenuItem>
                            {
                                group.isInherit && <MenuItem
                                    onClick={(e: React.MouseEvent<Element>) => {
                                        e.stopPropagation();
                                        openInheritModal(group);
                                    }}
                                >
                                Наследовать подписки
                                </MenuItem>
                            }


                            <MenuItem
                                onClick={goToSkillBinding}
                            >
                                Привязать компетенции
                            </MenuItem>


                            <MenuItem
                                onClick={(e: React.MouseEvent<Element>) => {
                                    e.stopPropagation();
                                    openDeleteModal(group);
                                }}
                                danger
                            >
                                Удалить
                            </MenuItem>
                        </TableMenu>
                    </RoleCheck>
                </AccordionTableRow>
            </div>
            {haveChilds && (
                <Collapse
                    in={isOpen}
                    timeout={0}
                >
                    {group.childs.map((childGroup) => (
                        <GroupItemCollapseElement
                            key={childGroup.UUID}
                            group={childGroup}
                            groupsProgramms={groupsProgramms}
                            openDeleteModal={openDeleteModal}
                            openGroupModal={openGroupModal}
                            openInheritModal={openInheritModal}
                            columnsStyles={columnsStyles}
                            level={level + 1}
                            openedGroups={openedGroups}
                            setOpenedGroups={setOpenedGroups}
                            currentGroup={currentGroup}
                        />
                    ))}
                </Collapse>
            )}

        </>
    );
};
