import React, { FC, useEffect, useState } from 'react';

import { TUploadFile } from '@components/core/inputs/FileUpload/type';
import { isStorageFile } from '@helpers/typeCheck';
import { IStorageFile } from '@store/api/apiTypes';

import { acceptPhotoTypes } from '../validation';

interface IFilePhotoUploadProps {
    file: TUploadFile | IStorageFile;
    className?: string;
}

export const FilePhotoUpload: FC<IFilePhotoUploadProps> = ({ file, className }) => {
    const [fileURL, setFileURL] = useState<string>('');

    const validate = () => {
        if (!isStorageFile(file)) {
            if (!file?.type) return;

            if (acceptPhotoTypes.includes(file.type)) {
                setFileURL(URL.createObjectURL(file));
            }
        } else {
            setFileURL(file.path);
        }
    };

    useEffect(validate, [file]);

    return (
        <React.Fragment>
            {fileURL && (
                <img className={className} src={fileURL} alt="preview" />
            )}
        </React.Fragment>
    );
};
