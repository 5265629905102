import { Column, Grid } from '@components/core/grid';
import { DataList } from '@components/DataList';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Preset, ResponseMessage } from '@store/api/apiTypes';

import styles from './PresetsTable.module.scss';
import PresetTableRow from './PresetTableRow';

const PresetsTable = ({ presets }: {presets: ResponseMessage<Preset>[]}) => {
    
    const [animationParent] = useAutoAnimate();
    
    return (
        <>
            <DataList className={styles.listContainer}>

                <Grid gridTemplateColumns="1fr 1fr" className={styles.header}>
                    <Column>Название</Column>
                    <Column>Привязано плановых дисциплин</Column>
                </Grid>

                <div ref={animationParent}>
                    {presets.map(preset => 
                        <PresetTableRow preset={preset} key={preset.uuid} />
                    )}
                </div>
            </DataList>
        </>
    );
};

export default PresetsTable;