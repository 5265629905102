import { FC, useEffect } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import Paper from '@components/core/styledWrappers/Paper';
import TinyEditor from '@components/methodist/TinyEditor';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import style from './section_form.module.scss';


export const formSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    description: yup.string()
});

export type IProgrammSectionFormDataType = yup.InferType<typeof formSchema>;

type ISectionFormData = {
    submitFormHandler: (formData: IProgrammSectionFormDataType) => void
    defaultValues: IProgrammSectionFormDataType
}

const SectionForm: FC<ISectionFormData> = ({ submitFormHandler, defaultValues }) => {
    const { name, description } = defaultValues;
    const navigate = useNavigate();
    const { uuidSection } = useParams();
    const methods = useForm<IProgrammSectionFormDataType>({
        defaultValues,
        resolver: yupResolver(formSchema)
    });

    const { control, handleSubmit, setValue } = methods;

    useEffect(() => {
        setValue('name', name);
        setValue('description', description);
    }, [defaultValues]);

    const onSubmit = handleSubmit((formData: IProgrammSectionFormDataType) => {
        submitFormHandler(formData);
    });

    const cancelHandler = () => {
        if (uuidSection) {
            navigate('./../..');
        } else {
            navigate('./..');
        }
    };

    return (
        <Grid>
            <Column size={10}>
                <Paper className={style.paper}>
                    <form onSubmit={onSubmit}>
                        <FormProvider {...methods}>
                            <div>
                                <div className={cx(style.fieldset, style['fieldset--border_line'])}>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <label htmlFor="field-title-id">
                                            Название*
                                        </label>
                                        <InputControl
                                            name="name"
                                            placeholder="Название"
                                            id="field-title-id"
                                        />
                                    </div>
                                    <div className={style.input_wrapper}>
                                        <p>
                                            Описание
                                        </p>
                                        <TinyEditor name="description" control={control} />
                                    </div>
                                </div>
                                <div className={style.fieldset} style={{ paddingTop: '24px' }}>
                                    <div className={style.input_wrapper}>
                                        <div></div>
                                        <div className={style.form_content__button_wrapper}>
                                            <Button type="submit">Сохранить</Button>
                                            <Button
                                                btnType="secondary"
                                                onClick={cancelHandler}
                                            >
                                                Отмена
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormProvider>
                    </form>
                </Paper>
            </Column>
        </Grid>
    );
};

export default SectionForm;
