import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import Main from '@components/Main';
import { findCategory } from '@helpers/categories';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import { useCreateDisciplineMutation } from '@store/api/disciplinesApi';
import CreateCourseForm from '@views/methodist/Materials/Course/CreateCourse/CreateCourseForm';
import style from '@views/methodist/Materials/Course/CreateCourse/CreateCourseForm/create_course_form.module.scss';
import { CreateCourseFormData } from '@views/methodist/Materials/Course/CreateCourse/CreateCourseForm/type';

const CreateCoursePage = () => {
    const { uuidCategory } = useParams();
    const navigate = useNavigate();

    const categoriesQueryData = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000');

    const category = findCategory(categoriesQueryData.data, uuidCategory);

    const [createCourse, { data, isLoading, isError, error, isSuccess }] = useCreateDisciplineMutation();

    const createCourseSubmit = (formData: CreateCourseFormData) => {

        const transformedData = {
            ...formData,
            uuidCategory: uuidCategory,
            uuidsMetodist: formData.uuidsMetodist ? formData.uuidsMetodist.map(item => item.uuid) : [],
            uuidsTeacher: formData.uuidsTeacher ? formData.uuidsTeacher.map(item => item.uuid) : [],
            uuidsDouble: formData.uuidsDouble ? formData.uuidsDouble.map(item => item.uuid) : []
        };

        createCourse(transformedData);
    };

    useEffect(() => {
        if (isSuccess) {
            navigate(`./../${data?.uuid}`);
        }
    }, [isLoading, data, isSuccess]);

    return (
        <>
            <Main title={category && `Учебные материалы – ${category.name}: Добавление нового курса`}>
                <div className={style.title_wrapper}>
                    <ButtonIcon
                        typeIcon="arrowLink"
                        onClick={() => navigate(-1)}
                    />
                    <h1>Новый курс</h1>
                </div>

                <CreateCourseForm
                    createCourseSubmit={createCourseSubmit}
                />
            </Main>
        </>
    );
};

export default CreateCoursePage;
