import React from 'react';

import { Outlet } from 'react-router-dom';

import style from './ViewCourseTemplate.module.scss';

export const ViewCourseTemplate = () => {

    return (
        <div className={style.container}>
            <div className={style.content}>
                <Outlet />
            </div>
        </div>
    );
};
