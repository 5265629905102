import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Outlet, useLocation, useOutletContext } from 'react-router-dom';

import { TreeSelectOptions } from '@components/core/inputs/Select/type';
import { StringOptionType } from '@components/core/inputs/SelectStyled/type';
import { NewLoader } from '@components/core/NewLoader';
import { getTreeDisciplinesByCategory } from '@helpers/disciplines';
import { findDefaultValueOption, getTransformGroupData } from '@helpers/groups';
import { Event, ICategory, IDiscipline } from '@store/api/apiTypes';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import { useGetDisciplinePaginationQuery } from '@store/api/disciplinesApi';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useGetUsersByRoleQuery } from '@store/api/usersApi';
import { Role } from '@store/Roles';
import { User } from '@type/users';
import { CreateEventFormData } from '@views/methodist/Events/CreateEvent/CreateBasicEvent/CreateEventForm/type';


type FormStateType = {
    formContext: CreateEventFormData;
    setFormContext: Dispatch<SetStateAction<CreateEventFormData>>;
    teacherOptions: StringOptionType[];
    groupOptions: TreeSelectOptions;
    disciplineOptions: StringOptionType[];
    categoriesData: ICategory[]
};

export interface NavigationEventState {
    currentEvent?: Event;
    pathname: string,
    actionType: 'create' | 'duplicate' | 'edit'
    today?: string,
}

export const useEditEventFormContext = () => {
    return useOutletContext<FormStateType>();
};

const getFlat = (categories: ICategory[]): ICategory[] => categories.map(cat => [cat, ...getFlat(cat.children)]).flat();


const CreateEvent = () => {
    const location = useLocation();
    const navigationState = location.state as NavigationEventState || null;
    const currentEvent = navigationState?.currentEvent || null;

    const groupsTree = useGetGroupsTreeQuery('');
    const disciplines = useGetDisciplinePaginationQuery({ offset: 0, limit: 0 });
    const teachers = useGetUsersByRoleQuery(Role.Teacher);
    const { categoriesData, isCategoriesLoading, isCategoriesSuccess } = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000', {
        selectFromResult: ({ data, isLoading, isSuccess }) => ({
            categoriesData: data ?? [],
            isCategoriesLoading: isLoading,
            isCategoriesSuccess: isSuccess
        })
    });

    const [teacherOptions, setTeacherOptions] = useState<StringOptionType[]>([]);
    const [disciplineOptions, setDisciplineOptions] = useState<StringOptionType[]>([]);
    const [groupOptions, setGroupOptions] = useState<TreeSelectOptions>([]);
    const [blockedGroups, setBlockedGroups] = useState<string[]>([]);

    const categories: ICategory[] = getFlat(categoriesData);

    const [formContext, setFormContext] = useState<CreateEventFormData>({
        name: '',
        blockedGroups: [],
        discipline: '',
        teacher: {} as StringOptionType,
        event: { label: 'Вебинар', value: 'webinar' },
        hours: undefined,
        minutes: undefined,
        date: '',
        link: '',
        videoLink: '',
        fileUpload: [],
        accessLimitation: false
    });

    useEffect(() => {
        setTeacherOptions(
            teachers.data
                ?
                teachers.data?.map((user: User) => (
                    {
                        label: `${user.lastName} ${user.firstName} ${user.patronymic}`,
                        value: user.uuid
                    }
                ))
                :
                []
        );
    }, [teachers.isSuccess]);

    useEffect(() => {
        setGroupOptions(getTransformGroupData(groupsTree.data?.childs || []));
    }, [groupsTree.isSuccess]);

    useEffect(() => {
        if (currentEvent) {
            setBlockedGroups(findDefaultValueOption(groupOptions, currentEvent.excludedGroups));
        }
    }, [groupOptions, currentEvent]);

    useEffect(() => {
        setDisciplineOptions(
            disciplines.data
                ?
                disciplines.data.map((discipline: IDiscipline) => {
                    const categoryName = categories.find(cat => cat.uuid === discipline.uuidCategory)?.name;

                    return {
                        label: discipline.name + (categoryName ? ` / ${categoryName}` : ''),
                        value: discipline.uuid
                    };
                })
                :
                []
        );
    }, [disciplines.isSuccess, isCategoriesSuccess]);

    useEffect(() => {
        if (currentEvent) {
            setFormContext({
                name: navigationState?.actionType === 'duplicate' ? `${currentEvent.name} (копия)` : currentEvent.name,
                blockedGroups: blockedGroups,
                discipline: currentEvent.disciplineUuid,
                teacher: currentEvent.teacherInfo ? {
                    label: currentEvent.teacherInfo.lastName
                        + ' ' + currentEvent.teacherInfo.firstName
                        + ' ' + currentEvent.teacherInfo.patronymic,
                    value: currentEvent.teacherInfo.uuid
                } : {} as StringOptionType,
                event: { label: 'Вебинар', value: 'webinar' },
                hours: navigationState?.actionType === 'create' ? 1 : Math.floor(currentEvent.duration / 60),
                minutes: navigationState?.actionType === 'create' ? 30 : currentEvent.duration - Math.floor(currentEvent.duration / 60) * 60,
                date: currentEvent.startEventAt,
                link: currentEvent.links[0],
                videoLink: currentEvent.linkToRecords[0],
                fileUpload: currentEvent.files,
                accessLimitation: !!blockedGroups.length
            });
        }
    }, [blockedGroups]);


    if (teachers.isLoading || groupsTree.isLoading || disciplines.isLoading || isCategoriesLoading)
        return <NewLoader />;


    const disciplinesDataByCategory = disciplines.data ? getTreeDisciplinesByCategory(categoriesData, disciplines.data) : [];

    return (
        <>
            {
                <Outlet context={{
                    formContext,
                    setFormContext,
                    teacherOptions,
                    groupOptions,
                    disciplineOptions,
                    categoriesData: disciplinesDataByCategory
                }} />
            }
        </>
    );
};

export default CreateEvent;

