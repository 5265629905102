import React from 'react';

import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-acc.svg';
import { ReactComponent as ArrowLeftIcon } from '@assets/icons/arrow-left.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg';
import { ReactComponent as MenuIcon } from '@assets/icons/menu.svg';
import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { LinkUI } from '@components/core/LinkUI';
import ContactsBlock from '@components/Footer/ContactsBlock';
import { SSOLink } from '@components/Linkify/SSOLink';
import Logo from '@components/Logo';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { useToggle } from '@hooks/useToggle.hook';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';

import style from './Drawer.module.scss';

type Tab = {
    name: string;
    path: string;
    disabled?: boolean;
}

interface DrawerProps {
    tabs: Tab[];
}

export const Drawer: React.FC<DrawerProps> = ({ tabs }) => {
    const navigate = useNavigate();
    const [isOpen, { on, off }] = useToggle();
    const [moreIsOpen, { on: onOpenMore, off: onCloseMore }] = useToggle();

    const user = useAppSelector(selectApiUser);

    const clickScoreCard = () => {
        navigate('/record-book');
        off();
    };

    return (
        <>
            <ButtonIcon onClick={on}><MenuIcon /></ButtonIcon>
            <SwipeableDrawer anchor="left" open={isOpen} onClose={off} onOpen={on}>
                <div className={style.container}>
                    <div>
                        <Logo isLarge className={style.logo} />
                        <ButtonIcon className={style.close} onClick={off}>
                            <CloseIcon />
                        </ButtonIcon>

                        <div className={clsx(style.content, moreIsOpen && style['more-opened'])}>
                            <div className={style.main}>
                                {user?.role === Role.Student && <Button btnType="white" className={style.button} onClick={clickScoreCard}>Моя зачетка</Button>}
                                <ul className={style.list}>
                                    {tabs.map(tab => (
                                        <li key={tab.path}>
                                            <LinkUI className={style.link} to={tab.path} onClick={off}>{tab.name}</LinkUI>
                                        </li>
                                    ))}
                                    {checkRole(Role.Student) && (
                                        <li className={clsx(style.link, style.more)} onClick={onOpenMore}>
                                            Еще
                                            <ArrowIcon className={style.arrow} />
                                        </li>
                                    )}
                                </ul>
                            </div>

                            <RoleCheck roles={Role.Student}>
                                <div className={style['more-container']}>
                                    <ArrowLeftIcon className={style['more-back']} onClick={onCloseMore} />

                                    <h3>Онлайн библиотеки</h3>
                                    <SSOLink href='biblioclub.ru' value={'Университетская библиотека онлайн'} domain="biblioclub.ru"></SSOLink>
                                </div>
                            </RoleCheck>

                        </div>


                    </div>
                    <ContactsBlock className={style.contacts} />
                </div>
            </SwipeableDrawer>
        </>
    );
};
