import { FC } from 'react';

import cx from 'classnames';

import { ReactComponent as Loader } from '@assets/icons/bars-rotate-loader.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg';
import { IStorageFile } from '@store/api/apiTypes';

import DateFormat from '../DateFormat';
import style from './MessagePhoto.module.scss';

export interface IMessagePhotoProps {
    time: string;
    file?: IStorageFile;
    own: boolean;
    loading: boolean;
    error: boolean;
    onImageLoaded: () => void;
}

const MessagePhoto:FC<IMessagePhotoProps> = ({
    time,
    file,
    own,
    loading,
    error,
    onImageLoaded
}) => {

    return (
        <div className={cx(style.container, own && style.own)}>
            <div className={style.inner}>
                <img src={file?.path} alt="Chat" className={style.photo} onLoad={onImageLoaded} />

                <div className={style.time}>
                    <DateFormat time={time} theme={'photo'} />
                </div>

                {loading && !error && <Loader className={style.loader} />}
                {error && <CloseIcon className={style['error-icon']} />}
            </div>
        </div>
    );
};

export default MessagePhoto;
