import { TreeSelectOption } from '../NewTreeSelect';

export const filterOptions = (optionsArr: TreeSelectOption[], text: string): TreeSelectOption[] => {
    const result: TreeSelectOption[] = [];

    optionsArr.forEach((option, index) => {
        if (option.label.toLowerCase().includes(text.toLowerCase())) {
            return result.push(option);
        }
        const foundOptions = filterOptions(option.children, text);
        if (foundOptions.length) {
            const filteredOption = {
                ...option,
                index,
                children: foundOptions
            };
            result.push(filteredOption);
        }
    });

    return result;
};

export const filterOptionsByList = (optionsArr: TreeSelectOption[], list: string[], type: 'white' | 'black' = 'white'): TreeSelectOption[] => {
    const result: TreeSelectOption[] = [];

    optionsArr.forEach((option, index) => {
        const isIncludes = list.includes(option.value);

        if (isIncludes) {
            if (type === 'white') {
                return result.push(option);
            }
            return;
        }
        const foundOptions = filterOptionsByList(option.children, list, type);
        if (foundOptions.length || type === 'black') {
            const filteredOption = {
                ...option,
                index,
                children: foundOptions
            };
            result.push(filteredOption);
        }
    });

    return result;
};
