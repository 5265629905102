import { Dispatch, FC, SetStateAction } from 'react';

import cx from 'classnames';
import { isBefore } from 'date-fns';
import { useParams } from 'react-router-dom';

import Button from '@components/core/inputs/Button';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { datePrepareToSend } from '@helpers/date';
import { areDeadlinesCorrect } from '@helpers/deadlines';
import { getFlatGroupsWithEndsChildren } from '@helpers/groups';
import { isEmptyObject } from '@helpers/isEmptyObject';
import { useToggle } from '@hooks/useToggle.hook';
import { IGroupDeadlinesApiResponse } from '@store/api/apiTypes';
import {
    useDeleteMaterialsDeadlinesMutation,
    useGetGroupsTreeQuery,
    useSetGroupDeadlinesMutation,
    useSetUserDeadlinesMutation,
    useDeleteStudentsMaterialsDeadlinesMutation
} from '@store/api/groupsApi';
import { deadlineItems, deadlinesPresense, selectedDeadlines } from '@store/features/deadlinesSlice';
import {  useAppSelector } from '@store/hooks';

import { TTermsModalType } from '../TermsModal';
import style from './terms_controls.module.scss';


export interface ITermsControls {
    onClose: () => void;
    nextPage: boolean;
    setNextPage: Dispatch<SetStateAction<boolean>>;
    type: TTermsModalType;
    localStudents: string[];
    isSetupMode: boolean;
    hideParentModal: (isHide: boolean) => void;
}

export const TermsControls: FC<ITermsControls> = ({
    onClose,
    type,
    localStudents,
    nextPage,
    setNextPage,
    isSetupMode,
    hideParentModal
}) => {
    const { uuidGroup } = useParams();

    const [isOpen, { on, off }] = useToggle(false);

    const [setGroupDeadlines] = useSetGroupDeadlinesMutation();
    const [setUserDeadlines] = useSetUserDeadlinesMutation();
    const [deleteDeadlines] = useDeleteMaterialsDeadlinesMutation();
    const [deleteIndividualDeadlines] = useDeleteStudentsMaterialsDeadlinesMutation();

    const selectedMaterials = useAppSelector(deadlineItems);
    const deadlinesToSet = useAppSelector(selectedDeadlines);
    const areDeadlinesPresent = useAppSelector(deadlinesPresense);

    const groupsTreeQueryData = useGetGroupsTreeQuery('');

    const toggleNextPage = () => setNextPage(true);

    const submitDeadlines = async () => {
        let materialsToSend: Omit<IGroupDeadlinesApiResponse, 'deadline'>[] = [];
        for (const key in selectedMaterials) {
            if (Object.prototype.hasOwnProperty.call(selectedMaterials, key)) {
                materialsToSend = [...materialsToSend, ...selectedMaterials[key]];
            }
        }

        const convertedDeadlines = {
            deadlineStart: deadlinesToSet.deadlineStart ? datePrepareToSend(deadlinesToSet.deadlineStart) : '',
            deadlineEnd: deadlinesToSet.deadlineEnd ? datePrepareToSend(deadlinesToSet.deadlineEnd) : ''
        };

        if (type === 'group') {
            const groupMapWithEndChildren = groupsTreeQueryData.data ? getFlatGroupsWithEndsChildren(groupsTreeQueryData.data.childs) : null;
            const currentGroup = groupMapWithEndChildren?.[uuidGroup as string];
            const endChildren = currentGroup?.endChildren || [];

            await setGroupDeadlines({
                groupUUID: uuidGroup as string,
                materials: materialsToSend,
                deadline: convertedDeadlines
            });

            // Если есть флаг наследования у текущей группы и есть кому наследовать
            if (currentGroup?.isInherit && endChildren.length) {
                await Promise.all(endChildren.map(childGroup => {
                    return setGroupDeadlines({
                        groupUUID: childGroup.UUID,
                        materials: materialsToSend,
                        deadline: convertedDeadlines
                    });
                }));
            }
        } else if (localStudents.length) {
            await setUserDeadlines({
                groupUUID: uuidGroup as string,
                userUUID: localStudents,
                materials: materialsToSend,
                deadline: convertedDeadlines
            });
        }

        onClose();
    };

    const onDeadlineDelete = () => {
        let materialsToDelete: string[] = [];
        for (const key in selectedMaterials) {
            if (Object.prototype.hasOwnProperty.call(selectedMaterials, key)) {
                materialsToDelete = [...materialsToDelete, ...selectedMaterials[key].map(material => material.materialUUID)];
            }
        }

        if (type === 'group') {
            deleteDeadlines({
                groupUUID: uuidGroup as string,
                materialsUUID: materialsToDelete
            });
        } else {
            console.log('delete individual deadlines', {
                groupUUID: uuidGroup,
                materialsUUID: materialsToDelete,
                studentsUUID: localStudents
            });
            //  TODO удаление индивидуальных сроков сдачи
            deleteIndividualDeadlines({
                groupUUID: uuidGroup,
                materialsUUID: materialsToDelete,
                studentsUUID: localStudents
            });
        }

        off();
        onClose();
        hideParentModal(false);
    };

    const onDeleteModalOpen = () => {
        on();
        hideParentModal(true);
    };

    const onDeleteModalClose = () => {
        off();
        hideParentModal(false);
    };

    const renderBtn = () => {
        if (!isSetupMode) return (
            <Button btnType="danger" disabled={isEmptyObject(selectedMaterials)} onClick={onDeleteModalOpen}>
                Удалить
            </Button>
        );

        if (nextPage) return (
            <Button disabled={!areDeadlinesCorrect(areDeadlinesPresent, deadlinesToSet)} btnType="success" onClick={submitDeadlines}>Установить</Button>
        );

        return (
            <Button disabled={isEmptyObject(selectedMaterials)} onClick={toggleNextPage}>Далее</Button>
        );
    };

    return <div className={cx(style.actions, !isSetupMode && style['actions--no_steps'], !nextPage && style['actions--shadow'])}>
        {
            isSetupMode && <div className={style.steps}>
                Шаг { !nextPage ? '1' : '2' } из 2
            </div>
        }

        <div className={style.btns}>
            <Button btnType="secondary" onClick={onClose}>Отмена</Button>
            {renderBtn()}
        </div>

        <DeleteItemModal
            itemName="deadlines"
            isOpen={isOpen}
            onClose={onDeleteModalClose}
            itemType="deadlines"
            onDelete={onDeadlineDelete}
            description="Данное действие нельзя будет отменить."
        />
    </div>;
};
