import React, { useMemo } from 'react';

import { DNDWrapper } from '@components/core/DNDWrapper';
import { Column, Grid } from '@components/core/grid';
import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import Paper from '@components/core/styledWrappers/Paper';
import { useToggle } from '@hooks/useToggle.hook';
import { useAddPortfolioSectionTypeMutation, useGetPortfolioSectionsListQuery, useUpdatePositionsPortfolioSectionByIdMutation } from '@store/api/portfolioApi';
import { PortfolioSectionType } from '@type/portfolio';
import AddPortfolioSectionModal, {
    AddSectionForm
} from '@views/methodist/AdditionalSettings/AdditionalSettingsLayout/PortfolioSettings/AddPortfolioSectionModal/AddPortfolioSectionModal';
import PortfolioSection from '@views/methodist/AdditionalSettings/AdditionalSettingsLayout/PortfolioSettings/PortfolioSection';

import style from './PortfolioSetting.module.scss';

const PortfolioSettings = () => {
    const [isOpenedDetails, { on, off }] = useToggle();
    const { data: portfolioSectionsTypeList, isLoading: isLoadingPortfolioSections } = useGetPortfolioSectionsListQuery();
    const [fetchAddSection] = useAddPortfolioSectionTypeMutation();
    const [fetchUpdatePosition] = useUpdatePositionsPortfolioSectionByIdMutation();
    const onOpenSectionModal = () => {
        on();
    };

    const transformSectionsTypeList: PortfolioSectionType[] = useMemo(() => {
        return portfolioSectionsTypeList?.filter((target: PortfolioSectionType) => target.active) || [];
    }, [portfolioSectionsTypeList]);
    const updatePositionsSectionsHandler = (startIndex: number, endIndex: number) => {
        const findSection = portfolioSectionsTypeList?.find(target => target.position === startIndex + 1);
        if (findSection) {
            fetchUpdatePosition({ uuid: findSection.uuid, position: endIndex + 1 });
        }
    };

    const addSectionSubmit = (formData: AddSectionForm) => {
        const transformData = {
            typeName: formData.typeName,
            active: true
        };

        fetchAddSection(transformData);
    };

    if (isLoadingPortfolioSections) return <NewLoader />;

    return (
        <React.Fragment>
            <Grid>
                <Column size={10}>
                    <Paper className={style.paper}>
                        <h2 className={style.title}>Портфолио</h2>
                        <div className={style.list}>
                            {
                                transformSectionsTypeList && (
                                    <DNDWrapper items={transformSectionsTypeList} setCurrentOrder={updatePositionsSectionsHandler}>
                                        {transformSectionsTypeList?.map(section => (
                                            <PortfolioSection section={section} key={section.uuid} />
                                        ))}
                                    </DNDWrapper>
                                )
                            }
                        </div>
                        <Button
                            onClick={onOpenSectionModal}
                            className={style.button}
                        >
                            <IconUI typeIcon="add" />
                            Добавить раздел
                        </Button>
                    </Paper>
                </Column>
            </Grid>
            {isOpenedDetails && (
                <AddPortfolioSectionModal
                    title="Добавить раздел"
                    opened={isOpenedDetails}
                    onClose={off}
                    onOpen={on}
                    submitFormData={addSectionSubmit}
                />
            )}
        </React.Fragment>
    );
};

export default PortfolioSettings;
