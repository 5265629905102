export const baseConfig = {
    language: 'ru',
    language_url: '/langs/ru.js',
    width: 'auto',
    height: 400,
    resize: true,
    placeholder: '',
    resize_img_proportional: true,
    automatic_uploads: false,
    autosave_ask_before_unload: true,
    powerpaste_allow_local_images: true,
    plugins: ['advlist', 'anchor', 'autolink', 'codesample', 'fullscreen', 'help',
        'image', 'tinydrive', 'lists', 'link', 'media', 'preview',
        'searchreplace', 'table', 'template', 'visualblocks', 'wordcount', 'code', 'template-plugin'
    ],
    
    toolbar1: 'insertfile template-plugin a11ycheck undo redo | bold italic underline | forecolor backcolor | fontfamily fontsize| alignleft aligncenter alignright alignjustify | bullist numlist | link image | code',
    //toolbar2:  template_codes.map(item => item.value).join(' '),
    toolbar_mode: 'wrap',


    contextmenu: false,
    extended_valid_elements: 'svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*],ellipse[*],polygon[*],polyline[*],linearGradient[*],radialGradient[*],stop[*],image[*],view[*],text[*],textPath[*],title[*],tspan[*],glyph[*],symbol[*],switch[*],use[*]',
    spellchecker_dialog: true,
    spellchecker_ignore_list: ['Ephox', 'Moxiecode'],
    //setup: (outerEditor : any) => registerTemplatePlugin(outerEditor),
    font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
    font_family_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Mulish="Mulish", sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
    // @ts-ignore
    paste_postprocess: (editor, args) => {
        // @ts-ignore
        args.node.querySelectorAll('[style]').forEach(item => {
            item.style.fontSize = '14px';
            item.style.fontFamily = 'Mulish, sans-serif';
        });
    },
    content_style:
        '@import url(\'https://fonts.googleapis.com/css2?family=Mulish:wght@500;700;800&display=swap\'); body { font-family: "Mulish", sans-serif !inherit; font-size:14px; } svg { content: sdcsdc}'
};
