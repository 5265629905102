type Query = Record<string, string>;

// eslint-disable-next-line @typescript-eslint/ban-types
export const isEmpty = <T = object>(obj?: T | {} | null | undefined): obj is {} | null | undefined => {
    return !obj || Object.keys(obj).length === 0;
};

export const stringifyQuery = (query: Query): string => {
    return Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&');
};

export const compileUrl = <T = Query>(path: string, query?: T) =>
    `${path}${!isEmpty(query) ? '?' + stringifyQuery(query) : ''}`;
