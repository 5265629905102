import { FC } from 'react';

import InputBase from '@components/core/inputs/InputBase/InputBase';

import style from './Search.module.scss';

export interface ISearchBarProps {
    onChange?: (value: string) => void;
    value: string;
}

const Search: FC<ISearchBarProps> = ({ onChange, value }) => {

    const onChangeW = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (!onChange) return;
        onChange(e.target.value);
    };

    return <div className={style.container}>
        <InputBase
            variant="search"
            placeholder="Поиск"
            value={value}
            name="searchbar"
            onChange={onChangeW} />
    </div>;
};

export default Search;
