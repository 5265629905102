import React, { FC, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { useParams } from 'react-router-dom';

import { ReactComponent as EmptySectionIcon } from '@assets/icons/empti-section-icon.svg';
import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import { DataList, DataListCell } from '@components/DataList';
import { Pagination, usePagination } from '@components/Pagination';
import { checkRole } from '@components/RoleCheck';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetPortfolioFilesListQuery } from '@store/api/portfolioApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { PortfolioFile } from '@type/portfolio';
import AddFileModal from '@views/student/StudentProfile/Portfolio/AddFileModal';
import FileListItem from '@views/student/StudentProfile/Portfolio/FilesList/FileListItem';

import style from './FilesList.module.scss';

interface IProps {
    typeUuid: string;
}

const FilesList: FC<IProps> = ({ typeUuid }) => {
    const mdDown = useBreakpoint('md', 'down');
    const [isOpenAddFileModal, setIsOpenAddFileModal] = useState<boolean>(false);
    const { userId } = useParams();
    const user = useAppSelector(selectApiUser);

    const { data: portfolioFilesList, isLoading: isLoadingFilesList } = useGetPortfolioFilesListQuery(
        (user && typeUuid) ? {
            userUuid: (user?.role === Role.Methodist || user?.role === Role.Teacher) ? userId as string : user?.uuid || '',
            typeUuid: typeUuid
        } : skipToken);

    const transformFilesList: PortfolioFile[] = useMemo(() => {
        return portfolioFilesList?.filter((target: PortfolioFile) => target.active) || [];
    }, [portfolioFilesList]);

    const { handleSliceData, setTotal, paginationProps } = usePagination<any>({});

    const openAddFileModalHandler = () => {
        setIsOpenAddFileModal(true);
    };
    const getEmptyMessage = () => {
        return (
            <div className={style.empty_message}>
                <EmptySectionIcon className={style.empty_message__icon} />
                <p className={style.empty_message__text}>Здесь пока ничего нет</p>
                {checkRole([Role.Methodist, Role.Student]) && (
                    <Button
                        onClick={openAddFileModalHandler}
                    >
                        Добавить файл</Button>
                )}
            </div>
        );
    };

    useEffect(() => {
        setTotal(transformFilesList?.length || 0);
    }, [transformFilesList]);

    if (isLoadingFilesList) return <NewLoader />;

    return (
        <>
            <DataList className={style.list_wrapper}>
                {
                    !mdDown && (
                        <Grid gridTemplateColumns="40% 20% 40%" className={style.data_list__header}>
                            <>
                                <Column>
                                    <DataListCell className={style.column_label}>Название</DataListCell>
                                </Column>
                                <Column>
                                    <DataListCell className={style.column_label}>Формат</DataListCell>
                                </Column>
                                <Column>
                                    <DataListCell className={cx(style.column_label)}>Дата загрузки</DataListCell>
                                </Column>
                            </>
                        </Grid>
                    )
                }

                {
                    !transformFilesList?.length ?
                        <div className={style.empty_message}>{getEmptyMessage()}</div>
                        :
                        handleSliceData(transformFilesList).map(file => (
                            <FileListItem file={file} key={file.uuid} />
                        ))
                }
            </DataList>

            <Pagination className={style.pagination} {...paginationProps} />

            <AddFileModal isOpen={isOpenAddFileModal} onClose={setIsOpenAddFileModal} />
        </>
    );
};

export default FilesList;
