import { toast } from 'react-toastify';

import { CreatePageData, CreatePageDataResponse, GetPageDataResponse, UpdatePageData, UpdatePageDataResponse, Uuid } from '@store/api/apiTypes';

import { emptyBaseApi } from './emptyBaseApi';

export const pageApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getPageById: build.query<GetPageDataResponse, Uuid>({
            query: (uuidPage: Uuid) => `disciplines/materials/page/find/${uuidPage}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить страницу');
                }
            },
            transformResponse: (result: GetPageDataResponse) => {
                return result;
            },
            providesTags: (result, error, arg) => [{ type: 'Material', id: `${arg}` }]
        }),
        createPage: build.mutation<CreatePageDataResponse, CreatePageData>({
            query(data) {
                return {
                    url: 'disciplines/materials/page/create',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Страница создана',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => ['SectionList', { type: 'Material', id: `List_${arg.uuidParent}` }]
        }),
        updatePage: build.mutation<UpdatePageDataResponse, UpdatePageData>({
            query(data) {
                return {
                    url: `disciplines/materials/page/update/${data.uuid}`,
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Страница обновлена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => [
                'SectionList', 
                { type: 'Material', id: `List_${arg.uuidParent}` }, 
                { type: 'Material', id: `${arg.uuid}` }
            ]
        })
    })
});

export const { useGetPageByIdQuery, useCreatePageMutation, useUpdatePageMutation } = pageApi;
