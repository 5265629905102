import { FC, useState } from 'react';


import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { LectureChapter } from '@store/api/apiTypes';

import style from './chapter_item.module.scss';

interface ChapterItemProps {
    chapterItem: LectureChapter;
    onDelete: (uuid: string) => void;
    disabled?: boolean;
}

export const ChapterItem: FC<ChapterItemProps> = ({ chapterItem, onDelete, disabled }) => {
    const { uuidChapter } = useParams();
    const navigate = useNavigate();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const displayDeleteModal = () => {
        setShowDeleteModal(true);
    };

    return (
        <>
            <li
                key={chapterItem.uuid}
                className={cx(
                    style.item,
                    {
                        [style.active]: uuidChapter === chapterItem.uuid,
                        [style['item--disabled']]: disabled
                    }
                )}
            >
                <span
                    onClick={() => {navigate(`./chapter/${chapterItem.uuid}`);}}
                    className={cx(style.span, uuidChapter === chapterItem.uuid ? style.active : '')}
                >
                    {chapterItem.name}
                </span>
                {!disabled && (
                    <ButtonIcon
                        className={style.delete_button}
                        typeIcon={'basket'}
                        onClick={displayDeleteModal}
                    />
                )}
            </li>

            <DeleteItemModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onDelete={() => onDelete(chapterItem.uuid)}
                itemName={chapterItem.name}
                itemType={'lectureChapter'}
            />
        </>

    );
};
