import React from 'react';

import { CalendarProvider } from '@components/EventsFilters/context/provider';

import FinishedEvents from '../FinishedEvents';

export const FinishedEventsWrapper = () => {
    return (
        <CalendarProvider>
            <FinishedEvents />
        </CalendarProvider>
    );
};
