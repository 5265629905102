import React, { FC, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { ReactComponent as InfoIcon } from '@assets/icons/info-icon.svg';
import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import { FileUploadButtonControl } from '@components/core/inputs/FileUpload/FileUploadButtonControl';
import InputControl from '@components/core/inputs/InputControl';
import NewTreeSelectControl, { transformDataToOptions } from '@components/core/inputs/NewTreeSelectControl';
import SwitcherControl from '@components/core/inputs/Switcher/SwitcherControl';
import Paper from '@components/core/styledWrappers/Paper';
import TinyEditor from '@components/methodist/TinyEditor';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { Tooltip } from '@components/Tooltip/Tooltip';
import { maxSizeUploadFile } from '@constants/constants';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { GroupTree } from '@store/api/apiTypes';
import { Role } from '@store/Roles';
import { TaskFormData } from '@views/methodist/Materials/Course/EditCourse/Structure/TaskForm/type';

import style from './TaskForm.module.scss';


const tooltipText = 'При выбранном значении 0% все загруженные контрольные работы студентов попадут в сервис Антиплагиат и к преподавателю на проверку';

export const createTaskFormSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    content: yup.string().default(''),
    files: yup.array(yup.mixed().label('File')).max(10, 'Максимальное кол-во файлов 10'),
    maxRating: yup.string().required('Поле обязательно для заполнения!').matches(/^[0-9]+$/)
        .test('len', 'Максимальная оценка 100 баллов!', (val) => Number(val) <= 100),
    accessLimitation: yup.boolean(),
    needPlagiarismCheck: yup.boolean(),
    minUniquenessPercent: yup.number()
        .when('needPlagiarismCheck', {
            is: true,
            then: yup.number().required('Поле обязательно для заполнения!').transform(value => (isNaN(value) ? undefined : value)),
            otherwise: yup.number().notRequired().transform(() => undefined)
        }),
    blockedGroups: yup.array().of(yup.string().required()).when('accessLimitation', {
        is: true,
        then: yup.array().min(1, 'Выберите группу')
    })
});

interface ITaskFormData {
    submitFormHandler: (formData: TaskFormData) => void;
    defaultValues: TaskFormData;
    groups?: GroupTree;
}

const TaskForm: FC<ITaskFormData> = ({ submitFormHandler, defaultValues, groups }) => {
    const { name, content, files, maxRating, accessLimitation, blockedGroups, minUniquenessPercent, needPlagiarismCheck } = defaultValues;
    const { uuidTask } = useParams();
    const navigate = useNavigate();
    const methods = useForm<TaskFormData>({
        defaultValues,
        resolver: yupResolver(createTaskFormSchema)
    });
    const { control, handleSubmit, setValue, watch } = methods;

    const onSubmit = handleSubmit((formData) => {
        formData.accessLimitation || delete formData.blockedGroups;
        formData.needPlagiarismCheck || delete formData.minUniquenessPercent;

        submitFormHandler(formData);
    });

    const groupOptions = useMemo(() => groups
        ? transformDataToOptions(groups.childs, {
            value: 'UUID',
            label: 'name',
            children: 'childs'
        })
        : [], [groups]);

    const cancelHandler = () => {
        if (uuidTask) {
            navigate('./../../..');
        } else {
            navigate('./../..');
        }
    };
    const watchAccessLimitation = watch('accessLimitation');
    const watchNeedPlagiarismCheck =watch('needPlagiarismCheck');

    useEffect(() => {
        setValue('name', name);
        setValue('content', content);
        setValue('files', files);
        setValue('maxRating', maxRating);
        setValue('accessLimitation', accessLimitation);
        setValue('blockedGroups', blockedGroups);
        setValue('needPlagiarismCheck', needPlagiarismCheck);
        setValue('minUniquenessPercent', minUniquenessPercent);
    }, [defaultValues]);



    const [ratingError, setRatingError] = useState<string | null | undefined>();
    const [uniquenessPercentError, setUniquenessPercentError] = useState<string | null | undefined>();

    useEffect(()=>{
        if(!watchNeedPlagiarismCheck) setUniquenessPercentError(null);
    },[watchNeedPlagiarismCheck]);

    return (
        <Grid>
            <Column size={10}>
                <Paper className={style.paper}>
                    <form onSubmit={onSubmit}>
                        <FormProvider {...methods}>
                            <div>
                                <div className={cx(style.fieldset, style['fieldset--border_line'])}>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <label htmlFor="field-title-id">
                                            Название*
                                        </label>
                                        <InputControl
                                            name="name"
                                            placeholder="Название"
                                            id="field-title-id"
                                            disabled={!checkRole(Role.Methodist)}
                                        />
                                    </div>
                                    <div className={style.input_wrapper}>
                                        <p>
                                            Описание
                                        </p>
                                        <TinyEditor
                                            name="content"
                                            control={control}
                                            disabled={!checkRole(Role.Methodist)}
                                        />
                                    </div>
                                    <div className={cx(style.input_wrapper)}>
                                        <p>Прикрепленный файл</p>
                                        <FileUploadButtonControl
                                            name="files"
                                            control={control}
                                            withoutCheckControl
                                            isMulti={true}
                                            maxSizeFile={maxSizeUploadFile}
                                            className={style.file_wrapper}
                                            disabled={!checkRole(Role.Methodist)}
                                        />
                                    </div>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <label htmlFor="field-score-id">
                                            Максимальная оценка*
                                        </label>
                                        <div className={style.input_score_wrapper}>
                                            <div className={style.input_score_group}>
                                                <InputControl
                                                    name="maxRating"
                                                    type="number"
                                                    id="field-score-id"
                                                    styled={{ width: '100px' }}
                                                    onErrorFire={setRatingError}
                                                    disabled={!checkRole(Role.Methodist)}
                                                />
                                                <span>Баллов</span>
                                            </div>
                                            <div className={style.input_error_container}>
                                                {ratingError}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*Антиплагиат*/}
                                <div className={cx(style.fieldset, style['fieldset--border_line'])} style={{ paddingTop: '24px' }} >
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <label htmlFor="uniqueness-percent-id" className={style.anti_plagiarism_label}>
                                            <span>Антиплагиат</span>
                                            <Tooltip title={tooltipText}><InfoIcon /></Tooltip>
                                        </label>
                                        <div className={style.anti_plagiarism_block}>
                                            <SwitcherControl
                                                name="needPlagiarismCheck"
                                                control={control}
                                                disabled={!checkRole(Role.Methodist)}
                                            />
                                            {watchNeedPlagiarismCheck && (
                                                <div className={style.input_score_group}>
                                                    <InputControl
                                                        name="minUniquenessPercent"
                                                        type="number"
                                                        id='uniqueness-percent-id'
                                                        styled={{ width: '100px' }}
                                                        disabled={!checkRole(Role.Methodist)}
                                                        onErrorFire={setUniquenessPercentError}
                                                    />
                                                    <span>%</span>
                                                </div>
                                            )}
                                            <div className={style.input_error_container}>
                                                {uniquenessPercentError}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*  */}

                                <div className={style.fieldset} style={{ paddingTop: '24px' }}>
                                    <div className={style.input_wrapper}>
                                        <p>Доступ ограничен</p>
                                        <div className={style.permission_block_wrapper}>
                                            <SwitcherControl
                                                name="accessLimitation"
                                                control={control}
                                                disabled={!checkRole(Role.Methodist)}
                                            />
                                            {watchAccessLimitation && (
                                                <div className={style.select_list_wrapper}>
                                                    <NewTreeSelectControl
                                                        className={style.select}
                                                        name="blockedGroups"
                                                        options={groupOptions}
                                                        isMulti={true}
                                                        placeholder="Выберите группы"
                                                        disabled={!checkRole(Role.Methodist)}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <RoleCheck roles={Role.Methodist}>
                                        <div className={style.input_wrapper}>
                                            <div></div>
                                            <div className={style.form_content__button_wrapper}>
                                                <Button type="submit">Сохранить</Button>
                                                <Button
                                                    btnType="secondary"
                                                    onClick={cancelHandler}
                                                >
                                                    Отмена
                                                </Button>
                                            </div>
                                        </div>
                                    </RoleCheck>
                                </div>
                            </div>
                        </FormProvider>
                    </form>
                </Paper>
            </Column>
        </Grid>
    );
};

export default TaskForm;
