import React, { FC, useMemo } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { OptionType } from '@components/core/inputs/SelectStyled/type';
import { useGetUsersByRoleQuery } from '@store/api/usersApi';
import { Role } from '@store/Roles';
import { User }  from '@type/users';
import AddTeachingStaffModal, { AddTeacherForm } from '@views/methodist/Materials/Course/AddTeachingStaffModal/AddTeachingStaffModal';
import ListItem from '@views/methodist/Materials/Course/TeachersCard/ListItem';
import { TeacherModal } from '@views/methodist/Materials/Course/TeachersCard/TeachersCard';

interface IProps {
    showModal: TeacherModal;
    setShowModal: (showModal: boolean) => void;
}

const TeachersList: FC<IProps> = ({
    showModal,
    setShowModal
}) => {
    const { teachers } = useGetUsersByRoleQuery(Role.Teacher, {
        selectFromResult: ({ data }) => ({
            teachers: data ?? []
        })
    });
    const { control } = useFormContext();
    const { fields, remove, append } = useFieldArray({
        name: 'uuidsTeacher',
        control
    });

    const { fields: fieldsDouble, remove: removeDouble, append: appendDouble } = useFieldArray({
        name: 'uuidsDouble',
        control
    });

    const setSelectedTeacherValue = (selectData: AddTeacherForm) => {
        const transformedSelectData = selectData.select.reduce((uuids: { uuid: string }[], item: { value: string, label: string }) => {
            uuids.push({ uuid: item.value });
            return uuids;
        }, []);
        if (showModal === 'teacher') {
            append(transformedSelectData);
        }
        if (showModal === 'secondTeacher') {
            appendDouble(transformedSelectData);
        }
    };

    const deleteUserHandler = (fieldIndex: number | number[]) => {
        remove(fieldIndex);
    };

    const deleteDobleHandler = (fieldIndex: number | number[]) => {
        removeDouble(fieldIndex);
    };

    const filterOptions = useMemo(() => {
        const filterTeachers = teachers?.filter((item: User) => fields?.every((field: any) => field.uuid !== item.uuid));
        const filterTeachersAndDouble = filterTeachers?.filter((item: User) => fieldsDouble?.every((field: any) => field.uuid !== item.uuid));

        return filterTeachersAndDouble?.map((user: User) => (
            {
                label: `${user.lastName} ${user.firstName} ${user.patronymic}`,
                value: user.uuid,
                picture: user.picture
            }
        ));
    }, [fields, fieldsDouble, teachers]);

    return (
        <>
            <ul>
                {fields.map((field, index) => {
                    // @ts-ignore
                    const selectedUser = teachers.find((user) => user.uuid === field.uuid) as User;
                    return selectedUser &&
                        <ListItem
                            key={selectedUser.uuid}
                            user={selectedUser}
                            fieldIndex={index}
                            deleteUser={deleteUserHandler}
                        />;
                })}
                {fieldsDouble.map((field, index) => {
                    // @ts-ignore
                    const selectedUser = teachers.find((user) => user.uuid === field.uuid) as User;
                    return selectedUser &&
                        <ListItem
                            key={selectedUser.uuid}
                            user={selectedUser}
                            fieldIndex={index}
                            deleteUser={deleteDobleHandler}
                            caption="Преподаватель-дублер"
                        />;
                })}
            </ul>
            <AddTeachingStaffModal
                title={showModal === 'teacher' ? 'Добавить преподавателя' : 'Добавить преподавателя-дублера'}
                type={showModal || 'teacher'}
                options={filterOptions as OptionType[]}
                showModal={Boolean(showModal)}
                setShowModal={setShowModal}
                setSelectedTeacherValue={setSelectedTeacherValue}
            />
        </>
    );
};

export default TeachersList;
