import { useEffect } from 'react';

import { toast } from 'react-toastify';

import { ReactComponent as FirstCompetence } from '@assets/image/firstCompetence.svg';
import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import SwitchFade from '@components/SwitchFade';
import { useToggle } from '@hooks/useToggle.hook';
import { useGetPresetsQuery } from '@store/api/skillsApi';

import AddPresetModal from './AddPresetModal';
import styles from './PresetsSettings.module.scss';
import PresetsTable from './PresetsTable';

const PresetsSettings = () => {

    const [isOpen, { on:openModal, off:closeModal }] = useToggle(false);
    const { data, isLoading, isError  } = useGetPresetsQuery(undefined);

    useEffect(()=>{
        if(isError) toast.error('Не удалось получить пресеты');
    },[isError]);

    return (
        <>
            <h1 className={styles.header}>Пресеты</h1>

            <SwitchFade
                className={styles.fullWidth}
                slotIndex={isLoading ? 0 : data?.length ? 1 : 2}
                slots={[
                    <NewLoader key={0} />
                    ,
                    <div className={styles.grid} key={1}>
                        <Button typeIcon='add' className={styles.button} onClick={openModal}>Добавить пресет</Button>
                        <PresetsTable presets={data!} />
                    </div>
                    ,
                    <div className={styles.empty} key={2}>
                        <FirstCompetence />
                        <p className={styles.text}>Создайте свой первый пресет</p>
                        <Button typeIcon='add' className={styles.button} onClick={openModal}>Добавить пресет</Button>
                    </div>
                ]}
            />   

            <AddPresetModal isOpen={isOpen} closeModal={closeModal} />
        </>
    );
};

export default PresetsSettings;
