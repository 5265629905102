import React from 'react';

import { ISSOLinkProps } from '@components/Linkify/SSOLink';

import { User } from './users';

export interface IJSXRender {
    [key: string]: () => React.ReactNode,
}

export interface IJSXRenderClear {
    [key: string]: React.ReactNode,
}

export type ValueOf<T> = T[keyof T];

export enum ENV {
    dev = 'development',
    test = 'test',
    stage = 'stage',
    prod = 'production'
}

export enum SSOEnum {
    urait = 'urait',
    biblio = 'biblio'
}

export type LinkifyFindType = {
    type: string;
    value: string;
    isLink: boolean;
    href: string;
    start: number;
    end: number;
} & Record<string, string | boolean | number>;

export type SSOType = 'urait' | 'biblio'

export type SSOOptionsType = {
    pid: string;
    secret_key: string;
    token: string;
    email: string;
    userUuid?: string;
    domain?: string;
    fname: string;
    lname: string;
    sex?: number;
    time?: number;
    role_id?: number;
    sign?: string;
    redirect_url: string;
}

export interface IFindedLink {
    domain: string,
    value: string,
    startIndex: number,
    endIndex: number
}

export interface IReplaceOptions {
    links: IFindedLink[],
    content: string,
    component: React.FC<ISSOLinkProps>
} 

export interface ILinkOptions {
    domain: string;
    sign: string;
    time: number;
    user_uuid: string;
}