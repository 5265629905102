import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSubscribeItem, TTermsItem } from '@store/api/apiTypes';

import type { RootState } from '@store/store';

interface ITermsState {
    selectedItems: {
        [uuid: string]: TTermsItem
    }
}

const initialState: ITermsState = {
    selectedItems: {}
};

export const termsSlice = createSlice({
    initialState,
    name: 'termsSlice',
    reducers: {
        addItem: (state, action: PayloadAction<TTermsItem>) => {
            state.selectedItems[action.payload.uuid] = action.payload;
        }
    }
});

export default termsSlice.reducer;

//export const selectedItemsCountSelector = (state: RootState) => state.termsSlice;

export const {
    addItem
} = termsSlice.actions;