import { FC } from 'react';

import cx from 'classnames';

import { dateConvert } from '@helpers/date';
import { Role } from '@store/Roles';

import { INavigationQuestions } from '../TestResult';
import style from './QuestionNavigator.module.scss';


interface IQuestionNavigator {
    testQuestions: INavigationQuestions[];
    attemptIndex: number;
    attemptDate: string;
}

export const QuestionNavigator: FC<IQuestionNavigator> = ({ testQuestions, attemptIndex, attemptDate }) => {

    return (
        <div className={style.navigation}>
            <h3>Попытка {attemptIndex + 1}</h3>
            <div className={style.attempt_date}>
                {!!attemptDate && 'Завершено ' + dateConvert(attemptDate.replace(/ \+0000 UTC/, '')).toFormat('dd.LL.yyyy, HH:mm')}
            </div>
            <div className={style.navigation_items}>
                {testQuestions.map((question, index) => (
                    <a href={`#${question.uuid}`}
                        className={cx(style.navigation_item, question.isContainWrongAnswer && style['navigation_item--wrong'])}
                        key={question.uuid}>{++index}
                    </a>
                ))}
            </div>
        </div>
    );
};
