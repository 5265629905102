import { FC } from 'react';

import { LinkUI } from '@components/core/LinkUI';

import style from './GoBackLink.module.scss';

interface IGoBackLink {
    children?: React.ReactNode,
    to: string;
    state?: any;
}

const GoBackLink: FC<IGoBackLink> = ({ children, to, state }) => {
    return (
        <LinkUI
            className={style.link_prev}
            typeIcon={'left-arrow'}
            to={to}
            state={state}
        >
            {children}
        </LinkUI>
    );
};

export default GoBackLink;
