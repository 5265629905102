import { toast } from 'react-toastify';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IBiblioClubRequest, IBiblioClubResponse, IUraitRequest, IUraitResponse } from './apiTypes';
import { emptyBaseApi } from './emptyBaseApi';

const ssoAPI = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getUraitSSO: build.query<IUraitResponse, IUraitRequest>({
            query(data) {
                return {
                    url: '/uraitSSO',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Не получить данные с сервиса urait.ru'
                });
            }
        }),
        getBiblioClubSSOSign: build.query<IBiblioClubResponse, IBiblioClubRequest>({
            query(data) {
                return {
                    url: '/biblioclubSSO',
                    method: 'POST',
                    body: data
                };
            }
        })
    })
});

export const { 
    useLazyGetUraitSSOQuery, 
    useLazyGetBiblioClubSSOSignQuery 
} = ssoAPI;