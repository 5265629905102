import React, { FC } from 'react';

import classNames from 'classnames/bind';

import style from './router_tabs.module.scss';
import Tab from './Tab';

const cx = classNames.bind(style);

type Tab = {
    name: string;
    path: string;
    disabled?: boolean;
}

interface IProps {
    tabs?: Tab[] | undefined;
    tabsType?: 'btns' | 'default';
    className?: string;
    end?: boolean;
    itemsInTab?: string[];
}

const RenderTabs: FC<IProps> = ({ tabs, tabsType = 'default', className, end = true, itemsInTab }) => {

    return (
        <ul className={cx(style.tab_list, tabsType && `tab_list--${tabsType}`, className)}>
            {tabs?.map((item, index) => {
                return <Tab
                    key={index}
                    to={item.path}
                    tabsType={tabsType}
                    end={end}
                >
                    <div className={style.tabs_content}>
                        {item.name}

                        {itemsInTab && itemsInTab[index] &&
                            <div className={'tab_items_count'}>
                                {itemsInTab[index]}
                            </div>
                        }
                    </div>
                </Tab>;
            })}
        </ul>
    );
};

export default RenderTabs;
