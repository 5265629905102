import { createContext, FC, useState } from 'react';
import React from 'react';

import { usePopper } from 'react-popper';

import { ClickAwayListener } from '@mui/base';

import style from './Menu.module.scss';

export interface IMenuProps extends React.HTMLAttributes<HTMLDivElement>  {
    children: React.ReactNode;
    anchorEl: HTMLButtonElement | null;
    isOpen?: boolean | null;
    placement?:  'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';
    onClose: () => void
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const CloseContext = createContext(() => {});

export const Menu:FC<IMenuProps> = ({
    anchorEl,
    children,
    onClose,
    isOpen,
    placement = 'bottom-end'
}) => {

    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

    const { styles, attributes } = usePopper(anchorEl, popperElement, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 12]
                }
            }
        ],
        strategy: 'fixed',
        placement
    });

    return <>
        { isOpen && <ClickAwayListener onClickAway={onClose}>
            <div 
                ref={setPopperElement} 
                className={style.container}
                style={styles.popper}
                {...attributes.popper}>
                
                <CloseContext.Provider value={onClose}>
                    { children }
                </CloseContext.Provider>
            </div>
        </ClickAwayListener> }
    </>;
};