import { FC, useEffect, useState } from 'react';

import Button from '@components/core/inputs/Button';
import { OptionType } from '@components/core/inputs/SelectStyled/type';
import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import Modal from '@components/Modal';
import { TestGetQuestionsFromBankForQuestion, TestWithQuestions } from '@store/api/apiTypes';
import { useGetQuestionsFromBankForQuestionQuery } from '@store/api/testsApi';
import { TOnSelectType } from '@type/table';
import { getQuestionHumanType, TestQuestionWithName } from '@views/methodist/Materials/Course/Test/EditTest/types';

import { QuestionTable } from '../../Questions/QuestionTable';
import style from './manual_question_modal.module.scss';

interface IManualQuestionModalProps {
    title?: string;
    options?: OptionType[];
    test: TestWithQuestions,
    showModal: boolean;
    course_id: string;
    setShowModal: (flag: boolean) => void;
    handleAddQuestions?: (questions: string[]) => void;
}

export const ManualQuestionModal: FC<IManualQuestionModalProps> = ({
    title,
    showModal,
    course_id,
    setShowModal,
    test,
    handleAddQuestions
}) => {
    const [selectCount, setSelectCount] = useState(0);

    const [selectedQuestions, setSelected] = useState<string[]>([]);
    const [questions, setQuestions] = useState<TestQuestionWithName[]>([]);
    const questionsReq: TestGetQuestionsFromBankForQuestion = {
        filters: {
            limit: 0,
            offset: 0,
            search: ''
        },
        uuid: course_id,
        questionId: test.test.uuid
    };

    const { data: questionsData, isFetching, isSuccess, isError } = useGetQuestionsFromBankForQuestionQuery(questionsReq);

    useEffect(() => {
        if (isSuccess) {
            const req_questions: TestQuestionWithName[] = [];
            questionsData.map(q => {
                if (test.questions.find(x => x.question.uuid == q.question.uuid) == undefined) {
                    const rq: TestQuestionWithName = {
                        name: q.question.question,
                        uuid: q.question.uuid,
                        type: getQuestionHumanType(q.question.type)
                    };

                    req_questions.push(rq);
                }
            });

            setQuestions(req_questions);
        }
    }, [isFetching, isSuccess, isError, showModal]);

    const onSelect = (selectObject: TOnSelectType) => {
        const selected_local:string[] = [];

        if (selectObject.selectedUuids.includes('')) {
            setSelected([]);
            setSelectCount(0);
        } else {
            selectObject.selectedUuids.map(item => {
                selected_local.push(item);
            });

            setSelected(selected_local);
            setSelectCount(selectObject.count);
        }
    };

    const renderCount = () => {
        return (<div className={style.table_select_count}>
            Выбрано: {selectCount}
        </div>);
    };

    const submitHandler = () => {
        setShowModal(false);
        if (handleAddQuestions != undefined)
            handleAddQuestions(selectedQuestions);
    };

    return (
        <Modal
            onClose={() => setShowModal(false)}
            isOpen={showModal}
            title={title}
        >
            <div className={style.table_container}>
                <QuestionTable 
                    data={questions}
                    onSelectItems={onSelect}
                    gridTemplateColumns={'7fr 3fr'}
                    isFetching={isFetching}
                    isSelectable
                    scrolled
                    isPagination={true}
                    isDisableSelectPanel={false}
                />
            </div>

            <div className={style.footer_сontainer}>
                {renderCount()}

                <div className={style.btn_container}>
                    <Button
                        onClick={() => setShowModal(false)}
                        btnType={'secondary'}>
                        Отмена
                    </Button>

                    <Button type={'submit'} onClick={submitHandler}>Добавить</Button>
                </div>
            </div>
        </Modal>
    );
};
