import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { Tabs } from 'react-tabs';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { NewLoader } from '@components/core/NewLoader';
import Paper from '@components/core/styledWrappers/Paper';
import Main from '@components/Main';
import { RoleCheck } from '@components/RoleCheck';
import { renderPanelList, renderTabList } from '@components/Tabs/Tabs';
import { useNavigationState } from '@hooks/useNavigationState';
import { skipToken } from '@reduxjs/toolkit/query';
import { EnumTestAnswer } from '@store/api/apiTypes';
import { useGetTestResultQuery } from '@store/api/gradesApi';
import { useDeleteAttemptMutation, useGetTestByIdQuery } from '@store/api/testsApi';
import { useGetUserByUuidQuery } from '@store/api/usersApi';
import { Role } from '@store/Roles';
import { QuestionResult } from '@type/grades';
import { DeleteAttempt } from '@views/methodist/Materials/Course/TestResult/DeleteAttempt';

import AttemptUserInfo from './AttemptUserInfo';
import QuestionNavigator from './QuestionNavigator';
import TestAttempt from './TestAttempt/TestAttempt';
import style from './TestResult.module.scss';


export interface INavigationQuestions {
    uuid: string;
    isContainWrongAnswer: boolean;
}

export const TestResult = () => {
    const { uuidCategory, uuidDiscipline, userId, materialId } = useParams();
    const { state } = useNavigationState();
    const navigate = useNavigate();

    const testInfo = useGetTestByIdQuery(materialId || skipToken);
    const user = useGetUserByUuidQuery(userId || skipToken);
    const testAttempts = useGetTestResultQuery(userId && materialId ? {
        uuidTest: materialId,
        uuidUser: userId
    } : skipToken);

    const [deleteAttempt] = useDeleteAttemptMutation();

    const [panels, setPanels] = useState<React.ReactNode[]>([]);
    const [tabs, setTabs] = useState<string[]>([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [bestGrade, setBestGrade] = useState(0);
    const [navigationQuestions, setNavigationQuestions] = useState<INavigationQuestions[]>([]);

    const renderGradeInfo = () => {
        if (testAttempts.data && testInfo.data && testAttempts.data.length) {
            return `Оценка: ${testAttempts.data[tabIndex].grade} из ${testInfo.data?.test.maximumRating} баллов`;
        }

        return '';
    };

    const isBestAttempt = () => {
        return !!(testAttempts.data && testAttempts.data[tabIndex]?.grade === bestGrade);
    };

    const isAnswerWrong = (isCorrect: boolean, isChosen: boolean) => {
        return (!isCorrect && isChosen) || (!isChosen && isCorrect);
    };

    const checkWrongAnswer = (questions: QuestionResult[]): INavigationQuestions[] => {
        return questions.map(question => {
            if (question.questionType === EnumTestAnswer.SINGLE) {
                return {
                    uuid: question.uuid,
                    isContainWrongAnswer: question.answers.some(answer => {
                        return isAnswerWrong(answer.answerSimple.isCorrect, answer.answerSimple.chosen);
                    })
                };
            }

            if (question.questionType === EnumTestAnswer.MULTIPLY) {
                return {
                    uuid: question.uuid,
                    isContainWrongAnswer: question.answers.some(answer => {
                        return isAnswerWrong(answer.answerMultiple.weight > 0, answer.answerMultiple.chosen);
                    })
                };
            }

            if (question.questionType === EnumTestAnswer.TO_MATCH) {
                return {
                    uuid: question.uuid,
                    isContainWrongAnswer: question.answers.some(answer => {
                        return isAnswerWrong(answer.answerToMatch.studentSubAnswer === answer.answerToMatch.subAnswer, true);
                    })
                };
            }

            return {
                uuid: question.uuid,
                isContainWrongAnswer: false
            };
        });
    };

    useEffect(() => {
        if (testAttempts.isSuccess && testAttempts.data) {
            setPanels(testAttempts.data.map((attempt, index) => (
                <TestAttempt attempt={testAttempts.data[tabIndex]} key={`test_${materialId}`} />
            )));
            setTabs(testAttempts.data.map((attempt, index) => `Попытка ${index + 1}`));

            const bestMark = testAttempts.data.reduce((mark, attempt) => {
                return attempt.grade >= mark ? attempt.grade : mark;
            }, 0);
            setBestGrade(bestMark);

            const checkedQuestions = checkWrongAnswer(testAttempts.data[tabIndex].questions);
            setNavigationQuestions(checkedQuestions);
        }

    }, [testAttempts.data, testAttempts.isSuccess, tabIndex]);

    const backLink = `/materials/${uuidCategory}/course/${uuidDiscipline}/scores`;

    const handleDeleteAttempt = async () => {
        if (testAttempts.data?.[tabIndex]) {
            await deleteAttempt({ uuids: [testAttempts.data?.[tabIndex].uuidAttempt] });
            const prevTab = tabIndex - 1;
            if (prevTab < 0) {
                navigate(backLink, { state });
            } else {
                setTabIndex(prevTab);
            }

        }
    };

    if (testAttempts.isFetching || testInfo.isFetching || user.isFetching) return <NewLoader />;

    return (
        <Main className={style.content}>
            <div className={style.title_wrapper}>
                <ButtonIcon
                    typeIcon="arrowLink"
                    onClick={() => navigate(backLink, { state })}
                />
                <h1>
                    {testInfo.data?.test.name}
                </h1>
            </div>
            <Tabs selectedIndex={tabIndex} onSelect={(index: number) => setTabIndex(index)} className={style['react-tabs--grey']}>
                <Paper className={style.paper}>
                    <div className={style.attempt_info}>
                        <AttemptUserInfo user={user.data!} />
                        <div className={style.attempt_grade_wrapper}>
                            {
                                isBestAttempt() &&
                                    <div className={style.attempt_best}>
                                        лучшая попытка
                                    </div>
                            }
                            <div className={style.attempt_grade}>
                                {renderGradeInfo()}
                            </div>
                        </div>
                    </div>
                    { renderTabList(tabs) }
                </Paper>
                <div className={style['attempt-wrapper']}>
                    { renderPanelList(panels) }
                    <div className={style.sticky_menu}>
                        {
                            !!testAttempts.data && !!testAttempts.data[tabIndex].questions.length &&
                                <QuestionNavigator
                                    testQuestions={navigationQuestions}
                                    attemptIndex={tabIndex}
                                    attemptDate={testAttempts.data[tabIndex].submittedAt}
                                />
                        }

                        <RoleCheck roles={Role.Methodist}>
                            <DeleteAttempt handleDelete={handleDeleteAttempt} attemptName={`Попытка ${tabIndex + 1}`} />
                        </RoleCheck>
                    </div>
                </div>

            </Tabs>
        </Main>
    );
};
