import { FC } from 'react';

import { dateConvert } from '@helpers/date';

import style from './MessageDateSeparator.module.scss';


export interface IMessageDateSeparator {
    date: string
}

const MessageDateSeparator:FC<IMessageDateSeparator> = ({
    date
}) => {

    const formattedDate = dateConvert(date.replace(/ \+0000 UTC/, '')).toFormat('dd MMMM');

    return <div className={style.container}>
        <div className={style.dateContainer}>
            {formattedDate}
        </div>
    </div>;
};

export default MessageDateSeparator;
