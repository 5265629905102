import { FC, useEffect, useState } from 'react';

import { useForm, useWatch, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import NewTreeSelectControl, { transformDataToOptions, filterOptionsByList, type TreeSelectOption } from '@components/core/inputs/NewTreeSelectControl';
import Search from '@components/Search';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useNavigationState } from '@hooks/useNavigationState';
import { useSearch } from '@hooks/useSearch';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetDisciplineExportFileMutation } from '@store/api/gradesApi';
import { useGetGroupsTreeQuery, useGetGroupsOfDisciplineQuery } from '@store/api/groupsApi';


import style from './Scores.module.scss';
import { ScoreTable } from './ScoreTableView/ScoreTable';

export interface TScoresGroups {
    selectGroups: string[];
}

const schema = yup.object({
    selectGroups: yup.array().of(yup.string())
});

export const Scores:FC = () => {
    const params = useParams();

    const [getXlsFile] = useGetDisciplineExportFileMutation();

    const [groupOptions, setGroupOptions] = useState<TreeSelectOption[]>([]);

    const { search, ...searchInputProps } = useSearch();

    const groupsTree = useGetGroupsTreeQuery('');
    const disciplineGroupsQueryData = useGetGroupsOfDisciplineQuery(params.uuidDiscipline || skipToken);

    const { state } = useNavigationState<string[]>();

    // Forms
    const methods = useForm<TScoresGroups>({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            selectGroups: state || []
        }
    });

    const { control } = methods;

    const selectGroups = useWatch({
        control,
        name: 'selectGroups'
    });

    const getXLSFile = async () => {
        const file = await getXlsFile({
            uuidDiscipline: params.uuidDiscipline as string,
            userSearch: search,
            uuidsGroupSearch: selectGroups
        }).unwrap();
        window.open(file.path);
    };

    useEffect(() => {
        if ( groupsTree.isSuccess && disciplineGroupsQueryData.isSuccess) {
            const allGroupsOptions = groupsTree.data
                ? transformDataToOptions(groupsTree.data.childs, {
                    value: 'UUID',
                    label: 'name',
                    children: 'childs'
                })
                : [];

            const currentGroupsOptions = filterOptionsByList(allGroupsOptions, disciplineGroupsQueryData.data.payload.map(({ groupUUID }) => groupUUID));

            setGroupOptions(currentGroupsOptions);
        }
    }, [groupsTree.isSuccess, disciplineGroupsQueryData.isSuccess]);

    return <div className={style.container}>
        <div className={style.table_header}>

            <div className={style.header_controls}>
                <FormProvider {...methods}>
                    <NewTreeSelectControl
                        options={groupOptions}
                        name="selectGroups"
                        className={style.groups}
                        placeholder="Укажите группы"
                        isMulti={true}
                    />
                </FormProvider>

                <div className={style.search}>
                    <Search {...searchInputProps} />
                </div>
            </div>

            <Button onClick={getXLSFile}>Экспорт в .xls</Button>
        </div>

        <div className={style.table}>
            <ScoreTable search={search} groupUuids={selectGroups} />
        </div>
    </div>;
};
