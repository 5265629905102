import { FC, ReactNode } from 'react';

import classNames from 'classnames/bind';

import style from './header.module.scss';

const cx = classNames.bind(style);

interface IProps {
    className?: string;
    children?: ReactNode;
}

const Header: FC<IProps> = ({ className, children }) => {
    return <header className={cx('root', className)}>{children}</header>;
};

export default Header;
