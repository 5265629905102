import React, { FC, HTMLAttributes, useRef, useState } from 'react';

import cx from 'classnames';

import Button from '@components/core/inputs/Button';
import { Menu } from '@components/Menu';

import styles from './tablemenu.module.scss';

interface ITableMenuProps extends HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    className?: string;
    stopPropagation?: boolean;
}

export const TableMenu: FC<ITableMenuProps> = ({ children, className, stopPropagation, ...props }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (stopPropagation) event.stopPropagation();

        if (isOpen) {
            setAnchorEl(null);
            setIsOpen(false);
        } else {
            setAnchorEl(event.currentTarget);
            setIsOpen(true);
        }
    };

    const closeMenu = () => {
        setAnchorEl(null);
        setIsOpen(false);
    };

    return (<>
        <div ref={menuRef} {...props}>
            <Button 
                btnType="menu" 
                className={cx(className, 'table-menu', isOpen && styles.btnOpen)} 
                typeIcon="menu" 
                onClick={onClick}></Button>

            <Menu className={'tbmenu'} isOpen={isOpen} anchorEl={anchorEl} onClose={closeMenu}>
                {children}
            </Menu>
        </div>

    </>
    );
};
