import { FC } from 'react';

import cx from 'classnames';
import { useOutletContext } from 'react-router-dom';

import { IProgrammContext } from '@layouts/Methodist/StudyProgrammesLayout/StudyProgrammesLayout';
import SectionCard from '@views/student/StudentCourse/SectionsList/SectionCard/SectionCard';

import style from './ProgrammSectionsList.module.scss';

interface ISectionsList {
    className?: string;
}

const ProgrammSectionsList: FC<ISectionsList> = ({ className }) => {
    const { programnInfo: { sections } } = useOutletContext<IProgrammContext>();

    return (
        <ul className={cx(style.list, className)}>
            {
                sections?.map(section =>
                    <SectionCard key={section.uuid} section={section} type='programmStudy' />
                )
            }
        </ul>
    );
};

export default ProgrammSectionsList;