import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import ButtonMenu from '@components/core/inputs/Button/ButtonMenu';
import { NewLoader } from '@components/core/NewLoader';
import { Helmet } from '@components/Helmet';
import { MassEdit } from '@components/MassEdit';
import { MenuItem } from '@components/Menu';
import { RoleCheck } from '@components/RoleCheck';
import { ScoreCard } from '@components/ScoreCard';
import { getUsersIdsWithExtraSession } from '@helpers/extraSession';
import { makeGroupTreeFlat } from '@helpers/groupTree';
import { useNavigationState } from '@hooks/useNavigationState';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetGroupDeadlinesQuery, useGetGroupsTreeQuery, useGetUsersDeadlinesQuery, useGetExtraSessionsDeadlinesByGroupsQuery } from '@store/api/groupsApi';
import { useChangeUserStatusByUuidMutation, useGetUsersByGroupQuery, useResetUserPasswordMutation } from '@store/api/usersApi';
import { selectStudentsUuidSlice, setStudentsUuidAll } from '@store/features/massEditSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { User, UserStatus } from '@type/users';

import { AdditionalSession } from './AdditionalSession';
import style from './edit_group.module.scss';
import EditGroupModals from './EditGroupModals';
import EditGroupSideBar from './EditGroupSideBar';
import { TermsModal, TTermsModalType } from './TermsModal/TermsModal';

export interface ISelectStudent extends User {
    isSelect: boolean;
}

const EditGroup = () => {
    const { state } = useNavigationState<{ currentGroup: string }>();
    const navigate = useNavigate();
    const { uuidGroup } = useParams();

    const [resetPassword] = useResetUserPasswordMutation();

    const [selectStudents, setSelectStudents] = useState<ISelectStudent[] | undefined>();
    const [selectStudentsCount, setSelectStudentsCount] = useState(0);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [showSendMessageModal, setShowSendMessageModal] = useState(false);
    const [showChangeGroupModal, setShowChangeGroupModal] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [localStudents, setLocalStudents] = useState<string[]>([]);
    const [currentUser, setCurrentUser] = useState<ISelectStudent>();
    const [isGroupTerms, setIsGroupTerms] = useState(false);

    const [termsModalType, setTermsModalType] = useState<TTermsModalType>('person');

    const dispatch = useAppDispatch();

    // API
    const groupsTree = useGetGroupsTreeQuery('');
    const currentGroup = groupsTree.data && makeGroupTreeFlat(groupsTree.data.childs).find(group => group.UUID === uuidGroup);

    const usersList = useGetUsersByGroupQuery(uuidGroup ? uuidGroup : skipToken);

    const groupDeadlines = useGetGroupDeadlinesQuery(uuidGroup ? uuidGroup : skipToken);
    const individualDeadlines = useGetUsersDeadlinesQuery(uuidGroup && usersList.data ? {
        groupUUID: uuidGroup as string,
        userUUID: usersList.data
            .filter(user => user.role === Role.Student)
            .map(user => user.uuid)
    } : skipToken, {
        selectFromResult: ({ data: usersDeadlinesData }) => {
            return ({
                data: usersDeadlinesData?.payload
            });
        }
    });

    const extraSessionDeadlinesQueryData = useGetExtraSessionsDeadlinesByGroupsQuery(uuidGroup || skipToken);

    const usersWithExtraSession = useMemo(() => {
        if (!extraSessionDeadlinesQueryData.data) return [];
        return getUsersIdsWithExtraSession(extraSessionDeadlinesQueryData.data);
    }, [extraSessionDeadlinesQueryData.data]);

    const massSelectedStudents: string[] = useAppSelector(selectStudentsUuidSlice);
    const [changeStatus] = useChangeUserStatusByUuidMutation();

    const toggleAllSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectStudents(selectStudents?.map(selectStudent => {
            return {
                ...selectStudent,
                isSelect: e.target.checked
            };
        }));

        setIsAllSelected(e.target.checked);
    };

    const toggleSelectStudent = (e: React.ChangeEvent<HTMLInputElement>, uuid: string) => {
        setSelectStudents(selectStudents?.map(selectStudent => {
            if (uuid === selectStudent.uuid) selectStudent.isSelect = e.target.checked;

            return selectStudent;
        }));
    };

    const toggleTermsModal = (type: TTermsModalType) => {
        setShowTermsModal(true);
        setTermsModalType(type);
        setIsGroupTerms(false);
    };

    const massEditingCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.checked && selectStudentsCount) {
            toggleAllSelect(e);

            setSelectStudents(selectStudents?.map(selectStudent => {
                return {
                    ...selectStudent,
                    isSelect: e.target.checked
                };
            }));

            return;
        }

        toggleAllSelect(e);
    };

    const getSelectedStudents = (students: ISelectStudent[]) => {
        return students ? students.filter(selectStudent => selectStudent.isSelect) : [];
    };

    const getSelectedStudentsUuid = (selectedStudents: ISelectStudent[]) => {
        return selectedStudents ? selectedStudents?.map(student => student.uuid) : [];
    };

    const toggleUsersStatuses = (users: string[], status: UserStatus): void => {

        changeStatus({
            uuids: users,
            status
        });
    };

    useEffect(() => {
        const selectedStudents = getSelectedStudents(selectStudents ?? []);
        dispatch(setStudentsUuidAll(getSelectedStudentsUuid(selectedStudents)));
        if (selectedStudents) setSelectStudentsCount(selectedStudents.length);
    }, [selectStudents]);

    useEffect(() => {
        selectStudentsCount === selectStudents?.length ? setIsAllSelected(true) : setIsAllSelected(false);
    }, [selectStudentsCount]);

    useEffect(() => {
        if (usersList.isSuccess) {
            const students = usersList.data.filter(item => item.role === Role.Student);
            setSelectStudents(students.map(student => ({ ...student, isSelect: false })));
        }
    }, [usersList.isFetching, usersList.isSuccess, usersList.data]);

    if (usersList.isFetching || groupsTree.isLoading) return <NewLoader />;

    // ToDo: Если что то не пришло, надо как то реагировать
    if (!usersList.isSuccess || !groupsTree.isSuccess) return <NewLoader />;


    const sendPassword: (usersUuids: string[]) => void = (usersUuids) => {
        resetPassword(usersUuids);
    };

    const editTerms = (type: TTermsModalType, isGroup: boolean) => {
        if (!isGroup) setLocalStudents(massSelectedStudents);
        toggleTermsModal(type);
        setIsGroupTerms(isGroup);
    };

    const sendMessage = () => {
        setShowSendMessageModal(true);
        setLocalStudents(massSelectedStudents);
    };

    const changeGroup = () => {
        setShowChangeGroupModal(true);
        setLocalStudents(massSelectedStudents);
    };

    const getBackLink = () => {
        if (state) {
            return `/users/groups/${state.currentGroup}`;
        } else {
            return '/users';
        }
    };

    return (
        <div className={style.wrapper}>
            <Helmet title={currentGroup && `Управление пользователями – Группы: ${currentGroup.name}`} />
            <div className={style.title_wrapper}>
                <div className={style.title_navigate}>
                    <ButtonIcon
                        typeIcon="arrowLink"
                        onClick={() => navigate(getBackLink(), { state })}
                    />
                    <h1>
                        {currentGroup?.name}
                    </h1>
                </div>

                <div className={style.buttons_wrapper}>
                    <div className={style.deadline_wrapper}>
                        {
                            (groupDeadlines.isSuccess && groupDeadlines.data.length === 0) ?
                                <div className={style.deadline_text}>Сроки сдачи не установлены!</div> : null
                        }
                        <RoleCheck roles={Role.Methodist}>
                            <div className={style.deadline_btns}>
                                <Button onClick={() => editTerms('group', true)}>
                                    Сроки сдачи группы
                                </Button>
                                <RoleCheck roles={Role.Methodist}>
                                    <AdditionalSession />
                                </RoleCheck>
                            </div>
                        </RoleCheck>
                    </div>

                    {/* <Button>Пользователи группы</Button> */}
                </div>
            </div>

            <div className={style.content_wrapper}>
                <EditGroupSideBar
                    students={selectStudents}
                    toggleSelectStudent={toggleSelectStudent}
                    toggleAllSelect={toggleAllSelect}
                    isAllSelected={isAllSelected}
                    setShowSendMessageModal={setShowSendMessageModal}
                    setShowChangeGroupModal={setShowChangeGroupModal}
                    toggleTermsModal={toggleTermsModal}
                    setLocalStudents={setLocalStudents}
                    setCurrentUser={setCurrentUser}
                    individualDeadlines={individualDeadlines.data ?? []}
                    usersWithExtraSession={usersWithExtraSession}
                />

                <div className={style.draft_container}>

                    {
                        currentUser && <>
                            <div className={style.user_heading}>
                                <h2>{`${currentUser.lastName} ${currentUser.firstName} ${currentUser.patronymic}`}</h2>
                                <div className={style.user_sub_header}>
                                    Электронная зачетка
                                </div>
                            </div>

                            <div className={style.user_table}>
                                <ScoreCard studentId={currentUser.uuid} />
                            </div>
                        </>
                    }

                    <MassEdit onSelectAll={massEditingCheck} selectCount={selectStudentsCount} isShow={!!selectStudentsCount} >
                        <div className={style.mass_container}>
                            <RoleCheck roles={Role.Methodist}>
                                <ButtonMenu className={style.action_btn} btnText="Действия" btnType="dark" size="small">
                                    <MenuItem
                                        onClick={() => toggleUsersStatuses(massSelectedStudents, UserStatus.Active)}
                                    >
                                        Разблокировать
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => toggleUsersStatuses(massSelectedStudents, UserStatus.Blocked)}
                                    >
                                        Заблокировать
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => sendPassword(massSelectedStudents)}
                                    >
                                        Отправить пароли
                                    </MenuItem>
                                </ButtonMenu>
                            </RoleCheck>
                            {/**/}
                            <div className={style.action_btn}>
                                <Button btnType="dark" size="small" onClick={sendMessage}>
                                    Отправить сообщение
                                </Button>
                            </div>
                            <RoleCheck roles={Role.Methodist}>
                                <div className={style.action_btn}>
                                    <Button btnType="dark" size="small" onClick={changeGroup}>
                                        Сменить группу
                                    </Button>
                                </div>
                                <div className={style.action_btn}>
                                    <Button
                                        btnType="dark"
                                        size="small"
                                        onClick={() => editTerms('person', false)}
                                    >
                                        Сроки сдачи
                                    </Button>
                                </div>
                            </RoleCheck>
                        </div>
                    </MassEdit>
                </div>
            </div>

            <EditGroupModals
                groups={groupsTree.data}
                localStudents={localStudents}
                setShowChangeGroupModal={setShowChangeGroupModal}
                setShowSendMessageModal={setShowSendMessageModal}
                showChangeGroupModal={showChangeGroupModal}
                showSendMessageModal={showSendMessageModal}
                uuidGroup={uuidGroup}
            />

            <TermsModal
                isOpen={showTermsModal}
                localStudents={localStudents}
                type={termsModalType}
                onClose={setShowTermsModal}
                isGroupTerms={isGroupTerms}
                studentsDeadlines={individualDeadlines.data ?? []}
            />
        </div>
    );
};

export default EditGroup;
