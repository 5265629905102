import { Outlet } from 'react-router-dom';

import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { useGetStudyProgrammeQuery } from '@store/api/programmesApi';

const StudentStudyProgrammLayout = () => {
    const { data: programmData, isFetching } = useGetStudyProgrammeQuery();

    if (isFetching) return <NewLoader />;

    return <Outlet context={{ programnInfo: programmData }} />;
};

export default StudentStudyProgrammLayout;