import { useEffect, useState } from 'react';

import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import Main from '@components/Main';
import { IProgrammContext } from '@layouts/Methodist/StudyProgrammesLayout/StudyProgrammesLayout';
import { useCreateProgrammePageBlockMutation } from '@store/api/programmesApi';
import { ICreateProgrammPage } from '@type/studyProgrammes';
import CreatePageForm from '@views/methodist/Materials/Course/CreatePageForm';
import { CreatePageFormData } from '@views/methodist/Materials/Course/CreatePageForm/type';

import style from './StudyProgrammesAddPage.module.scss';

const StudyProgrammesAddPage = () => {
    const navigate = useNavigate();
    const { uuidSection } = useParams();
    const [createPage, { isSuccess }] = useCreateProgrammePageBlockMutation();
    const { programnInfo: { sections, uuidGroup } } = useOutletContext<IProgrammContext>();

    const [defaultValuesForm] = useState<CreatePageFormData>({
        name: '',
        content: '',
        accessLimitation: false,
        blockedGroups: []
    });

    const submitFormHandler = (formData: CreatePageFormData) => {
        const transformedData: ICreateProgrammPage = {
            name: formData.name,
            content: formData.content,
            uuidSection: uuidSection || '',
            position: sections.length,
            uuidGroup: uuidGroup
        };

        createPage(transformedData);
    };

    useEffect(() => {
        if (isSuccess) {

            navigate('./../..');
        }

    }, [isSuccess]);

    return (
        <Main>
            <div className={style.title_wrapper}>
                <ButtonIcon
                    typeIcon='arrowLink'
                    onClick={() => navigate('./../..')}
                />
                <h1>Добавить страницу</h1>
            </div>

            <CreatePageForm
                submitFormHandler={submitFormHandler}
                defaultValues={defaultValuesForm}
            />
        </Main>
    );
};

export default StudyProgrammesAddPage;
