import React, { FC } from 'react';

import style from './accordion_table_row.module.scss';

interface IProps {
    contents: React.ReactNode[]
    columnsStyles?: React.CSSProperties[]
    children? : React.ReactNode
    onClick?: () => void
}

const AccordionTableRow: FC<IProps> = ({ contents, children, onClick, columnsStyles = [] }) => {

    return (
        <div className={style.group_row}>
            {contents.map((content, index) => {
                return (
                    <div key={index} className={style.group_column} style={columnsStyles[index]} onClick={onClick}>
                        {content}
                    </div>
                );
            })}
            <div>
                {children}
            </div>
        </div>
    );
};

export default AccordionTableRow;
