import React, { useEffect, useState } from 'react';

import Button from '@components/core/inputs/Button';
import Modal from '@components/Modal';
import { dateConvert, datePrepareToSend } from '@helpers/date';
import { DisciplineWithExtraSession } from '@type/extraSession';
import { UpdateExtraSession } from '@type/groups';
import { PeriodStep } from '@views/methodist/Users/UsersGroups/EditGroup/AdditionalSessionModal/PeriodStep';

import styles from './EditDeadlinesModal.module.scss';

interface EditDeadlinesModalProps {
    onClose: () => void;
    discipline: DisciplineWithExtraSession | null;
    onEdit: (data: UpdateExtraSession) => Promise<void>;
}

export const EditDeadlinesModal: React.FC<EditDeadlinesModalProps> = ({ onClose, discipline, onEdit }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        if (discipline) {
            setStartDate(dateConvert(discipline.deadline.startDate).toFormat('yyyy-LL-dd HH:mm:ss'));
            setEndDate(dateConvert(discipline.deadline.endDate).toFormat('yyyy-LL-dd HH:mm:ss'));
        }
    }, [discipline]);

    if (!discipline) return null;

    const validateDeadline = () => {
        if (startDate && endDate) {
            const start = dateConvert(startDate);
            const end = dateConvert(endDate);

            const diff = end.diff(start, ['milliseconds']);
            return diff.toObject().milliseconds as number > 0;
        }
        return true;
    };

    const onSubmit = async () => {
        const data: UpdateExtraSession = {
            uuids: discipline.extraSessions,
            deadline: {
                deadlineStart: datePrepareToSend(startDate),
                deadlineEnd: datePrepareToSend(endDate)
            }
        };
        await onEdit(data);
        onClose();
    };

    return (
        <Modal isOpen={Boolean(discipline)} onClose={onClose} className={styles.modal}>
            <h2>Сроки сдачи доп. сессии</h2>
            <p className={styles.description}>{discipline?.name}</p>

            <PeriodStep
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                defaultStartDate={startDate}
                defaultEndDate={endDate}
                error={validateDeadline() ? undefined : 'Дата окончания должна быть позже даты начала'}
            />

            <div className={styles.control}>
                <Button onClick={onSubmit} disabled={!(startDate && endDate) || !validateDeadline()}>
                    Установить
                </Button>
            </div>
        </Modal>
    );
};
