import { FC } from 'react';

import { AttemptResult } from '@type/grades';

import style from './TestAttempt.module.scss';
import TestAttemptQuestion from './TestAttemptQuestion';


interface IAttempt {
    attempt: AttemptResult;
}

const TestAttempt: FC<IAttempt> = ({ attempt }) => {

    return (
        <div className={style.wrapper}>
            <div className={style.questions}>
                {
                    attempt.questions.map((question, index) => (
                        <TestAttemptQuestion key={question.uuid} question={question} index={index} />
                    ))
                }
            </div>
        </div>
    );
};

export default TestAttempt;
