import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import style from './drawer.module.scss';

const cx = classNames;

interface IProps {
    className?: string;
    children?: ReactNode;
    noScroll?: boolean;
}

const Drawer: FC<IProps> = ({ className, children, noScroll = false }) => {
    return (
        <div className={cx(style.root, className)}>
            <div className={cx(style.content, noScroll && style.noScroll)}>{children}</div>
        </div>
    );
};

export default Drawer;
