import React, { PropsWithChildren } from 'react';

import clsx from 'classnames';

import SwipeableDrawer, { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer';

import style from './BottomDrawer.module.scss';

interface BottomDrawerProps extends SwipeableDrawerProps {
    contentClassName?: string;
}

export const BottomDrawer: React.FC<PropsWithChildren<BottomDrawerProps>> = ({ children, contentClassName, ...props }) => {

    return (
        <SwipeableDrawer anchor={'bottom'} classes={{ ...props.classes, paper: clsx(style.paper, props.classes?.paper) }} disableSwipeToOpen {...props}>
            <div className={clsx(style.container, contentClassName)}>
                <div className={style.container_handle} />
                {children}
            </div>
        </SwipeableDrawer>
    );
};
