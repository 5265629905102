import React, { Dispatch, FC, SetStateAction } from 'react';

import Modal from '@components/Modal';
import { UpdateLinkData } from '@store/api/apiTypes';
import CreateLinkForm from '@views/methodist/Materials/Course/CreateLinkForm/CreateLinkForm';
import { CreateLinkFormData } from '@views/methodist/Materials/Course/CreateLinkForm/type';

interface IEditLinkModal {
    isOpen: boolean
    onClose: Dispatch<SetStateAction<boolean>>
    linkData: UpdateLinkData
    getUpdateLinkData?: (updateLinkData: UpdateLinkData) => void
    updateLink: (linkData: UpdateLinkData) => void
}

const EditLinkModal: FC<IEditLinkModal> = ({ isOpen, onClose, getUpdateLinkData, linkData, updateLink }) => {
    const createLinkHandler = (formData: CreateLinkFormData) => {
        const transformFormData: UpdateLinkData = {
            uuid: linkData.uuid,
            name: formData.name,
            link: formData.link,
            uuidSection: linkData.uuidSection,
            // TODO: remove position
            position: linkData.position
        };

        if (getUpdateLinkData) {
            getUpdateLinkData(transformFormData);
            onClose(false);
        } else {
            updateLink(transformFormData);
            onClose(false);
        }
    };

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title='Редактировать ссылку'
        >
            <CreateLinkForm
                defaultValues={{ name: linkData.name, link: linkData.link }}
                onSubmitFormHandler={createLinkHandler}
                onCloseModal={onClose}
            />
        </Modal>

    );
};

export default EditLinkModal;
