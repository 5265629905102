import { ReactComponent as PictureAllSVG } from '@assets/icons/picture-all.svg';

import style from './RecordNotFound.module.scss';

export const CompletedDiscipline = () => {

    return <div className={style.container}>
        <div className={style.picture}>
            <PictureAllSVG />
        </div>

        <div className={style.title}>Завершенных<br /> дисциплин нет</div>
        <div className={style.description}>
            Сданные дисципилины будут<br />
            отображаться в этом разделе
        </div>
    </div>;
};