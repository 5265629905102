import { FC } from 'react';

import Collapse from 'rc-collapse';

import Button from '@components/core/inputs/Button';
import Paper from '@components/core/styledWrappers/Paper';
import { ICategory } from '@store/api/apiTypes';

import style from './SubscribeCard.module.scss';
import { SubscribePanel } from './SubscribePanel/SubscribePanel';

export interface ISubscribeCardProps {
    items: ICategory[];
    onSubmit: () => void
}

// ToDo: много рередеров изза подписки на стор
export const SubscribeCard: FC<ISubscribeCardProps> = ({ items, onSubmit }) => {

    return <Paper className={style.container}>
        <Collapse>
            {
                items.map((item, index) => {
                    return <SubscribePanel key={`subscribe-panel-${index}`} level={1} item={item} />;
                })
            }
        </Collapse>

        <div className={style.actions}>
            <Button btnType={'success'} onClick={onSubmit}> Сохранить </Button>
            <Button btnType={'secondary'}> Отмена </Button>
        </div>

    </Paper>;
};