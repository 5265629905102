import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetSkillsQuery, useGetSkillDisciplinesQuery } from '@store/api/skillsApi';
import { useGetFileQuery } from '@store/api/storageApi';

export function useSkill(skillUUID:string){
    const {
        data: skills
    } = useGetSkillsQuery(skillUUID ? { uuids:[skillUUID] } : skipToken);

    const {
        data: picture,
        isSuccess: getPictureIsSuccess
    } = useGetFileQuery(skills?.length ? (skills[0].uuidPicture || skipToken) : skipToken);

    const {
        data: skillDisciplines
    } = useGetSkillDisciplinesQuery(undefined);

    if(skills && skillDisciplines && getPictureIsSuccess){
        return { skill: skills[0], picture, skillDisciplines };
    }else{
        return { skill: undefined, picture: undefined, skillDisciplines: undefined };
    }

}
