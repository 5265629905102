import { useEffect, useState } from 'react';

import { ICheckboxProps } from '@components/core/inputs/Checkbox/Checkbox';

export type IExtendedCheckboxData<T> = T & { checked?: boolean } 
export type IExtendedCheckboxProps<T> = (item: IExtendedCheckboxData<T>, index: number) => ICheckboxProps
export interface IUseGroupCheckboxProps<T> {
    data: T[]
}
export interface IUseGroupCheckboxReturned<T> {
    selectAllProps: ICheckboxProps,
    dataWithChecked: IExtendedCheckboxData<T>[],
    selectCount: number;
    checkboxProps: IExtendedCheckboxProps<T>,
}

export function useGroupCheckbox<T>({ 
    data 
} : IUseGroupCheckboxProps<T>) : IUseGroupCheckboxReturned<T> {
    
    const [ isSelectAll, setSelectAll ]         = useState<boolean>(false); 
    const [ dataWithChecked, setCheckboxes]     = useState<IExtendedCheckboxData<T>[]>([]);
    const [ selectCount, setSelectCount ]       = useState<number>(0);

    const selectAllProps: ICheckboxProps = {
        checked: isSelectAll,
        onChange: () => setSelectAll(!isSelectAll)
    };

    const checkboxProps = (item: IExtendedCheckboxData<T>, index: number) : ICheckboxProps => {

        const onChange = () => {
            const temp = [...dataWithChecked];
            temp[index].checked = !item.checked;
            setCheckboxes(temp);
        };

        return {
            checked: dataWithChecked[index].checked,
            onChange
        } as ICheckboxProps;
    };

    // Добавляем к данным свойство checked
    useEffect(() => {
        setCheckboxes(data.map(item => {
            return {
                ...item,
                checked: false
            } as IExtendedCheckboxData<T>;
        }));

        if (isSelectAll && data.length === 0) {
            setSelectAll(false);
        }
    }, [data]);

    // Если жмякнули выбрать все
    useEffect(() => {
        setCheckboxes(
            dataWithChecked?.map(item => {
                item.checked = isSelectAll;
                return item;
            })
        );
    }, [isSelectAll]);

    // Обновляем проп SelectCount
    useEffect(() => {
        const count = dataWithChecked.filter(t => t.checked).length;
        setSelectCount(count);
        
    }, [isSelectAll, dataWithChecked]);


    return {
        selectAllProps,
        dataWithChecked,
        selectCount,
        checkboxProps
    };
}