import { toast } from 'react-toastify';

import { VideoBlockCreateData, VideoBlockCreateResponse, VideoBlockResponse, VideoLectureCreateData, VideoLectureCreateResponse } from '@store/api/apiTypes';

import { emptyBaseApi } from './emptyBaseApi';

export const videosApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getVideoBlockById: build.query<VideoBlockResponse, string>({
            query: (uuidVideoBlock: string) => `disciplines/materials/block/video-lecture/list/${uuidVideoBlock}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить видеолекции');
                }
            },
            providesTags: ['Videos']
        }),

        createVideoBlock: build.mutation<VideoBlockCreateResponse, VideoBlockCreateData>({
            query(data) {
                return {
                    url: 'disciplines/materials/block/create',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Создан блок видеолекций',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Videos', 'SectionList']
        }),
        deleteVideoBlock: build.mutation<void, string>({
            query(blockUuid) {
                return {
                    url: 'disciplines/materials/block/video-lectures/delete',
                    method: 'POST',
                    body: { uuids: [blockUuid] }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Блок видеолекций удален',
                    error: 'Ошибка удаления!'
                });
            },
            invalidatesTags: ['Videos', 'SectionList']
        }),

        createVideoLecture: build.mutation<VideoLectureCreateResponse, VideoLectureCreateData>({
            query(data) {
                return {
                    url: 'disciplines/materials/block/video-lecture',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Videos']
        }),
        updateVideoLecture: build.mutation<VideoLectureCreateResponse, VideoLectureCreateResponse>({
            query(data) {
                return {
                    url: `disciplines/materials/block/video-lecture/update/${data.uuid}`,
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Videos']
        }),

        deleteVideoLecture: build.mutation<void, string>({
            query(videoUuid) {
                return {
                    url: 'disciplines/materials/block/video-lecture/delete',
                    method: 'POST',
                    body: { uuids: [videoUuid] }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Видеолекция удалена',
                    error: 'Ошибка удаления!'
                });
            },
            invalidatesTags: ['Videos']
        })
    })
});

export const {
    useGetVideoBlockByIdQuery,
    useCreateVideoBlockMutation,
    useCreateVideoLectureMutation,
    useUpdateVideoLectureMutation,
    useDeleteVideoBlockMutation,
    useDeleteVideoLectureMutation
} = videosApi;
