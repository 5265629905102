import { toast } from 'react-toastify';

import { compileUrl } from '@helpers/api';
import { sortScoreSessions, transformScoreCardBySlices } from '@helpers/scoreCard';
import {
    CreateExtraSessionRequest, CreateExtraSessionResponse,
    DeleteDeadlinesRequest,
    DeleteStudentsDeadlinesRequest,
    DisciplineGroupsResponse,
    GetDebtorsRequest,
    GetDebtorsResponse, UpdateExtraSession
} from '@type/groups';
import { FilledScoreCard, ScoreCardResult, ScoreCardResponse, ExportedScoreCard } from '@type/scoreCard';
import { Debtor, ExtraSessionDeadline, User } from '@type/users';

import {
    CreateGroupDataRequest,
    DisciplineMaterials,
    GroupData,
    GroupDiscipline,
    GroupTree,
    IGetUserDeadlinesRequest,
    IGroupDeadlinesApiResponse,
    ISetGroupDeadlinesApiRequest,
    ISetUserDeadlinesApiRequest,
    IStudentDeadlinesApiResponse,
    SubscribeAdditionalDisciplinesRequest,
    SubscribeDisciplinesRequest
} from './apiTypes';
import { emptyBaseApi } from './emptyBaseApi';

export const groupsApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getGroupsTree: build.query<GroupTree, string>({
            query(parentUuid: string) {
                return {
                    url: 'group/list-tree',
                    method: 'POST',
                    body: {
                        parentUUID: parentUuid,
                        limit: 0
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список групп');
                }
            },
            providesTags: ['Group_List']
        }),
        createGroup: build.mutation<GroupData, CreateGroupDataRequest>({
            query(data) {
                return {
                    url: 'group/create',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Группа создана',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Group_List']
        }),
        updateGroup: build.mutation<GroupData, GroupData>({
            query(data) {
                return {
                    url: 'group/update',
                    method: 'PUT',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Группа обновлена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Group_List']
        }),
        deleteGroup: build.mutation<GroupData, string>({
            query(groupUuid) {
                return {
                    url: `group/${groupUuid}`,
                    method: 'DELETE'
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Группа удалена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Group_List']
        }),
        getSubscribedDisciplines: build.query<GroupDiscipline[], string[]>({
            query(groupId: string[]) {
                return {
                    url: 'group/discipline/get',
                    method: 'POST',
                    body: {
                        groupsUUID: groupId,
                        limit: 0
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список подписанных дисциплин');
                }
            },
            transformResponse: (result: { disciplines: GroupDiscipline[] }) => {
                return result.disciplines;
            },
            providesTags: (result, error, arg) => [{ type: 'GroupSubs', id: arg[0] }]
        }),
        getSubscribedIndividualsStudentDisciplines: build.query<GroupDiscipline[], string[]>({
            query(uuidsUser: string[]) {
                return {
                    url: 'group/users/get/many',
                    method: 'POST',
                    body: {
                        uuidsUser
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список индивидуальных дисциплин');
                }
            },
            transformResponse: (result: { disciplines: GroupDiscipline[] }) => {
                return result.disciplines;
            }
        }),
        subscribeToDisciplines: build.mutation<void, SubscribeDisciplinesRequest>({
            query({ noToast, ...data }) {
                return {
                    url: 'group/discipline/set',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                if (!args.noToast) {
                    toast.promise(queryFulfilled, {
                        success: 'Дисциплины подписаны',
                        error: 'Ошибка сохранения!'
                    });
                }
            },
            invalidatesTags: (result, error, arg) => [{ type:'GroupSubs', id: arg.groupUUID }]
        }),
        getUsersDeadlines: build.query<{ payload: IStudentDeadlinesApiResponse[] }, IGetUserDeadlinesRequest>({
            query(data) {
                return {
                    url: 'group/user/discipline/materials/deadline/get',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Не смогли получить сроки сдачи для студента'
                });
            },
            providesTags: (result, error, arg) => [{ type: 'StudentsDeadlines', id: arg.groupUUID }]
        }),
        setUserDeadlines: build.mutation<void, ISetUserDeadlinesApiRequest>({
            query(data) {
                return {
                    url: 'group/user/discipline/materials/deadline/set',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Сроки сдачи установленны',
                    error: 'Не смогли установить сроки сдачи для студента'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type:'StudentsDeadlines', id: arg.groupUUID }]
        }),
        getGroupDeadlines: build.query<IGroupDeadlinesApiResponse[], string>({
            query(uuidGroup) {
                return {
                    url: 'group/discipline/materials/deadline/get',
                    method: 'POST',
                    body: { groupUUID: uuidGroup }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Не смогли получить сроки сдачи для группы'
                });
            },
            transformResponse: (result: { payload: IGroupDeadlinesApiResponse[] }) => {

                return result.payload;
            },
            providesTags: (result, error, arg) => [{ type: 'GroupDeadlines', id: arg }]
        }),
        setGroupDeadlines: build.mutation<void, ISetGroupDeadlinesApiRequest>({
            query(data) {
                return {
                    url: 'group/discipline/materials/deadline/set',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Сроки сдачи установленны',
                    error: 'Не смогли установить сроки сдачи для группы'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type:'GroupDeadlines', id: arg.groupUUID }]
        }),
        getScoreCard: build.query<FilledScoreCard[], ScoreCardResponse | undefined>({
            query: (params) => compileUrl('group/scorecard', params),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось загрузить зачетку');
                }
            },
            transformResponse: (result: ScoreCardResult) => {
                return transformScoreCardBySlices(sortScoreSessions(result.payload));
            }
        }),
        exportScoreCard: build.query<ExportedScoreCard, ScoreCardResponse | undefined>({
            query: (params) => compileUrl('group/scorecard/export', params),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось экспортировать зачетку');
                }
            },
            transformResponse: (result: ExportedScoreCard) => {
                return result;
            }
        }),
        getGroupsOfDiscipline: build.query<DisciplineGroupsResponse, string>({
            query: (disciplineUUID) => `group/uuids/${disciplineUUID}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось получить список групп дисциплины');
                }
            },
            transformResponse: (result: DisciplineGroupsResponse) => {
                return result;
            }
        }),
        deleteMaterialsDeadlines: build.mutation<void, DeleteDeadlinesRequest>({
            query(data) {
                return {
                    url: 'group/materials/delete/deadlines/group',
                    method: 'POST',
                    body: {
                        uuidGroup: data.groupUUID,
                        materialsUUID: data.materialsUUID
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Ошибка удаления сроков сдачи'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type:'GroupDeadlines', id: arg.groupUUID }]
        }),
        deleteStudentsMaterialsDeadlines: build.mutation<void, DeleteStudentsDeadlinesRequest>({
            query(data) {
                return {
                    url: 'group/student/delete/deadlines',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Ошибка удаления сроков сдачи'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type: 'StudentsDeadlines', id: arg.groupUUID }]
        }),
        getDebtorsByGroups: build.query<Debtor[], GetDebtorsRequest>({
            query({ groupsUuids }: GetDebtorsRequest) {
                return {
                    url: 'group/debtors',
                    method: 'POST',
                    body: {
                        uuidsGroup: groupsUuids
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список должников');
                }
            },
            transformResponse: (result: GetDebtorsResponse) => {
                return result.users;
            },
            providesTags: (result, error, arg) => [{ type: 'GroupDebtors', id: arg.groupsUuids.join() }]
        }),
        createExtraSession: build.mutation<CreateExtraSessionResponse, CreateExtraSessionRequest>({
            query(body: CreateExtraSessionRequest) {
                return {
                    url: 'group/extra-session/create',
                    method: 'POST',
                    body
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    const result = await queryFulfilled;
                    const { entitiesCreated, entitiesToCreate } = result.data;
                    toast.success(`Дополнительная сессия установлена для ${entitiesCreated} из ${entitiesToCreate}`);
                } catch (error) {
                    toast.error('Не смогли создать дополнительную сессию');
                }
            },
            invalidatesTags: (result, error, arg) => [{ type: 'GroupDebtors', id: arg.uuidGroup }, { type: 'ExtraSession', id: arg.uuidGroup }]
        }),
        getExtraSessionsDeadlinesByGroups: build.query<ExtraSessionDeadline[], string>({
            query(uuidGroup: string) {
                return {
                    url: 'group/extra-session/get',
                    method: 'POST',
                    body: {
                        uuidGroup
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить дополнительные сессии');
                }
            },
            transformResponse: (result: { deadlines: ExtraSessionDeadline[] }) => {
                return result.deadlines;
            },
            providesTags: (result, error, arg) => [{ type: 'ExtraSession', id: arg }]
        }),
        deleteExtraSession: build.mutation<void, string[]>({
            query(uuids: string[]) {
                return {
                    url: 'group/extra-session/delete',
                    method: 'POST',
                    body: { uuids }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Удаление завершено успешно');
                } catch (error) {
                    toast.error('Ошибка удаления');
                }
            },
            invalidatesTags: ['GroupDebtors', 'ExtraSession']
        }),
        updateExtraSession: build.mutation<void, UpdateExtraSession>({
            query(body: UpdateExtraSession) {
                return {
                    url: 'group/extra-session/update',
                    method: 'POST',
                    body
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Дополнительная сессия обновлена');
                } catch (error) {
                    toast.error('Ошибка обновления');
                }
            },
            invalidatesTags: ['GroupDebtors', 'ExtraSession']
        }),

        getSubscribedAdditionalDisciplines: build.query<GroupDiscipline[], string>({
            query(uuidUser: string) {
                return {
                    url: 'group/users/get',
                    method: 'POST',
                    body: {
                        uuidUser
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список подписанных дисциплин студента');
                }
            },
            transformResponse: (result: { disciplines: GroupDiscipline[] }) => {
                return result.disciplines;
            },
            providesTags: (result, error, arg) => [{ type:'StudentsSubs', id: arg }]
        }),
        subscribeStudentsToDisciplines: build.mutation<void, SubscribeAdditionalDisciplinesRequest>({
            query(data) {
                return {
                    url: 'group/users/create',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Дополнительные дисциплины подписаны',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type:'StudentsSubs', id: arg.uuidUser[0] }]
        })
    })
});

export const {
    useGetGroupsTreeQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    useGetSubscribedDisciplinesQuery,
    useGetSubscribedIndividualsStudentDisciplinesQuery,
    useSubscribeToDisciplinesMutation,
    useGetUsersDeadlinesQuery,
    useSetUserDeadlinesMutation,
    useGetGroupDeadlinesQuery,
    useSetGroupDeadlinesMutation,
    useGetScoreCardQuery,
    useGetGroupsOfDisciplineQuery,
    useDeleteMaterialsDeadlinesMutation,
    useDeleteStudentsMaterialsDeadlinesMutation,
    useGetDebtorsByGroupsQuery,
    useCreateExtraSessionMutation,
    useGetExtraSessionsDeadlinesByGroupsQuery,
    useDeleteExtraSessionMutation,
    useUpdateExtraSessionMutation,
    useSubscribeStudentsToDisciplinesMutation,
    useGetSubscribedAdditionalDisciplinesQuery
} = groupsApi;
