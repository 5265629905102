import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';

import { store } from '@store/store';

import App from './App';


const initGtm = () => {
    const gtmKey = process.env.REACT_APP_GTM_KEY;

    if (gtmKey) {
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GTM_KEY || ''
        };
        
        TagManager.initialize(tagManagerArgs);
    }
};

initGtm();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
