import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { CreatePageData } from '@store/api/apiTypes';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useGetDisciplineMaterialsListQuery } from '@store/api/materialsApi';
import { useCreatePageMutation } from '@store/api/pageApi';
import CreatePageForm from '@views/methodist/Materials/Course/CreatePageForm';
import { CreatePageFormData } from '@views/methodist/Materials/Course/CreatePageForm/type';

import style from './CreatePage.module.scss';

const CreatePage = () => {
    const navigate = useNavigate();
    const { uuidSection, uuidDiscipline } = useParams();
    const [fetchCreatePage, { isSuccess }] = useCreatePageMutation();
    const groupsTree = useGetGroupsTreeQuery('');
    const materialsQuery = useGetDisciplineMaterialsListQuery(uuidDiscipline as string);

    const [defaultValuesForm] = useState<CreatePageFormData>({
        name: '',
        content: '',
        accessLimitation: false,
        blockedGroups: []
    });

    const submitFormHandler = (formData: CreatePageFormData) => {
        const transformedData: CreatePageData = {
            name: formData.name,
            content: formData.content,
            uuidParent: uuidSection ? uuidSection : uuidDiscipline!,
            blockedGroups: formData.blockedGroups || [],
            position: materialsQuery.isSuccess ? materialsQuery.data.length + 1 : 0
        };
        fetchCreatePage(transformedData);
    };

    useEffect(() => {
        if (isSuccess) {

            navigate(uuidSection ? './../..' : './..');
            console.log('🚀 ~ file: CreatePage.tsx ~ line 47 ~ useEffect ~ uuidSection', uuidSection);
        }

    }, [isSuccess]);

    if (groupsTree.isFetching) return <NewLoader />;

    return (
        <Main>
            <div className={style.title_wrapper}>
                <ButtonIcon
                    typeIcon={'arrowLink'}
                    onClick={() => navigate(uuidSection ? './../..' : './..')}
                />
                <h1>Добавить страницу</h1>
            </div>

            <CreatePageForm
                submitFormHandler={submitFormHandler}
                defaultValues={defaultValuesForm}
                groups={groupsTree.data}
            />
        </Main>
    );
};

export default CreatePage;
