import React, { PropsWithChildren, useState, useMemo, useEffect } from 'react';

import { NewTreeSelectProps } from '../NewTreeSelect';
import { getFlatOptionsWithParent, getFlatOptionsWithEndsChildren } from '../utils/flatOptions';

import { treeSelectContext } from './index';

interface DropDownSelectProps {
    isOpen: boolean;
    on: () => void;
    off: () => void;
}

type TreeSelectProviderProps = DropDownSelectProps & NewTreeSelectProps;

export const TreeSelectProvider: React.FC<PropsWithChildren<TreeSelectProviderProps>> = ({
    isOpen,
    on,
    off,
    children ,
    options,
    isMulti,
    value,
    onChange,
    selectableParent
}) => {
    const [selectedMultiValue, setSelectedMultiValue] = useState(isMulti ? value : []);

    const optionsMapWithParents = useMemo(() => getFlatOptionsWithParent(options), [options]);
    const optionsMapWithEndChildrens = useMemo(() => getFlatOptionsWithEndsChildren(options), [options]);

    const handleSelect = (optionValue: string) => {
        if (isMulti) {
            const optionData = optionsMapWithEndChildrens[optionValue];

            // если выбрали родителя
            if (optionData.endChildren.length) {

                // если в выбранных уже имеются все дочерние опции
                if (selectedMultiValue.filter(el => optionData.endChildren.includes(el)).length === optionData.endChildren.length) {
                    setSelectedMultiValue((prev) => {
                        return prev.filter(item => !optionData.endChildren.includes(item));
                    });
                } else {
                    setSelectedMultiValue((prev) => {
                        const prevSelected = [...prev];
                        optionData.endChildren.forEach(item => {
                            if (!prevSelected.includes(item)) {
                                prevSelected.push(item);
                            }
                        });
                        return prevSelected;
                    });
                }
            } else {
                setSelectedMultiValue(prev => {
                    if (prev.includes(optionValue)) {
                        return prev.filter(item => item !== optionValue);
                    } else {
                        return [...prev, optionValue];
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (!isOpen && isMulti) {
            setSelectedMultiValue(value);
        }
    }, [isOpen]);

    return (
        <treeSelectContext.Provider
            value={{
                isOpen,
                onOpen: on,
                onClose: off,
                options,
                optionsMapWithParents,
                optionsMapWithEndChildrens,
                handleSelect,
                selectedMultiValue,
                // @ts-expect-error в зависимости от значения isMulti должны быть разые типы value и onChange
                isMulti, value, onChange, selectableParent
            }}
        >
            {children}
        </treeSelectContext.Provider>
    );
};
