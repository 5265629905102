import React, { FC, useState } from 'react';

import cx from 'classnames';
import { FieldError } from 'react-hook-form';

import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import InputBase from '@components/core/inputs/InputBase/InputBase';

import style from './upload_video_block.module.scss';

interface UploadVideoBlockProps {
    className?: string;
    setVideoLinkHandler: (link: string) => void;
    defaultValue?: string;
    error: boolean | undefined;
}

const UploadVideoBlock: FC<UploadVideoBlockProps> = ({ className, setVideoLinkHandler, defaultValue = '', error }) => {
    const [videoLink, setVideoLink] = useState<string>(defaultValue);

    return (
        <div className={cx(style.wrapper, className)}>
            <div className={style.form_content}>
                <div className={style.placeholder}>
                    <IconUI typeIcon={'playVideo'} className={style.icon} />
                    <p className={style.text}>
                        Добавьте ссылку на видео
                        (Youtube,&nbsp;Vimeo,&nbsp;VK.&nbsp;Rutube,&nbsp;Яндекс&nbsp;диск,&nbsp;Google&nbsp;Drive)
                    </p>
                </div>
                <div className={style.input_wrapper}>
                    <InputBase
                        error={error}
                        name={''}
                        value={videoLink}
                        onChange={(e) => setVideoLink(e.target.value)}
                        placeholder={'Ссылка на видео'}
                        classNameWrapper={style.input}
                    />
                    <Button
                        btnType={'outline'}
                        typeIcon={'addCircle'}
                        className={style.button}
                        onClick={(e) => {
                            e.preventDefault();
                            setVideoLinkHandler(videoLink);
                        }}
                    >Добавить</Button>
                </div>
            </div>
        </div>
    );
};

export default UploadVideoBlock;
