import React, { FC } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-right-small.svg';
import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import ProgressBar from '@components/core/ProgressBar';
import { RoleCheck } from '@components/RoleCheck';
import { calculateLectureBlockProgress } from '@helpers/calculateLectureBlockProgress';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useGetLectureBlockProgressQuery } from '@store/api/lecturesApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { ILecture } from '@views/student/StudentCourse/Materials/type';

import style from './Lecture.module.scss';

const Lecture: FC<ILecture> = ({ lecture }) => {
    const lgDown = useBreakpoint('lg', 'down');
    const smDown = useBreakpoint('sm', 'down');

    const user = useAppSelector(selectApiUser);
    const lecturesBlockProgress = useGetLectureBlockProgressQuery(lecture.uuid as string);
    const navigate = useNavigate();

    const handleClickCard = () => {
        if (lgDown) {
            navigate(`lecture/${lecture.uuid}`);
        }
    };

    return (
        <div className={style.card} onClick={handleClickCard}>
            <div className={style.title_wrapper}>
                <IconUI typeIcon="lecture" />
                {
                    !smDown && <Link className={style.name} to={`lecture/${lecture.uuid}`}>
                        <h3>{lecture.name}</h3>
                    </Link>
                }
            </div>
            <div className={style.progress_bar_wrapper}>
                <div className={style.progress_bar_container}>
                    {smDown && <h3>{lecture.name}</h3>}
                    <RoleCheck roles={Role.Student}>
                        <ProgressBar completed={calculateLectureBlockProgress(lecturesBlockProgress.data)} />
                    </RoleCheck>
                </div>
                {lgDown ? <ArrowIcon /> : (
                    <Button
                        btnType="success"
                        onClick={() => navigate(`lecture/${lecture.uuid}`)}
                        disabled={user?.role === Role.Teacher}
                    >
                        Читать лекции
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Lecture;
