import React from 'react';

import TTabs from '@components/Tabs';
import TasksList from '@views/teacher/TeacherMain/TasksList';

import { useGradesContext } from '../TeacherMain/TeacherMain';
import style from './TasksVerification.module.scss';

const tabs = ['Все работы', 'Истекает срок', 'Просроченные'];

const TasksVerification = () => {
    const { workOnVerifieTabsCountAll, workOnVerifie } = useGradesContext();

    const panels = [
        <TasksList key="all-works" tasks={workOnVerifie.allWorks} filter="all-works" />,
        <TasksList key="all-works" tasks={workOnVerifie.deadlineExpiresWorks} filter="deadline-expires" />,
        <TasksList key="all-works" tasks={workOnVerifie.overdueWorks} filter="overdue" />
    ];

    return (
        <TTabs tabs={tabs} panels={panels} itemsInTab={workOnVerifieTabsCountAll} className={style.tabs} />
    );
};

export default TasksVerification;
