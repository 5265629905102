import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import classNames from 'classnames/bind';

import HelperText from '@components/core/inputs/HelperText/HelperText';
import { IStorageFile } from '@store/api/apiTypes';

import { FileCaption } from '../parts/FileCaption/FileCaption';
import { FilePlaceholder } from '../parts/FilePlaceholder';
import { IPlaceholderBackendData, TUploadFile } from '../type';
import { FilePhotoStage } from './FilePhotoStage';
import style from './fileuploadphoto.module.scss';
import { acceptPhotoTypes } from './validation';

const cx = classNames.bind(style);

export type FileUploadPhotoProps = {
    name: string;
    onChange: (file: TUploadFile | IStorageFile) => void;
    value?: TUploadFile | IStorageFile;
    error?: boolean;
    helperText?: string | null;
    disabled?: boolean;
    children?: ReactNode;
};

export const FileUploadPhoto: FC<FileUploadPhotoProps> = ({ name, onChange, value, error, helperText, disabled,children }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [api, setApi] = useState<IPlaceholderBackendData | null>({
        file: null,
        stage: 'default',
        isDragOver: false,
        inputRef,
        acceptTypes: acceptPhotoTypes
    });

    const [firstUpdate, setFirstUpdate] = useState(true);

    const deleteFile = () => {
        if (api?.inputRef?.current) {
            api.inputRef.current.value = '';
        }

        setApi({ ...api, file: null, stage: 'default' });
        onChange(null);
    };

    // Set value form
    useEffect(() => {
        onChange(api?.file);
    }, [api?.file]);

    // Default value form
    useEffect(() => {
        if (value && firstUpdate) {
            setApi({ ...api, file: value, stage: 'uploading' });
            setFirstUpdate(false);
        }
    }, [value]);

    return (
        <div role="presentation" className={cx('root')}>
            <div
                className={cx(
                    'root__inner',
                    {
                        ['root--select']: api?.isDragOver,
                        ['root--droped']: api?.file,
                        ['root--error']: error,
                        ['root--disabled']: disabled
                    }
                )}
            >
                <FilePlaceholder name={name} api={api} setApi={setApi} disabled={disabled}>
                    <FilePhotoStage api={api} deleteFile={deleteFile} disabled={disabled} >
                        {children}
                    </FilePhotoStage>
                </FilePlaceholder>
            </div>

            <FileCaption type="block" size="200" />
            {
                helperText && <HelperText message={helperText} />
            }
        </div>
    );
};
