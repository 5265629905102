import React from 'react';

import clsx from 'clsx';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-acc.svg';
import { Drawer } from '@components/Drawer';
import Header from '@components/Header';
import { SSOLink } from '@components/Linkify/SSOLink';
import Logo from '@components/Logo';
import MethodistMenu from '@components/methodist/MethodistMenu';
import RouterTabs from '@components/RouterTabs';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useToggle } from '@hooks/useToggle.hook';

import style from './StudentHeader.module.scss';

const tabs = [
    {
        name: 'Обучение',
        path: 'education',
        component: <><span>10</span></>
    },
    {
        name: 'Мероприятия',
        path: 'events'
    },
    {
        name: 'Контакты',
        path: 'contacts'
    }
];

const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const lgUp = useBreakpoint('lg', 'up');
    return lgUp ? <React.Fragment>{children}</React.Fragment> : <div className={style.wrapper}>{children}</div>;
};

const StudentHeader = () => {
    const mdUp = useBreakpoint('md', 'up');

    const [moreIsOpen, { toggle }] = useToggle();

    return (
        <Header className={style.header}>
            <Wrapper>
                {!mdUp && <Drawer tabs={tabs} />}
                <Logo />

                {mdUp && (
                    <div className={style['controls-wrapper']}>
                        <RouterTabs tabs={tabs} className={style.tabs} end={false} />
                        <button className={style.more} onClick={toggle}>
                            Еще
                            <ArrowIcon className={clsx(style.arrow, moreIsOpen && style['arrow-rotate'])} />
                        </button>
                    </div>
                )}
                {/* <LibButton /> */}

            </Wrapper>

            <MethodistMenu />

            {mdUp && (
                <div className={clsx(style.popover, moreIsOpen && style['popover-opened'])}>
                    <div className={style.container}>
                        <div className={style.content}>
                            <h4>Онлайн библиотеки</h4>
                            <SSOLink href='biblioclub.ru' value={'Университетская библиотека онлайн'} domain="biblioclub.ru"></SSOLink>
                        </div>

                    </div>
                </div>
            )}

        </Header>
    );
};

export default StudentHeader;
