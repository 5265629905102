import { Outlet } from 'react-router-dom';

import { Helmet } from '@components/Helmet';
import RouterTabs from '@components/RouterTabs/RouterTabs';

import { useGradesContext } from '../TeacherMain';
import style from './teacher_main.module.scss';


const tabs = [
    {
        name: 'Работы на проверку',
        path: ''
    },
    {
        name: 'Проверенные работы',
        path: 'tasksVerified'
    },
    {
        name: 'Работы дублёра',
        path: 'substitute'
    },
    {
        name: 'Преподаваемые дисциплины',
        path: 'teachingDisciplines'
    }
];

const TeachingTabs = () => {
    const gradesContext = useGradesContext();

    return (
        <>
            <Helmet title="Преподавание – Работы на проверку" />
            <h1 className={style.title}>Преподавание</h1>
            <RouterTabs
                tabs={tabs}
                itemsInTab={gradesContext.itemsInMainTabCount}
                tabsType={'btns'}
                end={true}
                className={style.tabs}
            />
            <Outlet context={gradesContext} />
        </>
    );
};

export default TeachingTabs;
