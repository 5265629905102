import React, { useState } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { DNDWrapper } from '@components/core/DNDWrapper';
import ButtonDrop from '@components/core/inputs/Button/ButtonDrop';
import { DropMenuItem } from '@components/core/styledWrappers/DropMenu';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { PositionsItemsList } from '@store/api/apiTypes';
import { useCreateFileMutation } from '@store/api/fileApi';
import { useCreateLinkMutation } from '@store/api/linkApi';
import { useGetDisciplineMaterialsListQuery, useUpdatePositionsMaterialsListMutation } from '@store/api/materialsApi';
import { Role } from '@store/Roles';
import AddFileModal from '@views/methodist/Materials/Course/AddFileModal';
import AddLinkModal from '@views/methodist/Materials/Course/AddLinkModal';
import { EditCourseFormData } from '@views/methodist/Materials/Course/EditCourse/BasicCourse/EditCourseForm/type';
import { useEditCourseFormContext } from '@views/methodist/Materials/Course/EditCourse/EditCourse';
import MaterialsListItem from '@views/methodist/Materials/Course/Materials/MaterialsListItem';

import style from './materials.module.scss';

const Materials = () => {
    const navigate = useNavigate();
    const { uuidDiscipline } = useParams();

    const [fetchCreateLink] = useCreateLinkMutation();
    const { data: materials, isSuccess: isMaterialsSuccess } = useGetDisciplineMaterialsListQuery(uuidDiscipline as string);
    const [fetchCreateFile, { isSuccess: isCreateFileSuccess }] = useCreateFileMutation();

    const { setFormContext } = useEditCourseFormContext();

    const { control, getValues } = useFormContext<EditCourseFormData>();

    const { fields } = useFieldArray({
        control,
        name: 'materials'
    });

    const [isOpenAddLinkModal, setIsOpenAddLinkModal] = useState<boolean>(false);
    const [isOpenAddFileModal, setIsOpenAddFileModal] = useState<boolean>(false);

    const [fetchUpdatePositions] = useUpdatePositionsMaterialsListMutation();

    const updatePositionsMaterialsHandler = (reorderMaterialsList: PositionsItemsList) => {
        fetchUpdatePositions({ parentUuid: uuidDiscipline as string, positions: reorderMaterialsList });
    };

    const isDisabled = !checkRole(Role.Methodist);

    return (
        <div>
            <h2 className={style.fieldset_title}>Материалы курса</h2>

            <ul className={style.content_list}>
                {
                    (fields.length > 1 && !isDisabled) ? (
                        <DNDWrapper items={fields} onOrder={updatePositionsMaterialsHandler}>
                            {fields.map((item) => {
                                return (
                                    <MaterialsListItem
                                        key={item.uuid}
                                        material={item}
                                        className={style.content_list__item}
                                    />
                                );
                            })}
                        </DNDWrapper>
                    ) : (
                        fields.map((item) => {
                            return (
                                <MaterialsListItem
                                    key={item.uuid}
                                    material={item}
                                />
                            );
                        })
                    )
                }
            </ul>
            <RoleCheck roles={Role.Methodist}>
                <ButtonDrop
                    btnType='outline'
                    typeIcon='addCircle'
                    buttonChild='Добавить'
                >
                    <DropMenuItem
                        onClick={() => setIsOpenAddLinkModal(true)}
                    >
                        Ссылка
                    </DropMenuItem>
                    <DropMenuItem
                        onClick={() => {
                            setFormContext(getValues());
                            navigate('add-page');
                        }}
                    >
                        Страница
                    </DropMenuItem>
                    <DropMenuItem
                        onClick={() => setIsOpenAddFileModal(true)}
                    >
                        Загрузить файл
                    </DropMenuItem>
                </ButtonDrop>
            </RoleCheck>


            <AddLinkModal
                uuidParent={uuidDiscipline as string}
                isOpen={isOpenAddLinkModal}
                onClose={setIsOpenAddLinkModal}
                createLink={fetchCreateLink}
                materials={materials || []}
                isMaterialsSuccess={isMaterialsSuccess}
            />
            <AddFileModal
                uuidParent={uuidDiscipline as string}
                isOpen={isOpenAddFileModal}
                onClose={setIsOpenAddFileModal}
                materials={materials || []}
                isMaterialsSuccess={isMaterialsSuccess}
                createFile={fetchCreateFile}
                isFileCreationSuccess={isCreateFileSuccess}
            />
        </div>
    );
};

export default Materials;
