import { useEffect, useState } from 'react';

import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { DNDWrapper } from '@components/core/DNDWrapper';
import { ChapterItem } from '@components/core/DNDWrapper/DNDChildren/ChapterItem';
import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import Sidebar from '@components/methodist/Sidebar';
import Drawer from '@components/methodist/Sidebar/Drawer';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { SaveState } from '@components/SaveState/SaveState';
import { maxPosition } from '@helpers/findMaxPosition';
import { CreateLectureChapterRequest, PositionsItemsList } from '@store/api/apiTypes';
import {
    useCreateLectureChapterMutation,
    useDeleteLectureChapterMutation,
    useGetLectureByIdQuery,
    useUpdateLectureChaptersPositionsMutation
} from '@store/api/lecturesApi';
import { Role } from '@store/Roles';

import style from './edit_lecture.module.scss';

const EditLecture = () => {
    const { uuidLecture, uuidChapter } = useParams();
    const [isSaving, setIsSaving] = useState<boolean>(true);
    const navigate = useNavigate();

    const lectureMain = useGetLectureByIdQuery(uuidLecture as string);
    const [createChapter, createChapterRes] = useCreateLectureChapterMutation();

    const [deleteChapter] = useDeleteLectureChapterMutation();

    const [fetchUpdateChapterPositions] = useUpdateLectureChaptersPositionsMutation();

    const deleteChapterHandler = (uuid: string) => {

        if (lectureMain.isSuccess && uuid == uuidChapter) {
            const currIdx = lectureMain.data.chapters.findIndex(chapter => chapter.uuid === uuid);
            const maxIdx = lectureMain.data.chapters.length ? lectureMain.data?.chapters.length - 1 : 0;
            let newIdx = 0;

            if (currIdx == maxIdx) { newIdx = maxIdx - 1; }
            else if (currIdx == 0 && maxIdx === 0) { newIdx = -1; }
            else { newIdx = currIdx + 1; }

            if (newIdx !== -1) {
                const newChapter = lectureMain.data.chapters[newIdx].uuid;
                navigate(`./chapter/${newChapter}`);
            } else {
                navigate('./');
            }
        }

        deleteChapter(uuid);
    };

    const createChapterHandler = () => {
        let newPosition = 1;

        if (lectureMain.isSuccess) {
            newPosition = maxPosition(lectureMain.data.chapters) + 1;
        }

        const request: CreateLectureChapterRequest={
            name: 'Новая глава',
            uuidLecture: uuidLecture!,
            content: ' ',
            position: newPosition
        };

        createChapter(request);
    };

    useEffect(() => {
        if (lectureMain.isSuccess) {
            if (uuidChapter) {
                navigate(`./chapter/${uuidChapter}`);
            } else {
                if (lectureMain.data.chapters.length) {
                    navigate(`./chapter/${lectureMain.data.chapters[0].uuid}`);
                } else {
                    createChapterHandler();
                }
            }
        }

    },[lectureMain.isLoading, lectureMain.isSuccess, lectureMain.isError]);

    useEffect(()=>{
        if(createChapterRes.isSuccess) {
            navigate(`./chapter/${createChapterRes.data.uuid}`);
        }
    },[createChapterRes.isLoading, createChapterRes.isSuccess]);

    const updateChaptersPositionsHandler = (reorderChaptersList: PositionsItemsList) => {
        fetchUpdateChapterPositions({ positions: reorderChaptersList, parentUuid: uuidLecture as string });
    };

    // Статус сохранения
    if (lectureMain.isLoading) return <NewLoader />;

    return (
        <div className={style.content_wrapper}>
            <Sidebar className={style.sidebar}>
                <Drawer noScroll>
                    <div className={style.inner}>
                        <div className={style.title_wrapper}>
                            <h2 className={style.title}>Главы лекции</h2>

                            <RoleCheck roles={Role.Methodist}>
                                <Button
                                    btnType={'outline'}
                                    typeIcon={'addCircle'}
                                    fullWidth={true}
                                    onClick={createChapterHandler}
                                >
                                    Добавить главу
                                </Button>
                            </RoleCheck>
                        </div>

                        <ul className={style.scrolled}>
                            {
                                lectureMain.isSuccess ? (
                                    <>
                                        {checkRole(Role.Methodist) ? (
                                            <DNDWrapper items={lectureMain.data.chapters} onOrder={updateChaptersPositionsHandler}>
                                                {lectureMain.data.chapters.map(item => (
                                                    <ChapterItem
                                                        key={item.uuid}
                                                        chapterItem={item}
                                                        onDelete={deleteChapterHandler}
                                                    />
                                                ))}
                                            </DNDWrapper>
                                        ) : (
                                            <div>
                                                {lectureMain.data.chapters.map(item => (
                                                    <ChapterItem
                                                        key={item.uuid}
                                                        chapterItem={item}
                                                        onDelete={deleteChapterHandler}
                                                        disabled
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </>
                                ) : null
                            }
                        </ul>
                    </div>
                </Drawer>
            </Sidebar>

            <Main className={style.main}>
                <Grid className={style.grid}>
                    <Column size={10}>
                        <div className={style.button_wrapper}>
                            <Button
                                btnType={'secondary'}
                                onClick={() => {navigate('./../../../..');} }
                            >
                                    Закрыть
                            </Button>

                            <RoleCheck roles={Role.Methodist}>
                                <SaveState isSaving={isSaving} />
                            </RoleCheck>
                        </div>
                        <div className={style.lecture_title_wrapper}>
                            <ButtonIcon
                                typeIcon={'prev'}
                                onClick={() => navigate('./../../../..')}
                            />
                            <h3>{lectureMain.data?.name}</h3>
                        </div>

                        <div className={style.form}>
                            <Outlet context={setIsSaving} />
                        </div>
                    </Column>
                </Grid>
            </Main>
        </div>
    );
};

export default EditLecture;
