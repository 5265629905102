import { FC } from 'react';

import cx from 'classnames';

import style from './Message.module.scss';

export interface IMessageChatProps {
    own?: boolean;
    children: React.ReactNode;
    photo?: boolean;
}

const Message:FC<IMessageChatProps> = ({
    own,
    children,
    photo
}) => {

    return (
        <div className={cx(style.container, own && style.own)}>
            <div className={cx(style.bubble, photo && style.noPseudo)}>
                <div className={cx(style.inner, photo && style.photo)}>
                    {
                        children
                    }
                </div>
            </div>
        </div>
    );
};

export default Message;
