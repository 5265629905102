import { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import classNames from 'classnames/bind';

import style from './icon_button.module.scss';

const cx = classNames.bind(style);

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    children?: ReactNode;
}

const IconButton: FC<IProps> = ({ className, children, ...props }) => {
    return (
        <button className={cx('root', className)} type="button" {...props}>
            {children}
        </button>
    );
};

export default IconButton;
