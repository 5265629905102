import { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { usePopper } from 'react-popper';
import { DateTime } from 'ts-luxon';

import EventList from '@components/core/Calendar/EventList';
import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { checkRole } from '@components/RoleCheck';
import { dateConvert, sortByTime } from '@helpers/date';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Event } from '@store/api/apiTypes';
import { Role } from '@store/Roles';
import { InfoEvent, SelectDayData, VisibleAllEvents } from '@type/eventCalendar';

import style from './cell.module.scss';

interface ICell {
    dayItem: DateTime;
    events: Event[];
    visibleAllEvents: VisibleAllEvents;
    setVisibleAllEvents: (obj: VisibleAllEvents) => void
    infoEvent: InfoEvent;
    setInfoEvent: Dispatch<SetStateAction<InfoEvent>>
    setSelectDayData: (data: SelectDayData) => void;
    selectedDate?: DateTime,
    eventsRef?: MutableRefObject<HTMLDivElement | null>;
    today: DateTime
}

const maxVisibleEvents = 4;

const Cell: FC<ICell> = ({
    dayItem,
    events,
    visibleAllEvents,
    setVisibleAllEvents,
    infoEvent,
    setInfoEvent,
    setSelectDayData,
    selectedDate,
    eventsRef,
    today
}) => {
    const lgDown = useBreakpoint('lg', 'down');

    const isNotMethodist = !checkRole([Role.Methodist, Role.Inspector]);

    const [referenceElement, setReferenceElement] = useState<HTMLLIElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'auto',
        modifiers: [{
            name: 'flip',
            enabled: true,
            phase: 'main',
            options: {
                allowedAutoPlacements: ['right', 'right-start', 'right-end', 'left', 'left-start', 'left-end']
            }
        },
        {
            name: 'arrow',
            enabled: true,
            phase: 'main',
            options: { element: arrowElement }
        },
        {
            name: 'offset',
            options: {
                offset: [0, 8]
            }
        }]
    });

    const isCurrentDay = (value: DateTime) => DateTime.now().hasSame(value, 'day');
    const isSelectedDay = (value: DateTime, selected?: DateTime) => {
        if (!selected) return false;
        return selected.hasSame(value, 'day');
    };

    const filterEvents = useMemo(() => {
        return sortByTime<any>(events.filter((event: Event) => {
            const dt = dateConvert(event.startEventAt).toFormat('X');
            return dt >= dayItem.startOf('day').toFormat('X') &&
                dt <= dayItem.endOf('day').toFormat('X');
        }), 'startEventAt', 'ask');
    }, [events]);

    const setDayData = () => {
        setSelectDayData({
            dayItem,
            events: filterEvents,
            visibleAllEvents,
            setVisibleAllEvents,
            infoEvent,
            setInfoEvent
        });
    };

    const handleClickDay = () => {
        if (lgDown && isNotMethodist) {
            setDayData();
            if (eventsRef?.current) {
                const topCoordinate = eventsRef.current.offsetTop;
                window.scrollTo({ top: topCoordinate, behavior: 'smooth' });
            }
        }
    };

    useEffect(() => {
        if (isCurrentDay(dayItem) && lgDown && isNotMethodist) {
            setDayData();
        }
    }, []);

    return (
        <>
            <div
                className={cx(
                    style.wrapper,
                    {
                        [style['wrapper--student']]: isNotMethodist,
                        [style['day--current-day']]: isNotMethodist && isCurrentDay(dayItem),
                        [style['wrapper--selected']]: isNotMethodist && isSelectedDay(dayItem, selectedDate)
                    }
                )}
                onClick={handleClickDay}
            >
                {filterEvents.length > 0 && <div className={cx(style.status, isCurrentDay(dayItem) && style['status--today'])} />}
                <div className={cx(style.show_day, isNotMethodist && style['show_day--student'])}>
                    <span className={cx(
                        style.day,
                        {
                            [style['day--student']]: isNotMethodist,
                            [style['day--current']]: !(isNotMethodist && lgDown) && isCurrentDay(dayItem),
                            [style['day--current-student']]: isNotMethodist && isCurrentDay(dayItem)
                        }
                    )}>{dayItem?.day}</span>
                </div>
                {!(lgDown && isNotMethodist) && (
                    <div className={cx(
                        style['event_list_wrapper'],
                        {
                            [style['event_list_wrapper--methodist']]: checkRole(Role.Methodist),
                            [style['event_list_wrapper--student']]: !checkRole(Role.Methodist)
                        }
                    )}>
                        {
                            visibleAllEvents.currentDayId === dayItem.toFormat('X') && visibleAllEvents.isVisible
                                ? null
                                : (
                                    <EventList
                                        events={filterEvents}
                                        dayItem={dayItem}
                                        infoEvent={infoEvent}
                                        setInfoEvent={setInfoEvent}
                                        setReferenceElement={setReferenceElement}
                                        setPopperElement={setPopperElement}
                                        setArrowElement={setArrowElement}
                                        attributes={attributes}
                                        arrowStyle={styles.arrow}
                                        popperStyle={styles.popper}
                                        today={today}
                                    />
                                )
                        }

                    </div>
                )}

                {
                    isNotMethodist && filterEvents.length > maxVisibleEvents && !lgDown && (
                        <div className={style.button_wrapper}>
                            <Button
                                btnType={'clear'}
                                className={style.button}
                                onClick={() => setVisibleAllEvents({
                                    isVisible: true,
                                    currentDayId: dayItem.toFormat('X')
                                })}
                            >
                                + {filterEvents.length - maxVisibleEvents} ещё
                            </Button>
                        </div>
                    )}
                {
                    (visibleAllEvents.currentDayId === dayItem.toFormat('X') && visibleAllEvents.isVisible) && (
                        <div className={style.visible_events}>
                            <div className={style.visible_events__title_wrapper}>
                                <span>{dayItem.setLocale('ru').toFormat('d MMMM')}</span>
                                <ButtonIcon
                                    typeIcon="close"
                                    onClick={() => {
                                        setVisibleAllEvents({
                                            isVisible: false,
                                            currentDayId: null
                                        });
                                        setInfoEvent({ isVisible: false, currentEventId: null });
                                    }} />
                            </div>
                            
                            <div className={style.visible_events__event_list_wrapper}>
                                <EventList
                                    events={filterEvents}
                                    dayItem={dayItem}
                                    infoEvent={infoEvent}
                                    setInfoEvent={setInfoEvent}
                                    setReferenceElement={setReferenceElement}
                                    setPopperElement={setPopperElement}
                                    setArrowElement={setArrowElement}
                                    attributes={attributes}
                                    arrowStyle={styles.arrow}
                                    popperStyle={styles.popper}
                                    today={today}
                                />
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
};

export default Cell;
