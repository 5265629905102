import React, { Dispatch, FC, SetStateAction } from 'react';

import classNames from 'classnames/bind';
import { toast } from 'react-toastify';

import { preventBrowserDefaults } from '@helpers/events';
import { IStorageFile } from '@store/api/apiTypes';

import { IPlaceholderBackendData, TUploadFile } from '../../type';
import { FileHiddenInput } from './FileHiddenInput';
import styles from './fileplaceholder.module.scss';

const cx = classNames.bind(styles);

interface IFilePlaceholderProps {
    children?: React.ReactNode;
    api: IPlaceholderBackendData | null;
    setApi?: Dispatch<SetStateAction<any>>;
    name?: string;
    isMulti?: boolean;
    maxSizeFile?: number;
    disabled?: boolean;
    droppable?: boolean;
}

export const FilePlaceholder: FC<IFilePlaceholderProps> = ({
    children,
    api,
    setApi,
    name,
    isMulti,
    maxSizeFile,
    disabled,
    droppable
}) => {
    // Process Files
    const processFiles = (file: TUploadFile | TUploadFile[], isDragOver: boolean) => {
        if (!setApi) return;

        if (isMulti && Array.isArray(file)) {

            const filterFiles = file.filter(item => {
                if (item && maxSizeFile) {
                    return item.size < (maxSizeFile * 1024 * 1024);
                }
            });

            if (file.length !== filterFiles.length) {
                toast.error(`Максимальный размер файла ${maxSizeFile} Мб`);
            }

            setApi((prevState: IPlaceholderBackendData) => ({
                ...prevState,
                files: [...prevState.files as TUploadFile[] | IStorageFile[], ...filterFiles],
                isDragOver,
                stage: 'default'
            }));
        } else {
            if (file && maxSizeFile) {
                if ('size' in file && (file.size > (maxSizeFile * 1024 * 1024))) {
                    toast.error(`Максимальный размер файла ${maxSizeFile} Мб`);
                    return;
                }
            }

            setApi((prevState: IPlaceholderBackendData) => ({
                ...prevState,
                file: file,
                isDragOver,
                stage: 'uploading'
            }));
        }
    };

    // Drags Events
    const dragHandler = (e: React.DragEvent<HTMLDivElement>) => {
        if (droppable) {
            preventBrowserDefaults(e);
            const files: File[] = [];
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                const fileItem = e.dataTransfer.files.item(i);
                if (fileItem && api?.acceptTypes?.includes(fileItem.type)) {
                    files.push(fileItem);
                }
            }
            if (files.length) {
                processFiles(files[0], false);
            }
        }

    };

    const onDragOver = (e: React.DragEvent) => {
        if (!setApi || !droppable) return;
        preventBrowserDefaults(e);

        setApi({ ...api, isDragOver: true });
    };

    const onDragLeave = () => {
        if (!setApi || !droppable) return;

        setApi({ ...api, isDragOver: false });
    };

    const openDialog = () => {
        if (disabled) return;
        if (!api?.file || isMulti) {
            api?.inputRef?.current?.click();
        }
    };

    // Input Change
    const fileChange = (file: TUploadFile | TUploadFile[]) => {
        processFiles(file, false);
    };

    return (
        <div
            className={cx('root')}
            role="presentation"
            onClick={openDialog}
            onKeyDown={openDialog}
            onDrop={dragHandler}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
        >
            <FileHiddenInput
                name={name}
                types={api?.acceptTypes}
                fileChange={fileChange}
                inputRef={api?.inputRef}
                isMulti={isMulti}
            />
            {children}
        </div>
    );
};
