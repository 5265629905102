import { FC } from 'react';

import { Navigate, Outlet, useOutlet, useOutletContext, useParams } from 'react-router-dom';

import { NewLoader } from '@components/core/NewLoader';
import { findCategory, sortCategories } from '@helpers/categories';
import { ICategory } from '@store/api/apiTypes';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';

interface MaterialsPageContext {
    category?: ICategory;
    selectedMethodist?: string;
}

export const useCategory = () => {
    return useOutletContext<MaterialsPageContext>();
};

const MaterialsPage: FC = () => {
    const { uuidCategory, uuidMethodist } = useParams();

    const outlet = useOutlet();

    const { data, isLoading } = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000');

    const category = findCategory(data, uuidCategory);

    const sortedCategories = sortCategories(data ?? []);

    if (isLoading) return <NewLoader />;

    return (
        <>
            {
                outlet ?
                    <Outlet context={{ category, selectedMethodist: uuidMethodist }} /> :
                    (sortedCategories.length ?
                        <Navigate to={sortedCategories[0].uuid} /> :
                        <NewLoader />
                    )
            }
        </>
    );
};

export default MaterialsPage;
