import React, { FC } from 'react';

import cx from 'classnames';

import style from './DataList.module.scss';

export interface IDataListProps {
    className?: string;
    children?: React.ReactNode;
    height?: string;
}

export const DataList: FC<IDataListProps> = ({ children, className  }) => {

    return <div className={cx(style.container, className)}>
        { children }
    </div>;
};