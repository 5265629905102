import { FC, useEffect } from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Column, Grid } from '@components/core/grid';
import { NewLoader } from '@components/core/NewLoader';
import { DataList, DataListCell } from '@components/DataList';
import { Pagination, usePagination } from '@components/Pagination';
import { Score } from '@components/Score';
import { CloseTypes } from '@store/api/apiTypes';
import { GradeSheetItem } from '@type/grades';

import style from './StatementsList.module.scss';

interface IStatementsList {
    rows?: GradeSheetItem[];
    isFetching: boolean;
}

const FilesList: FC<IStatementsList> = ({ rows, isFetching = false }) => {

    const { handleSliceData, setTotal, paginationProps } = usePagination<GradeSheetItem>({});

    const getEmptyMessage = () => {
        return (
            <div className={style.empty_message_text}>
                {rows ? 'Для формирования отчета укажите группу, дисциплину и период' : ''}
            </div>
        );
    };

    useEffect(() => {
        setTotal(rows?.length || 0);
    }, [rows]);

    if (isFetching) return <NewLoader />;

    return (
        <>
            <DataList className={style.list_wrapper}>
                <Grid gridTemplateColumns="5% 25% 5% 60%" className={style.data_list__header}>
                    <>
                        <Column>
                            <DataListCell className={style.column_label}>№</DataListCell>
                        </Column>
                        <Column>
                            <DataListCell className={style.column_label}>ФИО</DataListCell>
                        </Column>
                        <Column>
                            <DataListCell className={cx(style.column_label)}>Зачетка</DataListCell>
                        </Column>

                        <Column>
                            <DataListCell className={cx(style.column_label, style.flex_end)}>Оценка</DataListCell>
                        </Column>
                    </>
                </Grid>
                {
                    !rows?.length ?
                        <div className={style.empty_message}>{getEmptyMessage()}</div>
                        :
                        handleSliceData(rows).map((row, index) => {
                            return (
                                <div key={row.uuid}>
                                    <Link to={`/users/${row.uuid}/user`} className={style.rowLink}>
                                        <Grid gridTemplateColumns="5% 25% 5% 60%" className={style.list_item}>

                                            <Column>
                                                {index + 1}
                                            </Column>

                                            <Column>
                                                {row.firstName} {row.patronymic} {row.lastName}
                                            </Column>

                                            <Column>
                                                {row.recordBookNumber}
                                            </Column>

                                            <Column className={style.column_content}>
                                                <Score score={row.grade} closeType={CloseTypes.Assessment} />
                                            </Column>
                                        </Grid>
                                    </Link>
                                </div>
                            );
                        })
                }
            </DataList>

            <Pagination className={style.pagination} {...paginationProps} />
        </>
    );
};

export default FilesList;
