import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Column, Grid } from '@components/core/grid';
import { NewLoader } from '@components/core/NewLoader';
import { DataList, DataListCell } from '@components/DataList';
import { Helmet } from '@components/Helmet';
import { TableMenu } from '@components/Menu';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { Pagination, usePagination } from '@components/Pagination';
import { checkRole } from '@components/RoleCheck';
import { dateConvert } from '@helpers/date';
import { getEvents } from '@helpers/events';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { MenuItem } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Event } from '@store/api/apiTypes';
import { useDeleteEventMutation, useGetPastEventsQuery, useGetStudentPastEventsQuery, useGetTeacherPastEventsQuery } from '@store/api/eventApi';
import { Role } from '@store/Roles';

import style from './FinishedEvents.module.scss';

const FinishedEvents = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const lgDown = useBreakpoint('lg', 'down');
    const smDown = useBreakpoint('sm', 'down');

    const isMethodist = checkRole([Role.Methodist, Role.Inspector]);
    const isStudent = checkRole(Role.Student);
    const isTeacher = checkRole(Role.Teacher);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [deleteEvent, { isError: deleteErr, isSuccess: deleteSuc }] = useDeleteEventMutation();

    const requestData = {
        limit: 300,
        offset: 0
    };

    const methodistPastEvents = useGetPastEventsQuery(isMethodist ? requestData : skipToken);
    const studentPastEvents = useGetStudentPastEventsQuery(isStudent ? requestData : skipToken);
    const teacherPastEvents = useGetTeacherPastEventsQuery(isTeacher ? requestData : skipToken);

    const pastEvents = getEvents(methodistPastEvents, studentPastEvents, teacherPastEvents);

    const { handleSliceData, setTotal, paginationProps } = usePagination<Event>({});

    useEffect(() => {
        setTotal(pastEvents?.data?.length || 0);
    }, [pastEvents?.data?.length]);

    useEffect(() => {
        if (deleteSuc) {
            setShowDeleteModal(false);
        }
    }, [deleteSuc]);

    if (pastEvents.isError || pastEvents.isLoading) return <NewLoader />;

    const getGridTemplateColumns = () => {
        if (lgDown && !smDown) return '7fr 3fr';
        if (smDown) return '1fr';
        return '8fr 4fr 3fr';
    };

    const editEventHandler = (eventInfo: Event) => {
        const navigateState = {
            state: {
                pathname: location.pathname,
                currentEvent: eventInfo,
                actionType: 'edit'
            }
        };

        navigate('../create-event', navigateState);
    };

    return (
        <>
            <Helmet title="Мероприятия – Прошедшие" />
            <div className={cx(style.table, !isMethodist && style.student_table)} style={{ width: '100%' }}>
                <DataList className={style['list-container']}>
                    {!lgDown && (
                        <Grid gridTemplateColumns="8fr 4fr 3fr" className={style.header}>
                            <Column>
                                <DataListCell>Мероприятие</DataListCell>
                            </Column>
                            <Column>
                                <DataListCell>Преподаватель</DataListCell>
                            </Column>
                            <Column>
                                <DataListCell className={style['header--last']}>Дата проведения</DataListCell>
                            </Column>
                        </Grid>
                    )}

                    {handleSliceData(pastEvents?.data || []).map((row, index, array) => (
                        <div key={row.eventUuid}>
                            <Grid
                                gridTemplateColumns={getGridTemplateColumns()}
                                className={cx(
                                    'row--selectable',
                                    style.row,
                                    {
                                        [style['first-row']]: index === 0,
                                        [style['last-row']]: index === array.length - 1
                                    }
                                )}>
                                {!smDown && (
                                    <Column className={style.cell}>
                                        <Link to={`${row.disciplineUuid}/${row.eventUuid}`}>
                                            <p className={style.event_name}>{row.name}</p>
                                            <p className={style.discipline_name}>{row.disciplineName}</p>
                                        </Link>
                                    </Column>
                                )}
                                {!lgDown && (
                                    <Column className={style.cell}>
                                        {row.teacherInfo ? `${row.teacherInfo.lastName} ${row.teacherInfo.firstName} ${row.teacherInfo.patronymic}` : ''}
                                    </Column>
                                )}
                                <Column className={style.cell}>
                                    {smDown && (
                                        <Link to={`${row.disciplineUuid}/${row.eventUuid}`}>
                                            <p className={style.event_name}>{row.name}</p>
                                            <p className={style.discipline_name}>{row.disciplineName}</p>
                                        </Link>
                                    )}
                                    {lgDown && (
                                        <p className={style['gray-text']}>{row.teacherInfo ? `${row.teacherInfo.firstName} ${row.teacherInfo.patronymic} ${row.teacherInfo.lastName}` : ''}</p>
                                    )}
                                    <p className={style['gray-text']}>{dateConvert(row.startEventAt).toFormat('dd.LL.yyyy в HH:mm')}</p>
                                    {checkRole(Role.Methodist) && (
                                        <div className={style.menu}>
                                            <TableMenu>
                                                <MenuItem className={style.menu__item} onClick={() => editEventHandler(row)}>
                                                    Редактировать
                                                </MenuItem>

                                                <MenuItem
                                                    className={style.menu__item}
                                                    onClick={() => {
                                                        setShowDeleteModal(true);
                                                        setCurrentEvent(row);
                                                    }}>
                                                    Удалить
                                                </MenuItem>
                                            </TableMenu>
                                        </div>
                                    )}
                                </Column>
                            </Grid>
                        </div>
                    ))}
                </DataList>
                <Pagination {...paginationProps} />


                {
                    !!pastEvents.data?.length || <div className={style.no_results}>Здесь будут отображаться прошедшие мероприятия</div>
                }

                <DeleteItemModal
                    isOpen={showDeleteModal}
                    onClose={() => {
                        setShowDeleteModal(false);
                    }}
                    onDelete={() => {
                        deleteEvent(currentEvent?.eventUuid || '');
                    }}
                    itemName={currentEvent?.name as string}
                    itemType="event"
                />
            </div>
        </>
    );
};

export default FinishedEvents;
