import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { DateTime } from 'ts-luxon';

import DuplicateEventPopup from '@components/core/Calendar/DuplicateEventPopup';
import EventPopup from '@components/core/Calendar/EventPopup';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { dateConvert } from '@helpers/date';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Event } from '@store/api/apiTypes';
import { useDeleteEventMutation } from '@store/api/eventApi';
import { selectJwtUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { InfoEvent } from '@type/eventCalendar';

import { EventSideBar } from '../EventSideBar';
import style from './event_list.module.scss';


interface IEventList {
    events: Event[];
    dayItem: DateTime;
    infoEvent: InfoEvent;
    setInfoEvent: Dispatch<SetStateAction<InfoEvent>>
    setReferenceElement?: Dispatch<SetStateAction<HTMLLIElement | null>>;
    setPopperElement?: Dispatch<SetStateAction<HTMLDivElement | null>>;
    setArrowElement?: Dispatch<SetStateAction<HTMLDivElement | null>>;
    attributes?: { [key: string]: { [key: string]: string; } | undefined; };
    arrowStyle?: React.CSSProperties;
    popperStyle?: React.CSSProperties;
    today: DateTime;
}

const EventList: FC<IEventList> = ({
    events,
    dayItem,
    infoEvent,
    setInfoEvent,
    setReferenceElement = null,
    setPopperElement,
    setArrowElement,
    attributes,
    arrowStyle,
    popperStyle,
    today
}) => {
    const lgDown = useBreakpoint('lg', 'down');
    const navigate = useNavigate();

    const [deleteEvent, { isError: deleteErr, isSuccess: deleteSuc }] = useDeleteEventMutation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [currentEvent, setCurrentEvent] = useState({} as Event);
    const userRoleType = useAppSelector(selectJwtUser)?.UserRole;

    const needAdaptive = lgDown && userRoleType === Role.Student;

    useEffect(() => {
        if (deleteSuc) {
            setShowDeleteModal(false);
        }
    }, [deleteSuc]);

    const setEventToDelete = (event: Event) => {
        setCurrentEvent(event);
        setShowDeleteModal(true);
    };

    const duplicateEventHandler = () => {
        const navigateState = {
            state: {
                pathname: location.pathname,
                today: today.toString(),
                currentEvent: currentEvent,
                actionType: 'duplicate'
            }
        };
        navigate('create-event', navigateState);
    };

    const setEventToDuplicate = (event: Event) => {
        setCurrentEvent(event);
        setShowDuplicateModal(true);
    };

    return (
        <>
            <ul className={style.list}>
                {
                    events.filter(item => {
                        const dt = dateConvert(item.startEventAt).toFormat('X');
                        return dt >= dayItem.startOf('day').toFormat('X') &&
                            dt <= dayItem.endOf('day').toFormat('X');
                    }).map((item) => {
                        return (
                            <li
                                className={style.list__item}
                                key={item.eventUuid}
                                onClick={() => setInfoEvent({ isVisible: true, currentEventId: item.eventUuid })}
                                ref={infoEvent.currentEventId === item.eventUuid ? setReferenceElement : null}
                            >
                                <span className={style['list__text--time']}>
                                    {dateConvert(item.startEventAt).toFormat('T')}
                                </span>
                                &nbsp;
                                <span className={style.list__text}>{item.name}</span>
                                {
                                    (!needAdaptive && infoEvent.isVisible && infoEvent.currentEventId === item.eventUuid) && (
                                        <EventPopup
                                            setInfoEvent={setInfoEvent}
                                            setPopperElement={setPopperElement}
                                            setArrowElement={setArrowElement}
                                            arrowStyle={arrowStyle}
                                            eventInfo={item}
                                            attributes={attributes}
                                            popperStyle={popperStyle}
                                            setEventToDelete={setEventToDelete}
                                            setEventToDuplicate={setEventToDuplicate}
                                            today={today}
                                        />
                                    )
                                }
                            </li>
                        );

                    })
                }
                <DeleteItemModal
                    isOpen={showDeleteModal}
                    onClose={() => {
                        setShowDeleteModal(false);
                    }}
                    onDelete={() => {
                        deleteEvent(currentEvent.eventUuid);
                    }}
                    itemName={currentEvent.name}
                    itemType="event"
                />
                <DuplicateEventPopup
                    isOpen={showDuplicateModal}
                    onClose={() => {
                        setShowDuplicateModal(false);
                    }}
                    onDuplicate={duplicateEventHandler}
                    itemName={currentEvent.name}
                />
            </ul>
            {needAdaptive && (
                <EventSideBar
                    isOpen={infoEvent.isVisible}
                    onOpen={() => setInfoEvent(infoEvent)}
                    onClose={() => setInfoEvent({ isVisible: false, currentEventId: infoEvent.currentEventId })}
                    eventInfo={events.find(event => event.eventUuid === infoEvent.currentEventId)}
                    setInfoEvent={setInfoEvent}
                />
            )}
        </>

    );
};

EventList.displayName = 'EventList';

export default EventList;
