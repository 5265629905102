import { FC, useEffect, useMemo } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import NewTreeSelectControl, { transformDataToOptions } from '@components/core/inputs/NewTreeSelectControl';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import SwitcherControl from '@components/core/inputs/Switcher/SwitcherControl';
import Paper from '@components/core/styledWrappers/Paper';
import TinyEditor from '@components/methodist/TinyEditor';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { GroupTree } from '@store/api/apiTypes';
import { SectionFormDataType } from '@views/methodist/Materials/Course/EditCourse/Structure/SectionForm/types';


import style from './section_form.module.scss';



export const publishedSelectData = [
    { label: 'Да', value: true },
    { label: 'Нет', value: false }
];

export const formSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    description: yup.string(),
    published: yup.object({ label: yup.string(), value: yup.boolean() }),
    accessLimitation: yup.boolean(),
    blockedGroups: yup.array().of(yup.string().required()).when('accessLimitation', {
        is: true,
        then: yup.array().min(1, 'Выберите группу')
    })
});

type SectionFormData = {
    submitFormHandler: (formData: SectionFormDataType) => void
    defaultValues: SectionFormDataType,
    groups?: GroupTree;
}

const SectionForm: FC<SectionFormData> = ({ submitFormHandler, defaultValues, groups }) => {
    const { name, description, published, accessLimitation, blockedGroups } = defaultValues;
    const navigate = useNavigate();
    const { uuidSection } = useParams();
    const methods = useForm<SectionFormDataType>({
        defaultValues,
        resolver: yupResolver(formSchema)
    });

    const groupsOptions = useMemo(() => groups
        ? transformDataToOptions(groups.childs, {
            value: 'UUID',
            label: 'name',
            children: 'childs'
        })
        : [],
    [groups]);

    const { control, handleSubmit, watch, setValue } = methods;


    const watchAccessLimitation = watch('accessLimitation');

    useEffect(() => {
        setValue('name', name);
        setValue('description', description);
        setValue('published', published);
        setValue('accessLimitation', accessLimitation);
        setValue('blockedGroups', blockedGroups);
    }, [defaultValues]);

    const onSubmit = handleSubmit((formData: SectionFormDataType) => {
        if (!formData.accessLimitation) {
            delete formData.blockedGroups;
        }
        submitFormHandler(formData);
    });

    const cancelHandler = () => {
        if (uuidSection) {
            navigate('./../..');
        } else {
            navigate('./..');
        }
    };

    return (
        <Grid>
            <Column size={10}>
                <Paper className={style.paper}>
                    <form onSubmit={onSubmit}>
                        <FormProvider {...methods}>
                            <div>
                                <div className={cx(style.fieldset, style['fieldset--border_line'])}>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <label htmlFor="field-title-id">
                                            Название*
                                        </label>
                                        <InputControl
                                            name="name"
                                            placeholder="Название"
                                            id="field-title-id"
                                        />
                                    </div>
                                    <div className={style.input_wrapper}>
                                        <p>
                                            Описание
                                        </p>
                                        <TinyEditor name="description" control={control} />
                                    </div>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <p>Опубликовано</p>
                                        <SelectStyled
                                            name="published"
                                            control={control}
                                            options={publishedSelectData}
                                            isSearchable={false}
                                            styled={{ width: '100px' }}
                                        />
                                    </div>
                                </div>
                                <div className={style.fieldset} style={{ paddingTop: '24px' }}>
                                    <div className={style.input_wrapper}>
                                        <p>Доступ ограничен</p>
                                        <div className={style.permission_block_wrapper}>
                                            <SwitcherControl
                                                name="accessLimitation"
                                                control={control}
                                            />
                                            {watchAccessLimitation && (
                                                <div className={style.select_list_wrapper}>
                                                    <NewTreeSelectControl
                                                        className={style.select}
                                                        name="blockedGroups"
                                                        options={groupsOptions}
                                                        isMulti={true}
                                                        placeholder="Выберите группы"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={style.input_wrapper}>
                                        <div></div>
                                        <div className={style.form_content__button_wrapper}>
                                            <Button type="submit">Сохранить</Button>
                                            <Button
                                                btnType="secondary"
                                                onClick={cancelHandler}
                                            >
                                                Отмена
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormProvider>
                    </form>
                </Paper>
            </Column>
        </Grid>
    );
};

export default SectionForm;
