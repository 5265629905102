
import { User } from '@type/users';

import { dateConvert } from './date';

export const getFormattedDate = (date: string | undefined, defaultText: string) => {
    if (!date) return defaultText;

    return dateConvert(date).toFormat('dd.LL.yyyy, HH:mm');
};

export const getUsersMap = (users: User[]): Record<string, User> => {
    const usersMap: Record<string, User> = {};

    users.forEach(user => {
        usersMap[user.uuid] = user;
    });

    return usersMap;
};
