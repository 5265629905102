import { ReactComponent as DebtDisciplinesSVG } from '@assets/image/debtDisciplines.svg';

import style from './DisciplinesNotFound.module.scss';


export const DebtDisciplines = () => {

    return <div className={style.emptyContainer}>
        <div className={style.picture}>
            <DebtDisciplinesSVG />
        </div>

        <div className={style.header}>
            Задолженностей <br />
            по дисциплинам нет
        </div>

        <div className={style.text}>
            Дисципилины не сданные <br />
            до окончания срока будут <br />
            отображаться в этом разделе
        </div>
    </div>;
};
