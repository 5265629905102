import cx from 'classnames';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';

import Button from '@components/core/inputs/Button';

import style from './tab.module.scss';

interface ITab extends LinkProps {
    tabsType?: 'btns' | 'default';
    end?: boolean;
}

const Tab = ({ children, to, tabsType, end = false, ...props }: ITab) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end });

    return (
        <li className={'tab_root'}>
            {tabsType === 'default'
                ?
                <Link
                    className={cx(style.link, match && 'tab_root--selected')}
                    to={to}
                    {...props}
                >
                    {children}
                </Link>
                :
                <Button
                    className={style.btn}
                    size={'normal'}
                    btnType={match ? 'tabsDark' : 'tabs'}
                    to={to}>
                    <Link
                        className={style.btnLink}
                        to={to}
                        {...props}
                    >
                        {children}
                    </Link>
                </Button>
            }
        </li>
    );
};

export default Tab;
