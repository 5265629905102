import { ReactComponent as CompletedDisciplinesSVG } from '@assets/image/finishedDisciplines.svg';

import style from './DisciplinesNotFound.module.scss';


export const CompletedDisciplines = () => {

    return <div className={style.emptyContainer}>
        <div className={style.picture}>
            <CompletedDisciplinesSVG />
        </div>

        <div className={style.header}>
            Завершенных дисциплин нет
        </div>

        <div className={style.text}>
            Сданные дисципилины будут <br />
            переведены в этот раздел <br />
            и останутся для просмотра
        </div>
    </div>;
};
