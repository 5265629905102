import React from 'react';

import { Outlet, useNavigate } from 'react-router-dom';

import { ReactComponent as CrossSVG } from '@assets/icons/cross.svg';
import { ReactComponent as StapleSVG } from '@assets/icons/staple.svg';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { Grid, Column } from '@components/core/grid';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import Paper from '@components/core/styledWrappers/Paper';
import Main from '@components/Main';
import UserAvatar from '@components/UserAvatar';


import style from './FinalTest.module.scss';

export const FinalTest = () => {
    const navigate = useNavigate();

    return (
        <Main>
            <div className={style.title}>
                <ButtonIcon
                    typeIcon={'arrowLink'}
                    onClick={() => navigate('./../')}
                />

                <h1>Контрольная работа</h1>
            </div>

            <Grid>
                <Column size={8}>
                    <div className={style.container}>
                        <Paper>
                            <div className={style.author_inner}>
                                <div className={style.leftcolumn}>
                                    <h1 className={style.control_heading}>КОНТРОЛЬНАЯ РАБОТА</h1>
                                    <h1 className={style.control_heading_big}>Психодиагностика и психокоррекция лиц с ограниченными возможностями здоровья </h1>

                                    <div className={style.author}>

                                        <UserAvatar name='Александрова' lastName='Ивановна' avatar='' />
                                        <div className={style.author_name}>
                                            Александрова Мария Геннадьевна
                                            <div className={style.author_breadcrumb}>
                                                <Breadcrumbs>
                                                    <div>Бакалавриат</div>
                                                    <div>1 курс 2019</div>
                                                    <div>Вечернее отделение</div>
                                                    <div>1 поток</div>
                                                </Breadcrumbs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </Column>

                <Column size={8}>
                    <div className={style.container}>
                        <Paper>
                            <h1 className={style.heading_card}>
                                Попытка №1
                            </h1>

                            <div className={style.fileUpload}>
                                <div className={style.fileUploadIcon}>
                                    <StapleSVG />
                                </div>
                                
                                <div className={style.fileUploadCaption}>Контрольная работа.doc</div>

                            </div>

                            <p className={style.paragraph_gray}>Отправлено на проверку: 22.02.2022, в 13:52</p>

                            <h2 className={style.heading_sub_card}>
                                Комментарий
                            </h2>  

                            <p className={style.paragraph}>Текст комментария к контрольной работе</p>


                            <div className={style.control_container}>
                                <h1 className={style.control_caption}>Оценка</h1>

                                <div className={style.goal}>
                                    50 баллов
                                </div>

                                <p className={style.paragraph_gray}>Отправлено на проверку: 22.02.2022, в 13:52</p>


                                <h1 className={style.control_caption_sub}>Отзыв к работе</h1>

                                <p className={style.paragraph}>Текст комментария к контрольной работе</p>

                            </div>
                        </Paper>
                    </div>
                </Column>
            </Grid>


        </Main>);
};