import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { TestCreateData } from '@store/api/apiTypes';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useCreateTestMutation } from '@store/api/testsApi';

import TestForm from '../TestForm';
import { TestFormType } from '../TestForm/TestForm';
import style from './create_test.module.scss';


const CreateTest = () => {
    const navigate = useNavigate();
    const { uuidSection, uuidDiscipline } = useParams();
    const groupsTree = useGetGroupsTreeQuery('');

    const [fetchCreateTest, { data, isSuccess }] = useCreateTestMutation();


    const onSubmit = async (formData: TestFormType) => {

        const transformedData: TestCreateData = {
            uuidDiscipline: uuidDiscipline!,
            uuidSection: uuidSection!,
            name: formData.title,
            description: formData.editor,
            maximumRating: formData.score,
            timeLimit: formData.time,
            numberAttempts: formData.countAttempts,
            needEvaluate: formData.select.value, //TODO
            accessRestricted: formData.blockedGroups || []
        };
        console.log('--saving test:' + JSON.stringify(transformedData, null, 2));

        const createdTest = await fetchCreateTest(transformedData).unwrap();

        console.log('--saved test:' + JSON.stringify(data, null, 2));

        navigate(`./../edit-test/${createdTest.uuid}`);
    };

    const emptyTest: TestFormType = {
        title: '',
        editor: '',
        select: { label: 'Да', value: true },
        score: 100,
        time: 0,
        countAttempts: 3,
        accessLimitation: false,
        blockedGroups: []
    };

    if (groupsTree.isFetching) return <NewLoader />;

    return (
        <Main>
            <div className={style.title_wrapper}>
                <ButtonIcon
                    typeIcon="arrowLink"
                    onClick={() => navigate('./../..')}
                />
                <h1>Добавить тест</h1>
            </div>

            <TestForm groups={groupsTree.data} submitFormHandler={onSubmit} defaultValues={emptyTest} />
        </Main>
    );
};

export default CreateTest;
