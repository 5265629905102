import React, { FC } from 'react';

import cx from 'classnames';
import { useParams } from 'react-router-dom';

import { useGetMaterialsSectionsListQuery } from '@store/api/materialsApi';
import SectionCard from '@views/student/StudentCourse/SectionsList/SectionCard';

import style from './SectionsList.module.scss';

interface ISectionsList {
    className?: string;
}

const SectionsList: FC<ISectionsList> = ({ className }) => {
    const { uuidDiscipline } = useParams();
    const { data: sectionsList } = useGetMaterialsSectionsListQuery(uuidDiscipline as string);

    return (
        <ul className={cx(style.list, className)}>
            {
                sectionsList?.map(section =>
                    <SectionCard key={section.uuid} section={section} />
                )
            }
        </ul>
    );
};

export default SectionsList;
