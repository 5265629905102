import { useEffect, useState } from 'react';

import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

import { Column, Grid } from '@components/core/grid';
import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import { LinkUI } from '@components/core/LinkUI';
import { NewLoader } from '@components/core/NewLoader';
import VideoPreview from '@components/core/VideoPreview';
import Main from '@components/Main';
import GoBackLink from '@components/student/GoBackLink';
import { dateConvert } from '@helpers/date';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { skipToken } from '@reduxjs/toolkit/query';
import { VideoLectureResponce } from '@store/api/apiTypes';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { useGetEventByUuidQuery } from '@store/api/eventApi';
import { useGetVideoBlockByIdQuery } from '@store/api/videosApi';

import style from './VideoLecture.module.scss';


const VideoLecture = () => {
    const navigate = useNavigate();
    const { uuidVideoBlock, uuidVideo, uuidDiscipline, uuidEvent } = useParams();
    const videoBlock = useGetVideoBlockByIdQuery(uuidVideoBlock ? uuidVideoBlock : skipToken);
    const { data: disciplineData, isFetching: disciplineDataFetching } = useGetDisciplineByIdQuery(uuidDiscipline as string);
    const { data: currentEvent, isFetching: currentEventIsFetching } = useGetEventByUuidQuery(uuidEvent ? { uuids: [uuidEvent] } : skipToken);
    const [videoLecture, setVideoLecture] = useState<VideoLectureResponce>();

    const eventStartDate = currentEvent && dateConvert(currentEvent[0].startEventAt);

    useEffect(() => {
        setVideoLecture(videoBlock?.data?.videoLectures.find((video) => video.uuid === uuidVideo));
    }, [videoBlock]);

    const smOnly = useBreakpoint('sm', 'only');
    const xsOnly = useBreakpoint('xs', 'only');

    if (videoBlock.isFetching || disciplineDataFetching || currentEventIsFetching) return <NewLoader />;

    const pageLabel = disciplineData && videoLecture && `Обучение – ${disciplineData.name}: ${videoLecture.name}`;

    return (
        <Main className={style.main} title={pageLabel}>
            <GoBackLink to={uuidEvent ? './../..' : './../../..'}>
                Назад
            </GoBackLink>

            <Grid className={style.grid_container}>
                <Column className={style.grid_container__column_left}>
                    <>
                        {
                            xsOnly && (
                                <h3 className={style.lecture_title}>{videoLecture?.name || currentEvent && currentEvent[0].name}</h3>
                            )
                        }
                        {
                            (videoLecture || currentEvent) && <VideoPreview
                                link={videoLecture?.linkFile || ((currentEvent && currentEvent[0].linkToRecords[0]) ? currentEvent[0].linkToRecords[0] : '')}
                                setPlay={true}
                                videoName={videoLecture?.name ?? ''}
                            />
                        }
                    </>
                </Column>
                <Column className={style.grid_container__column_right}>
                    <div className={style.video_info}>
                        {
                            !xsOnly && (
                                <h3 className={style.lecture_title}>{videoLecture?.name || currentEvent && currentEvent[0].name}</h3>
                            )
                        }
                        <ul className={style.video_info_list}>
                            <li className={style.video_info_list__item}>
                                <IconUI typeIcon="lychka" />
                                <p className={style.video_info_list_item__text}>{videoBlock?.data?.name || 'Вебинар'}</p>
                            </li>
                            <li className={style.video_info_list__item}>
                                <IconUI typeIcon="book" />
                                <p className={style.video_info_list_item__text}>Дисциплина: {disciplineData?.name}</p>
                            </li>
                            {
                                disciplineData?.teachers.length ? (
                                    <li className={cx(style.video_info_list__item,
                                        disciplineData?.teachers.length === 1 && style['video_info_list__item--center'])}
                                    >
                                        <IconUI typeIcon="user" />
                                        <div>
                                            {
                                                disciplineData?.teachers.length > 1 ? (
                                                    <span className={cx(style.video_info_list_item__text, style.video_info_list__teachers_span)}>
                                                        Преподаватели:&nbsp;
                                                    </span>
                                                ) : (
                                                    <span className={style.video_info_list_item__text}>
                                                        Преподаватель:&nbsp;
                                                    </span>
                                                )
                                            }
                                            {
                                                disciplineData?.teachers.map((teacher) => (
                                                    disciplineData?.teachers.length > 1 ?
                                                        <p className={style.video_info_list_item__text} key={teacher.uuid}>
                                                            {`${teacher.lastName} ${teacher.firstName} ${teacher.patronymic}`}
                                                        </p> :
                                                        <span className={style.video_info_list_item__text} key={teacher.uuid}>
                                                            {`${teacher.lastName} ${teacher.firstName} ${teacher.patronymic}`}
                                                        </span>
                                                ))
                                            }
                                        </div>
                                    </li>
                                ) : ''
                            }
                            {
                                !!currentEvent && !!eventStartDate &&
                                    <li className={style.video_info_list__item}>
                                        <IconUI typeIcon="calendar" />
                                        <span className={style.video_info_list_item__text}>
                                            {
                                                eventStartDate.toFormat('dd.LL.yyyy, HH:mm')
                                                + ' – ' +
                                                eventStartDate.plus(currentEvent[0].duration * 60000).toFormat('HH:mm')
                                                + ' (UTC' + eventStartDate.toFormat('ZZ') + ')'
                                            }
                                        </span>
                                    </li>
                            }
                            {
                                (videoLecture?.files.length || (currentEvent && !!currentEvent[0]?.files.length)) &&
                                    <li className={style.video_info_list__item}>
                                        <ul className={style.file_list}>
                                            {
                                                videoLecture?.files.map(file => (
                                                    <li key={file.uuid}>
                                                        <LinkUI
                                                            key={file.uuid}
                                                            to={file.path}
                                                            typeLink="default"
                                                            download={true}
                                                            target="_blank"
                                                        >
                                                            {file.title}
                                                        </LinkUI>
                                                    </li>
                                                ))
                                            }
                                            {
                                                currentEvent && currentEvent[0].files.map(file => (
                                                    <li key={file.uuid}>
                                                        <LinkUI
                                                            key={file.uuid}
                                                            to={file.path}
                                                            typeLink="default"
                                                            download={true}
                                                            target="_blank"
                                                        >
                                                            {file.title}
                                                        </LinkUI>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>
                            }
                        </ul>

                        <Button
                            btnType="outline"
                            fullWidth={!smOnly}
                            onClick={() => navigate(`/education/${uuidDiscipline}`)}
                            className={style.button}
                        >
                            Перейти к дисциплине</Button>
                    </div>
                </Column>
            </Grid>
        </Main>
    );
};

export default VideoLecture;
