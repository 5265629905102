import { FC, useEffect, useState } from 'react';

import cx from 'classnames';
import { useParams } from 'react-router-dom';

import { checkRole } from '@components/RoleCheck';
import { useToggle } from '@hooks/useToggle.hook';
import { Collapse } from '@mui/material';
import { LectureBlocksLecture } from '@store/api/apiTypes';
import { Role } from '@store/Roles';
import ChapterItem from '@views/student/LecturesPreview/LecturesSidebar/ChapterItem';
import style from '@views/student/LecturesPreview/LecturesSidebar/LecturesSidebar.module.scss';


interface ILecturePanelItems {
    lecture: LectureBlocksLecture;
}

const LecturePanelItems: FC<ILecturePanelItems> = ({ lecture }) => {
    const { uuidChapter } = useParams();
    const [isOpen, { toggle, on, off }] = useToggle();

    useEffect(() => {
        const isActiveLecture = !!lecture.chapters.find(chapter => chapter.uuid === uuidChapter);
        if (isActiveLecture) on();
    }, [uuidChapter]);

    return (
        <>
            <div onClick={toggle} className={cx(style.lecture__title_wrapper, lecture.disabled && checkRole(Role.Student) && style['lecture__title_wrapper--disabled'] )}>
                <div className={style.lecture__title}>
                    <h5>{lecture.name}</h5>
                </div>
            </div>
            <Collapse
                easing="ease-out"
                in={isOpen}
                className={style.collapse_panel}
            >
                <li className={style.lecture__item}>
                    <ul className={style.content_list}>
                        {lecture.chapters.map((chapter, indexChapter) => (
                            <ChapterItem key={indexChapter} chapter={chapter} />
                        ))}
                    </ul>
                </li>
            </Collapse>
        </>
    );
};

export default LecturePanelItems;
