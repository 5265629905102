import { FC, HTMLAttributes, ReactNode } from 'react';

import cx from 'classnames';

import style from './StatusLabel.module.scss';

export interface IDebtLabelProps extends HTMLAttributes<HTMLSpanElement> {
    type: 'danger' | 'outlined' | 'filled'
    children: ReactNode;
    className?: string;
}

export const StatusLabel:FC<IDebtLabelProps> = ({
    type,
    children,
    className,
    ...props
}) => {

    return <span
        className={cx(style.container, className, {
            [style.danger]: type == 'danger',
            [style.outlined]: type == 'outlined',
            [style.filled]: type == 'filled'
        })}
        {...props}>
        {children}
    </span>;
};
