import { ReactComponent as PictureBorgSVG } from '@assets/icons/picture-borg.svg';

import style from './RecordNotFound.module.scss';


export const AvailableDiscipline = () => {

    return <div className={style.container}>
        <div className={style.picture}>
            <PictureBorgSVG />
        </div>

        <div className={style.title}>
            Задолженностей <br />
            по дисциплинам нет
        </div>

        <div className={style.description}>
            Дисципилины не сданные<br />
            до окончания срока будут<br />
            отображаться в этом разделе
        </div>
    </div>;
};