import { toast } from 'react-toastify';

import { TeacherReportsRequest, TeacherReportsResponse } from '@type/reports';

import { IStorageFile } from './apiTypes';
import { emptyBaseApi } from './emptyBaseApi';

export const reportsApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getTeacherReports: build.query<TeacherReportsResponse, TeacherReportsRequest>({
            query(filters) {
                return {
                    url: 'grades/report',
                    body: filters,
                    method: 'POST'
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения отчетов');
                }
            },
            providesTags: (result, error, arg) => [{ type:'TeacherReports', id: `Report_${arg.uuidTeacher}` }]
        }),

        getTeacherReportsExportFile: build.mutation<IStorageFile, TeacherReportsRequest>({
            query(data) {
                return {
                    url: 'grades/report/xls',
                    method: 'POST',
                    body: data
                };
            }
        })
    })
});

export const {
    useGetTeacherReportsQuery,
    useGetTeacherReportsExportFileMutation
} = reportsApi;
