import { useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import { checkRole } from '@components/RoleCheck';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { skipToken } from '@reduxjs/toolkit/query';
import { useCreateEntryCompletedChapterMutation, useGetCompletedChaptersListQuery } from '@store/api/gradesApi';
import { useGetChapterByIdQuery } from '@store/api/lecturesApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { useLectureBlock } from '@views/student/LecturesPreview/LecturesPreview';

import style from './ChapterPreview.module.scss';


enum PaginationAction {
    prev = 'prev',
    next = 'next'
}

export type Chapter = {
    name: string,
    uuid: string,
    lectureName: string,
    lastTopic: boolean,
    lastLecture: boolean,
    lastChapter: boolean,
    countChapters: number,
    indexChapter: number,
    disabled: boolean
}


const ChapterPreview = () => {
    const navigate = useNavigate();
    const { uuidChapter, uuidLectureBlock } = useParams();
    const user = useAppSelector(selectApiUser);
    const lecturesBlock = useLectureBlock();

    const chapterData = useGetChapterByIdQuery(uuidChapter as string);

    const { data: completedChaptersList } = useGetCompletedChaptersListQuery(user ? {
        uuidBlock: uuidLectureBlock as string,
        uuidUser: user.uuid
    } : skipToken);

    const [createEntryCompletedChapter] = useCreateEntryCompletedChapterMutation();
    const [disabledPaginationNextButton, setDisabledPaginationNextButton] = useState(true);
    const [chapterUuids, setChapterUuids] = useState<string[]>([]);

    const smDown = useBreakpoint('sm', 'down');

    // useEffect(() => {
    //     if (chapterUuids.length) {
    //         navigate(`../${chapterUuids[chapterUuids.length - 1]}`);
    //     }
    // }, [chapterUuids]);

    useEffect(() => {
        if (chapterUuids.includes(uuidChapter as string)) {
            setDisabledPaginationNextButton(false);
        } else {
            const timer = setTimeout(() => setDisabledPaginationNextButton(false), 1000);
            return () => {
                clearTimeout(timer);
                setDisabledPaginationNextButton(true);
            };
        }
    }, [uuidChapter, chapterUuids]);

    useEffect(() => {
        if (completedChaptersList) {
            // @ts-ignore
            completedChaptersList.chapters.forEach(item => {
                setChapterUuids(prevState => ([...prevState, item.uuidChapter]));
            });
        }
    }, [completedChaptersList]);

    const chapters = useMemo(() => {
        return lecturesBlock?.topics.reduce((result: { [key in string]: Chapter }, topic, indexTopic, topicArr) => {
            topic.lectures.forEach((lecture, lectureIndex, lecturesArr) => {
                lecture.chapters.forEach((chapter, chapterIndex, chaptersArr) => {
                    result[chapter.uuid] =
                        {
                            ...chapter,
                            lectureName: lecture.name,
                            lastTopic: topicArr.length === indexTopic + 1,
                            lastLecture: lecturesArr.length === lectureIndex + 1,
                            lastChapter: chaptersArr.length === chapterIndex + 1,
                            countChapters: chaptersArr.length,
                            indexChapter: chapterIndex,
                            disabled: chapterUuids.includes(chapter.uuid)
                        };
                });
            });
            return result;
        }, {});
    }, [lecturesBlock, completedChaptersList]);

    const paginationHandler = (action: PaginationAction) => {
        if (checkRole(Role.Student)) {
            createEntryCompletedChapter({
                uuidBlock: uuidLectureBlock as string,
                uuidChapter: uuidChapter as string,
                uuidUser: user?.uuid as string
            });
        }

        const chaptersArr = Object.values(chapters as { [key in string]: Chapter });
        const currentChapter = chaptersArr.find(chapter => chapter.uuid === uuidChapter);

        const lastTopic = lecturesBlock?.topics[lecturesBlock?.topics.length - 1];
        const lastLecture = lastTopic?.lectures[lastTopic?.lectures.length - 1];
        const lastChapter = lastLecture?.chapters[lastLecture?.chapters.length - 1];

        if (lastChapter?.uuid === uuidChapter && action === PaginationAction.next) {
            navigate('./../../..');
            return;
        }

        if (currentChapter) {
            if (action === PaginationAction.next) {
                navigate(`../${chaptersArr[chaptersArr.indexOf(currentChapter) + 1].uuid}`);
            }
            if (action === PaginationAction.prev) {
                navigate(`../${chaptersArr[chaptersArr.indexOf(currentChapter) - 1].uuid}`);
            }
        }
    };

    const getNextButtonLabel = () => {
        if (chapters) {
            if (!chapters[uuidChapter as string].lastTopic ||
                !chapters[uuidChapter as string].lastLecture ||
                !chapters[uuidChapter as string].lastChapter
            ) {
                return smDown ? 'Далее' : (
                    (chapters[uuidChapter as string].lastLecture && chapters[uuidChapter as string].lastChapter) ?
                        'Следующая тема' :
                        chapters[uuidChapter as string].lastChapter ? 'Следующая лекция' : 'Далее'
                );
            } else {
                return 'Завершить';
            }
        }
    };

    return (
        <>
            {
                chapterData.isFetching ? <NewLoader /> : (
                    chapterData.isSuccess ? (
                        <>
                            <h1 className={style.main_title}>{chapterData.data.name}</h1>
                            {
                                chapters && (
                                    <>
                                        <p className={style.lecture_title}>{chapters && chapters[uuidChapter as string].lectureName}</p>
                                        <div className={style.chapter_count}>
                                            <span>Часть {chapters && chapters[uuidChapter as string].indexChapter + 1}</span>
                                            &nbsp;
                                            <span>из {chapters[uuidChapter as string].countChapters}</span>
                                        </div>
                                    </>
                                )
                            }

                            <CSVPreview content={chapterData.data.content} className={style.main_content} />
                            <div className={style.pagination_block}>
                                {
                                    (chapters && Object.keys(chapters as { [key in string]: Chapter }).indexOf(uuidChapter as string) !== 0) && (
                                        <Button
                                            btnType="secondary"
                                            typeIcon="left-arrow"
                                            onClick={() => paginationHandler(PaginationAction.prev)}
                                            className={style['pagination_block__btn--prev']}
                                        >
                                            {smDown ? '' : 'Назад'}
                                        </Button>
                                    )
                                }
                                {
                                    chapters && (
                                        <Button
                                            disabled={disabledPaginationNextButton}
                                            className={style.pagination_block__btn}
                                            onClick={() => paginationHandler(PaginationAction.next)}
                                        >
                                            {getNextButtonLabel()}
                                            <IconUI typeIcon="right-arrow" />
                                        </Button>
                                    )
                                }
                            </div>
                        </>
                    ) : <NewLoader />
                )
            }
        </>
    );
};

export default ChapterPreview;
