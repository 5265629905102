import { FC, ReactNode } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { ReactComponent as AdditionalSettings } from '@assets/icons/additional-settings.svg';
import { ReactComponent as AddressBook } from '@assets/icons/addressbook.svg';
import { ReactComponent as Bookmark } from '@assets/icons/bookbookmark.svg';
import { ReactComponent as CalendarPlus } from '@assets/icons/calendarplus.svg';
import { ReactComponent as Chart } from '@assets/icons/chartbar.svg';

import Tooltip from '../Tooltip';
import style from './toolbar.module.scss';

const cx = classNames;

interface IProps {
    className?: string;
    children?: ReactNode;
}

const Toolbar: FC<IProps> = ({ className, children }) => {
    const toolbarData = [
        {
            path: '/materials',
            name: 'Учебные материалы',
            icon: <Bookmark />
        },
        {
            path: '/users',
            name: 'Пользователи и группы ',
            icon: <AddressBook />
        },
        {
            path: '/events',
            name: 'Мероприятия',
            icon: <CalendarPlus />
        },
        {
            path: '/reports',
            name: 'Отчеты',
            icon: <Chart />
        },
        {
            path: '/additional-settings',
            name: 'Дополнительные настройки',
            icon: <AdditionalSettings />
        }
    ];

    return (
        <div className={cx(style.root, className)}>
            <nav>
                <ul className={style.nav__list}>
                    {toolbarData.map((route) => (
                        <li key={route.path} className={style.nav__item}>
                            <NavLink
                                className={({ isActive }) =>
                                    cx(
                                        style.nav__link,
                                        isActive && style['nav__link--active']
                                    )
                                }
                                to={route.path}
                            >
                                {route.icon}
                            </NavLink>
                            <Tooltip className={style.nav__tooltip}>
                                {route.name}
                            </Tooltip>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default Toolbar;
