import React, { FC, useState } from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import ButtonDrop from '@components/core/inputs/Button/ButtonDrop';
import { DropMenuItem } from '@components/core/styledWrappers/DropMenu';
import Paper from '@components/core/styledWrappers/Paper';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { EnumMaterialsType, LectureBlockCreateData, Section, VideoBlockCreateData } from '@store/api/apiTypes';
import { useCreateFileMutation, useUpdateFileMutation } from '@store/api/fileApi';
import { useCreateLectureBlockMutation, useDeleteLectureBlockMutation } from '@store/api/lecturesApi';
import { useCreateLinkMutation, useUpdateLinkMutation } from '@store/api/linkApi';
import { useDeleteMaterialByIdMutation } from '@store/api/materialsApi';
import { useDeleteSectionMutation } from '@store/api/sectionsApi';
import { useCreateVideoBlockMutation, useDeleteVideoBlockMutation } from '@store/api/videosApi';
import { Role } from '@store/Roles';
import AddFileModal from '@views/methodist/Materials/Course/AddFileModal';
import AddLinkModal from '@views/methodist/Materials/Course/AddLinkModal';
import { List } from '@views/methodist/Materials/Course/EditCourse/Structure/List';

import { CardHeader } from './CardHeader';
import { CardSubHeader } from './CardSubHeader';
import style from './sectionCard.module.scss';


interface ICardProps {
    children?: React.ReactNode;
    section: Section;
}

export const SectionCard: FC<ICardProps> = ({ section }) => {
    const { uuid, name, published, description, blockedGroups, materials, uuidDiscipline } = section;
    const navigate = useNavigate();

    const [fetchDeleteSection] = useDeleteSectionMutation();

    const [createVideoBlock] = useCreateVideoBlockMutation();
    const [createLectureBlock] = useCreateLectureBlockMutation();
    const [fetchCreateLink] = useCreateLinkMutation();
    const [fetchCreateFile, { isSuccess: isCreateFileSuccess }] = useCreateFileMutation();
    const [updateLink] = useUpdateLinkMutation();
    const [updateFile] = useUpdateFileMutation();

    const [deleteMaterial] = useDeleteMaterialByIdMutation();
    const [deleteVideoBlock] = useDeleteVideoBlockMutation();
    const [deleteLectureBlock] = useDeleteLectureBlockMutation();

    const [isOpenAddLinkModal, setIsOpenAddLinkModal] = useState<boolean>(false);
    const [isOpenAddFileModal, setIsOpenAddFileModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onMaterialDelete = (materialUuid: string, sectionUuid: string) => {
        deleteMaterial({ uuid: materialUuid, uuidParent: sectionUuid });
    };

    const lectureIsPresent = () => {
        if (materials) {
            return !!materials?.find(mat => mat.type == EnumMaterialsType.Lecture);
        }

        return false;
    };

    const editSectionHandler = async () => {
        navigate(`edit-section/${uuid}`);
    };
    const deleteSectionHandler = async () => {
        await fetchDeleteSection(uuid);
    };

    const createVideoBlockOnClick = async () => {
        const blockData: VideoBlockCreateData = {
            name: 'Блок видеолекций',
            uuidParent: uuid,
            videoLectures: []
        };

        try {
            const createdBlock = await createVideoBlock(blockData).unwrap();

            navigate(`${uuid}/video-lectures/${createdBlock.uuid}`);
        } catch (error) {
            alert('Ошибка создания блока видеолекций');
        }
    };

    const createLecturesBlockOnClick = async () => {
        const blockData: LectureBlockCreateData = {
            name: 'Блок лекций',
            uuidParent: uuid
        };

        try {
            const createdBlock = await createLectureBlock(blockData).unwrap();

            navigate(`${uuid}/lectures-block/${createdBlock.uuid}`);
        } catch (error) {
            alert('Ошибка создания блока лекций');
        }
    };


    const headerProps = {
        title: name,
        labels: {
            published,
            blockedGroups
        },
        controlsEvents: {
            onEdit: editSectionHandler,
            onDelete: () => setShowDeleteModal(true)
        }
    };

    return (
        <>
            <Paper className={style.container}>
                <div className={style.inner}>

                    <div className={style.header}>
                        <CardHeader {...headerProps} />
                    </div>

                    <div className={style.subHeader}>
                        <CardSubHeader subTitle={description} />
                    </div>

                    <div className={cx(style.list, {
                        [style['list--inspector']]: checkRole(Role.Inspector)
                    })}>
                        <List 
                            materials={materials ? materials : []} 
                            disciplineUuid={uuidDiscipline} 
                            deleteVideoBlock={deleteVideoBlock}
                            deleteLectureBlock={deleteLectureBlock}
                            deleteMaterial={onMaterialDelete}
                            updateLink={updateLink}
                            updateFile={updateFile}
                        />
                    </div>

                    <RoleCheck roles={Role.Methodist}>
                        <div className={style.action}>
                            <ButtonDrop
                                btnType='outline'
                                typeIcon='addCircle'
                                buttonChild='Добавить'
                            >
                                <DropMenuItem onClick={() => setIsOpenAddLinkModal(true)}>
                                    Ссылка
                                </DropMenuItem>
                                <DropMenuItem onClick={() => {
                                    navigate(`${uuid}/add-page`);
                                }}>
                                    Страница
                                </DropMenuItem>
                                <DropMenuItem onClick={() => setIsOpenAddFileModal(true)}>
                                    Загрузить файл
                                </DropMenuItem>

                                {
                                    lectureIsPresent() ? null :
                                        <DropMenuItem onClick={createLecturesBlockOnClick}>
                                            Лекция
                                        </DropMenuItem>
                                }

                                <DropMenuItem onClick={createVideoBlockOnClick}>
                                    Видеолекция
                                </DropMenuItem>

                                <DropMenuItem onClick={() => {
                                    navigate(`${uuid}/add-task`);
                                }}>
                                    Контрольная работа
                                </DropMenuItem>

                                <DropMenuItem onClick={() => {
                                    navigate(`${uuid}/add-test`);
                                }}>
                                    Тест
                                </DropMenuItem>
                            </ButtonDrop>
                        </div>
                    </RoleCheck>
                </div>
            </Paper>
            <AddLinkModal
                uuidParent={section.uuid}
                isOpen={isOpenAddLinkModal}
                onClose={setIsOpenAddLinkModal}
                materials={materials || []}
                createLink={fetchCreateLink}
            />
            <AddFileModal
                uuidParent={section.uuid}
                isOpen={isOpenAddFileModal}
                onClose={setIsOpenAddFileModal}
                materials={materials || []}
                isFileCreationSuccess={isCreateFileSuccess}
                createFile={fetchCreateFile}
            />
            <DeleteItemModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onDelete={deleteSectionHandler}
                itemName={name}
                itemType='section'
            />
        </>
    );
};
