import { FC } from 'react';

import cx from 'classnames';

import Button from '@components/core/inputs/Button';

import style from './AdditionalControls.module.scss';


type IAdditionalControls = {
    step: number;
    setStep: (step: number) => void;
    onClose: () => void;
    onSubmit: () => void;
    isBtnDisabled: boolean;
    setBtnDisabled: (isDisable: boolean) => void;
}

const AdditionalControls: FC<IAdditionalControls> = ({ step, setStep, onClose, onSubmit, isBtnDisabled, setBtnDisabled }) => {
    const onNext = () => {
        setStep(++step);
        setBtnDisabled(true);
    };

    const onPrev = () => {
        setStep(--step);
    };

    const renderBtn = () => {
        if (step === 4) return (
            <Button btnType="success" onClick={onSubmit} disabled={isBtnDisabled}>
                Установить
            </Button>
        );

        return (
            <Button onClick={onNext} disabled={isBtnDisabled}>
                Далее
            </Button>
        );
    };

    const renderCloseBtn = () => {
        if (step === 1) return (
            <Button btnType="secondary" onClick={onClose}>
                Отмена
            </Button>
        );

        return (
            <Button btnType="secondary" onClick={onPrev}>
                Назад
            </Button>
        );
    };

    return (
        <div className={cx(style.actions, {
            [style['actions--shadow']]: step < 4
        })}>
            {
                <div className={style.steps}>
                    Шаг {step} из 4
                </div>
            }

            <div className={cx(style.btns)}>
                {renderCloseBtn()}
                {renderBtn()}
            </div>
        </div>
    );
};

export default AdditionalControls;
