import * as React from 'react';
import { FC } from 'react';

import cx from 'classnames';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-accordionicon.svg';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import styles from './BasicAccordion.module.scss';

interface IBasicAccordionProps {
    label: string | React.ReactNode;
    id: string;
    children: React.ReactNode;
    className?: string;
    isExpand?: boolean;
}

const BasicAccordion: FC<IBasicAccordionProps> = ({
    label,
    id,
    className,
    children,
    isExpand = true
}) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        if (isExpand) {
            setExpanded(isExpanded ? panel : false);
        } else {
            setExpanded(false);
        }
    };

    return (
        <Accordion
            expanded={expanded === `panel${id}`}
            onChange={handleChange(`panel${id}`)}
            className={cx(styles.root, className, expanded && styles['root--expanded'])}
        >
            <AccordionSummary
                expandIcon={isExpand && (expanded ? <ArrowIcon /> : <ArrowIcon />)}
                aria-controls={`panel${id}-content`}
                id={`panel${id}-header`}
            >
                {label}
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default BasicAccordion;
