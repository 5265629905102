import { useState, useCallback } from 'react';

/**
 * Переключатель
 * @example
 * const [isOpen, { on, off, toggle }] = useToggle();
 */
export const useToggle = (defaultValue?: boolean) => {
    const [isOpen, setIsOpen] = useState(defaultValue || false);

    const on = useCallback(() => setIsOpen(true), []);
    const off = useCallback(() => setIsOpen(false), []);
    const toggle = useCallback(() => setIsOpen((state) => !state), []);

    return [isOpen, { on, off, toggle }] as const;
};
