import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '@store/store';

interface IMassEditState {
    studentsUuidAll: string[];
}

const initialState: IMassEditState = {
    studentsUuidAll: []
};

export const massEditSlice = createSlice({
    initialState,
    name: 'massEditSlice',
    reducers: {
        setStudentsUuidAll: (state, action: PayloadAction<string[]>) => {
            state.studentsUuidAll = action.payload;
        }
    }
});

export default massEditSlice.reducer;

export const { setStudentsUuidAll } = massEditSlice.actions;

export const selectStudentsUuidSlice = (state: RootState) => state.massEditSlice.studentsUuidAll;
