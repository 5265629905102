import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

// Hook
// T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.

// Usage
// function App() {
//     const [hoverRef, isHovered] = useHover<HTMLDivElement>();
//     return <div ref={hoverRef}>{isHovered ? "😁" : "☹️"}</div>;
// }

// hook returns tuple(array) with type [any, boolean]
export function useHover<T extends HTMLElement>(): [MutableRefObject<T & HTMLElement | null>,  boolean] {
    const [value, setValue] = useState<boolean>(false); 
    const ref = useRef<T & HTMLElement | null>(null);
    const handleMouseOver = (): void => setValue(true);
    const handleMouseOut = (): void => setValue(false);
    
    useEffect(
        () => {
            const node = ref.current;

            if (node) {
                node.addEventListener('mouseover', handleMouseOver);
                node.addEventListener('mouseout', handleMouseOut);

                return () => {
                    node.removeEventListener('mouseover', handleMouseOver);
                    node.removeEventListener('mouseout', handleMouseOut);
                };
            }
        },
        [ref.current] // Recall only if ref changes
    );
    return [ref, value];
}