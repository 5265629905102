import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import Main from '@components/Main';
import { Pagination, usePagination } from '@components/Pagination';
import { useNavigationHistory } from '@hooks/useNavigationHistory';

import { Archive } from '../Archive';
import style from './ArchiveLayout.module.scss';

const ArchiveLayout = () => {
    const { goBack } = useNavigationHistory('../reports/eventsLog');

    const { limit, start, setTotal, paginationProps } = usePagination({ defaultRowsPerPage: 25 });

    return (
        <Main>
            <div className={style.title_wrapper}>
                <ButtonIcon
                    typeIcon={'arrowLink'}
                    onClick={goBack}
                />
                <h1>Архив событий</h1>
                {paginationProps.total > 0 &&
                    <span className={style.notify}>{paginationProps.total}</span>
                }
            </div>

            <Archive limit={limit} setTotal={setTotal} start={start} />

            <Pagination {...paginationProps} />
        </Main>
    );
};

export default ArchiveLayout;
