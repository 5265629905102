import React from 'react';

import cx from 'classnames';

import IconUI from '@components/core/IconUI';
import Checkbox from '@components/core/inputs/Checkbox';
import IconButton from '@components/core/styledWrappers/IconButton';
import UserAvatar from '@components/UserAvatar';
import { dateConvert } from '@helpers/date';
import { IDiscipline, SessionTypes, SessionTypesWithLocale } from '@store/api/apiTypes';
import { DisciplineWithExtraSession } from '@type/extraSession';
import { User } from '@type/users';

import styles from './ExtraSessionCard.module.scss';

interface ExtraSessionCardProps {
    className?: string;
    user: User;
    disciplines: (IDiscipline & {
        extraSessions: string[];
        deadline: {
            startDate: string;
            endDate: string;
        };
        semester: SessionTypes;
    })[];
    onSelect: (value: string[]) => void;
    selectedStudent: string[];
    onDelete: (discipline: string) => void;
    onEdit: (discipline: DisciplineWithExtraSession) => void;
}

export const ExtraSessionCard: React.FC<ExtraSessionCardProps> = ({
    className,
    user,
    disciplines ,
    onSelect,
    selectedStudent,
    onDelete,
    onEdit
}) => {
    const handleSelect = () => {
        if (selectedStudent.includes(user.uuid)) {
            onSelect(selectedStudent.filter(el => el !== user.uuid));
        } else {
            onSelect([...selectedStudent, user.uuid]);
        }
    };


    return (
        <div className={cx(styles.container, className)}>
            <div className={styles['row-name']}>
                <Checkbox containerClassName={styles.checkbox} onChange={handleSelect} checked={selectedStudent.includes(user.uuid)} />
                <UserAvatar name={user.firstName} lastName={user.lastName} avatar={user.picture?.path} className={styles.avatar} />
                <h4 className={styles.name}>{user.lastName} {user.firstName} {user.patronymic}</h4>
            </div>
            <div className={styles.content}>
                {disciplines.map((discipline, index) => (
                    <div key={discipline.uuid} className={cx(styles.row, { [styles.last]: index === disciplines.length - 1 })}>
                        <div className={styles.title}>
                            {discipline.name}
                        </div>
                        <div className={styles.info}>
                            {SessionTypesWithLocale[discipline.semester]}
                        </div>
                        <div className={styles.info}>
                            {dateConvert(discipline.deadline.startDate).toFormat('dd.MM.yyyy')}
                            {' - '}
                            {dateConvert(discipline.deadline.endDate).toFormat('dd.MM.yyyy')}
                        </div>
                        <div className={styles.controls}>
                            <IconButton className={styles.edit} onClick={() => onEdit(discipline)}>
                                <IconUI typeIcon="edit" />
                            </IconButton>
                            <IconButton onClick={() => onDelete(discipline.uuid)}>
                                <IconUI typeIcon="basket" />
                            </IconButton>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
