import { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as ArrowLinkIcon } from '@assets/icons/arrow-link-icon.svg';
import { ReactComponent as CheckValueIcon } from '@assets/icons/check-value-icon.svg';
import { ReactComponent as FirstCompetence } from '@assets/image/firstCompetence.svg';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { useChat } from '@components/Chat/context';
import Button from '@components/core/inputs/Button';
import { getPaths } from '@helpers/findNestedPath';
import { makeGroupTreeFlat, findRootCurrentGroup } from '@helpers/groupTree';
import { useNavigationHistory } from '@hooks/useNavigationHistory';
import { skipToken } from '@reduxjs/toolkit/query';
import { GroupTree } from '@store/api/apiTypes';
import { useGetPresetsQuery, useGetSkillDisciplinesQuery, useUpdatePresetMutation } from '@store/api/skillsApi';

import AccordeonItem from './AccordeonItem';
import styles from './Binding.module.scss';

export const Binding = () => {
    const { goBack } = useNavigationHistory('./');
    const  { uuidGroup } = useParams();

    const [updatePreset, { isSuccess, isLoading }] = useUpdatePresetMutation();
    const { data: presetsData } = useGetPresetsQuery(undefined, { skip: isLoading });
    const { data: skillDisciplinesData } = useGetSkillDisciplinesQuery(
        presetsData ?
            { uuidsDiscipline: presetsData.map(preset => preset.uuidsDiscipline).flat() }
            :
            skipToken,
        { skip: isLoading }
    );

    useEffect(()=>{
        if(isSuccess) toast.success('Компетенции успешно привязаны!');
    },[isSuccess]);

    const [transformedPresetsData, setTransformedData] = useState<any[]>([]);

    useEffect(()=>{
        if(!presetsData || !skillDisciplinesData) return;
        const data:any[] = [];
        presetsData.forEach(preset => {
            const disciplines:any[] = [];

            preset.uuidsDiscipline.forEach(uuid => {
                const item = skillDisciplinesData.find(discipline => discipline.uuid === uuid);
                if(item) disciplines.push(item);
            });

            data.push({
                name: preset.name,
                uuid: preset.uuid,
                disciplines: disciplines
            });
        });
        setTransformedData(data);
    },[presetsData, skillDisciplinesData]);

    const [pathTo, setPathTo] = useState<{ uuid: string, name: string }[]>([]);
    const [rootGroupUuid, setRootGroupUuid] = useState<string>('');

    const { groupsData } = useChat();

    useEffect(() => {
        if (groupsData) {
            //Получение текущей группы студента
            const currentGroup = makeGroupTreeFlat(groupsData.childs).find(g => g.UUID === uuidGroup);
            // Нахождение рут группы студента
            currentGroup && setRootGroupUuid(findRootCurrentGroup(currentGroup, groupsData).UUID);
        }
    }, [groupsData]);

    useEffect(() => {
        if (groupsData) {
            const pathToGroup = getPaths<GroupTree, 'childs', 'UUID'>(
                groupsData.childs,
                'childs',
                'UUID',
                (group) => group.UUID === (uuidGroup ?? rootGroupUuid)
            );

            setPathTo(pathToGroup ?? []);
        }
    }, [groupsData, rootGroupUuid]);


    const [checks, setChecks] = useState<{[key:string]:boolean} | undefined>();

    useEffect(()=>{
        if(!presetsData) return;

        setChecks(
            presetsData.reduce((accumulator, preset) => {
                return { ...accumulator, [preset.uuid]: preset.uuidsGroup!.includes(uuidGroup!) };
            }, {})
        );
    },[presetsData]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, presetUUID:string) => {
        if(!checks) return;

        checks[presetUUID] = event.target.checked;
        setChecks(checks);
    };

    const save = () => {
        if(!checks || !presetsData) return;

        for(const preset of presetsData){
            const presetCopy = structuredClone(preset);

            if (checks[preset.uuid]) {
                if (!preset.uuidsGroup!.includes(uuidGroup!)) {
                    presetCopy.uuidsGroup!.push(uuidGroup!);
                    updatePreset(presetCopy);
                }
            } else {
                if (preset.uuidsGroup!.includes(uuidGroup!)) {
                    presetCopy.uuidsGroup = presetCopy.uuidsGroup!.filter((item:any) => item !== uuidGroup);
                    updatePreset(presetCopy);
                }
            }

        }
    };

    return (
        <div className={styles.page}>
            <Link to="../" className={styles.header}>
                <ArrowLinkIcon />
                <span>Привязать пресеты</span>
            </Link>
            <div className={styles.breadcrumbs}>
                <Breadcrumbs>
                    {
                        pathTo.map((group) =>
                            <span className={styles.breadcrumbs_item} key={group.uuid}>{group.name}</span>
                        )
                    }
                </Breadcrumbs>
            </div>

            <div className={styles.content}>

                {checks ?
                    <>
                        <div className={styles.accordeon}>
                            {transformedPresetsData.map(preset => (
                                <AccordeonItem
                                    label={preset.name}
                                    key={preset.uuid}
                                    onChange={(e)=>{onChange(e, preset.uuid);}} checked={checks[preset.uuid]}
                                    contentClassName={styles.accordeonItemContent}
                                >
                                    {preset.disciplines.map((discipline:any) =>
                                        <div className={styles.discipline} key={discipline.uuid}>
                                            <CheckValueIcon />
                                            {discipline.name}
                                        </div>)}
                                </AccordeonItem>
                            ))}
                        </div>

                        <div className={styles.buttons}>
                            <Button onClick={save}>Сохранить</Button>
                            <Button onClick={goBack} btnType='secondary'>Отмена</Button>
                        </div>
                    </>
                    :
                    <div className={styles.empty} key={2}>
                        <FirstCompetence />
                        <p className={styles.text}>Создайте свою первую компетенцию</p>
                        <Button typeIcon='add' className={styles.button} link to="/additional-settings/skills/add">Добавить компетенцию</Button>
                    </div>
                }


            </div>

        </div>
    );
};

export default Binding;
