
export const getPaths = <T, K extends keyof T, U extends keyof T>(nestedObj: T[], key: K , uuidKey: U, isObjectSelectedCB: (t: T) => boolean) => {
    if (!nestedObj) return;

    const paths: {uuid: string, name: string}[] = [];
    const parentPath: {uuid: string, name: string}[] = [];

    const deepCheck = (items: T[]) => {
        if (!items) return;
        
        for (const item of items) {
            /**
             * To include truthy items in `paths`, use following line as it is
             * Or move `parentPath.push(item.id);` after
             * `if (isObjectSelectedCB(item)) { ... }` block to skipping it from `paths`
             */

            // @ts-ignore:next-line
            parentPath.push({ uuid: item[uuidKey], name: item.name });
            
            if (isObjectSelectedCB(item)) {
                /**
                 * If want entire path for every selected items uncomment following line
                 * Else it will return unique ids only in path
                 */

                paths.push(...parentPath);
                break;
                // parentPath = [];
            }

            // parentPath.push(item.id);

            if (item[key]) {
                // @ts-ignore:next-line
                deepCheck(item[key]);
            }

            if (paths.length) break;
            
            parentPath.pop();
        }

        return paths;
    };

    return deepCheck(nestedObj);
};