import { FC, useEffect, useState } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Breadcrumbs } from '@components/Breadcrumbs';
import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import Switcher from '@components/core/inputs/Switcher';
import Paper from '@components/core/styledWrappers/Paper';
import { getPaths } from '@helpers/findNestedPath';
import { findRootCurrentGroup, makeGroupTreeFlat } from '@helpers/groupTree';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupTree } from '@store/api/apiTypes';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useUpdateUserSocialsMutation } from '@store/api/usersApi';
import { changeSoundOn, selectSoundOn } from '@store/features/settingsLSSlice';
import { selectApiUser } from '@store/features/userSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Role, roleNames } from '@store/Roles';

import { useUser } from './../StudentProfile';
import Skills from './Skills';
import style from './student_profile_info.module.scss';



interface IProps {
    className?: string;
}

const schema = yup.object({
    vk: yup.string(),
    telegram: yup.string()
});

type TUserProfileInfoForm = yup.InferType<typeof schema>;

const ProfileItem = ({ label, value }:{label: string, value: string | undefined}) => (
    <div className={style.section_item}>
        <div className={style.section_key}>{label}</div>
        <div className={style.section_value}>{value || '-'}</div>
    </div>
);

const UserProfileInfo: FC<IProps> = () => {
    const dispatch = useAppDispatch();
    const [updateSocials] = useUpdateUserSocialsMutation();
    const user = useUser();
    const me = useAppSelector(selectApiUser);
    const userGroupUuid = user.groupUUID[0] || '';
    const groupsData = useGetGroupsTreeQuery('');
    const isStudent = user.role === Role.Student;

    const [pathTo, setPathTo] = useState<{uuid: string, name: string}[]>([]);
    const [rootGroupUuid, setRootGroupUuid] = useState<string>('');
    const soundOn = useAppSelector(selectSoundOn);

    const methods = useForm<TUserProfileInfoForm>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            vk: user.vkontakte,
            telegram: user.telegram
        }
    });

    const { watch, handleSubmit } = methods;

    const watchSVk = watch('vk');
    const watchTelegram = watch('telegram');

    const isShowBtn = watchSVk !== user.vkontakte || watchTelegram !== user.telegram;

    const onSubmit = (formData: TUserProfileInfoForm) => {
        updateSocials({
            vkontakte: formData.vk || '',
            telegram: formData.telegram || ''
        });
    };

    useEffect(() => {
        if (groupsData.data && groupsData.isSuccess) {
            //Получение текущей группы студента
            const currentGroup = makeGroupTreeFlat(groupsData.data.childs).find(g => g.UUID === userGroupUuid);
            // Нахождение рут группы студента
            currentGroup && setRootGroupUuid(findRootCurrentGroup(currentGroup, groupsData.data).UUID);
        }
    }, [groupsData.isLoading, groupsData.isSuccess]);

    // Создание хлебных крошек на основании текущей и рутовой групп студента
    useEffect(() => {
        if (groupsData.isSuccess) {
            const pathToGroup = getPaths<GroupTree, 'childs', 'UUID'>(
                groupsData.data.childs,
                'childs',
                'UUID',
                (group) => group.UUID === (userGroupUuid ?? rootGroupUuid)
            );

            setPathTo(pathToGroup ?? []);
        }
    }, [groupsData.isSuccess, userGroupUuid, rootGroupUuid]);

    const isNeedDisabled = me?.uuid !== user.uuid;

    const handleSoundOff = () => {
        dispatch(changeSoundOn());
    };

    return (
        <>
            <Paper className={style.profile}>
                <div className={style.title_wrapper}>
                    <div className={style.profile_header}>
                        {/* TODO: Вынести аватар пользователя в отдельный компонент */}
                        <div className={style.profile_photo_container}>
                            {
                                user.picture?.path
                                    ?
                                    <img src={user.picture.path} alt="Profile" />
                                    :
                                    <>
                                        <h1 className={style.profile_photo_name}>
                                            {`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
                                        </h1>
                                        <div className={style.profile_photo_icon}>
                                            <IconUI typeIcon="camera" />
                                        </div>
                                    </>
                            }
                        </div>
                        <div className={style.header_info}>
                            {
                                roleNames[user.role]
                                    &&
                                <div className={cx(style.status, style.status_success)}>
                                    {roleNames[user.role]}
                                </div>
                            }
                            <h2 className={style.full_name}>
                                {`${user.lastName} ${user.firstName} ${user.patronymic}`}
                            </h2>
                            <div className={style.profile_breadcrumps_wrapper}>
                                {
                                    pathTo.length ?
                                        <Breadcrumbs>
                                            {
                                                pathTo.map((group) => <span key={group.uuid}>{group.name}</span>)
                                            }
                                        </Breadcrumbs>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
            <div className={cx(style.main_info_block)}>
                <Paper>
                    <div className={style.section}>
                        <h2 className={style.subtitle}>Личные данные</h2>
                        <div className={style.section_net}>
                            <ProfileItem label="Логин (email)" value={user.email} />
                            <ProfileItem label="Телефон" value={user.phone} />
                            <ProfileItem label="Страна" value={user.country} />
                            <ProfileItem label="Город" value={user.city} />
                            {isStudent && <ProfileItem label="Номер зачетки" value={user.recordBookNumber} />}
                        </div>
                    </div>
                </Paper>
                <Paper>
                    <div className={style.section}>
                        <div className={style.socialNetworks}>
                            <h2 className={style.subtitle}>Cоцсети</h2>

                            <FormProvider {...methods}>
                                <div className={style.inputs_wrapper}>
                                    {['vk', 'telegram'].map(network => (
                                        <div className={style.field} key={network}>
                                            <div className={style.label}>{network === 'vk' ? 'Вконтакте' : 'Telegram'}</div>
                                            <InputControl
                                                className={style.input}
                                                name={network}
                                                placeholder={`Аккаунт ${network === 'vk' ? 'VK' : 'Telegram'}`}
                                                disabled={isNeedDisabled}
                                            />
                                        </div>
                                    ))}
                                </div>

                                {!isNeedDisabled && isShowBtn && <Button btnType="success" className={style.save_btn} onClick={handleSubmit(onSubmit)}>
                                 Сохранить
                                </Button>
                                }
                            </FormProvider>
                        </div>
                    </div>
                </Paper>
                <Paper>
                    <div className={style.section}>
                        {!isNeedDisabled && (
                            <div className={style.socialNetworks}>
                                <h2 className={style.subtitle}>Уведомления</h2>
                                <div className={style.notice}>
                                    <p>Звуковые уведомления</p>
                                    <Switcher value={soundOn} onChange={handleSoundOff} />
                                </div>
                            </div>
                        )}
                    </div>
                </Paper>
            </div>
            { isStudent && <Skills />}
        </>
    );
};

export default UserProfileInfo;
