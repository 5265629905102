import { IStorageFile, SessionTypes } from '@store/api/apiTypes';
import { Role } from '@store/Roles';
import { DebtDiscipline } from '@type/disciplines';

import { ValueOf } from './common';

export interface User {
    uuid: string;
    groupUUID: string[];
    firstName: string;
    lastName: string;
    patronymic: string;
    email: string;
    role: Role;
    status: string;
    lastActivity: string;
    registrationStatus: string;
    vkontakte: string;
    telegram: string;
    picture?: IStorageFile;
    phone: string;
    country: string;
    city: string;
    createdAt: string;
    CountryID?: number;
    CityID?: number;
    recordBookNumber?: string;
    isChatAvailable?: boolean;
}

export interface UsersRequest {
    limit?: number;
    offset?: number;
    search?: string;
    role?: ValueOf<Role>;
    status?: string;
    uuid?: string;
}

export interface UsersResponse {
    total: number;
    count: number;
    users: User[];
}

export enum UserStatus {
    Active = 'active',
    Blocked = 'blocked'
}

export interface ChangeUserActiveStatusRequest {
    uuids: string[];
    status: UserStatus;
}

export interface ChangeUserChatStatusRequest {
    uuids: string[];
    status: boolean;
}

export interface Debtor extends User {
    role: Role.Student;
    debts: DebtDiscipline[];
}

export interface ExtraSessionDeadline {
    uuid: string;
    uuidUser: string;
    uuidDiscipline: string;
    uuidMaterial: string;
    startDate: string;
    endDate: string;
    uuidGroup: string;
    semester: SessionTypes
}
