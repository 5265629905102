import React from 'react';

import { useParams } from 'react-router-dom';

import { ReactComponent as CreateSection } from '@assets/image/create-section-svg.svg';
import { DNDWrapper } from '@components/core/DNDWrapper';
import { SectionCard } from '@components/core/DNDWrapper/DNDChildren/SectionCard';
import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import { Helmet } from '@components/Helmet';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { findCategory } from '@helpers/categories';
import { useNavigationHistory } from '@hooks/useNavigationHistory';
import { PositionsItemsList } from '@store/api/apiTypes';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { useGetMaterialsSectionsListQuery } from '@store/api/materialsApi';
import { useUpdatePositionsSectionsListMutation } from '@store/api/sectionsApi';
import { Role } from '@store/Roles';

import style from './structure.module.scss';

const Structure = () => {
    const { uuidDiscipline, uuidCategory } = useParams();
    const { navigate } = useNavigationHistory();

    const categoriesQueryData = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000');
    const category = findCategory(categoriesQueryData.data, uuidCategory);

    const { data: disciplineData } = useGetDisciplineByIdQuery(uuidDiscipline as string);

    const { data: sectionsList, isLoading } = useGetMaterialsSectionsListQuery(uuidDiscipline as string);
    const [fetchUpdatePositions] = useUpdatePositionsSectionsListMutation();

    const updatePositionsSectionsHandler = (reorderSectionsList: PositionsItemsList) => {
        fetchUpdatePositions({ positions: reorderSectionsList });
    };

    if (isLoading) return <NewLoader />;

    return (
        <React.Fragment>
            <Helmet title={category && disciplineData && `Учебные материалы – ${category.name}: ${disciplineData.name}, Структура`} />
            <h2 className={style.title}>Структура курса</h2>
            {
                sectionsList?.length ? (
                    <>
                        <div className={style.button_wrapper}>
                            {checkRole(Role.Methodist) ? (
                                <Button
                                    typeIcon="add"
                                    link={true}
                                    to="add-section"
                                >
                                    Новый раздел
                                </Button>
                            ) : <div />}
                            <Button
                                btnType="outline"
                                onClick={() => navigate(`/education/${uuidDiscipline}`)}
                            >
                                Предпросмотр
                            </Button>
                        </div>

                        <Grid>
                            <Column size={10}>
                                {checkRole(Role.Methodist) ? (
                                    <DNDWrapper items={sectionsList} onOrder={updatePositionsSectionsHandler}>
                                        {
                                            sectionsList.map(section => {
                                                return <SectionCard
                                                    key={section.uuid}
                                                    section={section}
                                                />;
                                            })
                                        }
                                    </DNDWrapper>
                                ) : sectionsList.map(section => {
                                    return <SectionCard
                                        key={section.uuid}
                                        section={section}
                                    />;
                                })}

                            </Column>
                        </Grid>
                    </>
                ) : (
                    <div className={style.create_section}>
                        <div className={style.background_img} >
                            <CreateSection />
                        </div>
                        <h3 className={style.create_section__title}>Структура курса</h3>
                        <p className={style.create_section__text}>Добавьте новые разделы
                            на страницу курса</p>
                        <RoleCheck roles={Role.Methodist}>
                            <Button typeIcon="add" link={true} to="add-section">Новый раздел</Button>
                        </RoleCheck>
                    </div>
                )
            }
        </React.Fragment>
    );
};

export default Structure;
