import { useNavigate } from 'react-router-dom';

import IconUI from '@components/core/IconUI';
import { ProgressIndicator } from '@components/core/ProgressBar/ProgressBar';
import { ImgWithDummy } from '@components/ImgWithDummy';
import { MarkdownPreview } from '@components/MarkdownPreview';
import Modal from '@components/Modal';
import { SessionTypesWithLocaleReverse, StudentSkill } from '@store/api/apiTypes';

import style from './SkillModal.module.scss';

export function SkillModal({ skill, close, isOpen, imagePath }: { skill: StudentSkill, isOpen: boolean; close: () => void; imagePath?: string }) {
    const navigate = useNavigate();

    const onDisciplineClick = (uuid: string) => {
        if (uuid) {
            navigate(`/education/${uuid}`);
        }
    };

    return (
        <Modal
            onClose={close}
            isOpen={isOpen}
        >
            <div className={style.wrapper}>
                <div className={style.header}>
                    <ImgWithDummy
                        width={60}
                        height={60}
                        src={imagePath}
                        alt="Изображение компетенции"
                    />
                    <div className={style.name}>{skill.name}</div>
                </div>
                <div className={style.progress}>
                    <div className={style.name_progress}>
                        <IconUI typeIcon='hat-graduation' />
                        {`Освоено ${skill.progress ?? 0}%`}
                    </div>
                    <ProgressIndicator completed={skill.progress ?? 0} />
                </div>
                <MarkdownPreview source={skill.content} isNewTabLinks  />
                <div className={style.disciplines}>
                    <h2 className={style.disciplines_header}>
                        Для открытия необходимо успешно сдать:
                    </h2>
                    <ul className={style.disciplines_list}>
                        {skill.disciplines.map((discipline) => (
                            <li
                                className={`${style.list_item} ${discipline.subscribed && style.list_item_active}`}
                                key={discipline.uuid}
                                onClick={() => onDisciplineClick(discipline.uuid)}
                            >
                                <div className={style.text_wrapper}>
                                    {discipline.name}
                                    <span className={style.semester}>
                                        {SessionTypesWithLocaleReverse[discipline.semester as  keyof typeof SessionTypesWithLocaleReverse]}
                                    </span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Modal>
    );
}
