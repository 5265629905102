import { FC } from 'react';

import { Control, useController } from 'react-hook-form';

import { FileUploadButton } from '@components/core/inputs/FileUpload';

type FileControlProps = {
    name: string;
    control: Control<any>;
    className?: string;
    accessTypes?: string[];
    children?: React.ReactNode;
    isMulti?: boolean;
    caption?: boolean;
    disabled?: boolean;
    withoutCheckControl?: boolean;
    maxSizeFile?: number
};

const FileUploadButtonControl: FC<FileControlProps> = ({ control, name, className, isMulti, caption, withoutCheckControl = false, ...props }) => {
    const {
        field: { onChange, value },
        fieldState: { error }
    } = useController({ name, control });

    return (
        <FileUploadButton
            name={name}
            value={value}
            onChange={onChange}
            helperText={error ? error.message : null}
            className={className}
            isMulti={isMulti}
            caption={caption}
            withoutCheckControl={withoutCheckControl}
            {...props}
        />
    );
};

export { FileUploadButtonControl };
