import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import Modal from '@components/Modal';
import { getBase64 } from '@helpers/base64';
import { CreateFileData, DisciplineMaterials, Materials, fileTypes } from '@store/api/apiTypes';
import { useUploadFileMutation } from '@store/api/storageApi';
import CreateFileForm from '@views/methodist/Materials/Course/CreateFileForm';
import { CreateFileFormData } from '@views/methodist/Materials/Course/CreateFileForm/type';

interface IAddFileModal {
    uuidParent?: string
    uuidSection?: string
    isOpen: boolean
    onClose: Dispatch<SetStateAction<boolean>>
    getCreateFileData?: (createLinkData: CreateFileData) => void
    isMaterialsSuccess?: boolean
    materials: DisciplineMaterials | Materials
    isFileCreationSuccess: boolean
    createFile: (formData: CreateFileData) => void
}

const AddFileModal: FC<IAddFileModal> = ({ 
    uuidParent, 
    uuidSection,
    isOpen, 
    onClose, 
    getCreateFileData,
    isMaterialsSuccess = true,
    materials,
    isFileCreationSuccess,
    createFile
}) => {
    const [uploadFile, { isSuccess: isSuccessUploadFile }] = useUploadFileMutation();
    const [defaultValues] = useState<CreateFileFormData>({
        name: '',
        file: null
    });

    const createFileHandler = async (formData: CreateFileFormData) => {
        let transformedFormData: CreateFileData = {
            uuidParent: uuidParent,
            uuidSection: uuidSection,
            name: formData.name,
            uuidFile: '',
            position: isMaterialsSuccess ? materials.length + 1 : 0
        };

        if (formData.file) {
            const b64 = await getBase64(formData.file);
            const uploadedFile = await uploadFile({
                file: b64 as string,
                meta: {
                    filename: formData.file.name,
                    type: fileTypes.Public
                }
            }).unwrap();
            transformedFormData = { ...transformedFormData, uuidFile: uploadedFile.uuid };
        }

        if (getCreateFileData) {
            getCreateFileData(transformedFormData);
            onClose(false);
        } else {
            createFile(transformedFormData);
        }
    };

    useEffect(() => {
        if (!getCreateFileData && isSuccessUploadFile && isFileCreationSuccess)
            onClose(false);
    }, [isSuccessUploadFile, isFileCreationSuccess]);

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title='Загрузить файл'
        >
            <CreateFileForm
                defaultValues={defaultValues}
                onSubmitFormHandler={createFileHandler}
                onCloseModal={onClose}
            />
        </Modal>

    );
};

export default AddFileModal;
