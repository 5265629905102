import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ChatProvider } from '@components/Chat/context/Provider';
import Calendar from '@components/core/Calendar/Calendar';
import NotFound from '@components/core/NotFound';
import { checkRole } from '@components/RoleCheck';
import { WebSocketProvider } from '@components/WebSocket/context/Provider';
import LoginLayout from '@layouts/Login';
import { EditCourseLayout, EditCourseLayoutWithContext } from '@layouts/Methodist/EditCourseLayout';
import EditGroupLayout from '@layouts/Methodist/EditGroupLayout';
import { EditTestLayout, EditTestLayoutWithContext } from '@layouts/Methodist/EditTestLayout';
import MethodistLayout from '@layouts/Methodist/MainLayout';
import MaterialsListLayout from '@layouts/Methodist/MaterialsListLayout';
import StudyProgrammesLayout from '@layouts/Methodist/StudyProgrammesLayout';
import UsersListLayout from '@layouts/Methodist/UsersListLayout';
import StudentLayout from '@layouts/StudentLayout';
import TeacherLayout from '@layouts/TeacherLayout';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { LoginPage } from '@views/login/LoginPage';
import { ResetPage } from '@views/login/ResetPage';
import AdditionalSettings from '@views/methodist/AdditionalSettings';
import AdditionalSettingsLayout from '@views/methodist/AdditionalSettings/AdditionalSettingsLayout';
import AdditionalSettingsTabs from '@views/methodist/AdditionalSettings/AdditionalSettingsLayout/AdditionalSettingsTabs/AdditionalSettingsTabs';
import Binding from '@views/methodist/AdditionalSettings/AdditionalSettingsLayout/Binding';
import PortfolioSettings from '@views/methodist/AdditionalSettings/AdditionalSettingsLayout/PortfolioSettings';
import PresetsSettings from '@views/methodist/AdditionalSettings/AdditionalSettingsLayout/PresetsSettings';
import SkillsDisciplinesSettings from '@views/methodist/AdditionalSettings/AdditionalSettingsLayout/SkillsDisciplinesSettings';
import SkillsSettings from '@views/methodist/AdditionalSettings/AdditionalSettingsLayout/SkillsSettings';
import AddSkill from '@views/methodist/AdditionalSettings/AdditionalSettingsLayout/SkillsSettings/AddSkill';
import EditSkill from '@views/methodist/AdditionalSettings/AdditionalSettingsLayout/SkillsSettings/EditSkill';
import Events from '@views/methodist/Events';
import CreateEvent from '@views/methodist/Events/CreateEvent';
import CreateBasicEvent from '@views/methodist/Events/CreateEvent/CreateBasicEvent';
import EventsLayout from '@views/methodist/Events/EventsLayout';
import { FinishedEventsWrapper } from '@views/methodist/Events/FinishedEvents/FinishedEventsWrapper';
import TabsLayout from '@views/methodist/Events/TabsLayout';
import Course from '@views/methodist/Materials/Course';
import CreateCoursePage from '@views/methodist/Materials/Course/CreateCourse/CreateCoursePage';
import CreatePage from '@views/methodist/Materials/Course/CreatePage';
import EditCourse from '@views/methodist/Materials/Course/EditCourse';
import BasicCourse from '@views/methodist/Materials/Course/EditCourse/BasicCourse';
import Structure from '@views/methodist/Materials/Course/EditCourse/Structure';
import CreateSection from '@views/methodist/Materials/Course/EditCourse/Structure/CreateSection';
import CreateTask from '@views/methodist/Materials/Course/EditCourse/Structure/CreateTask';
import EditSection from '@views/methodist/Materials/Course/EditCourse/Structure/EditSection';
import EditTask from '@views/methodist/Materials/Course/EditCourse/Structure/EditTask';
import EditLecture from '@views/methodist/Materials/Course/EditLecture';
import EditChapterForm from '@views/methodist/Materials/Course/EditLecture/EditChapterForm';
import EditPage from '@views/methodist/Materials/Course/EditPage';
import Lectures from '@views/methodist/Materials/Course/Lectures';
import { Scores } from '@views/methodist/Materials/Course/Scores/Scores';
import { Subscribe } from '@views/methodist/Materials/Course/Subscribe/Subscribe';
import TaskResult from '@views/methodist/Materials/Course/TaskResult';
import CreateTest from '@views/methodist/Materials/Course/Test/CreateTest';
import EditTest from '@views/methodist/Materials/Course/Test/EditTest';
import Basic from '@views/methodist/Materials/Course/Test/EditTest/BasicTest';
import Content from '@views/methodist/Materials/Course/Test/EditTest/Content';
import Questions from '@views/methodist/Materials/Course/Test/EditTest/Questions';
import { FinalTest } from '@views/methodist/Materials/Course/Test/FinalTest/FinalTest';
import TotalTest from '@views/methodist/Materials/Course/Test/TotalTest';
import TestResult from '@views/methodist/Materials/Course/TestResult';
import VideoLectures from '@views/methodist/Materials/Course/VideoLectures';
import ViewCourseTemplate from '@views/methodist/Materials/Course/ViewCourseTemplate';
import MaterialsPage from '@views/methodist/Materials/MaterialsPage';
import MaterialsList from '@views/methodist/Materials/MaterialsPage/MaterialsList';
import Reports from '@views/methodist/Reports';
import ReportsLayout from '@views/methodist/Reports/ReportsLayout';
import ArchiveLayout from '@views/methodist/Reports/ReportsLayout/Archive/ArchiveLayout';
import EventsLog from '@views/methodist/Reports/ReportsLayout/EventsLog/EventsLog';
import ReportsTabs from '@views/methodist/Reports/ReportsLayout/ReportsTabs/ReportsTabs';
import { Statements } from '@views/methodist/Reports/ReportsLayout/Statements';
import { StatementEditor } from '@views/methodist/Reports/ReportsLayout/Statements/StatementEditor';
import TeacherReports from '@views/methodist/Reports/ReportsLayout/TeacherReports';
import Users from '@views/methodist/Users';
import { ExtraSession } from '@views/methodist/Users/ExtraSession';
import UserProfile from '@views/methodist/Users/UserProfile';
import UserProfileInfo from '@views/methodist/Users/UserProfile/UserProfileInfo';
import UserRecordBook from '@views/methodist/Users/UserProfile/UserRecordBook';
import UsersGroups from '@views/methodist/Users/UsersGroups';
import EditGroup from '@views/methodist/Users/UsersGroups/EditGroup';
import StudyProgrammes from '@views/methodist/Users/UsersGroups/StudyProgrammes';
import CreateProgramme from '@views/methodist/Users/UsersGroups/StudyProgrammes/CreateProgramme';
import StudyProgrammesAddSection from '@views/methodist/Users/UsersGroups/StudyProgrammes/CreateSection';
import StudyProgrammesEditSection from '@views/methodist/Users/UsersGroups/StudyProgrammes/EditSection';
import StudyProgrammesAddPage from '@views/methodist/Users/UsersGroups/StudyProgrammesAddPage';
import StudyProgrammesEditPage from '@views/methodist/Users/UsersGroups/StudyProgrammesEditPage';
import UsersList from '@views/methodist/Users/UsersList';
import ConfidentialityPage from '@views/student/ConfidentialityPage';
import { ContactsPage } from '@views/student/Contacts';
import EquipmentRequirementsPage from '@views/student/EquipmentRequirementsPage';
import LecturesPreview from '@views/student/LecturesPreview';
import ChapterLayout from '@views/student/LecturesPreview/ChapterLayout';
import ChapterPreview from '@views/student/LecturesPreview/ChapterPreview';
import PagePreview from '@views/student/PagePreview';
import RecordBook from '@views/student/RecordBook';
import StudentCourse from '@views/student/StudentCourse';
import StudentEvents from '@views/student/StudentEvents';
import StudentMain from '@views/student/StudentMain';
import StudentProfile from '@views/student/StudentProfile';
import ChangePassword from '@views/student/StudentProfile/ChangePassword';
import Portfolio from '@views/student/StudentProfile/Portfolio';
import StudentProfileInfo from '@views/student/StudentProfile/StudentProfileInfo';
import Skills from '@views/student/StudentProfile/StudentProfileInfo/Skills';
import StudentStudyProgrammLayout from '@views/student/StudentStudyProgrammLayout';
import StudentStudyProgramm from '@views/student/StudentStudyProgrammLayout/StudentStudyProgramm';
import StudentStudyProgrammPage from '@views/student/StudentStudyProgrammLayout/StudentStudyProgramm/StudentStudyProgrammPage';
import StudentTask from '@views/student/StudentTask';
import { StudentTaskAuth } from '@views/student/StudentTask/StudentTaskAuth/StudentTaskAuth';
import StudentTest from '@views/student/StudentTest';
import NewTestPassing from '@views/student/StudentTest/NewTestPassing';
import { StudentTestAuth } from '@views/student/StudentTest/StudentTestAuth';
import TestStart from '@views/student/StudentTest/TestStart';
import TrainingRulesPage from '@views/student/TrainingRulesPage';
import UserAgreement from '@views/student/UserAgreement';
import VideoLecture from '@views/student/VideoLecture';
import GradesTask from '@views/teacher/GradesTask';
import { ChangePasswordWrapper } from '@views/teacher/ShowStudent/ChangePasswordWrapper';
import { SubstituteWorks } from '@views/teacher/SubstituteWorks';
import TasksVerification from '@views/teacher/TasksVerification';
import TeacherMain from '@views/teacher/TeacherMain';
import TeachingTabs from '@views/teacher/TeacherMain/TeachingTabs';
import TeachingDisciplines from '@views/teacher/TeachingDisciplines';
import VerifiedWorks from '@views/teacher/VerifiedWorks';

import CheckAuth from './CheckAuth';
import RoleIndexRoute from './RoleIndexRoute';


const Routers = () => {
    useAppSelector(selectApiUser);

    return (
        <BrowserRouter>
            <CheckAuth>
                <Routes>
                    <Route element={<LoginLayout />}>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/reset-password" element={<ResetPage />} />
                    </Route>

                    <Route path="/" element={<RoleIndexRoute />} />
                    <Route element={<WebSocketProvider />}>
                        <Route element={<ChatProvider />}>

                            {
                                checkRole([Role.Methodist, Role.Inspector]) && <Route>
                                    <Route element={<MethodistLayout />}>

                                        <Route path="materials" element={<MaterialsPage />}>
                                            <Route element={<MaterialsListLayout />}>
                                                <Route path=":uuidCategory" element={<MaterialsList />} />
                                                <Route path="methodist/:uuidMethodist" element={<MaterialsList />} />
                                            </Route>

                                            {/* TODO: Replace */}
                                            <Route path="total-test" element={<TotalTest />} />
                                            <Route path="final-test" element={<FinalTest />} />

                                            <Route path=":uuidCategory/course" element={<Course />}>
                                                {checkRole(Role.Methodist) && <Route path="create-course" element={<CreateCoursePage />} />}

                                                <Route path=":uuidDiscipline">

                                                    <Route element={<EditCourse />}>
                                                        <Route element={<EditCourseLayoutWithContext />}>
                                                            <Route index element={<BasicCourse />} />
                                                        </Route>
                                                        {checkRole(Role.Methodist) && <Route path="add-page" element={<CreatePage />} />}
                                                        <Route path="edit-page/:uuidPage" element={<EditPage />} />
                                                    </Route>

                                                    <Route element={<EditCourseLayout />}>
                                                        <Route path="structure" element={<Structure />} />
                                                        <Route path="scores" element={<Scores />} />

                                                    </Route>

                                                    <Route path="structure/:uuidSection">
                                                        {checkRole(Role.Methodist) && <Route path="add-test" element={<CreateTest />} />}

                                                        <Route path="edit-test/:uuidTest" element={<EditTest />}>
                                                            <Route element={<EditTestLayoutWithContext />}>
                                                                <Route index element={<Basic />} />
                                                                <Route path="content" element={<Content />} />
                                                                <Route path="questions" element={<Questions />} />
                                                            </Route>
                                                        </Route>

                                                        <Route element={<EditTestLayout />}>
                                                            <Route path="questions" element={<Questions />} />
                                                        </Route>

                                                    </Route>

                                                    {checkRole(Role.Methodist) && <Route path="structure/add-section" element={<CreateSection />} />}
                                                    {checkRole(Role.Methodist) &&
                                                        <Route
                                                            path="structure/edit-section/:uuidSection"
                                                            element={<EditSection />}
                                                        />
                                                    }
                                                    {checkRole(Role.Methodist) && <Route path="structure/:uuidSection/add-task" element={<CreateTask />} />}
                                                    <Route path="structure/:uuidSection/edit-task/:uuidTask" element={<EditTask />} />

                                                    <Route path="structure/:uuidSection/video-lectures/:uuidVideoBlock" element={<VideoLectures />} />

                                                    <Route path="structure/:uuidSection/lectures-block/:uuidLectureBlock" element={<Lectures />} />
                                                    <Route path="structure/:uuidSection/lectures-block/:uuidLectureBlock/topic/:uuidTopic/lecture/:uuidLecture"
                                                        element={<EditLecture />}
                                                    >
                                                        <Route path="chapter/:uuidChapter" element={<EditChapterForm />} />
                                                    </Route>

                                                    {checkRole(Role.Methodist) && <Route path="structure/:uuidSection/add-page" element={<CreatePage />} />}
                                                    <Route path="structure/:uuidSection/edit-page/:uuidPage" element={<EditPage />} />
                                                </Route>
                                            </Route>
                                        </Route>

                                        <Route
                                            path={'materials/:uuidCategory/course/:uuidDiscipline/scores/task/:userId/:materialId'}
                                            element={<TaskResult />}
                                        />
                                        <Route
                                            path={'materials/:uuidCategory/course/:uuidDiscipline/scores/test/:userId/:materialId'}
                                            element={<TestResult />}
                                        />

                                        <Route path="users" element={<Users />}>
                                            <Route path="groups/:uuidGroup/skills-binding" element={<Binding />} />

                                            <Route element={<UsersListLayout />}>
                                                <Route index element={<UsersList />} />
                                                <Route path="groups/:uuidGroup" element={<UsersGroups />} />
                                            </Route>

                                            <Route path=":userId/user" element={<UserProfile />}>
                                                <Route index element={<UserProfileInfo />} />
                                                <Route path="recordBook" element={<UserRecordBook />} />
                                                <Route path="portfolio" element={<Portfolio />} />
                                                <Route path="changePassword" element={<ChangePassword />} />
                                                <Route path="skills" element={<Skills  />} />
                                            </Route>

                                            <Route element={<EditGroupLayout />}>
                                                <Route path="groups/:uuidGroup/editGroup" element={<EditGroup />} />

                                                {checkRole(Role.Methodist) && (
                                                    <Route path="groups/:uuidGroup/course-subscribe/:activeGroup" element={<Subscribe />} />
                                                )}
                                                {checkRole(Role.Methodist) && (
                                                    <Route path="groups/:uuidGroup/course-subscribe/:activeGroup/:studentId" element={<Subscribe />} />
                                                )}

                                                <Route path="groups/:uuidGroup/studyProgrammes" element={<StudyProgrammesLayout />}>
                                                    <Route index element={<StudyProgrammes />} />
                                                    <Route path="create" element={<CreateProgramme />} />
                                                    {checkRole(Role.Methodist) && <Route path="add-section" element={<StudyProgrammesAddSection />} />}
                                                    {checkRole(Role.Methodist) &&
                                                      <Route
                                                          path="edit-section/:uuidSection"
                                                          element={<StudyProgrammesEditSection />}
                                                      />
                                                    }

                                                    {checkRole(Role.Methodist) && <Route path="add-page/:uuidSection" element={<StudyProgrammesAddPage />} />}
                                                    {checkRole(Role.Methodist) &&
                                                        <Route
                                                            path=":uuidSection/edit-page/:uuidPage"
                                                            element={<StudyProgrammesEditPage />}
                                                        />
                                                    }
                                                </Route>
                                            </Route>

                                            {checkRole(Role.Methodist) && <Route path="groups/:uuidGroup/extra-session" element={<ExtraSession />} />}
                                        </Route>

                                        <Route path="events" element={<Events />}>
                                            <Route element={<EventsLayout />}>
                                                <Route element={<TabsLayout />}>
                                                    <Route index element={<Calendar />} />
                                                    <Route path="finished" element={<FinishedEventsWrapper />} />
                                                </Route>

                                                <Route path="finished/:uuidDiscipline/:uuidEvent" element={<VideoLecture />} />
                                            </Route>

                                            {checkRole(Role.Methodist) && (
                                                <Route path="create-event" element={<CreateEvent />}>
                                                    <Route index element={<CreateBasicEvent />} />
                                                </Route>
                                            )}
                                        </Route>

                                        <Route path="reports" element={<Reports />}>
                                            <Route element={<ReportsLayout />}>
                                                <Route element={<ReportsTabs />}>
                                                    <Route index element={<TeacherReports />} />
                                                    <Route path="statements" element={<Statements />} />
                                                    <Route path="eventsLog" element={<EventsLog />} />
                                                </Route>
                                            </Route>
                                            <Route path="statements/edit" element={<StatementEditor />} />
                                        </Route>
                                        <Route path="reports/archive" element={<ArchiveLayout />} />
                                        <Route path="additional-settings" element={<AdditionalSettings />}>
                                            <Route  element={<AdditionalSettingsLayout />}>
                                                <Route element={<AdditionalSettingsTabs />}>
                                                    <Route index element={<PortfolioSettings />} />
                                                    <Route path="skills" element={<SkillsSettings />} />
                                                    <Route path="disciplines" element={<SkillsDisciplinesSettings />} />
                                                    <Route path="presets" element={<PresetsSettings />} />
                                                </Route>
                                            </Route>
                                            <Route path="statements/edit" element={<StatementEditor />} />
                                            <Route path="skills/add" element={<AddSkill />} />
                                            <Route path="skills/edit/:skillUUID" element={<EditSkill />} />
                                        </Route>

                                        <Route path="education/:uuidDiscipline" element={<ViewCourseTemplate />}>
                                            <Route index element={<StudentCourse />} />
                                            <Route path="page/:uuidPage" element={<PagePreview />} />
                                            <Route path="lecture/:uuidLectureBlock" element={<LecturesPreview />}>
                                                <Route index element={<ChapterLayout />} />
                                                <Route element={<ChapterLayout />}>
                                                    <Route path=":uuidChapter" element={<ChapterPreview />} />
                                                </Route>
                                            </Route>
                                            <Route path="video-lecture/:uuidVideoBlock/:uuidVideo" element={<VideoLecture />} />
                                            <Route path="task/:uuidTask" element={<StudentTask />} />
                                        </Route>
                                    </Route>

                                    <Route path="*" element={<NotFound />} />
                                </Route>
                            }

                            {
                                checkRole(Role.Student) && <Route element={<StudentLayout />}>
                                    <Route path="education" element={<StudentMain />} />

                                    <Route path="studyProgramm" element={<StudentStudyProgrammLayout />}>
                                        <Route index element={<StudentStudyProgramm />} />
                                        <Route path="page/:uuidPage" element={<StudentStudyProgrammPage />} />
                                    </Route>

                                    <Route path="education/:uuidDiscipline">
                                        <Route index element={<StudentCourse />} />
                                        <Route path="page/:uuidPage" element={<PagePreview />} />
                                        <Route path="lecture/:uuidLectureBlock" element={<LecturesPreview />}>
                                            <Route index element={<ChapterLayout />} />
                                            <Route element={<ChapterLayout />}>
                                                <Route path=":uuidChapter" element={<ChapterPreview />} />
                                            </Route>
                                        </Route>
                                        <Route path="video-lecture/:uuidVideoBlock/:uuidVideo" element={<VideoLecture />} />

                                        <Route path="task/:uuidTask">
                                            <Route index element={<StudentTask />} />
                                            <Route path="auth-persona" element={<StudentTaskAuth />} />
                                        </Route>

                                        <Route path="test/:uuidTest" element={<StudentTest />}>
                                            <Route index element={<TestStart />} />
                                            <Route path="start" element={<NewTestPassing />} />
                                            <Route path="auth" element={<StudentTestAuth />} />
                                        </Route>
                                    </Route>


                                    <Route path="education/:uuidDiscipline" element={<StudentCourse />} />

                                    <Route path="events" element={<StudentEvents />}>
                                        <Route index element={<Calendar />} />
                                        <Route path="finished" element={<FinishedEventsWrapper />} />
                                    </Route>
                                    <Route path="events/finished/:uuidDiscipline/:uuidEvent" element={<VideoLecture />} />

                                    <Route path="record-book" element={<RecordBook />} />

                                    <Route path="rules" element={<TrainingRulesPage />} />
                                    <Route path="privacy-policy" element={<ConfidentialityPage />} />
                                    <Route path="equipment-requirements" element={<EquipmentRequirementsPage />} />
                                    <Route path="user-agreement" element={<UserAgreement />} />
                                    <Route path="contacts" element={<ContactsPage />} />

                                    <Route path="/userProfile" element={<StudentProfile />}>
                                        <Route index element={<StudentProfileInfo />} />
                                        <Route path="changePassword" element={<ChangePassword />} />
                                        <Route path="portfolio" element={<Portfolio />} />
                                    </Route>

                                    <Route path="*" element={<NotFound />} />
                                </Route>
                            }
                            {
                                checkRole(Role.Teacher) && <Route element={<TeacherLayout />}>
                                    <Route path="teaching" element={<TeacherMain />}>
                                        <Route element={<TeachingTabs />}>
                                            <Route index element={<TasksVerification />} />
                                            <Route path="tasksVerified" element={<VerifiedWorks />} />
                                            <Route path="teachingDisciplines" element={<TeachingDisciplines />} />
                                            <Route path="substitute" element={<SubstituteWorks />} />
                                        </Route>

                                        <Route path="grades/:uuidUser/:uuidTask" element={<GradesTask />} />
                                    </Route>

                                    <Route path="teaching/:uuidDiscipline">
                                        <Route index element={<StudentCourse />} />
                                        <Route path="page/:uuidPage" element={<PagePreview />} />
                                        <Route path="video-lecture/:uuidVideoBlock/:uuidVideo" element={<VideoLecture />} />
                                    </Route>

                                    <Route path="events" element={<StudentEvents />}>
                                        <Route index element={<Calendar />} />
                                        <Route path="finished" element={<FinishedEventsWrapper />} />
                                    </Route>
                                    <Route path="events/finished/:uuidDiscipline/:uuidEvent" element={<VideoLecture />} />

                                    <Route path="rules" element={<TrainingRulesPage />} />
                                    <Route path="privacy-policy" element={<ConfidentialityPage />} />
                                    <Route path="equipment-requirements" element={<EquipmentRequirementsPage />} />
                                    <Route path="contacts" element={<ContactsPage />} />

                                    <Route path="user/:userId" element={<StudentProfile />}>
                                        <Route index element={<StudentProfileInfo />} />
                                        <Route path="portfolio" element={<Portfolio />} />
                                        <Route element={<ChangePasswordWrapper />}>
                                            <Route path="changePassword" element={<ChangePassword />} />
                                        </Route>
                                    </Route>

                                    <Route path="*" element={<NotFound />} />
                                </Route>
                            }
                        </Route>
                    </Route>
                </Routes>
            </CheckAuth>
        </BrowserRouter>
    );
};

export default Routers;
