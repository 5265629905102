import React, { useState } from 'react';

import { Outlet, useMatch, useNavigate, useOutletContext, useParams, useResolvedPath } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { checkRole } from '@components/RoleCheck';
import RouterTabs from '@components/RouterTabs';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useGetUserByUuidQuery } from '@store/api/usersApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { User } from '@type/users';
import AddFileModal from '@views/student/StudentProfile/Portfolio/AddFileModal';

import style from './student_profile.module.scss';


export const useUser = () => {
    return useOutletContext<User>();
};

const StudentProfile = () => {
    const smUp = useBreakpoint('sm', 'up');
    const navigate = useNavigate();
    const { userId } = useParams();
    const me = useAppSelector(selectApiUser);
    const [isOpenAddFileModal, setIsOpenAddFileModal] = useState<boolean>(false);
    const resolved = useResolvedPath('portfolio');
    const matchPortfolioPath = useMatch({ path: resolved.pathname });

    const { isFetching, data, isError } = useGetUserByUuidQuery(me?.role === Role.Teacher ? userId as string : me?.uuid || '');

    if (isFetching) return <NewLoader />;

    if (!data || isError) {
        navigate('/education');
        toast.error('Ошибка загрузки данных профиля');
    }

    const getTitle = () => {
        if (me?.role === Role.Student) {
            return 'Мой профиль';
        }
        return me?.uuid === userId ? 'Мой профиль' : 'Профиль студента';
    };

    const getTabs = () => {
        const tabs = [
            { name: 'Личные данные', path: '' }
        ];

        if (me?.uuid !== userId && (me?.role === Role.Teacher || me?.role === Role.Methodist)) {
            tabs.push({ name: 'Портфолио', path: 'portfolio' });
        }

        if (me?.role === Role.Student) {
            tabs.push({ name: 'Портфолио', path: 'portfolio' });
        }

        if (me?.uuid === userId || me?.role === Role.Student) {
            tabs.push({ name: 'Смена пароля', path: 'changePassword' });
        }

        return tabs;
    };

    const openAddFileModalHandler = () => {
        setIsOpenAddFileModal(true);
    };

    return (
        <>
            <Main>
                <div className={style.title_wrapper}>
                    <h1>{getTitle()}</h1>

                </div>

                <div className={style.tabs_wrapper}>
                    <RouterTabs className={style.tabs} tabs={getTabs()} />
                    {(smUp && (matchPortfolioPath && checkRole([Role.Student]))) && (
                        <Button
                            onClick={openAddFileModalHandler}
                            className={style.tabs_wrapper__btn}
                        >
                            Добавить файл</Button>
                    )}
                    <AddFileModal isOpen={isOpenAddFileModal} onClose={setIsOpenAddFileModal} />
                    <Outlet context={data} />
                </div>
            </Main>
        </>
    );
};

export default StudentProfile;
