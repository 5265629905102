import React from 'react';

import { Outlet } from 'react-router-dom';

import MainFooter from '@components/core/MainFooter';
import { Notify } from '@components/Notify';
import TeacherHeader from '@components/teacher/TeacherHeader';

import style from './TeacherLayout.module.scss';

const TeacherLayout = () => {

    return (
        <div className={style.layout_container}>
            <Notify />
            <TeacherHeader />
            <div className={style.main_layout_wrapper}>
                <Outlet />
            </div>
            <MainFooter />
        </div>
    );
};

export default TeacherLayout;
