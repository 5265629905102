import React, { useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';

import Button from '@components/core/inputs/Button';
import { useToggle } from '@hooks/useToggle.hook';

import AdditionalSessionModal from '../AdditionalSessionModal';
import styles from './AdditionalSession.module.scss';

export const AdditionalSession = () => {
    const { uuidGroup } = useParams();

    const navigate = useNavigate();

    const [isOpen, { off, toggle }] = useToggle();
    const [isOpenModal, { on: openModal, off: closeModal }] = useToggle();

    const containerRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(containerRef, () => {
        if (isOpen) {
            off();
        }
    });

    const handleCreate = () => {
        off();
        openModal();
    };

    const handeEdit = () => {
        off();
        navigate(`/users/groups/${uuidGroup}/extra-session`);
    };

    return (
        <>
            <div ref={containerRef} className={styles.container}>
                <Button
                    btnType="success"
                    onClick={toggle}
                >
                    Доп.сессия
                </Button>
                {isOpen && (
                    <div className={styles.button_wrapper}>
                        <div className={styles.item} onClick={handeEdit}>
                            Редактировать
                        </div>
                        <div className={styles.item} onClick={handleCreate}>
                            Создать
                        </div>
                    </div>
                )}
            </div>
            <AdditionalSessionModal
                onClose={closeModal}
                isOpen={isOpenModal}
                uuidGroup={uuidGroup || ''}
            />
        </>

    );
};
