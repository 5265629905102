import React, { FC } from 'react';

import cx from 'classnames';

import style from './HelperText.module.scss';

interface IHelperText {
    message?: string
    type?: 'success' | 'error'
}

const HelperText: FC<IHelperText> = ({
    message,
    type = 'error'
}) => {
    return (
        <span className={cx(type === 'error' && style.error)}>
            {message}
        </span>
    );
};

export default HelperText;
