import { FC, useEffect } from 'react';

import cx from 'classnames';

import { Column, Grid } from '@components/core/grid';
import { NewLoader } from '@components/core/NewLoader';
import { DataList, DataListCell } from '@components/DataList';
import { Pagination, usePagination } from '@components/Pagination';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetTeacherReportsQuery } from '@store/api/reportsApi';
import { TeacherDiscipline } from '@type/reports';

import style from './TeacherReportsList.module.scss';


interface ITeacherReportsList {
    teacher: string;
    beginDate: string;
    endDate: string;
}

const TeacherReportsList: FC<ITeacherReportsList> = ({ teacher, beginDate, endDate }) => {
    const reportsInfo = useGetTeacherReportsQuery(teacher ? {
        uuidTeacher: teacher === 'all' ? undefined : teacher,
        beginDate: beginDate,
        endDate: endDate
    } : skipToken);

    const { handleSliceData, setTotal, paginationProps } = usePagination<TeacherDiscipline>({});

    const disciplines = reportsInfo.data?.disciplines || [];
    const allReportsTotalCount = disciplines.reduce((totalCount, discipline) => totalCount + discipline.totalCount, 0);
    const allSubmittedReportsCount = disciplines.reduce((submittedCount, discipline) => submittedCount + discipline.submittedWorkCount, 0);

    const getEmptyMessage = () => {
        return (
            <div className={style.empty_message_text}>
                { teacher ? 'Нет дисциплин назначенных на преподавателя' : 'Выберите преподавателя для отображения данных'}
            </div>
        );
    };

    useEffect(() => {
        setTotal(reportsInfo.data?.disciplines.length || 0);
    }, [reportsInfo]);

    if (reportsInfo.isFetching) return <NewLoader />;

    const format = (timeSeconds: number) => {
        const hours = Math.floor(timeSeconds / 3600);
        const minutes = Math.floor(timeSeconds % 3600 / 60);
        const seconds = Math.floor(timeSeconds % 3600 % 60);

        const hoursDisplay   = String(hours  ).padStart(2, '0');
        const minutesDisplay = String(minutes).padStart(2, '0');
        const secondsDisplay = String(seconds).padStart(2, '0');

        return `${hoursDisplay}:${minutesDisplay}:${secondsDisplay}`;
    };

    return (
        <>
            <h3 className={style.disciplines_count}>Всего записей: {disciplines.length}</h3>
            <DataList className={style.list_wrapper}>
                <Grid gridTemplateColumns="49% 12% 12% 12% 12%" className={style.data_list__header}>
                    <>
                        <Column>
                            <DataListCell className={style.column_label}>Дисциплина</DataListCell>
                        </Column>
                        <Column>
                            <DataListCell className={cx(style.column_label, style.flex_end)}>Время на проверку (сред.)</DataListCell>
                        </Column>
                        <Column>
                            <DataListCell className={cx(style.column_label, style.flex_end)}>Время на проверку (мед.)</DataListCell>
                        </Column>
                        <Column>
                            <DataListCell className={cx(style.column_label, style.flex_end)}>На проверку ({allReportsTotalCount})</DataListCell>
                        </Column>
                        <Column>
                            <DataListCell className={cx(style.column_label, style.flex_end)}>Проверенные ({allSubmittedReportsCount})</DataListCell>
                        </Column>
                    </>
                </Grid>
                {
                    !disciplines.length ?
                        <div className={style.empty_message}>{getEmptyMessage()}</div>
                        :
                        handleSliceData(disciplines).map((discipline) => {
                            return (
                                <div key={discipline.uuidDiscipline}>
                                    <Grid gridTemplateColumns="49% 12% 12% 12% 12%" className={style.list_item}>
                                        <>
                                            <Column>
                                                {discipline.nameDiscipline}
                                            </Column>
                                            <Column className={style.column_content}>
                                                {format(discipline.averageTime)}
                                            </Column>
                                            <Column className={style.column_content}>
                                                {format(discipline.medianTime)}
                                            </Column>
                                            <Column className={style.column_content}>
                                                {discipline.totalCount}
                                            </Column>
                                            <Column className={style.column_content}>
                                                {discipline.submittedWorkCount}
                                            </Column>
                                        </>
                                    </Grid>
                                </div>
                            );
                        })
                }
            </DataList>
            <Pagination className={style.pagination} {...paginationProps} />
        </>
    );
};

export default TeacherReportsList;
