import { toast } from 'react-toastify';

import { compileUrl } from '@helpers/api';
import {
    FinishTestResponse,
    FinishTestRequest,
    TestAddQuestionsRequest,
    TestCreateData,
    TestData,
    TestDeleteQuestionsRequest,
    TestDeleteRequest, TestGetQuestionsFromBank, TestGetQuestionsFromBankForQuestion,
    TestQuestionWithAnswers, TestSetRandomQuestions, TestUpdateData,
    TestWithQuestions,
    PassStudentTest,
    TestAttemptsInfo,
    GetTestRequest,
    TestImportRequest, DeleteAttemptParams
} from '@store/api/apiTypes';
import { ExportedScoreCard, ScoreCardResponse } from '@type/scoreCard';
import { PassStudentTestWithSnapshot, StudentsTestSnapshotRequest } from '@type/tests';

import { emptyBaseApi } from './emptyBaseApi';

export const testsApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        createTest: build.mutation<TestData, TestCreateData>({
            query(data) {

                return {
                    url: 'test/create',
                    method: 'POST',
                    body: {
                        uuidDiscipline: data.uuidDiscipline,
                        uuidSection: data.uuidSection,
                        name: data.name,
                        description: data.description,
                        maximumRating: data.maximumRating,
                        timeLimit: data.timeLimit,
                        numberAttempts: data.numberAttempts,
                        needEvaluate: data.needEvaluate,
                        accessRestricted: data.accessRestricted
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Создан тест',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => result ?
                [ { type: 'Material', id: `List_${arg.uuidDiscipline}` }, { type:'Tests', id: result.uuid },'SectionList' ] :
                [ { type: 'Material', id: `List_${arg.uuidDiscipline}` },'SectionList']

        }),
        // deleteTest: build.mutation<void, TestDeleteRequest>({
        //     query(req) {
        //         return {
        //             url: 'test/delete',
        //             method: 'POST',
        //             body: {
        //                 uuidTests: [req.testUuid]
        //             }
        //         };
        //     },
        //     async onQueryStarted(args, { queryFulfilled }) {
        //         toast.promise(queryFulfilled, {
        //             success: 'Удалено',
        //             error: 'Ошибка удаления!'
        //         });
        //     },
        //     invalidatesTags: (result,error,arg) => [ { type: 'Material', id: `List_${arg.disciplineUuid}` }, { type:'Tests', id: arg.testUuid } ]
        // }),

        updateTest: build.mutation<TestData, TestUpdateData>({
            query(data) {
                return {
                    url: `test/${data.uuid}`,
                    method: 'PUT',
                    body: data.testData
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Тест обновлен',
                    error: 'Ошибка обновления!'
                });
            },
            invalidatesTags: (result, error, arg) => [ { type: 'Material', id: `List_${arg.testData.uuidDiscipline}` }, { type:'Tests', id: arg.uuid } ]
        }),

        setTestRandomQuestions:build.mutation<void,TestSetRandomQuestions>({
            query(data) {


                return {
                    url: 'test/questions/number',
                    method: 'POST',
                    body: {
                        testUuid: data.testUuid,
                        questionsNum: data.questionsNum
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Тест обновлен',
                    error: 'Ошибка обновления!'
                });
            },
            invalidatesTags: (result,error,arg) => {
                return [ { type:'Tests', id:arg.testUuid } ];
            }
        }),

        getTestById:build.query<TestWithQuestions, string>({

            query: (testId: string) => ({
                url: 'test/list',
                method: 'POST',
                body: {
                    uuidTests: [testId]
                }
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить тест');
                }
            },
            transformResponse: (result: { tests: TestWithQuestions[] })=>{
                return result.tests[0];
            },

            providesTags: (result,error,arg) => {
                //console.log('-----get test tag:'+arg);
                return [ { type: 'Tests', id: arg } ];
            }
        }),
        // result? [  { type: 'Tests' as const, id:result.test.uuid }  ]:


        // getTestsBySection: build.query<TestWithQuestions[], string>({
        //     query: (sectionId: string) => ({
        //         url: 'test/all/list/section',
        //         method: 'POST',
        //         body: {
        //             uuids: [sectionId]
        //         }
        //     }),
        //     async onQueryStarted(args, { queryFulfilled }) {
        //         try {
        //             await queryFulfilled;
        //         } catch (error) {
        //             toast.error('Не смогли получить тесты');
        //         }
        //     },
        //     transformResponse: (result: { tests: TestWithQuestions[] })=>{
        //         return result.tests;
        //     },


        //     providesTags: (result,error,arg) =>
        //         result? [ ...result.map(test => ( { type: 'Tests' as const, id:test.test.uuid } )) ]: [{ type: 'Tests', id:arg }]

        // }),

        addTestQuestions: build.mutation<void, TestAddQuestionsRequest>({
            query(data){
                return {
                    url: 'test/questions',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Тест обновлен',
                    error: 'Ошибка сохранения!'
                });
            },

            invalidatesTags: (result,error,arg) => {
                return [ { type:'Tests', id:arg.testUuid } ];
            }
        }),
        deleteTestQuestions: build.mutation<void, TestDeleteQuestionsRequest>({
            query(data){
                return {
                    url: 'test/questions/delete',
                    method: 'POST',
                    body: {
                        uuids: data.questionIds
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Вопросы удалены',
                    error: 'Ошибка удаления!'
                });
            },
            invalidatesTags: (result,error,arg)=>[ { type:'TestQuestions', id:arg.testUuid } ]
        }),

        addQuestionToBank: build.mutation<TestQuestionWithAnswers, TestQuestionWithAnswers>({
            query(data) {
                return {
                    url: 'questions/bank',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Вопрос добавлен в банк',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result,error,arg)=>  [ { type: 'TestQuestions', id: arg.question.testUuid } ]
        }),
        getQuestionsFromBank: build.query<TestQuestionWithAnswers[], TestGetQuestionsFromBank>({
            query: (query) => ({
                url: 'questions/list',
                method: 'POST',
                body: {

                    uuid: query.uuid,
                    filters:query.filters
                }
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить вопросы из банка');
                }
            },
            transformResponse: (result: { questions: TestQuestionWithAnswers[] })=>{
                return result.questions;
            },
            providesTags: (result,error,arg) => [ { type: 'TestQuestions', id: arg.uuid } ]
            //  providesTags: (result,error,arg) => result?
            //     [...result.map(question => ({ type: 'TestQuestions' as const, id: question.question.uuid }))] : [ { type: 'TestQuestions', id: arg } ]
        }),

        getQuestionsFromBankForQuestion: build.query<TestQuestionWithAnswers[], TestGetQuestionsFromBankForQuestion>({
            query: (query) => ({
                url: 'questions/list',
                method: 'POST',
                body: {

                    uuid: query.uuid,
                    filters:query.filters
                }
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить вопросы из банка');
                }
            },
            transformResponse: (result: { questions: TestQuestionWithAnswers[] })=>{
                return result.questions;
            },
            providesTags: (result,error,arg) => [ { type: 'TestQuestions', id: arg.uuid },{ type: 'Tests', id: arg.questionId } ]
            //  providesTags: (result,error,arg) => result?
            //     [...result.map(question => ({ type: 'TestQuestions' as const, id: question.question.uuid }))] : [ { type: 'TestQuestions', id: arg } ]
        }),
        getStudentsTest: build.query<PassStudentTest, GetTestRequest>({
            query(body) {
                return {
                    url: 'test/student/get',
                    method: 'POST',
                    body: { ...body }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка загрузки тестирования');
                }
            },
            transformResponse: (result: PassStudentTest ) => {
                return result;
            },
            providesTags: (result,error,arg) => {
                return [ { type: 'StudentTest', id: arg.uuidAuth } ];
            }
        }),
        finishStudentsTest: build.mutation<FinishTestResponse, FinishTestRequest>({
            query: (body) => ({
                url: 'test/student/pass',
                method: 'POST',
                body: body
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось отправить результаты теста');
                }
            },
            transformResponse: (result: { grade: FinishTestResponse })=>{
                return result.grade;
            },
            invalidatesTags: ['FinishStudentTest', 'TestAttempt']
        }),
        testAttempts: build.query<TestAttemptsInfo, string>({
            query: (attemptUuid) => ({
                url: `test/student/attempts/${attemptUuid}`,
                method: 'GET'
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка загрузки данных результатов теста');
                }
            },
            transformResponse: (result: TestAttemptsInfo) => {
                return result;
            },
            providesTags: (result,error,arg) => [ { type: 'TestAttempt', id: arg }]
        }),
        blockTestEdit: build.mutation<void, string>({
            query: (testUuid) => ({
                url: 'test/block',
                method: 'POST',
                body: { uuids: [testUuid] }
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка обновления данных теста');
                }
            },
            invalidatesTags: (result,error,arg)=>  [ { type: 'Tests', id: arg } ]
        }),
        importTestQuestions: build.mutation<void, TestImportRequest>({
            query: (fileData) => ({
                url: 'test/import',
                method: 'POST',
                body: fileData
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    pending:  'Загружаем файл...',
                    success: 'Файл загружен',
                    error: 'Не удалось загрузить файл'
                });
            },
            invalidatesTags: ['TestQuestions']
        }),
        deleteAttempt: build.mutation<void, DeleteAttemptParams>({
            query: (params) => ({
                url: 'snapshots/delete',
                method: 'POST',
                body: params
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка удаления попытки');
                }
            },
            invalidatesTags: ['GradeScores']
        }),
        getStudentsTestWithSnapshot: build.query<PassStudentTestWithSnapshot, GetTestRequest>({
            query(body) {
                return {
                    url: 'test/student/get/answers',
                    method: 'POST',
                    body: { ...body }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка загрузки тестирования');
                }
            },
            transformResponse: (result: PassStudentTestWithSnapshot ) => {
                return result;
            },
            providesTags: (result,error,arg) => {
                return [ { type: 'StudentTest', id: arg.uuidAuth } ];
            }
        }),
        sendStudentsTestSnapshot: build.mutation<void, StudentsTestSnapshotRequest>({
            query: (body) => ({
                url: 'test/student/snap/add',
                method: 'POST',
                body: body
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось отправить результаты теста');
                }
            },
            invalidatesTags: ['FinishStudentTest']
        }),
        exportQuestionsDocx: build.query<ExportedScoreCard, string>({
            query: (uuidTest: string) => ({
                url: 'test/export/doc',
                method: 'POST',
                body: { uuidTest }
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось экспортировать вопросы в docx');
                }
            }
        }),
        exportQuestionsTxt: build.query<ExportedScoreCard, string>({
            query: (uuidTest: string) => ({
                url: 'test/export/gift',
                method: 'POST',
                body: { uuidTest }
            }),
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось экспортировать вопросы в txt');
                }
            }
        })
    })
});

export const {
    useCreateTestMutation,
    // useDeleteTestMutation,
    // useGetTestsBySectionQuery,
    useAddTestQuestionsMutation,
    useAddQuestionToBankMutation,
    useGetQuestionsFromBankQuery,
    useGetTestByIdQuery,
    useUpdateTestMutation,
    useSetTestRandomQuestionsMutation,
    useDeleteTestQuestionsMutation,
    useGetQuestionsFromBankForQuestionQuery,
    useGetStudentsTestQuery,
    useFinishStudentsTestMutation,
    useTestAttemptsQuery,
    useBlockTestEditMutation,
    useImportTestQuestionsMutation,
    useDeleteAttemptMutation,
    useGetStudentsTestWithSnapshotQuery,
    useSendStudentsTestSnapshotMutation,
    useExportQuestionsDocxQuery,
    useExportQuestionsTxtQuery
} = testsApi;
