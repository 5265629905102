import React, { FC } from 'react';

import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

import Logo from '@components/Logo';

import style from './login_layout.module.scss';

// const jivoKey = process.env.REACT_APP_JIVO_KEY;

const LoginLayout: FC = () => {
    return (
        <div className={style.wrapper}>
            <Helmet>
                <title>Авторизация | ВЕИП</title>
                {/*{*/}
                {/*    jivoKey ?  <script src={`//code.jivo.ru/widget/${jivoKey}`} async></script> : null*/}
                {/*}*/}
            </Helmet>
            <div className={style.form_wrapper}>
                <header className={style.header}>
                    <Logo className={style.logo} isLarge />
                </header>
                <div className={style.form}>
                    <Outlet />
                </div>
                <footer className={style.footer}>
                    <h2 className={style.footer__title}>Поддержка</h2>
                    <p className={style.footer__schedule}>Время работы по будням с 10:00 до 18:00 (МСК)</p>
                    <div className={style.contacts}>
                        <div className={style.contacts__block}>
                            <span className={style.contacts__label}>Электронная почта</span>
                            <a className={style.contacts__link} href="mailto:support@eeip.ru">
                                support@eeip.ru
                            </a>
                        </div>
                        <div className={style.contacts__block}>
                            <span className={style.contacts__label}>Телефон</span>
                            <a className={style.contacts__link} href="tel:8 800 333 37 76">
                                8 800 333 37 76
                            </a>
                        </div>
                    </div>
                </footer>
            </div>
            <div className={style.background_wrapper} />
        </div>
    );
};

export default LoginLayout;
