import { toast } from 'react-toastify';

import {
    UsersGradesResponse,
    ReassignFakeGrade,
    ReassignGrade,
    ReassignTotalGrade,
    ScoresSearchRequest,
    AttemptResult,
    GetTestResultRequest,
    GradesSheetResponse,
    GradesSheetRequest,
    GradesSheetPDFRequest,
    StartTaskRequest
} from '@type/grades';
import { RetakeRequest, IRetakes }from '@type/retakes';

import {
    CheckedTasksForTeacher,
    CompletedChapterResponse,
    CreateEntryCompletedChapterData, DeleteGradesRequest,
    GetCheckedTasksForTeacherRequest,
    GetCompletedChaptersData,
    GetGradesRequest,
    GetUncheckedGradesInTaskForTeacherRequest,
    GetUncheckedTasksForTeacherRequest,
    Grade,
    GradeTeacher,
    GradeTeacherSubmit,
    GradeTeacherSubmitResponse,
    GradesCountsForTeacher,
    IGradeCreateRequest,
    IStorageFile,
    UncheckedGradesInTaskForTeacher,
    UncheckedTasksForTeacher
} from './apiTypes';
import { emptyBaseApi } from './emptyBaseApi';

const ANTIPLAGIARISM_PING_ERROR_CODE = 15;

export const gradesApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getCompletedChaptersList: build.query<CompletedChapterResponse[], GetCompletedChaptersData>({
            query(data) {
                return {
                    url: 'chapters/get',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Ошибка получения пройденных глав!'
                });
            },
            providesTags: (result, error, arg) => [{ type: 'ChaptersProgress', id: arg.uuidBlock }]
        }),
        createEntryCompletedChapter: build.mutation<CompletedChapterResponse, CreateEntryCompletedChapterData>({
            query(data) {
                return {
                    url: 'chapters/create',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Ошибка получения пройденных глав!'
                });
            },
            invalidatesTags: (result, error, arg) => [{ type: 'ChaptersProgress', id: arg.uuidBlock }, 'GroupSubs']
        }),
        getGrades: build.query<Grade[], GetGradesRequest>({
            query(data) {
                return {
                    url: 'grades/get',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения оценок');
                }
            },
            transformResponse: (result: { grades: Grade[] }) => {
                return result.grades;
            },
            providesTags: (result, error, arg) => [{ type: 'Grades', id: `Grade_${arg.uuidsDiscipline}_${arg.uuidsMaterial}` }, 'TestAttempt']
        }),
        deleteGrades: build.mutation<void, DeleteGradesRequest>({
            query(data) {
                return {
                    url: 'grades/delete',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Ошибка удаления попытки',
                    success: 'Попытка удалена'
                });
            },
            invalidatesTags: ['Grades', 'TestAttempt']
        }),

        getGradesTeacher: build.query<GradeTeacher[], Omit<GetGradesRequest, 'uuidsGroup'>>({
            query(data) {
                return {
                    url: 'grades/get/entities',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения оценок');
                }
            },
            transformResponse: (result: { grades: GradeTeacher[] }) => {
                return result.grades;
            },
            providesTags: ['TeacherGrades']
        }),

        getGradesCountsForTeacher: build.query<GradesCountsForTeacher, void>({
            query() {
                return {
                    url: 'grades/count/teacher',
                    method: 'GET'
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения счетчиков работ');
                }
            },
            providesTags: ['TeacherGrades']
        }),

        getCheckedTasksForTeacher: build.query<CheckedTasksForTeacher, GetCheckedTasksForTeacherRequest>({
            query(data) {
                return {
                    url: 'grades/checked',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения проверенных работ преподавателем');
                }
            },
            providesTags: ['TeacherGrades']
        }),

        getUncheckedTasksForTeacher: build.query<UncheckedTasksForTeacher, GetUncheckedTasksForTeacherRequest>({
            query(data) {
                return {
                    url: 'grades/unchecked',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения непроверенных работ преподавателем');
                }
            },
            providesTags: ['TeacherGrades']
        }),

        getUncheckedGradesInTaskForTeacher: build.query<UncheckedGradesInTaskForTeacher, GetUncheckedGradesInTaskForTeacherRequest>({
            query(data) {
                return {
                    url: 'grades/unchecked/task',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения непроверенных работ преподавателем');
                }
            },
            providesTags: ['TeacherGrades']
        }),

        getExpiringTasksForTeacher: build.query<UncheckedTasksForTeacher, GetUncheckedTasksForTeacherRequest>({
            query(data) {
                return {
                    url: 'grades/expiring',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения списка работ с итекающим сроком ');
                }
            },
            providesTags: ['TeacherGrades']
        }),

        getExpiringGradesInTaskForTeacher: build.query<UncheckedGradesInTaskForTeacher, GetUncheckedGradesInTaskForTeacherRequest>({
            query(data) {
                return {
                    url: 'grades/expiring/task',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения работ с итекающим сроком ');
                }
            },
            providesTags: ['TeacherGrades']
        }),


        getExpiredTasksForTeacher: build.query<UncheckedTasksForTeacher, GetUncheckedTasksForTeacherRequest>({
            query(data) {
                return {
                    url: 'grades/expired',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения списка просроченных работ');
                }
            },
            providesTags: ['TeacherGrades']
        }),

        getExpiredGradesInTaskForTeacher: build.query<UncheckedGradesInTaskForTeacher, GetUncheckedGradesInTaskForTeacherRequest>({
            query(data) {
                return {
                    url: 'grades/expired/task',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения просроченных работ');
                }
            },
            providesTags: ['TeacherGrades']
        }),


        submitGrades: build.mutation<GradeTeacherSubmitResponse, GradeTeacherSubmit>({
            query(data) {
                return {
                    url: 'grades/submit',
                    method: 'POST',
                    body: {
                        uuid: data.uuid,
                        grade: data.grade,
                        review: data.review,
                        retakeAvailable: data.retakeAvailable,
                        uuidTeacher: data.uuidTeacher
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: args?.type === 'retake' ? 'Ошибка предоставления попытки' : 'Ошибка отправки ответа',
                    success: args?.type === 'retake' ? 'Попытка предоставлена' : 'Оценка отправлена'
                });
            },
            invalidatesTags: ['TeacherGrades', 'TaskRetakes']
        }),
        requestRetake: build.query<IRetakes[], RetakeRequest>({
            query(params) {
                return {
                    url: 'grades/retakes',
                    method: 'POST',
                    body: params
                };
            },
            transformResponse: (result: { retakes: IRetakes[] }) => {
                return result.retakes;
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Ошибка получение количества попыток'
                });
            },
            providesTags:  (result, error, arg) => [{ type: 'TaskRetakes', id: `TaskRetakes_${arg.uuidMaterial}` }]
        }),
        submitTaskToTeacher: build.mutation<Grade, IGradeCreateRequest>({
            query(data) {
                return {
                    url: 'grades/create/task',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    pending: 'Отправка контрольной работы',
                    error: {
                        render({ data }) {
                            if (data?.error?.data?.code === ANTIPLAGIARISM_PING_ERROR_CODE) {
                                return 'Дорогой пользователь, сервис антиплагиат временно недоступен, попробуй позднее';
                            } else {
                                return 'Ошибка отправки работы на проверку';
                            }
                        }
                    },
                    success: 'Контрольная работа отправленна на проверку'
                });
            },
            invalidatesTags: (result, error, arg) => [
                'TeacherGrades',
                { type: 'Grades', id: `Grade_${arg.uuidDiscipline}_${arg.uuidMaterial}` },
                { type: 'TaskRetakes', id: `TaskRetakes_${arg.uuidMaterial}` }
            ]
        }),
        getGradesSheet: build.query<GradesSheetResponse, GradesSheetRequest>({
            query(data) {
                return {
                    url: 'grades/sheet',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Нет пользователей'
                });
            }
        }),
        getGradesSheetPDF: build.query<IStorageFile, GradesSheetPDFRequest>({
            query(data) {
                return {
                    url: 'grades/sheet/pdf',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Ошибка получения PDF Ведомости'
                });
            }
        }),
        getDisciplineGrades: build.query<UsersGradesResponse, ScoresSearchRequest>({
            query(data) {
                return {
                    url: 'grades/discipline',
                    method: 'POST',
                    body: data
                };
            },
            providesTags: ['GradeScores']
        }),
        reassignGrade: build.mutation<Record<string, never>, ReassignGrade>({
            query(data: ReassignGrade) {
                return {
                    url: 'grades/reassign',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Неудалось установить оценку',
                    success: 'Оценка установлена'
                });
            },
            invalidatesTags: ['GradeScores']
        }),
        reassignTotalGrade: build.mutation<Response, ReassignTotalGrade>({
            query(data: ReassignTotalGrade) {
                return {
                    url: 'reassignments',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Неудалось установить оценку',
                    success: 'Оценка установлена'
                });
            },
            invalidatesTags: ['GradeScores']
        }),
        reassignNotPassedGrade: build.mutation<Response, ReassignFakeGrade>({
            query(data: ReassignTotalGrade) {
                return {
                    url: 'grades/reassign/not-passed',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Неудалось установить оценку',
                    success: 'Оценка установлена'
                });
            },
            invalidatesTags: ['GradeScores']
        }),
        getDisciplineExportFile: build.mutation<IStorageFile, ScoresSearchRequest>({
            query(data) {
                return {
                    url: 'grades/discipline/xls',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Не удалось экспортировать'
                });
            }
        }),
        getTestResult: build.query<AttemptResult[], GetTestResultRequest>({
            query(data) {
                return {
                    url: 'grades/test/view',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Не удалось загрузить результаты теста'
                });
            },
            transformResponse: (result: { attempts: AttemptResult[] }) => {
                return result.attempts;
            },
            providesTags: ['GradeScores']
        }),
        startTaskWork: build.mutation<any, StartTaskRequest>({
            query(data) {
                return {
                    url: 'grades/statistics/start',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Не удалось начать проверку работы'
                });
            }
        }),
        stopTaskWork: build.mutation<any, StartTaskRequest>({
            query(data) {
                return {
                    url: 'grades/statistics/stop',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Не удалось закончить проверку работы'
                });
            }
        })

    })
});

export const {
    useGetGradesQuery,
    useGetGradesSheetQuery,
    useGetGradesSheetPDFQuery,
    useLazyGetGradesSheetPDFQuery,
    useGetGradesTeacherQuery,
    useGetGradesCountsForTeacherQuery,
    useGetCheckedTasksForTeacherQuery,
    useLazyGetCheckedTasksForTeacherQuery,
    useGetUncheckedTasksForTeacherQuery,
    useGetUncheckedGradesInTaskForTeacherQuery,
    useGetExpiredTasksForTeacherQuery,
    useGetExpiredGradesInTaskForTeacherQuery,
    useGetExpiringTasksForTeacherQuery,
    useGetExpiringGradesInTaskForTeacherQuery,
    useLazyGetUncheckedGradesInTaskForTeacherQuery,
    useLazyGetExpiredGradesInTaskForTeacherQuery,
    useLazyGetExpiringGradesInTaskForTeacherQuery,
    useGetCompletedChaptersListQuery,
    useCreateEntryCompletedChapterMutation,
    useSubmitGradesMutation,
    useSubmitTaskToTeacherMutation,
    useRequestRetakeQuery,
    useGetDisciplineGradesQuery,
    useReassignGradeMutation,
    useReassignTotalGradeMutation,
    useReassignNotPassedGradeMutation,
    useGetDisciplineExportFileMutation,
    useGetTestResultQuery,
    useDeleteGradesMutation,
    useStartTaskWorkMutation,
    useStopTaskWorkMutation
} = gradesApi;
