import React, { useState } from 'react';

import clsx from 'clsx';

import { ReactComponent as CrossSVG } from '@assets/icons/cross24.svg';
import Loader from '@components/core/Loader';
import { NewLoader } from '@components/core/NewLoader';
import DocViewer, { DocViewerRenderers, IHeaderOverride } from '@cyntler/react-doc-viewer';

import style from './DocumentPreview.module.scss';


interface DrawerProps {
    file: any;
    toggle: (arg: boolean) => void
}

const MyHeader: IHeaderOverride = (state, previousDocument, nextDocument) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
        return null;
    }

    return (
        <>
            <div>{state.currentDocument.uri || ''}</div>
            <div>
                <button onClick={previousDocument} disabled={state.currentFileNo === 0}>
            Previous Document
                </button>
                <button
                    onClick={nextDocument}
                    disabled={state.currentFileNo >= state.documents.length - 1}
                >
            Next Document
                </button>
            </div>
        </>
    );
};

const MyNoRenderer = () => <div>No Renderer Error!</div>;
const MyLoadingRenderer = () => <div><Loader /></div>;

export const DocumentPreview: React.FC<DrawerProps> = ({ file, toggle }) => {
    console.log('🚀 ~ file: DocumentPreview.tsx:15 ~ file:', file);

    const headers = {
        'Access-Control-Allow-Origin': '*'
    };

    const onClose = () => {
        toggle(false);
    };


    return (
        <> 
            <div className={style.container}>
                <div className={style.document}> 
                    <div className={style.cross} onClick={onClose}>
                        <CrossSVG />
                    </div>
                    <DocViewer 
                        documents={[{ uri: file.path , fileType: file.extension }]} 
                        className={style.DocViewer}
                        pluginRenderers={DocViewerRenderers}
                        config={{ 
                            header: { disableHeader: true },
                            noRenderer: {
                                overrideComponent: MyNoRenderer
                            },
                            loadingRenderer: {
                                overrideComponent: MyLoadingRenderer,
                                showLoadingTimeout: false // false if you want to disable or number to provide your own value (ms)
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
};
