import { FC, useState, useRef, useEffect, KeyboardEvent } from 'react';

import { TextareaMarkdownRef } from 'textarea-markdown-editor';

import { useChat } from '@components/Chat/context';
import { MarkdownTextArea } from '@components/MarkdownTextArea';
import { resetRoomServices, saveDraftMessage, selectCurrentServiceInformation } from '@store/features/chatSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { useMessages } from '../Messages/context';
import style from './ChatInput.module.scss';
import FileOpenButton from './FileOpenButton';
import SendButton from './SendButton';

const ChatInput:FC = () => {
    const dispatch = useAppDispatch();

    const { submitTextMessage } = useMessages();

    const markdownRef = useRef<TextareaMarkdownRef>(null);

    const { roomId } = useChat();
    const serviceInformationOfRoom = useAppSelector((state) => selectCurrentServiceInformation(state, roomId));

    const [textValue, setTextValue] = useState('');
    const textValueRef = useRef('');

    const handleSubmit = () => {
        const trimMessage = textValue.trim();
        if (trimMessage.length > 0) {
            submitTextMessage(textValue);
        }
        setTextValue('');
    };

    const handleDownButton = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleSubmit();
            event.preventDefault();
            return;
        }
    };

    useEffect(() => {
        textValueRef.current = textValue;
    }, [textValue]);

    useEffect(() => {
        setTextValue('');
        markdownRef.current?.focus();

        if (serviceInformationOfRoom?.draftMessage) {
            setTextValue(serviceInformationOfRoom.draftMessage);
        }

        dispatch(resetRoomServices(roomId ?? ''));

        return () => {
            dispatch(saveDraftMessage({ roomId: roomId ?? '', message: textValueRef.current }));
        };
    }, [roomId]);

    return (
        <div className={style.container}>
            <FileOpenButton typeIcon="chat-clip" />

            <div className={style['textArea-container']}>
                <MarkdownTextArea
                    ref={markdownRef}
                    value={textValue}
                    onChange={setTextValue}
                    placeholder="Введите текст сообщения"
                    onKeyDown={handleDownButton}
                />
            </div>


            {textValue && <SendButton isShow={true} onClick={handleSubmit} />}
        </div>
    );
};

export default ChatInput;
