import React, { ButtonHTMLAttributes, FC, useContext } from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import { CloseContext } from '../Menu';
import style from './MenuItem.module.scss';

export interface IMenuItemProps extends ButtonHTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    danger?: boolean;
    to?: string;
    linkProps?: any;
}

export const MenuItem: FC<IMenuItemProps> = ({
    children,
    danger,
    to,
    className,
    linkProps,
    ...props
}) => {

    const closeContext = useContext(CloseContext);

    const closeMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();

        props?.onClick?.(e);
        closeContext();
    };

    return <div className={cx(style.container, danger && style.danger, className)} {...props}>
        {
            to ?
                <Link className={style.inner} to={to} {...linkProps}>{children}</Link>
                :
                <div onClick={closeMenu} className={style.inner}>{children}</div>
        }
    </div>;
};
