import { toast } from 'react-toastify';

import { CreateCourseFormData } from '@views/methodist/Materials/Course/CreateCourse/CreateCourseForm/type';

import { GetDisciplinePagination, IDiscipline, IDisciplinesAll, IDisciplineStatus, UpdateCourseCategory, UpdateCourseData } from './apiTypes';
import { emptyBaseApi } from './emptyBaseApi';


export const disciplinesApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getDisciplinesAll: build.query<IDiscipline[], IDisciplinesAll>({
            query(data) {
                return {
                    url: 'disciplines/disciplines/all',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Неудалось получить все дисциплины');
                }
            },
            transformResponse: (result: { disciplines: IDiscipline[] }) => {
                return result.disciplines;
            }
        }),
        getDisciplinesByCategory: build.query<IDiscipline[], string>({
            query: (uuidCategory: string) => `disciplines/disciplines/list/${uuidCategory}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список дисциплин');
                }
            },
            transformResponse: (result: { disciplines: IDiscipline[] }) => {
                return result.disciplines;
            },
            providesTags: (result, error, arg) => result
                ? [...result.map(({ uuid }) => ({ type: 'Disciplines' as const, id: uuid })), 'Disciplines']
                : ['Disciplines']
        }),
        getDisciplinesById: build.query<IDiscipline[], string[]>({
            query(data) {
                return {
                    url: 'disciplines/disciplines/find',
                    method: 'POST',
                    body: { UUID: data }
                };
            },
            async onQueryStarted(_args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить дисциплины');
                }
            },
            transformResponse: (result: {disciplines: IDiscipline[]}) => result.disciplines,
            providesTags: (result) =>
                result
                    ? [...result.map(({ uuid }) => ({ type: 'Disciplines' as const, uuid })), 'Disciplines']
                    : ['Disciplines']
        }),
        getDisciplineById: build.query<IDiscipline, string>({
            query: (courseId: string) => `disciplines/disciplines/find/${courseId}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить дисциплину');
                }
            },
            providesTags: (result, error, arg) => result
                ? [{ type: 'Disciplines' as const, id: result.uuid }]
                :
                ['Disciplines']
        }), getDisciplineWithGradesById: build.query<IDiscipline, string[]>({
            query(uuid) {
                return {
                    url: 'disciplines/disciplines/find/cards',
                    method: 'POST',
                    body: {
                        UUID: [...uuid]
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить дисциплину');
                }
            },
            transformResponse: (result: { disciplines: IDiscipline[] }) => {
                return result.disciplines[0];
            },
            providesTags: (result, error, arg) => result
                ? [{ type: 'Disciplines' as const, id: result.uuid }]
                : ['Disciplines']
        }),
        getDisciplinesTeacher: build.query<IDiscipline[], void>({
            query: () => 'disciplines/disciplines/teacher',
            transformResponse: (result: { disciplines: IDiscipline[] }) => {
                return result.disciplines;
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список дисциплин');
                }
            }
        }),
        createDiscipline: build.mutation<IDiscipline, CreateCourseFormData>({
            query(data) {
                return {
                    url: 'disciplines/disciplines/create',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Дисциплина создана',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Disciplines']
        }),
        updateDiscipline: build.mutation<IDiscipline, UpdateCourseData>({
            query(data) {
                return {
                    url: 'disciplines/disciplines/update',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Дисциплина обновлена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Disciplines']
        }),
        deleteDiscipline: build.mutation<string, string>({
            query(uuid) {
                return {
                    url: 'disciplines/disciplines/delete',
                    method: 'POST',
                    body: {
                        uuids: [
                            uuid
                        ]
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Дисциплина удалена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Disciplines', 'SkillDisciplines']
        }),
        changeDisciplineCategory: build.mutation<IDiscipline, UpdateCourseCategory>({
            query(data) {
                return {
                    url: 'disciplines/disciplines/update/parent',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Категория изменена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Disciplines']
        }),
        getDisciplinePagination: build.query<IDiscipline[], GetDisciplinePagination>({
            query(data) {
                return {
                    url: 'disciplines/disciplines/all',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось загрузить дисциплины');
                }
            },
            transformResponse: (result: { disciplines: IDiscipline[] }) => {
                return result.disciplines;
            },
            providesTags: ['Disciplines']
        }),
        updateDisciplineStatus: build.mutation<void, IDisciplineStatus>({
            query(data) {
                return {
                    url: 'disciplines/disciplines/update/status',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Статус обновлен',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['Disciplines']
        }),
        getDisciplinesByMethodist: build.query<IDiscipline[], string>({
            query(uuid: string) {
                return {
                    url: 'disciplines/disciplines/methodist',
                    method: 'POST',
                    body: { uuid }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось получить дисциплины методиста');
                }
            },
            transformResponse: (result: { disciplines: IDiscipline[] }) => {
                return result.disciplines;
            },
            providesTags: (result, error, arg) => [{ type: 'Disciplines', id: `methodist=${arg}` }]
        })
    })

});


export const {
    useGetDisciplinesAllQuery,
    useGetDisciplinesByCategoryQuery,
    useCreateDisciplineMutation,
    useUpdateDisciplineMutation,
    useGetDisciplineByIdQuery,
    useGetDisciplinesByIdQuery,
    useLazyGetDisciplinesByIdQuery,
    useDeleteDisciplineMutation,
    useChangeDisciplineCategoryMutation,
    useGetDisciplinePaginationQuery,
    useGetDisciplinesTeacherQuery,
    useGetDisciplineWithGradesByIdQuery,
    useUpdateDisciplineStatusMutation,
    useGetDisciplinesByMethodistQuery
} = disciplinesApi;
