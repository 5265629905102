import { useParams } from 'react-router-dom';

import { NewLoader } from '@components/core/NewLoader';
import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import Main from '@components/Main';
import GoBackLink from '@components/student/GoBackLink';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { useGetPageByIdQuery } from '@store/api/pageApi';

import style from './PagePreview.module.scss';

const PagePreview = () => {
    const { uuidPage, uuidDiscipline } = useParams();
    const { data: disciplineData } = useGetDisciplineByIdQuery(uuidDiscipline || skipToken);
    const { data: pageData, isLoading: isLoadingPage, isError: isGetError, isFetching } = useGetPageByIdQuery(uuidPage ? uuidPage : skipToken);

    if (isFetching) return <NewLoader />;

    return (
        <Main className={style.main} title={pageData && disciplineData && `Обучение – ${disciplineData.name}: ${pageData.name}`}>
            <GoBackLink to='./../..'>
                Назад
            </GoBackLink>
            <h1 className={style.main_title}>{pageData?.name}</h1>
            <CSVPreview content={pageData?.content} />
        </Main>
    );
};

export default PagePreview;
