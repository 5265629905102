import { FC } from 'react';

import cx from 'classnames';

import { dateConvert } from '@helpers/date';

import style from './DateFormat.module.scss';

export interface IDateFormatProps extends React.HTMLAttributes<HTMLDivElement> {
    time: string;
    theme?: 'own' | 'photo' | 'default';
}

const DateFormat:FC<IDateFormatProps> = ({
    time,
    theme,
    ...props
}) => {

    const timeClass = cx(
        style.time,
        theme === 'default' && style.default,
        theme === 'own' && style.own,
        theme === 'photo' && style.photoOwn,
        props.className
    );

    const formatedDate = dateConvert(time.replace(/ \+0000 UTC/, '')).toFormat('HH:mm');

    return <div className={timeClass} {...props}>{formatedDate}</div>;
};

export default DateFormat;
