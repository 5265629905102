import { ReactComponent as FirstCompetence } from '@assets/image/firstCompetence.svg';
import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import SwitchFade from '@components/SwitchFade';
import { useToggle } from '@hooks/useToggle.hook';
import { useGetSkillDisciplinesQuery } from '@store/api/skillsApi';

import AddSkillDisciplineModal from './AddSkillDisciplineModal';
import DisciplinesTable from './DisciplinesTable';
import styles from './SkillsDisciplinesSettings.module.scss';

const SkillsDisciplinesSettings = () => {

    const [isOpen, { on:openModal, off:closeModal }] = useToggle(false);
    const { data, isLoading  } = useGetSkillDisciplinesQuery(undefined);

    return (
        <>
            <h1 className={styles.header}>Плановые дисциплины</h1>
            <SwitchFade
                className={styles.fullWidth}
                slotIndex={isLoading ? 0 : data?.length ? 1 : 2}
                slots={[
                    <NewLoader key={0} />
                    ,
                    <div className={styles.grid} key={1}>
                        <Button typeIcon='add' className={styles.button} onClick={openModal}>Добавить плановую дисциплину</Button>
                        <DisciplinesTable disciplines={data!} />
                    </div>
                    ,
                    <div className={styles.empty} key={2}>
                        <FirstCompetence />
                        <p className={styles.text}>Создайте свою первую плановую дисциплину</p>
                        <Button typeIcon='add' className={styles.button} onClick={openModal}>Добавить плановую дисциплину</Button>
                    </div>
                ]}
            />           
            <AddSkillDisciplineModal isOpen={isOpen} closeModal={closeModal} />
        </>
    );
};

export default SkillsDisciplinesSettings;
