import React, { useMemo } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import NewTreeSelectControl, { transformDataToOptions } from '@components/core/inputs/NewTreeSelectControl';
import Modal from '@components/Modal';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { ICategory } from '@store/api/apiTypes';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import { ISelectMaterialCategoryForm } from '@views/methodist/Materials/MaterialsPage/MaterialsList/materialsList';


import SelectStyled from '../../core/inputs/SelectStyled/SelectStyled';
import style from './ChangeCategoryModal.module.scss';


interface ChangeCategoryModalProps {
    isOpen: boolean;
    onClose: () => void;
    onChange: ({ uuid, uuidCategory }: {uuid: string; uuidCategory: string}) => void;
    currentMaterialId: string
}

const EMPTY_CATEGORIES: ICategory[] = [];

const schema = yup.object({
    select: yup.string().required('')
});

interface CategoriesOptions {
    label: string;
    value: string
}

const getFlat = (categories: ICategory[]): CategoriesOptions[] => categories.map(cat => [{ label: cat.name, value: cat.uuid }, ...getFlat(cat.children)]).flat();

interface FormData {
    select: string
}

export const ChangeCategoryModal: React.FC<ChangeCategoryModalProps> = ({
    isOpen,
    onClose,
    onChange,
    currentMaterialId
}) => {
    const { categories } = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000', {
        selectFromResult: ({ data: categoriesData }) => ({
            categories: categoriesData ?? EMPTY_CATEGORIES
        })
    });

    const categoriesOptions = useMemo(() => transformDataToOptions(categories, {
        value: 'uuid',
        label: 'name',
        children: 'children'
    }), [categories]);

    const methods = useForm<FormData>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            select: ''
        }
    });

    const { handleSubmit, reset } = methods;

    const onSubmit = async (formData: FormData) => {
        onChange({ uuid: currentMaterialId, uuidCategory: formData.select });
        reset();
    };

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title={'Сменить категорию'}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormProvider {...methods}>
                    <p className={style.label}>Категория</p>
                    <NewTreeSelectControl
                        className={style.select}
                        name="select"
                        options={categoriesOptions}
                        placeholder="Выберите категорию"
                    />
                    <div className={style.buttons_wrapper}>
                        <Button
                            onClick={onClose}
                            btnType="secondary"
                        >
                            Отмена
                        </Button>
                        <Button
                            type="submit"
                        >
                            Сохранить
                        </Button>
                    </div>
                </FormProvider>
            </form>
        </Modal>
    );
};
