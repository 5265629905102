import React, { useEffect, useState } from 'react';

import Checkbox from '@components/core/inputs/Checkbox';
import { DPControl } from '@components/DatePicker/DPControl';
import { setDeadline, setDeadlinePresense } from '@store/features/deadlinesSlice';
import { useAppDispatch } from '@store/hooks';

import style from './TermsCalendarPage.module.scss';



export const TermsCalendarPage = () => {
    const dispatch = useAppDispatch();

    const [NoStartDate, setNoStartDate] = useState(false);
    const [NoEndDate, setNoEndDate] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        const deadlines = {
            deadlineStart: '',
            deadlineEnd: ''
        };

        if (!NoStartDate && startDate) {
            deadlines.deadlineStart = startDate;
        }

        if (!NoEndDate && endDate) {
            deadlines.deadlineEnd = endDate;
        }

        dispatch(setDeadline(deadlines));
        dispatch(setDeadlinePresense({ noStartDate: NoStartDate, noEndDate: NoEndDate }));
    }, [NoStartDate, NoEndDate, startDate, endDate]);

    return <div className={style.container}>
        <div className={style.column}>
            <div className={style.label}>Дата начала</div>

            <DPControl
                onSubmit={(date: string) => setStartDate(date)}
                variant='date'
                style={{ width: '100%', height: 42 }}
                isButton={false}
                disabled={NoStartDate} />

            <div className={style.checkbox}>
                <Checkbox
                    label='Без даты начала'
                    checked={NoStartDate}
                    onChange={() => setNoStartDate(!NoStartDate)}  />
            </div>
        </div>

        <div className={style.column}>
            <div className={style.label}>Дата окончания</div>

            <DPControl
                onSubmit={(date: string) => setEndDate(date)}
                variant='date'
                isButton={false}
                style={{ width: '100%', height: 42 }}
                disabled={NoEndDate} />

            <div className={style.checkbox}>
                <Checkbox
                    label='Без даты окончания'
                    checked={NoEndDate}
                    onChange={() => setNoEndDate(!NoEndDate)}  />
            </div>
        </div>
    </div>;
};
