import React, { useState } from 'react';

import Button from '@components/core/inputs/Button';
import Loader from '@components/core/Loader';
import { groupsApi } from '@store/api/groupsApi';

import style from './ExportScorecard.module.scss';


interface ExportScorecardProps {
    studentId?: string
}

export const ExportScorecard: React.FC<ExportScorecardProps> = ({ studentId }) => {
    const isStudent = !studentId;

    const [isLoading, setIsLoading] = useState(false);

    const [trigger] = groupsApi.endpoints.exportScoreCard.useLazyQuerySubscription();

    if (isStudent) return null;

    const exportScorecard = async () => {
        setIsLoading(true);
        const result = await trigger(studentId ? { studentUUID: studentId } : undefined);
        if (result.data) {
            window.open(result.data.path);
        }
        setIsLoading(false);
    };

    return (
        <Button
            className={style.button}
            onClick={exportScorecard}
            btnType={isLoading ? 'white' : 'success'}
            disabled={isLoading}
        >
            {isLoading ? <Loader /> : 'Экспорт в .pdf'}
        </Button>
    );
};
