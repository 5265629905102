import { Dispatch, RefObject, SetStateAction } from 'react';
import { FC } from 'react';

import { DateTime } from 'ts-luxon';

import EventList from '@components/core/Calendar/EventList';
import { InfoEvent, SelectDayData } from '@type/eventCalendar';

import style from './CalendarEvents.module.scss';

export interface ICalendarEventsProps {
    today: DateTime;
    infoEvent: InfoEvent;
    eventsRef: RefObject<HTMLDivElement>;
    selectDayData: SelectDayData | null;

    setInfoEvent: Dispatch<SetStateAction<InfoEvent>>;
}

export const CalendarEvents:FC<ICalendarEventsProps> = ({
    eventsRef,
    selectDayData,
    infoEvent,
    setInfoEvent,
    today
}) => {
    return <div className={style['events-container']} ref={eventsRef}>
        <h3 className={style.events_heading}>{selectDayData?.dayItem.toFormat('d MMMM')}</h3>
        {
            selectDayData?.events.length ?
                <EventList
                    events={selectDayData.events}
                    dayItem={selectDayData.dayItem}
                    infoEvent={infoEvent}
                    setInfoEvent={setInfoEvent}
                    today={today}
                />
                :
                <div className={style.events_caption}>
                    Нет запланированных мероприятий
                </div>
        }
    </div>;
};