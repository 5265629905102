import React, { FC, useCallback, useEffect } from 'react';

import cx from 'classnames';

import { ReactComponent as BucketSVG } from '@assets/icons/bucket-edit.svg';
import { Column } from '@components/core/grid';
import Loader from '@components/core/Loader';
import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import { DataList, DataListBody, DataListCell, DataListHeader } from '@components/DataList';
import { DataListMassEdit } from '@components/DataList/DataListMassEdit';
import { DataListRow } from '@components/DataList/DataListRow';
import { IDataListRowProps } from '@components/DataList/DataListRow/DataListRow';
import { IExtendedCheckboxData, useGroupCheckbox } from '@components/DataList/hooks/useGroupCheckbox';
import { Pagination, usePagination } from '@components/Pagination';
import { checkRole } from '@components/RoleCheck';
import { Role } from '@store/Roles';
import { TOnSelectType } from '@type/table';

import { getQuestionHumanType, TestQuestionWithName } from '../../types';
import style from './QuestionTable.module.scss';


export interface IQuestionTableProps extends React.HTMLAttributes<HTMLDivElement> {
    data: TestQuestionWithName[];
    onSelectItems?: (selectObject: TOnSelectType) => void;
    onDeleteSelected?: () => void;
    onBucketClick?: (uuid: string) => void;

    isSelectable?: boolean;
    isFetching?: boolean;
    isPagination?: boolean;
    scrolled?: boolean;

    isDisableSelectPanel?: boolean;
    gridTemplateColumns?: string;
    disableSelectPanel?: boolean;
}

export const QuestionTable:FC<IQuestionTableProps> = ({
    data,
    isFetching,
    isSelectable = false,
    isPagination = true,
    isDisableSelectPanel = false,
    scrolled,
    onSelectItems,
    onDeleteSelected,
    onBucketClick,

    gridTemplateColumns = '7fr 4fr 2fr'
}) => {

    const { dataWithChecked, selectCount, selectAllProps, checkboxProps } = useGroupCheckbox<TestQuestionWithName>({ data });
    const { handleSliceData, setTotal, paginationProps } = usePagination<IExtendedCheckboxData<TestQuestionWithName>>({});

    const rowProps: IDataListRowProps = {
        gridTemplateColumns,
        isSelect: isSelectable
    };

    const renderBucket = (item: IExtendedCheckboxData<TestQuestionWithName>) => {
        return onBucketClick && <span>
            {
                checkRole(Role.Methodist) ? (
                    <div className={style.delete_bucket} onClick={(e) => { e.stopPropagation(); onBucketClick(item.uuid);}}>
                        <BucketSVG />
                    </div>
                ) : null
            }
        </span>;
    };

    const renderBody = () => {
        return handleSliceData(dataWithChecked).map((item, index) => (
            <DataListRow
                className={style.row}
                key={`c_${index}`}
                selectProps={checkboxProps(item, index)}
                {...rowProps}>

                <Column className={style.cell}>
                    <DataListCell>
                        <CSVPreview content={item.name} className={style['csv-preview']} />
                    </DataListCell>
                </Column>

                <Column className={cx(style.cell, style.bucket)}>
                    <DataListCell>{ getQuestionHumanType(item.type) }</DataListCell>
                    {
                        renderBucket(item)
                    }
                </Column>

            </DataListRow>
        ));
    };

    const selectedItems = useCallback(() => dataWithChecked.filter(t => t.checked), [selectCount]);

    useEffect(() => {
        setTotal(data.length || 0);
    }, [data]);

    useEffect(() => {
        if (!onSelectItems) return;
        onSelectItems({ count: selectCount, selectedUuids: selectedItems().map( item => item.uuid) });
    }, [selectCount]);


    return <div className={style.container}>

        <DataList>
            <DataListHeader className={style.header}>
                <DataListRow className={style.header_row} {...rowProps} selectProps={selectAllProps} >
                    <Column className={style.header_cell}>
                        Название
                    </Column>

                    <Column className={style.header_cell}>
                        Тип
                    </Column>
                </DataListRow>
            </DataListHeader>

            <DataListBody scrolled={scrolled}>
                {
                    isFetching ? <Loader /> : renderBody()
                }
            </DataListBody>
        </DataList>

        {   isDisableSelectPanel && <DataListMassEdit
            isShow={selectCount > 0}
            selectCount={selectCount}
            onDeleteSelected={onDeleteSelected} /> }

        {   isPagination && <Pagination className={style.pagination} {...paginationProps} /> }
    </div>;
};
