import React, { FC, useRef, useState } from 'react';

import cx from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

import Button from '@components/core/inputs/Button';
import { Menu } from '@components/Menu';

import styles from './button_menu.module.scss';

export type BtnType = 'success'
| 'secondary'
| 'danger'
| 'primary'
| 'outline'
| 'clear'
| 'dark'
| 'menu'
| 'pagination'
| 'yellow';

interface ITableMenuProps {
    children?: React.ReactNode;
    className?: string;
    stopPropagation?: boolean;
    btnText?: string;
    size?: 'normal' | 'small' | 'middle';
    btnType?: BtnType;
}

const ButtonMenu: FC<ITableMenuProps> = ({
    children,
    btnText,
    className,
    stopPropagation,
    btnType,
    size
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (stopPropagation) event.stopPropagation();

        if (isOpen) {
            setAnchorEl(null);
            setIsOpen(false);
        } else {
            setAnchorEl(event.currentTarget);
            setIsOpen(true);
        }
    };

    const closeMenu = () => {
        setAnchorEl(null);
        setIsOpen(false);
    };
    
    useOnClickOutside(menuRef, closeMenu);

    return (<>
        <div ref={menuRef}>
            <Button
                btnType={btnType}
                size={size}
                className={cx(className, isOpen && styles.btnOpen)}
                typeIcon="menu"
                onClick={onClick}
                isIconPrev={false}
            >
                { btnText }
            </Button>

            <Menu className={'tbmenu'} isOpen={isOpen} anchorEl={anchorEl} onClose={closeMenu}>
                {children}
            </Menu>
        </div>

    </>
    );
};

export default ButtonMenu;
