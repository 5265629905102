import {  useEffect, useRef, useState } from 'react';

import jwt_decode from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';

import { NewLoader } from '@components/core/NewLoader';
import { vAccess, vDeviceUuid, vRefresh } from '@constants/localStorage';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { JwtUser } from '@store/api/apiTypes';
import { emptyBaseApi } from '@store/api/emptyBaseApi';
import { useGetAuthUserQuery } from '@store/api/usersApi';
import { chatBaseApi } from '@store/chatApi/chatBaseApi';
import { logIn, logOut, selectAuth } from '@store/features/authSlice';
import { setDeviceUuid } from '@store/features/authSlice';
import { resetChatSlice } from '@store/features/chatSlice';
import { setJwtUser } from '@store/features/userSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';

const CheckAuth = ({ children }: { children: JSX.Element; }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    //  Берем состояние аунтификации. По дефлту false
    const isAuth = useAppSelector(selectAuth);
    //  Устанвливаем значение загрузки
    const [loadingInitial, setLoadingInitial] = useState(true);
    //  Устанвливаем предидущее значение аунтификации
    const prevIsAuthRef = useRef(isAuth);

    let aToken: any;
    let jwtUser: any;
    let rToken: any;
    let lsDuuid: any;


    // Получаем токены Refresh and access. Декадируем инфу с аксессо токена. Получает
    // id device из стораджа
    try {
        aToken = JSON.parse(localStorage.getItem(vAccess) || 'null');
        jwtUser = aToken ? jwt_decode(aToken) as JwtUser : null;
        rToken = JSON.parse(localStorage.getItem(vRefresh) || 'null');
        lsDuuid = JSON.parse(localStorage.getItem(vDeviceUuid) || 'null');
    } catch (err) {
        console.error(err);

        dispatch(logOut());
        dispatch(emptyBaseApi.util.resetApiState());
        dispatch(chatBaseApi.util.resetApiState());
        dispatch(resetChatSlice());
    }


    // Если есть jwt токен, то делаем запрос на юзера по id.
    useGetAuthUserQuery(jwtUser ? jwtUser.Uuid : skipToken );

    // Если у нас есть все данные и пользователь не авторизован. Записываем все в стейт
    if (aToken && rToken && !isAuth && jwtUser && lsDuuid) {
        console.log('Authorizing with storage tokens',{ rToken,lsDuuid });

        dispatch(setJwtUser(jwtUser));
        dispatch(logIn({ access: aToken, refresh: rToken, duuid: lsDuuid }));
    }

    useEffect(() => {
        const handlerOnStorage = (event:StorageEvent)=>{

            if (event.key === vAccess && !event.newValue) {
                console.log('🚀 ~ file: CheckAuth.tsx:43 ~ useEffect ~ logOut:');
                dispatch(logOut());
                dispatch(emptyBaseApi.util.resetApiState());
                dispatch(chatBaseApi.util.resetApiState());
                dispatch(resetChatSlice());
            }
            if (event.key === vAccess && !event.oldValue && event.newValue) {
                console.log('🚀 ~ file: CheckAuth.tsx:50 ~ useEffect ~ reload:');
                window.location.reload();
            }
            if (event.key === vDeviceUuid && event.newValue) {
                console.log('🚀 ~ file: CheckAuth.tsx:71 ~ useEffect ~ setDeviceUuid:');
                dispatch(setDeviceUuid(JSON.parse(event.newValue)));
            }
        };

        window.addEventListener('storage', handlerOnStorage);
        return () => {
            window.removeEventListener('storage', handlerOnStorage);
        };
    }, []);


    useEffect(() => {
        console.log('🚀 ~ file: CheckAuth.tsx ~ line 84 ~ useEffect ~ isAuth', isAuth);

        // Если пользователь не авторизован и токена нет.То убираем значок загрузки и редиректим
        // на авторизацию
        if(!isAuth && !aToken) {
            setLoadingInitial(false);

            if (location.pathname !== '/reset-password' && location.pathname !== '/login') {
                navigate('/login', { replace: true, state: !prevIsAuthRef.current ? location.pathname : undefined });
            }
        }

        prevIsAuthRef.current = isAuth;
    }, [isAuth,aToken]);




    if (loadingInitial && !isAuth) {
        return <NewLoader />;
    }
    return children;

};

export default CheckAuth;
