import { GroupBase, StylesConfig } from 'react-select';

import { OptionType } from '@components/core/inputs/SelectStyled/type';

export const themeLight: StylesConfig<OptionType> = {
    control: (provided) => ({
        ...provided,
        cursor: 'pointer',
        borderColor: '#E0E0E0',
        borderRadius: '8px',
        padding: '4px',
        position: 'relative',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#A0A0A0'
        }
    }),
    placeholder: (provided, state) => {
        return {
            ...provided,
            display:
                ((!state.selectProps.isMulti &&
                    // @ts-ignore
                    state.selectProps.value?.value) ||
                    state.selectProps.inputValue) &&
                'none',
            position: 'absolute',
            fontFamily: 'inherit',
            fontWeight: 500,
            color: '#A0A0A0'
        };
    },
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    }),
    dropdownIndicator: (provided, state) => {
        const transform = state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none';
        return {
            ...provided,
            transform,
            color: '#A0A0A0'
        };
    },
    clearIndicator: (provided) => ({
        ...provided,
        display: 'none'
    }),

    menu: (provided) => ({
        ...provided,
        margin: '4px 0px 0px 0px',
        boxShadow:
            '4px 4px 11px -5px rgba(201, 201, 201, 0.25), -3px -5px 10px -4px rgba(201, 201, 201, 0.25), 0px 100px 80px rgba(0, 0, 0, 0.00660515), 0px 24.9195px 32.3695px rgba(0, 0, 0, 0.013564), 0px 7.13834px 13.1879px rgba(0, 0, 0, 0.0225568), 0px 3.01104px 2.68349px rgba(0, 0, 0, 0.04)',
        borderRadius: '0px 0px 10px 10px',
        marginTop: 0,
        overflow: 'hidden',
        zIndex: 1000
    }),

    option: (provided, state) => ({
        ...provided,
        fontFamily: 'inherit',
        background: state.isSelected ? '#E3E6E6' : 'transparent',
        color: '#000000',
        '&:hover': {
            cursor: 'pointer',
            background: '#F3F3F3'
        }
    })
};

export const themeDark: StylesConfig<OptionType> = {
    control: (provided) => ({
        ...provided,
        cursor: 'pointer',
        backgroundColor: '#555555',
        borderColor: '#555555',
        borderRadius: '6px',
        padding: '2px',
        height: 34,
        minHeight: 34,
        color: '#FFFFFF',
        position: 'relative',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#A0A0A0'
        }
    }),
    valueContainer: (provided) => {
        return {
            ...provided,
            padding: '0px 0px 0px 8px'
        };
    },
    singleValue: (provided, state) => {
        return {
            ...provided,
            color: '#FFFFFF'
        };
    },
    placeholder: (provided, state) => {
        return {
            ...provided,
            display:
                ((!state.selectProps.isMulti &&
                    // @ts-ignore
                    state.selectProps.value?.value) ||
                    state.selectProps.inputValue) &&
                'none',
            position: 'absolute',
            fontFamily: 'inherit',
            fontWeight: 500,
            color: '#A0A0A0'
        };
    },
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    }),
    dropdownIndicator: (provided, state) => {
        const transform = state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none';
        return {
            ...provided,
            transform,
            color: '#A0A0A0'
        };
    },
    clearIndicator: (provided) => ({
        ...provided,
        display: 'none'
    }),

    menu: (provided) => ({
        ...provided,
        boxShadow:
            '4px 4px 11px -5px rgba(201, 201, 201, 0.25), -3px -5px 10px -4px rgba(201, 201, 201, 0.25), 0px 100px 80px rgba(0, 0, 0, 0.00660515), 0px 24.9195px 32.3695px rgba(0, 0, 0, 0.013564), 0px 7.13834px 13.1879px rgba(0, 0, 0, 0.0225568), 0px 3.01104px 2.68349px rgba(0, 0, 0, 0.04)',
        borderRadius: '0px 0px 10px 10px',
        marginTop: 0
    }),

    option: (provided, state) => ({
        ...provided,
        fontFamily: 'inherit',
        background: state.isSelected ? '#E3E6E6' : 'transparent',
        color: '#000000',
        '&:hover': {
            cursor: 'pointer',
            background: '#F3F3F3'
        }
    })
};
