import { useState, useEffect } from 'react';

import cx from 'classnames';
import { useNavigate, useParams, Link } from 'react-router-dom';

import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import { Helmet } from '@components/Helmet';
import Modal from '@components/Modal';
import SwipeDrawer from '@components/SwipeDrawer';
import { dateConvert } from '@helpers/date';
import { useBreakpoint } from '@hooks/useBreakpoint';
import usePreventPageClose from '@hooks/usePageClose';
import { useToggle } from '@hooks/useToggle.hook';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { TestAttemptsCard, TestAttemptsInfo } from '@store/api/apiTypes';
import { testsApi, useTestAttemptsQuery } from '@store/api/testsApi';
import { removeAuthenticationAttempt } from '@store/features/authenticationSlice';
import { useAppDispatch } from '@store/hooks';
import { useTest } from '@views/student/StudentTest/StudentTest';

import Counter from './../NewTestPassing/Counter/Counter';
import StartTestModal from './StartTestModal/StartTestModal';
import style from './test_start.module.scss';

const TestStart = () => {
    const navigate = useNavigate();
    const { uuidTest } = useParams();

    const { testData, discipine } = useTest();

    const dispatch = useAppDispatch();
    const smUp = useBreakpoint('sm', 'up');
    const [isOpen, { on, off }] = useToggle();

    const [attempts, setAttempts] = useState([] as TestAttemptsCard[]);
    const [showStartTestModal, setShowStartTestModal] = useState(false);
    const [showStartTestDrawer, setShowStartTestDrawer] = useState(false);

    const { attemptInfo, isLoadingAttempt, refetch, isFetchingAttempt } = useTestAttemptsQuery(uuidTest ? uuidTest : skipToken, {
        selectFromResult: ({ data: attemptData, isLoading: isLoadingAttemptData, isFetching: isFetchingAttemptData }) => ({
            attemptInfo: attemptData || {} as TestAttemptsInfo,
            isLoadingAttempt: isLoadingAttemptData,
            isFetchingAttempt: isFetchingAttemptData
        })
    });

    // Предотвращение закрытия страницы во время отправки запроса завершения попытки
    usePreventPageClose(isFetchingAttempt);

    const onCounterTick = ({ total }: { total: number }) => {
        if (total <= 0) refetch();
    };

    const finishTest = () => {
        setTimeout(refetch, 1000);
    };

    const startTest = () => {
        localStorage.removeItem(`marks:${uuidTest}`);
        navigate('auth');
        off();
    };

    // Очистка кеша, для того чтобы при следующем вмонтировании
    // компонента получать актуальные данные для таймера

    useEffect(() => {
        return () => {
            dispatch(testsApi.util.resetApiState());
        };

    }, []);


    useEffect(() => {
        if (attemptInfo.gradesForPreviousAttempts) {
            const attemptsToSort = [...attemptInfo.gradesForPreviousAttempts];
            attemptsToSort.sort(
                (attempt1, attempt2) => {
                    const date1 = (new Date(attempt1.createdAt)).getTime();
                    const date2 = (new Date(attempt2.createdAt)).getTime();
                    return date1 - date2;
                }
            );

            const bestMark = attemptInfo.gradesForPreviousAttempts.reduce((mark, attempt) => {
                return attempt.grade >= mark ? attempt.grade : mark;
            }, 0);

            const newAttempts: TestAttemptsCard[] = attemptsToSort.map(
                (attempt, index) => {
                    return {
                        uuid: index + 1 + '',
                        date: dateConvert(attempt.updatedAt.replace(/ \+0000 UTC/, ''))
                            .toFormat('dd.LL.yyyy, HH:mm'),
                        mark: attempt.grade,
                        isBest: attempt.grade === bestMark,
                        isInProgress: false
                    };
                }
            );

            attemptInfo.hasActiveAttempt && newAttempts.push({
                uuid: attemptInfo.gradesForPreviousAttempts.length + '',
                date: '',
                isBest: false,
                isInProgress: true,
                mark: 0
            });

            setAttempts(newAttempts);
        }

    }, [attemptInfo, isLoadingAttempt]);

    if (isLoadingAttempt) return <NewLoader />;

    if (attemptInfo.hasActiveAttempt) {
        dispatch(removeAuthenticationAttempt(uuidTest || ''));
    }

    return (
        <>
            <Helmet title={`Обучение – ${discipine.name}: ${testData.test.name}`} />
            <div className={style.wrapper}>
                <div className={style.header}>
                    <div className={style.title_navigate}>
                        <IconUI className={style.icon_navigate} typeIcon="left-arrow" />
                        <div onClick={() => navigate('../../')}>
                            Назад
                        </div>
                    </div>
                    <h1>{testData.test.name}</h1>
                </div>
                <div className={cx(style.test_info, style.paper)}>
                    <div className={style.main_info}>
                        <p>Число попыток: {attemptInfo?.totalNumberOfAttempts}</p>
                        <p>Время на одну попытку: {attemptInfo.testTimeLimit} минут</p>
                    </div>
                    <div className={style.description}>
                        <CSVPreview content={testData.test.description} />
                    </div>
                    {
                        attemptInfo.totalNumberOfAttempts !== attemptInfo.usersAttemptsCount
                        &&
                        <div className={style.button_wrapper}>
                            {
                                !attemptInfo.hasActiveAttempt
                                &&
                                <Button
                                    type="submit"
                                    className={style.button_start}
                                    onClick={on}
                                    disabled={!testData.questions.length}
                                >
                                    Начать тестирование
                                </Button>
                            }
                            <p>Доступно попыток: {attemptInfo.totalNumberOfAttempts - attemptInfo.usersAttemptsCount}</p>
                        </div>
                    }
                </div>
                {
                    !!attempts.length
                    &&
                    <div className={cx(style.results, style.paper)}>
                        <h2 className={style.results_title}>Результаты</h2>
                        <div>
                            {
                                attempts.map((attempt, index) => {
                                    return (
                                        <div
                                            key={attempt.uuid}
                                            className={cx(
                                                style.attempt,
                                                {
                                                    [style.attempt_active]: attempt.isInProgress
                                                }
                                            )}
                                        >
                                            <div className={style.attempt_header}>
                                                Попытка {++index}
                                            </div>
                                            <div className={style.attempt_date}>
                                                {(attempt.isInProgress ?
                                                    <div>
                                                        В процессе, осталось&nbsp;

                                                        <Counter
                                                            passedTime={+attemptInfo.passedTime}
                                                            timeLimit={attemptInfo.testTimeLimit}
                                                            onSubmit={finishTest}
                                                            onTick={onCounterTick}
                                                        />
                                                    </div>
                                                    : 'Завершено ' + attempt.date
                                                )}
                                            </div>
                                            {attempt.isBest
                                                &&
                                                <div className={style.attempt_best}>
                                                    лучшая попытка
                                                </div>
                                            }
                                            {
                                                attempt.isInProgress
                                                    ?
                                                    <Link to="start" className={style.attempt_link}>
                                                        Продолжить тестирование
                                                        <IconUI className={style.attempt_icon} typeIcon="right-arrow" />
                                                    </Link>
                                                    :
                                                    <div className={style.attempt_mark}>
                                                        {attempt.mark} баллов
                                                    </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                }
                <StartTestModal
                    isOpen={isOpen}
                    closeModal={off}
                    openModal={on}
                    onSubmit={startTest}
                    testTime={attemptInfo.testTimeLimit}
                />
            </div>
        </>
    );
};

export default TestStart;
