import { FC } from 'react';

import { Control, useController } from 'react-hook-form';

import { FileUploadArea, type FileUploadAreaProps } from '@components/core/inputs/FileUpload';

type FileControlProps = {
    name: string;
    control: Control<any>;
    withoutCheckControl?: boolean;
    maxSizeFile?: number
};

const FileUploadAreaControl: FC<FileControlProps & Omit<FileUploadAreaProps, 'value' | 'onChange'>> = ({
    control,
    name,
    withoutCheckControl = false,
    ...props
}) => {
    const {
        field: { onChange, value },
        fieldState: { error }
    } = useController({ name, control });

    return (
        <FileUploadArea
            name={name}
            value={value}
            onChange={onChange}
            withoutCheckControl={withoutCheckControl}
            helperText={error ? error.message : null}
            {...props}
        />
    );
};

export { FileUploadAreaControl };
