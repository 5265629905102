import React from 'react';

import cx from 'classnames';
import { DateTime } from 'ts-luxon';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { capitalizeFirstLetter } from '@helpers/text';
 
import style from './monitor.module.scss';

interface IMonitor {
    today: DateTime;

    prevHandler: () => void;
    todayHandler: () => void;
    nextHandler: () => void;
}

const Monitor: React.FC<IMonitor> = ({ today, prevHandler, nextHandler }) => {

    return (
        <div className={cx(style.wrapper, {
            [style['wrapper__methodist']]: false
        })}>
            <div className={style.month_control}>
                <ButtonIcon
                    typeIcon="left-arrow"
                    onClick={prevHandler}
                    className={style.month_control__btn}
                />

                <div className={style.month_control__date_wrapper}>
                    <span>{capitalizeFirstLetter(today.setLocale('ru').monthLong)}</span>
                    <span>{today.setLocale('ru').toFormat('yyyy')}</span>
                </div>
                
                <ButtonIcon
                    typeIcon="right-arrow"
                    onClick={nextHandler}
                    className={style.month_control__btn}
                />
            </div>
        </div>
    );
};

export default Monitor;