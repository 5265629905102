import React, { PropsWithChildren, Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from 'react';

import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg';
import IconButton from '@components/core/styledWrappers/IconButton';
import Portal from '@components/Portal/Portal';

import style from './modal.module.scss';


export interface IModalProps {
    children?: ReactNode;
    onClose: Dispatch<SetStateAction<boolean>>
    isOpen?: boolean;
    className?: string;
    title?: string;
    closeOnBackdrop?: boolean;
    contentClassName?: string
}

const Modal: FC<PropsWithChildren<IModalProps>> = ({
    children,
    isOpen = false,
    onClose,
    title,
    className,
    closeOnBackdrop,
    contentClassName
}) => {
    const [activeModal, setActiveModal] = useState(false);

    useEffect(() => {
        isOpen && setActiveModal(true);
    }, [isOpen]);

    const clickContentContainer = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    const closeAll = () => {
        setActiveModal(false);
        onClose(false);
    };

    const clickBackdrop = () => {
        // if (closeOnBackdrop) {
        //     closeAll();
        // } //  У нас были случаи когда не нужно было закрытие по щелчку на бэкдроп?

        closeAll();
    };

    return (
        <>
            <CSSTransition
                in={isOpen}
                timeout={200}
                classNames={{
                    enter: style.modal_enter,
                    enterActive: style.modal_enter_active,
                    exit: style.modal_exit,
                    exitActive: style.modal_exit_active
                }}
                unmountOnExit
            >
                <Portal>
                    <div className={style.backdrop} onClick={clickBackdrop}>
                        <div className={cx(style.content, className)} onClick={clickContentContainer}>
                            {title && <h2 className={style.content__title}>{title}</h2>}
                            {!closeOnBackdrop && (
                                <IconButton
                                    className={style.close_button}
                                    onClick={() => {
                                        setActiveModal(false);
                                        onClose(false);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                            <div className={cx(style.content__wrapper, contentClassName)}>
                                {children}
                            </div>
                        </div>
                    </div>
                </Portal>
            </CSSTransition>
        </>
    );
};

export default Modal;
