export const acceptAreaTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/svg+xml',
    'image/png',
    'image/jpeg',
    'application/zip',
    'text/plain'
];

export const MAX_FILE_SIZE = 209715200; // bytes
export const FILE_AREA_FORMATS = 'PDF, DOC, XLS, SVG, PNG, JPG, ZIP.';

export const checkFileSize = (size: number) => (size > MAX_FILE_SIZE ? true : false);
