import { FC, ReactNode } from 'react';

import cx from 'classnames';

import style from './paper.module.scss';

interface IProps {
    className?: string;
    children?: ReactNode;
    fullWidth?: boolean;
}

const Paper: FC<IProps> = ({ className, fullWidth, children, ...props }) => {
    return (
        <div className={cx(style.root, fullWidth && style['root--full_width'], className)} {...props}>
            {children}
        </div>
    );
};

export default Paper;
