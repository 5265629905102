import { FC } from 'react';

import cx from 'classnames';

import IconUI from '@components/core/IconUI';

import style from './SendButton.module.scss';

export interface ISendButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    isShow: boolean;
}

const SendButton:FC<ISendButtonProps> = ({
    isShow = false,
    ...props
}) => {

    return (
        <button className={cx(style.btn, !isShow && style.show)} {...props}>
            <IconUI typeIcon="send-button" />
        </button>
    );
};

export default SendButton;
