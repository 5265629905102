import React, { FC, memo, useState } from 'react';

import cx from 'classnames';

import IconUI from '@components/core/IconUI';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';

import { TVideoType } from '../VideoPreview';
import style from './iframe_video.module.scss';

interface VideoIframeProps {
    className?: string;
    videoData: TVideoType;
    setPlay?: boolean
}


const VideoIframe: FC<VideoIframeProps> = ({ className, videoData, setPlay }) => {
    const [iframe, setIframe] = useState(videoData.player === 'default');

    const previewIframe = () => {
        return (
            <>
                <img
                    src={videoData.previewImg}
                    alt=""
                    className={style.image}
                />
                {
                    setPlay && (
                        <ButtonIcon
                            className={style.button}
                            onClick={() => setIframe(true)}>
                            <IconUI typeIcon="youTubeSecond" className={style.play_icon} />
                        </ButtonIcon>
                    )
                }
            </>
        );
    };

    return (
        <div className={cx(style.root, className)}>
            <div className={style.wrapper}>
                {
                    iframe ? (
                        <iframe
                            width="100%"
                            height="100%"
                            src={videoData.embedLink}
                            title="VideoPlayer"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className={style.iframe}
                        />
                    ) : (
                        previewIframe()
                    )
                }
            </div>
        </div>
    );
};

export default memo(VideoIframe);
