import { TreeSelectOption } from '../NewTreeSelect';
import { OptionsMapWithParent, OptionsMapWithEndChildren } from '../types';

export const getFlatOptionsWithParent = (options: TreeSelectOption[], parentsIds?: string[]): OptionsMapWithParent => {
    const optionsMap: OptionsMapWithParent = {};

    options.forEach(({ value, label, children }) => {
        optionsMap[value] = {
            value,
            label,
            parents: parentsIds || []
        };

        const newParents = children.length ? [...(parentsIds || []), value] : [];

        const childrenFlat = getFlatOptionsWithParent(children, newParents);

        Object.values(childrenFlat).forEach(option => {
            optionsMap[option.value] = option;
        });
    });

    return optionsMap;
};

export const getFlatOptionsWithEndsChildren = (options: TreeSelectOption[]): OptionsMapWithEndChildren => {
    const optionsMap: OptionsMapWithEndChildren = {};

    options.forEach(({ value, label, children }) => {
        if (children.length) {
            const childs = getFlatOptionsWithEndsChildren(children);
            const childsArray = Object.values(childs);

            optionsMap[value] = {
                value,
                label,
                endChildren: childsArray
                    ?.map((childOption) => !childOption.endChildren.length ? childOption?.value : null)
                    ?.filter((childOption) => childOption !== null) as string[]
            };

            Object.values(childs)?.forEach((el) => {
                optionsMap[el.value] = {
                    ...el,
                    endChildren: el.endChildren?.map((childOption) => childOption)
                };
            });
        } else {
            optionsMap[value] = {
                value,
                label,
                endChildren: []
            };
        }
    });

    return optionsMap;
};
