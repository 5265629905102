import React, { useState } from 'react';

import { calendarContext } from './index';

interface CalendarProviderProps {
    children: React.ReactNode
}

export const CalendarProvider = ({ children }: CalendarProviderProps) => {
    const [search, setSearch] = useState('');
    const [groups, setGroups] = useState<string[]>([]);
    const [discipline, setDiscipline] = useState('');
    const [teacher, setTeacher] = useState('');

    // API
    

    return (
        <calendarContext.Provider value={{
            search,
            setSearch,
            groups,
            setGroups,
            discipline,
            setDiscipline,
            teacher,
            setTeacher
        }}>
            {children}
        </calendarContext.Provider>
    );
};
