import { FC } from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import IconUI from '@components/core/IconUI';
import { dateConvert } from '@helpers/date';
import { getTypeScore } from '@helpers/scores';
import { GroupDiscipline } from '@store/api/apiTypes';
import DisciplineSticker from '@views/student/StudentCourse/DisciplineSticker';

import style from './DisciplinesItem.module.scss';


interface IDisciplinesItem {
    discipline: GroupDiscipline
}


//ToDo: Отрефакторить оценки (еще есть одна комопнента с таким же именем у препода, смрежить их?)
const DisciplinesItem: FC<IDisciplinesItem> = ({ discipline }) => {
    const { name, progress, picture, session, score, deadline, closeType } = discipline;

    const localDeadline = deadline === '2100-01-01 01:01:01' ? '' : deadline;

    const scoreType = !!score && getTypeScore(score, closeType);

    return (
        <Link to={`${discipline.disciplineUUID}`}>
            <div className={style.root}>
                <div className={style.image_container}>
                    {
                        scoreType && <DisciplineSticker className={style.score} closeType={closeType} score={score} />
                    }
                    <div className={style.image_wrapper}>
                        <img className={style.image} src={picture?.path} alt="disciplineImage" />
                    </div>
                    {
                        !!progress && <div className={style.progress_block}>
                            <IconUI typeIcon="hat-graduation" />
                            <span>{progress}%</span>
                        </div>
                    }
                </div>
                <div className={style.description}>
                    <h4 className={style.title}>{name}</h4>
                    {/*<span className={style.status}>Ожидает проверки</span>*/}
                    <span className={style.deadline}>
                        {
                            localDeadline ? 'Срок сдачи до:  ' + dateConvert(localDeadline).toFormat('dd.LL.yyyy') : ''
                        }
                    </span>
                </div>
            </div>
        </Link>
    );
};

export default DisciplinesItem;
