import React from 'react';

import Main from '@components/Main';
import GoBackLink from '@components/student/GoBackLink';

import style from './UserAgreement.module.scss';

const UserAgreement = () => {
    return (
        <Main>
            <div className={style.main_content}>
                <GoBackLink to={'/'}>
                    Назад
                </GoBackLink>
                <h1 className={style.main_title}>
                    Пользовательское соглашение
                </h1>
                <p>Настоящее Соглашение определяет перечень условий, разрешений и ограничений использования Пользователями материалов и сервисов Сайта
                    https://eeip.online/ (далее — «Сайт»).</p>
                <p>В настоящем Соглашении и вытекающих или связанных с ним отношениях Сторон применяются следующие термины и определения:</p>
                <p>Администрация Сайта – Автономная некоммерческая организация высшего образования «Восточно-Европейский Институт психоанализа»</p>
                <p>Пользователь — физическое лицо, присоединившееся к настоящему Соглашению.</p>
                <p>Сервис — комплекс услуг, предоставляемых Пользователю с использованием Сайта.</p>
                <p>Соглашение — настоящее соглашение со всеми дополнениями и изменениями.</p>
                <ol>
                    <li>
                        <p>
                            1. Условия пользования Сайтом
                        </p>
                        <ol>
                            <li>
                                <p>
                                    1.1. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации.
                                </p>
                            </li>
                            <li>
                                <p>
                                    1.2. Настоящее Соглашение является публичной офертой. Получая доступ к материалам Сайта, Пользователь считается
                                    присоединившимся к настоящему
                                    Соглашению.
                                </p>
                            </li>
                            <li>
                                <p>
                                    1.3. Администрация Сайта вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие
                                    изменения вступают в силу по
                                    истечении 3 (трех) дней с момента размещения новой версии Соглашения на сайте. При несогласии Пользователя с внесенными
                                    изменениями он обязан
                                    отказаться от доступа к Сайту, прекратить использование материалов и сервисов Сайта.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            2. Обязательства Пользователя
                        </p>
                        <ol>
                            <li>
                                <p>
                                    2.1. Пользователь соглашается не предпринимать действий, которые могут рассматриваться как нарушающие российское
                                    законодательство или нормы
                                    международного права, в том числе в сфере интеллектуальной собственности, авторских и/ или смежных прав, а также любых
                                    действий, которые
                                    приводят или могут привести к нарушению нормальной работы Сайта и сервисов Сайта.
                                    Запрещается осуществлять копирование или любым другим способом воспроизведение материалов Сайта без согласия Администрации
                                    сайта.
                                </p>
                            </li>
                            <li>
                                <p>
                                    2.2. Не использовать материалы Сайта без согласия правообладателей (статья 1270 ГК РФ).
                                </p>
                            </li>
                            <li>
                                <p>
                                    2.3. При использовании цитат из материалов Сайта, включая охраняемые авторские произведения, ссылка на Сайт обязательна
                                    (подпункт 1 пункта 1
                                    статьи 1274 Г.К РФ).
                                </p>
                            </li>
                            <li>
                                <p>
                                    2.4. Комментарии и иные записи Пользователя на Сайте не должны противоречить требованиям законодательства Российской
                                    Федерации и общепринятым
                                    нормам морали и нравственности.
                                </p>
                            </li>
                            <li>
                                <p>
                                    2.5. Пользователь предупрежден о том, что Администрация Сайта не несет ответственности за посещение и использование им
                                    внешних ресурсов, ссылки
                                    на которые могут содержаться на сайте.
                                </p>
                            </li>
                            <li>
                                <p>
                                    2.6. Пользователь согласен с тем, что Администрация Сайта не несет ответственности и не имеет прямых или косвенных
                                    обязательств перед
                                    Пользователем, в связи с любыми возможными или возникшими потерями или убытками, связанными с любым содержанием Сайта,
                                    регистрацией авторских
                                    прав и сведениями о такой регистрации, товарами или услугами, доступными или полученными через внешние сайты или ресурсы
                                    либо иные контакты
                                    Пользователя, в которые он вступил, используя размещенную на Сайте информацию или ссылки на внешние ресурсы.
                                </p>
                            </li>
                            <li>
                                <p>
                                    2.7. Пользователь принимает положение о том, что все материалы и сервисы Сайта или любая их часть могут сопровождаться
                                    рекламой. Пользователь
                                    согласен с тем, что Администрация Сайта не несет какой-либо ответственности и не имеет каких-либо обязательств в связи с
                                    такой рекламой.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            3. Доступ к материалам Сайта
                        </p>
                        <ol>
                            <li>
                                <p>
                                    3.1. Доступ к материалам сайта осуществляется после идентификации личности Пользователя.
                                </p>
                            </li>
                            <li>
                                <p>
                                    3.2. Идентификация личности для доступа к материалам сайта обеспечивается путем ввода Пользователем парольной информации
                                    (логин, пароль). При
                                    этом передача парольной информации иному лицу запрещается. В случае выявления случая несанкционированного доступа лица по
                                    парольным данным
                                    Пользователя указанные логин и пароль блокируются.
                                </p>
                            </li>
                            <li>
                                <p>
                                    3.3. При прохождении Пользователем контрольных мероприятий, в том числе в рамках промежуточной аттестации, идентификация
                                    пользователя
                                    обязательно производится на основе визуальной информации путем предоставления фото- или видеоизображения Пользователя и
                                    документа,
                                    удостоверяющего его личность. При нарушении данного правила (непредоставлении документа, удостоверяющего личность, либо
                                    предоставлении
                                    документа, не удостоверяющего личность Пользователя) результаты контрольных мероприятий не засчитываются Пользователю и к
                                    повторной сдаче
                                    Пользователь не допускается.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            4. Прочие условия
                        </p>
                        <ol>
                            <li>
                                <p>
                                    4.1. Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному исполнению не
                                    влечет недействительности
                                    иных положений Соглашения.
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.2. Ничто в Соглашении не может пониматься как установление между Пользователем и Администрации Сайта агентских отношений,
                                    отношений
                                    товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо не
                                    предусмотренных Соглашением.
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.3. Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с
                                    действующим
                                    законодательством Российской Федерации.
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.4. Бездействие со стороны Администрации Сайта в случае нарушения кем-либо из Пользователей положений Соглашения не лишает
                                    Администрацию Сайта
                                    права предпринять позднее соответствующие действия в защиту своих интересов и защиту авторских прав на охраняемые в
                                    соответствии с
                                    законодательством материалы Сайта.
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.5. Пользователь подтверждает, что ознакомлен со всеми пунктами настоящего Соглашения в полном объеме до начала
                                    использования Сервиса ,
                                    безусловно принимает их и обязуется их соблюдать или прекратить использование Сервиса.
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.6. Пользователь соглашается с тем, что в случае нарушения настоящего Соглашения Пользователь отключается от Сайта, его
                                    пароль и логин
                                    блокируются.
                                </p>
                            </li>
                            <li>
                                <p>
                                    4.7. Пользователь не вправе ссылаться на то, что он не знал о своих правах и обязанностях и / или содержании настоящего
                                    Соглашения.
                                </p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </Main>
    );
};

export default UserAgreement;
