import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

interface NavigationState {
    history?: string
}

interface UseNavigationHistoryReturned {
    navigate: (to: string) => void;
    goBack: () => void;
    toForBack: string;
    linkProps: {
        state: any
    }
}

export const useNavigationHistory = (defaultBackUrl?: string): UseNavigationHistoryReturned => {
    const navigateRoot = useNavigate();
    const location = useLocation();

    const locationState = location.state as NavigationState;
    const historyUrl = locationState?.history || defaultBackUrl;

    const navigate = (to: string, state?: Record<string, any>) => {
        const historyState = { history: location.pathname, ...state };
        navigateRoot(to, { state: historyState });
    };

    const goBack = () => {
        if (historyUrl) {
            navigateRoot(historyUrl);
        } else {
            navigateRoot(-1);
        }
    };

    const linkProps = {
        state: { history: location.pathname }
    };

    return {
        navigate,
        goBack,
        toForBack: historyUrl || '',
        linkProps
    };
};
