import React from 'react';

import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import RouterTabs from '@components/RouterTabs';
import { useGetUserByUuidQuery } from '@store/api/usersApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { User } from '@type/users';

import style from './user_profile.module.scss';


const tabs = [
    { name: 'Профиль', path: '' },
    { name: 'Портфолио', path: 'portfolio' },
    { name: 'Электронная зачетка', path: 'recordBook' },
    { name: 'Смена пароля', path: 'changePassword' },
    { name: 'Компетенции', path: 'skills' }
];

export const useUser = () => {
    return useOutletContext<User>();
};

const UserProfile = () => {
    const navigate = useNavigate();

    const { userId } = useParams();
    const userUuid = userId ? userId : '';
    const { isLoading, data } = useGetUserByUuidQuery(userUuid);
    const myProfileUuid = useAppSelector(selectApiUser)?.uuid;

    let user: User | undefined;

    if (!isLoading && data?.uuid) {
        user = data;
    }

    if (isLoading) return <NewLoader />;

    const isStudent = data?.role === Role.Student;
    const isMyProfile = data?.uuid === myProfileUuid;

    const filteredIsStudentTabs = isStudent ? tabs : tabs.filter((tab) => {
        return (
            tab.name !== 'Электронная зачетка' &&
            tab.name !== 'Портфолио' &&
            tab.name !== 'Компетенции'
        );
    });
    const filteredIsMyProfileTabs = isMyProfile ? filteredIsStudentTabs : filteredIsStudentTabs.filter(tab => tab.name !== 'Смена пароля');

    const pageTitle = user && `Управление пользователями – Редактирование профиля, ${user.lastName} ${user.firstName} ${user.patronymic}`;

    return (
        <>
            <Main title={pageTitle}>
                <div className={style.title_wrapper}>
                    <ButtonIcon
                        typeIcon="arrowLink"
                        onClick={() => navigate('/users')}
                    />

                    <h2>Информация о пользователе</h2>
                </div>

                <div className={style.tabs_wrapper}>
                    <RouterTabs tabs={filteredIsMyProfileTabs} />
                    <Outlet context={user} />
                </div>
            </Main>
        </>
    );
};

export default UserProfile;
