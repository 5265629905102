import chatSoundSource from '@assets/sounds/chat_sound.wav';
import noticeSoundSource from '@assets/sounds/notice_sound.wav';
import { TypeSound } from '@type/sound';

class SoundManager {
    private ready = true;
    private delay = 2000;

    private soundEffect = new Audio();

    init() {
        this.soundEffect.muted = true;
        this.soundEffect.addEventListener('canplaythrough', () => {
            this.soundEffect.muted = false;
        });
    }

    private onReady() {
        this.ready = true;
    }

    private onDelay() {
        this.ready = false;
        setTimeout(() => {
            this.onReady();
        }, this.delay);
    }

    private static getSound(type: TypeSound): string {
        switch (type) {
            case 'chat':
                return chatSoundSource;
            case 'notice':
                return noticeSoundSource;
        }
    }

    play(type: TypeSound) {
        if (this.ready) {
            try {
                this.soundEffect.src = SoundManager.getSound(type);
                this.soundEffect.play();
            } catch (err) {
                console.error(err);
            }

            this.onDelay();
        }
    }
}

export const soundManager = new SoundManager();
