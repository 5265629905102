import { IStorageFile, Uuid } from '@store/api/apiTypes';
import { Role } from '@store/Roles';

// messages
export enum MessageType {
    Text ='text',
    File = 'file',
}

export interface MessageBase {
    uuid: Uuid;
    type: MessageType;
    uuidSender: Uuid;
    uuidRoom: Uuid;
    createdAt: string;
    id: string;
    storageLoaded?: boolean;
    storageError?: boolean;
}

export interface TextMessage extends MessageBase {
    type: MessageType.Text;
    text: string;
}

export interface FileMessage extends MessageBase {
    type: MessageType.File;
    files: IStorageFile[];
    text?: string;
}

export interface SocketFileMessage extends MessageBase {
    type: MessageType.File;
    uuidsFile: string[];
    text?: string;
}

export interface SocketMessage {
    name: 'message_event';
    uuidSender: string;
    uuid: string;
    id: string;
    recipients: RecipientUser[];
    payload: {
        createdAt: string;
    } & (TextMessage | SocketFileMessage);
}

export type Message = TextMessage | FileMessage;
export type TextMessageStorage = TextMessage & { loading?: boolean };
export type FileMessageStorage = FileMessage & { loading?: boolean };
export type StorageMessage = TextMessageStorage | FileMessageStorage


// previewMessages
export interface PreviewMessageBase {
    uuid: Uuid;
    uuidSender: Uuid;
    uuidRoom: Uuid;
    type: MessageType;
    createdAt: string;
}

export interface PreviewTextMessage extends PreviewMessageBase {
    type: MessageType.Text;
    text: string
}

export interface PreviewFileMessage extends PreviewMessageBase {
    type: MessageType.File;
    files: IStorageFile[]
}

export type PreviewMessage = PreviewTextMessage | PreviewFileMessage;

// users
export interface ChatUser {
    uuid: Uuid;
    fullName: string;
    avatar?: string;
    role: Role;
    uuidGroup?: Uuid;
}

// room
export interface Room {
    uuid: Uuid;
    users: ChatUser[];
    previewMessage: PreviewMessage
}

export interface RoomServiceInformation {
    uuid: Uuid;
    draftMessage?: string;
    notificationCount?: number;
}

export interface MainEventMessage {
    payload: string;
    type: 'New message';
    uuidRoom: Uuid;
    uuidSender: Uuid;
}

// requests
export interface CreateRoom {
    fromUser: Uuid;
    toUser: Uuid
}

export interface CreateRoomResponse {
    uuid: Uuid;
    uuidsUser: Uuid[]
}

export interface RoomsRequest {
    limit: number
    offset: number
}

export interface RoomsResponse {
    rooms: Room[];
    total: number;
}

export interface MessagesRequest {
    uuid: Uuid;
    limit?: number
    offset?: number
}

export interface MessagesResponse {
    messages: null | Message[];
    room: {
        uuid: Uuid;
        users: Omit<ChatUser, 'uuidGroup' | 'avatar'>[]
    }
}

export interface RecipientUser {
    uuid: Uuid;
    uuidRoom?: Uuid;
}

export interface SendMessageByGroupBase {
    type: MessageType;
    recipients: RecipientUser[]
}

export interface SendTextMessageByGroup extends SendMessageByGroupBase {
    type: MessageType.Text;
    text: string;
}

export interface SendFileMessageByGroup extends SendMessageByGroupBase {
    type: MessageType.File;
    text?: string;
    uuidsFile: string[]
}

export type SendMessageByGroup = SendTextMessageByGroup | SendFileMessageByGroup
