import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';

import { format, formatISO9075, parse } from 'date-fns';

import Button from '@components/core/inputs/Button';
import InputBase from '@components/core/inputs/InputBase/InputBase';
import Popover from '@mui/material/Popover';

import { DatePicker } from '../DatePicker';
import { addTimeToDate } from '../helper';
import style from './DPControl.module.scss';

export interface IDPControlProps {
    variant?: 'default' | 'cancel' | 'search' | 'date';
    defaultValue?: string;
    onSubmit: (date: string) => void;
    isButton?: boolean;
    element?: React.ReactNode;
    style?: CSSProperties;
    caption?: string;
    placeholder?: string;
    disabled?: boolean;
}

export const DPControl: FC<IDPControlProps> = ({
    variant,
    onSubmit,
    defaultValue,
    placeholder = 'Выбрать дату',
    caption = 'Дата открытия курса',
    isButton,
    ...props
}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | HTMLButtonElement | null>(null);
    const [selectDay, setSelected] = useState<Date | undefined>();

    const [time, setTime] = useState<string>('00:00');
    const refContainer = useRef(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = () => {
        setAnchorEl(refContainer.current);
    };

    const handleClose = () => {

        setAnchorEl(null);

        if (!selectDay) return;
        const tmpDate = format(selectDay, 'dd.MM.yyyy') + ' ' + time;
        const enchatedSelectedDay = parse(tmpDate, 'dd.MM.yyyy HH:mm', new Date());

        onSubmit(formatISO9075(enchatedSelectedDay));
    };

    const setActualDate = (date: Date) => {
        setSelected(date);
        setInputValue(format(date, 'dd.MM.yyyy HH:mm'));
        setTime(format(date, 'HH:mm'));
    };

    const processTime = (date: Date) => {
        date = addTimeToDate(date, time);
        setActualDate(date);
    };

    const onSelectDate = (date: Date | undefined) => {
        if (!date) return;
        processTime(date);
    };

    const onSetup = (date: Date) => {
        processTime(date);
        handleClose();
    };

    const onTimeChange = (value: string) => {
        if (value) setTime(value);
    };

    useEffect(() => {
        if (defaultValue) {
            const tmpValue = new Date(defaultValue);
            setActualDate(tmpValue);
        }

    }, [defaultValue]);

    const controlProps = {
        ...props,
        name: 'control-date',
        className: style.cursor,
        placeholder,
        variant,
        readOnly: true,
        value: !isButton ? inputValue : '',
        onClick: handleClick
    };

    return (<div className={style.container} ref={refContainer}>
        {
            !isButton ? (
                <InputBase {...controlProps} />
            ) : (
                <Button onClick={handleClick} btnType="dark" size="small" typeIcon="calendar-light">
                    {selectDay ? format(selectDay, 'dd.MM.yyyy') + ' ' + time : caption}
                </Button>
            )
        }

        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            style={{ top: 4 }}
        >
            {<DatePicker
                mode="single"

                startDate={selectDay}
                setStart={setSelected}

                startTime={time}
                onTimeChange={onTimeChange}

                onSelectDate={onSelectDate}
                onSubmit={onSetup} />}

        </Popover>
    </div>);
};
