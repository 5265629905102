import React from 'react';

import clsx from 'classnames';
import { Link } from 'react-router-dom';

import { Label } from '@components/Label';
import { TableMenu, MenuItem } from '@components/Menu';
import { RoleCheck } from '@components/RoleCheck';
import { useNavigationHistory } from '@hooks/useNavigationHistory';
import { useUpdateDisciplineStatusMutation } from '@store/api/disciplinesApi';
import { Role } from '@store/Roles';

import styles from './DisciplineItem.module.scss';

interface DisciplineItemProps {
    uuid: string;
    name: string;
    uuidCategory: string;
    status: boolean;
    openCategoryModal: (uuid: string, uuidCategory: string) => void;
    openDeleteModal: (uuid: string, name: string) => void;
}

export const DisciplineItem: React.FC<DisciplineItemProps> = ({
    uuid,
    status,
    uuidCategory,
    name,
    openCategoryModal,
    openDeleteModal
}) => {

    const [updateDiscipline] = useUpdateDisciplineStatusMutation();

    const publishDiscipline = () => {
        updateDiscipline({
            uuid,
            status: true
        });
    };

    const { linkProps } = useNavigationHistory();

    return (
        <div className={clsx('row--selectable', styles.container)}>
            <Link to={`/education/${uuid}`} target="_blank" {...linkProps}>
                <div className={styles.name}>
                    {name}
                    {!status && <Label caption='Черновик' />}
                </div>
            </Link>

            <TableMenu>
                <MenuItem to={`/materials/${uuidCategory}/course/${uuid}`} linkProps={linkProps}>
                    Редактировать
                </MenuItem>

                <RoleCheck roles={Role.Methodist}>
                    {!status && <MenuItem onClick={publishDiscipline}>Опубликовать</MenuItem>}

                    <MenuItem onClick={() => openCategoryModal(uuid, uuidCategory)}>Переместить</MenuItem>
                    <MenuItem onClick={() => openDeleteModal(uuid, name)} danger>Удалить</MenuItem>
                </RoleCheck>

            </TableMenu>
        </div>
    );
};
