import { toast } from 'react-toastify';

import { IGetEventsLogRequest, IGetEventsLogResponse, ISetEventsLogRequest } from '@type/eventsLog';

import { emptyBaseApi } from './emptyBaseApi';

export const eventsLogApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getEventsLog: build.query<IGetEventsLogResponse, IGetEventsLogRequest>({
            query(filters) {
                return {
                    url: 'auth/journal/get',
                    body: filters,
                    method: 'POST'
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Ошибка получения журнала');
                }
            },
            providesTags: (result, error, arg) => arg ? 
                [...arg.uuidsUser.map(uuid => ({ type: 'Journal' as const, id: `Journal_${uuid}` })), 'Journal'] : ['Journal']
        }),
        setEventToLog: build.mutation<void, ISetEventsLogRequest>({
            query(data) {
                return {
                    url: 'auth/journal',
                    method: 'POST',
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Journal', id: `Journal_${arg.uuidUser}` }]
        })
    })
});

export const {
    useLazyGetEventsLogQuery,
    useSetEventToLogMutation
} = eventsLogApi;
