import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { DateTime } from 'ts-luxon';

import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import { dateConvert } from '@helpers/date';
import { EnumEventStatus, Event } from '@store/api/apiTypes';
import { useSetEventToLogMutation } from '@store/api/eventsLogApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { InfoEvent } from '@type/eventCalendar';

import style from './EventInfo.module.scss';


interface EventInfoProps {
    eventInfo: Event;
    setInfoEvent: (obj: InfoEvent) => void;
}

export const EventInfo: React.FC<EventInfoProps> = ({ eventInfo, setInfoEvent }) => {
    const [showConnectEventBtn, setShowConnectEventBtn] = useState(false);
    const user = useAppSelector(selectApiUser);

    const [writeActionToEventLog] = useSetEventToLogMutation();

    const checkEventTime = () => {
        const today = DateTime.now().toMillis();
        return today >= dateConvert(eventInfo.startEventAt).minus({ minute: 15 }).toMillis();
    };

    const onEventConnect = () => {
        if (user) {
            writeActionToEventLog({
                uuidUser: user.uuid,
                name: 'Клик на ссылку на онлайн участие',
                type: 'events'
            });
        }
        setInfoEvent({ isVisible: false, currentEventId: eventInfo.eventUuid });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (checkEventTime()) {
                setShowConnectEventBtn(true);
            }
        }, 1000);

        if (checkEventTime()) {
            setShowConnectEventBtn(true);
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {
                (eventInfo.status == EnumEventStatus.Started)
                    ? <span className={cx(style.status, style.status_success)}>Началось</span>
                    : (eventInfo.status === EnumEventStatus.NotStarted)
                        ? <span className={cx(style.status)}>Еще не началось</span>
                        : <span className={cx(style.status)}>Завершено</span>
            }
            <h3 className={style.title}>{eventInfo.name}</h3>
            <div className={style.description}>
                <div className={style.description__item}>
                    <IconUI typeIcon="lychka" />
                    <p>Вебинар</p>
                </div>
                <div className={style.description__item}>
                    <IconUI typeIcon="book" />
                    <p>Дисциплина: {eventInfo.disciplineName}</p>
                </div>
                {
                    eventInfo.teacherInfo && <div className={style.description__item}>
                        <IconUI typeIcon="user" />
                        <p>Преподаватель: {`${eventInfo.teacherInfo.lastName} ${eventInfo.teacherInfo.firstName} ${eventInfo.teacherInfo.patronymic}`}</p>
                    </div>
                }
                <div className={style.description__item}>
                    <IconUI typeIcon="date" />
                    <p>
                        {
                            dateConvert(eventInfo.startEventAt).toFormat('yyyy.LL.dd, HH:mm')
                            + ' – ' +
                            dateConvert(eventInfo.startEventAt).plus(eventInfo.duration * 60000).toFormat('HH:mm')
                            + ' (UTC' + dateConvert(eventInfo.startEventAt).toFormat('ZZ') + ')'
                        }
                    </p>
                </div>
            </div>

            {
                (eventInfo.status === EnumEventStatus.Completed && eventInfo.linkToRecords[0])
                &&
                <div onClick={() => { setInfoEvent({ isVisible: false, currentEventId: eventInfo.eventUuid }); }}>
                    <Button
                        link
                        fullWidth={true}
                        className={style.button}
                        to={`finished/${eventInfo.disciplineUuid}/${eventInfo.eventUuid}`}
                    >
                        Смотреть запись
                    </Button>
                </div>
                
            }
            {
                ((eventInfo.status !== EnumEventStatus.Completed) && showConnectEventBtn)
                &&
                <div onClick={onEventConnect}>
                    <Button
                        fullWidth={true}
                        className={style.button}
                        href={eventInfo.links[0]}
                    >
                        Подключиться
                    </Button>
                </div>
                
            }
        </>
    );
};
