import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import { addMonths, format, getDay, getMonth, isEqual, isSameMonth, isToday, lastDayOfMonth, lastDayOfWeek, setMonth, setYear, startOfWeek } from 'date-fns';
import { Day, useLilius } from 'use-lilius';

import { RangeMonthSelected } from '@components/DatePicker/DatePicker';

import { DPMonthYear } from '../DPMonthYear';
import { DPNavbar } from '../DPNavbar';
import { TStartOrEnd } from '../type';
import style from './DPCalendar.module.scss';
import { DPDay } from './DPDay';

export interface IDPCalendarProps {
    defaultViewing: Date;
    onSelect: (newDate: Date, rangeMode: boolean) => void;
    inGlobalRange: (date: Date) => boolean;
    isStartOrEnd: (date: Date) => TStartOrEnd;
    className?: string;
    equalDates?: (date: Date, type: string | undefined) => void;
    type?: 'left' | 'right';
    selected?: Date;
    range?: boolean;
    isLastCalendar?: boolean;

    startDate?: Date;
    endDate?: Date;

    rangeMonthSelected: RangeMonthSelected;
    changeRangeMonthSelected: Dispatch<SetStateAction<RangeMonthSelected>>;
}

const cx = classNames.bind(style);

export type ISelectPickDate = {
    year?: number;
    month?: {
        value?: number;
        name?: string;
        shortName?: string;
    }
}

export const DPCalendar: FC<IDPCalendarProps> = ({
    defaultViewing,
    onSelect,
    inGlobalRange,
    isStartOrEnd,
    className,
    range = false,
    isLastCalendar = false,
    startDate,
    endDate,
    rangeMonthSelected,
    changeRangeMonthSelected
}) => {
    const {
        calendar,
        setSelected,
        viewing,
        setViewing,
        viewNextMonth,
        viewPreviousMonth
    } = useLilius({
        weekStartsOn: Day.MONDAY
    });

    const [showPick, setPick] = useState(false);
    const [offsetPick, setOffsetPick] = useState(0);
    const [selectedPickDate, setSelectPickDate] = useState<ISelectPickDate | null | undefined>(null);

    useEffect(() => {
        if (isLastCalendar) {
            // Есть дата конца и дата начала, и их месяцы не совпадают
            if (endDate && startDate && getMonth(endDate) !== getMonth(startDate)) {
                setViewing(endDate);
                // Если месяцы совпали и есть дата начала
            } else if (startDate) {
                setViewing(addMonths(startDate, 1));
                // Дефолтное значение при отсутствии дат
            } else {
                setViewing(addMonths(new Date, 1));
            }
        } else {
            if (startDate) {
                setViewing(startDate);
            } else {
                setViewing(new Date());
            }
        }

    }, []);

    useEffect(() => {
        if (range) {
            changeRangeMonthSelected(prevRangeDates => {
                const newRangeDates = { ...prevRangeDates };
                if (!isLastCalendar) {
                    newRangeDates.start = viewing;
                } else {
                    newRangeDates.end = viewing;
                }
                return newRangeDates;
            });
        }
    }, [viewing]);

    const disabledNext = range && !isLastCalendar && isSameMonth(addMonths(viewing, 1), rangeMonthSelected.end);
    const disabledPrev = range && isLastCalendar && isSameMonth(addMonths(viewing, -1), rangeMonthSelected.start);

    const nextBtn = () => {
        if (showPick) {
            setOffsetPick(offsetPick + 1);
            setOffsetPick(offsetPick + 1);
        } else if (!disabledNext) {
            viewNextMonth();
        }
    };

    const prevBtn = () => {
        if (showPick) {
            setOffsetPick(offsetPick - 1);
        } else if (!disabledPrev) {
            viewPreviousMonth();
        }
    };

    const onClickPick = () => {
        setPick(!showPick);
    };

    const onPickMonthYearChange = (data: ISelectPickDate) => {
        setSelectPickDate({ ...selectedPickDate, ...data });

        if (data.month?.name) {

            let finalDate = new Date();
            finalDate = setMonth(finalDate, data.month.value ? data.month.value : 0);
            finalDate = setYear(finalDate, selectedPickDate?.year ? selectedPickDate.year : 2022);

            setViewing(finalDate);
            setPick(false);
        }
    };

    const handleOnClick = (day: Date, hideDate: boolean) => {
        if (!hideDate) {
            if (!range) {
                onSelect(day, false);
            } else {
                setSelected([day]);
                onSelect(day, true);
            }
        }
    };

    const isEqualDayMonthYear = (day: Date, selectedDay: Date | undefined | null): boolean => {

        if (!selectedDay) return false;

        const dateWithoutTime = selectedDay.setHours(0, 0, 0, 0);

        return isEqual(day, dateWithoutTime);
    };


    return (
        <>
            <div className={cx(className)}>

                <DPNavbar
                    next={nextBtn}
                    prev={prevBtn}
                    viewing={viewing}
                    isOpen={showPick}
                    onClickBar={onClickPick}
                    disabledNext={!showPick ? disabledNext : undefined}
                    disabledPrev={!showPick ? disabledPrev : undefined}
                />

                {showPick && (<div className={style.pickContainer}>
                    <DPMonthYear
                        onChange={onPickMonthYearChange}
                        offset={offsetPick}
                        range={range}
                        isLastCalendar={isLastCalendar}
                    />
                </div>)}

                <table className={cx(style.calendarContainer)}>
                    <tbody>
                        <tr className={style.weeks}>
                            {calendar[0][0].map((day, i) => (
                                <th key={`calendar-weekday-${i}`} className={style.weekDay}>
                                    {['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'][getDay(day)]}
                                </th>
                            ))}
                        </tr>

                        {calendar[0].map((week, index) => {
                            return (
                                <tr key={`calendar-rows-${index}`} className={style.row}>
                                    {week.map((day, i) => {
                                        const hideDate = !isSameMonth(day, viewing);
                                        const textDay = hideDate ? '' : format(day, 'dd');
                                        const isFirstDayOfMonth = format(day, 'yyyy-MM-01') === format(day, 'yyyy-MM-dd');
                                        const isLastDayOfMonth = format(lastDayOfMonth(day), 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd');
                                        const isFirstDayOfWeek = format(startOfWeek(day, { weekStartsOn: 1 }), 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd');
                                        const isLastDayofWeek = format(lastDayOfWeek(day, { weekStartsOn: 1 }), 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd');

                                        const cellClass = cx(style.rowDay,
                                            hideDate && style.disable,
                                            isToday(day) && style.isToday,
                                            isEqualDayMonthYear(day, startDate) && style.isSelected,
                                            isStartOrEnd(day).startDate && style.isStartSelectedRange,
                                            isStartOrEnd(day).endDate && style.isEndSelectedRange,
                                            inGlobalRange(day) && !hideDate && style.isRangeSelected
                                        );

                                        return <DPDay
                                            key={`calendar-day-${i}`}
                                            className={cellClass}
                                            day={textDay}
                                            onClick={() => handleOnClick(day, hideDate)} />;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};
