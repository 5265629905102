import { FC } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import { FileUploadPhotoControl } from '@components/core/inputs/FileUpload/FileUploadPhotoControl';
import InputControl from '@components/core/inputs/InputControl';
import SwitcherControl from '@components/core/inputs/Switcher/SwitcherControl';
import Paper from '@components/core/styledWrappers/Paper';
import { getBase64 } from '@helpers/base64';
import { isStorageFile } from '@helpers/typeCheck';
import { yupResolver } from '@hookform/resolvers/yup';
import { fileTypes } from '@store/api/apiTypes';
import { useUploadFileMutation } from '@store/api/storageApi';
import { CreateCourseFormData } from '@views/methodist/Materials/Course/CreateCourse/CreateCourseForm/type';
import TeachersCard from '@views/methodist/Materials/Course/TeachersCard';

import style from './create_course_form.module.scss';

export const createCourseSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    picture: yup.mixed().label('File').required('Поле обязательно для заполнения!'),
    status: yup.boolean(),
    uuidsTeacher: yup.array(),
    uuidsDouble: yup.array(),
    uuidsMetodist: yup.array()
});

interface ICreateCourseForm {
    createCourseSubmit: (formData: CreateCourseFormData) => void,
}

const CreateCourseForm: FC<ICreateCourseForm> = ({ createCourseSubmit }) => {

    const methods = useForm<CreateCourseFormData>({
        resolver: yupResolver(createCourseSchema)
    });
    const { control, handleSubmit, reset, setValue } = methods;
    const navigate = useNavigate();


    const [uploadFile] = useUploadFileMutation();

    const onSubmit = async (formData: CreateCourseFormData) => {
        let transformedData = { ...formData };

        if (formData.picture) {
            let coverUuid = '';
            if (isStorageFile(formData.picture)) {
                coverUuid = formData.picture.uuid;
            } else {
                const b64 = await getBase64(formData.picture);
                const uploadedFile = await uploadFile({
                    file: b64 as string,
                    meta: {
                        filename: formData.picture.name,
                        type: fileTypes.Public
                    }
                }).unwrap();
                coverUuid = uploadedFile.uuid;
            }

            transformedData = {
                ...transformedData, picture: coverUuid
            };
        }
        createCourseSubmit(transformedData);
    };

    const cancelHandler = () => {
        navigate('./../..');
    };

    return (
        <Grid>
            <Column size={8}>
                <Paper className={style.paper}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormProvider {...methods}>
                            <div className={style.form_content}>
                                <div className={style.fieldset}>
                                    <h2 className={style.fieldset_title}>
                                        Основное
                                    </h2>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <label htmlFor="field-title-id">
                                            Название*
                                        </label>
                                        <InputControl
                                            name="name"
                                            placeholder="Название"
                                            id="field-title-id"
                                        />
                                    </div>
                                    <div className={style.input_wrapper}>
                                        <p>Обложка курса*</p>
                                        <FileUploadPhotoControl
                                            name="picture"
                                            control={control}
                                        />
                                    </div>
                                    <div className={style.input_wrapper}>
                                        <p>Опубликовано</p>
                                        <SwitcherControl
                                            name="status"
                                            control={control}
                                        />
                                    </div>
                                </div>
                                <TeachersCard />
                                <div className={style.form_content__button_wrapper}>
                                    <Button type="submit">Сохранить</Button>
                                    <Button
                                        btnType="secondary"
                                        onClick={cancelHandler}
                                    >
                                        Отмена
                                    </Button>
                                </div>
                            </div>
                        </FormProvider>
                    </form>
                </Paper>
            </Column>
        </Grid>
    );
};

export default CreateCourseForm;
