import { FC, useState, useEffect, useMemo } from 'react';

import Modal from '@components/Modal';
import { dateConvert, datePrepareToSend } from '@helpers/date';
import { useCreateExtraSessionMutation } from '@store/api/groupsApi';
import { DebtDiscipline } from '@type/disciplines';
import { Debtor } from '@type/users';

import AdditionalControls from './AdditionalControls/AdditionalControls';
import style from './AdditionalSessionModal.module.scss';
import DisciplinesStep from './DisciplinesStep/DisciplinesStep';
import { PeriodStep } from './PeriodStep';
import { SemesterStep } from './SemesterStep';
import StudentsStep from './StudentsStep/StudentsStep';



type IAdditionalSessionModal = {
    onClose: () => void;
    isOpen: boolean;
    uuidGroup: string;
}

const AdditionalSessionModal: FC<IAdditionalSessionModal> = ({ onClose, isOpen, uuidGroup }) => {
    const [step, setStep] = useState(1);
    const [isBtnDisabled, setBtnDisabled] = useState(false);
    const [selectedDebtors, setSelectedDebtors] = useState<Debtor[]>([]);
    const [selectedDisciplines, setSelectedDisciplines] = useState<string[]>([]);

    const [semesters, setSemesters] = useState<string[]>([]);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [createExtraSession] = useCreateExtraSessionMutation();

    const selectedStudents = useMemo(() => selectedDebtors.map((debtor) => debtor.uuid), [selectedDebtors]);

    const debtDisciplines = useMemo(() => {
        const disciplines: DebtDiscipline[] = [];
        selectedDebtors.forEach(({ debts }) => {
            debts.forEach((discipline) => {
                if (!disciplines.find(el => el.uuid === discipline.uuid)) {
                    disciplines.push(discipline);
                }
            });
        });

        return disciplines;
    }, [selectedDebtors]);

    const filteredDebtDisciplinesBySemesters = useMemo(() => {
        return debtDisciplines.filter(el => semesters.includes(el.semester));
    }, [debtDisciplines, semesters]);

    const closeModal = () => {
        onClose();
        setStep(1);
        setSelectedDebtors([]);
        setSemesters([]);
        setSelectedDisciplines([]);
        setStartDate('');
        setEndDate('');
    };

    const onSubmit = async () => {
        await createExtraSession({
            uuidGroup,
            uuidUser: selectedStudents,
            uuidsDisciplines: selectedDisciplines,
            deadline: {
                deadlineStart: datePrepareToSend(startDate),
                deadlineEnd: datePrepareToSend(endDate)
            }
        });
        closeModal();
    };

    const renderTitle = () => {
        if (step >= 4) {
            return 'Дополнительная сессия';
        }

        return 'Открыть дополнительную сессию';
    };

    const validateDeadline = () => {
        if (startDate && endDate) {
            const start = dateConvert(startDate);
            const end = dateConvert(endDate);

            const diff = end.diff(start, ['milliseconds']);
            return diff.toObject().milliseconds as number > 0;
        }
        return true;
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return <StudentsStep
                    uuidGroup={uuidGroup}
                    selectedStudents={selectedDebtors}
                    setSelectedStudents={setSelectedDebtors}
                />;


            case 2:
                return <SemesterStep selectedSemesters={semesters} setSemesters={setSemesters} />;

            case 3: {
                return <DisciplinesStep
                    selectedDisciplines={selectedDisciplines}
                    setSelectedDisciplines={setSelectedDisciplines}
                    disciplines={filteredDebtDisciplinesBySemesters}
                />;
            }

            case 4:
                return <PeriodStep
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    error={validateDeadline() ? undefined : 'Дата окончания должна быть позже даты начала'}
                />;

            default:
                return null;
        }
    };

    useEffect(() => {
        switch (step) {
            case 1:
                setBtnDisabled(!selectedDebtors.length);
                break;

            case 2:
                setBtnDisabled(!semesters.length);
                break;

            case 3:
                setBtnDisabled(!selectedDisciplines.length);
                break;


            case 4:
                setBtnDisabled(!(startDate && endDate) || !validateDeadline());
                break;

            default:
                setBtnDisabled(true);
        }

    }, [step, selectedDebtors, semesters, selectedDisciplines, startDate, endDate]);


    return (
        <Modal className={style.modal} onClose={closeModal} isOpen={isOpen} title={renderTitle()}>
            <div>
                {renderStep()}
            </div>
            <AdditionalControls
                step={step}
                setStep={setStep}
                onClose={closeModal}
                onSubmit={onSubmit}
                isBtnDisabled={isBtnDisabled}
                setBtnDisabled={setBtnDisabled}
            />
        </Modal>
    );
};

export default AdditionalSessionModal;
