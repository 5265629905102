import { IJSXRenderClear } from '@type/common';

import { ActiveDiscipline } from './ActiveDiscipline';
import { AllDiscipline } from './AllDiscipline';
import { AvailableDiscipline } from './AvailableDiscipline';
import { CompletedDiscipline } from './CompletedDiscipline';

export const notFoundComponents: IJSXRenderClear = {
    'all': <AllDiscipline />,
    'active': <ActiveDiscipline />,
    'borg': <AvailableDiscipline />,
    'completed': <CompletedDiscipline />
};