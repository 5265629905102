import { useBreakpoint } from '@hooks/useBreakpoint';
import { useGetStudentSkillByDisciplineQuery } from '@store/api/skillsApi';
import style from '@views/student/StudentCourse/EducationalMaterials/EducationalMaterials.module.scss';
import { SkillListCard } from '@views/student/StudentCourse/SkillsList/SkillListCard';

import styles from './SkillsList.module.scss';

export function SkillsList({ uuidsDiscipline }: {uuidsDiscipline: string}) {
    const lgDown = useBreakpoint('lg', 'down');
    const { data } = useGetStudentSkillByDisciplineQuery({ uuidsDiscipline });

    return <div className={styles.list}>
        {!lgDown && <h3 className={style.title}>Компетенции</h3>}
        {data?.map((skill, index) => <SkillListCard key={skill.uuid || index} card={skill} />)}
    </div>;
}
