import React, { FC, useRef } from 'react';

import cx from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

import style from './user_menu.module.scss';

interface IUserMenu {
    className?: string;
    onClose: (isOpen: boolean) => void;
    children?: React.ReactNode;
}

const UserMenu: FC<IUserMenu> = ({ className, onClose,  children }) => {

    const ref = useRef(null);

    const outsidePClick = () => {
        onClose(false);
    };

    useOnClickOutside(ref, outsidePClick);

    return (
        <div ref={ref} className={cx(style.dropdown, className)}>
            {children}
        </div>
    );
};

export default UserMenu;
