import { DateTime } from 'ts-luxon';

import { checkRole } from '@components/RoleCheck';
import { withStamps } from '@store/api/apiTypes';
import { Role } from '@store/Roles';


export const dateConvert = (date: string, dateFormat: 'sql' | 'iso' = 'sql') => {
    const zoneName = checkRole(Role.Methodist) ? 'Europe/Moscow' : 'system';
    if (dateFormat === 'sql') {
        return DateTime.fromSQL(date, { zone: 'utc' }).setZone(zoneName).setLocale('ru');
    }
    return DateTime.fromISO(date, { zone: 'utc' }).setZone(zoneName).setLocale('ru');
};

export const datePrepareToSend = (date: string) => {
    const transformedDate = DateTime.fromSQL(date, { zone: 'Europe/Moscow' }).setZone('utc').toSQL({ includeOffset: false });
    return transformedDate ? transformedDate.slice(0, -4) : '';
};

export function sortByTime<T extends withStamps>(
    grades: Array<T>,
    key: keyof T = 'createdAt',
    by: 'ask' | 'desc' = 'desc'
): Array<T> {
    return [...grades].sort(
        (objA, objB) => {
            const aTime = new Date((objA[key] as unknown as string).replace(/ \+0000 UTC/, ''));
            const bTime = new Date((objB[key] as unknown as string).replace(/ \+0000 UTC/, ''));
            if (by === 'desc') {
                return bTime.getTime() - aTime.getTime();
            }
            return aTime.getTime() - bTime.getTime();
        }
    );
}

// Defines by difference duration
export const checkDeadline = (date: string, difference: { [key: string]: number }) => {
    return dateConvert(date.replace(/ \+0000 UTC/, '')).plus(difference).diffNow().toMillis() <= 0;
};

// Defines by deadline date
export const checkExpires = (date: string, difference: { [key: string]: number }) => {
    return dateConvert(date.replace(/ \+0000 UTC/, '')).minus(difference).diffNow().toMillis() <= 0;
};

export const getDateDiff = (date: string, dateFormat: 'sql' | 'iso' = 'sql') => {
    return dateConvert(date.replace(/ \+0000 UTC/, ''), dateFormat).diffNow().toMillis();
};
