import { FC, useEffect, useRef, useState } from 'react';

import Chart from 'chart.js/auto';

import { dateConvert } from '@helpers/date';
import { sortObjectsByField } from '@helpers/sortObjectsByField';
import { IEventLogNote } from '@type/eventsLog';

import { actionTypesInfo, IActionTypesInfo } from '../EventsLog';
import { backgroundColor, borderColor } from './barColors';

type IEventsLogBar = {
    graphNotes: IEventLogNote[];
    onBarClick: (eventTypeName: string) => void;
}

const EventsLogBar: FC<IEventsLogBar> = ({ graphNotes, onBarClick }) => {
    const [myChart, setMyChart] = useState<Chart<'line', number[], string> | null>(null);
    const chartRef = useRef<HTMLCanvasElement>(null);

    const getNotesGroupCount = (notesGroupByDate: IEventLogNote[], notesGroupType: string) => {
        return notesGroupByDate.filter(note => note.type === notesGroupType).length;
    };

    const getNotesCountsPerDate = (sortedNotesGroupsByDate: IEventLogNote[][], notesGroupType: string) => {
        return sortedNotesGroupsByDate.map(notesGroupByDate => getNotesGroupCount(notesGroupByDate, notesGroupType));
    };

    const getTypeLabels = (typeName: string, allTypes: IActionTypesInfo[]) => {
        return allTypes.find(typeInfo => typeInfo.type.value === typeName)?.type.name || '';
    };

    const prepareNotesToDraw = (sortedNotesGroupsByType: IEventLogNote[][], sortedNotesGroupsByDate: IEventLogNote[][], sortedNotesGroupsByActions: any[][]) => {

        const notesToDraw = sortedNotesGroupsByType.map(notesGroupByType => {

            return ({
                label: getTypeLabels(notesGroupByType[0].type, actionTypesInfo),
                backgroundColor: backgroundColor[notesGroupByType[0].type as keyof typeof backgroundColor],
                borderColor: borderColor[notesGroupByType[0].type as keyof typeof borderColor],
                borderWidth: 1,
                data: getNotesCountsPerDate(sortedNotesGroupsByDate, notesGroupByType[0].type),
                dataName: sortedNotesGroupsByActions
            });
        });

        return notesToDraw;
    };

    const formatDateToGraph = (date: string) => {
        return dateConvert(date.replace(/ \+0000 UTC/, '')).toFormat('dd MMM');
    };

    function clickHandler(event: any) {
        if (myChart) {
            const points = myChart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

            if (points.length) {
                const firstPoint = points[0];
                const label = myChart.data.datasets[firstPoint.datasetIndex].label;

                if (label) {
                    onBarClick(label);
                }
            }
        }
    }

    useEffect(() => {
        if (myChart) {
            myChart.destroy();
        }

        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');

            if (!ctx) return;

            const graphNotesWithFormatedDates = graphNotes.map(note => ({ ...note, createdAt: formatDateToGraph(note.createdAt) }));
            const sortedNotesGroupsByType = sortObjectsByField(graphNotesWithFormatedDates, 'type');
            const sortedNotesGroupsByDate = sortObjectsByField(graphNotesWithFormatedDates, 'createdAt').reverse();
            const sortedNotesGroupsByActions = sortObjectsByField(graphNotesWithFormatedDates, 'name');

            const data = {
                labels: sortedNotesGroupsByDate.map(notesGroups => notesGroups[0].createdAt),
                datasets: prepareNotesToDraw(sortedNotesGroupsByType, sortedNotesGroupsByDate, sortedNotesGroupsByActions)
            };

            const options = {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: false
                    },
                    y: {
                        stacked: false,
                        ticks: {
                            beginAtZero: true
                        }
                    }
                },
                elements: {
                    point: {
                        radius: 0,
                        pointHitRadius: 8
                    }
                },

                defaultFontFamily: Chart.defaults.font.family = 'Mulish',
                defaultFontSize: Chart.defaults.font.size = 12,

                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                hover: {
                    mode: 'index',
                    intersect: false
                },
                interaction: {
                    intersect: false,
                    mode: 'index'
                },
                plugins: {
                    legend: {
                        align: 'start',
                        labels: {
                            font: {
                                size: 12,
                                family: 'Mulish'
                            },
                            usePointStyle: true,
                            boxHeight: 8
                        }
                    },
                    tooltip: {
                        mode: 'index',
                        usePointStyle: true,
                        boxHeight: 6,
                        backgroundColor: 'white',
                        bodyColor: '#222626',
                        titleColor: '#222626',
                        callbacks: {
                            beforeTitle: () => {
                                return 'События';
                            }
                        }
                    }
                }
            };

            const legendMargin = {
                id: 'legendMargin',
                beforeInit(chart: any) {
                    const fitValue = chart.legend.fit;
                    chart.legend.fit = function fit() {
                        fitValue.bind(chart.legend)();
                        return this.height += 24;
                    };
                }
            };

            const hoverLine = {
                id: 'hoverLine',
                afterDatasetsDraw(chart: any) {
                    const { tooltip, chartArea: { top, bottom }, scales: { x } } = chart;
                    if (ctx && tooltip._eventPosition?.x) {
                        const xCoor = x.getPixelForValue(tooltip.dataPoints[0].dataIndex);
                        ctx.save();
                        ctx.beginPath();
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = '#555';
                        ctx.moveTo(xCoor, top);
                        ctx.lineTo(xCoor, bottom);
                        ctx.stroke();
                        ctx.closePath();
                    }
                }
            };

            const newChart = new Chart(ctx, {
                type: 'line',
                data: data,
                options: options as any,
                plugins: [legendMargin, hoverLine]
            });

            setMyChart(newChart);
        }

        return () => {
            if (myChart) {
                myChart.destroy();
            }
        };
    }, [graphNotes]);


    return <canvas ref={chartRef} onClick={clickHandler}></canvas>;
};

export default EventsLogBar;
