import React, { FC, useState, useEffect } from 'react';

import cx from 'classnames';

import Checkbox from '@components/core/inputs/Checkbox';
import Loader from '@components/core/Loader';
import { DataList, DataListBody } from '@components/DataList';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetDebtorsByGroupsQuery } from '@store/api/groupsApi';
import { useGetUsersByGroupQuery } from '@store/api/usersApi';
import { Role } from '@store/Roles';
import { Debtor } from '@type/users';

import { ISelectStudent } from './../../EditGroup';
import style from './StudentsStep.module.scss';


type IStudentsStep = {
    selectedStudents: Debtor[];
    uuidGroup: string;
    setSelectedStudents: (students: Debtor[]) => void;
}


const StudentsStep: FC<IStudentsStep> = ({ selectedStudents, setSelectedStudents, uuidGroup }) => {
    const [students, setStudents] = useState<Debtor[] | undefined>();

    const debtorsListQueryData = useGetDebtorsByGroupsQuery(uuidGroup ? { groupsUuids: [uuidGroup] } : skipToken);

    const toggleStudent = (event: React.ChangeEvent<HTMLInputElement>, debtor: Debtor) => {
        if (event.target.checked) {
            setSelectedStudents([...selectedStudents, debtor]);
        } else {
            setSelectedStudents(selectedStudents.filter(student => student.uuid !== debtor.uuid));
        }
    };

    const toggleAllStudents = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && students) {
            setSelectedStudents(students);
        } else {
            setSelectedStudents([]);
        }
    };

    useEffect(() => {
        if (debtorsListQueryData.isSuccess) {
            setStudents(debtorsListQueryData.data.map(studentUser => ({ ...studentUser, isSelect: false })));
        }
    }, [debtorsListQueryData.isFetching, debtorsListQueryData.isSuccess, debtorsListQueryData.data]);

    if (debtorsListQueryData.isFetching) return <Loader />;

    return (
        <DataList>

            <DataListBody scrolled style={{ height: 516 }}>
                <div
                    className={cx(style.student, style.check_all)}>
                    <div className={style.checkbox}>
                        <Checkbox
                            checked={!!selectedStudents.length && selectedStudents.length === students?.length}
                            onChange={toggleAllStudents}
                        />
                    </div>
                    <div className={style.select_container}>
                        Студент
                    </div>
                </div>
                {
                    students?.length
                        ?
                        students.map(student => {
                            return (
                                <div
                                    key={student.uuid}
                                    className={style.student}>
                                    <div className={style.checkbox}>
                                        <Checkbox
                                            checked={Boolean(selectedStudents.find((el) => el.uuid === student.uuid))}
                                            onChange={(e) => toggleStudent(e, student)}
                                        />
                                    </div>
                                    <div className={style.select_container}>
                                        <div className={style.student_photo}>
                                            {
                                                student.picture?.path
                                                    ?
                                                    <img src={student.picture?.path} alt="Student" />
                                                    :
                                                    <div className={style.student_photo_name}>
                                                        {`${student.firstName.charAt(0)}${student.lastName.charAt(0)}`}
                                                    </div>
                                            }
                                        </div>
                                        <div className={style.student_name}>
                                            {`${student.lastName} ${student.firstName} ${student.patronymic}`}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                        :
                        <div className={style.no_students}>Нет пользователей в группе</div>
                }
            </DataListBody>
        </DataList>
    );
};

export default StudentsStep;
