import { FC, useContext, useState } from 'react';

import { DNDWrapper } from '@components/core/DNDWrapper';
import Title from '@components/core/DNDWrapper/DNDChildren/TopicCard/Title';
import TopicCardItem from '@components/core/DNDWrapper/DNDChildren/TopicCardItem/TopicCardItem';
import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import Loader from '@components/core/Loader';
import Paper from '@components/core/styledWrappers/Paper';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { maxPosition } from '@helpers/findMaxPosition';
import { LectureTopic, PositionsItemsList } from '@store/api/apiTypes';
import {
    useCreateLectureMutation,
    useDeleteLectureTopicMutation,
    useGetLecturesByTopicQuery,
    useUpdateLecturePositionsMutation,
    useUpdateLectureTopicMutation
} from '@store/api/lecturesApi';
import { Role } from '@store/Roles';

import { CardDragItem } from '../../CardDragItem';
import { DraggableContext } from '../../DNDItem/DNDItem';
import style from './topic_card.module.scss';

interface TopicCardProps {
    topic: LectureTopic;
}

const TopicCard: FC<TopicCardProps> = ({ topic }) => {

    const lecturesList = useGetLecturesByTopicQuery(topic.uuid);

    const [deleteTopic] = useDeleteLectureTopicMutation();

    const [updateTopic] = useUpdateLectureTopicMutation();

    const [createLecture] = useCreateLectureMutation();

    const [fetchUpdateLecturePositions] = useUpdateLecturePositionsMutation();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // Достаем контекст с пропсами для драга
    const draggableHandlerProps = useContext(DraggableContext);

    const updateLecturesPositionsHandler=(reorderLecturesList: PositionsItemsList)=>{
        fetchUpdateLecturePositions({ positions: reorderLecturesList, parentUuid: topic.uuid });
    };

    const editTopicHandler = (id: string, title: string) => {
        const request = {
            uuid:id,
            name:title
        };

        updateTopic(request);
    };

    const createLectureHandler = (topicId: string) => {
        let newPosition = 1;

        if (lecturesList.isSuccess) {
            newPosition = maxPosition(lecturesList.data) + 1;
        }

        const request = {
            name: 'Новая лекция',
            position: newPosition,
            uuidTopic: topicId
        };

        createLecture(request);
    };

    return (
        <>
            <Paper className={style.paper}>
                <div className={style.item}>
                    <div className={style.card_header}>
                        <RoleCheck roles={Role.Methodist}>
                            <div className={style.drag_handler} {...draggableHandlerProps}>
                                <CardDragItem />
                            </div>
                        </RoleCheck>

                        <Title
                            title={topic.name}
                            editHandler={editTopicHandler}
                            elemId={topic.uuid}
                            renderComponent={(propsText) => <h2>{propsText}</h2>}
                            inputClassName={style.item__edit_input_title}
                            disabled={!checkRole(Role.Methodist)}
                        />

                        <RoleCheck roles={Role.Methodist}>
                            <ButtonIcon
                                className={style.delete_button}
                                typeIcon="basket"
                                onClick={() => setShowDeleteModal(true)}
                            />
                        </RoleCheck>
                    </div>
                </div>

                <div className={style.lectures_list}>
                    {
                        (!lecturesList.isLoading && lecturesList.isSuccess) ? (
                            <>
                                {checkRole(Role.Methodist) ? (
                                    <DNDWrapper items={lecturesList.data} onOrder={updateLecturesPositionsHandler}>
                                        {lecturesList.data.map((lecture) => (
                                            <TopicCardItem
                                                key={lecture.uuid}
                                                lecture={lecture}
                                            />
                                        ))}
                                    </DNDWrapper>
                                ) : (
                                    <div>
                                        {lecturesList.data.map((lecture) => (
                                            <TopicCardItem
                                                key={lecture.uuid}
                                                lecture={lecture}
                                            />
                                        ))}
                                    </div>
                                )}

                            </>
                        ) : <Loader />
                    }

                </div>
                <RoleCheck roles={Role.Methodist}>
                    <Button
                        btnType="outline"
                        typeIcon="addCircle"
                        onClick={() => createLectureHandler(topic.uuid)}
                    >
                        Добавить лекцию
                    </Button>
                </RoleCheck>
            </Paper>

            <DeleteItemModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onDelete={() => deleteTopic(topic.uuid)}
                itemName={topic.name}
                itemType={'lectureTopic'}
            />
        </>
    );
};

export default TopicCard;
