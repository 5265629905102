import { vAccess, vDeviceUuid, vRefresh  } from '@constants/localStorage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

// Define a type for the slice state
interface authState {
    isAuthenticated: boolean;
    isBlocked: boolean;
    deviceUuid: string | null;
    accessToken: string | null;
    refreshToken: string | null;
}

// Define the initial state using that type
const initialState: authState = {
    isAuthenticated: false,
    isBlocked: false,
    deviceUuid: null,
    accessToken: null,
    refreshToken: null
};

export const authSlice = createSlice({
    name: 'authSlice',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        logOut: () => {
            localStorage.removeItem(vAccess);
            localStorage.removeItem(vRefresh);
            localStorage.removeItem(vDeviceUuid);
            return initialState;
        },
        logOutAndBlock: () => {
            localStorage.removeItem(vAccess);
            localStorage.removeItem(vRefresh);
            localStorage.removeItem(vDeviceUuid);

            return { ...initialState, isBlocked: true };
        },
        logIn: (state, action: PayloadAction<{ access: string, refresh: string, duuid: string}>) => {
            state.accessToken = action.payload.access;
            state.refreshToken = action.payload.refresh;
            state.deviceUuid = action.payload.duuid;
            state.isAuthenticated = true;
            localStorage.setItem(vAccess, JSON.stringify(action.payload.access));
            localStorage.setItem(vRefresh, JSON.stringify(action.payload.refresh));
            localStorage.setItem(vDeviceUuid, JSON.stringify(action.payload.duuid));

        },
        refreshTokens: (state, action: PayloadAction<{ access: string, refresh: string}>) => {
            state.accessToken = action.payload.access;
            state.refreshToken = action.payload.refresh;
            localStorage.setItem(vAccess, JSON.stringify(action.payload.access));
            localStorage.setItem(vRefresh, JSON.stringify(action.payload.refresh));
        },
        setAccessToken: (state, { payload }: PayloadAction<string>) => {
            state.accessToken = payload;
        },
        setRefreshToken: (state, { payload }: PayloadAction<string>) => {
            state.refreshToken = payload;
        },
        setDeviceUuid: (state, { payload }: PayloadAction<string>) => {
            state.deviceUuid = payload;
        }
    }
});

export const {
    logOut,
    logOutAndBlock,
    logIn,
    refreshTokens,
    setAccessToken,
    setRefreshToken,
    setDeviceUuid
} = authSlice.actions;

export const selectAuth = (state: RootState) => state.authSlice.isAuthenticated;
export const selectBlocked = (state: RootState) => state.authSlice.isBlocked;
export const selectDeviceUuid = (state: RootState) => state.authSlice.deviceUuid;
export const selectAccessToken = (state: RootState) => state.authSlice.accessToken;
export const selectRefreshToken = (state: RootState) => state.authSlice.refreshToken;

export default authSlice.reducer;
