import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CloseTypes, SessionTypes, TSubscribeItem, TSubscribeMassValues } from '@store/api/apiTypes';

import type { RootState } from '@store/store';

interface ISubscribeState {
    selectedItems: {
        [uuid: string]: TSubscribeItem
    },
    massEditing: {
        items: {
            [uuid: string]: boolean;
        },
        itemsCount: number
        massValues: TSubscribeMassValues,
        updatePing: number,
        resetPing: number
    }
}

const initialState: ISubscribeState = {
    selectedItems: {},
    massEditing: {
        items: {},
        itemsCount: 0,
        massValues: {
            session: SessionTypes.Semester1,
            closeType: CloseTypes.Assessment,
            startDate: ''
        },
        updatePing: 0,
        resetPing: 0
    }
};

export const subscribeSlice = createSlice({
    initialState,
    name: 'subscribeSlice',
    reducers: {
        addItem: (state, action: PayloadAction<TSubscribeItem>) => {
            state.selectedItems[action.payload.uuid] = action.payload;
        },
        addDefaultItems: (state, action: PayloadAction<TSubscribeItem[]>) => {
            state.selectedItems = {};
            action.payload.forEach(item => {
                state.selectedItems[item.uuid] = item;
            });
        },
        removeItem: (state, action: PayloadAction<string>) => {
            delete state.selectedItems[action.payload];
        },
        addToMassEdit: (state, action: PayloadAction<string>) => {
            state.massEditing.items[action.payload] = true;
            state.massEditing.itemsCount = Object.keys(state.massEditing.items).length;
        },
        removeFromMassEdit: (state, action: PayloadAction<string>) => {
            delete state.massEditing.items[action.payload];
            state.massEditing.itemsCount = Object.keys(state.massEditing.items).length;
        },
        setMassValues: (state, action: PayloadAction<TSubscribeMassValues>) => {
            state.massEditing.massValues = action.payload;
            state.massEditing.updatePing = Date.now();
        },
        resetMassValues: (state) => {
            return {
                ...state,
                massEditing: {
                    ...initialState.massEditing,
                    resetPing: Date.now()
                }
            };
        }
    }
});

export default subscribeSlice.reducer;

export const selectedItemsCountSelector = (state: RootState) => state.subscribeSlice.massEditing.itemsCount;
export const selectedItems = (state: RootState) => state.subscribeSlice.selectedItems;
export const selectedItemById = (state: RootState, id: string) => state.subscribeSlice.selectedItems[id];
export const selectMassPing = (state: RootState) => state.subscribeSlice.massEditing.updatePing;
export const selectResetPing = (state: RootState) => state.subscribeSlice.massEditing.resetPing;
export const selectMassValues = (state: RootState) => state.subscribeSlice.massEditing.massValues;

export const {
    addItem,
    addDefaultItems,
    removeItem,
    addToMassEdit,
    removeFromMassEdit,
    setMassValues,
    resetMassValues
} = subscribeSlice.actions;
