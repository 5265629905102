import { FC, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import NewTreeSelectControl, { transformDataToOptions, filterOptionsByList } from '@components/core/inputs/NewTreeSelectControl';
import SwitcherControl from '@components/core/inputs/Switcher/SwitcherControl';
import Modal from '@components/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupTree } from '@store/api/apiTypes';
import style from '@views/methodist/Materials/MaterialsPage/MaterialsList/materialsList.module.scss';

import styleRoot from './group_modal.module.scss';

const schema = yup.object({
    groupName: yup.string().required(''),
    select: yup.string().required(''),
    inherit: yup.boolean()
});

export interface IModalImportForm {
    select: string;
    groupName: string;
    inherit: boolean;
}

interface IGroupModal {
    isOpen: boolean,
    onClose: () => void
    onSubmit: (formData: IModalImportForm) => void
    groupsTree?: GroupTree,
    defaultValue?: GroupTree
}

const GroupModal: FC<IGroupModal> = ({ isOpen, onClose, onSubmit, groupsTree, defaultValue }) => {
    const groupOptions = useMemo(() => {
        const allOptions = groupsTree
            ? [
                {
                    value: groupsTree.UUID,
                    label: 'Без родительской группы',
                    children: []
                },
                ...transformDataToOptions(groupsTree.childs, {
                    value: 'UUID',
                    label: 'name',
                    children: 'childs'
                })
            ]
            : [];

        if (defaultValue) {
            return filterOptionsByList(allOptions, [defaultValue.UUID], 'black');
        }
        return allOptions;
    }, [groupsTree, defaultValue]);

    const methods = useForm<IModalImportForm>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            groupName: defaultValue?.name,
            select: defaultValue ? defaultValue.parentUUID : '',
            inherit: !!defaultValue?.isInherit
        }
    });

    const { handleSubmit, control } = methods;

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title={defaultValue ? 'Редактировать группу' : 'Создать группу'}
        >
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={style.input_wrapper}>
                        <InputControl
                            placeholder={'Введите название'}
                            label={'Название*'}
                            name={'groupName'}
                            type={'text'}
                        />
                    </div>

                    <p>Родительская группа</p>

                    <NewTreeSelectControl
                        className={style.select}
                        classes={{
                            input: style['select__input'],
                            inputIcon: style['select__icon']
                        }}
                        options={groupOptions}
                        name="select"
                        placeholder="Выберите группу"
                        selectableParent
                    />

                    <div className={styleRoot['switcher-wrapper']}>
                        <SwitcherControl name="inherit" control={control} />
                        <span>
                            Дочерние группы начнут наследовать назначаемые дисциплины и их сроки сдачи
                        </span>
                    </div>

                    <div className={style.button_wrapper}>
                        <Button
                            onClick={onClose}
                            btnType={'secondary'}
                        >
                            Отмена
                        </Button>
                        <Button type={'submit'} >
                            Сохранить
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default GroupModal;
