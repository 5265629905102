/* eslint-disable no-useless-escape */
import React from 'react';

import { extractDomain, extractParameters, isALink, removeHtmlTags } from '@helpers/url';
import { IBiblioClubResponse } from '@store/api/apiTypes';
import { Role } from '@store/Roles';
import { IFindedLink, IReplaceOptions } from '@type/common';
import { User } from '@type/users';

import { SSOLink } from './SSOLink';

/***
 * Поиск библиотечных ссылок внутри строки
 */
export const findLinks = (html: string): IFindedLink[] => {
    const linkRegex = new RegExp(/(<a\b[^>]*>[\s\S]*?<\/a>)|((https?:\/\/)?([a-z0-9.-]+\.[a-z]{2,})(:[0-9]+)?(\/\S*)?)/gi);
    const foundLinks: IFindedLink[] = [];

    let match;
    while ((match = linkRegex.exec(html)) !== null) {
        const [fullMatch, protocol, domain] = match;
        const startIndex = match.index;
        const endIndex = startIndex + fullMatch.length;
        const isALinkTag = isALink(fullMatch);

        if (isALinkTag) continue;

        const valueWithoutTags = removeHtmlTags(fullMatch);
        foundLinks.push({ domain: extractDomain(domain), value: valueWithoutTags, startIndex, endIndex });
    }

    return foundLinks.filter(target => ['urait.ru', 'biblioclub.ru'].includes(target.domain));
};

/***
 * Возвращает текст в таком виде [string, jsx, string]
 *
 */
export const replaceLinksInString = ( source: string ): (React.ReactNode | string)[]  => {
    const result:  (React.ReactNode | string)[] = [];
    const links = findLinks(source);

    let lastIndex = 0;

    links.forEach((link) => {
        const { startIndex, endIndex, domain } = link;

        if (source.slice(lastIndex, startIndex)) {
            result.push(source.slice(lastIndex, startIndex));
        }

        result.push(<SSOLink domain={domain} href={link.value} value={link.value} />);
        lastIndex = endIndex + 1;
    });

    result.push(source.slice(lastIndex));

    return result;
};

/***
 * Сделать из объекта параметры для ссылки
 */
const buildUrl = (baseUrl: string, params?: { [key: string]: string | number }): string => {
    const url = new URL(baseUrl);

    if (params) {
        Object.entries(params).forEach(([key, value]) => {
            url.searchParams.append(key, String(value));
        });
    }

    return url.toString();
};
/***
 * Генерация статической ссылки для Biblioclub
 */
export const generateBiblioLink = (value: string, user: User | null, linkOptions: IBiblioClubResponse ): string => {
    const isHttpsValue = value.startsWith('http') ? value : `https://${value}`;

    if (!user) return '';

    return buildUrl('https://biblioclub.ru/index.php', {
        action: 'auth_for_org',
        user_id: user.uuid,
        domain: linkOptions.domain,
        login: user.email,
        time: linkOptions.time,
        sign: linkOptions.sign,
        ...extractParameters(isHttpsValue)
    });
};
/**
 * Заменить ссылки внутри строки библиотечные
 */
export const replaceLinksWithComponent = (options: IReplaceOptions): React.ReactNode => {
    const { links, content } = options;
    const sortedLinks = [...links].sort((a, b) => b.startIndex - a.startIndex);
    const result: React.ReactNode[] = [];

    let lastIndex = content.length;

    for (const link of sortedLinks) {
        const { startIndex, endIndex } = link;
        const linkComponent = <SSOLink href={link.value} value={link.value} domain={link.domain} />;

        result.unshift(content.substring(endIndex, lastIndex));
        result.unshift(linkComponent);
        lastIndex = startIndex;
    }

    result.unshift(content.substring(0, lastIndex));

    return <>{result}</>;
};

