import React, { useEffect, useRef, useState } from 'react';

import clsx from 'classnames';

import { BottomDrawer } from '@components/BottomDrawer';
import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import Modal from '@components/Modal';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useToggle } from '@hooks/useToggle.hook';

import styles from './FeedbackModal.module.scss';

interface IProps {
    opened: boolean;
    onOpen: () => void;
    onClose: () => void;
    data: any | null;
}

export const FeedbackModal: React.FC<IProps> = ({ opened, onOpen, onClose, data }) => {
    const [isOpen, { toggle }] = useToggle(false);
    const smDown = useBreakpoint('sm', 'down');

    const [needMoreButton, setNeedMoreButton] = useState(false);

    const descriptionRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        if (descriptionRef.current) {
            if (data && data.length > 140) {
                setNeedMoreButton(true);
            }
        }
    }, [descriptionRef.current]);
    const renderContent = () => {
        return (
            <div className={styles.container}>
                <div className={styles.preview_wrapper}>
                    {data && (
                        <>
                            <span ref={descriptionRef} className={clsx(styles.text, styles.text_offset, !isOpen && styles.text_overflow)}>
                                <CSVPreview content={data} />
                            </span>

                            {needMoreButton && (
                                <button className={styles.more} onClick={toggle}>
                                    {!isOpen ? 'Читать полный отзыв' : 'Скрыть полный отзыв'}
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>

        );
    };

    if (!smDown) {
        return (
            <Modal
                onClose={onClose}
                isOpen={opened}
                title="Отзыв к работе"
                className={styles.modal}
            >
                {renderContent()}
            </Modal>
        );
    }

    return (
        <BottomDrawer open={opened} onOpen={onOpen} onClose={onClose}>
            <h3>Отзыв к работе</h3>
            {renderContent()}
        </BottomDrawer>
    );
};
