import { FC, useEffect, useState } from 'react';

import cx from 'classnames';

import { ReactComponent as Loader } from '@assets/icons/bars-rotate-loader.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg';
import Message from '@components/Chat/parts/Message';
import IconUI from '@components/core/IconUI';
import { checkOwnMessage } from '@helpers/chat';
import { FileMessageStorage } from '@type/chat';


import DateFormat from '../DateFormat';
import MessagePhoto from '../Photo';
import MessageText from '../Text';
import style from './MessageFile.module.scss';

export interface IMessageFileProps {
    message: FileMessageStorage;
    onImageLoaded: () => void;
}

const acceptedImages = [
    'png',
    'jpeg',
    'jpg',
    'gif'
];

const MessageFile:FC<IMessageFileProps> = ({
    message,
    onImageLoaded
}) => {
    const [sendingError, setSendingError] = useState(false);

    useEffect(() => {
        if (message?.loading && message?.storageLoaded) {
            const timer = setTimeout(() => {
                setSendingError(true);
            }, 1000 * 60);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [message?.loading, message?.storageLoaded]);

    useEffect(() => {
        if (message?.storageError) {
            setSendingError(true);
        }
    }, [message?.storageError]);

    const file = message?.files?.[0];

    const isOwn = checkOwnMessage(message);

    if (!message.text && acceptedImages.includes(file?.extension ?? '')) {
        return <MessagePhoto
            file={file}
            time={message.createdAt}
            own={isOwn}
            loading={Boolean(message.loading)}
            error={sendingError}
            onImageLoaded={onImageLoaded}
        />;
    }

    const disabled = message.loading || sendingError;

    const handleClick = () => {
        if (!disabled && file?.path) {
            window.open(file.path);
        }
    };

    const getFileSize = (): number => {
        if (!file?.size) return 0;
        return Math.ceil(file.size / 1024);
    };

    return (
        <Message own={isOwn}>
            <div className={style.wrapper}>
                <div className={style.container}>
                    <div
                        className={cx(style.iconContainer, {
                            [style.pointer]: !disabled
                        })}
                        onClick={handleClick}
                    >
                        <div className={style['icon-wrapper']}>
                            {!disabled && <IconUI typeIcon='download' />}
                            {message?.loading && !sendingError && <Loader className={style.loader} />}
                            {sendingError && <CloseIcon className={style['error-icon']} />}
                        </div>
                    </div>

                    <div className={style.content}>
                        <div className={style.title}>{file?.title}</div>
                        <div className={style.metaContainer}>
                            <div className={cx(style.size, sendingError && style.error)}>
                                {sendingError ? 'Ошибка отправки' : `${getFileSize()} KB`} </div>
                            {!message.text && <DateFormat time={message.createdAt} />}
                        </div>
                    </div>
                </div>
                {message.text && (
                    <MessageText own={isOwn} time={message.createdAt} className={style['text-message']}>
                        {message.text}
                    </MessageText>
                )}
            </div>
        </Message>
    );
};

export default MessageFile;
