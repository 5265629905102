// @ts-nocheck
// Есть проблемы с типами у html-react-parser они вообще не соотвествуют декларации

import React, { FC, useMemo } from 'react';

import parse, { HTMLReactParserOptions, domToReact } from 'html-react-parser';

import { extractDomain, isATagDOMNode, isListDOMNode, isSSOLink, isTextDOMNode } from '@helpers/url';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';

import { listStyleTypes } from './constants';
import { findLinks, replaceLinksWithComponent } from './helper';
import { SSOLink } from './SSOLink';

export interface ILinkfiyProps {
    content: string;
}

export const Linkify: FC<ILinkfiyProps> = ({
    content
}) => {

    const user = useAppSelector(selectApiUser);

    // Конфиг для html-react-parser
    const config: HTMLReactParserOptions = {
        replace({ name, attribs, children, type, data }) {
            if (isListDOMNode(name)) {
                if (attribs.type in listStyleTypes) {
                    attribs.style = {
                        ...attribs.style,
                        listStyleType: listStyleTypes[attribs.type]
                    };
                    delete attribs.type;
                    return <ol {...attribs}>{domToReact(children, config)}</ol>;
                }
            }
            // Если мы находим тег <a> ссылку, то мы можем работать с ним как с DomNode.
            if (isATagDOMNode(name)) {
                // Заменяем своим компонентом <a> тег, если это ссылка на библиотеку
                if (isSSOLink(extractDomain(attribs.href))) {
                    return <SSOLink href={attribs.href} value={children[0].data} user={user} domain={extractDomain(attribs.href)} />;
                }
            }

            // Если у нас отсутствуют HTML-теги, и мы работаем с текстом, то вручную ищем чистые ссылки в нем
            // и заменяем на SSOLink
            if (isTextDOMNode(type)) {
                const links = findLinks(data);

                return replaceLinksWithComponent({
                    links,
                    content: data,
                    user
                });
            }
        }
    };

    const parsedText = useMemo(() => parse(content, config), [content]);

    return <>{parsedText}</>;
};
