import { Column, Grid } from '@components/core/grid';
import { DataList } from '@components/DataList';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ResponseMessage, SkillDiscipline } from '@store/api/apiTypes';

import styles from './DisciplinesTable.module.scss';
import DisciplinesTableRow from './DisciplinesTableRow';

const DisciplinesTable = ({ disciplines }:{disciplines:ResponseMessage<SkillDiscipline>[]}) => {

    const [animationParent] = useAutoAnimate();
    
    return (
        <>
            <DataList className={styles.listContainer} >

                <Grid gridTemplateColumns="1fr 1fr" className={styles.header}>
                    <Column>
                    Название
                    </Column>
                    <Column>
                    Семестр
                    </Column>
                </Grid>

                <div ref={animationParent}>
                    {disciplines.map(discipline => 
                        <DisciplinesTableRow discipline={discipline} key={discipline.uuid}  />
                    )}
                </div>
            </DataList>
        </>
    );
};

export default DisciplinesTable;