import  { FC,  useState } from 'react';

import cx from 'classnames';
import Collapse, { Panel } from 'rc-collapse';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import { FileUploadButtonControl } from '@components/core/inputs/FileUpload/FileUploadButtonControl';
import { TUploadFile } from '@components/core/inputs/FileUpload/type';
import { TextArea } from '@components/core/inputs/TextArea';
import Paper from '@components/core/styledWrappers/Paper';
import { checkRole } from '@components/RoleCheck';
import { getBase64 } from '@helpers/base64';
import { isStorageFile } from '@helpers/typeCheck';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AuthenticationPayload, fileTypes, IStorageFile } from '@store/api/apiTypes';
import { useUploadFileMutation } from '@store/api/storageApi';
import { Role } from '@store/Roles';
import { User }  from '@type/users';

import style from './AnswerForm.module.scss';
import Notification from './Notification/Notification';

const taskAnswerFormSchema = yup.object({
    file: yup.mixed().nullable().required('Прикрепите файл!')
});

export type TaskAnswerForm = yup.InferType<typeof taskAnswerFormSchema>;
export interface IAnswerForm {
    userAccount: User | null;
    authenticationData: AuthenticationPayload | undefined;
    isRetake?: boolean;
    loading?: boolean
}

const AnswerForm: FC<IAnswerForm> = ({
    userAccount,
    authenticationData,
    isRetake = false,
    loading
}) => {
    const [isActivePanel, setIsActivePanel] = useState(false);

    const navigate = useNavigate();
    const { uuidDiscipline, uuidTask } = useParams();

    const location = useLocation();


    const [uploadFile, { isLoading }] = useUploadFileMutation();

    const { handleSubmit, control } = useForm<TaskAnswerForm>({
        resolver: yupResolver(taskAnswerFormSchema),
        mode: 'onBlur',
        defaultValues: {
            file: (location.state as any)?.file as File || null
        }
    });

    const uploadFileHandler = async (file: IStorageFile | TUploadFile) => {

        const uuidsFile: string[] = [];

        if (file) {
            if (!isStorageFile(file)) {
                const b64 = await getBase64(file);
                const uploadedFile = await uploadFile({
                    file: b64 as string,
                    meta: {
                        filename: file.name,
                        type: fileTypes.Public
                    }
                }).unwrap();
                uuidsFile.push(uploadedFile.uuid);
            } else {
                uuidsFile.push(file.uuid);
            }
        }

        return uuidsFile;
    };

    const isDisabled = loading || isLoading || !checkRole(Role.Student);

    const onSubmitWrapper = async (formData: TaskAnswerForm) => {
        if (isDisabled) return;

        if (!authenticationData) {

            const uuids = await uploadFileHandler(formData.file);
            const savesFile = formData.file;
            delete formData.file;

            const storageData = { ...formData, meta: {
                uuidDiscipline: uuidDiscipline || '',
                uuidMaterial: uuidTask || '',
                uuidUser: userAccount?.uuid || '',
                uuidFiles: uuids
            } };

            window.localStorage.setItem('KP_FORMDATA', JSON.stringify(storageData));
            navigate('auth-persona', { state: { file: savesFile } });
        }
    };

    return (
        <Paper fullWidth={true} className={style.root}>
            {
                isRetake && <Notification />
            }
            <form onSubmit={handleSubmit(onSubmitWrapper)}>
                <div className={style.info}>
                    <p>Обратите внимание на требование к формату загружаемого на проверку файла.</p>
                    <p>Размер файла максимум: 20 Мб.</p>
                </div>
                <div className={style.content}>
                    <FileUploadButtonControl
                        control={control}
                        name="file"
                        isMulti={false}
                        caption={false}
                        accessTypes={[
                            'application/pdf',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/msword'
                        ]}
                        className={style.upload}
                        disabled={isDisabled}
                    />
                    <Collapse accordion={false} onChange={() => setIsActivePanel(!isActivePanel)} className={style.collapse}>
                        <Panel
                            header="Добавить комментарий"
                            headerClass={style.collapse_panel__header}
                            showArrow={true}
                            expandIcon={() => (
                                <IconUI typeIcon="arrow"
                                    className={cx(style.collapse_panel__icon, isActivePanel && style['collapse_panel__icon--active'])} />
                            )}
                            className={style.collapse_panel}
                        >
                            <TextArea control={control} name="textAreaField" />
                        </Panel>
                    </Collapse>

                    <Button type="submit" className={style.submit} disabled={isDisabled || !checkRole(Role.Student)}>
                        Отправить на проверку
                    </Button>
                </div>
            </form>
        </Paper>
    );
};

export default AnswerForm;
