import { FC } from 'react';

import classNames from 'classnames/bind';

import { ReactComponent as StapleSVG } from '@assets/icons/staple.svg';
import { ReactComponent as UploadSVG } from '@assets/icons/upload.svg';
import { IPlaceholderBackendData, IRenderUpload } from '@components/core/inputs/FileUpload/type';
import { isStorageFile } from '@helpers/typeCheck';

import style from './filebuttonstage.module.scss';

const cx = classNames.bind(style);

interface IFileAreaStage {
    api: IPlaceholderBackendData | null;
}

export const FileButtonStage: FC<IFileAreaStage> = ({ api }) => {
    const fileRenderStage: IRenderUpload = {
        default: (
            <div className={cx('root__default')}>
                <span className={cx('root__default-icon')}>
                    <UploadSVG />
                </span>

                <span className={cx('root__default-label')}>Выберите файл</span>
            </div>
        ),
        uploading: (
            <>
                <div className={cx('root__icon-btn')}>
                    <StapleSVG />
                </div>

                <div className={cx('root__content')}>
                    <div className={cx('root__caption')}>
                        {
                            isStorageFile(api?.file) ? api?.file.title : api?.file?.name
                        }
                    </div>
                </div>

                <div className={cx('root__actions')}>
                    <div className={cx('root__accept')}></div>
                </div>
            </>
        )
    };

    const renderStage = () => (api?.stage ? fileRenderStage[api.stage] : null);

    return <div className={cx('root')}> {renderStage()} </div>;
};
