import React, { FC } from 'react';

import classNames from 'classnames/bind';

import { useIdHook } from '@hooks/useId.hook';

import style from './switcher.module.scss';

const cx = classNames.bind(style);

interface IProps {
    name?: string;
    value?: boolean;
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Switcher: FC<IProps> = ({ name, value, onChange, disabled }) => {
    const id = useIdHook('switch-');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!disabled && onChange) {
            onChange(event);
        }
    };

    return (
        <div className={cx('root', { ['disabled']: disabled })}>
            <input
                disabled={disabled}
                name={name}
                onChange={handleChange}
                className={cx('root__input')}
                type="checkbox"
                checked={value}
                id={id}
            />
            <label className={cx('root__label')} htmlFor={id}>
                &nbps;
            </label>
        </div>
    );
};

export default Switcher;
