import React from 'react';

import { LibButton } from '@components/core/LibButton/LibButton';
import Header from '@components/Header';
import Logo from '@components/Logo';
import MethodistMenu from '@components/methodist/MethodistMenu';

const MethodistHeader = () => {
    return (
        <Header>
            <Logo />
            {/* <LibButton /> */}
            <MethodistMenu />
        </Header>
    );
};

export default MethodistHeader;
