import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import Main from '@components/Main';
import RouterTabs from '@components/RouterTabs/RouterTabs';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetTestByIdQuery, useUpdateTestMutation } from '@store/api/testsApi';
import { useTestContext } from '@views/methodist/Materials/Course/Test/EditTest/EditTest';

import style from './edit_test_layout.module.scss';

const tabs = [
    { name: 'Основное', path: '' },
    { name: 'Содержание', path: 'content' },
    { name: 'Банк вопросов', path: 'questions' }
];

const CommonLayout = () => {
    const navigate = useNavigate();

    const { uuidTest } = useParams();

    const { testName } = useGetTestByIdQuery(uuidTest ? uuidTest : skipToken, {
        selectFromResult: ({ data: testData }) => ({
            testName: testData?.test.name ?? 'Редактирование теста'
        })
    });

    return (
        <>
            <div className={style.title_wrapper}>
                <ButtonIcon
                    typeIcon="arrowLink"
                    onClick={() => navigate('./../../../')}
                />
                <h1>{testName}</h1>
            </div>

            <RouterTabs tabs={tabs} />
        </>
    );
};

const EditTestLayout = () => {
    return (
        <Main>
            <CommonLayout />

            <Outlet />
        </Main>
    );
};

const EditTestLayoutWithContext = () => {
    const testContext = useTestContext();

    return (
        <Main>
            <CommonLayout />

            <Outlet context={ testContext } />
        </Main>
    );
};


export { EditTestLayout, EditTestLayoutWithContext };
