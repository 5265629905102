import { useMemo } from 'react';

import { useOutletContext, useParams } from 'react-router-dom';

import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import Main from '@components/Main';
import GoBackLink from '@components/student/GoBackLink';
import { capitalizeFirstLetter } from '@helpers/text';
import { IProgrammContext } from '@layouts/Methodist/StudyProgrammesLayout/StudyProgrammesLayout';
import { EnumMaterialsType, Page } from '@store/api/apiTypes';

import style from './StudentStudyProgrammPage.module.scss';

const StudentStudyProgrammPage = () => {
    const { uuidPage } = useParams();
    const { programnInfo: { sections } } = useOutletContext<IProgrammContext>();

    const actualPage = useMemo(() => {
        const actualSection = sections.find(section => section.materials.find(material => material.uuid === uuidPage));
        return actualSection?.materials.find(material => material.uuid === uuidPage && capitalizeFirstLetter(material.type) === EnumMaterialsType.Page);
    }, [sections, uuidPage]) as Page;

    return (
        <Main className={style.main} title={actualPage && `Обучение – ${actualPage.name}`}>
            <GoBackLink to='./../..'>
                Назад
            </GoBackLink>
            <h1 className={style.main_title}>{actualPage?.name}</h1>
            <CSVPreview content={actualPage?.content} />
        </Main>
    );
};

export default StudentStudyProgrammPage;
