import React, { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import Checkbox from '@components/core/inputs/Checkbox';
import NewSelect from '@components/core/inputs/Select/NewSelect/NewSelect';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { MassEdit } from '@components/MassEdit';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { getDisciplinesExtraSession, getExtraSessionsByUsers, getUsersWithExtraSession } from '@helpers/extraSession';
import { useToggle } from '@hooks/useToggle.hook';
import { skipToken } from '@reduxjs/toolkit/query';
import { SessionTypes, SessionTypesWithLocale } from '@store/api/apiTypes';
import { useGetDisciplinesAllQuery } from '@store/api/disciplinesApi';
import {
    useGetExtraSessionsDeadlinesByGroupsQuery,
    useDeleteExtraSessionMutation,
    useUpdateExtraSessionMutation
} from '@store/api/groupsApi';
import { useGetUsersByGroupQuery } from '@store/api/usersApi';
import { DisciplineWithExtraSession } from '@type/extraSession';
import { UpdateExtraSession } from '@type/groups';
import { EditDeadlinesModal } from '@views/methodist/Users/ExtraSession/EditDeadlinesModal';

import styles from './ExtraSession.module.scss';
import { ExtraSessionCard } from './ExtraSessionCard';


const semestersOptions = Object.values(SessionTypes).map((semester) => ({
    value: semester,
    label: SessionTypesWithLocale[semester]
}));

export const ExtraSession = () => {
    const { uuidGroup } = useParams();
    const navigate = useNavigate();

    const goBack = () => {
        navigate(`/users/groups/${uuidGroup}/editGroup`);
    };

    const [isOpen, { on, off }] = useToggle();

    // STATE
    const [filterStudents, setFilterStudents] = useState<string[]>([]);
    const [filterDisciplines, setFilterDisciplines] = useState<string[]>([]);
    const [filterSemesters, setFilterSemesters] = useState<string[]>([]);
    const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
    const [currentDisciplineForDelete, setCurrentDisciplineForDelete] = useState<string | null>(null);
    const [disciplineForEdit, setDisciplineForEdit] = useState<DisciplineWithExtraSession | null>(null);

    // API
    const disciplinesQueryData = useGetDisciplinesAllQuery({});
    const extraSessionDeadlinesQueryData = useGetExtraSessionsDeadlinesByGroupsQuery(uuidGroup || skipToken);
    const usersListQueryData = useGetUsersByGroupQuery(uuidGroup || skipToken);

    const [deleteExtraSession, { isLoading: loadingDelete }] = useDeleteExtraSessionMutation();
    const [updateExtraSession] = useUpdateExtraSessionMutation();

    const isFetching = disciplinesQueryData.isFetching || extraSessionDeadlinesQueryData.isFetching || usersListQueryData.isFetching;

    const extraSessionsByUsers = useMemo(() => {
        if (extraSessionDeadlinesQueryData.data && usersListQueryData.data && disciplinesQueryData.data) {
            return getExtraSessionsByUsers(extraSessionDeadlinesQueryData.data, usersListQueryData.data, disciplinesQueryData.data);
        }
        return [];
    }, [disciplinesQueryData.data, extraSessionDeadlinesQueryData.data, usersListQueryData.data]);

    const studentsOptions = useMemo(() => {
        return getUsersWithExtraSession(extraSessionsByUsers).map(user => ({
            value: user.uuid,
            label: `${user.lastName} ${user.firstName} ${user.patronymic}`
        }));
    }, [extraSessionsByUsers]);

    const disciplinesOptions = useMemo(() => {
        return getDisciplinesExtraSession(extraSessionsByUsers).map(discipline => ({
            value: discipline.uuid,
            label: discipline.name
        }));
    }, [extraSessionsByUsers]);

    const filteredExtraSessionsByUsers = useMemo(() => {
        const filteredUsers = extraSessionsByUsers.filter(el => {
            if (!filterStudents.length) return true;
            return filterStudents.includes(el.userUuid);
        }).map(user => ({
            ...user,
            disciplines: user.disciplines.filter(discipline => {
                let need = true;
                if (filterDisciplines.length && !filterDisciplines.includes(discipline.uuid)) {
                    need = false;
                }
                if (filterSemesters.length && !filterSemesters.includes(discipline.semester)) {
                    need = false;
                }
                return need;
            })
        }));

        return filteredUsers.filter(el => el.disciplines.length);
    }, [extraSessionsByUsers, filterStudents, filterDisciplines, filterSemesters]);

    useEffect(() => {
        setSelectedStudents([]);
    }, [filterStudents, filterDisciplines, filterSemesters]);

    const handleChangeAll = () => {
        if (selectedStudents.length === filteredExtraSessionsByUsers.length) {
            setSelectedStudents([]);
        } else {
            setSelectedStudents(filteredExtraSessionsByUsers.map(({ userUuid }) => userUuid));
        }
    };

    const handleSingleDelete = async () => {
        const currentDiscipline = getDisciplinesExtraSession(extraSessionsByUsers).find(el => el.uuid === currentDisciplineForDelete);
        if (currentDiscipline) {
            const { extraSessions } = currentDiscipline;

            await deleteExtraSession(extraSessions);
            setCurrentDisciplineForDelete(null);
        }
    };

    const handleMassDelete = async () => {
        const currentUsers = extraSessionsByUsers.filter(el => selectedStudents.includes(el.userUuid));

        let extraSessionIds: string[] = [];

        currentUsers.forEach(userExtraSession => {
            userExtraSession.disciplines.forEach(discipline => {
                extraSessionIds = [...extraSessionIds, ...discipline.extraSessions];
            });
        });

        await deleteExtraSession(extraSessionIds);
        setSelectedStudents([]);
        off();
    };

    const handleEdit = async (data: UpdateExtraSession) => {
        await updateExtraSession(data);
    };

    if (isFetching) return <NewLoader />;

    return (
        <Main>
            <div className={styles.title_navigate}>
                <ButtonIcon
                    typeIcon="arrowLink"
                    onClick={goBack}
                />
                <h1>
                    Дополнительная сессия
                </h1>
            </div>
            <div className={styles.container}>
                <div className={styles.filters}>
                    <NewSelect
                        options={studentsOptions}
                        placeholder="Выберите студента"
                        isMulti
                        value={filterStudents}
                        onChange={setFilterStudents}
                        className={cx(styles.select, styles.select_margin)}
                        classes={{ input: styles.select_input, inputIcon: styles.select_icon }}
                        dropdownWidth={400}
                        renderLabel={(value) => value.length === 1 ? value[0].label : `Выбрано студентов: ${value.length}`}
                    />
                    <NewSelect
                        options={disciplinesOptions}
                        placeholder="Выберите дисциплину"
                        isMulti
                        value={filterDisciplines}
                        onChange={setFilterDisciplines}
                        className={cx(styles.select, styles.select_margin)}
                        classes={{ input: styles.select_input, inputIcon: styles.select_icon }}
                        dropdownWidth={400}
                        renderLabel={(value) => value.length === 1 ? value[0].label : `Выбрано дисциплин: ${value.length}`}
                    />
                    <NewSelect
                        options={semestersOptions}
                        placeholder="Выберите семестр"
                        isMulti
                        value={filterSemesters}
                        onChange={setFilterSemesters}
                        className={styles.select}
                        classes={{ input: styles.select_input, inputIcon: styles.select_icon }}
                        dropdownWidth={400}
                        renderLabel={(value) => value.length === 1 ? value[0].label : `Выбрано семестров: ${value.length}`}
                    />
                </div>

                {filteredExtraSessionsByUsers.length > 0 ? (
                    <>
                        <div className={styles.header}>
                            <div className={styles.checkbox_wrapper}>
                                <Checkbox
                                    containerClassName={styles.checkbox}
                                    checked={selectedStudents.length === filteredExtraSessionsByUsers.length}
                                    onChange={handleChangeAll}
                                />
                                <span>Студент</span>
                            </div>
                            <div>Семестр</div>
                            <div>Срок сдачи дисциплины</div>
                        </div>
                        {filteredExtraSessionsByUsers.map((userExtraSession, index) => (
                            <ExtraSessionCard
                                key={userExtraSession.userUuid}
                                className={cx({ [styles.card]: index !== extraSessionsByUsers.length - 1 })}
                                user={userExtraSession.user}
                                disciplines={userExtraSession.disciplines}
                                selectedStudent={selectedStudents}
                                onSelect={setSelectedStudents}
                                onDelete={setCurrentDisciplineForDelete}
                                onEdit={setDisciplineForEdit}
                            />
                        ))}
                    </>
                ) : (
                    <div className={styles.empty}>
                        Ничего не найдено по заданным параметрам
                    </div>
                )}

                <MassEdit onSelectAll={() => setSelectedStudents([])} selectCount={selectedStudents.length} isShow={!!selectedStudents.length}>
                    <div className={styles.mass_container}>
                        <div className={styles.action_btn}>
                            <Button btnType="dark" size="small" onClick={on}>
                                Удалить доп. сессии
                            </Button>
                        </div>
                    </div>
                </MassEdit>

                <DeleteItemModal
                    isOpen={Boolean(currentDisciplineForDelete)}
                    onClose={() => setCurrentDisciplineForDelete(null)}
                    onDelete={handleSingleDelete}
                    itemType="extraSessionItem"
                    description="Данное действие нельзя будет отменить"
                    loading={loadingDelete}
                />

                <DeleteItemModal
                    isOpen={isOpen}
                    onClose={off}
                    onDelete={handleMassDelete}
                    itemType="extraSession"
                    description="Данное действие нельзя будет отменить"
                    loading={loadingDelete}
                />

                <EditDeadlinesModal onClose={() => setDisciplineForEdit(null)} discipline={disciplineForEdit} onEdit={handleEdit} />
            </div>
        </Main>
    );
};
