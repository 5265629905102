import { CloseTypes, CloseTypesWithLocale, IAntiplagiat, SessionTypes, Uuid } from '@store/api/apiTypes';

export interface ScoreCardResponse {
    studentUUID: string;
}

export interface IScoreMaterials extends IAntiplagiat  {
    materialUUID: Uuid;
    materialName: string;
    grade: number;
    teacherReview?: string;
}

export interface ScoreDisciplines {
    disciplineName: string;
    disciplineStartDate: string;
    disciplineEndDate: string;
    grade: number;
    closeType: CloseTypes;
    disciplineUUID: Uuid;
    materials: IScoreMaterials[];
    hasNotEstimatedAttempts: boolean;
}

export interface ScoreSession {
    session: SessionTypes;
    disciplines: ScoreDisciplines[];
}

export interface ScoreCardResult {
    payload: ScoreSession[];
}

export enum ScoreStatusDiscipline {
    'active' = 'active',
    'finished' = 'finished',
    'debt' = 'debt'
}

export interface FilledDisciplines extends ScoreDisciplines {
    status: ScoreStatusDiscipline;
    formatControl: CloseTypesWithLocale;
    dateInterval: string;
}

/**
 * @example
 * [
 *     {
 *         "session": "Semester5",
 *         "disciplines": [
 *             {
 *                 "disciplineName": "Преддипломная практика",
 *                 "disciplineStartDate": "2022-09-14 21:00:00",
 *                 "disciplineEndDate": "2022-12-08 23:59:00",
 *                 "grade": 0,
 *                 "closeType": "Assessment",
 *                 "status": "active"
 *             },
 *         ]
 *     }
 * ]
 */
export interface FilledScoreCard {
    session: SessionTypes;
    disciplines: FilledDisciplines[];
}

export type DisciplineScoreItem = {
    discipline: string;
    formatControl: CloseTypesWithLocale;
    grade: number;
    dateInterval: string;
    status: ScoreStatusDiscipline;
    default: FilledDisciplines
}

export interface ExportedScoreCard {
    uuid: Uuid;
    path: string;
    title: string;
    extension: string;
    size: number;
    userUuid: Uuid;
    createdAt: string;
    updatedAt: string;
    type: string;
}
