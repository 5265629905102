import React, { FC, useEffect, useRef, useState } from 'react';
import { CSSProperties } from 'react';

import { format, parse, formatISO9075 } from 'date-fns';

import Button from '@components/core/inputs/Button';
import InputBase from '@components/core/inputs/InputBase';
import Popover from '@mui/material/Popover';

import { DatePicker } from '../DatePicker';
import style from './DPRangeControl.module.scss';

export interface IRangeDates {
    startDate: string;
    endDate: string;
}

export interface IDPRangeControlProps {
    variant?: 'default' | 'cancel' | 'search' | 'date' ;
    defaultStartDate?: string;
    defaultEndDate?: string;
    onSubmit: (dates: IRangeDates) => void;
    isButton?: boolean;
    element?: React.ReactNode;
    style?: CSSProperties
    caption?: string;
    placeholder?: string;
    disabled?: boolean;
}

export const DPRangeControl: FC<IDPRangeControlProps> = ({
    variant,
    onSubmit,
    defaultStartDate,
    defaultEndDate,
    placeholder = 'Выбрать дату',
    caption,
    isButton,
    ...props
}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | HTMLButtonElement | null>(null);
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();

    const time = '00:00:00';
    const endTime = '23:59:59';
    const refContainer = useRef(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = () => {
        setAnchorEl(refContainer.current);
    };

    const handleClose = () => {

        setAnchorEl(null);

        if (!startDate || !endDate) return;
        const tmpStartDate = format(startDate, 'dd.MM.yyyy');
        const tmpEndDate = format(endDate, 'dd.MM.yyyy');

        const enchatedSelectedDate = {
            startDate: formatISO9075(parse(tmpStartDate  + ' ' + time, 'dd.MM.yyyy HH:mm:ss', new Date())),
            endDate: formatISO9075(parse(tmpEndDate  + ' ' + endTime, 'dd.MM.yyyy HH:mm:ss', new Date()))
        };

        onSubmit(enchatedSelectedDate);
    };

    const updateInput = () => {
        if (startDate && endDate) setInputValue(format(startDate, 'dd.MM.yyyy') + ' - ' + format(endDate, 'dd.MM.yyyy'));
    };

    const onSetup = () => {
        updateInput();
        handleClose();
    };

    const onClearDates = () => {
        setStartDate(undefined);
        setEndDate(undefined);

        setInputValue('');
        onSubmit({
            startDate: '',
            endDate: ''
        });
    };

    useEffect(() => {
        if (startDate && endDate) {
            updateInput();
        }

    }, [startDate, endDate]);

    useEffect(() => {
        if (defaultStartDate && defaultEndDate) {
            const tmpStartDate = new Date(defaultStartDate);
            const tmpEndDate = new Date(defaultEndDate);

            setStartDate(tmpStartDate);
            setEndDate(tmpEndDate);
        }

    }, [defaultStartDate, defaultEndDate]);

    const controlProps = {
        ...props,
        name: 'control-date',
        className: style.cursor,
        placeholder,
        variant,
        readOnly: true,
        value: !isButton ? inputValue : '',
        onClick: handleClick
    };

    return (<div className={style.container} ref={refContainer}>
        {
            !isButton ? (
                <InputBase { ...controlProps } />
            ) : (
                <Button onClick={handleClick} btnType="dark" size='small' typeIcon='calendar-light' >
                    { startDate && endDate ? format(startDate, 'dd.MM.yyyy') + ' - ' + format(endDate, 'dd.MM.yyyy') : caption}
                </Button>
            )
        }

        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            style={{ top: 4 }}
        >
            { <DatePicker
                mode="range"

                startDate={startDate}
                setStart={setStartDate}

                endDate={endDate}
                setEnd={setEndDate}

                onSelectDate={updateInput}
                onSubmitRange={onSetup}
                onClearDates={onClearDates} /> }

        </Popover>
    </div>);
};
