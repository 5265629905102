import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import './Toast.scss';

import { ReactComponent as SuccessSVG } from '@assets/icons/check-white.svg';
import { ReactComponent as CloseSVG } from '@assets/icons/close-toast.svg';
import { ReactComponent as ErrorSVG } from '@assets/icons/confirm-error-icon-white.svg';

import Loader from '../Loader';


const Toast = () => {
    return (
        <ToastContainer
            className={'container'}
            closeOnClick={false}
            position={'bottom-center'} 
            autoClose={2000}
            icon={({ type }) => {
                switch (type) {
                    case 'default':
                        return <Loader />;
                    case 'success':
                        return <SuccessSVG />;
                    case 'error':
                        return <ErrorSVG />;
                }
            }}
            closeButton={({ closeToast }) => <div className='close-button' role="presentation" onClick={closeToast}><CloseSVG  /></div>}
        />
    );
};


export default Toast;
