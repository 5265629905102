import { FC, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Role } from '@store/Roles';
import { CreateLinkFormData } from '@views/methodist/Materials/Course/CreateLinkForm/type';

import style from './createLinkForm.module.scss';

interface ILinkModal {
    defaultValues: CreateLinkFormData
    onSubmitFormHandler: (formData: CreateLinkFormData) => void
    onCloseModal: (isOpen: boolean) => void;
}

export const formSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    link: yup.string().required('Поле обязательно для заполнения!')
});

const CreateLinkForm: FC<ILinkModal> = ({ defaultValues, onSubmitFormHandler, onCloseModal }) => {
    const { name, link } = defaultValues;
    const methods = useForm<CreateLinkFormData>({
        resolver: yupResolver(formSchema),
        mode: 'onBlur',
        defaultValues
    });

    const { handleSubmit, setValue } = methods;

    useEffect(() => {
        setValue('name', name);
        setValue('link', link);
    }, [defaultValues]);

    const onSubmit = async (formData: CreateLinkFormData) => {
        onSubmitFormHandler(formData);
    };

    return (
        <form>
            <FormProvider {...methods}>
                <div className={style.input_wrapper}>
                    <InputControl
                        label={'Заголовок'}
                        name={'name'}
                        placeholder={'Заголовок'}
                        disabled={!checkRole(Role.Methodist)}
                    />
                    <InputControl
                        label={'Ссылка'}
                        name={'link'}
                        placeholder={'Ссылка'}
                        disabled={!checkRole(Role.Methodist)}
                    />
                </div>
                <RoleCheck roles={Role.Methodist}>
                    <div className={style.button_wrapper}>
                        <Button
                            btnType={'secondary'}
                            onClick={() => onCloseModal(false)}
                        >
                            Отмена
                        </Button>
                        <Button onClick={handleSubmit(onSubmit)}>Сохранить</Button>
                    </div>
                </RoleCheck>
            </FormProvider>
        </form>
    );
};

export default CreateLinkForm;
