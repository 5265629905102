import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import ButtonDrop from '@components/core/inputs/Button/ButtonDrop';
import { DropMenuItem } from '@components/core/styledWrappers/DropMenu';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { TestAddQuestionsRequest, TestGetQuestionsFromBankForQuestion, TestSetRandomQuestions } from '@store/api/apiTypes';
import {
    useAddTestQuestionsMutation,
    useGetQuestionsFromBankForQuestionQuery,
    useSetTestRandomQuestionsMutation
} from '@store/api/testsApi';
import { Role } from '@store/Roles';
import { TOnSelectType } from '@type/table';
import { useTestContext } from '@views/methodist/Materials/Course/Test/EditTest/EditTest';

import { QuestionTable } from '../Questions/QuestionTable';
import { ManualQuestionModal } from '../TestModals';
import { RandomQuestionModal } from '../TestModals/RandomQuestionModal';
import { TestQuestionWithName } from '../types';
import style from './content.module.scss';

const Content = () => {
    const { uuidTest } = useParams();
    const { testContext } = useTestContext();
    const [manualModal, setManualModal] = useState(false);
    const [randomModal, setRandomModal] = useState(false);
    const [questionsArray, setQuestions] = useState<TestQuestionWithName[]>([]);

    const [ addQuestions ] = useAddTestQuestionsMutation();

    const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
    const [setRandomQuestions] = useSetTestRandomQuestionsMutation();

    const questionsReq: TestGetQuestionsFromBankForQuestion = {
        filters: {
            limit: 0,
            offset: 0,
            search: ''
        },
        uuid: uuidTest!,
        questionId: testContext.test.uuid
    };

    const { data: questionsBankData, isFetching } = useGetQuestionsFromBankForQuestionQuery(questionsReq);
    const isEmptyBank = !questionsBankData?.length && !isFetching;
    const showManualModal = () => {
        setManualModal(true);
    };

    const showRandomModal = () => {
        setRandomModal(true);
    };

    const handleAddQuestions = (questions:string[]) => {

        let questions_data:string[] = [];

        testContext.questions.map(q=>{
            questions_data.push(q.question.uuid);
        });

        questions_data = questions_data.concat(questions);

        const addData:TestAddQuestionsRequest={
            testUuid:testContext.test.uuid,
            questionsUuids:questions_data
        };

        addQuestions(addData);
    };

    const handleSelectQuestions = (selectObject: TOnSelectType) => {

        const selected_local: string[] = [];
        
        selectObject.selectedUuids.map(item => {
            if(item != null) // VEIP-1288
                selected_local.push(item);
        });

        setSelectedQuestions(selected_local);
    };

    const handleDeleteQuestions = (uuid?: string) => {
        let questions_data:string[] = [];

        if (typeof uuid === 'string') {
            testContext.questions.map(q => {
                if(q.question.uuid !== uuid) {
                    questions_data.push(q.question.uuid);
                }
            });
        }
        
        if (typeof uuid === 'object') {

            questions_data = testContext.questions.filter(q => {
                return selectedQuestions.indexOf(q.question.uuid) === -1;
            }).map(q => q.question.uuid);
        }

        const addData:TestAddQuestionsRequest = {
            testUuid:testContext.test.uuid,
            questionsUuids:questions_data
        };

        addQuestions(addData);
    };

    const handleSetQuestionsNum = (num: number) => {
        const newData:TestSetRandomQuestions={
            testUuid: testContext.test.uuid,
            questionsNum: num
        };
        setRandomQuestions(newData);
    };

    useEffect(() => {
        const questionsArr: TestQuestionWithName[] = testContext.questions.map((question) => {
            return {
                name: question.question.question,
                type: question.question.type,
                uuid: question.question.uuid
            } as TestQuestionWithName;
        });

        setQuestions(questionsArr);

    }, [testContext.questions]);

    return (
        <>
            <div style={{ width: '100%' }}>
                <h2 className={style.title}>Содержание</h2>

                {testContext.test.testStarted && (
                    <p className={style.blocked_test}>
                        Внимание, уже были попытки пройти этот тест.
                    </p>
                )}

                {isEmptyBank && (
                    <p className={style.blocked_test}>
                        В банке вопросов отсутствуют вопросы
                    </p>
                )}

                <RoleCheck roles={Role.Methodist}>
                    <ButtonDrop
                        typeIcon="add"
                        buttonChild="Добавить вопросы"
                        disabled={isEmptyBank}
                    >
                        <DropMenuItem onClick={showManualModal}>
                            Ручной выбор
                        </DropMenuItem>

                        <DropMenuItem onClick={showRandomModal}>
                            Случайный выбор
                        </DropMenuItem>
                    </ButtonDrop>
                </RoleCheck>

                <div className={style.content_container}>

                    <QuestionTable 
                        data={questionsArray}
                        onSelectItems={handleSelectQuestions}
                        onDeleteSelected={handleDeleteQuestions} 
                        onBucketClick={handleDeleteQuestions}
                        isFetching={isFetching}
                        isSelectable={checkRole(Role.Methodist)}
                        isDisableSelectPanel={checkRole(Role.Methodist)}
                        style={{ marginTop: checkRole(Role.Methodist) ? 24 : 0 }}
                    /> 
                
                </div>
            </div>

            <ManualQuestionModal
                title='Добавить вопросы в ручную'
                course_id={uuidTest!}
                showModal={manualModal}
                test={testContext}
                setShowModal={setManualModal}
                handleAddQuestions={handleAddQuestions}
            />

            <RandomQuestionModal
                title='Добавить случайные вопросы'
                course_id={uuidTest!}
                showModal={randomModal}
                test={testContext}
                setShowModal={setRandomModal}
                handleSetQuestionsNum={handleSetQuestionsNum}
            />
        </>
    );
};

export default Content;
