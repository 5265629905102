import React from 'react';

import style from './ContactsBlock.module.scss';

interface ContactsBlockProps {
    className?: string;
}

const ContactsBlock: React.FC<ContactsBlockProps> = ({ className }) => {
    return (
        <div className={className}>
            <h4 className={style.title}>Техническая поддержка</h4>
            <div className={style.wrapper_block}>
                <div className={style.tel_wrapper}>
                    <a href="tel:+78003333776" className={style.tel}>8 800 333 37 76</a>
                    &nbsp;
                    <span className={style.extension}>(доб. 911)</span>
                    <span className={style.work_schedule}>По будням с 10:00 до 18:00 (МСК)</span>
                </div>
            </div>
            <a href="mailto:support@eeip.ru" className={style.mail}>support@eeip.ru</a>
        </div>
    );
};

export default ContactsBlock;
