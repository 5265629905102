import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGroupDeadlinesApiResponse, IGroupDeadlinesSliceData, TDeadline  } from '@store/api/apiTypes';
import { IDeadlinesPresent } from '@type/deadlines';

import type { RootState } from '@store/store';

interface IDeadlinesState {
    selectedMaterials: {
        [uuid: string]: Omit<IGroupDeadlinesApiResponse, 'deadline'>[]
    },
    deadlines: TDeadline,
    deadlinesPresent: IDeadlinesPresent
}

const initialState: IDeadlinesState = {
    selectedMaterials: {},
    deadlines: {
        deadlineStart: '',
        deadlineEnd: ''
    },
    deadlinesPresent: {
        noStartDate: false,
        noEndDate: false
    }
};

export const deadlinesSlice = createSlice({
    initialState,
    name: 'deadlinesSlice',
    reducers: {
        addDeadlineItem: (state, action: PayloadAction<IGroupDeadlinesSliceData>) => {
            state.selectedMaterials[action.payload.disciplineUUID] = action.payload.materials;
        },
        removeDeadlineItem: (state, action: PayloadAction<string>) => {
            delete state.selectedMaterials[action.payload];
        },
        setDeadline: (state, action: PayloadAction<TDeadline>) => {
            state.deadlines = action.payload;
        },
        setDeadlinePresense: (state, action: PayloadAction<{noStartDate: boolean, noEndDate: boolean}>) => {
            state.deadlinesPresent = action.payload;
        },
        resetDeadlines: () => {
            return initialState;
        }
    }
});

export default deadlinesSlice.reducer;

export const selectedDeadlines = (state: RootState) => state.deadlinesSlice.deadlines;
export const deadlinesPresense = (state: RootState) => state.deadlinesSlice.deadlinesPresent;
export const deadlineItems = (state: RootState) => state.deadlinesSlice.selectedMaterials;

export const {
    addDeadlineItem,
    setDeadlinePresense,
    removeDeadlineItem,
    setDeadline,
    resetDeadlines

} = deadlinesSlice.actions;
