import React, { useEffect, useState } from 'react';

import { Outlet, useOutletContext, useParams } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import ProgressBar from '@components/core/ProgressBar';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { calculateLectureBlockProgress } from '@helpers/calculateLectureBlockProgress';
import ScrollToTop from '@helpers/hoc/ScrollToTop';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useToggle } from '@hooks/useToggle.hook';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { LectureBlocksContent } from '@store/api/apiTypes';
import { useGetLectureBlockProgressQuery, useGetLecturesBlocksContentByIdQuery } from '@store/api/lecturesApi';
import { Role } from '@store/Roles';
import EmptyLecture from '@views/student/LecturesPreview/EmptyLecture';
import LecturesSidebar from '@views/student/LecturesPreview/LecturesSidebar';

import style from './LecturesPreview.module.scss';

export const useLectureBlock = () => {
    return useOutletContext<LectureBlocksContent | null>();
};

const LecturesPreview = () => {
    const { uuidLectureBlock } = useParams();
    const lecturesBlock = useGetLecturesBlocksContentByIdQuery(uuidLectureBlock as string);
    const lecturesBlockProgress = useGetLectureBlockProgressQuery(uuidLectureBlock as string);
    const [isOpen, { on, off }] = useToggle();
    const lgDown = useBreakpoint('lg', 'down');
    const mdUp = useBreakpoint('md', 'up');

    const [lecturesBlockData, setLecturesBlockData] = useState<LectureBlocksContent | null>(null);

    useEffect(() => {
        if (lecturesBlock.data) {
            if (checkRole(Role.Student)) {
                setLecturesBlockData({
                    ...lecturesBlock.data,
                    topics: lecturesBlock.data?.topics.map(topic => {
                        return {
                            ...topic,
                            lectures: topic.lectures.filter(lecture => lecture.chapters.length !== 0)
                        };
                    })
                });
            } else {
                setLecturesBlockData(lecturesBlock.data);
            }
        }
    }, [lecturesBlock.data]);

    if (lecturesBlock.isSuccess && !lecturesBlock.data?.topics.length) return <EmptyLecture />;

    return (
        <div className={style.wrapper}>
            {
                !lgDown && <LecturesSidebar lecturesBlock={lecturesBlockData} />
            }
            <ScrollToTop>
                <Outlet context={lecturesBlockData} />
            </ScrollToTop>
            {
                lgDown && (
                    <div className={style.lectures_menu_wrapper}>
                        <div className={style.lectures_menu}>
                            {mdUp && <div />}
                            <RoleCheck roles={Role.Student}>
                                <ProgressBar completed={calculateLectureBlockProgress(lecturesBlockProgress.data)} />
                            </RoleCheck>

                            <ButtonIcon onClick={on} typeIcon="menu-2" className={style.lectures_menu__btn} />
                        </div>
                        <SwipeableDrawer ModalProps={{ keepMounted: true }} anchor="right" open={isOpen} onClose={off} onOpen={on}
                            className={style.swipeable_drawer}>
                            <div className={style.swipeable_drawer__title_wrapper}>
                                <h3>Содержание</h3>
                                <ButtonIcon className={style.swipeable_drawer__close_btn} onClick={off}>
                                    <CloseIcon />
                                </ButtonIcon>
                            </div>
                            <div style={{ display: isOpen ? 'block' : 'none', height: '100%' }}>
                                <LecturesSidebar lecturesBlock={lecturesBlockData} />
                            </div>
                        </SwipeableDrawer>
                    </div>
                )
            }
        </div>
    );
};

export default LecturesPreview;
