import Main from '@components/Main';
import TTabs from '@components/Tabs';
import DisciplinesList from '@views/student/StudentMain/DisciplinesList';

import style from './StudentMain.module.scss';

const tabs = ['Все', 'Активные', 'Задолженности', 'Завершенные'];
const panels = [
    <DisciplinesList key="all" filter="all" />,
    <DisciplinesList key="active" filter="active" />,
    <DisciplinesList key="arrears" filter="arrears" />,
    <DisciplinesList key="completed" filter="completed" />
];

const StudentMain = () => {
    return (
        <Main className={style.main} title="Обучение">
            <h1 className={style.title}>Обучение</h1>
            <TTabs tabs={tabs} panels={panels} type="grey" containerClassName={style.tabs} />
        </Main>
    );
};

export default StudentMain;
