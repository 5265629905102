import { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { ReactComponent as CreateSection } from '@assets/image/create-section-svg.svg';
import Loader from '@components/core/Loader';
import { NewLoader } from '@components/core/NewLoader';
import { DataList, DataListBody, DataListCell, DataListHeader } from '@components/DataList';
import { DataListEditCell } from '@components/DataList/Cells/DataListEditCell';
import { DataListRow } from '@components/DataList/DataListRow';
import { Pagination, usePagination } from '@components/Pagination';
import { prepareColumnData, prepareColumns } from '@helpers/scores/table';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { CloseTypes, Task, Test } from '@store/api/apiTypes';
import { useGetDisciplineGradesQuery, useReassignGradeMutation, useReassignNotPassedGradeMutation, useReassignTotalGradeMutation } from '@store/api/gradesApi';
import { useGetGroupsOfDisciplineQuery } from '@store/api/groupsApi';
import { useGetDisciplineScoringMaterialsListQuery } from '@store/api/materialsApi';

import style from './ScoreTable.module.scss';

export interface IScoreTableColumn {
    header: string;
    width: number | string;
    last?: boolean;
}
export interface IScoreTableData {
    value?: string | number;
    uuid?: string;
    uuidGrade?: string;
    uuidMaterial?: string;
    uuidDiscipline?: string;

    isEditable?: boolean;
    isTotal?: boolean;
    isExist?: boolean;

    default?: Task | Test;
    userId?: string;
    closeType?: CloseTypes
}
export interface IScoreTableView {
    search: string;
    groupUuids: string[];
}

type TSaveGrade = (value: number) => void;

export const ScoreTable:FC<IScoreTableView> = ({ search, groupUuids }) => {
    const { uuidDiscipline } = useParams();

    const { handleSliceData, setTotal, paginationProps } = usePagination<IScoreTableData[]>({});

    const [reassignGradeApi] = useReassignGradeMutation();
    const [reassignTotalGradeApi] = useReassignTotalGradeMutation();
    const [reassignNotPassedMaterialApi] = useReassignNotPassedGradeMutation();

    const scoringMaterials = useGetDisciplineScoringMaterialsListQuery(uuidDiscipline ?? skipToken);

    const disciplineGrades = useGetDisciplineGradesQuery({
        userSearch: search,
        uuidDiscipline: uuidDiscipline ?? '',
        uuidsGroupSearch: groupUuids
    });

    const [data, setData] = useState<IScoreTableData[][]>([]);
    const [columns, setColumns] = useState<IScoreTableColumn[]>([]);
    const [noData, setNoData] = useState(false);

    useEffect(() => {
        disciplineGrades.refetch();
    }, [search, groupUuids, uuidDiscipline]);

    useEffect(() => {
        if (disciplineGrades.isSuccess && scoringMaterials.isSuccess) {
            setNoData(false);

            if (scoringMaterials.data.length === 0) {
                setNoData(true);
                return;
            }

            setColumns(
                prepareColumns(
                    scoringMaterials.data ?? []
                )
            );

            setData(
                prepareColumnData(
                    disciplineGrades.data ?? { users: [] },
                    scoringMaterials.data ?? []
                )
            );
        } else if (scoringMaterials.isError) {
            if ('status' in scoringMaterials.error && scoringMaterials.error.status == 404) {
                setNoData(true);
            }
        }
    }, [
        disciplineGrades.isSuccess,
        disciplineGrades.isFetching,
        scoringMaterials.isSuccess,
        scoringMaterials.isFetching
    ]);

    useEffect(() => {
        setTotal(data.length || 0);
    }, [data]);

    const renderColumns = () => {
        return columns.map( (column, index) => {
            return (
                <DataListCell
                    style={{ width: column.width }}
                    key={`h_${index}`}
                    last={column.last}
                    header
                >
                    {column.header}
                </DataListCell>
            );
        });
    };

    const renderCell = (unit: IScoreTableData, onSave: TSaveGrade) => {
        if (unit.isEditable || !unit.isExist) {
            return (
                <DataListEditCell onSave={onSave} value={unit.value} unit={unit} groupUuids={groupUuids} />
            );
        } else return unit.value;
    };

    // Methods
    const renderData = () => {
        return handleSliceData(data).map((item, index) => {

            return <DataListRow key={`dt_${index}`} gridTemplateColumns="7fr 4fr 2fr">
                {
                    item.map( (unit, indx) => {

                        const onSave = (value: number) => {

                            // Если это итоговая оценка
                            if (unit.isTotal) {
                                return reassignTotalGradeApi({
                                    uuidDiscipline: uuidDiscipline ?? '',
                                    uuidUser: unit.userId ?? '',

                                    grade: value
                                });
                            }

                            // Если это оценка которой не было
                            if (!unit.isExist) {
                                return reassignNotPassedMaterialApi({
                                    uuidDiscipline: uuidDiscipline ?? '',
                                    uuidMaterial: unit.uuidMaterial ?? '',
                                    uuidTeacher: '',
                                    uuidUser: unit.userId ?? '',

                                    grade: value
                                });
                            } else {
                                return reassignGradeApi({
                                    grade: value,
                                    uuid: unit.uuidGrade ?? ''
                                });
                            }
                        };

                        return (<DataListCell
                            style={{ width: columns[indx]?.width }}
                            last={columns[indx]?.last}
                            noPadding={unit.isEditable}
                            key={`dc_${indx}`}>

                            { renderCell(unit, onSave) }

                        </DataListCell>);
                    })
                }
            </DataListRow>;
        });
    };

    if (disciplineGrades.isFetching || scoringMaterials.isFetching) return <NewLoader />;

    if (noData) return(
        <div className={style.container}>
            <div className={style.picture}>
                <CreateSection />
            </div>

            <div className={style.title}>Нет оцениваемых материалов</div>
            <div className={style.description}>
                Добавьте контрольные или тесты в дисциплину
            </div>
        </div>
    );

    return (
        <div className={style.table}>
            <div className={style.scrollBody}>
                <DataList>
                    <DataListHeader>
                        { renderColumns() }
                    </DataListHeader>

                    <DataListBody>
                        { data ? renderData() :  <Loader />}
                    </DataListBody>
                </DataList>
            </div>


            <div className={style.pagination}>
                <Pagination {...paginationProps} />
            </div>
        </div>

    );
};
