import React, { FC, useMemo, useState } from 'react';

import cx from 'classnames';

import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import { checkRole } from '@components/RoleCheck';
import TTabs from '@components/Tabs';
import { useGetPortfolioSectionsListQuery } from '@store/api/portfolioApi';
import { Role } from '@store/Roles';
import { PortfolioSectionType } from '@type/portfolio';
import AddFileModal from '@views/student/StudentProfile/Portfolio/AddFileModal';
import FilesList from '@views/student/StudentProfile/Portfolio/FilesList';

import ControlWorksList from './ControlWorksList';
import style from './Portfolio.module.scss';

interface IProps {
    className?: string;
}

const Portfolio: FC<IProps> = () => {
    const [isOpenAddFileModal, setIsOpenAddFileModal] = useState<boolean>(false);
    const { data: portfolioSectionsTypeList, isLoading: isLoadingPortfolioSections } = useGetPortfolioSectionsListQuery();

    const transformSectionsTypeList: PortfolioSectionType[] = useMemo(() => {
        return portfolioSectionsTypeList?.filter((target: PortfolioSectionType) => target.active) || [];
    }, [portfolioSectionsTypeList]);

    const tabs = useMemo(() => {
        return transformSectionsTypeList?.map(section => section.typeName) || [];

    }, [transformSectionsTypeList]);

    const openAddFileModalHandler = () => {
        setIsOpenAddFileModal(true);
    };


    const panels = useMemo(() => {
        return transformSectionsTypeList?.map(section => {
            return (
                <>
                    {checkRole([Role.Student]) && (
                        <Button
                            onClick={openAddFileModalHandler}
                            className={style.tabs_container__btn__mobile}
                        >
                            Добавить файл</Button>
                    )}
                    {
                        section.uuid === '2' ? (
                            <ControlWorksList key={section.uuid} />
                        ) : (
                            <FilesList key={section.uuid} typeUuid={section.uuid} />
                        )
                    }
                </>
            );
        }) || [];
    }, [transformSectionsTypeList]);

    if (isLoadingPortfolioSections) return <NewLoader />;

    return (
        <>
            <h2 className={style.title}>Портфолио</h2>
            <div>
                <TTabs
                    tabs={tabs as string[]}
                    panels={panels} type="grey"
                    className={style.tabs}
                    containerClassName={cx(style.tabs_container, style['tabs_container--student'])}
                />
                {checkRole([Role.Methodist]) && (
                    <Button
                        onClick={openAddFileModalHandler}
                        className={style.tabs_container__btn}
                    >
                        Добавить файл</Button>
                )}
                <AddFileModal isOpen={isOpenAddFileModal} onClose={setIsOpenAddFileModal} />
            </div>
        </>
    );
};

export default Portfolio;
