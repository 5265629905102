import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import Modal from '@components/Modal';
import { getBase64 } from '@helpers/base64';
import { fileTypes } from '@store/api/apiTypes';
import { usePortfolioFilesChangeMutation } from '@store/api/portfolioApi';
import { useUploadFileMutation } from '@store/api/storageApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { PortfolioFile } from '@type/portfolio';
import CreateFileForm from '@views/student/StudentProfile/Portfolio/CreateFileForm';

interface IAddFileModal {
    isOpen: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
}

const AddFileModal: FC<IAddFileModal> = ({ isOpen, onClose }) => {
    const user = useAppSelector(selectApiUser);
    const { userId } = useParams();
    const [uploadFile, { isSuccess: isSuccessUploadFile }] = useUploadFileMutation();
    const [fetchCreateFile, { isSuccess: isSuccessCreateFile }] = usePortfolioFilesChangeMutation();

    const createFileHandler = async (formData: any) => {
        let transformedFormData: Partial<PortfolioFile> = {
            uName: formData.uName,
            typeId: formData.typeId.value,
            userId: user?.role === Role.Methodist ? userId as string : user?.uuid || '',
            active: true
        };

        if (formData.file) {
            const b64 = await getBase64(formData.file);
            const uploadedFile = await uploadFile({
                file: b64 as string,
                meta: {
                    filename: formData.file.name,
                    type: fileTypes.Public
                }
            }).unwrap();

            transformedFormData = { ...transformedFormData, fileName: uploadedFile.path };
        }
        await fetchCreateFile(transformedFormData);
    };

    useEffect(() => {
        if (isSuccessUploadFile && isSuccessCreateFile)
            onClose(false);
    }, [isSuccessUploadFile, isSuccessCreateFile]);

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title="Добавить файл"
        >
            <CreateFileForm
                onSubmitFormHandler={createFileHandler}
                onCloseModal={onClose}
            />
        </Modal>

    );
};

export default AddFileModal;
