import { FC, useState } from 'react';

import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import { FileUploadButtonControl } from '@components/core/inputs/FileUpload/FileUploadButtonControl';
import Modal from '@components/Modal';
import { getBase64 } from '@helpers/base64';
import { yupResolver } from '@hookform/resolvers/yup';
import { useImportTestQuestionsMutation } from '@store/api/testsApi';

import style from './import_test_questions_modal.module.scss';


const schema = yup.object({
    fileUpload: yup.mixed().required('Добавьте файл!').nullable()
});

type IModalImportForm = yup.InferType<typeof schema>;

interface IImportUsersModal {
    isOpen: boolean,
    onClose: () => void
}

const ImportTestQuestionsModal: FC<IImportUsersModal> = ({ isOpen, onClose }) => {

    const { uuidTest } = useParams();

    const [importTestQuestions] = useImportTestQuestionsMutation();

    // Form
    const methods = useForm<IModalImportForm>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            fileUpload: null
        }
    });

    const { handleSubmit, reset, control } = methods;

    const onSubmit = async (formData: IModalImportForm) => {
        const b64 = await getBase64(formData.fileUpload);

        const submitData = {
            data: b64 as string,
            testUuid: uuidTest || ''
        };

        onClose();
        submitData.testUuid && await importTestQuestions(submitData);
        reset();
    };

    return (
        <>
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                title="Импорт вопросов"
            >
                <form onSubmit={(handleSubmit(onSubmit))}>
                    <div className={style.steps}>
                        <div className={style.step_container}>
                            <div className={style.step_number}>1</div>
                            <div className={style.step_content}>
                                <p>
                                    <a href="/files/test_questions_import_sample.txt" download>Скачайте шаблон импорта</a>, чтобы добавить новые вопросы
                                </p>
                            </div>
                        </div>

                        <div className={style.step_container}>
                            <div className={style.step_number}>2</div>
                            <div className={style.step_content}>
                                <p>
                                Выберите файл с заполненным шаблоном
                                </p>
                                <div className={style.input_wrapper}>
                                    <FileUploadButtonControl
                                        name="fileUpload"
                                        control={control}
                                        accessTypes={[
                                            'text/plain'
                                        ]}
                                        className={style.file_wrapper}
                                    >
                                        Формат: TXT
                                    </FileUploadButtonControl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={style.button_wrapper}>
                        <Button
                            onClick={onClose}
                            btnType="secondary"
                        >
                        Отмена
                        </Button>
                        <Button type="submit">
                            Импортировать
                        </Button>
                    </div>

                </form>
            </Modal>
        </>

    );
};

export default ImportTestQuestionsModal;
