import { FC, useEffect, useRef, useState } from 'react';

import { DateTime } from 'ts-luxon';

import { NewLoader } from '@components/core/NewLoader';
import { useCalendar } from '@components/EventsFilters/context';
import { checkRole } from '@components/RoleCheck';
import { getEvents } from '@helpers/events';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { CalendarEventRequest } from '@store/api/apiTypes';
import { useGetCalendarEventsQuery, useGetStudentCalendarEventsQuery, useGetTeacherCalendarEventsQuery } from '@store/api/eventApi';
import { Role } from '@store/Roles';
import { InfoEvent, SelectDayData } from '@type/eventCalendar';

import style from './calendar_layout.module.scss';
import { CalendarContent } from './CalendarContent';
import { CalendarEvents } from './CalendarEvents';
import { CalendarHeader } from './CalendarHeader';

interface ICalendarGridProps {
    today: DateTime;
    startDay: DateTime;
    totalDays: number;
}

const CalendarLayout: FC<ICalendarGridProps> = ({ startDay, today, totalDays }) => {
    const lgDown = useBreakpoint('lg', 'down');

    const day = startDay.minus(1);
    const daysMap = [...Array(totalDays)].map((_, i) => day.plus({ day: i + 1 }));

    const [selectDayData, setSelectDayData] = useState<SelectDayData | null>(null);
    const [infoEvent, setInfoEvent] = useState<InfoEvent>({
        isVisible: false,
        currentEventId: null
    });

    const isMethodist = checkRole([Role.Methodist, Role.Inspector]);
    const isStudent = checkRole(Role.Student);
    const isTeacher = checkRole(Role.Teacher);

    // Контекст
    const { search, groups, discipline, teacher } = useCalendar();

    const eventsRef = useRef<HTMLDivElement>(null);

    const requestData = {
        fromDate: ('' + daysMap[0].set({ hour: 0, minute: 0, second: 0 }).toSQL({ includeOffset: false })).slice(0, -4),
        toDate: ('' + daysMap[daysMap.length - 1].toSQL({ includeOffset: false })).slice(0, -4)
    }; 

    const getRequestParams = (): CalendarEventRequest => {
        const params: CalendarEventRequest = {
            fromDate: ('' + daysMap[0].set({ hour: 0, minute: 0, second: 0 }).toSQL({ includeOffset: false })).slice(0, -4),
            toDate: ('' + daysMap[daysMap.length - 1].toSQL({ includeOffset: false })).slice(0, -4)
        };

        if (search) params.name = search;
        if (groups.length) params.groups = groups;
        if (discipline) params.discipline = discipline;
        if (teacher) params.teacher = teacher;

        return params;
    };

    // Апиха
    const methodistCalendarEvents = useGetCalendarEventsQuery(isMethodist ? getRequestParams() : skipToken);
    const studentCalendarEvents = useGetStudentCalendarEventsQuery(isStudent ? requestData : skipToken);
    const teacherCalendarEvents = useGetTeacherCalendarEventsQuery(isTeacher ? requestData : skipToken);

    const calendarEvents = getEvents(methodistCalendarEvents, studentCalendarEvents, teacherCalendarEvents);

    // Props
    const calendarContentProps = {
        today,
        daysMap,
        isMethodist,
        events: calendarEvents.data ?? [],
        infoEvent,
        eventsRef,
        selectDayData,
        setSelectDayData,
        setInfoEvent
    };

    const calendarEventsProps = {
        eventsRef,
        selectDayData,
        infoEvent,
        setInfoEvent,
        today
    };

    // Effect
    useEffect(() => {
        setInfoEvent({ isVisible: false, currentEventId: null });
    }, [lgDown]);

    // Error & Loader
    if (calendarEvents.isFetching) return <NewLoader className={style.loader} />;

    if (calendarEvents.isError) return (
        <div className={style['error-data']}>
            <span>Упс, что-то пошло не так</span>
        </div>
    );

    return (
        <>
            <CalendarHeader />

            {calendarEvents.isSuccess && <CalendarContent {...calendarContentProps}  /> }

            {lgDown && selectDayData && ( <CalendarEvents {...calendarEventsProps} /> )}
        </>
    );
};

export default CalendarLayout;
