import { useCallback, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import { OptionType } from '@components/core/inputs/SelectStyled/type';
import { NewLoader } from '@components/core/NewLoader';
import Paper from '@components/core/styledWrappers/Paper';
import Main from '@components/Main';
import TinyEditor from '@components/methodist/TinyEditor/TinyEditor';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Uuid } from '@store/api/apiTypes';
import { useGetDisciplinesAllQuery } from '@store/api/disciplinesApi';
import { useCreatePatternMutation, useLazyGetPatternQuery, useUpdatePatternMutation } from '@store/api/patternsApi';

import style from './StatementEditor.module.scss';

export const statementSchema = yup.object({
    editor: yup.string(),
    material: yup.object({ label: yup.string().default(''), value: yup.string().default('') })
});

export const StatementEditor = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [uuidDiscipline, setDisciplineUuid] = useState<Uuid>((location.state as any).uuidDiscipline);
    const [loader, setLoader] = useState<boolean>(true);
    const [patternError, setPatternErorr] = useState<boolean>(false);
    const [patternUuid, setPatternUuid] = useState<string>('');
    const [pattern, setPattern] = useState<string>('');

    const [triggerGetPattern] = useLazyGetPatternQuery();

    const [createPattern] = useCreatePatternMutation();
    const [updatePattern] = useUpdatePatternMutation();
    const { data: materialAll } = useGetDisciplinesAllQuery({});

    const methods = useForm<yup.InferType<typeof statementSchema>>({
        resolver: yupResolver(statementSchema),
        defaultValues: {
            material: {
                label: materialAll?.find(target => target.uuid === uuidDiscipline)?.name,
                value: uuidDiscipline
            }
        }
    });

    const { control, watch } = methods;
    const watchEditor = watch('editor');
    const watchDiscipline = watch('material');

    useEffect(() => {

        triggerGetPattern(uuidDiscipline).then(response => {

            if (response.isError) return setPatternErorr(response.isError);

            setPatternUuid(response.data?.uuid ?? '');
            setPattern(response.data?.pattern ?? '');
            setPatternErorr(false);
        });

    }, [uuidDiscipline]);

    useEffect(() => {
        setDisciplineUuid(watchDiscipline && watchDiscipline.value);
    }, [watchDiscipline]);

    const onSubmit = useCallback(() => {

        if (patternError) {
            return createPattern({
                uuidDiscipline,
                pattern: watchEditor ?? ''
            }).then(() => navigate(-1));
        }

        return updatePattern({
            uuid: patternUuid,
            uuidDiscipline,
            pattern: watchEditor ?? ''
        }).then(() => navigate(-1));

    }, [patternError, patternUuid, uuidDiscipline, watchEditor]);

    const onDecline = () => navigate(-1);
    const onInit = () => setLoader(false);

    const materialsOptions = useMemo(() => {

        return materialAll ? materialAll.map(item => {
            return {
                label: item.name,
                value: item.uuid
            } as OptionType;
        }) : [];

    }, [materialAll]);


    console.log('PatternID', patternUuid);

    return <>

        <Main title={'Шаблон Ведомости'}>
            {loader ? <div className={style.loader}><NewLoader /></div> : null}
            <div className={style.title_wrapper}>
                <ButtonIcon
                    typeIcon="arrowLink"
                    onClick={() => navigate(-1)}
                />

                <div className={style.header}>
                    <h1>
                        Шаблон экспорта
                    </h1>

                    <SelectStyled
                        control={control}
                        className={style.select}
                        name="material"
                        options={materialsOptions}
                        isMulti={false}
                        isSearchable
                        placeholder="Выбрать дисциплину"
                    />
                </div>
            </div>

            <Paper>
                <TinyEditor
                    name={'editor'}
                    initialValue={pattern}
                    control={control}
                    onInit={onInit}
                    templates />

                <div className={style.actions}>
                    <Button onClick={onSubmit} type="submit">Сохранить</Button>
                    <Button onClick={onDecline} btnType="secondary">Отменить</Button>
                </div>
            </Paper>
        </Main>

    </>;
};
