import React, { FC } from 'react';

import IconUI from '@components/core/IconUI';
import { useBreakpoint } from '@hooks/useBreakpoint';

import style from './ProgressBar.module.scss';

interface IProgressBar {
    completed: number | null
}

export const ProgressIndicator: FC<IProgressBar> = ({ completed }) => {
    return (
        <div className={style.container_bar}>
            <div className={style.placeholder} style={{ width: `${completed}%` }} />
        </div>
    );
};

const ProgressBar: FC<IProgressBar> = ({ completed }) => {
    const smLg = useBreakpoint('lg', 'down');

    if (!completed) {
        return null;
    }

    return (
        <div className={style.root}>
            {
                !smLg && (
                    <div className={style.label}>
                        <IconUI typeIcon={'hat-graduation'} />
                        <span className={style.label__text}>Материалов изучено {completed}%</span>
                    </div>
                )
            }
            {smLg && <IconUI typeIcon={'hat-graduation'} />}
            <ProgressIndicator completed={completed} />
            {smLg && <span className={style.label__text}>{completed}%</span>}
        </div>
    );
};

export default ProgressBar;
