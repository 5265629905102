import { FC, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import { FileUploadAreaControl } from '@components/core/inputs/FileUpload';
import InputControl from '@components/core/inputs/InputControl';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { maxSizeUploadFile } from '@constants/constants';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { IStorageFile } from '@store/api/apiTypes';
import { Role } from '@store/Roles';
import { CreateFileFormData } from '@views/methodist/Materials/Course/CreateFileForm/type';

import style from './createFileForm.module.scss';

interface ICreateFileModal {
    defaultValues: {
        name: string;
        file: IStorageFile;
    };
    onSubmitFormHandler: (formData: CreateFileFormData) => void;
    onCloseModal: (isOpen: boolean) => void;
}

export const createFileFormSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    file: yup.mixed().label('File').required('Поле обязательно для заполнения!')
});

const CreateFileForm: FC<ICreateFileModal> = ({
    defaultValues,
    onSubmitFormHandler,
    onCloseModal
}) => {
    const { name, file } = defaultValues;
    const methods = useForm<CreateFileFormData>({
        resolver: yupResolver(createFileFormSchema),
        mode: 'onBlur',
        defaultValues
    });

    const { control, handleSubmit, setValue } = methods;

    useEffect(() => {
        setValue('name', name);
        setValue('file', file);
    }, [defaultValues]);

    const onSubmit = async (formData: CreateFileFormData) => {
        onSubmitFormHandler(formData);
    };

    return (
        <form>
            <FormProvider {...methods}>
                <div className={style.input_wrapper}>
                    <InputControl
                        label={'Заголовок'}
                        name={'name'}
                        placeholder={'Заголовок'}
                        disabled={!checkRole(Role.Methodist)}
                    />

                    <FileUploadAreaControl
                        withoutCheckControl
                        name="file"
                        control={control}
                        maxSizeFile={maxSizeUploadFile}
                        disabled={!checkRole(Role.Methodist)}
                    />
                </div>
                <RoleCheck roles={Role.Methodist}>
                    <div className={style.button_wrapper}>
                        <Button
                            onClick={() => onCloseModal(false)}
                            btnType={'secondary'}
                        >
                            Отмена
                        </Button>
                        <Button onClick={handleSubmit(onSubmit)}>Сохранить</Button>
                    </div>
                </RoleCheck>
            </FormProvider>
        </form>
    );
};

export default CreateFileForm;

