import { FC } from 'react';

import cx from 'classnames';

import IconUI from '@components/core/IconUI';
import { InlineControls } from '@components/core/InlineControls';
import { IControl } from '@components/core/InlineControls/type';
import { capitalizeFirstLetter } from '@helpers/text';
import { EnumMaterialsType } from '@store/api/apiTypes';

import { IconRender } from '../type';
import style from './listitem.module.scss';

interface IListItemProps extends IControl {
    type: EnumMaterialsType
    name: string
    extension?: string
    noEditable?: boolean
    className?: string
}

export const ListItem: FC<IListItemProps> = ({
    type,
    name,
    extension = '',
    onLook,
    onEdit,
    onDelete,
    noEditable,
    className = ''
}) => {

    const fileExtension = (ext: string) => {
        switch (ext) {
            case 'docx':
                return <IconUI typeIcon="fileDoc" />;
            case 'pdf':
                return <IconUI typeIcon="filePdf" />;
            case 'xlst':
                return <IconUI typeIcon="fileXls" />;
            case 'ppt':
                return <IconUI typeIcon="filePpt" />;
            case 'pptx':
                return <IconUI typeIcon="filePptx" />;
            case 'zip':
                return <IconUI typeIcon="fileZip" />;
            default:
                return  <IconUI typeIcon="defaultFile" />;
        }
    };

    const icon: IconRender = {
        [EnumMaterialsType.Link]: <IconUI typeIcon="link" />,
        [EnumMaterialsType.File]: fileExtension(extension), // We need to go deeper
        [EnumMaterialsType.Page]: <IconUI typeIcon="page" />,
        [EnumMaterialsType.Task]: <IconUI typeIcon="taskExam" />,
        [EnumMaterialsType.Test]: <IconUI typeIcon="taskTst" />,
        [EnumMaterialsType.Video]: <IconUI typeIcon="videoLecture" />,
        [EnumMaterialsType.Lecture]: <IconUI typeIcon="lecture" />
    };

    return <div className={cx(style.container, className)} onClick={onEdit}>
        <div className={style.inner}>
            <div className={style.icon}>{icon[capitalizeFirstLetter(type) as EnumMaterialsType]}</div>
            <div className={style.title}>{name}</div>
        </div>

        <div className={style.controls}>
            <InlineControls
                onLook={onLook}
                onEdit={onEdit}
                onDelete={!noEditable ? onDelete : undefined}
            />
        </div>
    </div>;
};
