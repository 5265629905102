import React from 'react';

import { TreeSelectOption } from '../../NewTreeSelect';
import { Option } from '../Option';
import style from './OptionsList.module.scss';

interface OptionsListProps {
    options: TreeSelectOption[];
    search: string;
}

export const OptionsList: React.FC<OptionsListProps> = ({ options, search }) => {
    return (
        <div>
            <div className={style.option_list_wrapper}>
                {options.length ? options.map(option => (
                    <Option key={option.value} search={search} option={option} />
                )) : (
                    <div className={style.empty}>
                        Список пуст
                    </div>
                )}
            </div>
        </div>
    );
};
