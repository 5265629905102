import React from 'react';

import Button from '@components/core/inputs/Button';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { useToggle } from '@hooks/useToggle.hook';

import style from './DeleteAttempt.module.scss';


interface DeleteAttemptProps {
    handleDelete: () => void;
    attemptName: string;
}

export const DeleteAttempt: React.FC<DeleteAttemptProps> = ({ handleDelete, attemptName }) => {
    const [isOpen, { on, off }] = useToggle();

    return (
        <>
            <Button className={style['cancel-button']} btnType="danger" onClick={on}>Удалить попытку</Button>
            <DeleteItemModal isOpen={isOpen} onClose={off} onDelete={handleDelete} itemName={attemptName} itemType="attempt" />
        </>
    );
};
