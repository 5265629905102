import { FC } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import Button from '@components/core/inputs/Button';
import Modal from '@components/Modal';
import { useSoftUsersDeleteMutation } from '@store/api/usersApi';

import style from './UserDeleteModal.module.scss';


interface IUserDeleteModal {
    isOpen: boolean,
    onClose: () => void
}

export const UserDeleteModal: FC<IUserDeleteModal> = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const [softUserDelete] = useSoftUsersDeleteMutation();

    const deleteUser = (id: string) => {
        softUserDelete([id]);
        onClose();
        navigate('/users');
    };

    const onUserDelete = () => {
        if (userId) deleteUser(userId);
    };

    return (
        <>
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                title={'Удалить аккаунт?'}
            >

                <p className={style.message}>Внимание, данное действие нельзя будет отменить</p>

                <div className={style.actions}>
                    <Button btnType="secondary" onClick={onClose}>Отмена</Button>
                    <Button btnType="danger" onClick={onUserDelete}>Удалить</Button>
                </div>
            </Modal>
        </>

    );
};
