import { ICategory, IDiscipline, IGroupDeadlinesApiResponse, ISetGroupDeadlinesApiRequest, TDeadline } from '@store/api/apiTypes';

interface GetTreeDisciplinesByCategoryReturned {
    uuid: string;
    name: string;
    children: GetTreeDisciplinesByCategoryReturned[]
}

export const getTreeDisciplinesByCategory = (categories: ICategory[], disciplines: IDiscipline[]): GetTreeDisciplinesByCategoryReturned[] => {
    const disciplinesByCategory: GetTreeDisciplinesByCategoryReturned[] = [];

    categories.forEach((category) => {
        const currentDisciplinesByCategory = disciplines
            .filter(discipline => discipline.uuidCategory === category.uuid)
            .map(discipline => ({
                uuid: discipline.uuid,
                name: discipline.name,
                children: []
            }));

        const children = [...getTreeDisciplinesByCategory(category.children, disciplines), ...currentDisciplinesByCategory];

        if (children.length) {
            disciplinesByCategory.push({
                uuid: category.uuid,
                name: category.name,
                children
            });
        }
    });

    return disciplinesByCategory;
};

type MaterialsByDeadlineMap = Record<string, {
    materialUUID: string;
    disciplineUUID: string;
}[]>

export const getGroupedMaterialsByDeadline = (materialsWithDeadline: IGroupDeadlinesApiResponse[]): Omit<ISetGroupDeadlinesApiRequest, 'groupUUID'>[] => {
    const materialsByDeadlineMap: MaterialsByDeadlineMap = {};

    const getKey = (deadline: TDeadline) => {
        return JSON.stringify(deadline);
    };

    const parseKey = (key: string) => {
        return JSON.parse(key);
    };

    materialsWithDeadline.forEach(material => {
        const key = getKey(material.deadline);

        const value = {
            materialUUID: material.materialUUID,
            disciplineUUID: material.disciplineUUID
        };

        if (materialsByDeadlineMap[key]) {
            materialsByDeadlineMap[key].push(value);
        } else {
            materialsByDeadlineMap[key] = [value];
        }
    });

    return Object.entries(materialsByDeadlineMap).map(([key, materials]) => ({
        materials,
        deadline: parseKey(key)
    }));
};

export const getDisciplinesMap = (disciplines: IDiscipline[]): Record<string, IDiscipline> => {
    const disciplinesMap: Record<string, IDiscipline> = {};

    disciplines.forEach(discipline => {
        disciplinesMap[discipline.uuid] = discipline;
    });

    return disciplinesMap;
};
