import { dateConvert } from '@helpers/date';
import { Uuid } from '@store/api/apiTypes';
import { store } from '@store/store';
import { ChatUser, Room, Message, RecipientUser } from '@type/chat';
import { ENV } from '@type/common';
import { User } from '@type/users';

const currentEnv = process.env.REACT_APP_CURRENT_ENV;
const SERVICE_USER = currentEnv === ENV.prod ? 'f7511ab2-9fe0-44ef-b46c-3bebf005e1f4' : '';

export const validationAndReturnRooms = (rooms: Room[], me: User | null): Room[] => {
    return rooms.filter(room => {
        if (room.users.length < 2) return false;
        return room.users.every(user => user.uuid && user.fullName && user.role && !(me?.uuid !== SERVICE_USER && user.uuid === SERVICE_USER));
    });
};

export const getCompanion = (users: ChatUser[]): ChatUser | undefined => {
    const me = store.getState().userSlice.apiUser;
    return users.find(user => user.uuid !== me?.uuid);
};

export const sortRoomsByLastMessage = (rooms: Room[]): Room[] => {
    return rooms.sort((roomA, roomB) => {
        const aTime = roomA.previewMessage.createdAt
            ? dateConvert((roomA.previewMessage.createdAt).replace(/ \+0000 UTC/, ''))
            : null;
        const bTime = roomB.previewMessage.createdAt
            ? dateConvert((roomB.previewMessage.createdAt).replace(/ \+0000 UTC/, ''))
            : null;
        if (!aTime) return 1;
        if (!bTime) return -1;

        return bTime.diff(aTime, 'seconds').toObject().seconds as number;
    });
};

export const checkAndReturnRoom = (rooms: Room[], userId: Uuid): Room | null => {
    const foundRoom = rooms.find(room => room.users.some(user => user.uuid === userId));
    if (!foundRoom) return null;
    return foundRoom;
};

export const checkOwnMessage = (message: Message): boolean => {
    const me = store.getState().userSlice.apiUser;
    return message.uuidSender === me?.uuid;
};

export const checkIsOtherDate = (now: string, next?: string): boolean => {
    if (!next) return true;
    const nextDateParse = dateConvert(next.replace(/ \+0000 UTC/, '')).startOf('day');
    const nowDateParse = dateConvert(now.replace(/ \+0000 UTC/, '')).startOf('day');

    const diff = nowDateParse.diff(nextDateParse, ['milliseconds']);
    return diff.toObject().milliseconds as number > 0;
};

export const joinRoomsIds = (realRooms: string[], storage: string[]) => {
    const joined: Record<string, true> = {};
    [...realRooms, ...storage].forEach(id => {
        if (!joined[id]) {
            joined[id] = true;
        }
    });
    return Object.keys(joined);
};

export const addRoomToLSWithPayload = (roomId: string, payload: string) => {
    const user = store.getState().userSlice.apiUser;
    const lsKey = `rooms-of-${user?.uuid}`;

    const savedRoomsLS = localStorage.getItem(lsKey);
    const lastIdsOfRoom: Record<string, string> = savedRoomsLS ? JSON.parse(savedRoomsLS) : {};

    lastIdsOfRoom[roomId] = payload;
    localStorage.setItem(lsKey, JSON.stringify(lastIdsOfRoom));

};

export const addRoomToUserRecipients = (users: Uuid[], rooms: Room[]): RecipientUser[] => {
    return users.map(userId => {
        const foundRoom = rooms.find(room => room.users.some(user => user.uuid === userId));
        if (foundRoom) {
            return {
                uuid: userId,
                uuidRoom: foundRoom.uuid
            };
        }
        return {
            uuid: userId
        };
    });
};

export const handleTelegramMessage = (message: string): string => {
    const regTextWithLink = new RegExp(/.*\n?\s\((http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])\)/g);

    return  message.replace(regTextWithLink, (value: string) => {
        let linkText = '';

        const text = value.replace(/\((http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])\)/g, (link: string) => {
            linkText = link;
            return '';
        });

        return `[${text}]${linkText}`;
    });
};
