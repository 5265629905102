import { FC, useState, MouseEvent } from 'react';

import cx from 'classnames';

import { BottomDrawer } from '@components/BottomDrawer';
import IconUI from '@components/core/IconUI';
import Paper from '@components/core/styledWrappers/Paper';
import { useBreakpoint } from '@hooks/useBreakpoint';
import Popover from '@mui/material/Popover';

import style from './FormControl.module.scss';

interface FormControlProps {
    className?: string;
    isStudent?: boolean;
}

const emptyFunction = () => null;

const FormControlInfo = () => {
    return (
        <>
            <div className={style.caption}>Зачет с оценкой / Экзамен</div>
            <p className={style.subCaption}>
                Для прохождения необходимо набрать
                не менее 70 баллов из 100 возможных.
            </p>

            <div className={style.grades}>
                <div className={style.grade}>
                    <div className={cx(style.circle, style.bad)}></div>
                    <div className={style.circleLabel}> {'<'} 70 — Недостаточно баллов</div>
                </div>

                <div className={style.grade}>
                    <div className={cx(style.circle, style.medium)}></div>
                    <div className={style.circleLabel}> 70 – 79 — Удовлетворительно</div>
                </div>

                <div className={style.grade}>
                    <div className={cx(style.circle, style.good)}></div>
                    <div className={style.circleLabel}> 80 – 89 — Хорошо</div>
                </div>

                <div className={style.grade}>
                    <div className={cx(style.circle, style.excelent)}></div>
                    <div className={style.circleLabel}>90 – 100 — Отлично</div>
                </div>

            </div>

            <div className={style.caption}>Зачет</div>
            <p className={style.subCaption}>
                Для прохождения необходимо набрать
                не менее 60 баллов из 100 возможных.
            </p>
        </>
    );
};

export const FormControl: FC<FormControlProps> = ({ className, isStudent }) => {
    const lgDown = useBreakpoint('lg', 'down');
    const smDown = useBreakpoint('sm', 'down');

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const showPopover = (event: MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'header-cell-popover' : undefined;

    return <>
        <div
            className={cx(style.container, className)}
            role={'presentation'}
            onClick={isStudent && lgDown ? showPopover : emptyFunction}
        >

            <div>Форма контроля</div>
            <div className={style.icon}
                onMouseEnter={!(isStudent && lgDown) ? showPopover : emptyFunction}
                onMouseLeave={!(isStudent && lgDown) ? closePopover : emptyFunction} >
                <IconUI typeIcon="notice" />
            </div>
        </div>

        {!(isStudent && smDown) && (
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: lgDown ? 'right' : 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: lgDown ? 'right' : 'center'
                }}
                sx={{
                    pointerEvents: !(isStudent && lgDown) ? 'none' : ''
                }}
                disableRestoreFocus
                classes={{ paper: style['with-shadow'] }}
            >
                <Paper className={style.paper}>
                    <FormControlInfo />
                </Paper>
            </Popover>
        )}

        {isStudent && smDown && (
            <BottomDrawer open={open} onOpen={showPopover} onClose={closePopover}>
                <FormControlInfo />
            </BottomDrawer>
        )}
    </>;
};
