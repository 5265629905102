import { DateTime } from 'ts-luxon';

import { dateConvert } from '@helpers/date';
import { CloseTypes, CloseTypesWithLocale } from '@store/api/apiTypes';
import { DisciplineScoreItem, FilledDisciplines, FilledScoreCard, ScoreDisciplines, ScoreSession, ScoreStatusDiscipline } from '@type/scoreCard';

/**
 * сортировка по значению session
 */
export const sortScoreSessions  = (sessions: ScoreSession[]): ScoreSession[] => {
    return sessions.sort((a, b) => {
        if (a.session > b.session) {
            return 1;
        }
        return -1;
    });
};

const getStatus = (endDate: string, grade: number, formControl: CloseTypes): ScoreStatusDiscipline => {
    const endDataParse = dateConvert(endDate.replace(/ \+0000 UTC/, ''));
    const today = dateConvert(DateTime.utc().toFormat('yyyy-LL-dd HH:mm:ss'));
    const diff = today.diff(endDataParse, ['minutes']);

    const isExpired = diff.toObject().minutes && (diff.toObject().minutes as number) > 0;

    if (!isExpired) {
        return ScoreStatusDiscipline.active;
    } else if (
        (formControl === CloseTypes.Assessment && grade < 60)
        || ((formControl === CloseTypes.Exam || formControl === CloseTypes.AssessmentWithScore) && grade < 70)
    ) {
        return ScoreStatusDiscipline.debt;
    }
    return ScoreStatusDiscipline.finished;
};

const NO_DATE = 'Без даты';

/**
 * получает форматированную строку интервала дат
 * @example
 * 15.09.2022 - 09.12.2022
 * 15.09.2022 - Без даты
 */

const getFormattedDateInterval = (startDate: string, endDate: string): string => {
    const checkingStartDate = startDate === '2100-01-01 01:01:01' || !startDate ? null : startDate;
    const checkingEndDate = endDate === '2100-01-01 01:01:01' || !endDate ? null : endDate;
    const start = checkingStartDate ?
        dateConvert(checkingStartDate.replace(/ \+0000 UTC/, '')).toFormat('dd.LL.yyyy') :
        NO_DATE;
    const end = checkingEndDate ?
        dateConvert(checkingEndDate.replace(/ \+0000 UTC/, '')).toFormat('dd.LL.yyyy') :
        NO_DATE;
    return `${start} - ${end}`;
};

/**
 * дополняет в дисциплины поля formatControl, dateInterval, status
 */
const getFilledDisciplines = (disciplines: ScoreDisciplines[]): FilledDisciplines[] => {
    return disciplines.map((discipline) => ({
        ...discipline,
        formatControl: CloseTypesWithLocale[discipline.closeType],
        dateInterval: getFormattedDateInterval(discipline.disciplineStartDate, discipline.disciplineEndDate),
        status: getStatus(discipline.disciplineEndDate, discipline.grade, discipline.closeType)
    }));
};

/**
 * изменяет в sessions массивы disciplines, добавляя новые поля
 */
export const transformScoreCardBySlices = (sessions: ScoreSession[]): FilledScoreCard[] => {
    return sessions.map(sessionData => ({
        session: sessionData.session,
        disciplines: getFilledDisciplines(sessionData.disciplines)
    }));
};

/**
 * получение массива всех дисциплин по всем семестрам
 */
export const allScoreDisciplines = (sessions: FilledScoreCard[]): FilledDisciplines[] => {
    return sessions.flatMap((sessionData) => sessionData.disciplines);
};

/**
 * получение массива дисциплин для конкретного семестра
 */
export const getSelectedSession = (sessions: FilledScoreCard[], current: string): FilledDisciplines[] => {
    return sessions.find(session => session.session === current)?.disciplines || [];
};

/**
 * получение массива дисциплин по статусу
 */
export const getFilteredDisciplinesScore = (disciplines: FilledDisciplines[], filter: ScoreStatusDiscipline): FilledDisciplines[] => {
    return disciplines.filter(discipline => discipline.status === filter);
};

/**
 * трансформация массива дисциплин для отображения в таблице
 */
export const transformScoresToTable = (disciplines: FilledDisciplines[]): DisciplineScoreItem[] => {
    return disciplines.map(discipline => ({
        discipline: discipline.disciplineName,
        formatControl: discipline.formatControl,
        grade: discipline.grade,
        dateInterval: discipline.dateInterval,
        status: discipline.status,
        default: discipline
    }));
};
