import { ReactNode, useRef } from 'react';

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import styles from './SwitchFade.module.scss';

export default function SwitchFade(
    {
        slots,
        slotIndex,
        className
    }:
    {
        slots:ReactNode[],
        slotIndex: number;
        className?: string
    }
){
    const nodeRef = useRef<HTMLDivElement>(null);
    return (
        <SwitchTransition mode="out-in">
            <CSSTransition
                key={slotIndex}
                nodeRef={nodeRef}
                addEndListener={(done) => {
                    nodeRef.current!.addEventListener('transitionend', done, false);
                }}
                classNames={{
                    enter: styles.enter,
                    enterActive: styles.enterActive,
                    exit: styles.exit,
                    exitActive: styles.exitActive
                }}
            >
                <div ref={nodeRef} className={className}>
                    {slots[slotIndex]}
                </div>
            </CSSTransition>
        </SwitchTransition>
    );
}