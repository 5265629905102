import React, { FC, ReactNode, useState } from 'react';

import classNames from 'classnames/bind';
import { useMatch } from 'react-router-dom';

import Button from '@components/core/inputs/Button';
import { NotifyButton } from '@components/Notify/NotifyButton';
import { checkRole } from '@components/RoleCheck';
import UserAvatar from '@components/UserAvatar';
import UserMenu from '@components/UserAvatar/UserMenu';
import UserMessageButton from '@components/UserMessageButton';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { emptyBaseApi } from '@store/api/emptyBaseApi';
import { chatBaseApi } from '@store/chatApi/chatBaseApi';
import { logOut } from '@store/features/authSlice';
import { resetChatSlice } from '@store/features/chatSlice';
import { selectApiUser } from '@store/features/userSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Role, roleNames } from '@store/Roles';

import style from './methodist_menu.module.scss';

const cx = classNames.bind(style);

interface IProps {
    className?: string;
    children?: ReactNode;
}

const MethodistMenu: FC<IProps> = ({ className }) => {
    const [isOpenUserMenu, setIsOpenUserMenu] = useState(false);
    const match = useMatch('record-book');
    const lgUp = useBreakpoint('lg', 'up');
    const smUp = useBreakpoint('sm', 'up');

    const dispatch = useAppDispatch();

    const logout = () => {
        setIsOpenUserMenu(false);
        console.log('🚀 ~ file: MethodistMenu.tsx:41 ~ logout ~ logOut:');
        dispatch(logOut());
        dispatch(emptyBaseApi.util.resetApiState());
        dispatch(chatBaseApi.util.resetApiState());
        dispatch(resetChatSlice());
    };

    const user = useAppSelector(selectApiUser);

    // ToDo: Вытаскивать аватар из пользователя, а нотификации из сервиса нотификаций
    const dataUser = {
        message: 3,
        avatar: ''
    };

    // Todo: Обработка, если пользователь не пользователь (когда такое может быть вообще?) - когда ответ из апи не пришел
    if (!user) return (<></>);

    const getLinkToProfile = () => {
        switch (user.role) {
            case Role.Teacher:
                return `/user/${user.uuid}`;
            case Role.Student:
                return '/userProfile';
            default:
                return `/users/${user.uuid}/user`;
        }
    };

    return (
        <div className={cx('root', className)}>
            {user.role === Role.Student && smUp && (
                <Button link={true} className={cx(match?.pathname === '/record-book' && style['btn--active'])} btnType="green-light" to="/record-book">
                    Моя зачетка
                </Button>
            )}

            <UserMessageButton />

            {(user.role === Role.Student || user.role === Role.Teacher) && (
                <NotifyButton />
            )}

            <div className={style.user_avatar_wrapper}>
                {lgUp && <span>{`${user.firstName} ${user.lastName?.charAt(0)}.`}</span>}
                
                <div onClick={() => setIsOpenUserMenu(!isOpenUserMenu)} onKeyDown={() => setIsOpenUserMenu(!isOpenUserMenu)} role="button" tabIndex={0}>
                    <UserAvatar
                        avatar={dataUser.avatar} name={user.firstName} lastName={user.lastName} />
                </div>
                {
                    isOpenUserMenu && (
                        <UserMenu onClose={setIsOpenUserMenu}>
                            <div className={style.user_info}>
                                <p className={style.user_info__role}>{roleNames[user.role]}</p>
                                <p className={style.user_info__lastname}>{`${user.lastName} ${user.firstName} ${user.patronymic}`}</p>
                            </div>
                            <ul>
                                <li className={style.menu_item} onClick={() => setIsOpenUserMenu(false)}>
                                    <Button
                                        btnType="clear"
                                        className={style.menu_item__btn}
                                        link={true}
                                        to={getLinkToProfile()}
                                    >
                                        Мой Профиль
                                    </Button>
                                </li>
                                {
                                    checkRole(Role.Student) && <li className={style.menu_item} onClick={() => setIsOpenUserMenu(false)}>
                                        <Button
                                            btnType="clear"
                                            className={style.menu_item__btn}
                                            link={true}
                                            to="/studyProgramm"
                                        >
                                            Образование
                                        </Button>
                                    </li>
                                }
                                <li className={style.menu_item}>
                                    <Button
                                        btnType="clear"
                                        onClick={logout}
                                        className={style.menu_item__btn}
                                    >
                                        Выйти
                                    </Button>
                                </li>
                            </ul>
                        </UserMenu>
                    )
                }
            </div>
        </div>
    );
};

export default MethodistMenu;
