import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '@store/store';

interface ISettingsLSState {
    soundOn: boolean;
}

const initialState: ISettingsLSState = {
    soundOn: false
};

export const settingsLSSlice = createSlice({
    initialState,
    name: 'userSlice',
    reducers: {
        initLS: (state) => {
            const soundOn = localStorage.getItem('soundOn');
            state.soundOn = Boolean(soundOn);
        },
        changeSoundOn: (state) => {
            if (state.soundOn) {
                localStorage.removeItem('soundOn');
                state.soundOn = false;
            } else {
                localStorage.setItem('soundOn', 'true');
                state.soundOn = true;
            }
        }
    }
});

export default settingsLSSlice.reducer;

export const { initLS, changeSoundOn } = settingsLSSlice.actions;

export const selectSoundOn = (state: RootState) => state.settingsLSSlice.soundOn;
