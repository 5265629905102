import { Outlet } from 'react-router-dom';

import Main from '@components/Main';

import style from './ReportsLayout.module.scss';

const ReportsLayout = () => {
    return (
        <Main>
            <div>
                <h1 className={style.title}>Отчеты</h1>
            </div>

            <Outlet />
        </Main>
    );
};

export default ReportsLayout;
