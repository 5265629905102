import { FC } from 'react';

import cx from 'classnames';

import IconUI from '@components/core/IconUI';
import { TypeIcon } from '@components/core/IconUI/type';
import Checkbox from '@components/core/inputs/Checkbox';
import Radio from '@components/core/inputs/Radio';
import SelectBase from '@components/core/inputs/SelectStyled/SelectBase';
import CSVPreview from '@components/core/styledWrappers/CSVPreview';
import Paper from '@components/core/styledWrappers/Paper';
import { questionAnswerHeaderByType } from '@helpers/test';
import { EnumTestAnswer } from '@store/api/apiTypes';
import { AnswerResult, QuestionResult } from '@type/grades';

import style from './TestAttemptQuestion.module.scss';


interface IQuestion {
    question: QuestionResult;
    index: number;
}

// Если ответ не выбран и он не является верным, его не нужно отмечать никаким образом

const shouldValidate = (isCorrect: boolean, isChosen: boolean) => {
    return !isCorrect && !isChosen;
};

// Ошибка если: ответ был выбран и является неверным или ответ не был выбран и является верным

const getLabelClass = (isCorrect: boolean, isChosen: boolean) => {
    return (!isCorrect && isChosen) || (!isChosen && isCorrect) ? 'wrong' : 'right';
};

const getIconType = (isCorrect: boolean, isChosen: boolean): TypeIcon => {
    return (!isCorrect && isChosen) || (!isChosen && isCorrect) ? 'confirm-error' : 'success-icon';
};

const getInputs = (answers: AnswerResult[], questionType: EnumTestAnswer, questionUUid: string) => {
    if (questionType !== EnumTestAnswer.TO_MATCH) return (
        answers.map((answer, inputIndex) => {
            return (
                <div key={inputIndex} className={style.answers_input}>
                    {
                        questionType === EnumTestAnswer.SINGLE
                            ?
                            <Radio
                                disabled={true}
                                checked={answer.answerSimple.chosen}
                                group={questionUUid}
                                inputCentered={true}
                                className={cx(style.radio,
                                    shouldValidate(answer.answerSimple.isCorrect, answer.answerSimple.chosen) ||
                                    style[`${getLabelClass(answer.answerSimple.isCorrect, answer.answerSimple.chosen)}_answer`])
                                }
                            >
                                { answer.answerSimple.text }
                                {
                                    shouldValidate(answer.answerSimple.isCorrect, answer.answerSimple.chosen) ||
                                        <IconUI className={style.icon} typeIcon={
                                            getIconType(answer.answerSimple.isCorrect, answer.answerSimple.chosen)
                                        } />
                                }
                            </Radio>
                            :
                            <Checkbox
                                disabled={true}
                                checked={answer.answerMultiple.chosen}
                                containerClassName={cx(style.checkbox,
                                    shouldValidate(answer.answerMultiple.weight > 0, answer.answerMultiple.chosen) ||
                                    style[`${getLabelClass(answer.answerMultiple.weight > 0, answer.answerMultiple.chosen)}_answer`])
                                }
                            >
                                {answer.answerMultiple.text}
                                {
                                    shouldValidate(answer.answerMultiple.weight > 0, answer.answerMultiple.chosen) ||
                                        <IconUI className={style.icon} typeIcon={getIconType(answer.answerMultiple.weight > 0, answer.answerMultiple.chosen)} />
                                }
                            </Checkbox>
                    }
                </div>
            );
        })
    );

    else {

        return (
            answers.map((answer, inputIndex) => {
                return (
                    <div key={inputIndex} className={cx(style.answers_input, style.answers_select_wrapper)}>
                        {
                            questionType === EnumTestAnswer.TO_MATCH
                                &&
                                <>
                                    <p>{answer.answerToMatch.subQuestion}</p>
                                    <SelectBase
                                        isDisabled={true}
                                        className={style.answers_select}
                                        theme="dark"
                                        name="select"
                                        options={[]}
                                        onChange={() => null} //TODO: remove onChange
                                        placeholder="Выберите вариант"
                                        defaultValue={{ value: answer.answerToMatch.uuid, label: answer.answerToMatch.studentSubAnswer }}
                                    />
                                    {
                                        <IconUI className={style.icon}
                                            typeIcon={getIconType(answer.answerToMatch.studentSubAnswer === answer.answerToMatch.subAnswer, true)}
                                        />
                                    }
                                </>
                        }
                    </div>
                );
            })
        );
    }
};

const getRightAnswers = (answers: AnswerResult[], questionType: EnumTestAnswer) => {
    if (questionType === EnumTestAnswer.SINGLE) {
        return answers.filter(answer => {
            return answer.answerSimple.isCorrect;
        }).map(answer => answer.answerSimple.text).join(', ');
    }

    if (questionType === EnumTestAnswer.MULTIPLY) {
        return answers.filter(answer => {
            return answer.answerMultiple.weight > 0;
        }).map(answer => answer.answerMultiple.text).join(', ');
    }

    if (questionType === EnumTestAnswer.TO_MATCH) {
        return answers.map(answer => answer.answerToMatch.subQuestion + ' → ' + answer.answerToMatch.subAnswer).join(', ');
    }
};

const TestAttemptQuestion: FC<IQuestion> = ({ question, index }) => {
    return (
        <Paper className={style.question_paper}>
            <div id={question.uuid} className={style.question_wrapper} >
                <div className={style.question_header}>
                    Вопрос { ++index }
                </div>
                <div className={style.question_description}>
                    <CSVPreview content={question.question} className={style['csv-preview']} />
                </div>
                <div className={style.answers_header}>
                    { questionAnswerHeaderByType(question.questionType) }
                </div>
                <div className={style.answers_wrapper}>
                    {
                        getInputs(question.answers, question.questionType, question.uuid)
                    }
                </div>
                <div className={style.right_answer_description}>
                    Правильный ответ: {getRightAnswers(question.answers, question.questionType)}
                </div>
            </div>

        </Paper>
    );
};

export default TestAttemptQuestion;
