import { FC, useRef } from 'react';

import cx from 'classnames';
import { Control, useController } from 'react-hook-form';

import { baseConfig } from '@components/methodist/TinyEditor/config';
import { Editor } from '@tinymce/tinymce-react';

import { registerTemplatePlugin, template_codes } from './plugins/StatementTemplate';
import style from './tiny_editor.module.scss';

type TinyEditorProps = {
    name: string;
    control: Control<any>;
    initialValue?: string;
    onChange?: (data: any) => void;
    onInit?: () => void;
    error?: boolean
    disabled?: boolean;
    className?: string;
    height?: number | string;
    templates? : boolean;
};

const TinyEditor: FC<TinyEditorProps> = ({
    name,
    control,
    initialValue,
    onChange,
    onInit,
    error,
    disabled,
    className,
    height,
    templates
}) => {
    const { field: { onChange: formChange, ...field } } = useController({ control, name });
    const editorRef = useRef(null);
    const localOnChange = (data: any) => {
        formChange(data);
        onChange && onChange(data);
    };

    // Если включен режим templates, то добавляем еще один тулбар в конфинг,
    // с шорткодами шаблона
    if (templates)  {
        //@ts-ignore 
        baseConfig.toolbar2 = template_codes.map(template => template.value).join(' ');
        //@ts-ignore 
        baseConfig.setup = (outerEditor: Editor) => registerTemplatePlugin(outerEditor);
    }

    return (
        <div className={cx(style.wrapper, className, error && style.error)}>
            <Editor
                {...field}
                licenseKey='gpl'
                tinymceScriptSrc='/tinymce/tinymce.min.js'
                onEditorChange={localOnChange}
                initialValue={initialValue}
                
                onInit={(_evt, editor) =>{ 
                    // @ts-ignore
                    editorRef.current = editor;
                    onInit && onInit();}
                }
                // @ts-ignore
                init={{ ...baseConfig, height: height || baseConfig.height  }}
                disabled={disabled}
            />
        </div>
    );
};

export default TinyEditor;
