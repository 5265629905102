import { useEffect, useState } from 'react';

import { useNavigate, useOutletContext } from 'react-router-dom';

import { LinkUI } from '@components/core/LinkUI';
import { NewLoader } from '@components/core/NewLoader';
import { IProgrammContext } from '@layouts/Methodist/StudyProgrammesLayout/StudyProgrammesLayout';
import { useCreateStudyProgrammeSectionMutation } from '@store/api/programmesApi';
import { ICreateSectionData } from '@type/studyProgrammes';
import SectionForm from '@views/methodist/Users/UsersGroups/StudyProgrammes/SectionForm';

import { IProgrammSectionFormDataType } from '../SectionForm/SectionForm';
import style from './CreateSection.module.scss';


const CreateSection = () => {
    const navigate = useNavigate();
    const { programnInfo: { uuidGroup } } = useOutletContext<IProgrammContext>();
    const [defaultValuesForm] = useState<IProgrammSectionFormDataType>({
        name: '',
        description: ''
    });
    const [createSection, { isLoading, isSuccess }] = useCreateStudyProgrammeSectionMutation();
    const { programnInfo: { uuid, sections } } = useOutletContext<IProgrammContext>();

    const submitFormHandler = (formData: IProgrammSectionFormDataType) => {

        const createSectionData: ICreateSectionData = {
            name: formData.name,
            description: formData.description || '',
            position: sections.length + 1,
            uuidProgramme: uuid,
            uuidGroup: uuidGroup
        };

        createSection(createSectionData);
    };

    useEffect(() => {
        if (isSuccess) {
            navigate('./..');
        }
    }, [isSuccess]);

    if (isLoading) return <NewLoader />;

    return (
        // <Main title={category && disciplineData && `Учебные материалы – ${category.name}: ${disciplineData.name}, Структура, Добавление раздела`}>
        <>
            <div className={style.title_wrapper}>
                <LinkUI
                    to="./.."
                    typeIcon="arrowLink"
                />
                <h1>Добавить раздел</h1>
            </div>
            <SectionForm submitFormHandler={submitFormHandler} defaultValues={defaultValuesForm} />
        </>

    // </Main> 
    );
};

export default CreateSection;
