import { ChapterLectureBlockProgress } from '@store/api/apiTypes';

export const calculateLectureBlockProgress = (data: ChapterLectureBlockProgress[] | undefined) => {
    if (data) {
        const completedChapters = data.reduce((sum, chapter) => {
            if (chapter.status) {
                sum += 1;
            }
            return sum;
        }, 0);
        return Math.round(completedChapters * 100 / data.length);
    }
    return null;
};
