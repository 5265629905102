import { FC } from 'react';

import IconUI from '@components/core/IconUI';

import style from './carddragitem.module.scss';


export const CardDragItem: FC = () => {

    return <div className={style.main}>
        <IconUI typeIcon={'drag'} />
    </div>;
};
