import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { ReactComponent as CreateSection } from '@assets/image/create-section-svg.svg';
import { DNDWrapper } from '@components/core/DNDWrapper';
import StudyProgrammesSectionCard from '@components/core/DNDWrapper/DNDChildren/StudyProgrammesSectionCard';
import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { NewLoader } from '@components/core/NewLoader';
import { Helmet } from '@components/Helmet';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { useNavigationState } from '@hooks/useNavigationState';
import { PositionsItemsList } from '@store/api/apiTypes';
import { useDeleteStudyProgrammeMutation, useGetStudyProgrammeByUuidGroupQuery, useUpdateProgrammeSectionsPositionMutation } from '@store/api/programmesApi';
import { Role } from '@store/Roles';

import style from './StudyProgrammes.module.scss';

const StudyProgrammes = () => {
    const { state } = useNavigationState<{ currentGroup: string, openedGroups: string[] }>();
    const { navigate } = useNavigationState<{ currentGroup: string, openedGroups: string[] }>(state);
    const { uuidGroup } = useParams();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { data: programmData, isLoading } = useGetStudyProgrammeByUuidGroupQuery(uuidGroup as string);
    const [updatePositions] = useUpdateProgrammeSectionsPositionMutation();
    const [deleteProgramme, { isError: deleteErr, isSuccess: deleteSuc, isLoading: deleteLoading }] = useDeleteStudyProgrammeMutation();

    const updatePositionsSectionsHandler = (reorderSectionsList: PositionsItemsList) => {
        updatePositions({ positions: reorderSectionsList, uuidGroup: uuidGroup });
    };
    // TODO: shouldn't backend return already sorted sections?
    const filteredSections = useMemo(() => {
        const sections = programmData?.sections ? [...programmData.sections] : [];
        return sections.sort((a, b) => a.position - b.position);
    }, [programmData]);


    const deleteHandler = () => {
        if (programmData) deleteProgramme(programmData.uuid);
    };

    const getBackLink = () => {
        if (state) {
            return `/users/groups/${state.currentGroup}`;
        } else {
            return '/users';
        }
    };

    

    const displayDeleteModal = () => {
        setShowDeleteModal(true);
    };

    useEffect(() => {
        if(deleteSuc) navigate(getBackLink(), { state });
    }, [deleteErr, deleteSuc]);
    

    if (isLoading || deleteLoading) return <NewLoader />;

    return (
        <>
            <div className={style.title_wrapper}>
                <div className={style.title}>
                    <ButtonIcon
                        typeIcon="arrowLink"
                        onClick={() => navigate(getBackLink(), { state })}
                    />
                    <h1>Учебная программа</h1>
                </div>
                
                <Button
                    typeIcon="basket"
                    btnType="secondary"
                    onClick={displayDeleteModal}
                >
                    Удалить программу
                </Button>
            </div>
            <Helmet title='Учебная программа' />
            {
                filteredSections?.length ? (
                    <>
                        <div className={style.button_wrapper}>
                            {checkRole(Role.Methodist) ? (
                                <Button
                                    typeIcon="add"
                                    link={true}
                                    to="add-section"
                                >
                                    Новый раздел
                                </Button>
                            ) : <div />}
                        </div>

                        <Grid>
                            <Column size={10}>
                                {checkRole(Role.Methodist) ? (
                                    <DNDWrapper items={filteredSections} onOrder={updatePositionsSectionsHandler}>
                                        {
                                            filteredSections?.map(section => {
                                                return <StudyProgrammesSectionCard
                                                    key={section.uuid}
                                                    section={section}
                                                />;
                                            })
                                        }
                                    </DNDWrapper>
                                ) : filteredSections?.map(section => {
                                    return <StudyProgrammesSectionCard
                                        key={section.uuid}
                                        section={section}
                                    />;
                                })}

                            </Column>
                        </Grid>
                    </>
                ) : (
                    <div className={style.create_section}>
                        <div className={style.background_img} >
                            <CreateSection />
                        </div>
                        <h3 className={style.create_section__title}>Создайте свой первый раздел</h3>
                        <RoleCheck roles={Role.Methodist}>
                            <Button typeIcon="add" link={true} to="add-section">Новый раздел</Button>
                        </RoleCheck>
                    </div>
                )
            }
            <DeleteItemModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onDelete={deleteHandler}
                itemName={'Учебная программа'}
                itemType={'studyProgramme'}
            />
        </>
    );
};

export default StudyProgrammes;
