import { FC } from 'react';

import cx from 'classnames';

import { getScoreCaption, getTypeScore, ScoreType } from '@helpers/scores';
import { CloseTypes } from '@store/api/apiTypes';

import style from './DisciplineSticker.module.scss';


interface IDisciplineSticker {
    className?: string;
    score: number;
    closeType: CloseTypes;
}

const getScoreStatusClassName = (scoreType: false | ScoreType | null) => {
    switch(scoreType) {
        case 'excelent':
            return 'score__status--excellent';
        case 'success':
            return 'score__status--excellent';
        case 'good':
            return 'score__status--good';
        case 'medium':
            return 'score__status--satisfactory';
        case 'failed':
            return 'score__status--unsatisfactory';
        case 'bad':
            return 'score__status--unsatisfactory';

        default:
            return 'score__status--hide';
    }
};

const renderScore = (score: number, scoreType: false | ScoreType | null, className: string | undefined) => {
    return <div className={className}>
        <span className={cx(
            style.score__status,
            style[getScoreStatusClassName(scoreType)]
        )} />

        { getScoreCaption(score, scoreType) }
    </div>;
};

const DisciplineSticker: FC<IDisciplineSticker> = ({ className, score, closeType }) => {

    const scoreType = !!score && getTypeScore(score, closeType);

    return (
        <>
            {
                renderScore(score, scoreType, className)
            }
        </>
    );
};

export default DisciplineSticker;
