import { FC } from 'react';

import cx from 'classnames';

import IconUI from '@components/core/IconUI';
import { LinkUI } from '@components/core/LinkUI';
import { findLinks } from '@components/Linkify/helper';
import { SSOLink } from '@components/Linkify/SSOLink';
import { isSSOLink } from '@helpers/url';
import { EnumMaterialsType } from '@store/api/apiTypes';
import { IconRender } from '@views/methodist/Materials/Course/EditCourse/Structure/List/type';
import { IAdditionalMaterial } from '@views/student/StudentCourse/Materials/type';

import style from './AdditionalMaterial.module.scss';

const AdditionalMaterial: FC<IAdditionalMaterial> = ({ material, className, onFileDownLoad }) => {
    const { uuid, name, type } = material;
    const extension = type == 'File' ? material.file?.extension : '';

    const fileExtension = (ext: string) => {
        switch (ext) {
            case 'docx':
                return <IconUI typeIcon={'fileDoc'} />;
            case 'pdf':
                return <IconUI typeIcon={'filePdf'} />;
            case 'xlst':
                return <IconUI typeIcon={'fileXls'} />;
            case 'ppt':
                return <IconUI typeIcon={'filePpt'} />;
            case 'pptx':
                return <IconUI typeIcon={'filePptx'} />;
            case 'zip':
                return <IconUI typeIcon={'fileZip'} />;
            default:
                return <IconUI typeIcon={'defaultFile'} />;
        }
    };

    const icon: IconRender = {
        [EnumMaterialsType.Link]: <IconUI typeIcon={'link'} />,
        [EnumMaterialsType.File]: fileExtension(extension),
        [EnumMaterialsType.Page]: <IconUI typeIcon={'page'} />,
        [EnumMaterialsType.Test]: <IconUI typeIcon={'taskExam'} />,
        [EnumMaterialsType.Task]: <IconUI typeIcon={'taskTst'} />,
        [EnumMaterialsType.Video]: <IconUI typeIcon={'videoLecture'} />,
        [EnumMaterialsType.Lecture]: <IconUI typeIcon={'lecture'} />
    };

    const renderMaterial = () => {

        switch (type) {
            case EnumMaterialsType.File:
                return material.file ? <LinkUI
                    to={material.file.path}
                    typeLink={'default'}
                    target={'_blank'}
                    download={true}
                    className={style.link}
                    needIcon={false}
                    onClick={onFileDownLoad}
                >
                    {name}
                </LinkUI> : null;


            case EnumMaterialsType.Link:

                if (isSSOLink(material.link)) {

                    const link = findLinks(material.link)[0];

                    return <SSOLink domain={link.domain} href={link.value} value={link.value}>{name}</SSOLink>;
                }

                return <LinkUI
                    to={material.link}
                    typeLink={'default'}
                    target={'_blank'}
                    className={style.link}
                >
                    {name}
                </LinkUI>;


            case EnumMaterialsType.Page:
                return <LinkUI
                    to={`page/${uuid}`}
                    className={style.link}
                    target={'_blank'}
                >
                    {name}
                </LinkUI>;

            default:
                break;
        }
    };

    return (
        <div className={cx(style.item, className)}>
            <div>{icon[type]}</div>
            {
                renderMaterial()
            }
        </div>
    );
};

export default AdditionalMaterial;
