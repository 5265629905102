import React, { FC } from 'react';

import IconUI from '@components/core/IconUI';
import { useBreakpoint } from '@hooks/useBreakpoint';

import style from './Caption.module.scss';

interface IChatCaptionProps {
    title: string;
    onClose?: () => void;
}

const Caption:FC<IChatCaptionProps> = ({ title = 'Сообщения', onClose }) => {
    const mdDown = useBreakpoint('md', 'down');

    return (
        <div className={style.caption}>
            <h3>{title}</h3>
            {onClose && mdDown && (
                <div className={style.closeIcon} onClick={onClose}>
                    <IconUI typeIcon="close" />
                </div>
            )}
        </div>
    );
};

export default Caption;
