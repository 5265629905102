import { FC, ReactNode } from 'react';

import cx from 'classnames/bind';

import style from './footer.module.scss';

interface IProps {
    className?: string;
    children?: ReactNode;
}

const Footer: FC<IProps> = ({ className, children }) => {
    return <footer className={cx(style.root, className)}>{children}</footer>;
};

export default Footer;
