import React, { FC } from 'react';

import cx from 'classnames';

import { IColumnProps } from '@components/core/grid/type';
import { isEmptyObject } from '@helpers/isEmptyObject';

// ToDo: Поправить стили для column и таблиц в целом, сейчас ячейки могут быть больше, чем размер таблицы.
const Column: FC<IColumnProps> = ({
    className,
    size,
    children,
    width,
    height,
    styled
}) => {
    const classNames = cx(
        'column',
        size ? `col-${size}` : '',
        className
    );

    const inlineStyles = {
        width,
        height,
        ...styled
    };
    return (
        <div
            className={classNames}
            style={!isEmptyObject(inlineStyles) ? inlineStyles : undefined}
        >
            {children}
        </div>
    );
};

export { Column };
