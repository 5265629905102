export const backgroundColor = {
    controlWorks: '#FB3C3C',
    tests: '#06725E',
    lectures: '#E18FDA',
    auth: '#FFCD1A',
    events: '#77B6E7'
};

export const borderColor = {
    controlWorks: '#FB3C3C',
    tests: '#06725E',
    lectures: '#E18FDA',
    auth: '#FFCD1A',
    events: '#77B6E7'
};
