import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '@store/store';

interface IPassTestState {
    testCache: string;
}

const initialState: IPassTestState = {
    testCache: ''
};

export const passTestSlice = createSlice({
    initialState,
    name: 'passTestSlice',
    reducers: {
        setTestCache: (state, action: PayloadAction<string>) => {
            state.testCache = action.payload;
        }
    }
});

export default passTestSlice.reducer;

export const { setTestCache } = passTestSlice.actions;

export const selectTestCache = (state: RootState) => state.passTestSlice.testCache;
