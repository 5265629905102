import React, { FC } from 'react';

import cx from 'classnames';

import { DataList, DataListBody } from '@components/DataList';
import { DataListSelect } from '@components/DataList/DataListSelect/DataListSelect';
import { DebtDiscipline } from '@type/disciplines';

import style from './DisciplinesStep.module.scss';

type IDisciplinesStep = {
    selectedDisciplines: string[];
    setSelectedDisciplines: (disciplines: string[]) => void;
    disciplines: DebtDiscipline[];
}

const DisciplinesStep: FC<IDisciplinesStep> = ({ selectedDisciplines, setSelectedDisciplines, disciplines }) => {
    // const disciplinesByGroup = useGetSubscribedDisciplinesQuery(uuidGroup ? [uuidGroup] : skipToken);

    const toggleDiscipline = (event: React.ChangeEvent<HTMLInputElement>, uuid: string) => {
        if (event.target.checked) {
            setSelectedDisciplines([...selectedDisciplines, uuid]);
        } else {
            setSelectedDisciplines(selectedDisciplines.filter(disciplineUuid => disciplineUuid !== uuid));
        }
    };

    const toggleAllDisciplines = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedDisciplines(disciplines.map(discipline => discipline.uuid));
        } else {
            setSelectedDisciplines([]);
        }
    };

    return (
        <DataList>

            <DataListBody scrolled style={{ height: 516 }}>
                <div className={cx(style.item, style.check_all)}>
                    <div className={style.select}>
                        <DataListSelect
                            checked={!!disciplines.length && disciplines.length === selectedDisciplines.length}
                            onChange={toggleAllDisciplines}
                        />
                    </div>

                    <div className={style.content}>
                        Дисциплина
                    </div>
                </div>

                {
                    disciplines?.map(discipline => {
                        const isChecked = selectedDisciplines.includes(discipline.uuid);
                        return (
                            <div className={cx(style.item, isChecked && style.checked)} key={`di-${discipline.uuid}`}>
                                <div className={style.select}>
                                    <DataListSelect
                                        checked={isChecked}
                                        onChange={(event) => toggleDiscipline(event, discipline.uuid)}
                                    />
                                </div>

                                <div className={style.content}>
                                    <div className={style.title}>{discipline.name}</div>
                                </div>
                            </div>
                        );

                    })
                }
            </DataListBody>
        </DataList>
    );
};

export default DisciplinesStep;
