import { useEffect } from 'react';

import { preventPageClose } from '@helpers/preventPageClose';

const usePreventPageClose = (condition: boolean) => {
    useEffect(() => {
        if (condition) {
            window.addEventListener('beforeunload', preventPageClose);
        } else {
            window.removeEventListener('beforeunload', preventPageClose);
        }
    }, [condition]);

    useEffect(()=>{
        return () => {
            window.removeEventListener('beforeunload', preventPageClose);
        };
    },[]);
};

export default usePreventPageClose;
