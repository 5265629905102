import { FC } from 'react';

import Button from '@components/core/inputs/Button';
import Loader from '@components/core/Loader';
import Modal from '@components/Modal';
import useThrottle from '@hooks/useThrottle';

import style from './delete_item_modal.module.scss';

type itemType =
    'default'
    | 'discipline'
    | 'material'
    | 'section'
    | 'lectureBlock'
    | 'lecture'
    | 'lectureTopic'
    | 'lectureChapter'
    | 'videoLecture'
    | 'user'
    | 'event'
    | 'attempt'
    | 'taskAttempt'
    | 'deadlines'
    | 'extraSessionItem'
    | 'extraSession'
    | 'studyProgramme'

interface IDeleteItemModal {
    isOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
    itemName?: string;
    itemType?: itemType;
    description?: string;
    loading?: boolean;
}

const DeleteItemModal: FC<IDeleteItemModal> = ({
    isOpen,
    onClose,
    onDelete,
    itemName,
    itemType = 'default',
    description,
    loading
}) => {

    const modalTitle = () => {
        let name = '';
        switch (itemType) {
            case 'discipline':
                name = 'дисциплину';
                break;

            case 'material':
                name = 'материал';
                break;

            case 'section':
                name = 'раздел';
                break;

            case 'lectureBlock':
                name = 'блок лекций';
                break;

            case 'lecture':
                name = 'лекцию';
                break;

            case 'lectureTopic':
                name = 'тему';
                break;

            case 'lectureChapter':
                name = 'главу';
                break;

            case 'videoLecture':
                name = 'видеолекцию';
                break;

            case 'user':
                name = 'пользователя';
                break;

            case 'event':
                name = 'Мероприятие';
                break;

            case 'attempt':
                name = 'попытку';
                break;

            case 'taskAttempt':
                name = 'попытку сдачи';
                break;

            case 'deadlines':
                name = 'сроки сдачи';
                break;

            case 'extraSessionItem':
                name = 'дисципилину из доп. сессии';
                break;

            case 'extraSession':
                name = 'выбранные доп. сессии';
                break;
                
            case 'studyProgramme':
                name = 'учебную программу';
                break;

            case 'default':
                name = 'выбранный элемент';
                break;
        }

        return `Удалить ${name}?`;
    };

    const getDescriptionText = () => {
        if (description) return description;
        if (itemType !== 'user') return `Выбрано для удаления: ${itemName}`;
        return `Выбран для удаления: ${itemName}`;
    };

    const throttledSubmit = useThrottle(onClose, 1000);

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title={modalTitle()}
        >

            {!loading ? (
                <>
                    <p className={style.modal__text}>
                        {getDescriptionText()}
                    </p>
                    <div className={style.button_wrapper}>
                        <Button
                            onClick={throttledSubmit}
                            btnType="secondary"
                        >
                            Отмена
                        </Button>
                        <Button
                            onClick={onDelete}
                            btnType="danger"
                        >
                            Удалить
                        </Button>
                    </div>
                </>
            ) : (
                <div className={style.row}>
                    <Loader className={style.loader} />
                    <p className={style.loading_text}>
                        Это может занять некоторое время.
                        {'\n'}
                        Пожалуйста, не обновляйте страницу.
                    </p>
                </div>
            )}

        </Modal>
    );
};

export default DeleteItemModal;
