import { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import GroupModal from '@components/methodist/GroupModal';
import { IModalImportForm } from '@components/methodist/GroupModal/GroupModal';
import InheritModal from '@components/methodist/InheritModal';
import { RoleCheck } from '@components/RoleCheck';
import { makeGroupTreeFlat } from '@helpers/groupTree';
import { useNavigationState } from '@hooks/useNavigationState';
import { CreateGroupDataRequest, GroupData, GroupTree } from '@store/api/apiTypes';
import { useCreateGroupMutation, useDeleteGroupMutation, useGetGroupsTreeQuery, useUpdateGroupMutation } from '@store/api/groupsApi';
import { useLazyGetStudyProgrammeGroupsInfoQuery } from '@store/api/programmesApi';
import { Role } from '@store/Roles';
import { StudyProgrammGroupInfo } from '@type/studyProgrammes';

import { GroupItemCollapseElement } from './GroupItemCollapseElement';
import style from './users_groups.module.scss';

export interface GroupTreeColumn {
    content: string
}

interface IProps {
    className?: string;
}

const columnsStyles: React.CSSProperties[] = [
    {
        flexBasis: '372px'
    },
    {
        flexBasis: '184px',
        flexShrink: '0'
    }
];

const UsersGroups: FC<IProps> = () => {
    const { uuidGroup } = useParams();

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showInheritModal, setShowInheritModal] = useState(false);
    const [groupsTreeCurrent, setGroupsTreeCurrent] = useState<GroupTree | null>(null);
    const [groupsTreeAll, setGroupsTreeAll] = useState<GroupTree | undefined>(undefined);
    const [currentGroup, setCurrentGroup] = useState<GroupTree | undefined>(undefined);
    const [groupPrograms, setGroupPrograms] = useState<StudyProgrammGroupInfo[]>([]);

    const [createGroup] = useCreateGroupMutation();
    const [updateGroup] = useUpdateGroupMutation();
    const [deleteGroup] = useDeleteGroupMutation();
    const [openedGroups, setOpenedGroups] = useState<string[]>([]);

    const { state } = useNavigationState<{openedGroups: string[]; currentGroup: string}>();

    const groupsTree = useGetGroupsTreeQuery('');
    const [getProgrammsInfo, { isFetching: isInfoLoading, isSuccess: isInfoSuccess, data: programmsInfo }] = useLazyGetStudyProgrammeGroupsInfoQuery();

    useEffect(() => {
        if (groupsTreeCurrent?.UUID !== state?.currentGroup) {
            setOpenedGroups([]);
        }
    }, [groupsTreeCurrent, state]);

    useEffect(() => {
        setOpenedGroups(state?.openedGroups || []);
    }, [state]);

    useEffect(() => {
        if (groupsTree.isSuccess) {

            setGroupsTreeAll(groupsTree.data);

            const findGroup = groupsTree.data.childs.find((groupBranch) => groupBranch.UUID === uuidGroup);

            const flatGroups = findGroup ? [uuidGroup as string, ...makeGroupTreeFlat(findGroup.childs).map(group => group.UUID)] : [uuidGroup as string];
            getProgrammsInfo(flatGroups);

            //TODo: Eсли не нашли группу, нужно показывать ошибку
            setGroupsTreeCurrent(findGroup ? findGroup : null);
        }
    }, [uuidGroup, groupsTree.isSuccess, groupsTree.isFetching]);

    useEffect(() => {
        if (isInfoSuccess && programmsInfo) {
            setGroupPrograms(programmsInfo);
        }
    }, [isInfoLoading, isInfoSuccess]);

    const onCreateSubmit = (formData: IModalImportForm) => {
        const groupData: CreateGroupDataRequest = {
            parentUUID: formData.select,
            name: formData.groupName,
            isInherit: formData.inherit
        };

        createGroup(groupData);
        setShowCreateModal(false);
    };

    const onUpdateSubmit = (formData: IModalImportForm) => {
        const groupData: GroupData = {
            parentUUID: formData.select,
            name: formData.groupName,
            UUID: currentGroup?.UUID as string,
            isInherit: formData.inherit
        };

        updateGroup(groupData);
        setShowUpdateModal(false);
    };

    const onDeleteSubmit = (uuid: string) => {

        deleteGroup(uuid);
        setShowDeleteModal(false);
    };

    const openGroupModal = (modalGroup: GroupTree) => {
        setCurrentGroup(modalGroup);
        setShowUpdateModal(true);
    };

    const openDeleteModal = (modalGroup: GroupTree) => {
        setCurrentGroup(modalGroup);
        setShowDeleteModal(true);
    };

    const openInheritModal = (modalGroup: GroupTree) => {
        setCurrentGroup(modalGroup);
        setShowInheritModal(true);
    };

    const handleSetOpenGroup = (groupId: string) => {
        if (openedGroups.includes(groupId)) {
            setOpenedGroups(prev => prev.filter(id => id !== groupId));
        } else {
            setOpenedGroups(prev => [...prev, groupId]);
        }
    };

    if (groupsTree.isLoading || !groupsTreeCurrent || isInfoLoading) return <NewLoader />;

    return (
        <Main title="Управление пользователями">
            <h1 className={style.title}> {groupsTreeCurrent.name} </h1>

            <RoleCheck roles={Role.Methodist}>
                <Button
                    onClick={() => setShowCreateModal(true)}
                    typeIcon="add"
                >
                    Добавить группу
                </Button>
            </RoleCheck>

            <div className={style.table_wrapper}>
                <div className={style.table_header}>
                    <div>
                        Группа
                    </div>
                    <div>
                        Всего пользователей
                    </div>
                </div>
                <GroupItemCollapseElement
                    group={groupsTreeCurrent}
                    groupsProgramms={groupPrograms}
                    openGroupModal={openGroupModal}
                    openDeleteModal={openDeleteModal}
                    openInheritModal={openInheritModal}
                    columnsStyles={columnsStyles}
                    openedGroups={openedGroups}
                    setOpenedGroups={handleSetOpenGroup}
                    currentGroup={groupsTreeCurrent?.UUID}
                />
            </div>

            {
                showCreateModal &&  <GroupModal
                    isOpen={showCreateModal}
                    onClose={() => setShowCreateModal(false)}
                    onSubmit={onCreateSubmit}
                    groupsTree={groupsTreeAll}
                />
            }
            {
                (currentGroup && showUpdateModal) && <GroupModal
                    isOpen={showUpdateModal}
                    onClose={() => setShowUpdateModal(false)}
                    onSubmit={onUpdateSubmit}
                    groupsTree={groupsTreeAll}
                    defaultValue={currentGroup}
                />
            }
            {
                (currentGroup && currentGroup.isInherit && showInheritModal) && <InheritModal
                    isOpen={showInheritModal}
                    onClose={() => setShowInheritModal(false)}
                    defaultValue={currentGroup}
                />
            }
            {
                (currentGroup && showDeleteModal) &&  <DeleteItemModal
                    isOpen={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    onDelete={() => onDeleteSubmit(currentGroup.UUID)}
                    itemName={currentGroup?.name || ''}
                />
            }

        </Main>
    );
};

export default UsersGroups;
