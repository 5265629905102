import { FC } from 'react';

import cx from 'classnames';
import { DateTime } from 'ts-luxon';

import { Grid, Column } from '@components/core/grid';

import style from './CalendarHeader.module.scss';


export const CalendarHeader:FC = () => {
    return <Grid className={cx(style.grid, style.header)}>
        {
            [...Array(7)].map((_, i) => (
                <Column size={1} className={cx(style.column, style.column__header)} key={i}>
                    <span className={style.week_day_name}>
                        { DateTime.now().startOf('week').setLocale('ru').plus({ day: i }).toFormat('ccc') }
                    </span>
                </Column>
            ))
        }
    </Grid>;
};