import { ScoringMaterials, UserGradeMaterial, UsersGradesResponse } from '@type/grades';
import { GroupByDiscipline } from '@type/groups';
import { IScoreTableColumn, IScoreTableData } from '@views/methodist/Materials/Course/Scores/ScoreTableView/ScoreTable';

interface objectMaterials {
    [key: string]: UserGradeMaterial,
}

export const prepareColumns = (scoringQuery: ScoringMaterials) => {
    const dynamicColumns = scoringQuery.map((column) => {
        return {
            header: column.name,
            width: 240
        };
    });

    return [
        {
            header: 'Название',
            width: 350

        },
        ...dynamicColumns as IScoreTableColumn[],
        {
            header: 'Итоговая оценка',
            width: 240,
            last: true
        }
    ] as IScoreTableColumn[];
};

export const prepareColumnData = (gradesQuery: UsersGradesResponse, scoringMaterials: ScoringMaterials) : IScoreTableData[][] => {
    return gradesQuery.users.map((gradeInfo) => {
        const closeType = gradeInfo.user.closeType;

        const tempArray: IScoreTableData[] = [{
            value: gradeInfo.user.fullName,
            userId: gradeInfo.user.uuid,
            isExist: true,
            isEditable: false
        }];

        const userMaterials: objectMaterials = gradeInfo.materials.reduce((prev, curr) => ({ ...prev, [curr.uuid]: curr }), {});

        // Динамические колонки
        const dynamicColumnData = scoringMaterials.map((material) => {

            if (material.uuid in userMaterials) {
                return {
                    value: userMaterials[material.uuid].grade,
                    uuid: userMaterials[material.uuid].uuid,
                    uuidGrade: userMaterials[material.uuid].uuidGrade,
                    uuidMaterial: material.uuid,
                    uuidDiscipline: material.disciplineUuid,
                    isEditable: true,
                    isExist: true,
                    default: material,
                    userId: gradeInfo.user.uuid
                } as IScoreTableData;
            }

            return {
                isExist: false,
                uuidMaterial: material.uuid,
                userId: gradeInfo.user.uuid,
                default: material
            } as IScoreTableData;
        });

        tempArray.push(dynamicColumnData as IScoreTableData);

        tempArray.push({
            value: gradeInfo.gradeForDiscipline,
            userId: gradeInfo.user.uuid,
            isEditable: true,
            isTotal: true,
            closeType
        });

        return tempArray.flat();
    });

};
