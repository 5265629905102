import React, { useRef, useState } from 'react';

import cx from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

import Button from '@components/core/inputs/Button';
import Loader from '@components/core/Loader';
import { useToggle } from '@hooks/useToggle.hook';
import { testsApi } from '@store/api/testsApi';

import styles from './ExportQuestions.module.scss';


interface ExportQuestionsProps {
    testUuid?: string;
    isQuestionExist: boolean;
}

export const ExportQuestions: React.FC<ExportQuestionsProps> = ({ testUuid, isQuestionExist = false }) => {
    const [isOpen, { off, toggle }] = useToggle();

    const containerRef = useRef<HTMLDivElement>(null);

    const [docxLoading, setDocxLoading] = useState(false);
    const [txtLoading, setTxtLoading] = useState(false);

    const [triggerDocx] = testsApi.endpoints.exportQuestionsDocx.useLazyQuerySubscription();
    const [triggerTxt] = testsApi.endpoints.exportQuestionsTxt.useLazyQuerySubscription();

    useOnClickOutside(containerRef, () => {
        if (isOpen) {
            off();
        }
    });

    const handleDocx = async () => {
        if (testUuid && !docxLoading) {
            setDocxLoading(true);
            const result = await triggerDocx(testUuid);
            if (result.data) {
                window.open(result.data.path);
            }
            setDocxLoading(false);
            off();
        }
    };

    const handleTxt = async () => {
        if (testUuid && !txtLoading) {
            setTxtLoading(true);
            const result = await triggerTxt(testUuid);
            if (result.data) {
                window.open(result.data.path);
            }
            setTxtLoading(false);
            off();
        }
    };

    return (
        <div ref={containerRef} className={styles.container}>
            <Button
                btnType="secondary"
                style={{ height: 34 }}
                onClick={toggle}
                disabled={!isQuestionExist}
            >
                Экспорт банка вопросов
            </Button>
            {isOpen && (
                <div className={styles.button_wrapper}>
                    <div className={cx(styles.item, docxLoading && styles.disabled)} onClick={handleDocx}>
                        {!docxLoading ? 'Скачать docx - файл' : <Loader />}
                    </div>
                    <div className={cx(styles.item, txtLoading && styles.disabled)} onClick={handleTxt}>
                        {!txtLoading ? 'Скачать txt - файл' : <Loader />}
                    </div>
                </div>
            )}
        </div>
    );
};
