import { FC, useEffect, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-icon.svg';
import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoverPasswordMutation } from '@store/api/authApi';

import style from './recover_step.module.scss';

const recoverSchema = yup.object({
    email: yup
        .string()
        .email('Пожалуйста, уĸажите ĸорреĸтный адрес')
        .required('Введите текст')
});

// export type RecoverInput = yup.TypeOf<typeof recoverSchema>;
export type RecoverInput = yup.InferType<typeof recoverSchema>;
interface IRecoverStep {
    setRecoverPassword: (value: boolean) => void;
}

export const RecoverStep: FC<IRecoverStep> = ({ setRecoverPassword }) => {
    const [result, setResult] = useState<'success' | 'error' | null>(null);

    const [recoverPassword, { isLoading, isError, error, isSuccess }] = useRecoverPasswordMutation();

    const methods = useForm<RecoverInput>({
        resolver: yupResolver(recoverSchema),
        mode: 'onBlur'
    });

    const { handleSubmit, setError } = methods;

    const onSubmit: SubmitHandler<RecoverInput> = data => {
        recoverPassword(data);
    };


    useEffect(() => {
        if (isSuccess) {
            setResult('success');
        }

        if (isError) {
            setError('email', { type: 'custom', message: 'Не смогли обработать запрос' });
        }

    }, [isLoading, isSuccess, isError]);

    if (result === 'success') {
        return (
            <>
                <Link
                    onClick={() => setRecoverPassword(false)}
                    className={style.back_link}
                    to={'#'}
                >
                    <ArrowIcon style={{ transform: 'rotate(90deg)' }} />
                    Вернуться назад
                </Link>
                <div className={style.notification_success}>
                    <p>
                        Письмо с инструкцией по восстановлению пароля отправлено
                        вам на email
                    </p>
                </div>
            </>
        );
    }

    return (
        <>
            <Link
                onClick={() => setRecoverPassword(false)}
                className={style.back_link}
                to={'#'}
            >
                <ArrowIcon style={{ transform: 'rotate(90deg)' }} />
                Вернуться назад
            </Link>
            <h1 className={style.title}>Восстановление пароля</h1>
            <p className={style.text}>
                Укажите email, который вы использовали для входа в личный
                кабинет.
            </p>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className={style.input_layout}>
                        <InputControl
                            name="email"
                            type="email"
                            placeholder="E-mail"
                        />
                    </div>
                    <Button
                        type={'submit'}
                        fullWidth={true}
                        className={style.button_submit}
                    >
                    Отправить
                    </Button>
                </form>
            </FormProvider>
            
        </>
    );
};
