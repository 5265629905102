import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '@store/store';


type IInfiniteSelectScrollSelect = {
    name: string
    isInfiniteScroll: boolean
    hasMore: boolean
    isLoading: boolean
}

interface IInfiniteSelectScrollState {
    selects: IInfiniteSelectScrollSelect[]
}

const initialState: IInfiniteSelectScrollState = {
    selects: []
};

export const infiniteSelectScrollSlice = createSlice({
    initialState,
    name: 'infiniteSelectScrollSlice',
    reducers: {
        addOrUpdateSelect: (state, action: PayloadAction<IInfiniteSelectScrollSelect>) => {
            const selectIndex = state.selects.findIndex(select => select.name === action.payload.name);

            if (selectIndex != -1) {
                state.selects[selectIndex] = action.payload;
                return;
            }
            
            state.selects.push(action.payload);
        }
    }
});

export default infiniteSelectScrollSlice.reducer;

export const { addOrUpdateSelect } = infiniteSelectScrollSlice.actions;

export const selectInfiniteScrollSelectByName = (state: RootState, name: string) => 
    state.infiniteSelectScrollSlice.selects.find(select => select.name === name) || {} as IInfiniteSelectScrollSelect;