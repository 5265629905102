import { createContext, useContext } from 'react';

import { IStorageFile } from '@store/api/apiTypes';

interface MessagesContext {
    checkAndRequestChunk: () => Promise<unknown> | undefined;
    canUpdate: boolean;
    submitTextMessage: (messageText: string) => void;
    submitFileMessage: (fileData: IStorageFile, file: File) => void;
}

export const messagesContext = createContext<MessagesContext | null>(null);

export function useMessages() {
    const ctx = useContext(messagesContext);
    if (ctx === null) throw new Error('messagesContext must be defined');
    return ctx;
}
