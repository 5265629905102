import React from 'react';

import { useDropzone } from 'react-dropzone';

import { useMessages } from '@components/Chat/parts/Messages/context';
import { fileTypes, IStorageFile } from '@store/api/apiTypes';


import style from './FileDropWrapper.module.scss';

interface FileDropWrapperProps {
    children: React.ReactNode;
    className?: string
}

export const FileDropWrapper: React.FC<FileDropWrapperProps> = ({ children, className }) => {
    const { submitFileMessage } = useMessages();

    const fileChange = async (files: File[] | null) => {
        const file = files?.[0];
        if (file && !Array.isArray(file)) {
            const url = URL.createObjectURL(file);
            const splitedName = file.name.split('.');

            const fileData: IStorageFile = {
                uuid: Date.now().toString(),
                path: url,
                title: file.name,
                extension: splitedName[splitedName.length - 1] ?? '',
                size: file.size,
                userUuid: '',
                createdAt: '',
                updatedAt: '',
                type: fileTypes.Public
            };

            submitFileMessage(fileData, file);
        }
    };

    const onDrop = (files: File[] | null) => {
        fileChange(files);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true });

    return (
        <div  {...getRootProps()} className={className}>
            <input {...getInputProps()} />
            {children}
            {isDragActive && (
                <div className={style['drop-box-wrapper']}>
                    <div className={style['drop-box']}>
                        <span>Перетяните файл сюда</span>
                    </div>
                </div>
            )}
        </div>
    );
};
