import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import Loader from '@components/core/Loader';
import { useSetEventToLogMutation } from '@store/api/eventsLogApi';
import { useGetDisciplineMaterialsListQuery } from '@store/api/materialsApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import Materials from '@views/student/StudentCourse/Materials';

import style from './EducationalMaterials.module.scss';

const EducationalMaterials: FC = () => {
    const { uuidDiscipline } = useParams();
    const { data: materialsList, isFetching } = useGetDisciplineMaterialsListQuery(uuidDiscipline as string);
    const [writeActionToEventLog] = useSetEventToLogMutation();

    const user = useAppSelector(selectApiUser);

    const onFileDownLoad = () => {
        writeActionToEventLog({
            uuidUser: user?.uuid || '',
            name: 'Скачивание лекций',
            type: 'lectures'
        });
    };

    if (isFetching) return <Loader />;

    return (
        <div>
            <h3 className={style.title}>Учебные материалы</h3>
            <ul>
                {
                    materialsList?.map(material => (
                        <li key={material.uuid}>
                            <Materials.AdditionalMaterial
                                material={material}
                                className={style.material}
                                onFileDownLoad={onFileDownLoad}
                            />
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};

export default EducationalMaterials;
