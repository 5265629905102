import { createContext, FC } from 'react';

import { CardDragItem } from '@components/core/DNDWrapper/CardDragItem';
import { Draggable, DraggableProvidedDragHandleProps, DraggingStyle, NotDraggingStyle } from '@hello-pangea/dnd';


import style from './dnditem.module.scss';

export interface IDNDItemProps {
    children?: React.ReactNode
    index?: number;
    draggableId?: string;
    isDefaultDraggableHandler?: boolean;
}

export const DraggableContext = createContext<DraggableProvidedDragHandleProps | null>(null); 

export const DNDItem: FC<IDNDItemProps> = ({ children, draggableId, index = 0, isDefaultDraggableHandler }) => {
    const getItemStyle = (draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
        ...draggableStyle
    });

    return (
        <Draggable draggableId={draggableId ?? ''} index={index}>
            {(providedDraggable) => (
                <div
                    className={style.root}
                    ref={providedDraggable.innerRef}
                    {...providedDraggable.draggableProps}
                    style={getItemStyle(
                        providedDraggable.draggableProps.style
                    )}>
                        
                    {
                        !isDefaultDraggableHandler && <div className={style.handle} {...providedDraggable.dragHandleProps}> 
                            <CardDragItem /> 
                        </div> 
                    }

                    <DraggableContext.Provider value={providedDraggable.dragHandleProps}>
                        {children}
                    </DraggableContext.Provider>
                </div>
            )}
        </Draggable>
    );
};
