import { FC, useMemo, useState } from 'react';

import cx from 'classnames';
import { useNavigate, useOutletContext } from 'react-router-dom';

import ButtonDrop from '@components/core/inputs/Button/ButtonDrop';
import { DropMenuItem } from '@components/core/styledWrappers/DropMenu';
import Paper from '@components/core/styledWrappers/Paper';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { IProgrammContext } from '@layouts/Methodist/StudyProgrammesLayout/StudyProgrammesLayout';
import { CreateFileData, CreateLinkData, PositionsItemsList, UpdateFileData, UpdateLinkData } from '@store/api/apiTypes';
import { 
    useCreateProgrammeFileBlockMutation, 
    useCreateProgrammeLinkBlockMutation, 
    useDeleteProgrammeBlockMutation, 
    useDeleteStudyProgrammeSectionMutation, 
    useUpdateProgrammeBlocksPositionMutation, 
    useUpdateProgrammeFileBlockMutation, 
    useUpdateProgrammeLinkBlockMutation 
} from '@store/api/programmesApi';
import { Role } from '@store/Roles';
import { StudyProgrammeSection } from '@type/studyProgrammes';
import AddFileModal from '@views/methodist/Materials/Course/AddFileModal/AddFileModal';
import AddLinkModal from '@views/methodist/Materials/Course/AddLinkModal/AddLinkModal';
import { List } from '@views/methodist/Materials/Course/EditCourse/Structure/List';


import { CardHeader } from '../SectionCard/CardHeader';
import { CardSubHeader } from '../SectionCard/CardSubHeader';
import style from './sectionCard.module.scss';


interface ICardProps {
    section: StudyProgrammeSection;
}

const StudyProgrammesSectionCard: FC<ICardProps> = ({ section }) => {
    const { uuid, name, description, materials } = section;
    const { programnInfo: { uuidGroup } } = useOutletContext<IProgrammContext>();
    const navigate = useNavigate();

    const [createLink] = useCreateProgrammeLinkBlockMutation();
    const [createFile, { isSuccess: isCreateFileSuccess }] = useCreateProgrammeFileBlockMutation();
    const [fetchDeleteSection] = useDeleteStudyProgrammeSectionMutation();
    const [deleteMaterial] = useDeleteProgrammeBlockMutation();
    const [updateLink] = useUpdateProgrammeLinkBlockMutation();
    const [updateFile] = useUpdateProgrammeFileBlockMutation();
    const [updatePositions] = useUpdateProgrammeBlocksPositionMutation();

    const [isOpenAddLinkModal, setIsOpenAddLinkModal] = useState<boolean>(false);
    const [isOpenAddFileModal, setIsOpenAddFileModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const updatePositionsSectionsHandler = (reorderSectionsList: PositionsItemsList) => {
        updatePositions({ positions: reorderSectionsList, uuidGroup: uuidGroup });
    };

    const onMaterialDelete = (materialUuid: string) => {
        deleteMaterial({ uuidItem: materialUuid, uuidGroup: uuidGroup });
    };

    const editSectionHandler = () => {
        navigate(`edit-section/${uuid}`);
    };

    const deleteSectionHandler = () => {
        fetchDeleteSection({ uuidItem: uuid, uuidGroup: uuidGroup });
    };

    const onLinkCreate = (linkData: CreateLinkData) => {
        createLink({ ...linkData, uuidGroup: uuidGroup });
    };

    const onLinkUpdate = (linkData: UpdateLinkData) => {
        updateLink({ ...linkData, uuidGroup: uuidGroup });
    };

    const onFileCreate = (fileData: CreateFileData) => {
        createFile({ ...fileData, uuidGroup: uuidGroup });
    };

    const onFileUpdate = (fileData: UpdateFileData) => {
        updateFile({ ...fileData, uuidGroup: uuidGroup });
    };

    const headerProps = {
        title: name,
        labels: { published: true, blockedGroups: [] },
        controlsEvents: {
            onEdit: editSectionHandler,
            onDelete: () => setShowDeleteModal(true)
        }
    };

    return (
        <>
            <Paper className={style.container}>
                <div className={style.inner}>

                    <div className={style.header}>
                        <CardHeader {...headerProps} />
                    </div>

                    <div className={style.subHeader}>
                        <CardSubHeader subTitle={description} />
                    </div>

                    <div className={cx(style.list, {
                        [style['list--inspector']]: checkRole(Role.Inspector)
                    })}>
                        <List 
                            materials={materials ? materials : []} 
                            deleteLectureBlock={onMaterialDelete}
                            deleteVideoBlock={onMaterialDelete}
                            deleteMaterial={onMaterialDelete}
                            updateLink={onLinkUpdate}
                            updateFile={onFileUpdate}
                            isDraggable={true}
                            onOrder={updatePositionsSectionsHandler}
                        />
                    </div>

                    <RoleCheck roles={Role.Methodist}>
                        <div className={style.action}>
                            <ButtonDrop
                                btnType="outline"
                                typeIcon="addCircle"
                                buttonChild="Добавить"
                            >
                                <DropMenuItem onClick={() => setIsOpenAddLinkModal(true)}>
                                    Ссылка
                                </DropMenuItem>
                                <DropMenuItem onClick={() => {
                                    navigate(`add-page/${uuid}`);
                                }}>
                                    Страница
                                </DropMenuItem>
                                <DropMenuItem onClick={() => setIsOpenAddFileModal(true)}>
                                    Загрузить файл
                                </DropMenuItem>
                            </ButtonDrop>
                        </div>
                    </RoleCheck>
                </div>
            </Paper>

            <AddLinkModal
                uuidSection={section.uuid}
                isOpen={isOpenAddLinkModal}
                onClose={setIsOpenAddLinkModal}
                createLink={onLinkCreate}
                materials={materials}
            />
            <AddFileModal
                uuidSection={section.uuid}
                isOpen={isOpenAddFileModal}
                onClose={setIsOpenAddFileModal}
                materials={materials}
                isFileCreationSuccess={isCreateFileSuccess}
                createFile={onFileCreate}
            />
            <DeleteItemModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onDelete={deleteSectionHandler}
                itemName={name}
                itemType="section"
            />
        </>
    );
};

export default StudyProgrammesSectionCard;
