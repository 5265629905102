import React, { FC } from 'react';

import classNames from 'classnames/bind';

import { TUploadFile } from '@components/core/inputs/FileUpload/type';

import styles from './filehiddeninput.module.scss';

const cx = classNames.bind(styles);

interface IBaseUploadProps {
    types?: string[];
    inputRef?: React.RefObject<HTMLInputElement> | null;
    fileChange?: (file: TUploadFile | TUploadFile[]) => void;
    name?: string;
    isMulti?: boolean;
}
const checkValidFile=(typesFiles:string[] | undefined,fileType:string | undefined)=>{
    if(typeof typesFiles==='undefined' || typesFiles.length===0) return true;
  
    return fileType && typesFiles.includes(fileType);
};

export const FileHiddenInput: FC<IBaseUploadProps> = ({ types, fileChange, inputRef, name, isMulti }) => {
    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
 
        if (fileChange) {
            if (isMulti && e.target.files) {
                const transformFilesList = [];
                for (let i = 0; i < e.target.files?.length; i++) {
                  
                    if(checkValidFile(types,e.target.files?.item(i)?.type))
                        transformFilesList.push(e.target.files?.item(i));
                }
                fileChange(transformFilesList);
            } else {
                if(checkValidFile(types,e.target.files?.item(0)?.type))
                    fileChange(e.target.files?.item(0));
            }
        }
    };

    return (
        <div className={cx('root')}>
            <input
                name={name}
                type={'file'}
                accept={types?.join(', ')}
                ref={inputRef}
                onChange={onFileChange}
                multiple={isMulti}
            />
        </div>
    );
};
