import React from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import IconUI from '@components/core/IconUI';
import { Helmet } from '@components/Helmet';

import { IContactCardsProps } from './ContactCard/ContactCard';
import { ContactAccount, ContactDeanery, ContactMethodist, ContactTech } from './ContactDudes';
import { contactsProfiles } from './contactProfiles';
import style from './Contacts.module.scss';

export interface IDudesCardsProps {
    items: IContactCardsProps[]
}

export const ContactsPage = () => {

    return (
        <div className={style.container}>
            <Helmet title="Контакты" />
            <h1 className={style.heading}>Контакты</h1>

            <Tabs>
                <TabList className={style.tabList}>
                    <Tab className={style.tab}>{contactsProfiles.methodist.meta.title}</Tab>
                    <Tab className={style.tab}>{contactsProfiles.deanery.meta.title}</Tab>
                    <Tab className={style.tab}>{contactsProfiles.tech.meta.title}</Tab>
                    <Tab className={style.tab}>{contactsProfiles.admins.meta.title}</Tab>
                </TabList>

                <div className={style.content}>
                    <TabPanel>
                        <h2>{contactsProfiles.methodist.meta.title}</h2>
                        
                        <div className={style.date}>
                            <div className={style.icon}>
                                <IconUI typeIcon='clock' />
                            </div>

                            <div className={style.caption}>
                                Время работы: {contactsProfiles.methodist.meta.time}
                            </div>
                        </div>

                        <div className={style.panelContent}>
                            <ContactMethodist items={contactsProfiles.methodist.items} />
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <h2>{contactsProfiles.deanery.meta.title}</h2>
                        <div className={style.date}>
                            <div className={style.icon}>
                                <IconUI typeIcon='clock' />
                            </div>

                            <div className={style.caption}>
                                Время работы: {contactsProfiles.deanery.meta.time}
                            </div>
                        </div>

                        <div className={style.panelContent}>
                            <ContactDeanery items={contactsProfiles.deanery.items} />
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <h2>{contactsProfiles.tech.meta.title}</h2>
                        <div className={style.date}>
                            <div className={style.icon}>
                                <IconUI typeIcon='clock' />
                            </div>

                            <div className={style.caption}>
                                Время работы: {contactsProfiles.tech.meta.time}
                            </div>
                        </div>

                        <div className={style.panelContent}>
                            <ContactTech items={contactsProfiles.tech.items} />
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <h2>{contactsProfiles.admins.meta.title}</h2>
                        <div className={style.date}>
                            <div className={style.icon}>
                                <IconUI typeIcon='clock' />
                            </div>

                            <div className={style.caption}>
                                Время работы: {contactsProfiles.admins.meta.time}
                            </div>
                        </div>

                        <div className={style.panelContent}>
                            <ContactAccount items={contactsProfiles.admins.items} />
                        </div>
                    </TabPanel>
                </div>
            </Tabs>

        </div>
    );
};
