import { FC, MouseEventHandler } from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import IconUI from '@components/core/IconUI';
import { SSOLink } from '@components/Linkify/SSOLink';
import { extractDomain, isSSOLink } from '@helpers/url';

import style from './link_ui.module.scss';

interface IProps {
    to: string
    className?: string;
    typeIcon?: 'arrowLink' | 'left-arrow';
    typeLink?: 'router' | 'default';
    download?: boolean;
    target?: '_self' | '_blank',
    children?: React.ReactNode;
    disabled?: boolean;
    state?: any;
    onClick?: MouseEventHandler<HTMLAnchorElement>,
    needIcon?: boolean
}

const LinkUI: FC<IProps> = (
    {
        className,
        children,
        to,
        typeIcon,
        typeLink,
        download,
        target = '_blank',
        disabled,
        needIcon = true,
        ...props
    }) => {

    

    const renderLinkRouter = () => {
        return (
            <Link
                to={to} className={cx(style.root, disabled && style['root--disabled'], className)} {...props}>
                {typeIcon && <IconUI typeIcon={typeIcon} />}
                {children}
            </Link>
        );
    };

    const renderLink = () => {

        // Если вдруг, ссылка от сервиса то генерим отдельный линкус        
        if (isSSOLink(to)) {
            
            return <SSOLink 
                domain={extractDomain(to)} 
                href={to} 
                value={children}
                className={cx(style.root, className, download && style['root--download'], disabled && style['root--disabled'])}>
                
                {download && needIcon ?
                    <IconUI typeIcon="clip" />
                    : (
                        typeIcon && <IconUI typeIcon={typeIcon} />
                    )}
                {typeIcon && <IconUI typeIcon={typeIcon} />}
                <span>
                    {children}
                </span>

            </SSOLink>;
        }


        return (
            <a
                href={to}
                className={cx(style.root, className, download && style['root--download'], disabled && style['root--disabled'])}
                download={download}
                target={target}
                {...props}
            >
                {download && needIcon ?
                    <IconUI typeIcon="clip" />
                    : (
                        typeIcon && <IconUI typeIcon={typeIcon} />
                    )}
                {typeIcon && <IconUI typeIcon={typeIcon} />}
                <span>
                    {children}
                </span>
            </a>
        );
    };
    return (
        <>
            {typeLink === 'default' ? renderLink() : renderLinkRouter()}
        </>

    );
};

export { LinkUI };
