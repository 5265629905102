import React from 'react';

import { Outlet } from 'react-router-dom';

import { useCategory } from '@views/methodist/Materials/MaterialsPage/materialsPage';
import MaterialsSidebar from '@views/methodist/Materials/MaterialsPage/MaterialsSidebar';

import style from './materials_list_layout.module.scss';

const MaterialsListLayout = () => {
    const categoryContext = useCategory();
    return (
        <div className={style.wrapper}>
            <MaterialsSidebar />
            <Outlet context={categoryContext} />
        </div>
    );
};

export default MaterialsListLayout;
