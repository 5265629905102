import { useEffect } from 'react';

import '@styles/main.scss';

import Toast from '@components/core/Toast';
import { initLS } from '@store/features/settingsLSSlice';
import { useAppDispatch } from '@store/hooks';

import Routers from './router';

function App() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(initLS());
    }, []);

    return (
        <>
            <Routers />
            <Toast />
        </>
    );
}

export default App;
