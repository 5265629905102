import React, { FC } from 'react';

import Checkbox from '@components/core/inputs/Checkbox';
import { ICheckboxProps } from '@components/core/inputs/Checkbox/Checkbox';

import style from './DataListSelect.module.scss';

export const DataListSelect: FC<ICheckboxProps> = ({ ...props }) => {

    return <> 
        <div className={style.serviceSelect}>
            <Checkbox {...props} />
        </div>
    </>;
};