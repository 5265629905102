import React from 'react';

import { Role } from '@store/Roles';

import { checkRole } from './helpers';

interface RoleCheckProps {
    children: React.ReactNode;
    roles: Role | Role[]
}

export const RoleCheck: React.FC<RoleCheckProps> = ({ children, roles }) => {
    if (checkRole(roles)) {
        return (
            <>
                {children}
            </>
        );
    }
    return null;
};
