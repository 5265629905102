import { configureStore } from '@reduxjs/toolkit';

import { emptyBaseApi } from './api/emptyBaseApi';
import { chatBaseApi } from './chatApi/chatBaseApi';
import authenticationSlice from './features/authenticationSlice';
import authSlice from './features/authSlice';
import chatSlice from './features/chatSlice';
import deadlinesSlice from './features/deadlinesSlice';
import infiniteSelectScrollSlice from './features/infiniteSelectScrollSlice';
import massEditSlice from './features/massEditSlice';
import notifySlice from './features/notifySlice';
import passTestSlice from './features/passTestSlice';
import settingsLSSlice from './features/settingsLSSlice';
import subscribeSlice from './features/subscribeSlice';
import termsSlice from './features/termsSlice';
import userSlice from './features/userSlice';

export const store = configureStore({
    reducer: {
        userSlice: userSlice,
        authSlice: authSlice,
        massEditSlice: massEditSlice,
        subscribeSlice: subscribeSlice,
        deadlinesSlice: deadlinesSlice,
        termsSlice,
        passTestSlice,
        notifySlice,
        authenticationSlice,
        chatSlice,
        settingsLSSlice,
        infiniteSelectScrollSlice,
        [emptyBaseApi.reducerPath]: emptyBaseApi.reducer,
        [chatBaseApi.reducerPath]: chatBaseApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            emptyBaseApi.middleware,
            chatBaseApi.middleware
        ),

    devTools: process.env.NODE_ENV !== 'production'
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
