import Footer from '@components/Footer';
import ContactsBlock from '@components/Footer/ContactsBlock';
import FooterMenu from '@components/Footer/FooterMenu/FooterMenu';
import SocialNetwork from '@components/Footer/SocialNetwork';
import style from '@layouts/StudentLayout/StudentLayout.module.scss';

const MainFooter = () => {
    return (
        <Footer className={style.footer}>
            <div className={style.block_wrapper}>
                <div className={style.label}>© {new Date().getFullYear()} АНО ВО «ВЕИП»</div>
                <div className={style.contacts_wrapper}>
                    <FooterMenu />
                    <ContactsBlock />
                </div>
            </div>
            <div className={style['social-wrapper']}>
                <SocialNetwork />
                <div className={style['label-right']}>© {new Date().getFullYear()} АНО ВО «ВЕИП»</div>
            </div>

        </Footer>
    );
};

export default MainFooter;
