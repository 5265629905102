import React, { ChangeEventHandler, CSSProperties, FocusEventHandler, forwardRef, InputHTMLAttributes, MouseEventHandler, useEffect, useState } from 'react';

import cx from 'classnames';
import { UseFormReturn } from 'react-hook-form';

import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg';
import { ReactComponent as DateIcon } from '@assets/icons/date.svg';
import { ReactComponent as HidePasswordIcon } from '@assets/icons/hide-password-icon.svg';
import { ReactComponent as SearchIcon } from '@assets/icons/search-icon.svg';
import { ReactComponent as ShowPasswordIcon } from '@assets/icons/show-password-icon.svg';
import IconUI from '@components/core/IconUI';
import IconButton from '@components/core/styledWrappers/IconButton';
import { useIdHook } from '@hooks/useId.hook';

import style from './input_base.module.scss';

export interface InputCancelProps extends BaseInputProps {
    variant: 'cancel';
    setValue: UseFormReturn['setValue'];
}

export type BaseInputProps = {
    name?: string;
    className?: string;
    classNameWrapper?: string;
    type?: string;
    value?: string;
    onClick?: MouseEventHandler<HTMLInputElement | HTMLButtonElement>;
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    autoFocus?: boolean;
    variant?: 'default' | 'cancel' | 'search' | 'date' | 'errorWarning';
    inputSize?: 'default' | 'small';
    label?: string;
    helperText?: string;
    endAdornment?: React.ReactElement;
    error?: boolean;
    disabled?: boolean;
    setValue?: UseFormReturn['setValue'];
    styled?: CSSProperties;
    onErrorFire?: (errorText: string | null | undefined, element: React.ReactElement) => void;
    textarea?: boolean;
    passwordStayHide?: boolean;
    clearInput?: () => void;
} & InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;

export type IProps = BaseInputProps | InputCancelProps;

const InputBase = forwardRef<HTMLInputElement, BaseInputProps>(
    (
        {
            name,
            type,
            value = '',
            onChange,
            onBlur,
            setValue,
            className,
            variant,
            label,
            disabled,
            helperText,
            error,
            endAdornment,
            inputSize = '',
            styled,
            classNameWrapper,
            textarea = false,
            passwordStayHide = false,
            clearInput,
            ...props
        },
        ref
    ) => {
        const id = useIdHook(`input-${type}-`);
        const positionAdornment = endAdornment && endAdornment.props.position;
        const [passwordShown, setPasswordShown] = useState(false);
        const [isFocus, setFocus] = useState<boolean>();

        const onFocus = () => {
            setFocus(true);
        };

        const onBlurInput = () => {
            setFocus(false);
        };

        const onClickDeleteValue = () => {
            (setValue && name) && setValue(name, '');
            clearInput?.();
        };

        const togglePasswordVisibility = () => {
            setPasswordShown(!passwordShown);
        };

        const renderErrorElement = () => {
            return (
                <span className={
                    cx(error && style['helper_text--error'])
                }>
                    {helperText}
                </span>);
        };

        useEffect(() => {
            if (!props?.onErrorFire) return;

            props.onErrorFire(helperText, renderErrorElement());
        }, [error]);


        return (
            <div
                className={cx(style.wrapper, classNameWrapper)}
                onBlur={onBlurInput}
                onFocus={onFocus}
                style={styled}
            >
                {label && <label htmlFor={id}>{label}</label>}

                {
                    textarea
                        ?
                        <div
                            className={cx(
                                style.root_wrapper,
                                error && style['root_wrapper--error'],
                                disabled && style['root_wrapper--disabled'],
                                isFocus && style['root_wrapper--focus']
                            )}
                        >
                            <textarea
                                className={cx(style.root, className)}
                                id={id}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                disabled={disabled}
                                autoComplete="false"

                                {...props}
                            />
                        </div>
                        :
                        <div
                            className={cx(
                                style.root_wrapper,
                                error && style['root_wrapper--error'],
                                disabled && style['root_wrapper--disabled'],
                                isFocus && style['root_wrapper--focus']
                            )}
                        >
                            {positionAdornment === 'start' && endAdornment}

                            {variant === 'search' && (
                                <div className={style.search_icon}>
                                    <SearchIcon />
                                </div>
                            )}

                            <input
                                className={cx(
                                    style.root,
                                    inputSize === 'small' && style['root--small'],
                                    type === 'number' && style['root--input_number'],
                                    className
                                )}
                                id={id}
                                autoComplete="false"
                                value={value}
                                type={
                                    type === 'password'
                                        ? passwordShown
                                            ? 'text'
                                            : 'password'
                                        : type
                                }
                                onChange={onChange}
                                onBlur={onBlur}
                                ref={ref}
                                disabled={disabled}
                                {...props}
                            />

                            {type === 'password' && !passwordStayHide && (
                                <IconButton
                                    className={style.show_password_button}
                                    onClick={togglePasswordVisibility}
                                >
                                    {passwordShown ? (
                                        <HidePasswordIcon />
                                    ) : (
                                        <ShowPasswordIcon />
                                    )}
                                </IconButton>
                            )}

                            {variant === 'date' && (
                                <IconButton className={style.input_icon} onClick={props.onClick}>
                                    <DateIcon />
                                </IconButton>
                            )}

                            {(variant === 'cancel' || clearInput) && value !== '' && (
                                <IconButton
                                    className={style.cancel_button}
                                    onClick={onClickDeleteValue}>

                                    <CloseIcon />
                                </IconButton>
                            )}

                            {variant === 'errorWarning' && error && (
                                <IconButton
                                    className={style.input_icon}
                                    onClick={onClickDeleteValue}>

                                    <IconUI typeIcon="confirm-error" />
                                </IconButton>
                            )}

                            {/* TODO: Надо переделать. */}

                            {positionAdornment === 'end' && endAdornment}
                        </div>
                }

                {helperText && !props.onErrorFire && renderErrorElement()}
            </div>
        );
    }
);

InputBase.displayName = 'InputBase';

export default InputBase;
