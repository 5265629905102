import React from 'react';

import Button from '@components/core/inputs/Button';
import { useTreeSelect } from '@components/core/inputs/Select/NewTreeSelect/context';

import style from './ButtonsControl.module.scss';

export const ButtonsControl: React.FC = () => {
    const { onClose, onChange, isMulti, selectedMultiValue } = useTreeSelect();

    const onClear = () => {
        if (isMulti) {
            onChange([]);
        }
        onClose();
    };

    const onSubmit = () => {
        if (isMulti) {
            onChange(selectedMultiValue);
        }
        onClose();
    };

    return (
        <div className={style.dropdown_control}>
            {selectedMultiValue.length > 0 && (
                <Button
                    btnType="clear"
                    className={style.dropdown_control__clear}
                    size="small"
                    onClick={onClear}
                >
                    Очистить
                </Button>
            )}
            <Button
                size="small"
                onClick={onSubmit}
            >
                Выбрать
            </Button>
        </div>
    );
};
