import React, { FC } from 'react';

import clsx from 'classnames';

import usePagination, { UsePaginationItem } from '@mui/material/usePagination';
import { type ValueOf } from '@type/common';

import style from './Pagination.module.scss';
import PaginationControl from './PaginationControl';
import PaginationItem from './PaginationItem';
import PaginationRowsSwitcher from './PaginationRowsSwitcher';

export interface PaginationProps {
    total: number;
    currentPage: number;
    onChangePage: (page: number) => void;
    itemsPerPageOptions: number[];
    rowsPerPage?: number;
    changeRowsOptions?: (rowsCount: number) => void;
    noData?: boolean;
    className?: string;
    isAll?: boolean;
    minTotal?: number;
}

type ItemPaginationType = Pick<UsePaginationItem, 'type'>;
type PaginationRender = Record<ValueOf<ItemPaginationType>, (item: UsePaginationItem) => JSX.Element>;

export const Pagination:FC<PaginationProps> = ({
    total,
    currentPage,
    onChangePage,
    itemsPerPageOptions = [],
    rowsPerPage = 25,
    minTotal = 25,
    changeRowsOptions,
    noData,
    isAll,
    className
}) => {
    const pagesCount = Math.ceil(total / rowsPerPage) || 0;
    const isOnePage = pagesCount <= 1;

    const onChangePagination = (event: React.ChangeEvent<unknown>, page: number) => {
        onChangePage(page);
    };

    const { items } = usePagination({
        count: pagesCount,
        onChange: onChangePagination,
        page: currentPage
    });

    const renderChildren: PaginationRender = {
        // Buttons shmatons
        'start-ellipsis' : (item) => <PaginationItem ellipsis='...' {...item} onClick={() => null} disabled />,
        'end-ellipsis' : (item) => <PaginationItem ellipsis='...' {...item} onClick={() => null} disabled />,
        'page': (item) => <PaginationItem {...item} />,
        'next' : (item) => <PaginationControl {...item} />,
        // For next generation. If it needs ofcourse.
        'previous': (item) => <PaginationControl {...item} />,
        'first' : (item) => <></>,
        'last' : (item) => <></>
    };

    return (<div className={clsx(style.pagination, className, isOnePage && style['pagination--end'])}>
        {
            isOnePage ||
                <div className={style.list}>
                    <ul className={style.ul}>
                        {
                            items.map((item, index) => {
                                return <li key={index}>{ renderChildren[item.type](item) }</li>;
                            })
                        }
                    </ul>
                </div>
        }

        <div className={style.switcher}>
            <PaginationRowsSwitcher
                onChange={changeRowsOptions}
                isAll={isAll}
                rowsPerPage={rowsPerPage}
                currentRows={itemsPerPageOptions}
                total={total}
            />
        </div>
    </div>
    );
};
