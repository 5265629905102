import React, { FC, useEffect, useMemo, useState } from 'react';

import { addMonths, setHours, setMinutes } from 'date-fns';
import { Day, useLilius } from 'use-lilius';

import Button from '@components/core/inputs/Button';
import Paper from '@components/core/styledWrappers/Paper';

import style from './DatePicker.module.scss';
import { DPCalendar } from './DPCalendar';
import { IDPCalendarProps } from './DPCalendar/DPCalendar';
import { DPTime } from './DPTime';
import { TStartOrEnd } from './type';

export interface IDatePickerProps {
    mode?: 'single' | 'range';
    onSelectDate: (date: Date) => void;
    onSubmit?: (date: Date) => void;
    onSubmitRange?: () => void;
    onClearDates?: () => void;

    startDate?: Date;
    endDate?: Date;

    setStart: React.Dispatch<React.SetStateAction<Date | undefined>>;
    setEnd?: React.Dispatch<React.SetStateAction<Date | undefined>>;

    startTime?: string;
    onTimeChange?: (value: string) => void;
}

export interface RangeMonthSelected {
    start: Date;
    end: Date;
}

export const DatePicker: FC<IDatePickerProps> = ({
    mode,
    onSelectDate,
    onSubmit,
    onSubmitRange,
    startDate,
    endDate,
    setStart,
    setEnd,
    onClearDates,

    startTime,
    onTimeChange
}) => {

    const { selectRange, setSelected, inRange, viewing } = useLilius({
        weekStartsOn: Day.MONDAY
    });

    const [disabled, setDisable] = useState(false);

    const [rangeMonthSelected, setRangeMonthSelected] = useState<RangeMonthSelected>({
        start: (new Date()),
        end: addMonths(new Date, 1)
    });

    const isRange = mode === 'range';

    const onSelect = (date: Date, rangeMode: boolean) => {

        if (rangeMode) {
            if (!!startDate && !!endDate) {
                setStart(date);

                if (!setEnd) return;
                setEnd(undefined);
            } else {
                if (!startDate) {
                    setStart(date !== startDate ? date : undefined);
                } else {
                    if (!setEnd) return;

                    if (startDate.getTime() > date.getTime()) {
                        setEnd(startDate);
                        setStart(date);
                    } else {
                        setEnd(date !== endDate ? date : undefined);
                    }
                }
            }
        } else {
            setStart(date);

            if (!onSelectDate) return;
            onSelectDate(date);
        }
    };

    const rightCalendarViewing = useMemo(() => addMonths(viewing, 1), []);

    const inGlobalRange = (date: Date) => {
        return startDate && endDate ? inRange(date, startDate, endDate) : false;
    };

    const isStartOrEnd = (date: Date) => {
        const dateMills = date.getTime();
        const startDateMills = startDate?.getTime();
        const endDateMills = endDate?.getTime();

        return {
            isStartOrEnd: dateMills === startDateMills || date === endDate,
            startDate: dateMills === startDateMills,
            endDate: dateMills === endDateMills
        } as TStartOrEnd;
    };

    const onSubmitWrapper = () => {

        if (!startDate || !onSubmit) return;
        onSubmit(startDate);
    };

    const onTimeAccepted = (value: string) => {
        if (!onTimeChange) return;

        if (!value) {
            setDisable(true);
            onTimeChange('');
        } else {
            setDisable(false);
            onTimeChange(value);
        }
    };

    const onClear = () => {
        if (onClearDates) onClearDates();
        setSelected([]);
    };

    useEffect(() => {
        if (!!startDate && !!endDate) {
            selectRange(startDate, endDate);
            onSelectDate(startDate);
            setSelected([startDate, endDate]);
        } else {
            setSelected([]);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (!startTime) return;

        if (startDate) {
            const timeArr = startTime.split(':');
            const tt = setHours(startDate as Date, +timeArr[0]);
            const dateWithTime = setMinutes(tt, +timeArr[1]);

            setStart(dateWithTime);
            onSelectDate(dateWithTime);
        }
    }, [startTime]);

    const DPProps: IDPCalendarProps = {
        onSelect,
        inGlobalRange,
        defaultViewing: viewing,
        isStartOrEnd,
        startDate,
        endDate,
        range: isRange,
        rangeMonthSelected,
        changeRangeMonthSelected: setRangeMonthSelected
    };

    return (<div className={style.container}>
        <Paper className={style.paper}>
            <div className={style.calendar}>
                <DPCalendar {...DPProps} />
                {
                    isRange && (<DPCalendar {...DPProps} isLastCalendar={true} defaultViewing={rightCalendarViewing} />)
                }
            </div>

            {
                !isRange && <div className={style.timePicker}>
                    <DPTime time={startTime} onTimeAccepted={onTimeAccepted} />
                </div>
            }

            <div className={style.setupBtn}>
                {
                    isRange ?
                        <>
                            <Button
                                onClick={onClear}
                                btnType="clear">
                                Очистить
                            </Button>
                            <Button
                                onClick={onSubmitRange}
                                btnType="success"
                                size="small"
                                disabled={disabled}>
                                Применить
                            </Button>
                        </>
                        :
                        <Button
                            onClick={onSubmitWrapper}
                            btnType="success"
                            size="small"
                            disabled={disabled}
                            style={{ width: '100%' }}>
                            Установить
                        </Button>
                }
            </div>
        </Paper>
    </div>);
};
