import { FC, useMemo, useState } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ReactComponent as CheckIcon } from '@assets/icons/check-white.svg';
import { ReactComponent as TriangleWarningIcon } from '@assets/icons/warning-triangle.svg';
import Button from '@components/core/inputs/Button';
import { FileUploadButtonControl } from '@components/core/inputs/FileUpload/FileUploadButtonControl';
import NewTreeSelectControl, { transformDataToOptions } from '@components/core/inputs/NewTreeSelectControl';
import Modal from '@components/Modal';
import { getBase64 } from '@helpers/base64';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useImportUsersMutation } from '@store/api/usersApi';

import style from './import_users_modal.module.scss';


const schema = yup.object({
    fileUpload: yup.mixed().required('Добавьте файл!').nullable(),
    blockedGroups: yup.string().required('Выберите группу')
});

type IModalImportForm = yup.InferType<typeof schema>;

interface IImportUsersModal {
    isOpen: boolean,
    onClose: () => void
}

const ImportUsersModal: FC<IImportUsersModal> = ({ isOpen, onClose }) => {

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [importCount, setImportCount] = useState(16);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [returnedFile, setReturnedFile] = useState('');

    const { groupsTree } = useGetGroupsTreeQuery('', {
        selectFromResult: ({ data: groupData }) => ({
            groupsTree: groupData
        })
    });

    const groupsOptions = useMemo(() => groupsTree
        ? transformDataToOptions(groupsTree.childs, {
            value: 'UUID',
            label: 'name',
            children: 'childs'
        })
        : [],
    [groupsTree]);

    const [importUsers] = useImportUsersMutation();

    const methods = useForm<IModalImportForm>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            fileUpload: null,
            blockedGroups: ''
        }
    });

    const { handleSubmit, reset, control } = methods;

    const onSubmit = async (formData: IModalImportForm) => {
        const b64 = await getBase64(formData.fileUpload);

        const submitData = {
            file: b64 as string,
            group: formData.blockedGroups
        };

        const importedUsers = await importUsers(submitData).unwrap();

        setImportCount(importedUsers.count);
        setReturnedFile(importedUsers.file);
        onClose();

        if (importedUsers.status) {
            setShowSuccessModal(true);
        } else {
            setShowErrorModal(true);
        }

        reset();
    };

    return (
        <>
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                title="Импорт пользователей"
            >
                <form onSubmit={(handleSubmit(onSubmit))}>
                    <FormProvider {...methods}>
                        <div className={style.steps}>
                            <div className={style.step_container}>
                                <div className={style.step_number}>1</div>
                                <div className={style.step_content}>
                                    <p>
                                        <a href="/files/user_import_sample.xlsx" download>Скачайте шаблон импорта</a>, чтобы добавить новые вопросы
                                    </p>
                                </div>
                            </div>

                            <div className={style.step_container}>
                                <div className={style.step_number}>2</div>
                                <div className={style.step_content}>
                                    <p>
                                        Выберите файл с заполненным шаблоном
                                    </p>
                                    <div className={style.input_wrapper}>
                                        <FileUploadButtonControl
                                            name="fileUpload"
                                            control={control}
                                            accessTypes={[
                                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                                'application/vnd.ms-excel'
                                            ]}
                                            className={style.file_wrapper}
                                        >
                                            {' '}
                                        </FileUploadButtonControl>
                                    </div>
                                </div>
                            </div>

                            <div className={style.step_container}>
                                <div className={style.step_number}>3</div>
                                <div className={style.step_content}>
                                    <p>
                                        Группа для импорта
                                    </p>
                                    <NewTreeSelectControl
                                        className={style.select}
                                        name="blockedGroups"
                                        options={groupsOptions}
                                        placeholder="Выберите группу"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={style.button_wrapper}>
                            <Button
                                onClick={onClose}
                                btnType="secondary"
                            >
                                Отмена
                            </Button>
                            <Button type="submit">
                                Импортировать
                            </Button>
                        </div>
                    </FormProvider>
                </form>
            </Modal>

            <Modal
                onClose={() => setShowSuccessModal(false)}
                isOpen={showSuccessModal}
                title="Импорт завершен успешно!"
            >
                <div className={cx(style.modal__message, style.modal__message_success)}>
                    <div className={style.message__icon}>
                        <CheckIcon stroke="black" />
                    </div>
                    <p>
                        Импортировано: {importCount}
                    </p>
                </div>
            </Modal>

            <Modal
                onClose={() => setShowErrorModal(false)}
                isOpen={showErrorModal}
                title="Импорт не завершен!"
            >
                <div className={cx(style.modal__message, style.modal__message_error)}>
                    <div className={style.message__icon}>
                        <TriangleWarningIcon />
                    </div>
                    <p>
                        Ошибок в файле импорта: {importCount}
                    </p>
                </div>
                <Button
                    href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${returnedFile}`}
                    download
                    className="root--outline"
                    typeIcon="download">
                    Скачать отчет
                </Button>
            </Modal>
        </>

    );
};

export default ImportUsersModal;
