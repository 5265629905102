import { useEffect, useState } from 'react';

import { NavLink, Outlet,useParams } from 'react-router-dom';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { getPaths } from '@helpers/findNestedPath';
import { GroupTree } from '@store/api/apiTypes';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';

import style from './edit_group_layout.module.scss';

const EditGroupLayout = () => {
    const { 
        uuidGroup,
        activeGroup
    } = useParams();

    const [pathTo, setPathTo] = useState<{uuid: string, name: string}[]>([]);

    const groupsData = useGetGroupsTreeQuery('');

    useEffect(() => {
        if (groupsData.isSuccess) {
            const pathToGroup = getPaths<GroupTree, 'childs', 'UUID'>(
                groupsData.data.childs, 
                'childs', 
                'UUID', 
                (group) => group.UUID === (activeGroup ?? uuidGroup)
            );

            setPathTo(pathToGroup ?? []);
        }
    }, [groupsData.isLoading, groupsData.isSuccess, activeGroup, uuidGroup]);
    

    return (
        <div className={style.root}>
            {
                pathTo.length ? 
                    <div className={style.breadcrumps}>
                        <Breadcrumbs>
                            {
                                pathTo.map((group, index) => <NavLink key={group.uuid} to={`/users/groups/${group.uuid}${index > 0 ? '/editGroup' : ''}`}>
                                    {group.name}
                                </NavLink>)
                            }
                        </Breadcrumbs>
                    </div> 
                    : null
            }
            
            
            <Outlet />
        </div>
    );
};

export default EditGroupLayout;
