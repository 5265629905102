import React, { FC } from 'react';

import classNames from 'classnames/bind';

import { ReactComponent as CheckSVG } from '@assets/icons/check.svg';
import { ReactComponent as CrossSVG } from '@assets/icons/cross.svg';
import { ReactComponent as DocUploadSVG } from '@assets/icons/upload-doc.svg';
import { ReactComponent as UploadSVG } from '@assets/icons/upload.svg';
import { renderFileSize } from '@components/core/inputs/FileUpload/methods';
import { IPlaceholderBackendData, IRenderUpload } from '@components/core/inputs/FileUpload/type';
import { isStorageFile } from '@helpers/typeCheck';

import style from './fileareastage.module.scss';

const cx = classNames.bind(style);

interface IFileAreaStage {
    api: IPlaceholderBackendData | null;
    deleteFile: () => void;
    withoutCheckControl?: boolean;
    disabled?: boolean;
}

export const FileAreaStage: FC<IFileAreaStage> = ({
    api,
    withoutCheckControl = false,
    deleteFile,
    disabled
}) => {

    const fileRenderStage: IRenderUpload = {
        default: (
            <div className={cx('root__default')}>
                <span className={cx('root__default-icon')}>
                    <UploadSVG />
                </span>

                <span className={cx('root__default-label')}>
                    Выберите файл или перетащите сюда
                </span>
            </div>
        ),
        uploading: (
            <React.Fragment>
                <div className={cx('root__uploading-icon')}>
                    <DocUploadSVG />
                </div>

                <div className={cx('root__uploading-content')}>
                    <div className={cx('root__uploading-caption')}>
                        {
                            isStorageFile(api?.file) ? api?.file.title :  api?.file?.name
                        }
                    </div>
                    {
                        !isStorageFile(api?.file) && renderFileSize(api?.file, cx('root__uploading-size'))
                    }
                </div>

                <div className={cx('root__uploading-actions')}>
                    { !withoutCheckControl && <div className={cx('root__uploading-accept')}>
                        <CheckSVG />
                    </div> }

                    {!disabled && (
                        <div
                            className={cx('root__uploading-delete')}
                            role={'presentation'}
                            onClick={deleteFile}
                        >
                            <CrossSVG />
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    };

    const renderStage = () => (api?.stage ? fileRenderStage[api.stage] : null);

    return <div className={cx('root')}> {renderStage()} </div>;
};
