import React, {
    useState,
    ChangeEvent,
    forwardRef,
    ForwardRefRenderFunction,
    useEffect,
    MouseEvent,
    useRef,
    MutableRefObject
} from 'react';

import clsx from 'classnames';
import TextareaMarkdown, { TextareaMarkdownRef, TextareaMarkdownProps } from 'textarea-markdown-editor';
import { useOnClickOutside } from 'usehooks-ts';

import style from './MarkdownTextArea.module.scss';

const INPUT_ROW_HEIGHT = 20;
const MAX_ROWS = 11;

interface MarkdownTextAreaProps {
    className?: string;
    value: string;
    onChange: (value: string) => void;
    rowsLength?: number
}

interface ContextMenuPosition {
    vertical: number;
    horizontal: number;
}

const MarkdownTextArea: ForwardRefRenderFunction<TextareaMarkdownRef, MarkdownTextAreaProps & Omit<TextareaMarkdownProps, 'onChange' | 'value'>>  = ({
    className,
    value,
    onChange,
    rowsLength,
    ...props
}, ref) => {
    const [contextMenuPosition, setContextMenuPosition] = useState<ContextMenuPosition | null>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    const inputOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.target.value);
    };

    useEffect(() => {
        const inputElement: TextareaMarkdownRef | null = (ref as MutableRefObject<TextareaMarkdownRef>).current;
        if (inputElement) {
            if (inputElement.scrollHeight > INPUT_ROW_HEIGHT) {
                if ((inputElement.scrollHeight / INPUT_ROW_HEIGHT) <= (rowsLength || MAX_ROWS)) {
                    inputElement.style.removeProperty('height');
                    inputElement.style.height = `${inputElement.scrollHeight}px`;
                } else {
                    inputElement.style.removeProperty('height');
                    inputElement.style.height = `${(rowsLength || MAX_ROWS) * INPUT_ROW_HEIGHT}px`;
                }
            }
        }
    }, [value, (ref as MutableRefObject<TextareaMarkdownRef>).current]);

    const handleContextMenu = (event: MouseEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setContextMenuPosition({
            vertical: event.clientY,
            horizontal: event.clientX
        });
    };

    const closeContextMenu = () => {
        setContextMenuPosition(null);
    };

    useOnClickOutside(menuRef, closeContextMenu);

    const onFormat = (type: 'bold' | 'italic' | 'link') => {
        (ref as MutableRefObject<TextareaMarkdownRef>).current.trigger(type);
        closeContextMenu();
    };

    return (
        <>
            <TextareaMarkdown
                ref={ref}
                className={clsx(style.textArea, className)}
                value={value}
                onChange={inputOnChange}
                onContextMenu={handleContextMenu}
                {...props}
            />
            {contextMenuPosition && (
                <div ref={menuRef} style={{ top: contextMenuPosition.vertical, left: contextMenuPosition.horizontal }} className={style.menu}>
                    <ul>
                        <li onClick={() => onFormat('bold')}>Жирный</li>
                        <li onClick={() => onFormat('italic')}>Курсив</li>
                        <li onClick={() => onFormat('link')}>Добавить ссылку</li>
                    </ul>
                </div>
            )}
        </>
    );
};

export default forwardRef(MarkdownTextArea);
