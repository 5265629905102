import React, { useEffect, useMemo, useState } from 'react';

import { useParams, useLocation } from 'react-router-dom';

import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import GoBackLink from '@components/student/GoBackLink';
import { sortByTime } from '@helpers/date';
import { useGetGradesTeacherQuery, useRequestRetakeQuery, useStartTaskWorkMutation } from '@store/api/gradesApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import style from '@views/student/StudentTask/StudentTask.module.scss';
import CompletedTask from '@views/teacher/GradesTask/CompletedTask';
import TaskInfo from '@views/teacher/GradesTask/TaskInfo';

interface LocationState {
    isFinished?: boolean
}

const GradesTask = () => {
    const { uuidUser, uuidTask } = useParams();
    const { state } = useLocation();
    const me = useAppSelector(selectApiUser);
    const [gradeUuid, setGrade] = useState('');

    const grades = useGetGradesTeacherQuery({
        uuidsUser: [uuidUser as string],
        uuidsMaterial: [uuidTask as string]
    });

    const retakeData = useRequestRetakeQuery({ uuidMaterial: uuidTask as string, uuidUser: uuidUser });
    const [startWorkTimer] = useStartTaskWorkMutation();


    const dataWithRetakeMemo = useMemo(() => {
        if (!grades.data || !retakeData.data) return [];
        const sortedRetakeData = sortByTime(grades.data,  'createdAt', 'ask');
        return sortedRetakeData.map((attempt, index) => {
            if ((index == sortedRetakeData.length - 1) && !attempt.checked) {
                setGrade(attempt.uuid);
            }
            return ({
                ...attempt,
                retakeAvailable: Boolean(retakeData.data!.find(retake => retake.uuidAttempt === attempt.uuidAttempt)?.retakeAvailable)
            });
        }
        );
    }, [grades.data, retakeData.data]);


    useEffect(() => {
        if (me && gradeUuid) {
            startWorkTimer({ uuidTeacher: me.uuid, uuidGrade: gradeUuid });
        }
    }, [gradeUuid]);


    if (grades.isLoading || retakeData.isLoading) return <NewLoader />;

    const label = `Преподавание – ${(state as LocationState | undefined)?.isFinished ? 'Проверенные работы' : 'Работы на проверку'}: Оценка работы`;

    const backHref = (state as LocationState | undefined)?.isFinished ? '/teaching/tasksVerified' : '/teaching';


    return (
        <Main className={style.main} title={label}>
            <GoBackLink to={backHref}>
                Назад
            </GoBackLink>

            <h1 className={style.main_title}>Оценка работы</h1>
            {
                dataWithRetakeMemo &&
                <div className={style.content_wrapper}>
                    <TaskInfo
                        taskName={dataWithRetakeMemo[0]?.task.name}
                        disciplineName={dataWithRetakeMemo[0]?.discipline.name}
                        user={dataWithRetakeMemo[0].user}
                    />
                    {dataWithRetakeMemo.map((attempt, index) => {
                        const maybeEdit = index === dataWithRetakeMemo.length - 1 && attempt.checked;

                        return (
                            <CompletedTask
                                key={attempt.uuid}
                                attempt={attempt}
                                index={index}
                                maybeEdit={maybeEdit}
                            />
                        );
                    })}
                </div>
            }
        </Main>
    );
};

export default GradesTask;
