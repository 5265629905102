import React from 'react';

import { LibButton } from '@components/core/LibButton/LibButton';
import { Drawer } from '@components/Drawer';
import Header from '@components/Header';
import Logo from '@components/Logo';
import MethodistMenu from '@components/methodist/MethodistMenu';
import RouterTabs from '@components/RouterTabs';
import { useBreakpoint } from '@hooks/useBreakpoint';

import style from './TeacherHeader.module.scss';

const tabs = [
    {
        name: 'Преподавание',
        path: 'teaching'
    },
    {
        name: 'Мероприятия',
        path: 'events'
    },
    {
        name: 'Контакты',
        path: 'contacts'
    }
];

const Wrapper: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const lgUp = useBreakpoint('lg', 'up');
    return lgUp ? <React.Fragment>{children}</React.Fragment> : <div className={style.wrapper}>{children}</div>;
};

const TeacherHeader = () => {
    const smUp = useBreakpoint('sm', 'up');

    return (
        <Header className={style.header}>
            <Wrapper>
                {!smUp && <Drawer tabs={tabs} />}
                <Logo />
                {/* <LibButton /> */}
                {smUp && <RouterTabs tabs={tabs} className={style.tabs} end={false} />}
            </Wrapper>
            <MethodistMenu />
        </Header>
    );
};

export default TeacherHeader;
