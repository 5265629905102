import React, { FC } from 'react';

import { InlineControls } from '@components/core/InlineControls';
import { IControl } from '@components/core/InlineControls/type';
import { Label } from '@components/Label';
import { RoleCheck } from '@components/RoleCheck';
import { Role } from '@store/Roles';

import style from './cardheader.module.scss';

interface ICardHeaderProps {
    title?: string;
    labels?: { published: boolean, blockedGroups: string[] };
    controlsEvents?: IControl;
}

export const CardHeader: FC<ICardHeaderProps> = ({
    title,
    labels,
    controlsEvents
}) => {
    const renderLabels = () => {
        return (
            <>
                {!labels?.published ? <Label caption={'черновик'} /> : ''}
                {/* {labels.blockedGroups.length ? <Label caption={'ограничения доступа'} /> : ''} */}
            </>

        );
    };

    return <div className={style.wrapper}>
        <div className={style.container}>
            <div className={style.heading}>
                {title}
            </div>

            <div className={style.labels}>
                {renderLabels()}
            </div>
        </div>
        <RoleCheck roles={Role.Methodist}>
            <div className={style.controls}>
                <InlineControls {...controlsEvents} />
            </div>
        </RoleCheck>
    </div>;
};
