import React, { FC, ReactNode } from 'react';

import cx from 'classnames';

import { useDropMenuContext } from '@components/core/styledWrappers/DropMenu/dropMenuContext';

import style from './drop_menu.module.scss';

interface IProps {
    className?: string;
    children?: ReactNode;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const DropMenuItem: FC<IProps> = ({ className, children, onClick, ...props }) => {
    const { toggleMenu, setToggleMenu } = useDropMenuContext();

    const menuItemHandler = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setToggleMenu(!toggleMenu);
        onClick && onClick(e);
    };
    return (
        <li
            onClick={(e) => menuItemHandler(e)}
            className={cx(style.root__item, className)}
            role="menuitem"
            {...props}
        >
            {children}
        </li>
    );
};

export { DropMenuItem };
