import React, { FC } from 'react';

import classNames from 'classnames/bind';


import style from './paginationitem.module.scss';

const cx = classNames.bind(style);

interface IPaginationProps {
    page?: number | null;
    ellipsis?: string;
    onClick?: React.ReactEventHandler<Element>;
    selected?: boolean;
    disabled?: boolean;
}

export const PaginationItem:FC<IPaginationProps> = ({
    page,
    ellipsis,
    onClick,
    selected,
    disabled
}) => {
    return (
        <button
            onClick={onClick}
            className={cx(
                style.btn,
                {
                    [style.selected]: selected,
                    [style.disabled]: disabled
                })}
            disabled={disabled}
        >
            {page || ellipsis}
        </button>
    );
};
