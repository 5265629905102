import { Role } from '@store/Roles';
import { store } from '@store/store';

export const checkRole = (roles: Role | Role[]) => {
    const state = store.getState();
    const user = state.userSlice.apiUser;

    if (!user) return false;
    if (Array.isArray(roles)) {
        return roles.includes(user.role);
    } else {
        return user.role === roles;
    }
};
