import React from 'react';

import { Outlet, useParams } from 'react-router-dom';

import ProgressBar from '@components/core/ProgressBar';
import Main from '@components/Main';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import GoBackLink from '@components/student/GoBackLink';
import { calculateLectureBlockProgress } from '@helpers/calculateLectureBlockProgress';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { useGetChapterByIdQuery, useGetLectureBlockProgressQuery } from '@store/api/lecturesApi';
import { Role } from '@store/Roles';
import { useLectureBlock } from '@views/student/LecturesPreview/LecturesPreview';

import style from './ChapterLayout.module.scss';

const ChapterLayout = () => {
    const { uuidDiscipline, uuidLectureBlock, uuidChapter } = useParams();
    const lecturesBlockProgress = useGetLectureBlockProgressQuery(uuidLectureBlock as string);
    const { data: disciplineData } = useGetDisciplineByIdQuery(uuidDiscipline || skipToken);
    const chapterData = useGetChapterByIdQuery(uuidChapter || skipToken);
    const lecturesBlock = useLectureBlock();

    const lgUp = useBreakpoint('lg', 'up');

    const label = disciplineData && chapterData.data && `Обучение – ${disciplineData.name}: ${chapterData.data.name}`;

    const goBackHandle = () => {
        if (checkRole(Role.Student)) return './../../..';
        if (checkRole(Role.Methodist)) return './../..';
        return '';
    };

    return (
        <Main className={style.main} title={label}>
            <div className={style.progress_bar_container}>
                <GoBackLink to={goBackHandle()}>
                    Назад к курсу
                </GoBackLink>
                {lgUp && (
                    <RoleCheck roles={Role.Student}>
                        <ProgressBar completed={calculateLectureBlockProgress(lecturesBlockProgress.data)} />
                    </RoleCheck>
                )}
            </div>
            <Outlet context={lecturesBlock} />
        </Main>
    );
};

export default ChapterLayout;
