import { toast } from 'react-toastify';

import { logOut } from '@store/features/authSlice';
import { setApiUser } from '@store/features/userSlice';
import { Role } from '@store/Roles';
import { ChangeUserActiveStatusRequest, ChangeUserChatStatusRequest, User, UsersRequest, UsersResponse } from '@type/users';

import { UpdateMainData, UpdateUserSocialsRequest, UsersChangeGroupData, UsersImportData, UsersImportResponse } from './apiTypes';
import { emptyBaseApi } from './emptyBaseApi';

export const usersApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getAuthUser: build.query<User, string>({
            query(uuid: string) {
                return {
                    url: 'auth/users-by-uuids',
                    method: 'POST',
                    body: {
                        uuids: [
                            uuid
                        ]
                    }
                };
            },
            transformResponse: (result: { users: User[] }) => {
                return result.users[0];
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    await dispatch(setApiUser(data));
                } catch (error) {
                    console.log('🚀 ~ file: usersApi.ts:35 ~ onQueryStarted ~ logOut:');
                    await dispatch(logOut());
                }
            }
        }),

        getUsersAll: build.query<UsersResponse, UsersRequest>({
            query(filters) {
                return {
                    url: 'auth/users',
                    method: 'POST',
                    body: {
                        filters
                    }
                };
            },
            providesTags: ['Users_List']
        }),
        //ToDo: limit fix
        getUsersByRole: build.query<User[], string>({
            query(role: Role) {
                return {
                    url: 'auth/users',
                    method: 'POST',
                    body: {
                        filters: {
                            status: 'active',
                            role: role,
                            limit: 10000
                        }
                    }
                };
            },
            transformResponse: (result: { users: User[] }) => {
                return result.users;
            }
        }),

        getUserByUuid: build.query<User, string>({
            query(uuid: string) {
                return {
                    url: 'auth/users-by-uuids',
                    method: 'POST',
                    body: {
                        uuids: [
                            uuid
                        ]
                    }
                };
            },
            transformResponse: (result: { users: User[] }) => {
                return result.users[0];
            },
            providesTags: (result, error, arg) => [{ type: 'User', id: `User_${arg}` }]
        }),

        getUsersByUuids: build.query<User[], {uuids: string[], showDeleted?: boolean}>({
            query({ uuids, showDeleted = false }) {
                return {
                    url: 'auth/users-by-uuids',
                    method: 'POST',
                    body: {
                        uuids: uuids,
                        deleted: showDeleted
                    }
                };
            },
            transformResponse: (result: { users: User[] }) => {
                return result.users;
            },
            providesTags: (result, error, arg) => [{ type: 'User', id: `User_${arg}` }]
        }),

        getUsersByGroup: build.query<User[], string | string[]>({
            query(uuidGroup: string | string[]) {
                return {
                    url: 'auth/assignment/get',
                    method: 'POST',
                    body: {
                        uuids: [
                            uuidGroup
                        ].flat()
                    }
                };
            },
            transformResponse: (result: { users: User[] }) => {
                return result.users;
            },
            providesTags: (result, error, arg) => Array.isArray(arg) ? ['Users_List'] : [{ type: 'Users_List', id: arg }]
        }),

        getGroupsListByUserId: build.query<string[], string>({
            query(uuidUsr) {
                return {
                    url: 'auth/user/groups',
                    method: 'POST',
                    body: {
                        UUID: uuidUsr
                    }
                };
            },
            transformResponse: (result: { groupsUUID: string[] }) => {
                return result.groupsUUID;
            }
        }),

        deleteUser: build.mutation<void, string>({
            query(uuidUser) {
                return {
                    url: 'auth/delete',
                    method: 'POST',
                    body: { uuids: [uuidUser] }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Пользователь удален',
                    error: 'Ошибка удаления!'
                });
            },
            invalidatesTags: ['Users_List']
        }),

        importUsers: build.mutation<UsersImportResponse, UsersImportData>({
            query(data) {
                return {
                    url: 'auth/import',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    pending: 'Загружаем файл...',
                    success: 'Файл загружен',
                    error: 'Не удалось загрузить файл'
                });
            },
            invalidatesTags: ['Users_List']
        }),

        changeUsersGroup: build.mutation<void, UsersChangeGroupData>({
            query(data) {
                return {
                    url: 'auth/assignment/update',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    pending: 'Смена группы...',
                    success: 'Группа сменена',
                    error: 'Не удалось сменить группу'
                });
            },
            invalidatesTags: ['Users_List']
        }),

        resetUserPassword: build.mutation<void, string[]>({
            query(userUuids) {
                return {
                    url: 'auth/passwords/drop',
                    method: 'POST',
                    body: { UUIDs: userUuids }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Новый пароль отправлен',
                    error: 'Не удалось отправить пароль'
                });
            }
        }),

        updateUserSocials: build.mutation<User, UpdateUserSocialsRequest>({
            query(socials) {
                return {
                    url: 'auth/user/networks/edit',
                    method: 'POST',
                    body: { ...socials }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Социальные сети обновлены',
                    error: 'Не удалось обновить социальные сети'
                });
            },
            invalidatesTags: (result, error, arg) => result ? ['Users_List', { type: 'User', id: `User_${result.uuid}` }] : ['User', 'Users_List']
        }),

        updateUserData: build.mutation<User, UpdateMainData>({
            query(data) {
                return {
                    url: 'auth/user/update',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Данные обновлены',
                    error: 'Не удалось обновить данные'
                });
            },
            invalidatesTags: (result, error, arg) => result ? ['Users_List', { type: 'User', id: `User_${result.uuid}` }] : ['User', 'Users_List']
        }),

        softUsersDelete: build.mutation<void, string[]>({
            query(uuids) {
                return {
                    url: 'auth/softdelete',
                    method: 'POST',
                    body: {
                        uuids: uuids
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Пользователь удален',
                    error: 'Не удалось удалить пользователя'
                });
            },
            invalidatesTags: ['Users_List']
        }),

        changeUserStatusByUuid: build.mutation<void, ChangeUserActiveStatusRequest>({
            query({ uuids, status }) {
                return {
                    url: 'auth/change-status',
                    method: 'POST',
                    body: {
                        uuids: {
                            uuids: uuids
                        },
                        status: status
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Статус изменен',
                    error: 'Не удалось изменить статус'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuids.length === 1 ?
                ['Users_List', { type: 'User', id: `User_${arg.uuids[0]}` }] :
                ['Users_List']
        }),

        changeUserChatStatusByUuid: build.mutation<void, ChangeUserChatStatusRequest>({
            query({ uuids, status }) {
                return {
                    url: 'auth/change/chat-status',
                    method: 'POST',
                    body: {
                        uuids: {
                            uuids: uuids
                        },
                        status: status
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Настройки чата изменены',
                    error: 'Не удалось изменить настройки чата'
                });
            },
            invalidatesTags: (result, error, arg) => arg.uuids.length === 1 ?
                ['Users_List', { type: 'User', id: `User_${arg.uuids[0]}` }] :
                ['Users_List']
        }),
        getMethodists: build.query<UsersResponse, void>({
            query: () => 'auth/users/methodists',
            providesTags: ['Methodists']
        })

    })
});

export const {
    useGetUsersAllQuery,
    useLazyGetUsersAllQuery,
    useGetUsersByRoleQuery,
    useGetUserByUuidQuery,
    useLazyGetUsersByUuidsQuery,
    useDeleteUserMutation,
    useGetAuthUserQuery,
    useImportUsersMutation,
    useGetUsersByGroupQuery,
    useLazyGetUsersByGroupQuery,
    useChangeUsersGroupMutation,
    useResetUserPasswordMutation,
    useUpdateUserSocialsMutation,
    useUpdateUserDataMutation,
    useSoftUsersDeleteMutation,
    useChangeUserStatusByUuidMutation,
    useChangeUserChatStatusByUuidMutation,
    useGetMethodistsQuery,
    useGetGroupsListByUserIdQuery
} = usersApi;
