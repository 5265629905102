import React from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import MainFooter from '@components/core/MainFooter';
import { Notify } from '@components/Notify';
import StudentHeader from '@components/student/StudentHeader';
import { useBreakpoint } from '@hooks/useBreakpoint';

import style from './StudentLayout.module.scss';


// const jivoKey = process.env.REACT_APP_JIVO_KEY;

const isTestPassingPage = (path: string) => {
    return path.match(/education\/.*\/test\/.*\/start/)?.length;
};

const StudentLayout = () => {
    const { pathname } = useLocation();
    const smUp = useBreakpoint('md', 'up');

    const isHeaderFooterShow = !isTestPassingPage(pathname) || smUp;

    return (
        <div className={style.layout_container}>
            {/*<Helmet>*/}
            {/*    {*/}
            {/*        jivoKey ? <script src={`//code.jivo.ru/widget/${jivoKey}`} async></script> : null*/}
            {/*    }*/}
            {/*</Helmet>*/}
            <Notify />
            { isHeaderFooterShow && <StudentHeader /> }
            <div className={style.main_layout_wrapper}>
                <Outlet />
            </div>
            {isHeaderFooterShow && <MainFooter />}
        </div>
    );
};

export default StudentLayout;
