import { createContext, useContext } from 'react';

import { NewTreeSelectProps } from '../NewTreeSelect';
import { OptionsMapWithParent, OptionsMapWithEndChildren } from '../types';

interface TreeSelectContextBase {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    handleSelect: (value: string) => void;
    selectedMultiValue: string[];
    options: NewTreeSelectProps['options'];
    optionsMapWithParents: OptionsMapWithParent;
    optionsMapWithEndChildrens: OptionsMapWithEndChildren;
    isMulti?: boolean;
    selectableParent?: boolean;
}

interface SingleSelectContext extends TreeSelectContextBase {
    isMulti?: false;
    value: string;
    onChange: (value: string) => void;
    selectableParent?: boolean
}

interface MultiSelectContext extends TreeSelectContextBase {
    isMulti: true;
    value: string[];
    onChange: (value: string[]) => void;
    selectableParent?: false
}

export const treeSelectContext = createContext<SingleSelectContext | MultiSelectContext | null>(null);

export function useTreeSelect() {
    const ctx = useContext(treeSelectContext);
    if (ctx === null) throw new Error('treeSelectContext must be defined');
    return ctx;
}
