import { Dispatch, FC, SetStateAction } from 'react';

import Modal from '@components/Modal';
import { getBase64 } from '@helpers/base64';
import { isStorageFile } from '@helpers/typeCheck';
import { File, fileTypes, UpdateFileData } from '@store/api/apiTypes';
import { useUploadFileMutation } from '@store/api/storageApi';
import CreateFileForm from '@views/methodist/Materials/Course/CreateFileForm';
import { CreateFileFormData } from '@views/methodist/Materials/Course/CreateFileForm/type';

interface IEditFileModal {
    isOpen: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
    materialFile: File;
    getUpdateFileData?: (updateFileData: UpdateFileData) => void;
    updateFile: (formData: UpdateFileData) => void
}

const EditFileModal: FC<IEditFileModal> = ({ isOpen, onClose, materialFile, getUpdateFileData, updateFile }) => {
    const [uploadFile] = useUploadFileMutation();

    const createFileHandler = async (formData: CreateFileFormData) => {
        const transformedFormData: UpdateFileData = {
            uuid: materialFile.uuid,
            name: formData.name,
            uuidFile: '',
            uuidSection: materialFile.uuidSection,
            position: materialFile.position
        };

        if (isStorageFile(formData.file)) {
            transformedFormData.uuidFile = formData.file.uuid;
        } else {
            const b64 = await getBase64(formData.file);
            const uploadedFile = await uploadFile({
                file: b64 as string,
                meta: {
                    filename: formData.file.name,
                    type: fileTypes.Public
                }
            }).unwrap();
            transformedFormData.uuidFile = uploadedFile.uuid;
        }

        // Зачем оно? getUpdateFileData...
        if (getUpdateFileData) {
            getUpdateFileData(transformedFormData);
            onClose(false);
        } else {
            updateFile(transformedFormData);
            onClose(false);
        }
    };

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title='Редактировать файл'
        >
            <CreateFileForm
                defaultValues={{ name: materialFile.name, file: materialFile.file }}
                onSubmitFormHandler={createFileHandler}
                onCloseModal={onClose}
            />
        </Modal>

    );
};

export default EditFileModal;
