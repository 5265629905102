import { getDisciplinesMap } from '@helpers/disciplines';
import { getUsersMap } from '@helpers/user';
import { IDiscipline, SessionTypes } from '@store/api/apiTypes';
import { Deadline, DisciplineWithExtraSession, GetExtraSessionsByUsers } from '@type/extraSession';
import { ExtraSessionDeadline, User } from '@type/users';

export const getUsersIdsWithExtraSession = (deadlenes: ExtraSessionDeadline[]): string[] => {
    const usersIds: string[] = [];

    deadlenes.forEach((deadlene) => {
        if (usersIds.includes(deadlene.uuidUser)) return;
        usersIds.push(deadlene.uuidUser);
    });

    return usersIds;
};



export const getExtraSessionsByUsers = (deadlenes: ExtraSessionDeadline[], users: User[], disciplines: IDiscipline[]): GetExtraSessionsByUsers[] => {
    const usersIds = getUsersIdsWithExtraSession(deadlenes);
    const usersData = getUsersMap(users);
    const disciplinesData = getDisciplinesMap(disciplines);


    return usersIds.map(userId => {
        const userInfo = usersData[userId];

        const usersDeadlines = deadlenes.filter(deadline => deadline.uuidUser === userId);

        const disciplinesMap: Record<string, {uuid: string; extraSessions: string[]; deadline: Deadline; semester: SessionTypes}> = {};

        usersDeadlines.forEach(deadline => {
            if (!disciplinesMap[deadline.uuidDiscipline]) {
                disciplinesMap[deadline.uuidDiscipline] = {
                    uuid: deadline.uuidDiscipline,
                    extraSessions: [deadline.uuid],
                    deadline: {
                        startDate: deadline.startDate,
                        endDate: deadline.endDate
                    },
                    semester: deadline.semester
                };
            } else {
                disciplinesMap[deadline.uuidDiscipline].extraSessions.push(deadline.uuid);
            }
        });

        const userDisciplines = Object.values(disciplinesMap);

        return {
            userUuid: userId,
            user: userInfo,
            disciplines: userDisciplines.map(discipline => {
                const disciplineInfo = disciplinesData[discipline.uuid];

                return {
                    ...disciplineInfo,
                    extraSessions: discipline.extraSessions,
                    deadline: discipline.deadline,
                    semester: discipline.semester
                };
            })
        };

    }).filter((el) => el.user);
};

export const getUsersWithExtraSession = (extraSessions: GetExtraSessionsByUsers[]) => {
    return extraSessions.map(el => el.user);
};

export const getDisciplinesExtraSession = (extraSessions: GetExtraSessionsByUsers[]): DisciplineWithExtraSession[] => {
    let disciplines: DisciplineWithExtraSession[] = [];

    extraSessions.forEach(extraSession => {
        disciplines = [...disciplines, ...extraSession.disciplines];
    });

    return disciplines;
};
