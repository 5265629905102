import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import { NewLoader } from '@components/core/NewLoader';
import ImportTestQuestionsModal from '@components/methodist/ImportTestQuestionsModal';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import Search from '@components/Search';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useSearch } from '@hooks/useSearch';
import { TestDeleteQuestionsRequest, TestGetQuestionsFromBank } from '@store/api/apiTypes';
import { useDeleteTestQuestionsMutation, useGetQuestionsFromBankQuery } from '@store/api/testsApi';
import { Role } from '@store/Roles';
import { TOnSelectType } from '@type/table';
import { QuestionsUploadFile } from '@views/methodist/Materials/Course/CreateCourse/CreateCourseForm/type';
import { useTestContext } from '@views/methodist/Materials/Course/Test/EditTest/EditTest';
import { getQuestionHumanType, TestQuestionWithName } from '@views/methodist/Materials/Course/Test/EditTest/types';

import { ExportQuestions } from './ExportQuestions';
import style from './questions.module.scss';
import { QuestionTable } from './QuestionTable';

const schema = yup.object({
    fileUpload: yup.mixed().required('Добавьте файл!').nullable()
});

const Questions = () => {
    const { uuidSection, uuidTest } = useParams();
    const { testContext } = useTestContext();
    const { search, ...searchInputProps } = useSearch();

    const [showImportModal, setShowImportModal] = useState(false);
    const [questions, setQuestions] = useState<TestQuestionWithName[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [deleteRequest] = useDeleteTestQuestionsMutation();

    const questionsReq:TestGetQuestionsFromBank = {
        filters:{
            limit: 0,
            offset: 0,
            search: search
        },
        uuid: uuidTest!
    };

    const { data: questionsData, isFetching, isSuccess, isError } = useGetQuestionsFromBankQuery(questionsReq);

    const handleCellDelete = (uuid: string) =>{
        const req: TestDeleteQuestionsRequest = {
            testUuid: testContext.test.uuid,
            questionIds: [uuid]
        };

        deleteRequest(req);
    };

    const methods = useForm<QuestionsUploadFile>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            type: 'fileUpload',
            fileUpload: null
        }
    });

    const { reset } = methods;

    const onSubmit = async (qdata: QuestionsUploadFile) => {
        setShowImportModal(false);
        reset();
    };

    const handleTableSelect = (selectObject: TOnSelectType) => {

        const selected_local: string[] = [];

        selectObject.selectedUuids.map(item => {
            if(item != null) // VEIP-1288
                selected_local.push(item);
        });

        setSelected(selected_local);
    };

    const handleTableDelete = () => {

        if(selected.length > 0)
        {
            const req: TestDeleteQuestionsRequest = {
                testUuid:testContext.test.uuid,
                questionIds:selected
            };

            deleteRequest(req);

            setSelected([]);
        }
    };

    useEffect(() => {
        if(isSuccess)
        {
            const quests:TestQuestionWithName[] = questionsData.map(q => {

                const qwa:TestQuestionWithName={
                    name: q.question.question,
                    uuid: q.question.uuid,
                    type: getQuestionHumanType(q.question.type)
                };

                return qwa;
            });

            setQuestions(quests);
        }
    }, [isSuccess, isFetching, isError]);

    return (
        <>
            {
                isSuccess?

                    <React.Fragment>
                        <div>
                            <h2 className={style.title}>Банк вопросов</h2>
                        </div>

                        <div className={style.table_container}>
                            <div className={style.row}>
                                <RoleCheck roles={Role.Methodist}>
                                    <div className={style.table_control}>
                                        <Button
                                            onClick={() => setShowImportModal(true)}
                                            btnType="success" style={{ height: 34 }}
                                        >
                                            Импорт
                                        </Button>
                                    </div>
                                </RoleCheck>

                                <div className={style.table_control}>
                                    <Search {...searchInputProps} />
                                </div>
                            </div>
                            <ExportQuestions testUuid={uuidTest} isQuestionExist={!!questions.length} />
                        </div>

                        <QuestionTable
                            data={questions}
                            onSelectItems={handleTableSelect}
                            onDeleteSelected={handleTableDelete}
                            onBucketClick={handleCellDelete}

                            isFetching={isFetching}
                            isSelectable={checkRole(Role.Methodist)}
                            isDisableSelectPanel
                        />

                        <ImportTestQuestionsModal
                            isOpen={showImportModal}
                            onClose={() => setShowImportModal(false)}
                        />

                    </React.Fragment> : <NewLoader />
            }
        </>
    );
};

export default Questions;
