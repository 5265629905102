import React from 'react';

import IconUI from '@components/core/IconUI';

import style from './Notification.module.scss';

const Notification = () => {
    return (
        <div className={style.root}>
            <IconUI typeIcon={'notification-info-icon'} />
            <p>Вы получили дополнительную попытку для исправления работы, используйте ее с умом.</p>
        </div>
    );
};

export default Notification;
