import { useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import NewTreeSelectControl from '@components/core/inputs/NewTreeSelectControl/TreeSelectControl';
import { transformDataToOptions } from '@components/core/inputs/Select/NewTreeSelect';
import { NewLoader } from '@components/core/NewLoader';
import { DPRangeControl } from '@components/DatePicker/DPRangeControl';
import { IRangeDates } from '@components/DatePicker/DPRangeControl/DPRangeControl';
import Search from '@components/Search';
import { datePrepareToSend } from '@helpers/date';
import { makeGroupTreeFlat } from '@helpers/groupTree';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearch } from '@hooks/useSearch';
import { useLazyGetUsersArchiveQuery } from '@store/api/archiveApi';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useLazyGetUsersByUuidsQuery } from '@store/api/usersApi';
import { User } from '@type/users';
import materialStyle from '@views/methodist/Materials/MaterialsPage/MaterialsList/materialsList.module.scss';

import style from './Archive.module.scss';
import { ArchiveList } from './ArchiveList/ArchiveList';

const eventsLogSchema = yup.object({
    users: yup.array().of(yup.string().required()).required(),
    groups: yup.array().of(yup.string().required()).required(),
    startDate: yup.string().required(),
    endDate: yup.string().required()
});

type IEventLogSchema = yup.InferType<typeof eventsLogSchema>;

interface ArchiveProps {
    start: number;
    limit: number;
    setTotal: (total: number) => void;
}

export const Archive = ({ start, limit, setTotal }: ArchiveProps) => {
    const { search, ...searchInputProps } = useSearch();

    const [users, setUsers] = useState<any>([]);

    const { data: groupsTree } = useGetGroupsTreeQuery('');

    const [getUsersArchive, { isLoading: isLoadingUsersArchive }] = useLazyGetUsersArchiveQuery();
    const [getUsersByUuids] = useLazyGetUsersByUuidsQuery();

    const groupOptions = useMemo(() => {
        const allOptions = groupsTree
            ? [
                ...transformDataToOptions(groupsTree.childs, {
                    value: 'UUID',
                    label: 'name',
                    children: 'childs'
                })
            ]
            : [];

        return allOptions;
    }, [groupsTree]);

    const methods = useForm<IEventLogSchema>({
        resolver: yupResolver(eventsLogSchema),
        defaultValues: {
            groups: [],
            users: [],
            startDate: '',
            endDate: ''
        }
    });

    const { watch, setValue } = methods;

    const watchAllFields = watch();

    const onDateSubmit = (rangeDatesToSubmit: IRangeDates) => {
        setValue('startDate', rangeDatesToSubmit.startDate, { shouldValidate: true });
        setValue('endDate', rangeDatesToSubmit.endDate, { shouldValidate: true });
    };

    useEffect(() => {
        if (!groupsTree) return;

        const areUsers = watchAllFields.users.length;

        const setUsersArchiveFromApi = async () => {
            const usersArchiveData = await getUsersArchive({
                uuidsGroup: areUsers ? [] : watchAllFields.groups,
                search: search,
                firstNoteDate: datePrepareToSend(watchAllFields.startDate).split(' ')[0],
                lastNoteDate: datePrepareToSend(watchAllFields.endDate).split(' ')[0],
                offset: start,
                limit: limit
            });

            const uuidUsers = usersArchiveData?.data?.users.map((user) => user.uuidUser);

            if (uuidUsers?.length && groupsTree) {
                const userList = await getUsersByUuids({ uuids: uuidUsers, showDeleted: true });
                const userDict: Record<string, { user: User; index: number }> = {};
                userList?.data?.map((user) => {
                    userDict[user.uuid] = {
                        user,
                        index: 0
                    };
                });
                const sortedUserList = uuidUsers.map((uuid) => {
                    if (!(uuid in userDict)) {
                        return null;
                    }
                    return userDict[uuid].user;
                }).filter(Boolean) as User[];
                const transformUserList =
                    sortedUserList.map((user) => {
                        const archivedUsers = usersArchiveData?.data?.users.filter((usr) => usr.uuidUser === user.uuid) || [];

                        const findUser = archivedUsers[userDict[user.uuid].index];
                        userDict[user.uuid].index += 1;

                        return {
                            ...user,
                            archiveCreatedAt: findUser?.createdAt,
                            archiveFirstNoteDate: findUser?.firstNoteDate,
                            archiveLastNoteDate: findUser?.lastNoteDate,
                            groups: makeGroupTreeFlat(groupsTree.childs).find((g) => g.UUID === user.groupUUID[0])?.name || ''
                        };
                    }) || [];
                setUsers(transformUserList);
            } else {
                setUsers([]);
            }
            setTotal(usersArchiveData?.data?.total || 0);
        };

        setUsersArchiveFromApi();
    }, [
        search,
        watchAllFields.users,
        watchAllFields.groups,
        watchAllFields.endDate,
        watchAllFields.startDate,
        groupsTree,
        limit,
        start
    ]);

    if (isLoadingUsersArchive) return <NewLoader />;

    return (
        <>
            <div className={style.controls_container}>
                <div className={style.form}>
                    <FormProvider {...methods}>
                        <Search {...searchInputProps} />

                        <NewTreeSelectControl
                            className={style.filter}
                            classes={{
                                input: materialStyle['select__input'],
                                inputIcon: materialStyle['select__icon']
                            }}
                            options={groupOptions}
                            name="groups"
                            placeholder="Выберите группу"
                            selectableParent
                            isMulti={true}
                        />
                        <div className={style.datePicker}>
                            <DPRangeControl onSubmit={onDateSubmit} variant="date" isButton={false} />
                        </div>
                    </FormProvider>
                </div>
            </div>

            <ArchiveList users={users} />
        </>
    );
};
