import React, { FC, ReactNode, useEffect, useState } from 'react';

import { usePopper } from 'react-popper';

import Portal from '@components/Portal';

import style from './Dropdown.module.scss';

interface IDropMenu {
    referenceElement: HTMLElement | null;
    children: ReactNode;
    onClose: () => void;
    showDropdown?: boolean;
    dropdownWidth?: number;
}

const Dropdown: FC<IDropMenu> = ({
    referenceElement,
    children,
    onClose,
    showDropdown = true,
    dropdownWidth
}) => {
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const dropdownWidthAuto = referenceElement?.clientWidth;

    useEffect(() => {
        if (showDropdown) {
            const listener = (event: MouseEvent | TouchEvent ) => {

                if (!popperElement || popperElement.contains(event.target as Node)) {
                    return;
                }

                event.preventDefault();
                event.stopPropagation();
                onClose();

            };
            document.addEventListener('mouseup', listener);
            document.addEventListener('touchstart', listener);
            return () => {
                document.removeEventListener('mouseup', listener);
                document.removeEventListener('touchstart', listener);
            };
        }
    }, [popperElement, onClose, showDropdown]);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [{
            name: 'flip',
            enabled: true,
            phase: 'main',
            options: {
                allowedAutoPlacements: ['bottom', 'top']
            }
        },
        {
            name: 'offset',
            options: {
                offset: [0, 4]
            }
        }]
    });

    if (!showDropdown) return null;

    return (
        <Portal>
            <div
                ref={setPopperElement}
                style={{ ...styles.popper, width: dropdownWidth || dropdownWidthAuto }} {...attributes.popper}
                className={style.wrapper}
            >
                {children}
            </div>
        </Portal>
    );
};

export default Dropdown;
