import { FC } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import Loader from '@components/core/Loader';
import Sidebar from '@components/methodist/Sidebar';
import Drawer from '@components/methodist/Sidebar/Drawer';
import { GroupTree } from '@store/api/apiTypes';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';

import style from './users_tab.module.scss';

const cx = classNames;

interface IProps {
    className?: string;
    path?: string;
}

const UsersSidebar: FC<IProps> = ({ className, path }) => {

    const groupsTree = useGetGroupsTreeQuery('');

    return (
        <>
            <Sidebar>
                <Drawer className={style.wrapper}>
                    <h2 className={style.title}>Управление пользователями</h2>

                    <div className={cx(style.link_wrapper, style.links_section, style.item)}>
                        <NavLink end to="/users"
                            className={({ isActive }) => cx(style.link_header, isActive && style['link--active'])}>
                            {'Пользователи'}
                        </NavLink>
                    </div>

                    <div className={style.links_section}>
                        <p className={style.subtitle}>Группы</p>

                        {
                            groupsTree.isLoading ? <Loader /> :
                                (
                                    groupsTree.isSuccess ?
                                        <ul className={style.list}>
                                            {groupsTree.data.childs.map((item, index) => (
                                                <li key={index} className={style.item}>
                                                    <div className={style.link_wrapper}>
                                                        <NavLink to={`/users/groups/${item.UUID}`}
                                                            className={({ isActive }) => cx(style.link_header, isActive && style['link--active'])}>
                                                            {item.name}
                                                        </NavLink>
                                                    </div>
                                                    {/* {item?.children.length > 0 && (
                                            <ul className={style.content_list}>
                                                {item?.children.map((contentItem, indexContent) => (
                                                    <li key={indexContent} className={style.content_item}>
                                                        <div className={style.link_wrapper}>
                                                            <NavLink
                                                                to={`/users/${contentItem?.UUID}`}
                                                                className={({ isActive }) => cx(isActive && style['link--active'])}
                                                            >
                                                                {contentItem?.name}
                                                            </NavLink>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        )} */}
                                                </li>
                                            ))}
                                        </ul>
                                        : null
                                )
                        }
                    </div>

                </Drawer>
            </Sidebar>
        </>
    );
};

export default UsersSidebar;
