export enum Role {
    Student = 'student',
    Teacher = 'teacher',
    Methodist = 'metodist',
    Inspector = 'inspector',
    Nobody = ''
}

export const roleNames = {
    [Role.Methodist]: 'Методист',
    [Role.Teacher]: 'Преподаватель',
    [Role.Student]: 'Студент',
    [Role.Inspector]: 'Сотрудник деканата',
    [Role.Nobody]: ''
};
