import { FC, useState } from 'react';

import cx from 'classnames';

import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';
import { Antiplagiarism } from '@components/Antiplagiarism';
import Button from '@components/core/inputs/Button';
import ButtonMenu from '@components/core/inputs/Button/ButtonMenu';
import IconButton from '@components/core/styledWrappers/IconButton';
import Paper from '@components/core/styledWrappers/Paper';
import { DocumentPreview } from '@components/DocumentPreview';
import { Linkify } from '@components/Linkify';
import { MenuItem } from '@components/Menu';
import { dateConvert } from '@helpers/date';
import { GradeTeacher } from '@store/api/apiTypes';
import AnswerForm from '@views/teacher/GradesTask/AnswerForm';
import GradeReview from '@views/teacher/GradesTask/GradeReview';

import style from './CompletedTask.module.scss';

interface ICompletedTask {
    attempt: GradeTeacher;
    index: number;
    maybeEdit: boolean;
}

const CompletedTask: FC<ICompletedTask> = ({ attempt, index, maybeEdit }) => {
    const [isEdit, setIsEdit] = useState(false);

    const [showFile, setShowFile] = useState(false);

    const handleClickEdit = () => setIsEdit((prev) => !prev);

    return (
        <Paper fullWidth={true} className={cx(style.complete_paper, isEdit && style.edit_papper)}>
            <div className={style.header}>
                <h2 className={style.title}>Попытка {index + 1}</h2>

                <div className={style.controls}>
                    <ButtonMenu className={style.menu} btnType="menu" size="small">
                        <MenuItem
                            onClick={() => {
                                window.open(attempt.file.path, '_blank');
                            }}
                        >
                            Скачать
                        </MenuItem>
                    </ButtonMenu>

                    {maybeEdit && (
                        <IconButton onClick={handleClickEdit}>
                            <EditIcon />
                        </IconButton>
                    )}
                </div>
            </div>

            <div className={style.content}>
                <div className={style.file_upload}>
                    <div className={style.button}>
                        <Button
                            typeIcon="clip"
                            btnType="download"
                            onClick={() => {
                                setShowFile(true);
                            }}
                        >
                            <p className={style.button_text}>
                                <span className={style.button_text_title}>{attempt.file.title}</span>
                            </p>
                        </Button>
                    </div>

                    {/* <FilesListMenu files={[attempt.file]} /> */}
                    <span className={style.date}>
                        Отправлено на проверку:
                        {dateConvert(attempt.createdAt.replace(/ \+0000 UTC/, '')).toFormat(' dd.LL.yyyy, в HH:mm')}
                    </span>
                </div>
                {/* АНТИПЛАГИАТ */}

                {attempt.antiplagiarism && (
                    <Antiplagiarism result={attempt.antiplagiarism} file={attempt.antiplagiatFile} passedUniqueness={attempt.passedUniqueness} />
                )}

                {showFile && <DocumentPreview file={attempt.file} toggle={setShowFile} />}

                {attempt.studentComment && (
                    <div>
                        <h3 className={style.comment_title}>Комментарий</h3>
                        <p className={style.comment_content}>
                            {' '}
                            <Linkify content={attempt.studentComment} />{' '}
                        </p>
                    </div>
                )}

                {attempt.checked && !isEdit ? (
                    <GradeReview
                        grade={attempt.grade}
                        teacherReview={attempt.teacherReview}
                        teacher={attempt.teacher}
                        updatedAt={attempt.updatedAt}
                        isAttemptChecked={attempt.checked}
                    />
                ) : (
                    <AnswerForm
                        uuidGrade={attempt.uuid}
                        maxRating={attempt.task.maxRating}
                        defaultValues={{
                            grade: attempt.grade,
                            review: attempt.teacherReview,
                            retakeAvailable: Boolean(attempt.retakeAvailable)
                        }}
                        onClose={handleClickEdit}
                        isEdit={isEdit}
                    />
                )}
            </div>
        </Paper>
    );
};

export default CompletedTask;
