import { Outlet } from 'react-router-dom';

import RouterTabs from '@components/RouterTabs/RouterTabs';

import style from './ReportsTabs.module.scss';

const tabs = [
    { name: 'Отчет по преподавателям', path: '' },
    { name: 'Ведомость', path: 'statements' },
    { name: 'Журнал событий', path: 'eventsLog' }
];

const ReportsTabs = () => {
    return (
        <>
            <div className={style.tabs_wrapper}>
                <RouterTabs className={style.tabs} tabs={tabs} />
            </div>

            <Outlet />
        </>
    );
};

export default ReportsTabs;
