import React from 'react';

import { Outlet, useParams } from 'react-router-dom';

import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';

export const ChangePasswordWrapper = () => {
    const me = useAppSelector(selectApiUser);
    const { userId } = useParams();

    if (me?.uuid === userId) {
        return <Outlet />;
    }
    return null;
};
