import { FC } from 'react';

import cx from 'classnames';

import { IGridProps } from '@components/core/grid/type';
import { isEmptyObject } from '@helpers/isEmptyObject';

const Grid: FC<IGridProps> = ({
    className,
    children,
    expand,
    alignItems,
    justifyContent,
    gridTemplateColumns,
    gap,
    columnGap,
    rowGap,
    width,
    height,
    ...props
}) => {

    const classNames = cx(
        'grid',
        expand ? 'grid--expand' : '',
        alignItems ? `grid-align-items--${alignItems}` : '',
        justifyContent ? `grid-justify-content--${justifyContent}` : '',
        className
    );

    const inlineStyles = {
        gridTemplateColumns,
        width,
        height,
        gap,
        rowGap,
        columnGap
    };

    return (
        <div
            className={classNames}
            style={!isEmptyObject(inlineStyles) ? inlineStyles : undefined}
            {...props}
        >
            {children}
        </div>
    );
};

export { Grid };
