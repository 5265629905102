import React, { FC } from 'react';

import cx from 'classnames';

import style from './Breadcrumbs.module.scss';

export interface IBreadcrumbsProps {
    children?: React.ReactNode;
    noScroll?: boolean;
}

/*
    Example usage:

    <Breadcrumbs>
        <Link>Item 1</Link>
        <Link>Item 2</Link>
        <Link>Item 3</Link>
        <Link>Item 4</Link>
        <Link>Item 5</Link>
    </Breadcrumbs>

*/

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({ children, noScroll }) => {

    return (
        <div className={cx(style.wrapper, noScroll && style['no-scroll'])}>
            <div className={style.root}>
                { React.Children.toArray(children).map((item, index) => {
                    return <div key={`bread${index}`} className={style.item}>{item}</div>;
                })}
            </div>
        </div>
    );
};
