import { FC } from 'react';

import cx from 'classnames';

import { declination, getScoreCaption, getTypeScore } from '@helpers/scores';
import { CloseTypes } from '@store/api/apiTypes';

import style from './Score.module.scss';

export interface IScoreProps {
    score: number;
    closeType: CloseTypes;
}

export const Score:FC<IScoreProps> = (
    {
        score,
        closeType
    }
) => {

    const renderScore = () => {
        const type = getTypeScore(score, closeType);
        if (!type) return null;

        const scoreCl = cx(
            style.score,
            {
                [style.bad]: type === 'bad' || type === 'failed',
                [style.medium]: type === 'medium',
                [style.good]: type === 'good',
                [style.excelent]: type === 'excelent' || type === 'success'
            }
        );
    
        return <div className={style.container}>
            <div className={scoreCl}>{getScoreCaption(score, type)}</div>
        </div>;
    };
    
    return  <div className={style.score}>{renderScore()}</div>;
};