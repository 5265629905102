import { FC } from 'react';

import cx from 'classnames';
import { Control, useController } from 'react-hook-form';

import style from './TextArea.module.scss';

export interface ITextAreaProps {
    name: string;
    control: Control<any>;
    className?: string;
    accessTypes?: string[];
    children?: React.ReactNode;
    isMulti?: boolean;
    caption?: boolean;
}


export const TextArea: FC<ITextAreaProps> = ({ control, name, className, isMulti, caption, ...props }) => {
    const {
        field: { onChange, value },
        fieldState: { error }
    } = useController({ name, control });

    return <textarea
        name={name}
        id=""
        onChange={onChange}
        value={value}
        rows={7}
        className={cx(style.container, className)}
    />;
};
