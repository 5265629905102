import React, { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as DownloadIcon } from '@assets/icons/dovnload-portfolio-file-icon.svg';
import { ReactComponent as EmptySectionIcon } from '@assets/icons/empti-section-icon.svg';
import { ReactComponent as FeedbackIcon } from '@assets/icons/feedback-icon.svg';
import BasicAccordion from '@components/BasicAccordion';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { NewLoader } from '@components/core/NewLoader';
import { DataList } from '@components/DataList';
import { Pagination, usePagination } from '@components/Pagination';
import { checkRole } from '@components/RoleCheck';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useToggle } from '@hooks/useToggle.hook';
import { useGetPortfolioGradesQuery } from '@store/api/portfolioApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { FeedbackModal } from '@views/student/StudentProfile/Portfolio/FeedbackModal';


import style from './ControlWorksList.module.scss';

const ControlWorksList = () => {
    const lgDown = useBreakpoint('lg', 'down');
    const { userId } = useParams();
    const user = useAppSelector(selectApiUser);

    const { data: portfolioGrades, isLoading: isLoadingGrades } = useGetPortfolioGradesQuery(
        checkRole([Role.Methodist, Role.Teacher]) ? userId as string : user?.uuid || ''
    );


    const { handleSliceData, setTotal, paginationProps } = usePagination<any>({});
    const [isOpenedDetails, { on, off }] = useToggle();
    const [detailsData, setDetailsData] = useState<any | null>(null);
    const openFeedbackModalHandler = (teacherReview: any) => (e: any) => {
        e.stopPropagation();
        setDetailsData(teacherReview);
        on();
    };

    const renderControlWork = (attempt: any, label?: boolean) => {
        const getLink = () => {
            if (checkRole(Role.Student)) return `../../education/${attempt.discipline}/task/${attempt.material}`;
            if (checkRole(Role.Teacher)) return `../grades/${attempt.user}/${attempt.material}`;
            if (checkRole(Role.Methodist)) return `../../../../materials/uuidCategory/course/${attempt.discipline}/scores/task/${attempt.user}/${attempt.material}`;

            return '';
        };

        return (
            <div className={style.control_work} key={attempt.uuid}>
                <div className={style.control_work__wrapper}>
                    <div className={style.control_work__grade_wrapper}>
                        <div className={style.control_work__grade}>{attempt.grade} / {attempt.maxGrade}</div>
                        {lgDown && (
                            <a
                                href={attempt.fileUrl}
                                target="_blank"
                                download={true}
                                rel="noreferrer"
                                className={style.control_work__download_btn}
                            >
                                <DownloadIcon />
                            </a>
                        )}
                    </div>
                    <div className={style.control_work__discipline_name_wrapper}>
                        <Link to={getLink()}
                            className={style.control_work__discipline_name}>{attempt.name}</Link>
                        {lgDown && (
                            <ButtonIcon
                                className={cx(style.control_work__feedback_btn, style.control_work__feedback_btn__label)}
                                onClick={openFeedbackModalHandler(attempt.reviewTeacher)}
                            >
                                <FeedbackIcon />
                            </ButtonIcon>
                        )}
                    </div>

                    <div className={style.control_work__discipline_wrapper}>
                        <div className={style.control_work__material_name}>Контрольная работа</div>
                        <span className={style.control_work__separator} />
                        <div className={style.control_work__attempt}>Попытка {attempt.order} {label && '(лучшая)'}</div>
                    </div>
                    {!lgDown && (
                        <ButtonIcon
                            className={style.control_work__feedback_btn}
                            onClick={openFeedbackModalHandler(attempt.reviewTeacher)}
                        >
                            <FeedbackIcon />
                        </ButtonIcon>
                    )}
                </div>
                {!lgDown && (
                    <a
                        href={attempt.fileUrl}
                        target="_blank"
                        download={true}
                        rel="noreferrer"
                        className={style.control_work__download_btn}
                    >
                        <DownloadIcon />
                    </a>
                )}
            </div>
        );
    };
    const getEmptyMessage = () => {
        return (
            <div className={style.empty_message}>
                <EmptySectionIcon className={style.empty_message__icon} />
                <p className={style.empty_message__text}>Здесь пока ничего нет</p>
            </div>
        );
    };

    useEffect(() => {
        setTotal(portfolioGrades?.portfolioGradeList?.length || 0);
    }, [portfolioGrades]);

    // сортируем попытки сдачи от лучшей к худшей
    const sortGradesList = useMemo(() => {
        return portfolioGrades?.portfolioGradeList.map((item) => {
            // перед сортировкой запоминаем order
            const transformData = item.portfolioGrade.map((attempt, index) => {
                return { ...attempt, order: index + 1 };
            });

            return [...transformData].sort(function (a, b) {
                return parseFloat(String(b.grade)) - parseFloat(String(a.grade));
            });
        });
    }, [portfolioGrades]);

    if (isLoadingGrades) return <NewLoader />;

    return (
        <>
            <DataList className={style.list_wrapper}>
                {
                    !sortGradesList?.length ?
                        <div className={style.empty_message}>{getEmptyMessage()}</div>
                        :
                        handleSliceData(sortGradesList).map((attempts, index) => {
                            return (
                                <div key={index}>
                                    <BasicAccordion
                                        label={renderControlWork(attempts[0], true)}
                                        id={String(index)}
                                        className={style.accordion}
                                        isExpand={attempts.length > 1}
                                    >
                                        {
                                            [...attempts.filter((elem: any, i: number) => i > 0)]
                                                .sort(function (a, b) {
                                                    return parseFloat(String(a.order)) - parseFloat(String(b.order));
                                                })
                                                .map(attempt => renderControlWork(attempt))
                                        }
                                    </BasicAccordion>
                                </div>
                            );
                        })
                }
            </DataList>

            <Pagination className={style.pagination} {...paginationProps} />

            {isOpenedDetails && (
                <FeedbackModal opened={isOpenedDetails} onClose={off} data={detailsData} onOpen={on} />
            )}
        </>
    );
};

export default ControlWorksList;
