import { toast } from 'react-toastify';

import { Uuid } from '@store/api/apiTypes';
import {
    PortfolioAddSectionTypeRequest,
    PortfolioAddSectionTypeResponse,
    PortfolioDeleteSectionTypeRequest,
    PortfolioDeleteSectionTypeResponse,
    PortfolioEditSectionTypeRequest,
    PortfolioFile,
    PortfolioGetFilesListRequest,
    PortfolioGradesList,
    PortfolioSectionType,
    UpdatePositionPortfolioSection
} from '@type/portfolio';

import { emptyBaseApi } from './emptyBaseApi';

export const materialsApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getPortfolioSectionsList: build.query<PortfolioSectionType[], void>({
            query(data) {
                return {
                    url: 'portfolio/get_types',
                    method: 'POST',
                    body: {}
                };
            },

            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список разделов');
                }
            },
            transformResponse: (result: { portfoliotype: PortfolioSectionType[] }) => {
                return result.portfoliotype;
            },
            providesTags: (result, error, arg) => [{ type: 'Portfolio' }]
        }),

        getPortfolioGrades: build.query<PortfolioGradesList, Uuid>({
            query(userId) {
                return {
                    url: 'portfolio/portfolio_grades',
                    method: 'POST',
                    body: { uuid: userId }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список контрольных');
                }
            },
            transformResponse: (result: PortfolioGradesList) => {
                return result;
            }
        }),

        getPortfolioFilesList: build.query<PortfolioFile[], PortfolioGetFilesListRequest>({
            query(data) {
                return {
                    url: 'portfolio/files_list',
                    method: 'POST',
                    body: { ...data }
                };
            },

            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список файлов');
                }
            },
            transformResponse: (result: { portfolioFileList: PortfolioFile[] }) => {
                return result.portfolioFileList;
            },
            providesTags: (result, error, arg) => [{ type: 'PortfolioFilesList' }]
        }),

        addPortfolioSectionType: build.mutation<PortfolioAddSectionTypeResponse, PortfolioAddSectionTypeRequest>({
            query(data) {
                return {
                    url: 'portfolio/add_type',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Раздел создан',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => ['Portfolio']
        }),

        updatePositionsPortfolioSectionById: build.mutation<void, UpdatePositionPortfolioSection>({
            query(data) {
                return {
                    url: 'portfolio/move_type',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Позиция раздела сохранена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => ['Portfolio']
        }),

        deletePortfolioSectionById: build.mutation<PortfolioDeleteSectionTypeResponse, PortfolioDeleteSectionTypeRequest>({
            query(data) {
                return {
                    url: 'portfolio/active_hide_type',
                    method: 'POST',
                    body: { ...data, action: 'active', value: false }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Раздел удален',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => ['Portfolio', { type: 'Portfolio', id: arg.uuid }]
        }),

        editPortfolioSectionById: build.mutation<PortfolioDeleteSectionTypeResponse, PortfolioEditSectionTypeRequest>({
            query(data) {
                return {
                    url: 'portfolio/type_name_change',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Изменения сохранены',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => ['Portfolio', { type: 'Portfolio', id: arg.typeId }]
        }),

        portfolioFilesChange: build.mutation<{ success: true, fileUuid: string }, Partial<PortfolioFile>>({
            query(data) {
                return {
                    url: 'portfolio/files_change',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Сохранено',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => ['PortfolioFilesList', { type: 'PortfolioFilesList', id: arg.uuid }]
        })
    })
});

export const {
    useGetPortfolioSectionsListQuery,
    useGetPortfolioGradesQuery,
    useGetPortfolioFilesListQuery,
    useAddPortfolioSectionTypeMutation,
    useUpdatePositionsPortfolioSectionByIdMutation,
    usePortfolioFilesChangeMutation,
    useDeletePortfolioSectionByIdMutation,
    useEditPortfolioSectionByIdMutation
} = materialsApi;
