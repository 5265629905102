import React from 'react';

import { components, DropdownIndicatorProps, PlaceholderProps, ValueContainerProps } from 'react-select';

import { OptionType } from '@components/core/inputs/SelectStyled/type';

import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow-icon.svg';

export const CustomPlaceholder = (props: PlaceholderProps<OptionType>) => {
    return (
        <components.Placeholder {...props} isFocused={props.isFocused}>
            {props.selectProps.placeholder}
        </components.Placeholder>
    );
};

export const CustomValueContainer = ({ children, ...props }: ValueContainerProps<OptionType>) => {

    return (
        <components.ValueContainer {...props}>
            {
                // @ts-ignore:next-line
                props.isMulti && CustomPlaceholder({ ...props })
            }
            {React.Children.map(children, (child) => {
                return child &&
                    // @ts-ignore:next-line
                    child.type !== components.MultiValue &&
                    // @ts-ignore:next-line
                    child.type !== components.Placeholder
                    ? child 
                    : null;
            })}
        </components.ValueContainer>
    );
};

export const CustomDropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowIcon />
        </components.DropdownIndicator>
    );
};
