import { FC, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { useParams } from 'react-router-dom';

import Loader from '@components/core/Loader';
import { DataList, DataListBody } from '@components/DataList';
import { DataListItem } from '@components/DataList/DataListItem';
import Modal from '@components/Modal';
import { IModalProps } from '@components/Modal/Modal';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { IGroupDeadlinesApiResponse, IStudentDeadlinesApiResponse } from '@store/api/apiTypes';
import { useGetGroupDeadlinesQuery, useGetSubscribedDisciplinesQuery, useGetSubscribedIndividualsStudentDisciplinesQuery } from '@store/api/groupsApi';
import { resetDeadlines } from '@store/features/deadlinesSlice';
import { useAppDispatch } from '@store/hooks';

import SetEditTermsMode from '../SetEditTermsMode';
import { TermsCalendarPage } from './TermsCalendarPage';
import { TermsControls } from './TermsControls';
import style from './TermsModal.module.scss';

export type TTermsModalType = 'person' | 'group';
export interface ISetupTermsModal extends IModalProps {
    type?: TTermsModalType;
    localStudents?: string[];
    isOpen: boolean;
    isGroupTerms: boolean;
    studentsDeadlines: IStudentDeadlinesApiResponse[]
}

const getIndividualMaterials = (usersDeadlines: IStudentDeadlinesApiResponse[], localStudents?: string[]): IGroupDeadlinesApiResponse[] => {
    const materials: IGroupDeadlinesApiResponse[] = [];

    const filtered = usersDeadlines.filter(user => localStudents?.includes(user.userUUID));
    filtered.forEach(userDeadlines => {
        userDeadlines.material.forEach(material => {
            materials.push(material);
        });

    });

    return materials;
};

export const TermsModal: FC<ISetupTermsModal> = ({
    onClose,
    isOpen,
    isGroupTerms = false,
    type = 'group',
    localStudents = [],
    studentsDeadlines,
    ...props
}) => {

    const dispatch = useAppDispatch();
    const { uuidGroup } = useParams();

    const [title, setTitle] = useState('Сроки сдачи');
    const [showEditTermsModal, setShowEditTermsModal] = useState(false);
    const [isTermsModalHidden, setIsTermsModelHidden] = useState(false);
    const [isSetupMode, setIsSetupMode] = useState(false);

    const [nextPage, setNextPage] = useState(false);
    const disciplinesByGroup = useGetSubscribedDisciplinesQuery(uuidGroup ? [uuidGroup] : skipToken);
    const individualsDisciplinesQueryData = useGetSubscribedIndividualsStudentDisciplinesQuery(!isGroupTerms && localStudents.length ? localStudents : skipToken);

    const disciplinesArray = useMemo(() => {
        if (disciplinesByGroup.data) {
            const extraDisciplinesArray = !isGroupTerms && localStudents?.length && individualsDisciplinesQueryData.data
                ? individualsDisciplinesQueryData.data.filter(el => !disciplinesByGroup.data?.find(d => d.disciplineUUID === el.disciplineUUID))
                : [];

            return [
                ...disciplinesByGroup.data,
                ...extraDisciplinesArray
            ];
        }
        return [];
    }, [disciplinesByGroup.data, individualsDisciplinesQueryData.data, localStudents, isGroupTerms]);

    const { groupDeadlines } = useGetGroupDeadlinesQuery(uuidGroup ? uuidGroup : skipToken, {
        selectFromResult: ({ data: deadlinesData }) => {
            return ({
                groupDeadlines: deadlinesData
            });
        }
    });

    const toggleClose = () => {
        setShowEditTermsModal(false);
        dispatch(resetDeadlines());
        setNextPage(false);
    };

    const choseTermsMode = (isSetup: boolean) => {
        onClose(false);
        setShowEditTermsModal(true);
        setIsSetupMode(isSetup);
        setTitle(isSetup ? 'Установить сроки сдачи' : 'Удалить сроки сдачи');
    };

    const isLoading = disciplinesByGroup.isFetching || individualsDisciplinesQueryData.isFetching;

    return <>
        <SetEditTermsMode
            setEditMode={choseTermsMode}
            isOpen={isOpen}
            onClose={onClose}
            title={isGroupTerms ? 'Сроки сдачи группы' : 'Сроки сдачи'}
        />
        <Modal {...props} isOpen={showEditTermsModal} title={title} onClose={toggleClose} className={cx(style.container, isTermsModalHidden && style.hide)}>
            {
                !nextPage ?  ( <DataList>

                    <DataListBody scrolled style={{ height: 500 }}>
                        {
                            !isLoading ? disciplinesArray.map(theD => {
                                const disciplineDeadlines = isGroupTerms
                                    ? groupDeadlines?.filter(deadline => deadline.disciplineUUID === theD.disciplineUUID) ?? []
                                    : getIndividualMaterials(studentsDeadlines, localStudents);

                                const shouldRenderItemForDeadlineDelete = isSetupMode || disciplineDeadlines.length;

                                return shouldRenderItemForDeadlineDelete ? <DataListItem
                                    isSetupMode={isSetupMode}
                                    defaultDeadlines={disciplineDeadlines}
                                    key={`di-${theD.disciplineUUID}`}
                                    disciplineUUID={theD.disciplineUUID} /> : null;
                            }) : <Loader />
                        }
                    </DataListBody>
                </DataList>)
                    :
                    (<TermsCalendarPage />)
            }

            <TermsControls
                type={type}
                localStudents={localStudents}
                onClose={toggleClose}
                nextPage={nextPage}
                setNextPage={setNextPage}
                isSetupMode={isSetupMode}
                hideParentModal={setIsTermsModelHidden}
            />
        </Modal>
    </>;
};
