import React, { FC } from 'react';

import CSVPreview from '@components/core/styledWrappers/CSVPreview';


interface ICardSubHeaderProps {
    subTitle?: string;
}

export const CardSubHeader: FC<ICardSubHeaderProps> = ({
    subTitle
}) => (<CSVPreview content={subTitle} />);
