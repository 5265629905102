import React, { FC } from 'react';

import cx from 'classnames';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import style from './tabs.module.scss';

interface IProps {
    tabs?: string[];
    panels?: React.ReactNode[] | string[];
    type?: 'grey';
    className?: string;
    itemsInTab?: string[];
    containerClassName?: string;
}

type RenderTabList = (tabs?: string[], className?: string, itemsInTab?: string[]) => React.ReactNode;

export const renderTabList: RenderTabList = (tabs, className, itemsInTab) => (
    <TabList className={cx(className, 'react-tabs__tab-list')}>
        {tabs?.map((item, index) => {
            return (
                <Tab key={`tab-${index}`}>
                    <div className={style.tabs_content}>
                        {item}

                        {
                            itemsInTab && itemsInTab[index] &&
                            <div className={'tab_items_count'}>
                                {itemsInTab[index]}
                            </div>
                        }
                    </div>
                </Tab>
            );
        })}
    </TabList>
);

export const renderPanelList = (panels?: React.ReactNode[] | string[]) =>
    panels?.map((panel, index) => {
        return <TabPanel key={`tab-${index}`}>{panel}</TabPanel>;
    });

// ToDo: стили в модуль и отрефакторить их
const TTabs: FC<IProps> = ({ tabs, panels, type, className, itemsInTab, containerClassName }) => {
    return (
        <Tabs className={cx(style.root, type === 'grey' && style['react-tabs--grey'], containerClassName)}>
            {renderTabList(tabs, className, itemsInTab)}
            {renderPanelList(panels)}
        </Tabs>
    );
};

export default TTabs;
