import { FC } from 'react';

import { ContactCard } from '../ContactCard/ContactCard';
import { IDudesCardsProps } from '../Contacts';

export const ContactTech:FC<IDudesCardsProps> = ({ items }) => {

    return <>
        {
            items.map((card, index) => {
                return <ContactCard key={`card-${index}`} {...card} />;
            })
        }
    </>;
};