import { useEffect, useState } from 'react';

import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useChat } from '@components/Chat/context';
import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import { StringOptionType } from '@components/core/inputs/SelectStyled/type';
import { NewLoader } from '@components/core/NewLoader';
import { Helmet } from '@components/Helmet';
import { useBreakpoint } from '@hooks/useBreakpoint';
import usePreventPageClose from '@hooks/usePageClose';
import { useToggle } from '@hooks/useToggle.hook';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
    EnumTestAnswer,
    passTestRequest
} from '@store/api/apiTypes';
import {
    testsApi,
    useFinishStudentsTestMutation,
    useGetStudentsTestWithSnapshotQuery,
    useSendStudentsTestSnapshotMutation,
    useTestAttemptsQuery
} from '@store/api/testsApi';
import {
    addAuthenticationAttempt,
    removeAuthenticationAttempt,
    removeAuthenticationUuid,
    selectAuthenticationData
} from '@store/features/authenticationSlice';
import { selectDeviceUuid } from '@store/features/authSlice';
import {
    setTestCache,
    selectTestCache
} from '@store/features/passTestSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useTest } from '@views/student/StudentTest/StudentTest';

import { StudentTestQuestionWithAnswers } from './../../../../store/api/apiTypes';
import Counter from './Counter/Counter';
import FinishTestModal from './FinishTestModal/FinishTestModal';
import style from './NewTestPassing.module.scss';
import NewTestQuestion from './NewTestQuestion';


export type IToMatchAnswer = {
    subDescription: string;
    subAnswers: StringOptionType[];
    uuid: string;
};

const NewTestPassing = () => {
    const navigate = useNavigate();
    const { uuidTest, uuidDiscipline } = useParams();

    const dispatch = useAppDispatch();
    const uuidDevice = useAppSelector(selectDeviceUuid);
    const testCache = useAppSelector(selectTestCache);
    const authentication = useAppSelector(selectAuthenticationData).find(auth => auth.uuidTest === uuidTest);

    const smUp = useBreakpoint('sm', 'up');
    const [isOpen, { on, off }] = useToggle();

    const { testData, discipine } = useTest();

    const { sendServiceMessage } = useChat();

    const [isDangerous, setIsDangerous] = useState(false);
    const [testSnapshot, setTestSnapshot] = useState<passTestRequest[]>([] as passTestRequest[]);
    const [markQuestions, setMarkQuestions] = useState<string[]>();

    const [sendTestResult] = useFinishStudentsTestMutation();
    const [sendTestSnapshot] = useSendStudentsTestSnapshotMutation();
    const { refetch, isFetching } = useTestAttemptsQuery(uuidTest ?? skipToken);

    // Предотвращение закрытия страницы во время отправки запроса завершения попытки
    usePreventPageClose(isFetching);

    // Если тест в кеше или недостаточно данных, то не отправляем запрос на получение теста
    const isShouldGetTest = uuidTest && authentication && testCache !== uuidTest;
    const test = useGetStudentsTestWithSnapshotQuery(isShouldGetTest ? {
        uuidTest: uuidTest,
        uuidAuth: authentication?.uuidAuthentication || '',
        uuidDevice: uuidDevice || ''
    } : skipToken);

    // Сдача теста
    const finishTest = async () => {

        try {
            // Отправка результатов
            if (test.data) {
                sendTestResult({
                    uuidTest: test.data.test.uuid,
                    uuidAttempt: test.data.uuidAttempt,
                    uuidDiscipline: uuidDiscipline || '',
                    questions: testSnapshot
                });
            }
        } catch (error: any) {
            const message = {
                testSnapshot,
                params: {
                    uuidTest: test?.data?.test.uuid,
                    uuidAttempt: test?.data?.uuidAttempt,
                    uuidDiscipline: uuidDiscipline || '',
                    questions: testSnapshot
                },
                originMessage: error?.message,
                errorData: error?.data,
                errorStatus: error?.status,
                errorError: error?.error,
                errorCode: error?.code
            };
            localStorage.setItem('TEST_ERROR', JSON.stringify(message));
            sendServiceMessage(JSON.stringify(message));
        } finally {
            // Удаление теста из кеша аутентификации(теперь можно проходить аутентификацию на данный тест)
            dispatch(removeAuthenticationAttempt(uuidTest || ''));
            // Удаление uuid аутентификации попытки
            dispatch(removeAuthenticationUuid(authentication?.uuidAuthentication || ''));
            // Очищение localStorage
            localStorage.removeItem(`marks:${uuidTest}`);
            // Задаем кеш, запрещая юзеру возвращаться на данный тест(попытку)
            dispatch(setTestCache(uuidTest || ''));
            navigate('../');
        }
    };

    // Заполнение снепшота теста
    const prepareAnswerToSnapshot = (studQuestion: StudentTestQuestionWithAnswers) => {
        let studentAnswer;

        if (test.data?.studentsAnswers) {
            studentAnswer = test.data.studentsAnswers.find(answer => answer.uuidQuestion === studQuestion.question.uuid);
        }

        // question TO_MATCH type

        if (studQuestion.question.type === EnumTestAnswer.TO_MATCH) {

            if (studentAnswer) {
                return {
                    uuidQuestion: studentAnswer.uuidQuestion,
                    type: studentAnswer.type,
                    toMatchAnswers: studentAnswer.toMatchAnswers
                };
            }

            return {
                uuidQuestion: studQuestion.question.uuid,
                type: studQuestion.question.type,
                toMatchAnswers: studQuestion.answers.map(answer => {
                    return {
                        uuidAnswer: answer.answerToMatch.uuid,
                        subAnswer: ''
                    };
                })
            };
        }

        // question SINGLE or MULTIPLY type

        if (studentAnswer) {
            return {
                uuidQuestion: studentAnswer.uuidQuestion,
                type: studentAnswer.type,
                uuidsAnswers: studentAnswer.uuidsAnswers
            };
        }

        return {
            uuidQuestion: studQuestion.question.uuid,
            type: studQuestion.question.type as EnumTestAnswer,
            uuidsAnswers: []
        };
    };

    const handleMarksLocalStorage = () => {
        const localStorageMarksQuestions = localStorage.getItem(`marks:${uuidTest}`);

        if (localStorageMarksQuestions && JSON.parse(localStorageMarksQuestions).length) {
            setMarkQuestions(JSON.parse(localStorageMarksQuestions));
        }
    };

    const onCounterTick = ({ total }: { total: number }) => {
        if (total < 3 * 60 * 1000) {
            setIsDangerous(true);
        }
    };

    // Очистка кеша, для того чтобы при следующем вмонтировании
    // компонента получать актуальные данные для таймера
    useEffect(() => {
        return () => {
            dispatch(testsApi.util.resetApiState());
        };

    }, []);

    useEffect(() => {
        if (test.data) {
            // Заполнение снэпшота при загрузке данных теста
            setTestSnapshot(test.data.question.map(prepareAnswerToSnapshot));

            // Сохранение данной попытки в стор, для того чтобы юзер после начала теста не смог вернуться на ауентификацию
            if (isShouldGetTest) {
                dispatch(addAuthenticationAttempt({
                    uuidAttempt: test.data.uuidAttempt,
                    uuidTest: uuidTest
                }));
            }

            // Обновление данных о попытках
            refetch();
        }

        handleMarksLocalStorage();

    }, [test.data, test.isFetching, test.isSuccess]);

    // Отправка нового снепшота на бэк
    useEffect(() => {
        if (test?.data) sendTestSnapshot({ uuidAttempt: test.data.uuidAttempt, questions: testSnapshot });
    }, [testSnapshot]);

    // Обновление закладок
    useEffect(() => {
        if (markQuestions) localStorage.setItem(`marks:${uuidTest}`, `${JSON.stringify(markQuestions)}`);
    }, [markQuestions]);

    if (test.isFetching) return <NewLoader />;

    // Если данный тест есть в кэше, то не пускаем на него, для того чтобы юзер не вернулся на тест после его сдачи
    if (testCache === uuidTest) {
        navigate('../');
        return null;
    }

    // Если у пользователя нет аутентификации на данном устройстве, редиректим на страницу аутентификации
    if (!authentication) {
        dispatch(removeAuthenticationAttempt(uuidTest || ''));
        navigate('../auth');
        return null;
    }

    if (test.isError) {
        const error: any = test.error;

        // При ошибке аутентификации отправляем на повторную
        if (error && error.status === 400) {
            navigate('../auth');
        } else {
            navigate('../');
        }
    }

    // Если в тесте нет вопросов, уведомляем об этом
    if (test.isSuccess && !test.data?.question.length) {
        navigate('../');
        toast.error('Произошла ошибка. В тесте нет вопросов. Обратитесь в поддержку');
    }

    return (
        <>
            <Helmet title={`Обучение – ${discipine.name}: ${testData.test.name}`} />
            <div className={style.wrapper}>
                <div className={style.header}>
                    <h1>Итоговое тестирование</h1>
                </div>
                <div className={style.questions_wrapper}>
                    <div className={style.questions}>
                        {
                            test.data?.question.map((studQuestion, index) => {
                                return (
                                    <NewTestQuestion
                                        key={studQuestion.question.uuid}
                                        studQuestion={studQuestion}
                                        index={index}
                                        testSnapshot={testSnapshot}
                                        setTestSnapshot={setTestSnapshot}
                                        markQuestions={markQuestions || []}
                                        setMarkQuestions={setMarkQuestions}
                                    />
                                );
                            })
                        }
                    </div>
                    <div className={style.question_navigation}>
                        <div className={style.question_navigation_items}>
                            {test.data?.question.map((studQuestion, index) => (
                                <a href={`#${studQuestion.question.uuid}`}
                                    className={cx(
                                        style.question_navigation_item,
                                        {
                                            [style.question_navigation_item_active]: markQuestions?.includes(studQuestion.question.uuid)
                                        }
                                    )}
                                    key={studQuestion.question.uuid}>{++index}
                                </a>
                            ))}
                        </div>
                        <div className={style.timer_wrapper}>
                            <Button
                                className={style.question_navigation_btn}
                                fullWidth={true}
                                onClick={on}
                            >
                                Завершить тест
                            </Button>
                            <div className={cx(
                                style.question_navigation_deadline_wrapper,
                                {
                                    [style.question_navigation_dangerous]: isDangerous
                                }
                            )}>
                                <IconUI typeIcon="clock" />
                                <p>
                                    {smUp && 'До конца теста: '}
                                    {
                                        test.data && <Counter
                                            passedTime={+test.data.passedTime}
                                            timeLimit={test.data.test.timeLimit}
                                            onSubmit={finishTest}
                                            onTick={onCounterTick}
                                        />
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <FinishTestModal
                    isOpen={isOpen}
                    closeModal={off}
                    openModal={on}
                    onSubmit={finishTest}
                />

            </div>
        </>
    );
};

export default NewTestPassing;
