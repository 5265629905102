import React, { FC, useState } from 'react';

import cx from 'classnames';

import Button from '@components/core/inputs/Button';
import { Menu, MenuItem } from '@components/Menu';

import { PaginationRows } from './PaginationRows/PaginationRows';
import style from './paginationrowsswitcher.module.scss';

interface IPaginationRowsSwitcherProps {
    currentRows?: number[];
    children?: number;
    rowsPerPage?: number;
    isAll?: boolean;
    onChange?: (rowsCount: number) => void;
    total: number;
}

export const PaginationRowsSwitcher: FC<IPaginationRowsSwitcherProps>= ({
    currentRows,
    rowsPerPage,
    isAll,
    onChange,
    total
}) => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const isOpen = Boolean(anchorEl);

    const close = () => {
        setAnchorEl(null);
    };

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isOpen) {
            close;
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const menuProps = {
        isOpen,
        className: style.menu,
        onClose: close,
        anchorEl
    };

    const paginationProps = {
        currentRows,
        rowsPerPage,
        isAll,
        isOpen
    };

    const onClickChangeRow = (row: number) => {
        onChange?.(row);
    };

    const renderMenus = () => currentRows?.map((row: number, index: number) => {
        return (<MenuItem className={cx(rowsPerPage === row && style.active)} onClick={() => onClickChangeRow(row)} key={`menuitem${index}`}>{row}</MenuItem>);
    });

    return (
        <Button btnType="clear" className={style.dropbtn} onClick={onClick}>
            <PaginationRows { ...paginationProps } />

            <Menu {...menuProps} placement="bottom-end">
                {renderMenus()}
                <MenuItem className={cx(rowsPerPage === total && style.active)} onClick={() => onClickChangeRow(-1)}>Все</MenuItem>
            </Menu>
        </Button>);
};
