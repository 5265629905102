
import React, { FC } from 'react';

import cx from 'classnames';

import { Grid } from '@components/core/grid';
import { IGridProps } from '@components/core/grid/type';
import { ICheckboxProps } from '@components/core/inputs/Checkbox/Checkbox';

import { DataListSelect } from '../DataListSelect';
import style from './DataListRow.module.scss';

export interface IDataListRowProps extends IGridProps {
    children?: React.ReactNode;
    isSelect?: boolean;
    selectProps?: ICheckboxProps;
}

export const DataListRow:FC<IDataListRowProps> = ({ children, isSelect, selectProps, ...props }) => {

    const columnsCount = React.Children.count(children);

    return <>
        <div className={cx(style.row, props.className)}>
            { isSelect && <DataListSelect {...selectProps}  />  }

            <Grid {...props} gridTemplateColumns={`repeat(${columnsCount}, 1fr)`}>
                {children}
            </Grid>
        </div>
    </>;
};