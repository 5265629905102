import React, { FC, useEffect } from 'react';

import IconUI from '@components/core/IconUI';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { useToggle } from '@hooks/useToggle.hook';
import { useDeletePortfolioSectionByIdMutation, useEditPortfolioSectionByIdMutation } from '@store/api/portfolioApi';
import { PortfolioSectionType } from '@type/portfolio';
import EditPortfolioSectionModal
    from '@views/methodist/AdditionalSettings/AdditionalSettingsLayout/PortfolioSettings/EditPortfolioSectionModal/EditPortfolioSectionModal';

import style from './PortfolioSection.module.scss';

interface IProps {
    section: PortfolioSectionType;
}

const PortfolioSection: FC<IProps> = ({
    section
}) => {
    const [isOpenedDeleteModal, { on: onDeleteModal, off: offDeleteModal }] = useToggle();
    const [isOpenedEditModal, { on: onEditModal, off: offEditModal }] = useToggle();
    const [fetchDeleteSection, { isSuccess: isSuccessDeleteSection }] = useDeletePortfolioSectionByIdMutation();
    const [fetchEditSection, { isSuccess: isSuccessEditSection }] = useEditPortfolioSectionByIdMutation();
    const onOpenDeleteModal = () => {
        onDeleteModal();
    };

    const onOpenEditModal = () => {
        onEditModal();
    };

    const onDeleteSection = () => {
        fetchDeleteSection({ uuid: section.uuid });
    };

    const onEditSection = (formData: any) => {
        fetchEditSection({ typeId: section.uuid, typeName: formData.typeName });
    };

    useEffect(() => {
        if (isSuccessDeleteSection) {
            offDeleteModal();
        }
        if (isSuccessEditSection) {
            offDeleteModal();
        }
    }, [isSuccessDeleteSection, isSuccessEditSection]);

    return (
        <React.Fragment>
            <div className={style.root}>
                <div className={style.root__name_wrapper}>
                    <IconUI typeIcon={'taskTst'} />
                    <p>{section.typeName}</p>
                </div>

                <div className={style.root__list_btn}>
                    <ButtonIcon
                        typeIcon={'edit'}
                        onClick={onOpenEditModal}
                    />
                    {!section.nonRemovable && (
                        <ButtonIcon
                            typeIcon={'basket'}
                            onClick={onOpenDeleteModal}
                        />
                    )}
                </div>
            </div>
            {isOpenedEditModal && (
                <EditPortfolioSectionModal
                    title="Редактировать раздел"
                    opened={isOpenedEditModal}
                    onOpen={onOpenEditModal}
                    onClose={offEditModal}
                    data={section}
                    submitFormData={onEditSection}
                />
            )}
            {isOpenedDeleteModal && (
                <DeleteItemModal
                    isOpen={isOpenedDeleteModal}
                    onClose={offDeleteModal}
                    onDelete={onDeleteSection}
                    itemName={section.typeName}
                />
            )}

        </React.Fragment>
    );
};

export default PortfolioSection;
