import { EnumTestAnswer, passTestRequest, StudentTestQuestionAnswerCombined } from '@store/api/apiTypes';

export const questionAnswerHeaderByType = (type: EnumTestAnswer) => {
    switch (type) {
        case EnumTestAnswer.SINGLE:
            return 'Выберите один ответ:';

        case EnumTestAnswer.MULTIPLY:
            return 'Выберите один или несколько ответов:';

        case EnumTestAnswer.TO_MATCH:
            return 'Сопоставьте вариаты ответа';

        default:
            return '';
    }
};

export const isChecked = (questionSnapshot: passTestRequest, answerUuid: string): boolean => {
    return !!questionSnapshot?.uuidsAnswers?.includes(answerUuid);
};

// Шафл ответов, для селекта
export const getToMathAnswersOptions = (questions: StudentTestQuestionAnswerCombined[]) => {
    const questionsToShuffle = [...questions];
    questionsToShuffle.sort(()=>Math.random()-0.5);
    return questionsToShuffle.map(question => ({ label: question.answerToMatch.subAnswer, value: question.answerToMatch.subAnswer }));
};

