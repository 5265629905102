import React, { forwardRef, ForwardRefRenderFunction, useMemo, useEffect } from 'react';

import SelectSearch from '@components/core/inputs/Select/SelectSearch';
import { useSearch } from '@hooks/useSearch';
import { useToggle } from '@hooks/useToggle.hook';

import { TreeSelectProvider } from './context/provider';
import { ButtonsControl } from './parts/ButtonsControl';
import { OptionsList } from './parts/OptionsList';
import { SelectRoot } from './parts/SelectRoot';
import { filterOptions } from './utils/filterOptions';

export interface TreeSelectOption {
    value: string;
    label: string;
    children: TreeSelectOption[]
}

export interface RenderValue {
    value: string;
    label: string;
}

export interface Classes {
    input?: string;
    inputIcon?: string;
}

interface NewTreeSelectBaseProps {
    error?: string;
    className?: string;
    classes?: Classes;
    placeholder?: string;
    renderLabel?: (value: RenderValue | RenderValue[]) => React.ReactNode;
    options: TreeSelectOption[];
    isMulti?: boolean;
    selectableParent?: boolean;
    disabled?: boolean;
}

interface SingleTreeSelectProps extends NewTreeSelectBaseProps {
    isMulti?: false;
    value: string;
    onChange: (value: string) => void;
}

interface MultiTreeSelectProps extends NewTreeSelectBaseProps {
    isMulti: true;
    value: string[];
    onChange: (value: string[]) => void;
}

export type NewTreeSelectProps = SingleTreeSelectProps | MultiTreeSelectProps;

const NewTreeSelect: ForwardRefRenderFunction<HTMLDivElement, NewTreeSelectProps> = (
    {
        error,
        className,
        classes,
        placeholder,
        renderLabel,
        disabled,
        ...props
    },
    ref
) => {
    const [isOpen, { on, off }] = useToggle();

    const { search, clear, ...searchInputProps } = useSearch();

    const filteredOptions = useMemo(() => filterOptions(props.options, search), [props.options, search]);

    useEffect(() => {
        if (!isOpen) {
            clear();
        }
    }, [isOpen]);

    return (
        <TreeSelectProvider {...props} isOpen={isOpen} on={on} off={off}>
            <div className={className} ref={ref}>
                <SelectRoot
                    error={error}
                    placeholder={placeholder}
                    renderLabel={renderLabel}
                    classes={classes}
                    disabled={disabled}
                >
                    <SelectSearch {...searchInputProps} clearInput={clear} />
                    <OptionsList options={filteredOptions} search={search} />
                    {props.isMulti && <ButtonsControl />}
                </SelectRoot>
            </div>
        </TreeSelectProvider>
    );
};

export default forwardRef(NewTreeSelect);
