import React, { FC } from 'react';

import cx from 'classnames';

import { capitalizeFirstLetter } from '@helpers/text';
import { EnumMaterialsType } from '@store/api/apiTypes';
import { MaterialsItem as IMaterialsItem } from '@store/api/apiTypes';
import Materials from '@views/student/StudentCourse/Materials';

import style from './MaterialsItem.module.scss';


export interface IMaterialsItemProps {
    material: IMaterialsItem;
    nextAdditional?: boolean;
    type?: 'course' | 'programmStudy'
}

const MaterialsItem: FC<IMaterialsItemProps> = ({ material, nextAdditional, type = 'course' }) => {
    const renderItem = () => {
        if (type === 'programmStudy') {
            const materialCapitalize = {
                ...material,
                type: capitalizeFirstLetter(material.type)
            };
            // @ts-ignore:next-line
            return <Materials.AdditionalMaterial material={materialCapitalize} />;
        }

        switch (material.type) {
            case EnumMaterialsType.Lecture:
                return <Materials.Lecture lecture={material} />;
            case EnumMaterialsType.Video:
                return <Materials.VideoLectures videosSection={material} />;
            case EnumMaterialsType.File:
            case EnumMaterialsType.Link:
            case EnumMaterialsType.Page:
                return <Materials.AdditionalMaterial material={material} />;
            case EnumMaterialsType.Task:
                return <Materials.Task task={material} />;
            case EnumMaterialsType.Test:
                return <Materials.Test test={material} />;
        }
    };

    // ToDo хотфикс на скрытие тестов и кр, пока оставлю
    // if (material.type === EnumMaterialsType.Task || material.type === EnumMaterialsType.Test) return null;

    return (
        <div className={cx(
            style.root,
            {
                [style['root--video']]: material.type === EnumMaterialsType.Video,
                [style['no-adaptive-border']]: !nextAdditional
            }
        )}>
            {renderItem()}
        </div>
    );
};

export default MaterialsItem;
