import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationAttempt, AuthenticationPayload } from '@store/api/apiTypes';

import type { RootState } from '@store/store';

interface IAuthenticationState {
    authenticationElements: AuthenticationPayload[];
    attempts: AuthenticationAttempt[];
}

const authenticationElements = localStorage.getItem('authentication');

const initialState: IAuthenticationState = {
    authenticationElements: authenticationElements ? JSON.parse(authenticationElements) : [],
    attempts: []
};

export const authenticationSlice = createSlice({
    initialState,
    name: 'authenticationSlice',
    reducers: {
        addAuthenticationUuid: (state, action: PayloadAction<AuthenticationPayload>) => {
            state.authenticationElements = [...state.authenticationElements, action.payload];
            localStorage.setItem('authentication', `${JSON.stringify(state.authenticationElements)}`);
        },
        removeAuthenticationUuid: (state, action: PayloadAction<string>) => {
            state.authenticationElements = state.authenticationElements.filter(el => el.uuidAuthentication !== action.payload);
            localStorage.setItem('authentication', `${JSON.stringify(state.authenticationElements)}`);
        },
        addAuthenticationAttempt: (state, action: PayloadAction<AuthenticationAttempt>) => {
            state.attempts = [...state.attempts, action.payload];
        },
        removeAuthenticationAttempt: (state, action: PayloadAction<string>) => {
            state.attempts = state.attempts.filter(el => el.uuidTest !== action.payload);
        }
    }
});

export default authenticationSlice.reducer;

export const {
    addAuthenticationUuid,
    removeAuthenticationUuid,
    addAuthenticationAttempt,
    removeAuthenticationAttempt
} = authenticationSlice.actions;

export const selectAuthenticationData = (state: RootState) => state.authenticationSlice.authenticationElements;
export const selectAuthenticationAttempt = (state: RootState) => state.authenticationSlice.attempts;
