import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import DNDGrid from '@components/core/DNDGrid';
import Button from '@components/core/inputs/Button';
import { LinkUI } from '@components/core/LinkUI';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { VideoLectureResponce } from '@store/api/apiTypes';
import { useUpdatePositionsMaterialsListMutation } from '@store/api/materialsApi';
import { useGetVideoBlockByIdQuery } from '@store/api/videosApi';
import { Role } from '@store/Roles';
import AddVideoModal from '@views/methodist/Materials/Course/VideoLectures/AddVideoModal';
import VideoCard from '@views/methodist/Materials/Course/VideoLectures/VideoCard';


import style from './video_lectures.module.scss';
import { TVideoProps } from './VideoCard/VideoCard';


const VideoLectures = () => {
    const navigate = useNavigate();
    const { uuidVideoBlock } = useParams();

    const { data: videoBlock, isFetching: isVideosFetching, isError } = useGetVideoBlockByIdQuery(`${uuidVideoBlock}`);
    const [fetchUpdatePositions] = useUpdatePositionsMaterialsListMutation();

    const [stateVideoModal, setStateVideoModal] = useState<TVideoProps>({
        isOpen: false,
        actionType: 'add',
        videoId: ''
    });

    const setStateVideoModalHandler = (
        { isOpen, defaultValues, actionType, videoId }: TVideoProps) => {
        setStateVideoModal((prevState) => ({
            ...prevState,
            isOpen,
            defaultValues,
            actionType,
            videoId
        }));
    };

    useEffect(() => {
        if (isError) {
            navigate('./../../..');
        }

    }, [isError]);

    const updatePositionsVideoListHandler = (reorderData: any[]) => {
        const transformVideoData = reorderData.map((item, index) => ({ uuid: item.id, position: index + 1 }));
        fetchUpdatePositions({ positions: transformVideoData });
    };

    if (isVideosFetching) return <NewLoader />;

    return (
        <Main>
            <div className={style.title_wrapper}>
                <LinkUI
                    to="./../../.."
                    typeIcon="arrowLink"
                />
                <h1>Структура курса</h1>
            </div>
            {
                videoBlock?.videoLectures.length ? (
                    <>
                        <RoleCheck roles={Role.Methodist}>
                            <Button
                                typeIcon="add"
                                onClick={() => setStateVideoModalHandler({ isOpen: true })}
                            >
                                Новое видео
                            </Button>
                        </RoleCheck>
                        <div className={style.video_lectures_wrapper}>
                            {checkRole(Role.Methodist) ? (
                                <DNDGrid
                                    items={videoBlock.videoLectures.map(item => ({ ...item, id: item.uuid }))}
                                    className={style.video_lectures}
                                    onOrder={updatePositionsVideoListHandler}
                                >
                                    {
                                        (item) => <VideoCard video={item as VideoLectureResponce} setStateVideoModal={setStateVideoModal} />
                                    }
                                </DNDGrid>
                            ) : (
                                <div className={style.video_lectures}>
                                    {videoBlock.videoLectures.map(videoLecture => (
                                        <VideoCard key={videoLecture.uuid} video={videoLecture} setStateVideoModal={setStateVideoModal} disabled />
                                    ))}
                                </div>
                            )}

                        </div>
                    </>

                ) : (
                    <div className={style.create_section}>
                        <div className={style.background_img} />
                        <h3 className={style.create_section__title}>Нет видеолекций</h3>
                        <p className={style.create_section__text}>Здесь будут храниться все загруженные вами видеолекции</p>
                        <Button
                            typeIcon="add"
                            onClick={() => setStateVideoModalHandler({ isOpen: true })}
                        >Новое видео</Button>
                    </div>
                )
            }
            {
                stateVideoModal.isOpen && (
                    <AddVideoModal
                        stateVideoModal={stateVideoModal}
                        setStateVideoModal={setStateVideoModalHandler}
                    />
                )
            }
        </Main>
    );
};

export default VideoLectures;
