import React, { FC, useState } from 'react';

import cx from 'classnames';
import { getMonth, getYear } from 'date-fns';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-acc.svg';
import IconUI from '@components/core/IconUI';

import { months } from '../helper';
import style from './DPNavbar.module.scss';


export interface IDPNavbarProps {
    next?: () => void;
    prev?: () => void;
    mode?: 'picker' | 'month';
    date?: Date | number;
    onClickBar?: () => void;
    isOpen?: boolean;
    viewing: Date | number;
    disabledNext?: boolean;
    disabledPrev?: boolean;
}


export const DPNavbar: FC<IDPNavbarProps> = ({
    next,
    prev,
    onClickBar,
    viewing,
    isOpen ,
    disabledNext,
    disabledPrev
}) => {

    return (<div className={style.controls}>
        <div className={style.controlMonth} onClick={onClickBar} role="presentation">
            <div className={style.caption}>
                { months[getMonth(viewing)].name }{' '}
                { getYear(viewing) }
            </div>

            <div className={cx(style.arrow, isOpen && style.arrowUp)}>
                <ArrowIcon />
            </div>
        </div>

        <div className={style.controlNav}>
            <div
                className={cx(style.nextNav, disabledPrev && style.disabled)}
                onClick={!disabledPrev ? prev : undefined}
                role="presentation"
            >
                <IconUI typeIcon='prev' />
            </div>
            <div
                className={cx(style.prevNav, disabledNext && style.disabled)}
                onClick={!disabledNext ? next : undefined}
                role="presentation"
            >
                <IconUI typeIcon='next' />
            </div>
        </div>
    </div>);
};
