import { FC, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import Modal from '@components/Modal';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useThrottle from '@hooks/useThrottle';
import { PortfolioSectionType } from '@type/portfolio';

import style from './EditPortfolioSectionModal.module.scss';

const Schema = yup.object({
    typeName: yup.string().required('')
});

export type AddSectionForm = yup.InferType<typeof Schema>;

interface IProps {
    title: string;
    submitFormData: (data: AddSectionForm) => void;
    opened: boolean;
    onOpen: () => void;
    onClose: () => void;
    data: PortfolioSectionType;
}

const EditPortfolioSectionModal: FC<IProps> = ({
    title,
    opened,
    onClose,
    submitFormData,
    data
}) => {
    const methods = useForm<AddSectionForm>({
        resolver: yupResolver(Schema),
        mode: 'onBlur'
    });
    const { handleSubmit, setValue } = methods;

    const onSubmit = (formData: AddSectionForm) => {
        submitFormData(formData);
        onClose();
    };

    const throttledSubmit = useThrottle(onSubmit, 1000);

    useEffect(() => {
        setValue('typeName', data.typeName);
    }, [data]);

    return (
        <Modal
            onClose={onClose}
            isOpen={opened}
            title={title}
        >
            <FormProvider {...methods}>
                <form>
                    <InputControl
                        classNameWrapper={style.input}
                        label="Название раздела"
                        name="typeName"
                        placeholder="Укажите название раздела"
                    />
                    <div className={style.button_wrapper}>
                        <Button
                            onClick={onClose}
                            btnType="secondary"
                        >
                            Отмена
                        </Button>
                        <Button onClick={handleSubmit(throttledSubmit)}>Сохранить</Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default EditPortfolioSectionModal;
