import { FC } from 'react';

import Collapse from 'rc-collapse';

import Loader from '@components/core/Loader';
import { ICategory } from '@store/api/apiTypes';
import { useGetDisciplinesByCategoryQuery } from '@store/api/disciplinesApi';


import { SubscribeItem } from '../SubscribeItem';

export interface ISubscribePanelProps {
    item: ICategory;
    level: number;
}

export const SubscribePanel: FC<ISubscribePanelProps> = ({ item, level = 1, ...props }) => {

    const disciplinesData = useGetDisciplinesByCategoryQuery(item?.uuid);

    const disciplinesList = disciplinesData.isSuccess ?
        disciplinesData.data?.map((discip, i) => {
            return <SubscribeItem item={discip} key={`disciplines-${i}`} />;
        }) : <Loader />;

    return (
        <>
            {
                (level > 1 && item.children.length == 0) ? 
                    disciplinesList :
                    <Collapse.Panel header={` ${item?.name}`} {...props}>
                        {  
                            disciplinesList
                        }
                        {
                            item.children.length ? (
                                <Collapse> 
                                    { 
                                        item.children.map((child, index) => {
                                            return (
                                                <Collapse.Panel header={child.name} key={`panel-childrens-${index}`}>
                                                    <SubscribePanel item={child} level={level + 1} {...props} />
                                                </Collapse.Panel>
                                            );
                                        }) 
                                    }
                                </Collapse>)
                                : null
                        }
                    </Collapse.Panel>
            }
        </>
    );

};