import { toast } from 'react-toastify';

import { CreateFileData, CreateFileDataResponse, GetFileDataResponse, UpdateFileData, UpdateFileDataResponse, Uuid } from '@store/api/apiTypes';

import { emptyBaseApi } from './emptyBaseApi';

export const fileApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getFileById: build.query<GetFileDataResponse, Uuid>({
            query: (uuidFile: string) => `disciplines/materials/file/find/${uuidFile}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить файл');
                }
            },
            transformResponse: (result: GetFileDataResponse) => {
                return result;
            }
        }),
        createFile: build.mutation<CreateFileDataResponse, CreateFileData>({
            query(data) {
                return {
                    url: 'disciplines/materials/file/create',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    pending: 'Прикрепляем файл',
                    success: 'Файл прикреплен',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => ['SectionList', { type: 'Material', id: `List_${arg.uuidParent}` }]
        }),
        updateFile: build.mutation<UpdateFileDataResponse, UpdateFileData>({
            query(data) {
                return {
                    url: `disciplines/materials/file/update/${data.uuid}`,
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    pending: 'Обновляем файл',
                    success: 'Файл обновлен',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['SectionList']
        })
    })
});

export const { useGetFileByIdQuery, useCreateFileMutation, useUpdateFileMutation } = fileApi;
