import { FC, useEffect } from 'react';

import './style.scss';
import classNames from 'classnames';

import { ReactComponent as MessageIcon } from '@assets/icons/message-circle.svg';
import { Chat } from '@components/Chat';
import { useChat } from '@components/Chat/context';
import IconButton from '@components/core/styledWrappers/IconButton';
import Modal from '@components/Modal';
import { joinRoomsIds } from '@helpers/chat';
import { selectRoomsWithNewMessage } from '@store/features/chatSlice';
import { useAppSelector } from '@store/hooks';

interface IProps {
    className?: string;
}



const UserMessageButton: FC<IProps> = ({ className }) => {
    const { isOpen, onOpen, onClose, roomsWithNewMessages } = useChat();

    // массив комнат с новыми сообщениями в реальном времени
    const storeRoomsWithNewMessages = useAppSelector(selectRoomsWithNewMessage);

    const joinedRoomsWithNewMessages = joinRoomsIds(storeRoomsWithNewMessages, roomsWithNewMessages);

    useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            if (isOpen) {
                body.style.overflow = 'hidden';
            } else {
                body.style.removeProperty('overflow');
            }
        }

    }, [isOpen]);

    return (
        <div className={classNames('user-message', className)}>
            <IconButton className={'user-message__button'} onClick={onOpen}>
                <MessageIcon className={'user-message__icon'} />
                {joinedRoomsWithNewMessages.length > 0 && (
                    <span className="user-message__counter">
                        {joinedRoomsWithNewMessages.length}
                    </span>
                )}
            </IconButton>
            <Modal onClose={onClose} isOpen={isOpen} className="user-message__modal" contentClassName={'user-message__modal-wrapper'} closeOnBackdrop>
                <Chat />
            </Modal>
        </div>
    );
};

export default UserMessageButton;
