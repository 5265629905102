import React from 'react';

import { Column, Grid } from '@components/core/grid';
import { DataList, DataListCell } from '@components/DataList';
import { Column as IColumn } from '@components/ScoreCard/ScoreCard';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { DisciplineScoreItem } from '@type/scoreCard';

import style from './ScorecardList.module.scss';

interface ScorecardListProps {
    data: DisciplineScoreItem[];
    columns: IColumn[];
    isStudent?: boolean
}

export const ScorecardList: React.FC<ScorecardListProps> = ({ data, columns, isStudent }) => {
    const smDown = useBreakpoint('sm', 'down');

    return (
        <DataList className={style['list-container']}>
            <Grid gridTemplateColumns={columns.map(({ width }) => width).join(' ')} className={style.header}>
                {columns.map(({ header }, index) => (
                    <Column key={index.toString()}>
                        <DataListCell className={style.cell}>{header}</DataListCell>
                    </Column>
                ))}
            </Grid>

            {data.map((row) => (
                <Grid
                    key={row.default.disciplineUUID}
                    gridTemplateColumns={!(isStudent && smDown) ? columns.map(({ width }) => width).join(' ') : '1fr'}
                    className={style.row}>
                    {!(isStudent && smDown) ? columns.map(({ cell }, index) => (
                        <Column key={index.toString()}>
                            <DataListCell className={style.cell}>
                                {cell(row)}
                            </DataListCell>
                        </Column>
                    )) : (
                        <Column>
                            <DataListCell className={style.cell}>
                                <span className={style.bold}>
                                    {columns[0].cell(row)}
                                </span>

                                <div className={style['row-footer']}>
                                    {columns[1].cell(row)}
                                    {columns[2].cell(row)}
                                </div>
                            </DataListCell>
                        </Column>
                    )}
                </Grid>
            ))}

        </DataList>
    );
};
