import { useEffect, useState } from 'react';

import { Navigate, NavLink, Outlet, useOutlet, useParams } from 'react-router-dom';

import { Breadcrumbs } from '@components/Breadcrumbs';
import Main from '@components/Main';
import { getPaths } from '@helpers/findNestedPath';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ICategory } from '@store/api/apiTypes';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';

import style from './course.module.scss';

const Course = () => {
    const outlet = useOutlet();
    const { 
        uuidCategory, 
        uuidDiscipline, 
        uuidSection,
        uuidVideoBlock,
        uuidLectureBlock
    } = useParams();

    const [pathToCat, setPathToCat] = useState<{uuid: string, name: string}[]>([]);

    const categoriesData = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000');

    useEffect(() => {
        if (categoriesData.isSuccess) {
            const pathToCategory = getPaths<ICategory, 'children', 'uuid'>(categoriesData.data, 'children', 'uuid',(cat) => cat.uuid === uuidCategory);

            setPathToCat(pathToCategory ?? []);
        }
    }, [categoriesData.isLoading, categoriesData.isSuccess]);
    

    const { disciplineName, disciplineUuid } = useGetDisciplineByIdQuery(uuidDiscipline ? uuidDiscipline : skipToken, {
        selectFromResult: ({ data: disciplineData }) => ({
            disciplineName: disciplineData?.name,
            disciplineUuid: disciplineData?.uuid
        })
    });

    return (
        <div className={style.root}>
            {
                pathToCat.length ? 
                    <div className={style.breadcrumps}>
                        <Breadcrumbs>
                            {
                                pathToCat.map(cat => <NavLink key={cat.uuid} to={`/materials/${cat.uuid}`}>
                                    {cat.name}
                                </NavLink>)
                            }
                            {
                                disciplineUuid ? <NavLink to={`/materials/${pathToCat[pathToCat.length - 1].uuid}/course/${disciplineUuid}/structure`}>
                                    {disciplineName}
                                </NavLink> : null
                            }
                            {
                                (uuidSection && uuidLectureBlock) ? 
                                    <NavLink to={`/materials/${pathToCat[pathToCat.length - 1].uuid}/course/${disciplineUuid}/structure/${uuidSection}/lectures-block/${uuidLectureBlock}`}>
                                        Блок лекций
                                    </NavLink> : null
                            }
                            {
                                (uuidSection && uuidVideoBlock) ? 
                                    <NavLink to={`/materials/${pathToCat[pathToCat.length - 1].uuid}/course/${disciplineUuid}/structure/${uuidSection}/video-lectures/${uuidVideoBlock}`}>
                                        Блок видеолекций
                                    </NavLink> : null
                            }
                        </Breadcrumbs>
                    </div> 
                    : null
            }
            
            
            {
                outlet ? <Outlet /> : <Navigate to={'create-course'} />
            }
        </div>

    );
};

export default Course;
