import { FC, useEffect } from 'react';

import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { ReactComponent as CheckValueIcon } from '@assets/icons/check-value-icon.svg';
import { ReactComponent as ConfirmErrorIcon } from '@assets/icons/confirm-error-icon.svg';
import Button from '@components/core/inputs/Button';
import InputAdornment from '@components/core/inputs/InputBase/InputAdornment';
import InputControl from '@components/core/inputs/InputControl';
import { yupResolver } from '@hookform/resolvers/yup';
import { useChangePasswordMutation } from '@store/api/authApi';

import style from './reset_step.module.scss';

const resetSchema = yup.object({
    password: yup
        .string()
        .required('Введите текст')
        .min(8, 'Пароль должен содержать не менее 8 символов'),
    confirmPassword: yup
        .string()
        .required('Введите текст')
        .oneOf([yup.ref('password'), null], 'Введенные пароли не совпадают')
});

export type ResetInput = yup.InferType<typeof resetSchema>;

export const ResetPage: FC = () => {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token');

    const [changePassword, result] = useChangePasswordMutation();
    
    const methods = useForm<ResetInput>({
        resolver: yupResolver(resetSchema),
        mode: 'onBlur'
    });

    const { handleSubmit, control, watch, clearErrors, setError, getValues } = methods;

    const { errors } = useFormState({
        control
    });

    const onSubmit: SubmitHandler<ResetInput> = async (data) => {

        changePassword({
            token: token as string,
            newPassword: data.password
        });
    };

    useEffect(() => {
        if (!token) navigate('/login', { replace: true });
    }, []);
    

    useEffect(() => {
        if (result.isSuccess) {
            navigate('/login', { replace: true });
        }
    }, [result.isLoading, result.isSuccess, result.isError]);
    

    const watchConfirm = watch('confirmPassword');
    const watchPassword = watch('password');

    useEffect(() => {
        if (watchConfirm !== watchPassword && getValues('confirmPassword')) {
            setError('confirmPassword', {
                type: 'custom',
                message: 'Введенные пароли не совпадают'
            });
        } else {
            clearErrors('confirmPassword');
        }
    }, [clearErrors, getValues, setError, watchConfirm, watchPassword]);

    return (
        <>
            <h1 className={style.title}>Придумайте новый пароль</h1>
            <p className={style.text}>
                Пароль должен содержать не менее 8 символов
            </p>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className={style.input_layout}>
                        <InputControl
                            name="password"
                            type="password"
                            placeholder="Введите новый пароль"
                            endAdornment={
                                errors.password ? (
                                    <InputAdornment position="end">
                                        <ConfirmErrorIcon />
                                    </InputAdornment>
                                ) : getValues('password')?.length > 7 ? (
                                    <InputAdornment position="end">
                                        <CheckValueIcon />
                                    </InputAdornment>
                                ) : undefined
                            }
                        />
                        <InputControl
                            name="confirmPassword"
                            type="password"
                            placeholder="Повторите новый пароль"
                            endAdornment={
                                errors.confirmPassword ? (
                                    <InputAdornment position="end">
                                        <ConfirmErrorIcon />
                                    </InputAdornment>
                                ) : getValues('confirmPassword')?.length > 0 &&
                                getValues('confirmPassword') === getValues('password') ? (
                                        <InputAdornment position="end">
                                            <CheckValueIcon />
                                        </InputAdornment>
                                    ) : undefined
                            }
                        />
                    </div>
                    <Button
                        type={'submit'}
                        fullWidth={true}
                        className={style.button_submit}
                    >
                        Сохранить
                    </Button>
                </form>
            </FormProvider>

        </>
    );
};
