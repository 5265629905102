import React, { FC } from 'react';

import cx from 'classnames';

import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import { useBreakpoint } from '@hooks/useBreakpoint';

import style from './AuthPesonaSetupCamera.module.scss';

export interface IAuthPersonaCameraErrorProps {
    onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    className?: string;
}

export const AuthPersonaCameraError: FC<IAuthPersonaCameraErrorProps> = ({ onClick, className }) => {
    const lgUp = useBreakpoint('lg', 'up');

    return <div className={cx(style.box, className)}>
        { lgUp || <h3 className={style.title}>Сделайте селфи с&nbsp;паспортом</h3> }

        <div className={style.header}>
            <div className={style.icon}>
                <IconUI typeIcon="attention-error" />
            </div>
            <div className={style.errorTitle}>
                {lgUp ? 'Проверить веб-камеру не удалось!' : 'Отсутствует доступ к камере устройства!'}
            </div>
        </div>

        <div className={style.reasons}>
            {
                lgUp ?
                    <>
                        <strong className={style.listTitle}>Возможные причины:</strong>
                        <ul className={style.list}>
                            <li>Камера не подключена;</li>
                            <li>У браузера нет разрешения на доступ к камере;</li>
                            <li>Камеру используют другие приложения;</li>
                            <li>В настройках компьютера выбрана другая камера.</li>
                        </ul>

                        <p>
                            Если вы все проверили, а ошибка остается, попробуйте перезагрузить компьютер.
                        </p>
                    </>
                    :
                    <p>
                        Для предоставления доступа перейдите в настройки устройства.
                    </p>
            }

        </div>

        <div className={style.action}>
            <Button btnType="outline" fullWidth={true} onClick={onClick} >Попробовать снова</Button>
        </div>
    </div>;
};
