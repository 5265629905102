import { FC } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import Modal from '@components/Modal';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useThrottle from '@hooks/useThrottle';

import style from './AddPortfolioSectionModal.module.scss';

const Schema = yup.object({
    typeName: yup.string().required('Укажите название раздела')
});

export type AddSectionForm = yup.InferType<typeof Schema>;

interface IProps {
    title: string;
    submitFormData: (formData: AddSectionForm) => void;
    opened: boolean;
    onOpen: () => void;
    onClose: () => void;
}

const AddPortfolioSectionModal: FC<IProps> = ({
    title,
    opened,
    onClose,
    submitFormData
}) => {
    const methods = useForm<AddSectionForm>({
        resolver: yupResolver(Schema),
        mode: 'onSubmit',
        defaultValues: {
            typeName: ''
        }
    });
    const { handleSubmit } = methods;

    const onSubmit = (data: AddSectionForm) => {
        submitFormData(data);
        onClose();
    };

    const throttledSubmit = useThrottle(onSubmit, 1000);

    return (
        <Modal
            onClose={onClose}
            isOpen={opened}
            title={title}
        >
            <FormProvider {...methods}>
                <form>
                    <InputControl
                        classNameWrapper={style.input}
                        label="Название раздела"
                        name="typeName"
                        placeholder="Укажите название раздела"
                    />
                    <div className={style.button_wrapper}>
                        <Button
                            onClick={onClose}
                            btnType="secondary"
                        >
                            Отмена
                        </Button>
                        <Button onClick={handleSubmit(throttledSubmit)}>Сохранить</Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default AddPortfolioSectionModal;
