import React, { FC } from 'react';

import { useChat } from '@components/Chat/context';
import Button from '@components/core/inputs/Button';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetUserByUuidQuery } from '@store/api/usersApi';
import { ENV } from '@type/common';

import style from './ContactCard.module.scss';

const currentEnv = process.env.REACT_APP_CURRENT_ENV;

export interface IContactCardsProps {
    title: string,
    description: string,
    phones?: string[],
    emails?: string[],
    avatarLink?: string,
    uuid?: string
}

export const ContactCard: FC<IContactCardsProps> = ({
    title,
    description,
    phones,
    emails,
    avatarLink,
    uuid
}) => {
    const lgDown = useBreakpoint('lg', 'down');
    const { writeToUser } = useChat();

    const userUuid = currentEnv === ENV.test ? 'f7511ab2-9fe0-44ef-b46c-3bebf005e1f4' : uuid;
    const { data } = useGetUserByUuidQuery(userUuid ? userUuid : skipToken);

    const handleClickWrite = () => {
        // TODO надо закрывать модалку на адаптиве

        data && writeToUser(data);
    };

    const renderAvatar = () => {
        if (avatarLink) {
            return <img src={avatarLink} alt="Аватар" />;
        } else {
            const [firstname, lastname] = title.split(' ');
            return `${firstname?.charAt(0).toUpperCase()} ${lastname?.charAt(0).toUpperCase()}`;
        }
    };

    return <div className={style.container}>
        <div className={style.avatar}>
            {renderAvatar()}
        </div>

        <div className={style.content}>
            <div className={style.title}>
                {title}
            </div>

            <div className={style.description}>{description}</div>

            {
                phones?.length ? (
                    <div className={style.numberCaption}>
                        {
                            phones?.map(phone => {
                                return <div key={phone}>{phone}</div>;
                            })
                        }
                    </div>
                ) : null
            }

            {
                emails?.length ? (
                    <div className={style.emails}>
                        {
                            emails?.map(email => {
                                return <div key={email}><a target={'_blank'} href={`mailto:${email}`} rel="noreferrer">{email}</a></div>;
                            })
                        }
                    </div>
                ) : null
            }
            {
                ((currentEnv === ENV.prod) && uuid || currentEnv === ENV.test) && (
                    <div className={style.action}>
                        <Button
                            className={style['write-btn']}
                            onClick={handleClickWrite}
                            btnType={lgDown ? 'clear' : 'success-light'}
                        >
                            Написать в чат
                        </Button>
                    </div>
                )}
        </div>
    </div>;
};
