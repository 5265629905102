import { checkRole } from '@components/RoleCheck';
import { Role } from '@store/Roles';

export const preventBrowserDefaults = (e: React.DragEvent | React.ChangeEvent) => {
    e.preventDefault();
    e.stopPropagation();
};

export function getEvents<T>(methodistEvents: T, studentEvents: T, teacherEvents: T): T {
    const isStudent = checkRole(Role.Student);
    const isTeacher = checkRole(Role.Teacher);

    if (isStudent) return studentEvents;
    if (isTeacher) return teacherEvents;

    return methodistEvents;
}
