import { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import classNames from 'classnames/bind';

import IconUI from '@components/core/IconUI';
import { TypeIcon } from '@components/core/IconUI/type';

import style from './button_icon.module.scss';

const cx = classNames.bind(style);

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    classNameIcon?: string;
    children?: ReactNode;
    typeIcon?: TypeIcon;
    iconElement?: ReactNode;
}

const ButtonIcon: FC<IProps> = ({ className, classNameIcon, children, typeIcon, iconElement, ...props }) => {
    return (
        <button className={cx('root', className)} type="button" {...props}>
            {typeIcon && (
                <IconUI
                    typeIcon={typeIcon}
                    className={classNameIcon}
                    iconElement={iconElement}
                />
            )}
            {children}
        </button>
    );
};

export { ButtonIcon };
