import { FC } from 'react';

import InputBase from '@components/core/inputs/InputBase/InputBase';

import style from './SelectSearch.module.scss';


export interface ISearchBarProps {
    onChange?: (value: string) => void;
    value: string;
    clearInput?: () => void;
    onSearch?: (query: string) => void;
}

const SelectSearch: FC<ISearchBarProps> = ({ onChange, value, clearInput, onSearch }) => {

    const inputStyle = {
        height: 34
    };

    const onChangeW = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (!onChange) return;
        onChange(e.target.value);

        if (!onSearch) return;
        onSearch(e.target.value);
    };

    return <div className={style.container}>
        <InputBase
            variant="search"
            placeholder="Поиск"
            style={inputStyle}
            value={value}
            name="searchbar"
            onChange={onChangeW}
            clearInput={clearInput}
        />
    </div>;
};

export default SelectSearch;
