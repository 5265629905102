import { toast } from 'react-toastify';

import { CreateSectionData, Section, SectionsListData, UpdatePositionsSectionListData, UpdateSectionData } from '@store/api/apiTypes';

import { emptyBaseApi } from './emptyBaseApi';

export const sectionsApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getSectionsList: build.query<Section[], string>({
            query: (uuidDiscipline: string) => `disciplines/sections/list/${uuidDiscipline}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список секций');
                }
            },
            transformResponse: (result: SectionsListData) => {
                return [...result.sections];
            },
            providesTags: (result, error, arg) => result
                ? [...result.map(({ uuid }) => ({ type: 'SectionList' as const, id: uuid })), 'SectionList']
                : ['SectionList']
        }),
        getSectionById: build.query<Section, string>({
            query: (uuidSection: string) => `disciplines/sections/find/${uuidSection}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить секцию');
                }
            },
            transformResponse: (result: Section) => {
                return result;
            },
            providesTags: (result, error, arg) => result
                ? [{ type: 'SectionList' as const, id: result.uuid }, 'SectionList']
                : ['SectionList']
        }),
        createSection: build.mutation<Section, CreateSectionData>({
            query(data) {
                return {
                    url: 'disciplines/sections/create',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Раздел создан',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['SectionList']
        }),
        updateSection: build.mutation<Section, UpdateSectionData>({
            query(data) {
                return {
                    url: `disciplines/sections/update/${data.uuid}`,
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Раздел обновлен',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => result
                ? [{ type: 'SectionList' as const, id: result.uuid }, 'SectionList']
                : ['SectionList']
        }),
        updatePositionsSectionsList: build.mutation<void, UpdatePositionsSectionListData>({
            query(data) {
                return {
                    url: 'disciplines/sections/positions',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    //success: 'Позиция раздела сохранена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['SectionList']
        }),
        deleteSection: build.mutation<void, string>({
            query(uuidSection) {
                return {
                    url: 'disciplines/sections/delete',
                    method: 'POST',
                    body: { uuids: [uuidSection] }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Раздел удален',
                    error: 'Ошибка удаления!'
                });
            },
            invalidatesTags: (result, error, arg) => result
                ? [{ type: 'SectionList' as const, id: arg }, 'SectionList']
                : ['SectionList']
        })
    })
});

export const {
    useCreateSectionMutation,
    useGetSectionsListQuery,
    useUpdateSectionMutation,
    useUpdatePositionsSectionsListMutation,
    useDeleteSectionMutation,
    useGetSectionByIdQuery
} = sectionsApi;
