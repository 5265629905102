import { ChangeEventHandler, FC } from 'react';

import { Controller, ControllerRenderProps, FieldValues, UseControllerProps, useFormContext } from 'react-hook-form';

import InputBase, { BaseInputProps } from '@components/core/inputs/InputBase/InputBase';

type InputControlProps = {
    name?: string;
    rules?: UseControllerProps['rules'];
    defaultValue?: string | number;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    popoverAsError?: boolean;
} & BaseInputProps;

interface IRenderProps {
    field: ControllerRenderProps<FieldValues, string>;
}

export const InputControl: FC<InputControlProps> = ({ name, defaultValue, onChange, rules, error, ...props }) => {

    const {
        control,
        formState: { errors },
        setValue
    } = useFormContext();

    const InputCustom = ({ field }: IRenderProps) => {
        const localChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(event);
            onChange && onChange(event);
        };

        return (
            <InputBase
                {...field}
                setValue={setValue}
                onChange={localChange}
                onWheel={event => { (event.target as HTMLElement).blur(); }}
                error={name ? !!errors[name] : error}
                helperText={(name && errors[name]) ? errors[name].message : null}
                {...props}
            />
        );
    };

    return (
        <Controller
            name={name || ''}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={InputCustom}
        />
    );
};

export default InputControl;
