import { useEffect } from 'react';

import cx from 'classnames';
import { toast } from 'react-toastify';

import { Column, Grid } from '@components/core/grid';
import { MenuItem, TableMenu } from '@components/Menu';
import { useToggle } from '@hooks/useToggle.hook';
import { Preset, WithUUID } from '@store/api/apiTypes';
import { useDeletePresetMutation, useGetSkillDisciplinesQuery } from '@store/api/skillsApi';

import { DeletePresetModal } from '../../DeletePresetModal';
import UpdatePresetModal from '../../UpdatePresetModal';
import styles from './PresetTableRow.module.scss';

interface PresetTableRowProps {
    preset: WithUUID<Preset>
}

const PresetTableRow = ({ preset }: PresetTableRowProps) => {

    const [deletePreset, { isSuccess, isLoading }] = useDeletePresetMutation();

    const { data: getSkillDisciplinesData } = useGetSkillDisciplinesQuery(undefined);

    useEffect(()=>{
        if(isSuccess) toast.success('Пресет успешно удалён');
    },[isSuccess]);


    const [isUpdateOpen, { on: updateOn, off: updateOff }] = useToggle(false);
    const [isDeleteOpen, { on: deleteOn, off: deleteOff }] = useToggle(false);

    return (
        <>
            <Grid gridTemplateColumns="1fr 1fr" className={cx('row--selectable', styles.row)} key={preset.uuid} onClick={updateOn}>
                <Column>{preset.name}</Column>
                <Column>
                    {preset.uuidsDiscipline.length}
                    <TableMenu className={styles.menu} onClick={(e) => {e.stopPropagation();}}>
                        <MenuItem onClick={updateOn}>Редактировать</MenuItem>
                        <MenuItem>Дублировать</MenuItem>
                        <MenuItem danger onClick={deleteOn}>Удалить</MenuItem>
                    </TableMenu>
                </Column>
            </Grid>
            <DeletePresetModal isOpen={isDeleteOpen} onClose={deleteOff} isLoading={isLoading} onDelete={() => deletePreset(preset.uuid)} />
            <UpdatePresetModal isOpen={isUpdateOpen} closeModal={updateOff} preset={preset} skillDisciplines={getSkillDisciplinesData} />
        </>
    );
};

export default PresetTableRow;
