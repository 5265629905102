import { Outlet, useOutletContext, useParams } from 'react-router-dom';

import { NewLoader } from '@components/core/NewLoader';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { GroupTree, TestWithQuestions } from '@store/api/apiTypes';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useGetTestByIdQuery } from '@store/api/testsApi';

interface ITestContext {
    testContext: TestWithQuestions;
    groups: GroupTree;
    blockedGroups: string[];
}

export const useTestContext = () => {
    return useOutletContext<ITestContext>();
};


const EditTest = () => {
    const { uuidTest } = useParams();

    const getTestQuery = useGetTestByIdQuery(uuidTest ? uuidTest : skipToken);
    const groupsTree = useGetGroupsTreeQuery('');

    if (getTestQuery.isLoading && groupsTree.isLoading) return <NewLoader />;

    return (
        <>
            {
                getTestQuery.isSuccess ?
                    <Outlet context={{ testContext: getTestQuery.data, groups: groupsTree.data, blockedGroups: getTestQuery.data.test.accessRestricted }} />
                    : <NewLoader />
            }
        </>
    );
};

export default EditTest;
