import React from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '@components/core/inputs/Button';
import Main from '@components/Main';
import { RoleCheck } from '@components/RoleCheck';
import { Role } from '@store/Roles';
import { MaterialCategoryOption } from '@views/methodist/Materials/Course/CreateCourse/CreateCourseForm/type';
import { useCategory } from '@views/methodist/Materials/MaterialsPage/materialsPage';

import { DistiplinesList } from './components/DistiplinesList';
import style from './materialsList.module.scss';

interface IProps {
    className?: string;
}

export interface ISelectMaterialCategoryForm {
    select: MaterialCategoryOption;
}

const MaterialsList: React.FC<IProps> = () => {
    const { category, selectedMethodist } = useCategory();
    const navigate = useNavigate();

    return (
        <Main title="Учебные материалы">
            <h1 className={style.title}> {!selectedMethodist ? category?.name : 'Список дисциплин'} </h1>
            {!selectedMethodist && (
                <RoleCheck roles={Role.Methodist}>
                    <Button className={style.action} onClick={() => navigate('course/create-course')} typeIcon="add">
                        Новый курс
                    </Button>
                </RoleCheck>
            )}

            <DistiplinesList categoryUuid={category?.uuid} methodistUuid={selectedMethodist} />
        </Main>
    );
};

export default MaterialsList;
