import { isBefore } from 'date-fns';

import { TDeadline } from '@store/api/apiTypes';
import { IDeadlinesPresent } from '@type/deadlines';

export const areDeadlinesCorrect = (areDeadlinesPresent: IDeadlinesPresent , deadlinesToSet: TDeadline): boolean => {
    if (
        ((!areDeadlinesPresent.noStartDate && deadlinesToSet.deadlineStart) || areDeadlinesPresent.noStartDate) &&
        ((!areDeadlinesPresent.noEndDate && deadlinesToSet.deadlineEnd) || areDeadlinesPresent.noEndDate )
    ) {

        if (deadlinesToSet.deadlineStart && deadlinesToSet.deadlineEnd &&
            isBefore(new Date(deadlinesToSet.deadlineEnd), new Date(deadlinesToSet.deadlineStart))
        ) {
            return false;
        }

        return true;
    }
    return false;
};
