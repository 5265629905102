import React, { FC } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import { setTestCache } from '@store/features/passTestSlice';
import { useAppDispatch } from '@store/hooks';

import style from './AuthPersonaSuccess.module.scss';

interface AuthPersonaSuccessProps {
    type: 'task' | 'test';
}

export const AuthPersonaSuccess:FC<AuthPersonaSuccessProps> = ({ type }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { uuidDiscipline, uuidTest, uuidTask  } = useParams();

    const location = useLocation();

    const redirect = () => {

        if (uuidTest) {
            navigate('../start');
            dispatch(setTestCache(uuidDiscipline || ''));
        }
        else if (uuidTask) {
            const navigateState = ((location.state as any)?.file) ? { file: (location.state as any).file } : undefined;
            navigate('../', { state: navigateState });
        }
    };

    const getLabel = () => {
        switch (type) {
            case 'task':
                return 'Теперь вы можете отправить контрольную работу.';
            case 'test':
                return 'Теперь вы можете перейти к тестированию.';
        }
    };

    const getButtonLabel = () => {
        switch (type) {
            case 'task':
                return 'Отправить контрольную работу';
            case 'test':
                return 'Начать тестирование';
        }
    };

    return <div className={style.container}>
        <div className={style.header}>
            <div className={style.icon}><IconUI typeIcon="check-round" /></div>
            <div className={style.title}>Аутентификация личности успешно завершена</div>
        </div>

        <p className={style.description}>{getLabel()}</p>

        <div className={style.btn_wrapper}>
            <Button onClick={redirect} btnType={type === 'test' ? 'success' : 'outline'} >
                {getButtonLabel()}
            </Button>
        </div>
    </div>;
};
