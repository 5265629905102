import { Dispatch, FC, SetStateAction, useState } from 'react';

import Modal from '@components/Modal';
import { CreateLinkData, Materials } from '@store/api/apiTypes';
import CreateLinkForm from '@views/methodist/Materials/Course/CreateLinkForm/CreateLinkForm';
import { CreateLinkFormData } from '@views/methodist/Materials/Course/CreateLinkForm/type';

interface IAddLinkModal {
    uuidParent?: string
    uuidSection?: string
    isOpen: boolean
    onClose: Dispatch<SetStateAction<boolean>>
    getCreateLinkData?: (createLinkData: CreateLinkData) => void
    isMaterialsSuccess?: boolean
    materials: Materials
    createLink: (formData: CreateLinkData) => void
}

const AddLinkModal: FC<IAddLinkModal> = ({ 
    uuidParent, 
    uuidSection,
    onClose,
    isOpen,
    getCreateLinkData, 
    isMaterialsSuccess = true,
    materials, 
    createLink 
}) => {
    const [defaultValues] = useState<CreateLinkFormData>({
        name: '',
        link: ''
    });

    const createLinkHandler = (formData: CreateLinkFormData) => {
        const transformFormData: CreateLinkData = {
            uuidParent: uuidParent,
            uuidSection: uuidSection,
            name: formData.name,
            link: formData.link,
            position: isMaterialsSuccess ? materials.length + 1 : 0
        };

        if (getCreateLinkData) {
            getCreateLinkData(transformFormData);
            onClose(false);
        } else {
            createLink(transformFormData);
            onClose(false);
        }
    };

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title="Добавить ссылку"
        >
            <CreateLinkForm
                defaultValues={defaultValues}
                onSubmitFormHandler={createLinkHandler}
                onCloseModal={onClose}
            />
        </Modal>

    );
};

export default AddLinkModal;
