import React, { useState } from 'react';

import cx from 'classnames';

import { NewLoader } from '@components/core/NewLoader';
import { DetailsModal } from '@components/ScoreCard/DetailsModal';
import TTabs from '@components/Tabs';
import { getFractionByCondition } from '@helpers/grid';
import { allScoreDisciplines, getFilteredDisciplinesScore, getSelectedSession, transformScoresToTable } from '@helpers/scoreCard';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useToggle } from '@hooks/useToggle.hook';
import { skipToken } from '@reduxjs/toolkit/query';
import { SessionTypesWithLocale } from '@store/api/apiTypes';
import { useGetScoreCardQuery, useGetSubscribedAdditionalDisciplinesQuery } from '@store/api/groupsApi';
import { DisciplineScoreItem, ScoreStatusDiscipline } from '@type/scoreCard';
import { UserDisciplineCell } from '@views/methodist/Users/UserProfile/UserRecordBook/UserRecordPanelTable/UserDisciplineCell';
import { UserGradeCell } from '@views/methodist/Users/UserProfile/UserRecordBook/UserRecordPanelTable/UserGradeCell';
import { TUserRecord, UserRecordPanelTable } from '@views/methodist/Users/UserProfile/UserRecordBook/UserRecordPanelTable/UserRecordPanelTable';
import { FormControl } from '@views/student/RecordBook/FormControl';

import { ExportScorecard } from './ExportScorecard';
import style from './ScoreCard.module.scss';

interface ScoreCardProps {
    studentId?: string;
}

export interface Column {
    header: React.ReactNode;
    cell: (row: DisciplineScoreItem) => React.ReactNode;
    width: string;
}

export const ScoreCard: React.FC<ScoreCardProps> = ({ studentId }) => {
    const lgDown = useBreakpoint('lg', 'down');

    const { data, isFetching } = useGetScoreCardQuery(studentId ? { studentUUID: studentId } : undefined);
    const isStudent = !studentId;

    const subscribedStudentDisciplines = useGetSubscribedAdditionalDisciplinesQuery(studentId || skipToken);

    const [isOpenedDetails, { on, off }] = useToggle();
    const [detailsData, setDetailsData] = useState<DisciplineScoreItem | null>(null);

    const onOpenDiscipline = (discipline: DisciplineScoreItem) => {
        setDetailsData(discipline);
        on();
    };

    if (isFetching) {
        return <NewLoader />;
    }

    if (!data) return null;

    const getColumns = (): Column[] => {
        const columns: Column[] = [
            {
                header: 'Дисциплина',
                cell: (row) => (
                    <UserDisciplineCell
                        name={row.discipline}
                        isDebt={row.status === ScoreStatusDiscipline.debt}
                        isPending={row.default.hasNotEstimatedAttempts}
                        isPersonal={Boolean((subscribedStudentDisciplines?.data || []).find(el => el.disciplineUUID === row.default.disciplineUUID))}
                        onOpen={() => onOpenDiscipline(row)}
                    />
                ),
                width: getFractionByCondition([!isStudent, (isStudent && lgDown), (isStudent && !lgDown)], ['5fr', '4fr', '7fr'])
            },
            {
                header: isStudent && !lgDown ? <FormControl /> : 'Форма контроля',
                cell: (row) => row.formatControl,
                width: lgDown || isStudent ? '2fr' : '1fr'
            },
            {
                header: 'Оценка',
                cell: (row) => <UserGradeCell score={row.grade} closeType={row.default.closeType} />,
                width: lgDown ? '3fr' : '2fr'
            }
        ];

        if (!isStudent) {
            columns.push({
                header: 'Срок сдачи дисциплины',
                cell: (row) => <>{row.dateInterval}</>,
                width: '1.7fr'
            });
        }

        return columns;
    };

    const getTableTabs = (tab: string): TUserRecord[] => {
        const currentSessionData = tab === 'all' ? allScoreDisciplines(data) : getSelectedSession(data, tab);

        return [
            {
                tab: {
                    title: 'Все',
                    type: 'all'
                },
                items: transformScoresToTable(currentSessionData)
            },
            {
                tab: {
                    title: 'Активные',
                    type: 'active'
                },
                items: transformScoresToTable(getFilteredDisciplinesScore(currentSessionData, ScoreStatusDiscipline.active))
            },
            {
                tab: {
                    title: 'Задолженности',
                    type: 'borg'
                },
                items: transformScoresToTable(getFilteredDisciplinesScore(currentSessionData, ScoreStatusDiscipline.debt))
            },
            {
                tab: {
                    title: 'Завершенные',
                    type: 'completed'
                },
                items: transformScoresToTable(getFilteredDisciplinesScore(currentSessionData, ScoreStatusDiscipline.finished))
            }
        ];
    };

    const tabs = ['Все', ...(data?.map(({ session }) => SessionTypesWithLocale[session]) || [])];
    const panels = [
        <UserRecordPanelTable
            key="tab-panel-all"
            data={getTableTabs('all')}
            columns={getColumns()}
            isStudent={isStudent}
        />,
        ...(data?.map(({ session }) => (
            <UserRecordPanelTable
                key={`tab-panel-${session}`}
                data={getTableTabs(session)}
                columns={getColumns()}
                isStudent={isStudent}
            />
        )) || [])
    ];

    return (
        <>
            {isStudent && lgDown ? <FormControl className={style['form-control']} isStudent={isStudent} /> : null}

            <div className={style.container}>
                <TTabs
                    tabs={tabs}
                    panels={panels}
                    type="grey"
                    className={style.tabs}
                    containerClassName={cx(style.tabs__container, isStudent && style['tabs__container--student'])}
                />
                <ExportScorecard studentId={studentId} />
            </div>

            <DetailsModal opened={isOpenedDetails} onClose={off} data={detailsData} onOpen={on} />
        </>

    );
};
