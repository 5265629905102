import { Dispatch, FC, SetStateAction, useState, RefObject } from 'react';

import cx from 'classnames';
import { DateTime } from 'ts-luxon';

import Cell from '@components/core/Calendar/Cell';
import { Column, Grid } from '@components/core/grid';
import { Event } from '@store/api/apiTypes';
import { InfoEvent, SelectDayData, VisibleAllEvents } from '@type/eventCalendar';

import style from './CalendarContent.module.scss';

export interface ICalendarContentProps {
    daysMap: DateTime[];
    today: DateTime;
    isMethodist: boolean;
    infoEvent: InfoEvent;
    eventsRef: RefObject<HTMLDivElement>;
    selectDayData: SelectDayData | null;
    events: Event[];

    setSelectDayData: Dispatch<SetStateAction<SelectDayData | null>>;
    setInfoEvent: Dispatch<React.SetStateAction<InfoEvent>>;
}

export const CalendarContent:FC<ICalendarContentProps> = ({
    daysMap,
    today,
    isMethodist,
    infoEvent,
    events,
    eventsRef,
    selectDayData,
    setSelectDayData,
    setInfoEvent
}) => {

    const isDisableMonth = (value: DateTime) => !today.hasSame(value, 'month');
    const isWeekend = (value: DateTime) => value.weekday === 6 || value.weekday === 7;

    const [visibleAllEvents, setVisibleAllEvents] = useState<VisibleAllEvents>({
        isVisible: false,
        currentDayId: null
    });

    return <Grid className={style.grid}>
        {
            daysMap.map((dayItem) => {
                return (
                    <Column
                        className={cx(
                            style.column,
                            isDisableMonth(dayItem) && style.disable_month, 
                            isWeekend(dayItem) && style.weekend,
                            {
                                [style.student]: !isMethodist
                            }
                        )}
                        key={dayItem.toLocaleString()}
                    >
                        <Cell
                            dayItem={dayItem}
                            events={events}
                            visibleAllEvents={visibleAllEvents}
                            setVisibleAllEvents={setVisibleAllEvents}
                            infoEvent={infoEvent}
                            setInfoEvent={setInfoEvent}
                            setSelectDayData={setSelectDayData}
                            selectedDate={selectDayData?.dayItem}
                            eventsRef={eventsRef}
                            today={today}
                        />
                    </Column>
                );
            })
        }
    </Grid>;
};