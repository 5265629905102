import React from 'react';

import { useParams } from 'react-router-dom';

import { ScoreCard } from '@components/ScoreCard';

import style from './user_record_book.module.scss';

const UserProfileInfo = () => {
    const { userId } = useParams();

    return (
        <div className={style.container}>
            <h2 className={style.title}>Электронная зачетка</h2>
            <ScoreCard studentId={userId} />
        </div>
    );
};

export default UserProfileInfo;
