import React, { FC, useState } from 'react';

import cx from 'classnames';
import { useWizard } from 'react-use-wizard';
import Webcam, { WebcamProps } from 'react-webcam';

import Button from '@components/core/inputs/Button';
import Loader from '@components/core/Loader';

import { AuthPersonaCameraError } from './AuthPersonaCameraError';
import style from './AuthPesonaSetupCamera.module.scss';


export const AuthPesonaSetupCamera:FC = () => {

    const { handleStep, nextStep }          = useWizard();
    const [ errorCamera, setErrorCamera ]   = useState(false);
    const [ isLoading, setIsLoading]        = useState(true);
    const [ isReloadPage, setIsReloadPage]  = useState(false);

    const onUserMediaError = (error: string | DOMException) => setErrorCamera(true);
    const onUserMedia = (stream: MediaStream) => {
        if(stream.active) {
            setIsLoading(false);
        }
    };

    const webCameraProps: WebcamProps = {
        audio: false,
        height: 520,
        screenshotFormat: 'image/jpeg',
        onUserMediaError,
        forceScreenshotSourceSize: true,
        imageSmoothing: true,
        mirrored: false,
        onUserMedia,
        screenshotQuality: 1
    };

    const toggleReload = () => {
        setErrorCamera(false);
    };

    const onNext = () => {
        if (!errorCamera) {
            nextStep();
        }
    };

    return <div className={style.container}>
        <div className={style.leftColumn}>
            <div className={style.columnItem}>
                <h3 className={style.title}>Разрешите доступ к&nbsp;веб камере</h3>

                <p>
                    Ваш браузер запрашивает доступ к&nbsp;веб-камере, чтобы вы&nbsp;смогли пройти аутентификацию личности.
                </p>
            </div>

            <div className={style.columnItem}>
                <Button
                    btnType="success"
                    onClick={onNext}
                    disabled={errorCamera}>Далее</Button>
            </div>
        </div>

        <div className={cx(style.rightColumn, !errorCamera && style.isCamera)}>
            { (isLoading && !errorCamera ) && <div className={style.loader}><Loader /></div> }
            {
                errorCamera
                    ? (<AuthPersonaCameraError onClick={toggleReload} />)
                    : <div className={style.innerCamera}>
                        { !isReloadPage ? <Webcam {...webCameraProps}  /> : null }
                    </div>
            }
        </div>
    </div>;
};
