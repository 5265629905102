import { useParams } from 'react-router-dom';

import { Helmet } from '@components/Helmet';
import { findCategory } from '@helpers/categories';
import { UpdateCourseData } from '@store/api/apiTypes';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import { useUpdateDisciplineMutation } from '@store/api/disciplinesApi';
import EditCourseForm from '@views/methodist/Materials/Course/EditCourse/BasicCourse/EditCourseForm';
import { EditCourseFormData } from '@views/methodist/Materials/Course/EditCourse/BasicCourse/EditCourseForm/type';
import { useEditCourseFormContext } from '@views/methodist/Materials/Course/EditCourse/EditCourse';

const BasicCourse = () => {
    const { uuidDiscipline, uuidCategory } = useParams();

    const categoriesQueryData = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000');
    const category = findCategory(categoriesQueryData.data, uuidCategory);

    const { formContext } = useEditCourseFormContext();
    const [updateCourse, { data, isSuccess, isError }] = useUpdateDisciplineMutation();

    const getFormData = (formData: EditCourseFormData) => {
        const transformedData: UpdateCourseData = {
            uuid: uuidDiscipline as string,
            picture: formData.picture,
            name: formData.name,
            status: !!formData.status,
            uuidsMetodist: formData.uuidsMetodist ? formData.uuidsMetodist.map(item => item.uuid) : [],
            uuidsTeacher: formData.uuidsTeacher ? formData.uuidsTeacher.map(item => item.uuid) : [],
            uuidsDouble: formData.uuidsDouble ? formData.uuidsDouble.map(item => item.uuid) : []
        };

        updateCourse(transformedData);
    };

    return (
        <>
            <Helmet title={category && `Учебные материалы – ${category.name}: ${formContext.name}`} />
            <EditCourseForm defaultValues={formContext} getFormData={getFormData} />
        </>
    );
};

export default BasicCourse;
