import { FC, useState } from 'react';

import { ReactComponent as SuccessSVG } from '@assets/icons/check.svg';
import Button from '@components/core/inputs/Button';
import Loader from '@components/core/Loader';
import Modal from '@components/Modal';
import { getGroupedMaterialsByDeadline } from '@helpers/disciplines';
import { getFlatGroupsWithEndsChildren } from '@helpers/groups';
import { GroupTree } from '@store/api/apiTypes';
import { groupsApi, useSubscribeToDisciplinesMutation, useSetGroupDeadlinesMutation } from '@store/api/groupsApi';

import styleRoot from './inherit_modal.module.scss';

interface IInheritModal {
    isOpen: boolean,
    onClose: () => void
    defaultValue: GroupTree
}

const InheritModal: FC<IInheritModal> = ({ isOpen, onClose, defaultValue }) => {
    const [inheritLoading, setInheritLoading] = useState(false);
    const [inheritSuccess, setInheritSuccess] = useState(false);

    const [getSubscribedDiscipline] = groupsApi.endpoints.getSubscribedDisciplines.useLazyQuerySubscription();
    const [getGroupDeadlines] = groupsApi.endpoints.getGroupDeadlines.useLazyQuerySubscription();
    const [subscribeDisciplines] = useSubscribeToDisciplinesMutation();
    const [setDeadlines] = useSetGroupDeadlinesMutation();

    const groupMapWithEndChildren = getFlatGroupsWithEndsChildren([defaultValue]);

    const handleInherit = async () => {
        if (!inheritLoading) {
            const endChildren = groupMapWithEndChildren?.[defaultValue.UUID].endChildren;

            if (endChildren) {
                setInheritLoading(true);
                const [{ data: disciplines }, { data: deadlines }] = await Promise.all([
                    getSubscribedDiscipline([defaultValue.UUID]),
                    getGroupDeadlines(defaultValue.UUID)
                ]);

                if (disciplines && deadlines) {
                    const dataForSubscribeDisciplines = disciplines.map(discipline => ({
                        disciplineUUID: discipline.disciplineUUID,
                        session: discipline.session,
                        closeType: discipline.closeType,
                        startDate: discipline.startDate,
                        progress: discipline.progress
                    }));

                    const groupedMaterialsByDeadline = getGroupedMaterialsByDeadline(deadlines);

                    await Promise.all(endChildren?.map((childGroup) => {
                        return [
                            subscribeDisciplines({
                                groupUUID: childGroup.UUID,
                                disciplines: dataForSubscribeDisciplines,
                                noToast: true
                            }).unwrap(),

                            ...groupedMaterialsByDeadline.map(groupMaterial => setDeadlines({
                                groupUUID: childGroup.UUID,
                                materials: groupMaterial.materials,
                                deadline: groupMaterial.deadline
                            }).unwrap())
                        ];
                    }));
                }
                setInheritLoading(false);
                setInheritSuccess(true);
            }
        }
    };

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title={'Наследовать подписки'}
        >
            {
                !inheritSuccess ? 
                    <>
                        <div className={styleRoot.info}>
                            Это действие <span className={styleRoot.bold}>заменит</span> все подписанные в дочерних группах дисциплины 
                            на дисциплины из данной группы и назначит установленные в данной группе сроки сдачи
                        </div>
                        <Button
                            btnType="outline"
                            className={styleRoot['inherit-button']}
                            onClick={handleInherit}
                        >
                            {
                                !inheritLoading
                                    ? 'Унаследовать уже назначенные дисциплины и их сроки сдачи для дочерних групп'
                                    : <Loader className={styleRoot.loader} />
                            }
                        </Button>
                    </>: <div className={styleRoot['inherit-success']}>
                        <SuccessSVG />
                        <span>Дисциплины и сроки сдачи унаследованы</span>
                    </div>
            }

        </Modal>
    );
};

export default InheritModal;
