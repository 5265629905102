import { useEffect, useState } from 'react';

import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import GoBackLink from '@components/student/GoBackLink';
import { sortByTime } from '@helpers/date';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Grade, GradeTeacher } from '@store/api/apiTypes';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { emptyBaseApi } from '@store/api/emptyBaseApi';
import { useSubmitTaskToTeacherMutation, useRequestRetakeQuery, useGetGradesTeacherQuery } from '@store/api/gradesApi';
import { useGetTaskByIdQuery } from '@store/api/taskApi';
import { removeAuthenticationUuid, selectAuthenticationData } from '@store/features/authenticationSlice';
import { selectApiUser } from '@store/features/userSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { IRetakes } from  '@type/retakes';
import AnswerForm from '@views/student/StudentTask/AnswerForm';
import Task from '@views/student/StudentTask/Task';


import CompletedTask from './CompletedTask';
import style from './StudentTask.module.scss';


const StudentTask = () => {
    const { uuidDiscipline, uuidTask } = useParams();
    const dispatch = useAppDispatch();

    const location = useLocation();
    const navigate = useNavigate();

    const authenticationData = useAppSelector(selectAuthenticationData).find(a => a.uuidTest === uuidTask);
    const userAccount = useAppSelector(selectApiUser);

    const [taskSendingLoading, setTaskSendingLoading] = useState(false);

    const taskData = useGetTaskByIdQuery(uuidTask ?? skipToken);
    const retakeData = useRequestRetakeQuery(uuidTask ? { uuidMaterial: uuidTask } : skipToken);
    const gradesData = useGetGradesTeacherQuery((userAccount && uuidDiscipline && uuidTask) ? {
        uuidsDiscipline: [uuidDiscipline],
        uuidsUser: [userAccount.uuid],
        uuidsMaterial: [uuidTask]
    } : skipToken);

    const { data: disciplineData } = useGetDisciplineByIdQuery(uuidDiscipline || skipToken);

    const [submitTask] = useSubmitTaskToTeacherMutation();

    // инвалидирует все тэги кэшей в RTQ, связанные с контрольной
    const invalidateQueryData = () => {
        dispatch(emptyBaseApi.util.invalidateTags([
            { type: 'Material', id: uuidTask },
            { type: 'Grades', id: `Grade_${uuidDiscipline}_${uuidTask}` },
            { type: 'TaskRetakes', id: `TaskRetakes_${uuidTask}` }
        ]));
    };

    // Проверяет, есть ли у самой свежей оценки доступный ретейк
    const isRetakeAvailable = (grades: GradeTeacher[], retakes: IRetakes[]) => {
        if (grades.length) {
            const sortedGrades = sortByTime(grades);
            if (!sortedGrades[0].checked) return false;

            if (sortedGrades[0].uuidAttempt === '' || sortedGrades[0].uuidAttempt === null) return true;

            const latestRetake = retakes.find(retake => ((retake.uuidAttempt === sortedGrades[0].uuidAttempt) && retake.retakeAvailable));

            return latestRetake !== undefined;
        }
        return false;
    };

    useEffect(() => {
        const localStorageData = localStorage.getItem('KP_FORMDATA');
        const localStorageParsingData = localStorageData ? JSON.parse(localStorageData) : null;
        localStorage.removeItem('KP_FORMDATA');

        if (authenticationData) {

            const callGrades = async () => {
                if (localStorageParsingData) {
                    setTaskSendingLoading(true);
                    await submitTask({
                        uuidAuth:  authenticationData.uuidAuthentication,
                        uuidDiscipline: localStorageParsingData?.meta.uuidDiscipline,
                        uuidMaterial: localStorageParsingData?.meta.uuidMaterial,
                        uuidUser: localStorageParsingData?.meta.uuidUser,
                        uuidFile: localStorageParsingData?.meta.uuidFiles[0],
                        studentComment: localStorageParsingData.textAreaField
                    });
                }

                dispatch(removeAuthenticationUuid(authenticationData.uuidAuthentication));
                setTaskSendingLoading(false);
            };

            callGrades();
        } else {
            invalidateQueryData();
        }

        navigate(location, { state: undefined, replace: true });
    }, []);

    if (taskData.isFetching || retakeData.isFetching || gradesData.isFetching) return <NewLoader />;
    if (!taskData.isSuccess || !retakeData.isSuccess || !gradesData.isSuccess) return <NewLoader />;


    const renderGrades = () => {

        return gradesData.data.map((grade, index, array) => {
            return <CompletedTask key={grade.uuid} attempt={grade} index={array.length - index} />;
        });

    };

    const renderAnswerForm = () => {
        const isRetake = isRetakeAvailable(gradesData.data, retakeData.data);

        if (!gradesData.data.length || isRetake) {

            return <AnswerForm authenticationData={authenticationData} userAccount={userAccount} isRetake={isRetake} loading={taskSendingLoading} />;
        }
    };

    return (
        <Main className={style.main} title={disciplineData && `Обучение – ${disciplineData.name}: ${taskData.data.name}`}>
            <GoBackLink to='./../..'>
                Назад
            </GoBackLink>

            <h1 className={style.main_title}>{taskData.data.name}</h1>
            <div className={style.content_wrapper}>
                <Task task={taskData.data} />
                {
                    renderAnswerForm()
                }
                {
                    renderGrades()
                }
            </div>
        </Main>
    );
};

export default StudentTask;
