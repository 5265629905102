import { useState } from 'react';

import ButtonDrop from '@components/core/inputs/Button/ButtonDrop';
import { DropMenuItem } from '@components/core/styledWrappers/DropMenu';
import { RoleCheck } from '@components/RoleCheck';
import { Role } from '@store/Roles';
import style from '@views/methodist/Materials/Course/CreateCourse/CreateCourseForm/create_course_form.module.scss';
import MethodistList from '@views/methodist/Materials/Course/TeachersCard/MethodistList';
import TeachersList from '@views/methodist/Materials/Course/TeachersCard/TeachersList';

export type TeacherModal = 'teacher' | 'secondTeacher' | null;

const TeachersCard = () => {
    const [showTeacherModal, setShowTeacherModal] = useState<TeacherModal>(null);
    const [showMethodistModal, setShowMethodistModal] = useState(false);

    const closeTeacherModal = (_: boolean) => {
        setShowTeacherModal(null);
    };

    return (
        <div>
            <h2 className={style.fieldset_title}>Преподаватели и методисты</h2>
            <ul className={style.content_list}>
                <li>
                    <TeachersList
                        showModal={showTeacherModal}
                        setShowModal={closeTeacherModal}
                    />
                </li>
                <li>
                    <MethodistList
                        showModal={showMethodistModal}
                        setShowModal={setShowMethodistModal}
                    />
                </li>
            </ul>
            <RoleCheck roles={Role.Methodist}>
                <ButtonDrop
                    btnType="outline"
                    typeIcon="addCircle"
                    buttonChild="Добавить"
                >
                    <DropMenuItem onClick={() => setShowTeacherModal('teacher')}>
                        Преподаватель
                    </DropMenuItem>
                    <DropMenuItem onClick={() => setShowTeacherModal('secondTeacher')}>
                        Преподаватель-дублер
                    </DropMenuItem>
                    <DropMenuItem onClick={() => setShowMethodistModal(true)}>
                        Методист
                    </DropMenuItem>
                </ButtonDrop>
            </RoleCheck>
        </div>
    );
};

export default TeachersCard;
