import { ReactComponent as AvailableDisciplinesSVG } from '@assets/image/availableDisciplines.svg';

import style from './DisciplinesNotFound.module.scss';


export const AvailableDisciplines = () => {

    return <div className={style.emptyContainer}>
        <div className={style.picture}>
            <AvailableDisciplinesSVG />
        </div>

        <div className={style.header}>
            Доступных дисциплин нет
        </div>

        <div className={style.text}>
            Дисциплины назначаются <br />
            в начале нового учебного семестра
        </div>
    </div>;
};
