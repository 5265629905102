import React, { FC } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import SwitcherControl from '@components/core/inputs/Switcher/SwitcherControl';
import Loader from '@components/core/Loader';
import TinyEditor from '@components/methodist/TinyEditor';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useStopTaskWorkMutation, useSubmitGradesMutation } from '@store/api/gradesApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { useGradesContext } from '@views/teacher/TeacherMain/TeacherMain';

import style from './AnswerForm.module.scss';

const taskAnswerFormSchema = (max: number) => yup.object({
    grade: yup.number().required('Поле обязательно для заполнения').max(max, `Максимальная оценка ${max} баллов`).typeError('Укажите число'),
    review: yup.string().required('Поле обязательно для заполнения'),
    retakeAvailable: yup.boolean().required()
});

interface TaskAnswerForm {
    grade: number;
    review: string;
    retakeAvailable: boolean
}

interface IAnswerForm {
    uuidGrade: string;
    maxRating: number;
    defaultValues: TaskAnswerForm;
    onClose?: () => void;
    isEdit?: boolean;
}

const AnswerForm: FC<IAnswerForm> = ({ uuidGrade, maxRating, defaultValues, onClose, isEdit = false }) => {
    const { uuidUser, uuidTask } = useParams();
    const { workOnVerifieIds } = useGradesContext();
    const navigate = useNavigate();
    const [submitGrade, { isLoading }] = useSubmitGradesMutation();
    const [stopWorkTimer] = useStopTaskWorkMutation();

    const methods = useForm<TaskAnswerForm>({
        defaultValues: defaultValues,
        resolver: yupResolver(taskAnswerFormSchema(maxRating))
    });

    const userAccount = useAppSelector(selectApiUser);

    const getNextTask = () => {
        const currentIndexTask = workOnVerifieIds.findIndex(id => id === `${uuidUser}/${uuidTask}`);
        // Скрываем кнопку для дублерских работ
        if (currentIndexTask == -1) return null;

        if (workOnVerifieIds[currentIndexTask + 1]) {
            return workOnVerifieIds[currentIndexTask + 1];
        } else if (workOnVerifieIds.length && currentIndexTask !== 0) {
            return workOnVerifieIds[0];
        } return null;
    };

    const nextTask = getNextTask();

    const { control, register, handleSubmit, formState: { errors } } = methods;

    const onSubmit = async (formData: TaskAnswerForm) => {
        return await submitGrade({
            uuid: uuidGrade as string,
            uuidTeacher: userAccount!.uuid,
            ...formData
        }).unwrap();
    };

    const onSave = () => {
        handleSubmit(async (data) => {
            await onSubmit(data);
            if (!isEdit)
                stopWorkTimer({ uuidGrade: uuidGrade, uuidTeacher: userAccount!.uuid });
            navigate('/teaching');
        })();
    };

    const onSaveAndNext = () => {


        handleSubmit(async (data) => {
            await onSubmit(data);
            if (!isEdit)
                stopWorkTimer({ uuidGrade: uuidGrade, uuidTeacher: userAccount!.uuid });
            navigate(`/teaching/grades/${nextTask}`);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        })();
    };

    const onCancel = () => {
        if  (onClose) {
            onClose();
            return;
        }
        navigate('/teaching');
    };

    if (isLoading) return <Loader />;

    return (
        <div className={style.form_wrapper}>
            <form>
                <FormProvider {...methods}>
                    <div className={cx(style.fieldset)}>
                        <h3 className={style.answer_title}>Оценка работы:</h3>

                        <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                            <label htmlFor="field-score-id">
                                Оценка (макс. {maxRating} баллов)
                            </label>
                            <div className={style.input_score_wrapper}>
                                <InputControl
                                    name="grade"
                                    type="number"
                                    id="field-score-id"
                                />
                            </div>
                        </div>

                        <div className={style.input_wrapper}>
                            <p>Отзыв к работе</p>
                            <TinyEditor name="review" control={control} error={Boolean(errors.review)} />
                        </div>

                        <div className={cx(style.input_wrapper, style.checker)}>
                            <p>Разрешить еще попытку</p>
                            <div className={style.permission_block_wrapper}>
                                <SwitcherControl
                                    name="retakeAvailable"
                                    control={control}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={cx(style.fieldset, style.action_btns)}>
                        <div className={style.form_content__button_wrapper}>
                            <Button onClick={onSave}>Сохранить</Button>
                            {nextTask && <Button btnType="outline" onClick={onSaveAndNext}>Сохранить и оценивать далее</Button>}
                            <Button
                                btnType="secondary"
                                onClick={onCancel}
                            >
                                Отмена
                            </Button>
                        </div>
                    </div>
                </FormProvider>
            </form>
        </div>
    );
};

export default AnswerForm;
