import React from 'react';

import Main from '@components/Main';
import GoBackLink from '@components/student/GoBackLink';

import style from './EquipmentRequirementsPage.module.scss';

const EquipmentRequirementsPage = () => {
    return (
        <Main title="Требования к оборудованию" className={style.container}>
            <GoBackLink to={'/'}>
                Назад
            </GoBackLink>
            <h1 className={style.main_title}>
                Требования к составу технических средств и программного обеспечения, необходимых для участия в дистанционном обучении
            </h1>
            <div className={style.main_content}>
                <p className={style.paragraph}>
                    Все требования в следующих разделах применяются как к настольному приложению системы дистанционного обучения ВЕИП, так и к веб-приложению.
                </p>
                <p className={style.paragraph}>1) Требования к оборудованию для приложения на ПК с Windows</p>
                <table>
                    <tr>
                        <td><p>Компонент</p>
                        </td>
                        <td><p>Требование</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Компьютер и процессор</p>
                        </td>
                        <td><p>Минимум 1,6 ГГц (или выше), 2 ядра</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Память</p>
                        </td>
                        <td><p>4,0 ГБ ОЗУ</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Жесткий диск</p>
                        </td>
                        <td><p>3,0 ГБ свободного пространства на жестком диске</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Отображение</p>
                        </td>
                        <td><p>Разрешение экрана 1024 x 768</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Операционная система</p>
                        </td>
                        <td><p>Windows 10, Windows 10 на ARM, Windows 8,1, Windows Server 2019, Windows Server 2016</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Версия .NET</p>
                        </td>
                        <td><p>Требуется .NET 4.5 CLR или более поздняя версия</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Видео</p>
                        </td>
                        <td><p>Видеокамера USB 2.0</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Устройства</p>
                        </td>
                        <td><p>Стандартная камера ноутбука, микрофон и динамики</p>
                        </td>
                    </tr>
                </table>
                <p className={style.paragraph}>2) Требования к оборудованию для приложения на Mac</p>
                <table>
                    <tr>
                        <td><p>Компонент</p>
                        </td>
                        <td><p>Требование</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Компьютер и процессор</p>
                        </td>
                        <td><p>Процессор Intel Core Duo</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Память</p>
                        </td>
                        <td><p>4,0 ГБ ОЗУ</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Жесткий диск</p>
                        </td>
                        <td><p>1,5 ГБ доступного дискового пространства</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Отображение</p>
                        </td>
                        <td><p>Разрешение 1280 x 800 или выше</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Операционная система</p>
                        </td>
                        <td><p>Одна из трех последних версий macOS. Например, если выпущена новая версия macOS, Новая версия и две, непосредственно
                            предшествующие
                            ей, становятся поддерживаемыми версиями.</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Видео</p>
                        </td>
                        <td><p>Совместимая веб-камера</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Голос</p>
                        </td>
                        <td><p>Совместимый микрофон и динамики, гарнитура с микрофоном или аналогичное устройство</p>
                        </td>
                    </tr>
                </table>
                <p className={style.paragraph}>3) Требования к оборудованию для приложения на Linux</p>
                <table>
                    <tr>
                        <td><p>Компонент</p>
                        </td>
                        <td><p>Требование</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Компьютер и процессор</p>
                        </td>
                        <td><p>1,6 ГГц (или более позднюю версию) (32-бит или 64-разр), 2 ядра</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Память</p>
                        </td>
                        <td><p>4,0 ГБ ОЗУ</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Жесткий диск</p>
                        </td>
                        <td><p>3,0 ГБ свободного пространства на жестком диске</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Отображение</p>
                        </td>
                        <td><p>Разрешение экрана 1024 x 768</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Операционная система</p>
                        </td>
                        <td><p>Дистрибутив Linux с возможностью установки DEB или RPM.</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Видео</p>
                        </td>
                        <td><p>Видеокамера USB 2.0</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Устройства</p>
                        </td>
                        <td><p>Стандартная камера ноутбука, микрофон и динамики</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Голос</p>
                        </td>
                        <td><p>Совместимый микрофон и динамики, гарнитура с микрофоном или аналогичное устройство</p>
                        </td>
                    </tr>
                </table>
                <p className={style.paragraph}>4) Требования к оборудованию для приложения на мобильных устройствах.</p>
                <p className={style.paragraph}>Вы можете использовать приложение на этих мобильных платформах:</p>
                <ul className={style.paragraph}>
                    <li>
                        <p>
                            - Android: совместимо с телефонами и планшетами Android.
                        </p>
                    </li>
                    <li>
                        <p>
                            - Поддержка может быть ограничена последними четырьмя основными версиями Android. Например, при выпуске новой основной версии
                            Android, требованием
                            Android является наличие новой версии и трех последних версий, предшествующих ей.
                        </p>
                    </li>
                    <li>
                        <p>
                            - iOS: совместимо с iPhone, iPad.
                        </p>
                    </li>
                    <li>
                        <p>
                            - Поддержка ограничена двумя последними основными версиями iOS. Например, при выпуске новой основной версии iOS, требованием iOS
                            является наличие
                            новой версии и последних версий, предшествующих ей.
                            Для лучшего взаимодействия с приложением используйте последнюю версию iOS и Android.
                        </p>
                    </li>
                </ul>

                <p className={style.paragraph}>5) Дополнительные требования и настройки</p>
                <ul>
                    <li>
                        <p>
                            - Система дистанционного обучения может некорректно работать при использовании VPN и других специфичных ограничений сети.
                        </p>
                    </li>
                    <li>
                        <p>
                            - Система дистанционного обучения корректно работает с такими браузерами, как: Google Chrome, Microsoft Edge, Яндекс.Браузер
                            (актуальная
                            версия). Браузеры Apple Safari, Opera, Mozilla Firefox работают некорректно или совсем не работают. Рекомендуем использовать
                            актуальные версии
                            предложенных браузеров.
                        </p>
                    </li>
                    <li>
                        <p>
                            - Требования к Интернет-каналу: скорость Интернет-соединения - от 2,5 Мбит/с.
                        </p>
                    </li>
                    <li>
                        <p>
                            - Для максимально качественной трансляции звука и видео во время участия в вебинарах рекомендуется использовать проводное соединение
                            с сетевым
                            оборудованием (рекомендуется не применять соединение с помощью Wi-Fi), а также отключение всех приложений и программ, которые могут
                            использовать
                            камеру или микрофон параллельно с проведением вебинара (skype, zoom, другое). Проверьте, что никто другой не забирает скорость
                            соединения.
                        </p>
                    </li>
                    <li>
                        <p>
                            - На устройствах фирмы Apple возможны проблемы со входом в приложение. В этом случае стоит подключаться через браузеры, указанные
                            выше или
                            позвонить по тел. 8 800 3333 77 6 доб.911.
                        </p>
                    </li>
                    <li>
                        <p>
                            - А также согласно правилам безопасности Apple, во время вебинаров вам потребуется в настройках конфиденциальности Mac и Macbook
                            предоставить
                            доступ к демонстрации экрана.
                        </p>
                    </li>
                    <li>
                        <p>
                            - При подключении к вебинару и для аутентификации перед прохождением тестирования и загрузки контрольной работы у вас запросят
                            разрешение на
                            использование камеры и микрофона. Во избежание проблем, все запросы нужно подтвердить.
                        </p>
                    </li>
                    <li>
                        <p>
                            - Все вышеуказанные требования универсальны для большинства сервисов, но из-за разнообразия устройств, программ и локальных проблем,
                            возможны
                            трудности в использовании.
                        </p>
                    </li>
                </ul>
            </div>
        </Main>
    );
};

export default EquipmentRequirementsPage;
