import React, { ButtonHTMLAttributes, FC, ReactNode, RefObject } from 'react';

import classNames from 'classnames/bind';
import { Link, To } from 'react-router-dom';

import IconUI from '@components/core/IconUI';
import { TypeIcon } from '@components/core/IconUI/type';

import style from './button.module.scss';

const cx = classNames.bind(style);

//ToDo: Этот компонент нужно разбить на отдельные для ссылок и для кнопок

export type BtnType =
    'success'
    | 'success-light'
    | 'secondary'
    | 'danger'
    | 'primary'
    | 'outline'
    | 'clear'
    | 'dark'
    | 'menu'
    | 'pagination'
    | 'yellow'
    | 'tabsDark'
    | 'tabs'
    | 'white'
    | 'download'
    | 'green-light';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    children?: ReactNode;
    size?: 'normal' | 'small' | 'middle' | 'tiny';
    link?: boolean;
    to?: To;
    btnType?: BtnType;
    fullWidth?: boolean;
    typeIcon?: TypeIcon;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    href?: string;
    download?: boolean;
    isIconPrev?: boolean;
    innerRef?: RefObject<HTMLButtonElement>;
}

const Button: FC<IButtonProps> = (
    {
        className,
        children,
        size = 'normal',
        btnType = 'success',
        fullWidth = false,
        type = 'button',
        disabled,
        typeIcon,
        link,
        to = '',
        href = '',
        download = false,
        isIconPrev = true,
        innerRef,
        ...props
    }) => {

    const classNamesButton = cx(
        'root',
        size && `root--${size}`,
        btnType && `root--${btnType}`,
        disabled && 'root--disabled',
        fullWidth && 'root--full_width',
        className
    );

    const icon = <IconUI key="iconuid" typeIcon={typeIcon} className={cx(style.icon, isIconPrev && style['icon-prev'])} />;

    const contentPosition = isIconPrev ? [icon, children] : [children, icon];

    const renderButton = () => {
        if (link) {
            return (
                <Link to={to} className={classNamesButton}>
                    {contentPosition}
                </Link>
            );
        } else if (href) {
            return (
                <a
                    href={href}
                    download={download}
                    target="_blank"
                    className={classNamesButton} rel="noreferrer">

                    {contentPosition}
                </a>
            );
        } else {
            return (
                <button
                    className={classNamesButton}
                    ref={innerRef}
                    type={type}
                    {...props} >

                    {contentPosition}
                </button>
            );
        }
    };
    return (
        <>
            {renderButton()}
        </>
    );
};

export default Button;
