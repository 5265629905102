import { toast } from 'react-toastify';


import { PatternCreateRequest, PatternCreateResponse, PatternDeleteRequest, PatternGetRequest, PatternGetResponse, PatternUpdateRequest, PatternUpdateResponse } from '@type/patterns';


import { emptyBaseApi } from './emptyBaseApi';

export const patternApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getPattern: build.query<PatternGetResponse, string>({
            query(uuidDiscipline: string) {
                return {
                    url: 'pattern/get',
                    method: 'POST',
                    body: {
                        uuidDiscipline
                    }
                };
            }
        }),
        deletePattern: build.query<Record<string, unknown>, PatternDeleteRequest>({
            query(data) {
                return {
                    url: 'pattern/get',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    error: 'Ошибка удаления паттерна!'
                });
            }
        }),
        createPattern: build.mutation<PatternCreateResponse, PatternCreateRequest>({
            query(data) {
                return {
                    url: 'pattern/create',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Шаблон создан',
                    error: 'Ошибка создания шаблона'
                });
            }
        }),
        updatePattern: build.mutation<PatternUpdateResponse, PatternUpdateRequest>({
            query(data) {
                return {
                    url: 'pattern/update',
                    method: 'POST',
                    body: data
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Шаблон обновлен',
                    error: 'Ошибка обновления шаблона'
                });
            }
        })
    })
});

export const {
    useLazyGetPatternQuery,
    useGetPatternQuery,
    useCreatePatternMutation,
    useUpdatePatternMutation,
    useDeletePatternQuery,
    useLazyDeletePatternQuery
} = patternApi;
