import { validationAndReturnRooms } from '@helpers/chat';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Uuid } from '@store/api/apiTypes';
import { RootState } from '@store/store';
import { MainEventMessage, Message, PreviewMessage, Room, RoomServiceInformation, StorageMessage } from '@type/chat';
import { User } from '@type/users';

type RoomsData = Record<Uuid, Room>;

export const MAX_CHUNK_SIZE = 30;

interface ChatState {
    rooms: Uuid[];
    roomsData: RoomsData;
    messages: Record<Uuid, StorageMessage[] | null>;
    roomServiceInformation: Record<string, RoomServiceInformation>
    total: number;
}

const initialState: ChatState = {
    rooms: [],
    roomsData: {},
    messages: {},
    roomServiceInformation: {},
    total: 0
};

export const chatSlice = createSlice({
    name: 'chatSlice',
    initialState,
    reducers: {
        setRoomsToStore: (state, { payload }: PayloadAction<{ rooms: Room[] | null | undefined, me: User | null, offset: number, total: number }>) => {
            if (payload.rooms) {
                const validData = validationAndReturnRooms(payload.rooms, payload.me);
                const roomsMapData: RoomsData = {};
                state.total = payload.total;
                if (payload.offset === 0) {
                    state.rooms = validData.map((room) => {
                        roomsMapData[room.uuid] = room;
                        return room.uuid;
                    });
                    state.roomsData = roomsMapData;
                } else {
                    state.rooms.push(...validData.map((room) => {
                        roomsMapData[room.uuid] = room;
                        return room.uuid;
                    }));

                    state.roomsData = { ...state.roomsData, ...roomsMapData };
                }
            } else {
                return initialState;
            }
        },
        initMessages: (state, { payload }: PayloadAction<{ roomId: Uuid; messages: Message[] | null }>) => {
            const unSendingMessages = state.messages[payload.roomId] ? state.messages[payload.roomId]?.filter(message => !message.uuid) : [];
            state.messages[payload.roomId] = [...(unSendingMessages ?? []), ...(payload.messages ?? [])];
        },
        addHistoryMessages: (state, { payload }: PayloadAction<{ roomId: Uuid; messages: Message[] | null }>) => {
            state.messages[payload.roomId] = [...(state.messages[payload.roomId] ?? []), ...(payload.messages ?? [])];
        },
        addNewMessage: (state, { payload }: PayloadAction<StorageMessage>) => {
            const messagesData = state.messages[payload.uuidRoom];
            const foundMessage = messagesData?.find(message => message.uuid === payload.uuid);
            if (foundMessage) {
                if (messagesData && messagesData.length >= MAX_CHUNK_SIZE) messagesData.pop();
                foundMessage.loading = false;
                foundMessage.uuid = payload.uuid;
                if (payload.storageLoaded) {
                    foundMessage.storageLoaded = true;
                }
                if (payload.storageError) {
                    foundMessage.storageError = true;
                    foundMessage.loading = false;
                }

                // Срабатывает, когда мы отправили сообщение, а после этого оно пришло по сокетам
                // Можно добавить статус об отправленности сообщения
            } else {
                if (!messagesData) {
                    state.messages[payload.uuidRoom] = [payload];
                } else {
                    messagesData.unshift(payload);
                    if (messagesData.length >= MAX_CHUNK_SIZE && !payload.loading) messagesData.pop();
                }

                // добавление нового сообщения в превью комнаты
                if (state.roomsData[payload.uuidRoom]) {
                    state.roomsData[payload.uuidRoom].previewMessage = payload as PreviewMessage;
                }

            }
        },

        handleServiceMessage: (state, { payload }: PayloadAction<MainEventMessage>) => {
            state.roomServiceInformation[payload.uuidRoom] = {
                ...(state.roomServiceInformation[payload.uuidRoom] || {}),
                uuid: payload.uuidRoom,
                notificationCount: (state.roomServiceInformation?.[payload.uuidRoom]?.notificationCount ?? 0) + 1
            };
        },

        resetRoomServices: (state, { payload }: PayloadAction<string | null>) => {
            if (payload) {
                delete state.roomServiceInformation[payload];
            }
        },

        saveDraftMessage: (state, { payload }: PayloadAction<{ roomId: string; message: string }>) => {
            state.roomServiceInformation[payload.roomId] = {
                uuid: payload.roomId,
                draftMessage: payload.message
            };
        },

        addNewRoom: (state, { payload }: PayloadAction<Room>) => {
            state.rooms.unshift(payload.uuid);
            state.roomsData[payload.uuid] = payload;
        },

        replaceNewRoomUuid: (state, { payload }: PayloadAction<string>) => {
            state.rooms[0] = payload;
            const roomData = state.roomsData.new;
            delete state.roomsData.new;
            state.roomsData[payload] = { ...roomData, uuid: payload };
        },

        deleteNewRoom: (state) => {
            state.rooms = state.rooms.filter(room => room !== 'new');
            delete state.roomsData.new;
        },

        resetChatSlice: () => {
            return initialState;
        }
    }
});


// selectors
export const selectChatRoot = (state: RootState) => state.chatSlice;
export const selectRoomsDataMap = createSelector([selectChatRoot], ({ roomsData }) => roomsData);

export const selectRooms = createSelector([selectChatRoot], ({ rooms, roomsData }) => {
    return rooms.map((uuid) => roomsData[uuid]);
});

export const selectRoomsTotal = (state: RootState) => state.chatSlice.total;

const selectRoomId = (_: RootState, roomId: string | null) => roomId;
export const selectCurrentChat = createSelector(
    [selectRoomsDataMap, selectRoomId],
    (roomsData, roomId) => roomId ? roomsData[roomId] : null
);
export const selectMessages = createSelector(
    [selectChatRoot, selectRoomId],
    ({ messages }, roomId) => roomId ? messages[roomId] ?? [] : []
);

const selectServiceInformation = createSelector([selectChatRoot], ({ roomServiceInformation }) => roomServiceInformation);
export const selectCurrentServiceInformation = createSelector(
    [selectServiceInformation, selectRoomId],
    (information, roomId) => roomId ? information?.[roomId] : null);

export const selectTotalNewMessage = createSelector([selectServiceInformation], (information) => {
    return Object.values(information).reduce((acc, item) => acc + (item.notificationCount ?? 0), 0);
});

export const selectRoomsWithNewMessage = createSelector([selectServiceInformation], (information) => {
    return Object.values(information).filter(room => room.notificationCount).map(room => room.uuid);
});


// actions
export const {
    setRoomsToStore,
    initMessages,
    addHistoryMessages,
    addNewMessage,
    handleServiceMessage,
    resetRoomServices,
    saveDraftMessage,
    resetChatSlice,
    addNewRoom,
    replaceNewRoomUuid,
    deleteNewRoom
} = chatSlice.actions;


export default chatSlice.reducer;
