import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ReactComponent as CreateSection } from '@assets/image/create-section-svg.svg';
import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { NewLoader } from '@components/core/NewLoader';
import { Helmet } from '@components/Helmet';
import {  RoleCheck } from '@components/RoleCheck';
import { useNavigationState } from '@hooks/useNavigationState';
import { useCreateStudyProgrammeMutation, useGetStudyProgrammeByUuidGroupQuery } from '@store/api/programmesApi';
import { Role } from '@store/Roles';

import style from './CreateProgramme.module.scss';

const CreateProgramme = () => {
    const { state } = useNavigationState<{ currentGroup: string, openedGroups: string[] }>();
    const { navigate } = useNavigationState<{ currentGroup: string, openedGroups: string[] }>(state);
    const { uuidGroup } = useParams();

    const { 
        data: programmData, 
        isLoading: groupInfoLoading,
        isSuccess: groupInfoSucces,
        isError: groupInfoError 
    } = useGetStudyProgrammeByUuidGroupQuery(uuidGroup as string);

    const getBackLink = () => {
        if (state) {
            return `/users/groups/${state.currentGroup}`;
        } else {
            return '/users';
        }
    };

    const [createProgramme, { isLoading, isSuccess }] = useCreateStudyProgrammeMutation();

    const createHandler = () => {
        if (uuidGroup) createProgramme(uuidGroup);
    };

    useEffect(() => {
        if (groupInfoSucces && programmData.uuid) {
            navigate(`/users/groups/${uuidGroup}/StudyProgrammes`);
        }

        if (groupInfoError) {
            navigate(getBackLink());
        }
    }, [groupInfoLoading, groupInfoSucces, groupInfoError]);

    useEffect(() => {
        if (isSuccess) {
            navigate(`/users/groups/${uuidGroup}/StudyProgrammes`);
        }
    }, [isSuccess]);

    return (
        <>
            <div className={style.title_wrapper}>
                <div className={style.title}>
                    <ButtonIcon
                        typeIcon="arrowLink"
                        onClick={() => navigate(getBackLink())}
                    />
                    <h1>Создание учебной программы</h1>
                </div>
            </div>
            <Helmet title='Создание учебной программы' />
            <div className={style.create_section}>
                {
                    (isLoading || groupInfoLoading) ? <NewLoader /> : <>
                        <div className={style.background_img} >
                            <CreateSection />
                        </div>
                        <h3 className={style.create_section__title}>Создайте учебную программу</h3>
                        <RoleCheck roles={Role.Methodist}>
                            <Button typeIcon="add" onClick={createHandler}>Новая учебная программа</Button>
                        </RoleCheck>
                    </>
                }
            </div>
        </>
    );
};

export default CreateProgramme;
