import React, { FC, ReactNode } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export interface ISortableItemProps {
    id: string | number;
    children: ReactNode;
    handle?: boolean;
}

const SortableItem: FC<ISortableItemProps> = ({ children, handle, id }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id: id });

    const style = {
        height: '100%',
        width: '100%',
        transform: CSS.Transform.toString(transform),
        transition,
        background: 'transparent',
        zIndex: isDragging ? '100' : 'auto',
        opacity: isDragging ? 0.3 : 1
    };

    return (
        <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
            {children}
        </div>
    );
};

export default SortableItem;
