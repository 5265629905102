import { FC } from 'react';

import cx from 'classnames';

import { declination, ScoreType } from '@helpers/scores';

import style from './UserGradeCell.module.scss';

const getLabel = ( type: ScoreType, score: number) => {
    switch (type) {
        case 'excelent':
            return 'Отлично';
        case 'good':
            return 'Хорошо';
        case 'medium':
            return 'Удовлетворительно';
        case 'bad':
            return score ? `${declination(score)}. Недостаточно` : 'Недостаточно баллов';
        case 'success':
            return 'Зачет';
        case 'failed':
            return 'Незачет';
    }
};

export interface IUserScoreProps {
    type: ScoreType;
    score: number;
}

export const Score: FC<IUserScoreProps> = ({ type, score }) => {
    const scoreCl = cx(
        style.score,
        {
            [style.bad]: type === 'bad' || type === 'failed',
            [style.medium]: type === 'medium',
            [style.good]: type === 'good',
            [style.excelent]: type === 'excelent' || type === 'success'
        }
    );

    return <div className={style.container}>
        <div className={scoreCl}>{getLabel(type, score)} {type !== 'bad' && declination(score)}</div>
    </div>;
};
