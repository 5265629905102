import { FC, ReactNode } from 'react';

import classNames from 'classnames/bind';

import { Helmet } from '@components/Helmet';
import { selectJwtUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';

import style from './main.module.scss';

const cx = classNames.bind(style);

interface IProps {
    className?: string;
    children?: ReactNode;
    title?: string
}

const Main: FC<IProps> = ({ className, children, title }) => {
    const userRoleType = useAppSelector(selectJwtUser)?.UserRole;

    return (
        <main className={cx('root', className, (userRoleType === Role.Student || userRoleType === Role.Teacher) && 'root--student')}>
            {title && <Helmet title={title} />}
            {children}
        </main>
    );
};

export default Main;
