import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { NewLoader } from '@components/core/NewLoader';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { useGetUsersByRoleQuery } from '@store/api/usersApi';
import { Role } from '@store/Roles';
import { EditCourseFormData } from '@views/methodist/Materials/Course/EditCourse/BasicCourse/EditCourseForm/type';

type FormStateType = {
    formContext: EditCourseFormData;
    setFormContext: Dispatch<SetStateAction<EditCourseFormData>>;
};

export const useEditCourseFormContext = () => {
    return useOutletContext<FormStateType>();
};

const EditCourse = () => {
    const { uuidDiscipline } = useParams();
    const navigate = useNavigate();

    useGetUsersByRoleQuery(Role.Teacher);
    useGetUsersByRoleQuery(Role.Methodist);

    const { data: disciplineData, isFetching, isSuccess, isError } = useGetDisciplineByIdQuery(uuidDiscipline as string);

    const [formContext, setFormContext] = useState<EditCourseFormData>({
        name: '',
        picture: '',
        status: false,
        uuidsTeacher: [],
        uuidsMetodist: [],
        uuidsDouble: [],
        materials: []
    });


    useEffect(() => {
        if (isSuccess) {
            setFormContext({
                name: disciplineData.name,
                picture: disciplineData.picture,
                status: disciplineData.status,
                uuidsMetodist: disciplineData.metodists.map(item => ({ uuid: item.uuid })),
                uuidsTeacher: disciplineData.teachers.map(item => ({ uuid: item.uuid })),
                uuidsDouble: disciplineData.doubles.map(item => ({ uuid: item.uuid })),
                materials: []
            });
        }

        if (isError) {
            navigate('./../..');
        }
    }, [isFetching, isSuccess, isError]);


    if (isFetching) return <NewLoader />;

    return (
        <>
            <Outlet context={{ formContext, setFormContext }} />
        </>
    );
};

export default EditCourse;
