import { FC, ReactNode } from 'react';

import classNames from 'classnames/bind';

import { ReactComponent as BucketSVG } from '@assets/icons/bucket.svg';
import { ReactComponent as PhotoSVG } from '@assets/icons/photo.svg';
import { IPlaceholderBackendData, IRenderUpload } from '@components/core/inputs/FileUpload/type';

import style from './filephotostage.module.scss';
import { FilePhotoUpload } from './FilePhotoUpload';

const cx = classNames.bind(style);

interface IFileAreaStage {
    api: IPlaceholderBackendData | null;
    deleteFile: () => void;
    disabled?: boolean;
    children?: ReactNode;
}

export const FilePhotoStage: FC<IFileAreaStage> = ({ api, deleteFile, disabled, children = 'Добавить обложку курса' }) => {
    const fileRenderStage: IRenderUpload = {
        default: (
            <div className={cx('root__default')}>
                <span className={cx('root__default-icon')}>
                    <PhotoSVG />
                </span>

                <span className={cx('root__default-label')}>
                    {children}
                </span>
            </div>
        ),
        uploading: (
            <div className={cx('root')}>
                {!disabled && (
                    <div className={cx('root__delete')}
                        role="presentation"
                        onClick={(e)=>{e.stopPropagation(); deleteFile();}}>
                        <div className={cx('root__delete-icon')} >
                            <BucketSVG />
                        </div>
                    </div>
                )}


                <div className={cx('root__photo')}>
                    <FilePhotoUpload
                        className={cx('root__photo-image')}
                        file={api?.file}
                    />
                </div>
            </div>
        )
    };

    const renderStage = () => (api?.stage ? fileRenderStage[api.stage] : null);

    return <div className={cx('root')}> {renderStage()} </div>;
};
