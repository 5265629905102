import React, { FC } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { DateTime } from 'ts-luxon';

import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import ButtonMenu from '@components/core/inputs/Button/ButtonMenu';
import { MenuItem } from '@components/Menu';
import Portal from '@components/Portal';
import { checkRole } from '@components/RoleCheck';
import { ClickAwayListener } from '@mui/base';
import { Event } from '@store/api/apiTypes';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import { InfoEvent } from '@type/eventCalendar';

import { EventInfo } from '../EventInfo';
import style from './event_popup.module.scss';

interface IEventPopup {
    setInfoEvent: (obj: InfoEvent) => void;
    eventInfo: Event;
    attributes: { [key: string]: { [key: string]: string } | undefined } | undefined;
    popperStyle?: React.CSSProperties;
    setPopperElement?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
    setArrowElement?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
    arrowStyle?: React.CSSProperties;
    setEventToDelete: (event: Event) => void;
    setEventToDuplicate: (event: Event) => void;
    today: DateTime;
}

const EventPopup: FC<IEventPopup> = ({
    setInfoEvent,
    eventInfo,
    attributes = {},
    popperStyle,
    setPopperElement = null,
    setArrowElement = null,
    arrowStyle,
    setEventToDelete,
    setEventToDuplicate,
    today
}) => {
    const userRoleType = useAppSelector(selectApiUser)?.role;
    const navigate = useNavigate();
    const location = useLocation();

    const hideEvent = (e: MouseEvent | TouchEvent | React.MouseEvent<HTMLDivElement | HTMLButtonElement>  ) => {
        e.stopPropagation();
        setInfoEvent({ isVisible: false, currentEventId: null });
    };

    const eventManagementHandler = () => {
        setInfoEvent({ isVisible: false, currentEventId: eventInfo.eventUuid });
        const navigateState = {
            state: {
                pathname: location.pathname,
                today: today.toString(),
                currentEvent: eventInfo,
                actionType: 'edit'
            }
        };

        navigate('create-event', navigateState);
    };

    const deleteEventHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        setInfoEvent({ isVisible: false, currentEventId: eventInfo.eventUuid });
        hideEvent(e);
        setEventToDelete(eventInfo);
    };

    const duplicateEventHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        setInfoEvent({ isVisible: false, currentEventId: eventInfo.eventUuid });
        hideEvent(e);
        setEventToDuplicate(eventInfo);
    };

    return (
        <Portal>
            <ClickAwayListener onClickAway={hideEvent}>
                <div
                    ref={(ref) => {
                        setPopperElement?.(ref);
                    }}
                    style={popperStyle}
                    {...attributes.popper}
                    className={style.wrapper}
                >
                    <div className={style.arrow} ref={setArrowElement} style={arrowStyle} />
                    <div className={style.btn_wrapper}>

                        { checkRole(Role.Methodist) ?
                            <ButtonMenu className={style.button_menu} btnType="menu" size="small">
                                <MenuItem onClick={duplicateEventHandler}>
                                    Дублировать
                                </MenuItem>
                                <MenuItem onClick={deleteEventHandler} className={style['button_menu__item--delete']}>
                                    Удалить
                                </MenuItem>
                            </ButtonMenu>
                            : null 
                        }

                        <ButtonIcon onClick={hideEvent} typeIcon="close" className={style.close_button} />
                    </div>
                    <EventInfo eventInfo={eventInfo} setInfoEvent={setInfoEvent} />

                    {
                        userRoleType === Role.Methodist
                        &&
                        <>
                            <Button
                                fullWidth={true}
                                onClick={eventManagementHandler}
                                btnType="outline"
                            >
                                Управление мероприятием
                            </Button>
                        </>
                    }
                </div>
            </ClickAwayListener>
        </Portal>
    );
};

EventPopup.displayName = 'Search';

export default EventPopup;
