import { GroupTree } from '@store/api/apiTypes';

export const makeGroupTreeFlat = (groups: GroupTree[]): GroupTree[] => {
    const newArrayOfGroups: GroupTree[] = [];

    groups.forEach(group => {
        newArrayOfGroups.push(group);

        if (group.childs.length > 0) {
            const childGroups = makeGroupTreeFlat(group.childs);
            childGroups.forEach(childGroup => {
                newArrayOfGroups.push(childGroup);
            });
        }
    });

    return newArrayOfGroups;
};

export const findRootCurrentGroup = (currentGroup: GroupTree, rootGroupParent: GroupTree): GroupTree => {
    if (currentGroup.parentUUID === rootGroupParent.UUID) return currentGroup;

    const groups = makeGroupTreeFlat(rootGroupParent.childs);
    return findRootCurrentGroup(groups?.find(group => group.UUID === currentGroup.parentUUID) || currentGroup, rootGroupParent);
};
