// @ts-ignore
// @ts-nocheck

import { FC, useEffect, useMemo } from 'react';

import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

import Sidebar from '@components/methodist/Sidebar';
import Drawer from '@components/methodist/Sidebar/Drawer';
import { checkRole } from '@components/RoleCheck';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { LectureBlocksContent } from '@store/api/apiTypes';
import { useGetCompletedChaptersListQuery } from '@store/api/gradesApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';
import LecturePanel from '@views/student/LecturesPreview/LecturesSidebar/LecturePanel';

import style from './LecturesSidebar.module.scss';

interface IProps {
    lecturesBlock: LectureBlocksContent | null;
}

const LecturesSidebar: FC<IProps> = ({ lecturesBlock }) => {
    const { uuidLectureBlock, uuidChapter } = useParams();
    const navigate = useNavigate();
    const user = useAppSelector(selectApiUser);
    const { data: completedChaptersList } = useGetCompletedChaptersListQuery(user ? {
        uuidBlock: uuidLectureBlock as string,
        uuidUser: user.uuid
    } : skipToken);

    const transformDataLectures = useMemo(() => {
        let result: any[] = []; // мне страшно типировать это xD
        
        if (lecturesBlock && completedChaptersList) {
            result = JSON.parse(JSON.stringify(lecturesBlock.topics));
            lecturesBlock?.topics.forEach((topic, indexTopic, topicArr) => {
                result[indexTopic].disabled = true;
                topic.lectures.forEach((lecture, lectureIndex, lecturesArr) => {
                    result[indexTopic].lectures[lectureIndex].disabled = true;
                    lecture.chapters.forEach((chapter, chapterIndex, chaptersArr) => {
                        result[indexTopic].lectures[lectureIndex].chapters[chapterIndex].disabled = true;
                        if (indexTopic === 0 && lectureIndex === 0 && chapterIndex === 0) {
                            result[indexTopic].disabled = false;
                            result[indexTopic].lectures[lectureIndex].disabled = false;
                            result[indexTopic].lectures[lectureIndex].chapters[chapterIndex].disabled = false;
                        } else { // @ts-ignore
                            if (completedChaptersList.chapters.find(item => item.uuidChapter === chapter.uuid)) {
                                result[indexTopic].disabled = false;
                                result[indexTopic].lectures[lectureIndex].disabled = false;
                                result[indexTopic].lectures[lectureIndex].chapters[chapterIndex].disabled = false;
                            }
                        }
                    });
                });
            });
        }
        return result;
    }, [lecturesBlock, completedChaptersList]);

    const lastOpenChapter = useMemo(() => {
        let result = null;
        const completedChaptersUuids = completedChaptersList?.chapters?.reduce((res, it) => {
            res.push(it.uuidChapter);
            return res;
        }, []);

        transformDataLectures?.forEach((topic) => {
            topic.lectures.forEach((lecture) => {
                lecture.chapters.forEach((chapter) => {
                    if (!completedChaptersUuids.includes(chapter.uuid)) {
                        if (!result) {
                            result = chapter.uuid;
                        }
                    }
                });
            });
        });

        if (!result) {
            result = lecturesBlock?.topics[0]?.lectures[0]?.chapters[0]?.uuid
                ? `${lecturesBlock.topics[0].lectures[0].chapters[0].uuid}`
                : null;
        }
        return result;
    }, [transformDataLectures, completedChaptersList]);

    useEffect(() => {
        if (!uuidChapter && lastOpenChapter) {
            navigate(lastOpenChapter);
        }
    }, [lastOpenChapter]);

    return (
        <>
            <Sidebar className={style.sidebar}>
                <Drawer className={style.drawer}>
                    <ul className={style.list}>
                        {transformDataLectures.map((topic, index) => (
                            <li key={index} className={style.item}>
                                <div
                                    className={cx(style.topic__title_wrapper, topic.disabled && checkRole(Role.Student) && style['topic__title_wrapper--disabled'])}>
                                    <h4 className={style.topic__title}>{topic.name}</h4>
                                </div>
                                <LecturePanel lectures={topic.lectures} />
                            </li>
                        ))}
                    </ul>
                </Drawer>
            </Sidebar>
        </>
    );
};

export default LecturesSidebar;
