import  { FC } from 'react';

import { Column, Grid } from '@components/core/grid';
import IconUI from '@components/core/IconUI';
import { DataList, DataListCell } from '@components/DataList';
import { useBreakpoint } from '@hooks/useBreakpoint';
import {  IGradesArray } from '@views/teacher/TeacherMain/TeacherMain';

import GradesInTask from './GradesInTask';
import style from './TasksList.module.scss';


interface ITasksList {
    filter: 'all-works' | 'deadline-expires' | 'overdue' | 'none';
    tasks: IGradesArray[];
}

const getEmptyMessage = (filter: string) => {
    switch (filter) {
        case 'all-works':
            return (
                <div className={style.empty_message}>
                    <div>
                        <IconUI typeIcon='teacher-tasks-plug' />
                    </div>
                    <div className={style.empty_message_header}>
                        Работ на проверку нет
                    </div>
                    <div className={style.empty_message_text}>
                        Работ на проверку еще <br /> нет или уже все проверены
                    </div>
                </div>
            );
        case 'deadline-expires':
            return 'Нет работ с истекающим сроком';
        case 'overdue':
            return 'Нет работ с просроченным сроком';

        default:
            return 'Нет работ для проверки';
    }
};

const TasksList: FC<ITasksList> = ({ tasks = [], filter }) => {
    const lgUp = useBreakpoint('lg', 'up');

    return (
        <>
            {!tasks.length ?
                <div className={style.empty_message}>{getEmptyMessage(filter)}</div>
                :
                <DataList className={style.data_list}>
                    {
                        lgUp && (
                            <Grid className={style.data_list__header}>
                                <Column>
                                    <DataListCell className={style.column_label}>Дисциплины</DataListCell>
                                </Column>
                                <Column>
                                    <DataListCell className={style.column_label}>Дата отправки</DataListCell>
                                </Column>
                            </Grid>
                        )
                    }
                    {
                        tasks.map((material, index) => {
                            return (
                                <GradesInTask key={index} material={material} filter={filter} />
                            );
                        })
                    }
                </DataList>
            }
        </>
    );
};

export default TasksList;
