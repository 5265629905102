import { ChangeEvent, FC, memo, useRef, useState } from 'react';

import { useOnClickOutside } from 'usehooks-ts';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { InputAutosize } from '@components/core/inputs/InputAutosize';
import { useIdHook } from '@hooks/useId.hook';

import style from './title.module.scss';

interface Title {
    elemId: string;
    editHandler: (id: string, title: string) => void;
    title: string;
    renderComponent: (propsText: string) => JSX.Element;
    inputClassName?: string;
    disabled?: boolean;
}

const Title: FC<Title> = ({ elemId, editHandler, title, renderComponent, inputClassName, disabled }) => {
    const id = useIdHook('check-btn');

    const [showEditInput, setShowEditInput] = useState(false);
    const [newTitle, setNewTitle] = useState<string>(title);

    const setTitleHandler = () => {
        if (!(newTitle.replace(/\s/g, '') == '')) {
            editHandler(elemId, newTitle);
        }

        setShowEditInput(!showEditInput);
    };

    const ref = useRef(null);

    const outsidePClick = () => {
        setTitleHandler();
        setShowEditInput(false);
    };

    useOnClickOutside(ref, outsidePClick);

    const sizeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.trim().length >= 2) {
            e.target.focus();
            e.target.setSelectionRange(e.target.value.length, e.target.value.length);
        }
    };

    const changeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        sizeInputHandler(e);
        setNewTitle(e.target.value);
    };

    return (
        <div className={style.wrapper}>
            {
                showEditInput ? (
                    <div ref={ref} className={style.input_wrapper}>

                        <InputAutosize
                            value={newTitle}

                            onChange={changeInputHandler}
                            onEnterKey={() => setTitleHandler()}
                            onEscapeKey={() => setShowEditInput(false)}
                        />

                    </div>
                ) : (
                    <div className={style.title_wrapper}>
                        {renderComponent(title)}
                        {!disabled && (
                            <ButtonIcon
                                className={style.edit_button}
                                typeIcon={'second-edit'}
                                onClick={() => setShowEditInput(!showEditInput)}
                            />
                        )}
                    </div>
                )
            }
        </div>
    );
};

export default memo(Title);
