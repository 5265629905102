import { FC } from 'react';

import { useChat } from '@components/Chat/context';
import IconUI from '@components/core/IconUI';
import { getCompanion } from '@helpers/chat';
import { useBreakpoint } from '@hooks/useBreakpoint';

import Contact from '../Contact';
import style from './Header.module.scss';

export interface IChatHeaderProps {
    onClose: () => void;
    onBack: () => void;
}

const Header:FC<IChatHeaderProps> = ({ onClose, onBack }) => {
    const { currentRoom } = useChat();
    const mdDown = useBreakpoint('md', 'down');

    if (!currentRoom) return null;

    return (
        <div className={style.header}>
            <div className={style.caption}>
                {mdDown && (
                    <div className={style.prev} onClick={onBack}>
                        <IconUI className={style.icon} typeIcon="prev" />
                    </div>
                )}
                <Contact uuid={currentRoom.uuid} user={getCompanion(currentRoom.users)!} isHeader  />
            </div>

            <div className={style.close} onClick={onClose}>
                <IconUI className={style.icon} typeIcon="close" />
            </div>
        </div>
    );
};

export default Header;
