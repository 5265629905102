import React, { FC, useEffect, useState } from 'react';

import cx from 'classnames';
import { useWizard } from 'react-use-wizard';

import Button from '@components/core/inputs/Button';
import Modal from '@components/Modal';
import SwipeDrawer from '@components/SwipeDrawer';
import { useAuthenticate } from '@hooks/useAuthenticate';
import { useBreakpoint } from '@hooks/useBreakpoint';

import style from './auth_confirm.module.scss';


interface AuthFinishProps {
    picture: string;
    setIsPaperFit: (isFit: boolean) => void;
}

export const AuthConfirm: FC<AuthFinishProps> = ({ picture, setIsPaperFit }) => {
    const { nextStep, previousStep } = useWizard();
    const smUp = useBreakpoint('sm', 'up');
    const { loadAuthenticationImage } = useAuthenticate(picture, nextStep);

    const [showConfirmAuthModal, setShowConfirmAuthModal] = useState(false);
    const [showConfirmAuthDrawer, setShowConfirmAuthDrawer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleModalOrSwiper = (state: boolean) => {
        if (smUp) {
            setShowConfirmAuthModal(state);
        } else {
            setShowConfirmAuthDrawer(state);
        }
    };

    const onAuthFinish = () => {
        toggleModalOrSwiper(true);
    };

    const onAuthConfirm = async () => {
        toggleModalOrSwiper(false);

        setIsLoading(true);
        await loadAuthenticationImage();
        setIsLoading(false);
    };

    const onAuthRetakePicture = () => {
        toggleModalOrSwiper(false);
        previousStep();
    };

    useEffect(() => {
        setIsPaperFit(smUp);

        return () => {
            setIsPaperFit(false);
        };
    }, [smUp]);

    if (!picture) previousStep();

    return (
        <>
            <div className={style.container}>
                <div className={style.photo}>
                    <img alt="Фото для аутентификации" src={picture} />
                </div>

                <Button
                    btnType="success"
                    onClick={onAuthFinish}
                    fullWidth={true}
                    disabled={isLoading}
                >
                    Завершить
                </Button>
            </div>
            <Modal
                onClose={() => setShowConfirmAuthModal(false)}
                isOpen={showConfirmAuthModal}
                title="Подтверждение аутентификации"
            >
                <p className={style.modal_content}>
                    Обратите внимание!
                    <br />
                    <br />
                    Администрация института оставляет за&nbsp;собой право аннулировать полученные результаты
                    экзаменов/зачетов в&nbsp;случае нарушения правил обучения или
                    предоставления заведомо ложных или нечитаемых сведений, подтверждающих личность.
                    <br />
                    <br />
                    Перед завершением аутентификации удостоверьтесь, что ваше фото
                    с&nbsp;паспортом отвечает всем указанным требованиям.
                </p>
                <div className={ cx(style.button_wrapper, style.button_wrapper_end) }>
                    <Button
                        onClick={onAuthRetakePicture}
                        btnType="secondary"
                    >
                        Переснять
                    </Button>
                    <Button onClick={onAuthConfirm}>
                        Подтвердить
                    </Button>
                </div>
            </Modal>
            <SwipeDrawer
                isOpen={showConfirmAuthDrawer}
                onClose={() => setShowConfirmAuthDrawer(false)}
                onOpen={() => setShowConfirmAuthDrawer(true)}
            >
                <p className={style.modal_content}>
                    Обратите внимание!
                    <br />
                    <br />
                    Администрация института оставляет за&nbsp;собой право аннулировать полученные результаты
                    экзаменов/зачетов в&nbsp;случае нарушения правил обучения или
                    предоставления заведомо ложных или нечитаемых сведений, подтверждающих личность.
                    <br />
                    <br />
                    Перед завершением аутентификации удостоверьтесь, что ваше фото
                    с&nbsp;паспортом отвечает всем указанным требованиям.
                </p>
                <div className={ cx(style.button_wrapper, style.button_wrapper_end) }>
                    <Button
                        onClick={onAuthRetakePicture}
                        btnType="secondary"
                        fullWidth={true}
                    >
                        Переснять
                    </Button>
                    <Button
                        onClick={onAuthConfirm}
                        fullWidth={true}
                    >
                        Подтвердить
                    </Button>
                </div>
            </SwipeDrawer>
        </>
    );
};

