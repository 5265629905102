import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';
import Webcam, { WebcamProps } from 'react-webcam';

import SelfieExample from '@assets/image/selfie-example.jpg';
import Button from '@components/core/inputs/Button';
import Loader from '@components/core/Loader';
import { selectApiUser } from '@store/features/userSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { AuthPersonaCameraError } from '../AuthPesonaSetupCamera/AuthPersonaCameraError';
import style from './auth_recommendation.module.scss';


interface AuthRecommendationProps {
    setAuthPhoto: (src: string) => void;
}

export const AuthRecommendation: FC<AuthRecommendationProps> = ({ setAuthPhoto }) => {
    const { uuidTest, uuidTask } = useParams();
    const { nextStep } = useWizard();
    const [ errorCamera, setErrorCamera ] = useState(false);
    const [ isLoading, setIsLoading] = useState(true);
    const [showCamera, setShowCamera] = useState(false);
    const [webCameraProps, setWebCameraProps] = useState<WebcamProps>();
    const [ picture, setPicture] =  useState('');

    const webcamRef = useRef<Webcam>(null);

    const userApi = useAppSelector(selectApiUser)?.uuid || '';

    const capture = useCallback(() => {
        if(!webcamRef.current) return;

        const imageSrc = webcamRef.current.getScreenshot() || '';
        setPicture(imageSrc);

    },[webcamRef]);

    const onUserMediaError = (error: string | DOMException) => setErrorCamera(true);
    const onUserMedia = (stream: MediaStream) => {
        if(stream.active) {
            setIsLoading(false);
        }
    };

    const toggleReload = () => {
        setErrorCamera(false);
    };

    const onNext = () => {
        if (!errorCamera && picture) {
            setAuthPhoto(picture);
            nextStep();
        }
    };

    const takePicture = (e: React.MouseEvent<HTMLButtonElement>) => {
        if(picture) {
            setPicture('');
        } else {
            capture();
        }
    };

    useEffect(() => {
        if (showCamera) {
            setWebCameraProps(
                {
                    audio: false,
                    height: '100%',
                    width: '100%',
                    screenshotFormat: 'image/jpeg',
                    onUserMediaError,
                    forceScreenshotSourceSize: true,
                    imageSmoothing: true,
                    mirrored: false,
                    onUserMedia,
                    screenshotQuality: 1
                }
            );
        }

    }, [showCamera]);

    return (
        <>
            {
                errorCamera ?
                    <AuthPersonaCameraError onClick={toggleReload} className={style.container} />
                    :
                    <div className={style.container}>
                        <h3 className={style.title}>Сделайте селфи с&nbsp;паспортом</h3>
                        <p className={style.description}>
                            Постарайтесь найти освещенное место&nbsp;&mdash; например,
                            встаньте лицом к&nbsp;окну и&nbsp;держите раскрытый паспорт рядом с&nbsp;собой
                        </p>

                        <p className={style.description}>
                            Примерно вот так должно получиться:
                        </p>

                        <div className={style.example}>
                            <img alt="Пример селфи" src={SelfieExample} />
                        </div>

                        <div className={style.button_wrapper}>
                            <Button
                                btnType="success"
                                onClick={() => setShowCamera(true)}
                                fullWidth={true}
                            >
                                Далее
                            </Button>
                        </div>
                    </div>
            }

            {
                showCamera && !errorCamera &&
                    <div className={cx(style.isCamera)}>
                        { (isLoading ) && <div className={style.loader}><Loader /></div> }

                        {
                            errorCamera
                                ? (<AuthPersonaCameraError onClick={toggleReload} />)
                                : <div className={style.innerCamera}>
                                    <Webcam ref={webcamRef} {...webCameraProps} />

                                    { picture && <div className={style.screenShoot}>
                                        <img src={picture} alt="Фото для аутентификации" />
                                    </div>}

                                    <div className={style.cameraUI}>
                                        {
                                            !picture ? (
                                                <Button
                                                    typeIcon="camera-dark"
                                                    btnType="white"
                                                    onClick={takePicture}
                                                    className={style.camera_btn}
                                                >
                                                    Сделать фото
                                                </Button>
                                            ) : (
                                                <div className={style.camera_btns_wrapper}>
                                                    <Button
                                                        typeIcon="camera-dark"
                                                        btnType="white"
                                                        onClick={takePicture}
                                                        className={style.camera_btn}
                                                    >
                                                        Переснять
                                                    </Button>
                                                    <Button
                                                        btnType="white"
                                                        onClick={onNext}
                                                        className={style.camera_btn}
                                                    >
                                                        Продолжить
                                                    </Button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                        }
                    </div>
            }
        </>
    );
};

