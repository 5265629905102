import React from 'react';

import { Outlet } from 'react-router-dom';

import UsersSidebar from '@views/methodist/Users/UsersSidebar';

import style from './users_list_layout.module.scss';


const UsersListLayout = () => {
    return (
        <div className={style.wrapper}>
            <UsersSidebar />
            <Outlet />
        </div>
    );
};

export default UsersListLayout;
