import { TStatementsTemplate } from '@type/editor';

export const template_codes: TStatementsTemplate[] = [
    { title: 'таблица', handler: '[table]', value: 'table_template_btn' },
    { title: 'дата начала', handler: '[date_start]', value: 'date_start_template_btn' },
    { title: 'дата конца', handler: '[date_end]', value: 'date_end_template_btn' },
    { title: 'имя группы', handler: '[group_name]', value: 'group_name_template_btn' },
    { title: 'имя преподавателя', handler: '[teacher_name]', value: 'teacher_name_template_btn' },
    { title: 'дата дедлайна', handler: '[deadline_date]', value: 'deadline_date_template_btn' },
    { title: 'имя дисциплины', handler: '[discipline_name]', value: 'discipline_name_template_btn' }
];

export const registerTemplatePlugin = (outerEditor: any) => {
    outerEditor.editorManager.PluginManager.add('template-plugin', function(editor: any) {

        template_codes.forEach(templateItem => {
            editor.ui.registry.addButton(templateItem.value, {
                text: templateItem.title,
                onAction: () => editor.insertContent(templateItem.handler)
            });
        });

    });
};