import React, { FC } from 'react';

import cx from 'classnames';

import { ReactComponent as ArrowSVG } from '@assets/icons/arrow-icon.svg';

import style from './paginationrows.module.scss';

interface IPaginatonProps {
    rowsPerPage?: number;
    isOpen?: boolean;
    isAll?: boolean;
}

export const PaginationRows : FC<IPaginatonProps> = ({ rowsPerPage, isOpen, isAll }) => {

    const countRows = isAll ? 'Все' : rowsPerPage;

    return  (<div className={style.switcher}>
        Показывать по: {countRows}
        <span className={cx(style.arrow, isOpen && style['arrow--active'])}>
            <ArrowSVG />
        </span>
    </div>);
};
