import { CloseTypes } from '@store/api/apiTypes';

import { inRange } from './numbers';

export type ScoreType = 'bad' | 'medium' | 'good' | 'excelent' | 'failed' | 'success';

export const getTypeScore = (score: number, closeType: CloseTypes): ScoreType | null => {
    if (closeType === CloseTypes.Exam || closeType === CloseTypes.AssessmentWithScore) {
        if (inRange(score, 0, 70)) {
            return 'bad';
        } else if (inRange(score, 70, 80)) {
            return 'medium';
        } else if (inRange(score, 80, 90)) {
            return 'good';
        } else if (inRange(score, 90, 100) || score === 100) {
            return 'excelent';
        }
    } else if (closeType === CloseTypes.Assessment) {
        if (inRange(score, 0, 60)) {
            return 'failed';
        } else if (inRange(score, 60, 100) || score === 100) {
            return 'success';
        }
    }
    return null;
};

export const declination = (number: number) => {
    if (number === 0) return '';

    const titles = ['балл', 'балла', 'баллов'];
    const cases = [2, 0, 1, 1, 1, 2];

    return number + ' ' + titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
};

export const getBorderScore = (closeType: CloseTypes) => {
    if (closeType === CloseTypes.Exam || closeType === CloseTypes.AssessmentWithScore) return 70;
    if (closeType === CloseTypes.Assessment) return 60;

    return 0;
};

export const getScoreCaption = (score: number | undefined = 0, scoreType: false | ScoreType | null) => {
    switch(scoreType) {
        case 'excelent':
            return `Отлично ${declination(score)}`;
        case 'success':
            return `Зачет ${declination(score)}`;
        case 'good':
            return `Хорошо ${declination(score)}`;
        case 'medium':
            return `Удовл. ${declination(score)}`;
        case 'failed':
            return `Незачет ${declination(score)}`;
        case 'bad':
            return 'Недостаточно баллов';

        default:
            return `${declination(score)}`;
    }
};
