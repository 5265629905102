import React, { FC } from 'react';

import cx from 'classnames';

import IconUI from '@components/core/IconUI';
import { selectUnReadedNotices, toggleNotify } from '@store/features/notifySlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import style from './NotifyButton.module.scss';

export interface INotifyButtonProps {
    className?: string;
}

export const NotifyButton: FC<INotifyButtonProps> = ({
    className
}) => {
    const dispatch = useAppDispatch();

    const noticesCount = useAppSelector(selectUnReadedNotices);

    const openNotify = () => {
        dispatch(toggleNotify(true));
    };

    return (
        <div
            className={cx(style.container, className)}
            onClick={openNotify}
        >
            <IconUI typeIcon={'notify-bar'} />
            {noticesCount > 0 && (
                <span className={style['user-notifications__counter']}>
                    {noticesCount}
                </span>
            )}
        </div>
    );
};
