import { useLocation } from 'react-router-dom';

import { Helmet } from '@components/Helmet';
import { EditEvent, EnumEventType } from '@store/api/apiTypes';
import { useCreateEventMutation, useEditEventMutation } from '@store/api/eventApi';
import { CreateEventFormData } from '@views/methodist/Events/CreateEvent/CreateBasicEvent/CreateEventForm/type';

import { NavigationEventState, useEditEventFormContext } from '../CreateEvent';
import EditActiveEventForm from './CreateEventForm';


const BasicActiveEvent = () => {
    const location = useLocation();
    const navigationState = location.state as NavigationEventState || null;

    const { formContext, teacherOptions, groupOptions, categoriesData } = useEditEventFormContext();
    const [editEvent] = useEditEventMutation();
    const [createEvent] = useCreateEventMutation();

    const getFormData = (formData: CreateEventFormData) => {
        const transformedEditData: EditEvent = {
            name: formData.name,
            disciplineUuid: formData.discipline || '',
            duration: (formData.hours || 0) * 60 + (formData.minutes || 0),
            files: formData.fileUpload ? formData.fileUpload : [],
            links: [formData.link || ''],
            linkToRecords: [formData.videoLink || ''],
            startEventAt: formData.date,
            type: EnumEventType.Webinar,
            eventUuid: navigationState?.currentEvent?.eventUuid || '',
            excludedGroups: formData.blockedGroups || [],
            teacherUuid: formData.teacher?.value || ''
        };
        switch (navigationState?.actionType) {
            case 'create':
                createEvent(transformedEditData);
                return;
            case 'duplicate':
                createEvent(transformedEditData);
                return;
            case 'edit':
                editEvent(transformedEditData);
                return;
        }
    };

    return (
        <>
            <Helmet title={`Мероприятия – Актуальные: ${formContext.name}`} />
            <EditActiveEventForm
                defaultValues={formContext}
                getFormData={getFormData}
                teacherOptions={teacherOptions}
                groupOptions={groupOptions}
                categoriesData={categoriesData}
            />
        </>
    );
};

export default BasicActiveEvent;
