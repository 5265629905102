import React, { FC } from 'react';

import cx from 'classnames';

import { Column } from '@components/core/grid';

import style from './DataListCell.module.scss';

export interface IDataListCellProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    header?: boolean;
    last?: boolean;
    noPadding?: boolean;
    className?: string;
}

export const DataListCell: FC<IDataListCellProps> = ({ children, header, last, noPadding, className, ...props }) => {

    const classConfig = cx(
        style.cell,
        className,
        last && style.last,
        header && style.headerCell,
        noPadding && style.noPaddings
    );

    return (<Column className={classConfig} {...props}>
        {
            children
        }
    </Column>);
};
