import React, { FC, MouseEvent } from 'react';

import { ReactComponent as BucketEditSVG } from '@assets/icons/bucket-edit.svg';
import { ReactComponent as EditSVG } from '@assets/icons/edit.svg';
import { ReactComponent as LookSVG } from '@assets/icons/sauron-eye.svg';
import { IControl } from '@components/core/InlineControls/type';

import style from './inlinecontrols.module.scss';

export const InlineControls: FC<IControl> = ({ onLook, onDelete, onEdit }) => {

    const handleDelete = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onDelete && onDelete(event);
    };

    const handleEdit = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onEdit && onEdit(event);
    };

    return  (
        <div className={style.container}>

            { onLook && <div className={style.control} onClick={onLook} role="presentation">
                <LookSVG />
            </div> }

            { onEdit && <div className={style.control} onClick={handleEdit} role="presentation">
                <EditSVG />
            </div> }

            { onDelete && <div className={style.control} onClick={handleDelete} role="presentation">
                <BucketEditSVG />
            </div> }
        </div>);
};
