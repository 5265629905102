import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { TUploadFile } from '@components/core/inputs/FileUpload/type';
import { LinkUI } from '@components/core/LinkUI';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { getBase64 } from '@helpers/base64';
import { isStorageFile } from '@helpers/typeCheck';
import { fileTypes, IStorageFile, UpdateTaskData } from '@store/api/apiTypes';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useUploadFileMutation } from '@store/api/storageApi';
import { useGetTaskByIdQuery, useUpdateTaskMutation } from '@store/api/taskApi';
import TaskForm from '@views/methodist/Materials/Course/EditCourse/Structure/TaskForm';
import { TaskFormData } from '@views/methodist/Materials/Course/EditCourse/Structure/TaskForm/type';

import style from './EditTask.module.scss';


const EditTask = () => {
    const { uuidTask } = useParams();
    const navigate = useNavigate();
    const { data: taskData, isLoading: isLoadingTask, isSuccess: isSuccessTask, isError: isGetError, isFetching } = useGetTaskByIdQuery(uuidTask ? uuidTask : '');
    const [uploadFile] = useUploadFileMutation();
    const [fetchUpdateTask, { isSuccess }] = useUpdateTaskMutation();
    const groupsTree = useGetGroupsTreeQuery('');

    const [defaultValuesForm, setDefaultValuesForm] = useState<TaskFormData>({
        name: '',
        content: '',
        files: [],
        maxRating: '',
        accessLimitation: false,
        needPlagiarismCheck: false,
        minUniquenessPercent: 0,
        blockedGroups: []
    });

    useEffect(() => {
        if (!isLoadingTask && taskData) {
            setDefaultValuesForm({
                name: taskData.name,
                content: taskData.content,
                files: taskData.files,
                maxRating: String(taskData.maxRating),
                accessLimitation: taskData.blockedGroups.length > 0,
                needPlagiarismCheck: taskData.needPlagiarismCheck,
                minUniquenessPercent: taskData.minUniquenessPercent,
                blockedGroups: taskData.blockedGroups
            });
        }
    }, [isSuccessTask, isFetching]);

    const submitFormHandler = async (formData: TaskFormData) => {

        const uuidsFile: string[] = [];
        const uploadFileHandler = async (file: IStorageFile | TUploadFile) => {
            if (file) {
                if (!isStorageFile(file)) {
                    const b64 = await getBase64(file);
                    const uploadedFile = await uploadFile({
                        file: b64 as string,
                        meta: {
                            filename: file.name,
                            type: fileTypes.Public
                        }
                    }).unwrap();
                    uuidsFile.push(uploadedFile.uuid);
                } else {
                    uuidsFile.push(file.uuid);
                }
            }
        };

        if (formData.files) {
            for await (const file of formData.files) {
                await uploadFileHandler(file);
            }
        }

        const transformedData: UpdateTaskData = {
            uuid: uuidTask!,
            name: formData.name,
            content: formData.content,
            maxRating: Number(formData.maxRating),
            uuidsFile: uuidsFile,
            blockedGroups: formData.blockedGroups || [],
            needPlagiarismCheck: !!formData.needPlagiarismCheck,
            minUniquenessPercent: formData?.minUniquenessPercent ? formData.minUniquenessPercent : 0
        };
        fetchUpdateTask(transformedData);
    };

    useEffect(() => {
        if (isGetError) {
            navigate('./../../..');
        }
    }, [isGetError]);

    useEffect(() => {
        if (isSuccess)
            navigate('./../../..');
    }, [isSuccess]);


    if (groupsTree.isFetching || isLoadingTask) return <NewLoader />;

    return (
        <Main>
            <div className={style.title_wrapper}>
                <LinkUI
                    to={'./../../..'}
                    typeIcon="arrowLink"
                />
                <h1>Редактировать контрольную работу</h1>
            </div>
            <TaskForm
                submitFormHandler={submitFormHandler}
                defaultValues={defaultValuesForm}
                groups={groupsTree.data}
            />
        </Main>
    );
};

export default EditTask;
