import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notices } from '@type/notifications';

import type { RootState } from '@store/store';

interface INotifyState {
    items: Notices[];
    isOpen: boolean;
}

const initialState: INotifyState = {
    items: [],
    isOpen: false
};

export const notifySlice = createSlice({
    initialState,
    name: 'notifySlice',
    reducers: {
        initItems: (state, action: PayloadAction<Notices[]>) => {
            state.items = action.payload;
        },
        toggleNotify: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        }
    }
});

export default notifySlice.reducer;

export const notifyIsOpen = (state: RootState) => state.notifySlice.isOpen;
export const notifyItems = (state: RootState) => state.notifySlice.items;
export const selectUnReadedNotices = createSelector(
    [notifyItems],
    (notices) => notices.reduce((acc, item) => {
        if (!item.readed) return acc + 1;
        return acc;
    }, 0)
);

export const {
    initItems,
    toggleNotify
} = notifySlice.actions;
