import React, { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import { OptionType } from '@components/core/inputs/SelectStyled/type';
import Modal from '@components/Modal';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useThrottle from '@hooks/useThrottle';
import { useGetPortfolioSectionsListQuery, usePortfolioFilesChangeMutation } from '@store/api/portfolioApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { PortfolioFile } from '@type/portfolio';
import style from '@views/student/StudentProfile/Portfolio/CreateFileForm/createFileForm.module.scss';

interface IProps {
    isOpen: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
    file: PortfolioFile;
}

export const createFileFormSchema = yup.object({
    typeId: yup.object().required('Укажите тип файла!'),
    uName: yup.string().required('Поле обязательно для заполнения!')
});

const EditFileModal: FC<IProps> = ({ isOpen, onClose, file }) => {
    const { userId } = useParams();
    const user = useAppSelector(selectApiUser);
    const [fetchChangeFile, { isSuccess: isSuccessChangeFile }] = usePortfolioFilesChangeMutation();
    const { data: portfolioSectionsTypeList, isLoading: isLoadingPortfolioSections } = useGetPortfolioSectionsListQuery();

    const methods = useForm({
        resolver: yupResolver(createFileFormSchema),
        mode: 'onBlur',
        defaultValues: {
            uName: '',
            typeId: []
        }
    });

    const { control, handleSubmit, setValue } = methods;

    useEffect(() => {
        if (isSuccessChangeFile)
            onClose(false);
    }, [isSuccessChangeFile]);

    const sectionTypeOptions = useMemo(() => {
        return portfolioSectionsTypeList?.reduce((result: any[], section) => {
            if (section.active && section.uuid !== '2') {
                result.push({ label: section.typeName, value: section.uuid });
            }
            return result;
        }, []);
    }, [portfolioSectionsTypeList]);

    useEffect(() => {
        setValue('uName', file.uName);
        setValue('typeId', sectionTypeOptions?.find(target => target?.value === file.typeId));
    }, [file, sectionTypeOptions]);

    const onSubmit = async (formData: any) => {
        const transformedFormData: Partial<PortfolioFile> = {
            uuid: file.uuid,
            uName: formData.uName,
            typeId: formData.typeId.value,
            userId: file.userId,
            active: true
        };
        await fetchChangeFile(transformedFormData);
    };

    const throttledSubmit = useThrottle(onSubmit, 1000);


    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            title="Редактировать файл"
        >
            <form>
                <FormProvider {...methods}>
                    <div className={style.input_wrapper}>
                        <InputControl
                            label="Название"
                            name="uName"
                            placeholder="Укажите название"
                        />
                        <p>Тип файла</p>
                        {sectionTypeOptions?.length && (
                            <SelectStyled
                                name="typeId"
                                control={control}
                                options={sectionTypeOptions as OptionType[]}
                                isMulti={false}
                                isSearchable={false}
                                placeholder="Укажите тип файла"
                            />
                        )
                        }
                    </div>
                    <div className={style.button_wrapper}>
                        <Button
                            onClick={() => onClose(false)}
                            btnType={'secondary'}
                        >
                            Отмена
                        </Button>
                        <Button onClick={handleSubmit(throttledSubmit)}>Сохранить</Button>
                    </div>
                </FormProvider>
            </form>
        </Modal>

    );
};

export default EditFileModal;
