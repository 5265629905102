import { getYear } from 'date-fns';
import { setHours, setMinutes } from 'date-fns/esm';

export const months = [
    {
        value: 0,
        name: 'Январь',
        shortName: 'ЯНВ'
    },
    {
        value: 1,
        name: 'Февраль',
        shortName: 'ФЕВ'
    },
    {
        value: 2,
        name: 'Март',
        shortName: 'МАР'
    },
    {
        value: 3,
        name: 'Апрель',
        shortName: 'АПР'
    },
    {
        value: 4,
        name: 'Май',
        shortName: 'МАЙ'
    },
    {
        value: 5,
        name: 'Июнь',
        shortName: 'ИЮН'
    },
    {
        value: 6,
        name: 'Июль',
        shortName: 'ИЮЛ'
    },
    {
        value: 7,
        name: 'Август',
        shortName: 'АВГ'
    },
    {
        value: 8,
        name: 'Сентябрь',
        shortName: 'СЕН'
    },
    {
        value: 9,
        name: 'Октябрь',
        shortName: 'ОКТ'
    },
    {
        value: 10,
        name: 'Ноябрь',
        shortName: 'НОЯ'
    },
    {
        value: 11,
        name: 'Декабрь',
        shortName: 'ДЕК'
    }
];

export const generateYears = (offset = -1) => {
    const  resultArr = [];
    const  multiplier = 10;
    let    YEAR = 0;
    const  CURRENT_YEAR = getYear(new Date());

    YEAR = CURRENT_YEAR + (multiplier * offset);
    resultArr.push(YEAR);

    for (let i = 1; i < multiplier; i++) {
        resultArr.push(YEAR + i);
    }

    return resultArr;
};

export const addTimeToDate = (date: Date, time: string) => {
    const [hours, minutes] = time.split(':').map(item => parseInt(item));

    date = setHours(date, hours);
    date = setMinutes(date, minutes);

    return date;
};
