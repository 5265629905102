import { toast } from 'react-toastify';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { DisciplineMaterials, Section, SectionsListData, UpdatePositionsMaterialsListData, Uuid } from '@store/api/apiTypes';
import { ScoringMaterials } from '@type/grades';

import { emptyBaseApi } from './emptyBaseApi';

export const materialsApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({

        getDisciplineMaterialsList: build.query<DisciplineMaterials, Uuid>({
            query: (uuidDiscipline: string) => `disciplines/materials/list/${uuidDiscipline}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список материалов');
                }
            },
            transformResponse: (result: { materials: DisciplineMaterials }) => {
                return result.materials;
            },
            providesTags: (result, error, arg) => [{ type: 'Material', id: `List_${arg}` }]
        }),

        getMaterialsSectionsList: build.query<Section[], string>({
            queryFn: async (
                uuidDiscipline,
                _queryApi,
                _extraOptions,
                baseQuery) => {

                const sectionsList = await baseQuery(`disciplines/sections/list/${uuidDiscipline}`);


                if (sectionsList.error)
                    return { error: sectionsList.error as FetchBaseQueryError };

                const sectionsListData = sectionsList.data as SectionsListData;
                const sectionsListIds = sectionsListData.sections.reduce((ids: string[], item) => {
                    ids.push(item.uuid);
                    return ids;
                }, []);


                const materialsList = await Promise.all(sectionsListIds.map(uuid => baseQuery(`disciplines/sections/find/${uuid}`)));

                const mergedResponse = [...materialsList.map(result => result.data)];


                //TODO - fix that
                // for(const sect of mergedResponse)
                // {

                //     // @ts-ignore
                //     for(const material of sect.materials)
                //     {
                //         console.log('---material:'+JSON.stringify(material,null,2));

                //         if(material.type=='Test')
                //         {
                //             material.uuidParent=material.sectionUuid;
                //         }
                //     }
                // }

                // const mergedErrors = [...materialsList.filter(result => result.error != null).map(result => result.error)];
                //
                // if (mergedErrors.length > 0)
                //     return { error: mergedErrors };

                return { data: mergedResponse as Section[] };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список секций');
                }
            },
            providesTags: (result) => ['SectionList']
        }),

        deleteMaterialById: build.mutation<void, { uuid: string, uuidParent: string }>({
            query(data) {
                return {
                    url: 'disciplines/materials/delete',
                    method: 'POST',
                    body: {
                        uuids: [
                            data.uuid
                        ]
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Материал удален',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => ['SectionList', { type: 'Material', id: `List_${arg.uuidParent}` }]
        }),

        updatePositionsMaterialsList: build.mutation<void, UpdatePositionsMaterialsListData>({
            query(data) {
                return {
                    url: 'disciplines/materials/positions',
                    method: 'POST',
                    body: {
                        positions: data.positions
                    }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Позиция материала сохранена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => ['SectionList', { type: 'Material', id: `List_${arg.parentUuid}` }]
        }),

        getDisciplineScoringMaterialsList: build.query<ScoringMaterials, Uuid>({
            query: (uuidDiscipline: string) => `disciplines/scored/materials/${uuidDiscipline}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error: any) {   
                    if (Object.prototype.hasOwnProperty.call(error, 'error')) {
                        if (Object.prototype.hasOwnProperty.call(error.error, 'status')) {
                            if (error.error.status !== 404) {
                                toast.error('Не смогли получить список материалов');
                            } 
                        }
                    }
                }
            },
            transformResponse: (result: { materials: ScoringMaterials }) => {
                return result.materials;
            },
            providesTags: (result, error, arg) => [{ type: 'Material', id: `Scoring_${arg}` }]
        })
    })
});

export const {
    useGetDisciplineMaterialsListQuery,
    useDeleteMaterialByIdMutation,
    useGetMaterialsSectionsListQuery,
    useGetDisciplineScoringMaterialsListQuery,
    useUpdatePositionsMaterialsListMutation
} = materialsApi;
