import React, { FC } from 'react';

import classNames from 'classnames/bind';

import Checkbox from '@components/core/inputs/Checkbox';

import style from './MassEdit.module.scss';

export interface IMassEditProps {
    mode?: 'fixed' | 'sticky';
    children?: React.ReactElement;
    selectCount?: number;
    onSelectAll?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isShow: boolean;
}

const cx = classNames.bind(style);

export const MassEdit: FC<IMassEditProps> = ({ children, selectCount = 0, onSelectAll, isShow }) => {

    const checkboxLabel = `Выбрано: ${selectCount}`;

    if (!isShow) return null;

    return (
        <div className={cx(style.container)}>
            <div className={style.wrapperContainer}>
                <div className={style.selectContainer}>
                    <div className={style.checkbox}>
                        <Checkbox checked={!!selectCount} theme={'dark'} label={checkboxLabel} onChange={onSelectAll} />
                    </div>
                </div>

                <div className={style.children}>
                    {children}
                </div>
            </div>
        </div>
    );
};
