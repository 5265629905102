import { IArchiveNoticeResponse, IGetArchiveResponse, IGetUsersArchiveRequest, IGetUsersArchiveResponse, Uuid } from './apiTypes';
import { emptyBaseApi } from './emptyBaseApi';

export const archiveApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getArchiveNotice: build.query<IArchiveNoticeResponse, void>({
            query: () => {
                return {
                    url: 'auth/archive/notice',
                    method: 'GET'
                };
            }
        }),
        getArchive: build.query<IGetArchiveResponse, Uuid>({
            query: (uuid) => {
                return {
                    url: 'auth/archive/get',
                    method: 'POST',
                    body: { uuidUser: uuid }
                };
            }
        }),
        getUsersArchive: build.query<IGetUsersArchiveResponse, IGetUsersArchiveRequest | void>({
            query: (data) => {
                return {
                    url: 'auth/archive/users',
                    method: 'POST',
                    body: { ...data }
                };
            }
        }),
        setArchiveLoaded: build.query<object, Uuid>({
            query: (uuid) => {
                return {
                    url: 'auth/archive/loaded',
                    method: 'POST',
                    body: { uuid: uuid }
                };
            }
        })
    })
});

export const {
    useLazyGetArchiveQuery,
    useLazySetArchiveLoadedQuery,
    useLazyGetUsersArchiveQuery,
    useGetUsersArchiveQuery,
    useGetArchiveNoticeQuery
} = archiveApi;
