import { FC, useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import { FileUploadAreaControl } from '@components/core/inputs/FileUpload';
import InputControl from '@components/core/inputs/InputControl';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import { OptionType } from '@components/core/inputs/SelectStyled/type';
import { maxSizeUploadFile } from '@constants/constants';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useThrottle from '@hooks/useThrottle';
import { useGetPortfolioSectionsListQuery } from '@store/api/portfolioApi';

import style from './createFileForm.module.scss';

interface ICreateFileModal {
    onSubmitFormHandler: (formData: any) => void;
    onCloseModal: (isOpen: boolean) => void;
    defaultValues?: any;
}

export const createFileFormSchema = yup.object({
    typeId: yup.object().required('Укажите тип файла!'),
    uName: yup.string().required('Поле обязательно для заполнения!'),
    file: yup.mixed().label('File').required('Поле обязательно для заполнения!')
});

const CreateFileForm: FC<ICreateFileModal> = ({
    onSubmitFormHandler,
    onCloseModal,
    defaultValues
}) => {
    const { data: portfolioSectionsTypeList, isLoading: isLoadingPortfolioSections } = useGetPortfolioSectionsListQuery(undefined, {
        selectFromResult: (data) => data ?? []
    });

    const methods = useForm({
        resolver: yupResolver(createFileFormSchema),
        mode: 'onBlur'
    });

    const { control, handleSubmit, setValue } = methods;

    useEffect(() => {
        if (defaultValues) {
            setValue('uName', defaultValues.uName);
            setValue('file', defaultValues.file);
        }
    }, [defaultValues]);


    const sectionTypeOptions = useMemo(() => {
        return portfolioSectionsTypeList?.reduce((result: any[], section) => {
            if (section.active && section.uuid !== '2') {
                result.push({ label: section.typeName, value: section.uuid });
            }
            return result;
        }, []);
    }, [portfolioSectionsTypeList]);

    const onSubmit = async (formData: any) => {
        onSubmitFormHandler(formData);
    };

    const throttledSubmit = useThrottle(onSubmit, 1000);

    return (
        <form>
            <FormProvider {...methods}>
                <div className={style.input_wrapper}>
                    <InputControl
                        label="Название"
                        name="uName"
                        placeholder="Укажите название"
                    />
                    <p>Тип файла</p>
                    <SelectStyled
                        name="typeId"
                        control={control}
                        options={sectionTypeOptions as OptionType[]}
                        isMulti={false}
                        isSearchable={false}
                        placeholder="Укажите тип файла"
                    />
                    <FileUploadAreaControl
                        withoutCheckControl
                        name="file"
                        control={control}
                        maxSizeFile={maxSizeUploadFile}
                        className={style.upload}
                    />
                </div>
                <div className={style.button_wrapper}>
                    <Button
                        onClick={() => onCloseModal(false)}
                        btnType={'secondary'}
                    >
                        Отмена
                    </Button>
                    <Button onClick={handleSubmit(throttledSubmit)}>Сохранить</Button>
                </div>
            </FormProvider>
        </form>
    );
};

export default CreateFileForm;

