import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { DNDWrapper } from '@components/core/DNDWrapper';
import TopicCard from '@components/core/DNDWrapper/DNDChildren/TopicCard';
import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import { LinkUI } from '@components/core/LinkUI';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { maxPosition } from '@helpers/findMaxPosition';
import { CreateLectureTopicRequest, PositionsItemsList } from '@store/api/apiTypes';
import { useCreateLectureTopicMutation, useGetLectureTopicsByBlockQuery, useUpdateLectureTopicsPositionsMutation } from '@store/api/lecturesApi';
import { Role } from '@store/Roles';

import style from './lectures.module.scss';

const Lectures = () => {
    const navigate = useNavigate();
    const { uuidLectureBlock } = useParams();

    const topicsList = useGetLectureTopicsByBlockQuery(uuidLectureBlock as string);

    const [createTopic] = useCreateLectureTopicMutation();

    const [fetchUpdateTopicPositions] = useUpdateLectureTopicsPositionsMutation();

    const addTopicHandler = () => {
        let newPosition = 1;

        if (topicsList.isSuccess) {
            newPosition = maxPosition(topicsList.data) + 1;
        }

        const createParams: CreateLectureTopicRequest={
            position: newPosition,
            name: 'Новая тема',
            uuidBlock: uuidLectureBlock as string
        };

        createTopic(createParams);
    };

    const updateTopicsPositionsHandler = (reorderTopicsList: PositionsItemsList) => {
        fetchUpdateTopicPositions({ positions: reorderTopicsList, parentUuid: uuidLectureBlock as string });
    };

    useEffect(() => {
        if (topicsList.isError) {
            navigate('./../../..');
        }
    }, [topicsList.isFetching]);

    if (topicsList.isLoading) return <NewLoader />;

    return (
        <Main>
            <div className={style.title_wrapper}>
                <LinkUI
                    to={'./../../..'}
                    typeIcon={'arrowLink'}
                />
                <h1>Блок лекций</h1>
            </div>
            {
                topicsList.isSuccess && topicsList.data.length > 0 ? (
                    <>
                        <RoleCheck roles={Role.Methodist}>
                            <Button
                                typeIcon={'add'}
                                onClick={addTopicHandler}
                                className={style.add_button}
                            >
                                Добавить тему
                            </Button>
                        </RoleCheck>

                        <div className={style.topic_cards_wrapper}>
                            <Grid>
                                <Column size={10}>
                                    {checkRole(Role.Methodist) ? (
                                        <DNDWrapper
                                            items={topicsList.data}
                                            onOrder={updateTopicsPositionsHandler}
                                            isDefaultDraggableHandler={true}>
                                            {
                                                topicsList.data.map((topic) => (
                                                    <TopicCard
                                                        key={topic.uuid}
                                                        topic={topic}
                                                    />
                                                ))
                                            }
                                        </DNDWrapper>
                                    ) : (
                                        <div>
                                            {topicsList.data.map((topic) => (
                                                <TopicCard
                                                    key={topic.uuid}
                                                    topic={topic}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </Column>
                            </Grid>
                        </div>
                    </>
                ) : (
                    <div className={style.create_section}>
                        <div className={style.create_section__background} />
                        <h3 className={style.create_section__title}>Список тем пуст</h3>
                        <p className={style.create_section__text}>Добавьте новую тему лекции</p>
                        <RoleCheck roles={Role.Methodist}>
                            <Button
                                typeIcon={'add'}
                                onClick={addTopicHandler}
                            >
                                Добавить тему
                            </Button>
                        </RoleCheck>
                    </div>
                )
            }
        </Main>
    );
};

export default Lectures;
