import { useEffect, useState } from 'react';

import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { LinkUI } from '@components/core/LinkUI';
import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { IProgrammContext } from '@layouts/Methodist/StudyProgrammesLayout/StudyProgrammesLayout';
import { useUpdateStudyProgrammeSectionMutation } from '@store/api/programmesApi';
import { IUpdateSectionData } from '@type/studyProgrammes';

import SectionForm, { IProgrammSectionFormDataType } from '../SectionForm/SectionForm';
import style from './editSection.module.scss';


const EditSection = () => {
    const navigate = useNavigate();
    const { uuidSection } = useParams();
    const [updateSection, { isLoading, isSuccess }] = useUpdateStudyProgrammeSectionMutation();
    const { programnInfo: { sections, uuid, uuidGroup } } = useOutletContext<IProgrammContext>();

    const [defaultValuesForm, setDefaultValuesForm] = useState<IProgrammSectionFormDataType>({
        name: '',
        description: ''
    });

    const actualSection = sections.find(section => section.uuid === uuidSection);

    useEffect(() => {
        if (actualSection) {
            setDefaultValuesForm({
                name: actualSection?.name,
                description: actualSection.description
            });
        }
    }, []);

    const submitFormHandler = (formData: IProgrammSectionFormDataType) => {
        const updateSectionData: IUpdateSectionData = {
            uuid: uuidSection || '',
            name: formData.name,
            description: formData.description || '',
            position: actualSection?.position || 1,
            uuidProgramme: uuid || '',
            uuidGroup: uuidGroup
        };

        updateSection(updateSectionData);
    };

    useEffect(() => {
        if (isSuccess) {
            navigate('./../..');
        }
    }, [isSuccess]);

    if (isLoading) return <NewLoader />;

    return (
        <Main>
            <div className={style.title_wrapper}>
                <LinkUI
                    to='./../..'
                    typeIcon='arrowLink'
                />
                <h1>Редактировать раздел</h1>
            </div>
            <SectionForm
                submitFormHandler={submitFormHandler}
                defaultValues={defaultValuesForm}
            />
        </Main>
    );
};

export default EditSection;
