import { createContext, useContext } from 'react';

export interface CalendarContext {
    search: string;
    setSearch: (value: string) => void;
    groups: string[];
    setGroups: (value: string[]) => void;
    discipline: string;
    setDiscipline: (value: string) => void;
    teacher: string;
    setTeacher: (value: string) => void;
}

export const calendarContext = createContext<CalendarContext | null>(null);

export function useCalendar() {
    const ctx = useContext(calendarContext);
    if (ctx === null) throw new Error('calendarContext must be defined');
    return ctx;
}
