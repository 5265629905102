import { toast } from 'react-toastify';

import { CreateLinkData, Link, UpdateLinkData } from '@store/api/apiTypes';

import { emptyBaseApi } from './emptyBaseApi';

export const linkApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getLinkBiId: build.query<Link, string>({
            query: (uuidLink: string) => `disciplines/materials/link/find/${uuidLink}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить ссылку');
                }
            },
            transformResponse: (result: Link) => {
                return result;
            }
        }),
        createLink: build.mutation<Link, CreateLinkData>({
            query(data) {
                return {
                    url: 'disciplines/materials/link/create',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Ссылка создана',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: (result, error, arg) => ['SectionList', { type: 'Material', id: `List_${arg.uuidParent}` }]
        }),
        updateLink: build.mutation<Link, UpdateLinkData>({
            query(data) {
                return {
                    url: `disciplines/materials/link/update/${data.uuid}`,
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Ссылка обновлена',
                    error: 'Ошибка сохранения!'
                });
            },
            invalidatesTags: ['SectionList']
        })
    })
});

export const { useGetLinkBiIdQuery, useCreateLinkMutation, useUpdateLinkMutation } = linkApi;
