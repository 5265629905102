import { FC, useEffect, useState } from 'react';

import cx from 'classnames';

import { ISelectPickDate } from '../DPCalendar/DPCalendar';
import { generateYears, months } from '../helper';
import style from './DPMonthYear.module.scss';

export type TDateTime = {
    value: number;
    name: string;
    shortName: string;
}

export interface IDPMonthYearProps {
    onChange: (data: ISelectPickDate) => void;
    offset?: number;
    range?: boolean;
    isLastCalendar?: boolean;
}

export const DPMonthYear: FC<IDPMonthYearProps> = ({ onChange, offset, range = false, isLastCalendar = false }) => {

    const [years, setYears] = useState<number[]>(() => generateYears(0));
    const [isMonth, setIsMonth] = useState(false);

    const onClickYear = (year: number) => {
        onChange({ year });
        setIsMonth(true);
    };

    const onClickMonth = (month: TDateTime) => {
        onChange({ month });
    };

    useEffect(() => {
        setYears(generateYears(offset));
    }, [offset]);

    return <div className={cx(style.container, range && style['container--range'], isLastCalendar && style['container--last'])}>
        { !isMonth ? (<div className={style.itemsContainer}>
            {
                years.map((year, i) => {
                    return (<div
                        key={i}
                        onClick={() => onClickYear(year)}
                        role="presentation"
                        className={style.items}>{year}</div>);
                })
            }
        </div>) : (<div className={style.itemsContainer}>
            {
                months?.map((month, i) => {
                    return (<div
                        key={i}
                        onClick={() => onClickMonth(month)}
                        role="presentation"
                        className={style.items}>{month.name}</div>);
                })
            }
        </div>)
        }
    </div>;
};
