import { FC } from 'react';

import { getTypeScore } from '@helpers/scores';
import { CloseTypesWithLocale, CloseTypes } from '@store/api/apiTypes';

import { Score } from './Score';
import style from './UserGradeCell.module.scss';


export interface IUserGradeCellProps {
    score: number;
    closeType: CloseTypes
}

export const UserGradeCell: FC<IUserGradeCellProps> = ({
    score = 0,
    closeType
}) => {

    const renderScore = () => {
        const type = getTypeScore(score, closeType);
        if (!type) return null;
        return <Score type={type} score={score} />;
    };

    return <div className={style.cell}>
        <div className={style.score}>{renderScore()}</div>
    </div>;
};
