import React, { useState, PropsWithChildren } from 'react';

import cx from 'classnames';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-icon.svg';
import Dropdown from '@components/core/inputs/Select/Dropdown';
import { useTreeSelect } from '@components/core/inputs/Select/NewTreeSelect/context';

import { Classes, RenderValue } from '../../NewTreeSelect';
import style from './SelectRoot.module.scss';



interface SelectRootProps {
    error?: string;
    placeholder?: string;
    renderLabel?: (option: RenderValue | RenderValue[]) => React.ReactNode;
    classes?: Classes;
    disabled?: boolean;
}

export const SelectRoot: React.FC<PropsWithChildren<SelectRootProps>> = ({
    error,
    children,
    placeholder,
    renderLabel,
    classes,
    disabled
}) => {
    const { isOpen, onOpen, onClose, isMulti, value, optionsMapWithEndChildrens } = useTreeSelect();
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

    const renderLabelDefault = () => {
        if (!isMulti) {
            if (!value) return placeholder || 'Выберите';
            return renderLabel ? renderLabel(optionsMapWithEndChildrens[value]) : optionsMapWithEndChildrens[value]?.label;
        }
        if (!value.length) return placeholder || 'Выберите';
        return renderLabel ? renderLabel(value.map(el => optionsMapWithEndChildrens[el])) : `Выбрано: ${value.length}`;
    };

    const handleClickOpen = () => {
        if (!disabled) onOpen();
    };

    return (
        <>
            <div
                className={cx(
                    style.root,
                    classes?.input,
                    {
                        [style['root--error']]: error,
                        [style['root--active']]: isOpen,
                        [style['root--disabled']]: disabled
                    }
                )}
                tabIndex={0}
                role="button"
                onClick={handleClickOpen}
                ref={setReferenceElement}
            >
                <span className={style.option_name}>
                    {renderLabelDefault()}
                </span>
                <ArrowIcon className={cx(
                    style.icon,
                    classes?.inputIcon,
                    {
                        [style['icon--active']]: isOpen
                    }
                )} />
            </div>

            <Dropdown referenceElement={referenceElement} showDropdown={isOpen} onClose={onClose}>
                {children}
            </Dropdown>
        </>
    );
};
