import { FC, ReactNode } from 'react';

import classNames from 'classnames/bind';

import style from './sidebar.module.scss';

const cx = classNames.bind(style);

interface IProps {
    className?: string;
    children?: ReactNode;
}

const Sidebar: FC<IProps> = ({ className, children }) => {
    return (
        <aside className={cx('root', className)}>
            <div className={style.fixed}>{children}</div>
        </aside>
    );
};

export default Sidebar;
