import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

interface UseSearch {
    search: string;
    value: string;
    onChange: (value: string) => void;
    clear: () => void;
}

export const useSearch = (): UseSearch => {
    const [search, setSearch] = useState('');
    const [value, setValue] = useState('');

    const onChange = (inputValue: string) => {
        setValue(inputValue);
    };

    const onDebounceFilter = (debounceValue: string) => {
        setSearch(debounceValue);
    };

    const onFilterDebounce = useCallback(_.debounce(onDebounceFilter, 300), []);

    useEffect(() => {
        onFilterDebounce(value);
    }, [value]);

    const clear = () => {
        setValue('');
        setSearch('');
    };

    return {
        search,
        value: value,
        onChange,
        clear
    };
};
