import React, { FC } from 'react';

import classNames from 'classnames/bind';

import { useIdHook } from '@hooks/useId.hook';

import style from './radio.module.scss';

const cx = classNames.bind(style);

interface IProps {
    group?: string;
    disabled?: boolean;
    children?: React.ReactNode;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    className?: string;
    inputCentered?: boolean;
}

const Radio: FC<IProps> = ({ group, onChange, disabled, children, className, checked = false, inputCentered = false }) => {
    const id = useIdHook('radio-');

    return (
        <div className={cx('root', disabled && 'root--disabled', className)}>
            <input
                id={id}
                name={group}
                type="radio"
                className={cx('root__input')}
                onChange={onChange}
                checked={checked}
            />

            <label
                htmlFor={id}
                className={cx('root__label', children && 'root--labeled', inputCentered && 'root--centered')}
            >
                {children}
            </label>
        </div>
    );
};

export default Radio;
