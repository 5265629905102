import { FC, ReactNode } from 'react';

import cx from 'classnames';

import style from './input_adornment.module.scss';

interface IProps {
    className?: string;
    position: 'start' | 'end';
    children: ReactNode;
}

const InputAdornment: FC<IProps> = ({ className, children, position }) => {
    return (
        <div
            className={cx(
                className,
                position === 'start' ? style.start : style.end
            )}
        >
            {children}
        </div>
    );
};

export default InputAdornment;
