import React, { FC } from 'react';

import { User }  from '@type/users';
import TeachingStaffItem from '@views/student/StudentCourse/TeachingStaffCard/TeachingStaffItem';

import style from './TeachingStaffCard.module.scss';

interface ITeachingStaffCard {
    metodists: User[];
    teachers: User[];
    secondTeachers: User[];
}

const TeachingStaffCard: FC<ITeachingStaffCard> = ({
    metodists,
    teachers,
    secondTeachers
}) => {

    return (
        <div>
            <h3 className={style.title}>Преподаватели и методисты</h3>
            <ul>
                {secondTeachers.map(teacher => (
                    <TeachingStaffItem key={teacher.uuid} user={teacher} isDouble />
                ))}
                
                {
                    teachers.map(teacher => (<TeachingStaffItem key={teacher.uuid} user={teacher} />))
                }

                {metodists.map(metodist => (
                    <TeachingStaffItem key={metodist.uuid} user={metodist} />
                ))}
            </ul>
        </div>
    );
};

export default TeachingStaffCard;

