import React, { FC, MouseEventHandler } from 'react';

import { useNavigate } from 'react-router-dom';
import { DateTime } from 'ts-luxon';

import FilesListMenu from '@components/core/styledWrappers/FilesListMenu';
import VideoPreview from '@components/core/VideoPreview';
import { VideoLectureResponce } from '@store/api/apiTypes';

import style from './VideoCard.module.scss';

interface IVideoCard {
    video: VideoLectureResponce
}

const VideoCard: FC<IVideoCard> = ({ video }) => {
    const navigate = useNavigate();

    const convertTime = (date: string) => {
        const dt = DateTime.fromSQL(date.replace(/ \+0000 UTC/, ''));
        return dt.toFormat('dd.LL.yyyy');
    };

    const navigateVideoPageHandler: MouseEventHandler<HTMLDivElement> = (e) => {
        if (e.defaultPrevented) return;
        e.preventDefault();
        navigate(`video-lecture/${video.uuidParent}/${video.uuid}`);
    };

    return (
        <div className={style.wrapper} onClick={navigateVideoPageHandler}>
            <div className={style.wrapper}>
                <VideoPreview className={style.video} link={video.linkFile} videoName={video.name} />
                <p className={style.name}>
                    {video.name}
                </p>
                {
                    video.files.length ? <FilesListMenu files={video.files} /> : null
                }
                <span className={style.date}>{convertTime(video.updatedAt)}</span>
            </div>
        </div>
    );
};

export default VideoCard;
