import { useEffect } from 'react';

import { formatISO } from 'date-fns';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button/Button';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import { DPControl } from '@components/DatePicker/DPControl';
import { MassEdit } from '@components/MassEdit';
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseTypes, SessionTypes, TSubscribeMassValues } from '@store/api/apiTypes';
import {  resetMassValues, selectedItemsCountSelector, setMassValues } from '@store/features/subscribeSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import style from './subscribe_mass_edit.module.scss';

const subscribeItemSchema = yup.object({
    session: yup.object(),
    closeType: yup.object(),
    startDate: yup.string().required()
});

const SubscribeMassEdit = () => {
    const selectedItemsCount = useAppSelector(selectedItemsCountSelector);

    const methods = useForm({
        defaultValues: {
            session: { label: 'Семестр 1', value: SessionTypes.Semester1 },
            closeType: { label: 'Зачет', value: CloseTypes.Assessment },
            startDate: ''
        },
        resolver: yupResolver(subscribeItemSchema)
    });

    const { control, setValue, getValues } = methods;

    const dispatch = useAppDispatch();

    const submitMassValues = () => {
        const formValues = getValues();

        const dummyData: TSubscribeMassValues = {
            session: formValues.session.value,
            closeType: formValues.closeType.value,
            startDate: formValues.startDate
        };

        dispatch(setMassValues(dummyData));
    };

    const onDateSubmit = (date: string) => {
        setValue('startDate', date, { shouldValidate: true });
    };

    useEffect(() => {
        return () => {
            dispatch(resetMassValues());
        };
    }, []);

    return (<>
        <MassEdit isShow={selectedItemsCount > 1} selectCount={selectedItemsCount} onSelectAll={() => dispatch(resetMassValues())}>
            <div className={style.massContainer}>

                <div className={style.massControls}>
                    <div className={style.massControl}>
                        <SelectStyled
                            styled={{ height: 34 }}
                            name="session"
                            control={control}
                            theme='dark'
                            options={[
                                { label: 'Семестр 1', value: SessionTypes.Semester1 },
                                { label: 'Семестр 2', value: SessionTypes.Semester2 },
                                { label: 'Семестр 3', value: SessionTypes.Semester3 },
                                { label: 'Семестр 4', value: SessionTypes.Semester4 },
                                { label: 'Семестр 5', value: SessionTypes.Semester5 },
                                { label: 'Семестр 6', value: SessionTypes.Semester6 },
                                { label: 'Семестр 7', value: SessionTypes.Semester7 },
                                { label: 'Семестр 8', value: SessionTypes.Semester8 }
                            ]}
                        />
                    </div>

                    <div className={style.massControl}>
                        <SelectStyled
                            styled={{ width: '100%' }}
                            name="closeType"
                            theme='dark'
                            control={control}
                            options={[
                                { label: 'Зачет', value: CloseTypes.Assessment },
                                { label: 'Зачет с оценкой', value: CloseTypes.AssessmentWithScore },
                                { label: 'Экзамен', value: CloseTypes.Exam },
                                { label: 'Без оценки', value: CloseTypes.NoMark }
                            ]}
                        />
                    </div>

                    <div className={style.massControl}>
                        <DPControl isButton onSubmit={(date: string) => onDateSubmit(date)} />
                    </div>

                </div>

                <div className={style.actionBtn}>
                    <Button btnType='yellow' size='tiny' onClick={submitMassValues}>Выполнить</Button>
                </div>
            </div>
        </MassEdit>
    </>);
};

export default SubscribeMassEdit;
