import React, { FC } from 'react';

import classNames from 'classnames/bind';

import style from './label.module.scss';

const cx = classNames.bind(style);

interface IProps {
    caption?: string;
    color?: 'green' | 'red' | 'gray';
}

export const Label: FC<IProps> = ({ caption, color = 'gray' }) => {
    return <div className={cx(style.label, color && `color_${color}`)}>{caption}</div>;
};
