import { toast } from 'react-toastify';

import { ICategory } from './apiTypes';
import { emptyBaseApi } from './emptyBaseApi';

export const categoriesApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getCategoriesList: build.query<ICategory[], string>({
            query: (uuidParent) => `disciplines/categories/find/${uuidParent}`,
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не смогли получить список категорий');
                }
            },
            transformResponse: (result: { categoryTrees: ICategory[] }) => {
                return result.categoryTrees;
            }
        })
    })
});

export const { useGetCategoriesListQuery } = categoriesApi;
