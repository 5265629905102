import { Mutex } from 'async-mutex';
import { v4 as uuidv4 } from 'uuid';

import { vSid } from '@constants/localStorage';
import { broadcastChannelUpdating } from '@helpers/broadcastChannelUpdating';
import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from '@reduxjs/toolkit/query';

import { logOut, logOutAndBlock } from './features/authSlice';
import { AppDispatch, RootState } from './store';

export let sessionId = sessionStorage.getItem(vSid);
const mutex = new Mutex();
const mainUrl = process.env.REACT_APP_API_ENDPONT;
const chatUrl = process.env.REACT_APP_CHAT_API_ENDPONT;

type TUpdateTokensParams = {
    token: string | null;
    refresh: string | null;
    duuid: string | null;
    dispatch: AppDispatch;
};

export const updateTokens = async ({
    token,
    refresh,
    duuid,
    dispatch
}: TUpdateTokensParams) => {
    // try to get a new token
    try {
        if (!mutex.isLocked() && !broadcastChannelUpdating.isLocked) {
            const release = await mutex.acquire();
            broadcastChannelUpdating.updateToken(
                `${mainUrl}auth/refresh` as string,
                token,
                refresh,
                duuid,
                sessionId
            );
            await broadcastChannelUpdating.waitForUnlock();
            if (broadcastChannelUpdating.status !== 'success') {
                console.log('🚀 ~ file: baseQuery.ts:101 ~ logOut:');
                dispatch(logOut());
            }
            release();
            return broadcastChannelUpdating.status === 'success';
        } else {
            await mutex.waitForUnlock();
            return true;
        }

    } catch(err) {
        console.log('Произошла ошибка, которую мы не обработали :)',err);
    }
};


const baseQuery = (type: 'main' | 'chat') => {
    const baseUrl = type === 'main' ? mainUrl : chatUrl;
    return fetchBaseQuery({
        baseUrl: baseUrl || '/',
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).authSlice.accessToken;

            headers.set('Session', `${sessionId}`);

            if (token) {
                headers.set('Authorization', `${token}`);
            }

            return headers;
        }
    });
};

export const baseQueryWithReauth: (type: 'main' | 'chat') => BaseQueryFn< string | FetchArgs, unknown, FetchBaseQueryError > =
    (type) => async (args, api, extraOptions) => {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();

        if (!sessionId) {
            sessionId = uuidv4();
            sessionStorage.setItem(vSid, sessionId);
        }

        const queryHandler = baseQuery(type);

        let result = await queryHandler(args, api, extraOptions);
        // result.error.status === 'FETCH_ERROR' ||
        if (result.error)
            console.error('🚀 ~ file: baseQuery.ts ~ line 46 ~ baseQueryWithReauth= ~ result.error', result.error);

        if (result.error) {

            let errorStatus = result.error.status;

            if (result.error.status === 'PARSING_ERROR') {
                errorStatus = result.error.originalStatus;
            }

            // Пользователь заблокирован
            if (errorStatus === 409) {
                api.dispatch(logOutAndBlock());
            }

            // Токен протух
            if (errorStatus === 401) {
                const token = (api.getState() as RootState).authSlice.accessToken;
                const refresh = (api.getState() as RootState).authSlice.refreshToken;
                const duuid = (api.getState() as RootState).authSlice.deviceUuid;

                const isSuccess = await updateTokens({ token, refresh, duuid, dispatch: api.dispatch });

                if (isSuccess) {
                    result = await queryHandler(args, api, extraOptions);
                }
            }
        }
        return result;
    };
