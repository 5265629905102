import { useEffect, useState } from 'react';

import { Outlet, useOutletContext } from 'react-router-dom';

import { NewLoader } from '@components/core/NewLoader';
import { skipToken } from '@reduxjs/toolkit/query';
import { CheckedTasksForTeacher, GetTaskDataResponse, IDiscipline } from '@store/api/apiTypes';
import { useGetDisciplinesTeacherQuery } from '@store/api/disciplinesApi';
import {
    useGetCheckedTasksForTeacherQuery,
    useGetExpiredTasksForTeacherQuery,
    useGetExpiringTasksForTeacherQuery,
    useGetUncheckedTasksForTeacherQuery
} from '@store/api/gradesApi';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { User } from '@type/users';

import style from './TeacherMain.module.scss';

export const useGradesContext = () => {
    return useOutletContext<ITeacherGradesContext>();
};

interface ITeacherGrade {
    uuid: string;
    uuidAttempt: string;
    checked: boolean;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    grade: number;
    studentComment: string;
    teacherReview: string;
}

interface Grade {
    gradeCreatedAt:string;
    nameUser:string;
    uuidUser:string;
}

export interface IGradesArray {
    uuidDiscipline: string;
    nameDiscipline: string;
    uuidTask: string;
    nameTask: string;
    grades: Grade[];
    total: number;
}

export interface IGradesUsers extends User {
    material: {
        task: GetTaskDataResponse;
        gradeInfo: ITeacherGrade;
    }
}

export interface ITransformedGradesUsers extends User {
    material: {
        task: GetTaskDataResponse;
        grades: {
            [key: string]: ITeacherGrade;
        }
    }
}

export interface ITransformedGradesData {
    [key: string]: {
        uuidDiscipline: string;
        disciplineName: string;
        uuidTask: string;
        taskName: string;
        users: {
            [key: string]: ITransformedGradesUsers;
        };
    }
}

export interface ITeacherGradesContext {
    workOnVerifie: {
        allWorks: IGradesArray[];
        deadlineExpiresWorks: IGradesArray[];
        overdueWorks: IGradesArray[];
    };
    itemsInMainTabCount: string[];
    uuidsDiscipline:string[];
    checkedTasksForTeacher: CheckedTasksForTeacher;
    disciplines: ITeacherDisciplines;
    substituteDisciplines: IGradesArray[];
    workOnVerifieTabsCountAll: string[];
    workOnVerifieIds: string[]
}

export interface ITeacherDiscipline {
    disciplineUuid: string;
    disciplineName: string;
    worksCount: number;
    doubles: User[];
}

export interface ITeacherDisciplines {
    [key: string]: ITeacherDiscipline;
}

export const deadlineExpiresDaysGap = { days: 12 };
export const deadlineOverdueDaysGap = { days: 14 };

const calculateCount = (works?: IGradesArray[]) => {
    return works?.reduce((acc, work) => {
        acc += work.total;
        return acc;
    }, 0) ?? 0;
};

const TeacherMain = () => {
    const disciplinesData = useGetDisciplinesTeacherQuery();
    const userAccount = useAppSelector(selectApiUser);

    const shouldSkip = Boolean(disciplinesData.data?.length);

    const uuidsDiscipline = disciplinesData.data?.length ? [...disciplinesData.data.reduce((uuids: string[], descipline: IDiscipline) => {
        uuids.push(descipline.uuid);
        return uuids;
    }, [])] : [];

    const uncheckedTasksForTeacher = useGetUncheckedTasksForTeacherQuery(shouldSkip ? { uuidsDiscipline } : skipToken);
    const checkedTasksForTeacher = useGetCheckedTasksForTeacherQuery(shouldSkip ? { uuidsDiscipline, limit: 1, offset: 0 } : skipToken);
    const expiringTasksForTeacher = useGetExpiringTasksForTeacherQuery(shouldSkip ? { uuidsDiscipline } : skipToken);
    const expiredTasksForTeacher = useGetExpiredTasksForTeacherQuery(shouldSkip ? { uuidsDiscipline } : skipToken);

    const [workOnVerifie, setWorkOnVerifie] = useState<IGradesArray[]>([]);
    const [disciplines, setDisciplines] = useState<ITeacherDisciplines>({});

    const [substituteDisciplines, setUnderstudyDisciplines] = useState<IGradesArray[]>();

    const [deadlineExpiresWorks, setDeadlineExpiresWorks] = useState<IGradesArray[]>([]);

    const [overdueWorks, setOverdueWorks] = useState<IGradesArray[]>([]);

    const [workOnVerifieIds, setWorkOnVerifieIds] = useState<string[]>([]);

    const prepareDisciplines = () => {
        let transformDisciplines: ITeacherDisciplines = {};

        if (disciplinesData.isSuccess && uncheckedTasksForTeacher.isSuccess) {
            transformDisciplines = disciplinesData.data.reduce((result,  discipline) => {
                return {
                    ...result,
                    [discipline.uuid]: {
                        disciplineUuid: discipline.uuid,
                        disciplineName: discipline.name,
                        doubles: discipline.doubles.length ? discipline.doubles : [],
                        worksCount: 0
                    }

                };
            }, {} as ITeacherDisciplines);

            uncheckedTasksForTeacher.data.tasks.forEach((task)=>{
                transformDisciplines[task.uuidDiscipline].worksCount = task.total;
            });

            setDisciplines(transformDisciplines);
        }
    };

    useEffect(()=>{
        const doubles = [];
        for(const discipline of Object.values(disciplines)){
            if(discipline.doubles.filter(target => target.uuid === userAccount?.uuid).length){
                doubles.push(discipline);
            }
        }

        const userAccountTasks = [];
        const doubleTasks = [];

        if(!uncheckedTasksForTeacher.data) return;
        for(const task of uncheckedTasksForTeacher.data.tasks){
            if(doubles.findIndex(discipline => discipline.disciplineUuid === task.uuidDiscipline) === -1){
                userAccountTasks.push(task);
            }else{
                doubleTasks.push(task);
            }
        }

        setUnderstudyDisciplines(doubleTasks);
        setWorkOnVerifie(userAccountTasks);
    },[disciplines]);


    useEffect(() => {
        if (!uncheckedTasksForTeacher.isFetching && !disciplinesData.isFetching && uncheckedTasksForTeacher.isSuccess && disciplinesData.isSuccess) {
            prepareDisciplines();
        }
    }, [uncheckedTasksForTeacher.isFetching, uncheckedTasksForTeacher.isSuccess, disciplinesData.isSuccess, disciplinesData.isFetching]);

    useEffect(() => {
        if (!expiringTasksForTeacher.isFetching && expiringTasksForTeacher.isSuccess) {
            setDeadlineExpiresWorks(expiringTasksForTeacher.data.tasks);
        }
    }, [expiringTasksForTeacher.isFetching, expiringTasksForTeacher.isSuccess]);

    useEffect(() => {
        if (!expiredTasksForTeacher.isFetching && expiredTasksForTeacher.isSuccess) {
            setOverdueWorks(expiredTasksForTeacher.data.tasks);
        }
    }, [expiredTasksForTeacher.isFetching, expiredTasksForTeacher.isSuccess]);

    useEffect(() => {
        const tasksIds: string[] = [];
        workOnVerifie?.forEach(material => {
            material.grades.forEach(grade => {
                tasksIds.push(`${grade.uuidUser}/${material.uuidTask}`);
            });
        });

        setWorkOnVerifieIds(tasksIds);
    }, [workOnVerifie]);

    if (uncheckedTasksForTeacher.isFetching || checkedTasksForTeacher.isFetching) return <NewLoader />;

    const uncheckedWorksCount = calculateCount(uncheckedTasksForTeacher?.data?.tasks);
    const substituteWorksCount = calculateCount(substituteDisciplines);
    const deadlineExpiresWorksCount = calculateCount(deadlineExpiresWorks);
    const overdueWorksCount = calculateCount(overdueWorks);

    return (
        <div className={style.main}>
            <Outlet context={{
                itemsInMainTabCount: [
                    `${uncheckedWorksCount - substituteWorksCount}`,
                    `${checkedTasksForTeacher.data?.total ?? 0}`,
                    `${substituteWorksCount}`,
                    `${uuidsDiscipline.length}`
                ],

                workOnVerifie: { allWorks: workOnVerifie, deadlineExpiresWorks, overdueWorks },
                workOnVerifieTabsCountAll: ['', `${deadlineExpiresWorksCount}`, `${overdueWorksCount}`],

                uuidsDiscipline,
                disciplines,
                substituteDisciplines,
                workOnVerifieIds
            }} />
        </div>
    );
};

export default TeacherMain;
