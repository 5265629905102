import React from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '@components/core/inputs/Button';

import style from './EmptyLecture.module.scss';

const EmptyLecture = () => {
    const navigate = useNavigate();

    const goBackHandle = () => {
        navigate('./../..');
    };

    return (
        <div className={style.section}>
            <div className={style.section__background_img} />
            <h3>Лекций пока нет</h3>
            <p className={style.section__text}>
                Лекции еще не загружены, но возможно в будущем они появятся.
            </p>
            <Button onClick={goBackHandle}>Вернуться к курсу</Button>
        </div>
    );
};

export default EmptyLecture;
