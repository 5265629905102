import { FC, useState } from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import IconUI from '@components/core/IconUI';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import DeleteItemModal from '@components/methodist/DeleteItemModal';
import { RoleCheck } from '@components/RoleCheck';
import { EnumMaterialsType, MaterialsItem } from '@store/api/apiTypes';
import { useUpdateFileMutation } from '@store/api/fileApi';
import { useUpdateLinkMutation } from '@store/api/linkApi';
import { useDeleteMaterialByIdMutation } from '@store/api/materialsApi';
import { Role } from '@store/Roles';
import EditFileModal from '@views/methodist/Materials/Course/EditFileModal';
import EditLinkModal from '@views/methodist/Materials/Course/EditLinkModal';
import { MaterialIconRender } from '@views/methodist/Materials/Course/Materials/MaterialsListItem/type';

import style from './MaterialsListItem.module.scss';

interface IMaterialsListItem {
    material: MaterialsItem;
    className?: string
}

const MaterialsListItem: FC<IMaterialsListItem> = ({ material, className }) => {
    const navigate = useNavigate();

    const [isOpenEditLinkModal, setIsOpenEditLinkModal] = useState<boolean>(false);
    const [materialsData, setMaterialsData] = useState<MaterialsItem | any>();

    const [isOpenEditFileModal, setIsOpenEditFileModal] = useState<boolean>(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentMaterial, setCurrentMaterial] = useState<MaterialsItem>({} as MaterialsItem);
    const [updateLink] = useUpdateLinkMutation();
    const [updateFile] = useUpdateFileMutation();

    const [deleteMaterial] = useDeleteMaterialByIdMutation();

    const editMaterialHandler = (materialData: MaterialsItem) => {
        switch (materialData.type) {
            case EnumMaterialsType.Link:
                setIsOpenEditLinkModal(true);
                setMaterialsData({ name: materialData.name, link: materialData.link, uuid: materialData.uuid });
                return;
            case EnumMaterialsType.File:
                setIsOpenEditFileModal(true);
                setMaterialsData(materialData);
                return;
            case EnumMaterialsType.Page:
                navigate(`edit-page/${materialData.uuid}`);
                return;
            default:
                return;
        }
    };

    const getMaterialUuid = (materialData: MaterialsItem) => {
        if (materialData.type === EnumMaterialsType.Test) return materialData.sectionUuid;
        return materialData.uuidParent;
    };

    const deleteMaterialHandler = async (materialData: MaterialsItem) => {
        const parentUuid = getMaterialUuid(materialData);

        if (parentUuid) {
            await deleteMaterial({ uuid: materialData.uuid, uuidParent: parentUuid });
            setShowDeleteModal(false);
        } 
        
        if (!parentUuid) {
            toast.error('Не удалось удалить материал');
        }
    };

    const icon: MaterialIconRender = {
        [EnumMaterialsType.Link]: <IconUI typeIcon="link" className={style.list_item__icon} />,
        [EnumMaterialsType.File]: <IconUI typeIcon="fileDoc" className={style.list_item__icon} />,
        [EnumMaterialsType.Page]: <IconUI typeIcon="page" className={style.list_item__icon} />
    };

    return (
        <>
            <li className={cx(style.list_item, className)}>
                <div className={style.list_item__title}>
                    {/*// @ts-ignore*/}
                    {icon[material.type]}
                    <p>{material.name}</p>
                </div>
                <RoleCheck roles={Role.Methodist}>
                    <div className={style.list_item__button_wrapper}>
                        <ButtonIcon
                            className={style.list_item__button}
                            typeIcon="edit"
                            onClick={() => editMaterialHandler(material)}
                        />
                        <ButtonIcon
                            className={style.list_item__button}
                            typeIcon="basket"
                            onClick={() => {
                                setShowDeleteModal(true);
                                setCurrentMaterial(material);
                            }}
                        />
                    </div>
                </RoleCheck>
            </li>
            {
                isOpenEditLinkModal &&
                <EditLinkModal
                    isOpen={isOpenEditLinkModal}
                    onClose={setIsOpenEditLinkModal}
                    linkData={materialsData}
                    updateLink={updateLink}
                />

            }
            {
                isOpenEditFileModal &&
                <EditFileModal
                    isOpen={isOpenEditFileModal}
                    onClose={setIsOpenEditFileModal}
                    materialFile={materialsData}
                    updateFile={updateFile}
                />
            }
            {
                showDeleteModal &&
                <DeleteItemModal
                    isOpen={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    onDelete={() => deleteMaterialHandler(currentMaterial as MaterialsItem)}
                    itemName={currentMaterial?.name as string}
                    itemType="material"
                />
            }
        </>
    );
};

export default MaterialsListItem;
