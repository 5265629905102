import { useEffect } from 'react';

import { Navigate, Outlet, useOutlet, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { NewLoader } from '@components/core/NewLoader';
import Main from '@components/Main';
import { skipToken } from '@reduxjs/toolkit/query';
import { IDiscipline, TestWithQuestions } from '@store/api/apiTypes';
import { useGetDisciplineByIdQuery } from '@store/api/disciplinesApi';
import { useGetTestByIdQuery } from '@store/api/testsApi';

interface UseTest {
    testData: TestWithQuestions;
    discipine: IDiscipline;
}

export const useTest = () => {
    return useOutletContext<UseTest>();
};

const StudentTest = () => {
    const outlet = useOutlet();
    const { uuidDiscipline, uuidTest } = useParams();

    const disciplineDataQuery = useGetDisciplineByIdQuery(uuidDiscipline || skipToken);
    disciplineDataQuery.data;

    const getTestQuery = useGetTestByIdQuery(uuidTest ? uuidTest : skipToken);

    // Если в тесте нет вопросов, уведомляем об этом
    useEffect(() => {
        if (getTestQuery.isSuccess && !getTestQuery.data.questions.length) {
            toast.error('Произошла ошибка. В тесте нет вопросов. Обратитесь в поддержку');
        }
    }, [getTestQuery.isFetching, getTestQuery.isSuccess]);

    if (!(getTestQuery.isSuccess && disciplineDataQuery.isSuccess)) return <NewLoader />;

    return (
        <Main>
            {
                outlet ? <Outlet context={{ testData: getTestQuery.data, discipine: disciplineDataQuery.data }} /> : <Navigate to={'/education'} />
            }
        </Main>
    );
};

export default StudentTest;
