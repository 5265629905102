import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-right-small.svg';
import IconUI from '@components/core/IconUI';
import { LinkUI } from '@components/core/LinkUI';
import { checkRole } from '@components/RoleCheck';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Role } from '@store/Roles';


import { ITest } from '../type';
import style from './Test.module.scss';


const Test: FC<ITest> = ({ test }) => {
    const lgDown = useBreakpoint('lg', 'down');
    const navigate = useNavigate();

    const { uuid, name, maximumRating } = test;

    const handleClickCard = () => {
        if (lgDown) {
            navigate(`test/${uuid}`);
        }
    };

    //ToDo: Test url
    return (
        <div className={style.card} onClick={handleClickCard}>
            <div className={style.title_wrapper}>
                <div><IconUI typeIcon={'taskTst'} /></div>
                <div className={style.wrapper}>
                    {lgDown ? <h3>{name}</h3> : (
                        <LinkUI
                            to={`test/${uuid}`}
                            className={style.link}
                            disabled={!checkRole(Role.Student)}
                        >
                            {name}
                        </LinkUI>
                    )}
                    <span className={style.maximum_score}>Максимум {maximumRating} баллов</span>
                </div>

            </div>
            {lgDown && <ArrowIcon />}
        </div>
    );
};

export default Test;
