import cx from 'classnames';

import { Column, Grid } from '@components/core/grid';
import Main from '@components/Main';
import GoBackLink from '@components/student/GoBackLink/GoBackLink';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useNavigationHistory } from '@hooks/useNavigationHistory';

import ProgrammSectionsList from './ProgrammSectionsList';
import style from './StudentStudyProgramm.module.scss';

const StudentStudyProgramm = () => {
    const lgDown = useBreakpoint('lg', 'down');
    const mdDown = useBreakpoint('md', 'down');

    const { toForBack } = useNavigationHistory('/education');

    const getMainColumnSize = () => {
        if (mdDown) return 12;
        if (lgDown) return 8;
        return 9;
    };

    return (
        <Main className={style.main} title='Учебные материалы'>
            <GoBackLink to={toForBack}>
                Назад
            </GoBackLink>

            <h1 className={style.title}>Учебные материалы</h1>

            <Grid className={style.content_wrapper} gridTemplateColumns="repeat(12, 1fr)" columnGap={!mdDown ? '32px' : 0}>
                <Column size={getMainColumnSize()}>
                    <ProgrammSectionsList className={cx(style.section_list)} />
                </Column>
            </Grid>
        </Main>
    );
};

export default StudentStudyProgramm;