import { FC } from 'react';

import cx from 'classnames';

import Button from '@components/core/inputs/Button';
import { MassEdit } from '@components/MassEdit';
import { IMassEditProps } from '@components/MassEdit';

import style from './DataListMassEdit.module.scss';

interface IDataListMassEditProps extends IMassEditProps {
    onDeleteSelected?: () => void
}

export const DataListMassEdit:FC<IDataListMassEditProps> = ({
    onDeleteSelected,
    ...props
}) => {

    return (<>
        {
            props.isShow &&  <MassEdit {...props}>
                <div className={cx(style.buttons)}>
                    <Button size="small" btnType="dark" onClick={onDeleteSelected}>
                        Удалить
                    </Button>
                </div>
            </MassEdit>
        }
    </>);
};