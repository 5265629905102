import React, { FC, useEffect, useState } from 'react';

import { Controller, FormProvider, useForm, useFormState } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import { FileUploadButtonControl } from '@components/core/inputs/FileUpload/FileUploadButtonControl';
import { TUploadFile } from '@components/core/inputs/FileUpload/type';
import HelperText from '@components/core/inputs/HelperText/HelperText';
import InputControl from '@components/core/inputs/InputControl';
import VideoPreview from '@components/core/VideoPreview';
import Modal from '@components/Modal';
import { maxSizeUploadFile } from '@constants/constants';
import { getBase64 } from '@helpers/base64';
import { maxPosition } from '@helpers/findMaxPosition';
import { isStorageFile } from '@helpers/typeCheck';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { fileTypes, IStorageFile, VideoLectureCreateData, VideoLectureCreateResponse } from '@store/api/apiTypes';
import { useUploadFileMutation } from '@store/api/storageApi';
import { useCreateVideoLectureMutation, useGetVideoBlockByIdQuery, useUpdateVideoLectureMutation } from '@store/api/videosApi';
import UploadVideoBlock from '@views/methodist/Materials/Course/VideoLectures/UploadVideoBlock';

import { TVideoProps } from '../VideoCard/VideoCard';
import style from './add_video_modal.module.scss';

interface AddVideoModalProps {
    stateVideoModal: TVideoProps;
    setStateVideoModal: (stateModal: TVideoProps) => void;
}

const regMatch = /^(ftp|http|https):\/\/[^ "]+$/;
const schema = yup.object().shape({
    title: yup.string().required('Введите название'),
    files: yup.array(yup.mixed().label('File')).max(10, 'Максимальное кол-во файлов 10'),
    videoLink: yup.string()
        .required('Добавьте видео')
        .matches(regMatch, 'Данный ресурс не найден, убедитесь что вы вставили правильную ссылку')
});

const AddVideoModal: FC<AddVideoModalProps> = (
    {
        stateVideoModal,
        setStateVideoModal
    }) => {
    const { uuidVideoBlock } = useParams();
    const videoBlock = useGetVideoBlockByIdQuery(`${uuidVideoBlock}`);

    const { defaultValues, actionType = 'add', isOpen } = stateVideoModal;

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            type: 'file',
            title: defaultValues?.name,
            videoLink: defaultValues?.linkFile,
            files: defaultValues?.files
        }
    });

    const { handleSubmit, control, setValue, watch, trigger } = methods;
    const { errors } = useFormState({ control });

    const [createVideo, { isSuccess: isCreateSuc, isError: isCreateError }] = useCreateVideoLectureMutation();
    const [updateVideo, { isSuccess: isUpdateSuc, isError: isUpdateError }] = useUpdateVideoLectureMutation();

    const [uploadFile] = useUploadFileMutation();

    const [isOpenUploadVideoBlock, setIsOpenUploadVideoBlock] = useState(false);

    const createNewVideo = async (formData: any) => {

        const uuidsFile: string[] = [];
        const uploadFileHandler = async (file: IStorageFile | TUploadFile) => {
            if (!isStorageFile(file) && file) {
                const b64 = await getBase64(file as File);
                const uploadedFile = await uploadFile({
                    file: b64 as string,
                    meta: {
                        filename: file.name,
                        type: fileTypes.Public
                    }
                }).unwrap();
                uuidsFile.push(uploadedFile.uuid);
            }
        };

        if (formData.files) {
            for await (const file of formData.files) {
                await uploadFileHandler(file);
            }
        }

        let newPosition = 1;
        if (videoBlock.isSuccess) {
            newPosition = maxPosition(videoBlock.data.videoLectures) + 1;
        }

        const videoData: VideoLectureCreateData = {
            name: formData.title,
            uuidParent: uuidVideoBlock as string,
            linkFile: formData.videoLink,
            uuidsFile: uuidsFile,
            position: newPosition
        };
        createVideo(videoData);
        setStateVideoModal({ isOpen: false });
    };

    const updateOldVideo = async (formData: any) => {
        const uuidsFile: string[] = [];
        const uploadFileHandler = async (file: IStorageFile | TUploadFile) => {
            if (file) {
                if (!isStorageFile(file)) {
                    const b64 = await getBase64(file);
                    const uploadedFile = await uploadFile({
                        file: b64 as string,
                        meta: {
                            filename: file.name,
                            type: fileTypes.Public
                        }
                    }).unwrap();
                    uuidsFile.push(uploadedFile.uuid);
                } else {
                    uuidsFile.push(file.uuid);
                }
            }
        };

        if (formData.files) {
            for await (const file of formData.files) {
                await uploadFileHandler(file);
            }
        }

        const videoData: VideoLectureCreateResponse = {
            uuid: defaultValues?.uuid ?? '',
            name: formData.title,
            uuidParent: defaultValues?.uuidParent ?? '',
            linkFile: formData.videoLink as string,
            uuidsFile: uuidsFile
        };

        updateVideo(videoData);
        setStateVideoModal({ isOpen: false });
    };


    useEffect(() => {
        if (isCreateSuc) {
            setStateVideoModal({ isOpen: false });
        }

        if (isCreateError) {
            alert('Ошибка создания видео');
        }

    }, [isCreateSuc, isCreateError]);

    useEffect(() => {
        if (isUpdateSuc) {
            setStateVideoModal({ isOpen: false });
        }

        if (isUpdateError) {
            alert('Ошибка сохранения видео');
        }

    }, [isUpdateSuc, isUpdateError]);

    useEffect(() => {
        if (defaultValues?.linkFile) {
            setIsOpenUploadVideoBlock(true);
        }
    }, []);


    const onSubmit = async (formData: any) => {
        switch (actionType) {
            case 'edit':
                updateOldVideo(formData);
                break;

            case 'add':
                createNewVideo(formData);
                break;

            default:
                break;
        }
    };

    const setVideoLinkHandler = async (link: string) => {
        setValue('videoLink', link);
        const result = await trigger('videoLink');
        if (result) setIsOpenUploadVideoBlock(true);
    };

    useEffect(() => {
        // проверка импортируемых из мудла видео ссылок
        if (defaultValues?.linkFile) {
            trigger('videoLink').then(result => {
                if (!result) setIsOpenUploadVideoBlock(false);
            });
        }
    }, [defaultValues, trigger]);

    const watchVideoLink = watch('videoLink');
    const watchVideoName = watch('title');

    const titleModal = actionType === 'add' ? 'Добавить видео' : 'Редактировать видео';
    return (
        <Modal
            onClose={() => setStateVideoModal({ isOpen: false })}
            isOpen={isOpen}
            title={titleModal}
            className={style.modal}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormProvider {...methods}>
                    <div className={style.content_wrapper}>
                        <InputControl
                            label="Название"
                            name="title"
                            placeholder="Введите название"
                            classNameWrapper={style.input}
                        />
                        <div className={style.preview_video_wrapper}>
                            {
                                (watchVideoLink && isOpenUploadVideoBlock) ? (
                                    <>
                                        <VideoPreview
                                            className={style.preview_video}
                                            link={watchVideoLink}
                                            setPlay={true}
                                            videoName={watchVideoName ?? ''}
                                        />
                                        <div className={style.preview_button_wrapper}>
                                            <ButtonIcon
                                                typeIcon="edit"
                                                onClick={() => setIsOpenUploadVideoBlock(false)}
                                                className={style.preview_button}
                                            />
                                            <ButtonIcon
                                                typeIcon="basket"
                                                onClick={() => setValue('videoLink', '')}
                                                className={style.preview_button}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <Controller
                                            render={({ field: { value } }) => (
                                                <UploadVideoBlock
                                                    setVideoLinkHandler={setVideoLinkHandler}
                                                    defaultValue={value}
                                                    error={!!errors?.videoLink}
                                                />
                                            )}
                                            name="videoLink"
                                            control={control}
                                        />
                                        {errors?.videoLink && <HelperText type="error" message={errors?.videoLink?.message} />}
                                    </>

                                )
                            }
                        </div>
                        <div>
                            <p style={{ marginBottom: '6px' }}>Прикрепленный файл</p>
                            <FileUploadButtonControl
                                name="files"
                                withoutCheckControl
                                control={control}
                                className={style.file_upload_btn}
                                isMulti={true}
                                maxSizeFile={maxSizeUploadFile}
                            />
                        </div>
                    </div>
                    <div className={style.button_wrapper}>
                        <Button
                            onClick={() => setStateVideoModal({ isOpen: false })}
                            btnType="secondary"
                        >
                            Отмена
                        </Button>
                        <Button type="submit">Сохранить</Button>
                    </div>
                </FormProvider>
            </form>
        </Modal>
    );
};

export default AddVideoModal;
