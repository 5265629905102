import React from 'react';

import cx from 'classnames';

import { DPControl } from '@components/DatePicker/DPControl';
import style from '@views/methodist/Users/UsersGroups/EditGroup/TermsModal/TermsCalendarPage/TermsCalendarPage.module.scss';

import styles from './PeriodStep.module.scss';

interface PeriodStepProps {
    setStartDate: (value: string) => void;
    setEndDate: (value: string) => void;
    defaultStartDate?: string;
    defaultEndDate?: string;
    error?: string;
}

export const PeriodStep: React.FC<PeriodStepProps> = ({ setStartDate, setEndDate, defaultStartDate, defaultEndDate, error }) => {
    return (
        <div className={styles.container}>
            <div className={cx(style.container, styles.row)}>
                <div className={style.column}>
                    <div className={style.label}>Дата начала</div>

                    <DPControl
                        onSubmit={(date: string) => setStartDate(date)}
                        variant='date'
                        style={{ width: '100%', height: 42 }}
                        isButton={false}
                        defaultValue={defaultStartDate}
                    />
                </div>

                <div className={style.column}>
                    <div className={style.label}>Дата окончания</div>

                    <DPControl
                        onSubmit={(date: string) => setEndDate(date)}
                        variant='date'
                        isButton={false}
                        style={{ width: '100%', height: 42 }}
                        defaultValue={defaultEndDate}
                    />
                </div>
            </div>
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};
