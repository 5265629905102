import { FC } from 'react';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@components/core/inputs/Button';
import SelectReact from '@components/core/inputs/SelectStyled/SelectStyled';
import { OptionType } from '@components/core/inputs/SelectStyled/type';
import Modal from '@components/Modal';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import style from './add_teaching_staff_modal.module.scss';

const methodistSchema = yup.object({
    select: yup
        .array()
        .of(yup.object({ label: yup.string().default(''), value: yup.string().default('') }))
        .min(1, 'Выберите методиста').required()
});

const teacherSchema = yup.object({
    select: yup
        .array()
        .of(yup.object({ label: yup.string().default(''), value: yup.string().default('') }))
        .min(1, 'Выберите преподавателя').required() });

export type AddTeacherForm = yup.InferType<typeof methodistSchema>;

interface IAddTeacherModal {
    title: string;
    type: 'methodist' | 'teacher' | 'secondTeacher'
    options: OptionType[];
    showModal: boolean;
    setShowModal: (showTeacherPopup: boolean) => void;
    setSelectedTeacherValue: (data: AddTeacherForm) => void;
}

const AddTeachingStaffModal: FC<IAddTeacherModal> = ({
    title,
    type,
    options,
    showModal,
    setShowModal,
    setSelectedTeacherValue
}) => {
    const { handleSubmit, control } = useForm<AddTeacherForm>({
        resolver: yupResolver(type === 'methodist' ? methodistSchema : teacherSchema),
        mode: 'onSubmit',
        defaultValues: {
            select: []
        }
    });

    const onSubmit = (data: AddTeacherForm) => {
        setSelectedTeacherValue(data);
        setShowModal(false);
    };

    const getPlaceholder = () => {
        switch (type) {
            case 'methodist':
                return 'Укажите методиста';
            case 'teacher':
                return 'Укажите преподавателя';
            case 'secondTeacher':
                return 'Укажите преподавателя-дублера';
        }
    };

    return (
        <Modal
            onClose={() => setShowModal(false)}
            isOpen={showModal}
            title={title}
        >
            <form>
                <SelectReact
                    className={style.select}
                    control={control}
                    name="select"
                    options={options}
                    isMulti={true}
                    isAvatarOnItem
                    placeholder={getPlaceholder()}
                />
                <div className={style.button_wrapper}>
                    <Button
                        onClick={() => {
                            setShowModal(false);
                        }}
                        btnType="secondary"
                    >
                        Отмена
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)}>Сохранить</Button>
                </div>
            </form>
        </Modal>
    );
};

export default AddTeachingStaffModal;
