import { Outlet, useNavigate } from 'react-router-dom';

import Button from '@components/core/inputs/Button';
import { RoleCheck } from '@components/RoleCheck';
import RouterTabs from '@components/RouterTabs/RouterTabs';
import { Role } from '@store/Roles';

import style from './tabs_layout.module.scss';

const tabs = [
    { name: 'Активные', path: '' },
    { name: 'Прошедшие', path: 'finished' }
];

const TabsLayout = () => {
    const navigate = useNavigate();

    const createEventHandler = () => {
        const navigateState = {
            state: {
                pathname: location.pathname,
                actionType: 'create'
            }
        };
        navigate('create-event', navigateState);
    };

    return (
        <>
            <div className={style.tabs_wrapper}>
                <RouterTabs className={style.tabs} tabs={tabs} />
                <RoleCheck roles={Role.Methodist}>
                    <Button
                        size="small"
                        className={style.tabs_wrapper__btn}
                        onClick={createEventHandler}
                    >
                        Добавить мероприятие
                    </Button>
                </RoleCheck>
            </div>

            <Outlet />
        </>
    );
};

export default TabsLayout;
