import TasksList from '../TeacherMain/TasksList';
import { useGradesContext } from '../TeacherMain/TeacherMain';

export const SubstituteWorks = () => {

    const { substituteDisciplines } = useGradesContext();

    return (
        <TasksList tasks={substituteDisciplines} filter="none" />
    );
};