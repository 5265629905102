import { FC, useEffect } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Column, Grid } from '@components/core/grid';
import Button from '@components/core/inputs/Button';
import { FileUploadPhotoControl } from '@components/core/inputs/FileUpload/FileUploadPhotoControl';
import InputControl from '@components/core/inputs/InputControl';
import SwitcherControl from '@components/core/inputs/Switcher/SwitcherControl';
import Loader from '@components/core/Loader';
import Paper from '@components/core/styledWrappers/Paper';
import { checkRole, RoleCheck } from '@components/RoleCheck';
import { getBase64 } from '@helpers/base64';
import { isStorageFile } from '@helpers/typeCheck';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigationHistory } from '@hooks/useNavigationHistory';
import { fileTypes } from '@store/api/apiTypes';
import { useGetDisciplineMaterialsListQuery } from '@store/api/materialsApi';
import { useUploadFileMutation } from '@store/api/storageApi';
import { Role } from '@store/Roles';
import { EditCourseFormData } from '@views/methodist/Materials/Course/EditCourse/BasicCourse/EditCourseForm/type';
import Materials from '@views/methodist/Materials/Course/Materials/Materials';
import TeachersCard from '@views/methodist/Materials/Course/TeachersCard';

import style from './EditCourseForm.module.scss';

export const editCourseSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    picture: yup.mixed().label('File').required('Поле обязательно для заполнения!'),
    status: yup.boolean(),
    uuidsTeacher: yup.array(),
    uuidsMetodist: yup.array(),
    uuidsDouble: yup.array(),
    materials: yup.array()

});

interface IEditCourseForm {
    defaultValues: EditCourseFormData;
    getFormData: (formData: EditCourseFormData) => void,
}

const EditCourseForm: FC<IEditCourseForm> = ({ defaultValues, getFormData }) => {

    const { uuidDiscipline } = useParams();
    const { goBack } = useNavigationHistory('./../..');

    const { name, picture, status, uuidsTeacher, uuidsMetodist, uuidsDouble } = defaultValues;

    const materialsQuery = useGetDisciplineMaterialsListQuery(uuidDiscipline as string);

    const [uploadFile] = useUploadFileMutation();

    const methods = useForm<EditCourseFormData>({
        defaultValues,
        resolver: yupResolver(editCourseSchema)
    });
    const { control, handleSubmit, setValue } = methods;

    useEffect(() => {
        setValue('name', name);
        setValue('picture', picture);
        setValue('status', status);
        setValue('uuidsTeacher', uuidsTeacher);
        setValue('uuidsMetodist', uuidsMetodist);
        setValue('uuidsDouble', uuidsDouble);
    }, [defaultValues]);

    useEffect(() => {
        if (materialsQuery.isSuccess) {
            setValue('materials', [...materialsQuery.data].sort((a,b) => (a.position && b.position) ? a.position - b.position : 0));
        }
    }, [materialsQuery.isFetching, materialsQuery.isSuccess]);

    const onSubmit = async (formData: EditCourseFormData) => {
        let transformedData = { ...formData };

        if (formData.picture) {
            let coverUuid = '';
            if (isStorageFile(formData.picture)) {
                coverUuid = formData.picture.uuid;
            } else {
                const b64 = await getBase64(formData.picture);
                const uploadedFile = await uploadFile({
                    file: b64 as string,
                    meta: {
                        filename: formData.picture.name,
                        type: fileTypes.Public
                    }
                }).unwrap();
                coverUuid = uploadedFile.uuid;
            }

            transformedData = {
                ...transformedData, picture: coverUuid
            };
        }
        getFormData(transformedData);
    };

    const isDisabled = !checkRole(Role.Methodist);

    return (
        <Grid>
            <Column size={8}>
                <Paper className={style.paper}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormProvider {...methods}>
                            <div className={style.form_content}>
                                <div className={style.fieldset}>
                                    <h2 className={style.fieldset_title}>
                                        Основное
                                    </h2>
                                    <div className={cx(style.input_wrapper, style['input_wrapper--center_content'])}>
                                        <label htmlFor="field-title-id">
                                            Название*
                                        </label>
                                        <InputControl
                                            name="name"
                                            placeholder="Название"
                                            id="field-title-id"
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div className={style.input_wrapper}>
                                        <p>Обложка курса*</p>
                                        <FileUploadPhotoControl
                                            name="picture"
                                            control={control}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div className={style.input_wrapper}>
                                        <p>Опубликовано</p>
                                        <SwitcherControl
                                            name="status"
                                            control={control}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                </div>
                                <TeachersCard />

                                {
                                    materialsQuery.isFetching ? <Loader /> : <Materials />
                                }

                                <div className={style.form_content__button_wrapper}>
                                    <RoleCheck roles={Role.Methodist}>
                                        <Button type="submit" >Сохранить</Button>
                                        <Button
                                            btnType="secondary"
                                            onClick={goBack}
                                        >
                                        Отмена
                                        </Button>
                                    </RoleCheck>
                                </div>
                            </div>
                        </FormProvider>
                    </form>
                </Paper>
            </Column>
        </Grid>
    );
};

export default EditCourseForm;
