import { FC, MouseEvent, useState } from 'react';

import cx from 'classnames';

import { BottomDrawer } from '@components/BottomDrawer';
import Paper from '@components/core/styledWrappers/Paper';
import { StatusLabel } from '@components/Status';
import { useBreakpoint } from '@hooks/useBreakpoint';
import Popover from '@mui/material/Popover';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role } from '@store/Roles';

import style from './UserDisciplineCell.module.scss';


export interface IUserDisciplineProps {
    isDebt: boolean;
    isPending: boolean,
    isPersonal?: boolean,
    name: string;
    onOpen: () => void;
}

const emptyFunction = () => null;

export const UserDisciplineCell: FC<IUserDisciplineProps> = ({
    isDebt = false,
    isPending,
    isPersonal = false,
    name,
    onOpen
}) => {
    const smDown = useBreakpoint('sm', 'down');
    const mdDown = useBreakpoint('md', 'down');

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'header-cell-popover' : undefined;

    const user = useAppSelector(selectApiUser);

    const isStudent = user?.role === Role.Student;

    const handleClickDiscipline = () => {
        onOpen();
    };

    const renderLabels = () => {

        const props = {
            onMouseEnter: !(isStudent && smDown) ? handleClick : emptyFunction,
            onMouseLeave: !(isStudent && smDown) ? handleClose : emptyFunction,
            onClick: isStudent && smDown ? handleClick : emptyFunction
        };

        const renderBlock = [];

        if (isDebt) {
            renderBlock.push(<StatusLabel type={'danger'} {...props}>Задолженность</StatusLabel>);
        }

        if (isPending) {
            renderBlock.push(<StatusLabel type={'outlined'} >Ожидает проверки</StatusLabel>);
        }

        if (isPersonal) {
            renderBlock.push(<StatusLabel type={'filled'} >Персональная дисциплина</StatusLabel>);
        }

        return renderBlock;
    };

    return <>
        <div className={style.cell}>
            <div className={cx(style.name, style.link)}>
                <span onClick={handleClickDiscipline}>{name}</span>
                {
                    renderLabels()
                }
            </div>
        </div>

        {!(isStudent && smDown) ? (
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{
                    pointerEvents: 'none'
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: mdDown ? 'left' : 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: mdDown ? 'left' : 'center'
                }}
                disableRestoreFocus
                style={{ top: 4 }}
                classes={{ paper: style['with-shadow'] }}
            >
                <Paper className={style.paper}>
                    Закончился срок сдачи дисциплины, за подробностями обратитесь к методистам курса.
                </Paper>
            </Popover>
        ) : (
            <BottomDrawer onClose={handleClose} onOpen={handleClickDiscipline} open={open}>
                Закончился срок сдачи дисциплины, за подробностями обратитесь к методистам курса.
            </BottomDrawer>
        )}
    </>;
};
