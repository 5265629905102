import { FC, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useChat } from '@components/Chat/context';
import Button from '@components/core/inputs/Button';
import { FileUploadButton } from '@components/core/inputs/FileUpload';
import { TUploadFile } from '@components/core/inputs/FileUpload/type';
import InputBase from '@components/core/inputs/InputBase/InputBase';
import TreeSelectControl, { transformDataToOptions } from '@components/core/inputs/NewTreeSelectControl';
import Modal from '@components/Modal';
import { getBase64 } from '@helpers/base64';
import { yupResolver } from '@hookform/resolvers/yup';
import { fileTypes, GroupTree, IStorageFile } from '@store/api/apiTypes';
import { useUploadFileMutation } from '@store/api/storageApi';
import { useChangeUsersGroupMutation } from '@store/api/usersApi';


import style from './edit_group_modals.module.scss';

interface IProps {
    setShowSendMessageModal: (flag: boolean) => void;
    showSendMessageModal: boolean;
    localStudents: string[];
    setShowChangeGroupModal: (flag: boolean) => void;
    showChangeGroupModal: boolean;
    groups?: GroupTree;
    uuidGroup?: string;
}

interface IModalGroupForm {
    select: string;
}

const schema = yup.object({
    select: yup.string().required('')
});

const MAX_FILE_SIZE = 1024 * 1024 * 50;

const EditGroupModals: FC<IProps> = ({
    setShowSendMessageModal,
    showSendMessageModal,
    localStudents,
    setShowChangeGroupModal,
    showChangeGroupModal,
    groups,
    uuidGroup
}) => {
    const { sendMessageByGroup } = useChat();

    const [localMessageToSend, setLocalMessageToSend] = useState('');
    const [messageError, setMessageError] = useState(false);
    const [localFilesToSend, setLocalFilesToSend] = useState<TUploadFile | IStorageFile | TUploadFile[] | IStorageFile[] | null>(null);
    const methods = useForm<IModalGroupForm>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            select: uuidGroup || ''
        }
    });

    const groupsOptions = useMemo(() => groups ? transformDataToOptions(groups.childs, {
        value: 'UUID',
        label: 'name',
        children: 'childs'
    }) : [], [groups]);

    const { handleSubmit, reset } = methods;

    const [changeUsersGroup] = useChangeUsersGroupMutation();
    const [uploadFile, { isLoading }] = useUploadFileMutation();

    const onSubmit = (formData: IModalGroupForm) => {
        setShowChangeGroupModal(false);
        changeUsersGroup({
            uuidGroup: formData.select,
            uuids: localStudents
        });
        reset();
    };

    const sendMessage = async () => {
        if (localFilesToSend) {
            let file: File;

            if (Array.isArray(localFilesToSend)) {
                file = localFilesToSend[0] as File;
            } else {
                file = localFilesToSend as File;
            }

            if (file.size > MAX_FILE_SIZE) {
                toast.error('Максимальный размер файла 50 Мб');
                return;
            }

            const b64 = await getBase64(file);
            const uploadedFile = await uploadFile({
                file: b64 as string,
                meta: {
                    filename: file.name,
                    type: fileTypes.Public
                }
            }).unwrap();
            sendMessageByGroup(localStudents, localMessageToSend, [uploadedFile.uuid]);
        } else {
            if (localMessageToSend) {
                sendMessageByGroup(localStudents, localMessageToSend);
            } else {
                setMessageError(true);
                return;
            }
        }

        setShowSendMessageModal(false);
        setLocalMessageToSend('');
        setLocalFilesToSend(null);
    };

    useEffect(() => {
        if ((messageError && localFilesToSend) || !showSendMessageModal) {
            setMessageError(false);
        }
    }, [localFilesToSend, messageError, showSendMessageModal]);

    return (
        <>
            <Modal
                onClose={() => setShowSendMessageModal(false)}
                isOpen={showSendMessageModal}
                title={'Отправить сообщение'}
                className={style.modal}
            >
                <p>Выбрано пользователей: {localStudents.length}</p>
                <div className={style.input_modal_container}>
                    <InputBase
                        name="send-message"
                        value={localMessageToSend}
                        onChange={(event) => {
                            setLocalMessageToSend(event.target.value);
                            if (event.target.value && messageError) {
                                setMessageError(false);
                            }
                        }}
                        label={'Текст сообщения'}
                        className={style.textarea}
                        textarea={true}
                        error={messageError}
                    />
                </div>
                <FileUploadButton
                    caption={false}
                    className={style['file-upload']}
                    onChange={setLocalFilesToSend}
                    value={localFilesToSend}
                />
                <div className={style.button_wrapper}>
                    <Button
                        onClick={() => setShowSendMessageModal(false)}
                        btnType={'secondary'}
                    >
                        Отмена
                    </Button>
                    <Button onClick={sendMessage} disabled={isLoading}>
                        Отправить
                    </Button>
                </div>
            </Modal>
            <Modal
                onClose={() => setShowChangeGroupModal(false)}
                isOpen={showChangeGroupModal}
                title="Сменить группу"
            >
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p>Группа</p>

                        <TreeSelectControl
                            options={groupsOptions}
                            name="select"
                            className={style.select}
                            placeholder="Выберите группу"
                        />

                        <div className={style.button_wrapper}>
                            <Button
                                onClick={() => setShowChangeGroupModal(false)}
                                btnType="secondary"
                            >
                                Отмена
                            </Button>
                            <Button type="submit">
                                Сохранить
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            </Modal>
        </>
    );
};

export default EditGroupModals;
