import React, { FC, useState } from 'react';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ReactComponent as EditSVG } from '@assets/icons/edit.svg';
import { ReactComponent as MinusSVG } from '@assets/icons/minus.svg';
import { ReactComponent as ScoreSVG } from '@assets/icons/score.svg';
import Button from '@components/core/inputs/Button';
import InputControl from '@components/core/inputs/InputControl';
import { getTypeScore } from '@helpers/scores';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigationState } from '@hooks/useNavigationState';
import { EnumMaterialsType } from '@store/api/apiTypes';
import { IScoreTableData } from '@views/methodist/Materials/Course/Scores/ScoreTableView/ScoreTable';


import style from './DataListEditCell.module.scss';


interface IDataListEditCellProps {
    onSave: (value: number) => void;
    value?: string | number;
    unit: IScoreTableData;
    groupUuids: string[];
}

const loginSchema = yup.object({
    cellInput: yup.number().required('').max(100)
});

export type EditInput = yup.InferType<typeof loginSchema>;

export const DataListEditCell:FC<IDataListEditCellProps> = ({ onSave, value = '', unit, groupUuids }) => {
    const showScore = unit.default?.type === EnumMaterialsType.Task || (unit.default?.type === EnumMaterialsType.Test && unit.isExist);

    const materialType = unit.default?.type === EnumMaterialsType.Task ? 'task' : 'test';

    const { navigate } = useNavigationState(groupUuids);

    const [edit, setEdit] = useState(false);

    const methods = useForm<EditInput>({
        resolver: yupResolver(loginSchema),
        mode: 'onBlur'
    });

    const { handleSubmit } = methods;

    const controlStyle: React.CSSProperties = {
        height: 33
    };

    const onSubmit = async (formData: EditInput) => {
        onSave(formData.cellInput);
        setEdit(false);
    };

    const showTaskResult = () => {

        navigate(`${materialType}/${unit.userId}/${unit.uuidMaterial}`);
    };

    const getLabel = (): React.ReactNode => {
        if (unit.isTotal && unit.closeType) {
            const type = getTypeScore(Number(value), unit.closeType);
            if (!type) return value;

            switch (type) {
                case 'excelent':
                    return `Отлично (${value})`;
                case 'good':
                    return `Хорошо (${value})`;
                case 'medium':
                    return `Удовлетворительно (${value})`;
                case 'bad':
                    return `Недостаточно баллов (${value})`;
                case 'success':
                    return `Зачет (${value})`;
                case 'failed':
                    return `Незачет (${value})`;
            }

        }
        return value !== '' ? value : <MinusSVG />;
    };

    const renderInput = () => {

        if (edit) {
            return <div className={style.input}>
                <FormProvider {...methods}>
                    <div className={style.control}>
                        <InputControl helperText={''} style={controlStyle} name='cellInput' defaultValue={value !== '' ? value : 0} />
                    </div>
                    <Button style={controlStyle} btnType='success' onClick={handleSubmit(onSubmit)}>Сохранить</Button>
                </FormProvider>
            </div>;
        } else {
            return <div className={style.value}>
                <div className={style.content}>{getLabel()}</div>
                <div className={style.actions}>
                    <EditSVG onClick={() => setEdit(true)} className={style.icon} />
                    { showScore && <ScoreSVG className={style.icon} onClick={showTaskResult} />}
                </div>
            </div>;
        }
    };

    return <div className={cx(style.container, edit && style.editable)}>
        {renderInput()}
    </div>;
};
