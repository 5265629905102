import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { logIn } from '@store/features/authSlice';
import { setJwtUser } from '@store/features/userSlice';
import { LoginInput } from '@views/login/LoginPage/LoginStep';
import { RecoverInput } from '@views/login/LoginPage/RecoverStep';

import { UpdatePasswordRequest, JwtUser, PassAuthenticationRequest, PassAuthenticationResponse, ResetPassData } from './apiTypes';
import { emptyBaseApi } from './emptyBaseApi';

const duuid = uuid();

export const authApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        loginUser: build.mutation<{ access: string, refresh: string, user: JwtUser }, LoginInput >({
            query(data) {
                return {
                    url: 'auth/login',
                    method: 'POST',
                    body: { ...data, uuidDevice: duuid }
                };
            },
            transformResponse: (result: { access: string, refresh: string } ) => {
                return { ...result, user: jwt_decode(result.access) };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(setJwtUser(data.user));
                    dispatch(logIn({ access: data.access, refresh: data.refresh, duuid: duuid }));
                } catch (error) {
                    //console.log('🚀 ~ file: authApi.ts ~ line 25 ~ onQueryStarted ~ error', error);
                }
            }
        }),
        recoverPassword: build.mutation<string, RecoverInput> ({
            query(data) {
                return {
                    url: 'auth/recover',
                    method: 'POST',
                    body: { ...data }
                };
            }

        }),
        changePassword: build.mutation<string, ResetPassData> ({
            query(data) {
                return {
                    url: 'auth/change-by-token',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Пароль обновлен!',
                    error: 'Не удалось обновить пароль'
                });
            }

        }),
        passAuthentication: build.mutation<PassAuthenticationResponse, PassAuthenticationRequest> ({
            query(data) {
                return {
                    url: 'grades/auth/create',
                    method: 'POST',
                    body: { ...data }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error('Не удалось пройти ауентификацию');
                }
            },
            transformResponse: (result: PassAuthenticationResponse) => {
                return result;
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Authentication', id: arg.uuidAuth }]

        }),
        updatePassword: build.mutation<void, UpdatePasswordRequest> ({
            query(passwordData) {
                return {
                    url: 'auth/change',
                    method: 'POST',
                    body: { ...passwordData }
                };
            },
            async onQueryStarted(args, { queryFulfilled }) {
                toast.promise(queryFulfilled, {
                    success: 'Смена пароля прошла успешно',
                    error: 'Не удалось обновить пароль'
                });
            }

        })
    })
});

export const {
    useLoginUserMutation,
    useRecoverPasswordMutation,
    useChangePasswordMutation,
    usePassAuthenticationMutation,
    useUpdatePasswordMutation
} = authApi;
