import React from 'react';

import { Outlet } from 'react-router-dom';

import { ButtonIcon } from '@components/core/inputs/Button/ButtonIcon';
import Main from '@components/Main';
import RouterTabs from '@components/RouterTabs/RouterTabs';
import { useNavigationHistory } from '@hooks/useNavigationHistory';
import { useEditCourseFormContext } from '@views/methodist/Materials/Course/EditCourse/EditCourse';

import style from './edit_course_layout.module.scss';

const tabs = [
    { name: 'Основное', path: '' },
    { name: 'Структура курса', path: 'structure' },
    { name: 'Оценки', path: 'scores' }
];

const CommonLayout = () => {
    const { goBack } = useNavigationHistory('./../..');

    return (
        <>
            <div className={style.title_wrapper}>
                <ButtonIcon
                    typeIcon={'arrowLink'}
                    onClick={goBack}
                />
                <h1>Редактировать курс</h1>
            </div>

            <RouterTabs tabs={tabs} />
        </>
    );
};

const EditCourseLayout = () => {
    return (
        <Main>
            <CommonLayout />

            <Outlet />
        </Main>
    );
};

const EditCourseLayoutWithContext = () => {
    const { formContext, setFormContext } = useEditCourseFormContext();

    return (
        <Main>
            <CommonLayout />

            <Outlet context={{ formContext, setFormContext }} />
        </Main>
    );
};

export { EditCourseLayout, EditCourseLayoutWithContext };
