import React from 'react';

import Main from '@components/Main';
import GoBackLink from '@components/student/GoBackLink';

import style from './ConfidentialityPage.module.scss';

const ConfidentialityPage = () => {
    return (
        <Main title="Политика конфиденциальности" className={style.container}>
            <GoBackLink to="/">
                Назад
            </GoBackLink>
            <h1 className={style.main_title}>Политика конфиденциальности персональных данных</h1>
            <div className={style.main_content}>
                <p className={style.paragraph}>Настоящая Политика конфиденциальности персональных данных (далее — Политика) определяет порядок обработки и защиты персональной информации о
                    Пользователях, которую Автономная некоммерческая организация высшего образования «Восточно-Европейский Институт психоанализа» (далее —
                    Институт), может получить во время использования ими сервисов сайта <a href="https://eeip.online">https://eeip.online</a> Института (далее —
                    Сайта) в соответствии с Пользовательским соглашением.</p>
                <p>Настоящая Политика действует в отношении всей информации, которую Институт может получить о Пользователе во время
                    использования Сайта.</p>
                <p>Перед использованием Сайта Пользователям следует ознакомиться с условиями настоящей Политики конфиденциальности.</p>
                <p className={style.paragraph}>В настоящей Политике конфиденциальности используются следующие термины:</p>
                <p><strong>Институт</strong> – Автономная некоммерческая организация высшего образования «Восточно-Европейский Институт психоанализа».</p>
                <p className={style.paragraph}>Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту
                    персональных
                    данных).</p>
                <p className={style.paragraph}><strong>Обработка персональных данных</strong> – любое действие (операция) или совокупность действий (операций), совершаемых с использованием
                    средств
                    автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение,
                    уточнение
                    (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование,
                    удаление,
                    уничтожение персональных данных.</p>
                <p className={style.paragraph}><strong>Конфиденциальность персональных данных</strong> – обязательное для соблюдения Институтом или иным получившим доступ к персональным
                    данным
                    лицом требование
                    не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
                <p className={style.paragraph}><strong>Пользователь</strong> – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.</p>
                <p className={style.paragraph}><strong>Cookies</strong> – небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или
                    веб-браузер каждый
                    раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>
                <p className={style.paragraph}>IP-адрес – уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>
                <ol>
                    <li>
                        <p className={style.paragraph}>
                            1. Общие положения
                        </p>
                        <ol>
                            <li>
                                1.1. Использование Сайта в любой форме означает безоговорочное согласие Пользователя с условиями настоящей Политики
                                конфиденциальности и
                                указанными в ней условиями обработки его персональной информации. В случае несогласия с условиями Политики конфиденциальности
                                Пользователь
                                должен воздержаться от использования Сайта.
                            </li>
                            <li>
                                1.2. Политика конфиденциальности (в том числе любая из ее частей) может быть изменена Институтом без какого-либо специального
                                уведомления и
                                без
                                выплаты какой-либо компенсации в связи с этим. Новая редакция Политики конфиденциальности вступает в силу с момента ее
                                размещения на
                                Сайте.
                            </li>
                            <li>
                                1.3. Принимая условия настоящей Политики, Пользователь выражает свое согласие на обработку Институтом данных о Пользователе в
                                целях,
                                предусмотренных настоящей Политикой, а также на передачу данных о Пользователе третьим лицам в случаях, перечисленных в
                                настоящей
                                Политике.
                                <p>Указанное согласие может быть отозвано Пользователем только при условии письменного уведомления им Института не менее чем за
                                    90
                                    дней до
                                    предполагаемой даты прекращения использования данных Институтом.</p>
                                <p>Использование Сайта с помощью веб-браузера, который принимает данные из cookies, означает выражение согласия Пользователя с
                                    тем,
                                    что
                                    Институт
                                    может собирать и обрабатывать данные из cookies в целях, предусмотренных настоящей Политикой, а также на передачу данных из
                                    cookies
                                    третьим
                                    лицам в случаях, перечисленных в настоящей Политике.</p>
                                <p>Отключение и/или блокировка Пользователем опции веб-браузера по приему данных из cookies означает запрет на сбор и обработку
                                    Институтом
                                    данных из
                                    cookies в соответствии с условиями настоящей Политики конфиденциальности.</p>
                            </li>
                            <li>
                                1.4. По общему правилу Институт не проверяет достоверность предоставляемой Пользователями персональной информации. Вместе с тем
                                в
                                случаях,
                                предусмотренных Пользовательским соглашением, Пользователь обязан предоставить подтверждение достоверности предоставленной им
                                персональной
                                информации о себе.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p className={style.paragraph}>
                            2. Состав информации о Пользователях, которую получает и обрабатывает Институт
                        </p>
                        <ol>
                            <li>2.1. Настоящая Политика распространяется на следующие виды персональной информации:</li>
                            <ol>
                                <li>
                                    2.1.1. Персональная информация, размещаемая Пользователями, в т.ч. о себе самостоятельно при заполнении формы отправки
                                    сообщения, иная
                                    персональная информация, доступ к которой Пользователь предоставляет Институту через веб-сайты или Сайты третьих лиц, или
                                    персональная
                                    информация, размещаемая Пользователями в процессе использования Сайта. К персональной информации, полученной таким образом,
                                    могут
                                    относиться, в частности, фамилия, имя, отчество, номер телефона, адрес электронной почты Пользователя, адрес для доставки
                                    заказа
                                    (услуги),
                                    место жительство Пользователя. Иная информация предоставляется Пользователем на его усмотрение.
                                    <p>
                                        Запрещается предоставление Пользователем персональных данных третьих лиц без полученного от третьих лиц разрешения на
                                        такое
                                        распространение либо,
                                        если такие персональные данные третьих лиц не были получены самим Пользователем из общедоступных источников информации.
                                    </p>
                                </li>
                                <li>
                                    <p>2.1.2. Институт гарантирует Пользователю сохранение конфиденциальности следующей персональной информации о нем:</p>
                                    <p>- информация о карте пользователя (последние 4 цифры);</p>
                                    <p>- сведения об услугах.</p>
                                    <p>Указанная информация передается Институтом третьим лицам исключительно с целью осуществления оплаты услуги платежной
                                        системой; иные
                                        случаи
                                        передачи указанной информации третьим лицам не допускаются.</p>
                                </li>
                                <li>
                                    2.1.3. Данные, автоматически передаваемые Сайту в процессе их использования с помощью установленного на устройстве
                                    Пользователя
                                    программного
                                    обеспечения, в т.ч. IP-адрес, индивидуальный сетевой номер устройства (MAC-адрес, ID устройства), электронный серийный номер
                                    (IMEI, MEID),
                                    данные из cookies, информация о браузере, операционной системе, времени доступа, поисковых запросах Пользователя.
                                </li>
                                <li>
                                    2.1.4. Данные, дополнительно предоставляемые Пользователями по запросу Института в целях выполнения обязательств Института
                                    перед
                                    Пользователями
                                    в отношении использования Сайта.
                                </li>
                                <li>2.1.5. Иная информация о Пользователях, сбор и/или обработка которой установлены пользовательским соглашением Института.
                                </li>
                            </ol>
                        </ol>
                    </li>
                    <li>
                        <p className={style.paragraph}>
                            3. Цели сбора и обработки информации о Пользователях
                        </p>
                        <ol>
                            <li>3.1. Институт осуществляет сбор и обработку только той информации о Пользователях, в т.ч. их персональных данных, которая
                                необходима для
                                выполнения обязательств Института по предоставлению Сайта, ответа на вопрос, заданный Пользователем при отправке сообщения с
                                помощью
                                Сайта,
                                а также исполнения обязательств, предусмотренных пользовательским соглашением.
                            </li>
                            <li>
                                <p className={style.paragraph}>
                                    3.2. Институт может использовать персональную информацию Пользователей для целей:
                                </p>
                                <ol>
                                    <li>3.2.1. идентификации стороны в рамках договоров между Пользователем и Институтом.</li>
                                    <li>
                                        3.2.2. предоставления Пользователям услуг с использованием Сайта и для выполнения своих обязательств перед ними, в т.ч.
                                        уточнения данных
                                        платежа, обработки заказов и запросов и дальнейшего совершенствования Сайта, разработки новых Сайтов и услуг.
                                    </li>
                                    <li>
                                        3.2.3. информирования Пользователей о появлении новых материалов на Сайте, направления запросов, касающихся
                                        использования Сайта,
                                        обратной
                                        связи
                                        с Пользователем.
                                    </li>
                                    <li>3.2.4. выполнения маркетинговых задач, проведения статистических и иных исследований на основе обезличенных данных.</li>
                                    <li>
                                        3.2.5. информирования Пользователя посредством электронных рассылок. Предоставляя свои данные, Пользователь соглашается
                                        на
                                        получение
                                        сообщений
                                        рекламно-информационного характера и иных сообщений (рассылку).
                                    </li>
                                </ol>
                            </li>
                            <li>3.3. Настоящим Пользователь выражает свое согласие на передачу персональной информации о нем третьим лицам в целях,
                                предусмотренных п.
                                3.2
                                настоящей Политики конфиденциальности.
                            </li>
                            <li>3.4. При необходимости использовать персональную информацию о Пользователе в целях, не предусмотренных настоящей Политикой,
                                Институт
                                запрашивает согласие Пользователя на такие действия.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p className={style.paragraph}>
                            4. Обработка информации о Пользователях
                        </p>
                        <ol>
                            <li>4.1. Персональная информация о Пользователях хранится в соответствии с действующим законодательством.</li>
                            <li>
                                <p className={style.paragraph}>
                                    4.2. Персональная информация о Пользователях не передается третьим лицам, за исключением следующих случаев:
                                </p>
                                <ol>
                                    <li>4.2.1. Пользователь выразил согласие на такие действия.</li>
                                    <li>4.2.2. Передача необходима в целях обеспечения функционирования Сайта и/или его отдельных функциональных возможностей.
                                    </li>
                                    <li>4.2.3. Передача предусмотрена применимым правом.</li>
                                    <li>4.2.4. В целях обеспечения возможности защиты прав и законных интересов Института и/или третьих лиц в случаях, когда
                                        Пользователь
                                        нарушает
                                        условия пользовательского соглашения.
                                    </li>
                                    <li>4.2.5. Если Институт примет участие в слиянии, приобретении или любой другой форме продажи части или всех своих активов.
                                        При
                                        этом к
                                        приобретателю активов Института переходят все обязательства по соблюдению условий настоящей Политики.
                                    </li>
                                </ol>
                            </li>
                            <li>4.3. Пользователь настоящим уведомлен и соглашается, что Институт может получать персональные данные третьих лиц, которые
                                предоставляются
                                Пользователем при использовании Сайта, и использовать их для реализации отдельных функций Сайта при условии, что Пользователь
                                гарантирует
                                наличие согласия третьих лиц, данные о которых предоставляются Пользователем при использовании Сайта, на обработку Институтом, в
                                целях,
                                предусмотренных настоящей Политикой, а также на передачу таких данных в случаях, перечисленных в настоящей Политике.
                            </li>
                            <li>4.4. Кроме того, Пользователь настоящим уведомлен и соглашается, что Институт может получать статистические обезличенные (без
                                привязки к
                                Пользователю) данные о действиях Пользователя при использовании Сайта.
                            </li>
                            <li>4.5. Пользователи вправе по запросу получать от Института информацию, касающуюся обработки их персональных данных.</li>
                        </ol>
                    </li>
                    <li>
                        <p className={style.paragraph}>
                            5. Меры по защите информации о Пользователях
                        </p>
                        <ol>
                            <li>
                                5.1. Институт принимает все необходимые и достаточные организационные и технические меры для защиты персональной информации о
                                Пользователях
                                от
                                неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, распространения персональной информации, а
                                также
                                от иных
                                неправомерных действий с ней. К этим мерам относятся, в частности, внутренняя проверка процессов сбора, хранения и обработки
                                данных
                                и мер
                                безопасности, включая меры по обеспечению физической безопасности данных для предотвращения неавторизированного доступа к
                                персональной
                                информации.
                            </li>
                            <li>
                                5.2. При обработке персональных данных Пользователей Институт руководствуется Федеральным законом от 27.07.2006 № 152-ФЗ «О
                                персональных
                                данных».
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p className={style.paragraph}>
                            6. Заключительные положения
                        </p>
                        <ol>
                            <li>
                                6.1. Настоящая Политика, отношения между Пользователем и Институтом, возникающие в связи с применением настоящей Политики, а
                                также
                                вопросы,
                                не
                                урегулированные настоящей Политикой, регулируются действующим законодательством Российской Федерации.
                            </li>
                            <li>
                                6.2. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и Институтом, обязательным является
                                предъявление
                                претензии (письменного предложения о добровольном урегулировании спора). Получатель претензии в течение 30 календарных дней со
                                дня
                                получения
                                претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии. При недостижении соглашения спор будет
                                передан
                                на
                                рассмотрение в судебный орган по месту нахождения Института в соответствии с действующим законодательством Российской Федерации.
                            </li>
                            <li>
                                6.3. Институт вправе вносить изменения в настоящую Политику без согласия Пользователя. Новая Политика конфиденциальности
                                вступает в
                                силу
                                с
                                момента ее размещения на Сайте.
                            </li>
                            <li>6.4. Действующая Политика конфиденциальности размещена на сайте по адресу: <a href="https://eeip.online">https://eeip.online</a>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </Main>
    );
};

export default ConfidentialityPage;
