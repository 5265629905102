import { FC, useRef } from 'react';

import { useMessages } from '@components/Chat/parts/Messages/context';
import IconUI from '@components/core/IconUI';
import { TypeIcon } from '@components/core/IconUI/type';
import { acceptPhotoTypes } from '@components/core/inputs/FileUpload/FileUploadPhoto/validation';
import { FileHiddenInput } from '@components/core/inputs/FileUpload/parts/FilePlaceholder/FileHiddenInput';
import { TUploadFile } from '@components/core/inputs/FileUpload/type';
import { fileTypes, IStorageFile } from '@store/api/apiTypes';

import style from './FileOpenButton.module.scss';

export interface IFileOpenButtonProps extends React.HTMLAttributes<HTMLDivElement> {
    typeIcon?: TypeIcon;
}

const FileOpenButton: FC<IFileOpenButtonProps> = ({
    typeIcon,
    ...props
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const { submitFileMessage } = useMessages();

    const fileChange = async (file: TUploadFile | TUploadFile[]) => {
        if (file && !Array.isArray(file)) {
            const url = URL.createObjectURL(file);
            const splitedName = file.name.split('.');

            const fileData: IStorageFile = {
                uuid: Date.now().toString(),
                path: url,
                title: file.name,
                extension: splitedName[splitedName.length - 1] ?? '',
                size: file.size,
                userUuid: '',
                createdAt: '',
                updatedAt: '',
                type: fileTypes.Public
            };

            submitFileMessage(fileData, file);
        }

        if (fileInputRef.current) fileInputRef.current.value = '';
    };

    const onOpenDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className={style.container} {...props} onClick={onOpenDialog}>
            <FileHiddenInput
                inputRef={fileInputRef}
                fileChange={fileChange}
            />
            <IconUI typeIcon={typeIcon || 'clip'} className={style.clip} />
        </div>
    );
};

export default FileOpenButton;
