import React from 'react';

import { ReactComponent as GraduationIcon } from '@assets/icons/hat-graduation-stroked-svg.svg';
import { useToggle } from '@hooks/useToggle.hook';
import { CommonSidebar } from '@views/student/StudentCourse/Sidebars/CommonSidebar';
import style from '@views/student/StudentCourse/Sidebars/MaterialsSidebar/MaterialsSidebar.module.scss';
import { SkillsList } from '@views/student/StudentCourse/SkillsList';

export function SkillsSidebar({ uuidsDiscipline }: { uuidsDiscipline: string }) {
    const [isOpen, { on, off }] = useToggle();

    return (
        <CommonSidebar isOpen={isOpen} on={on} off={off} title="Компетенции" icon={<GraduationIcon />} containerClassName={style.materials_content}>
            <SkillsList uuidsDiscipline={uuidsDiscipline} />
        </CommonSidebar>
    );
}
