import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import IconUI from '@components/core/IconUI/IconUI';
import Button from '@components/core/inputs/Button/Button';
import Checkbox from '@components/core/inputs/Checkbox/Checkbox';
import NewTreeSelectControl from '@components/core/inputs/NewTreeSelectControl';
import { transformDataToOptions } from '@components/core/inputs/Select/NewTreeSelect';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import { OptionType, StringOptionType } from '@components/core/inputs/SelectStyled/type';
import { DPRangeControl } from '@components/DatePicker/DPRangeControl';
import { IRangeDates } from '@components/DatePicker/DPRangeControl/DPRangeControl';
import { Helmet } from '@components/Helmet';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetDisciplinesAllQuery } from '@store/api/disciplinesApi';
import { useGetGradesSheetQuery, useLazyGetGradesSheetPDFQuery } from '@store/api/gradesApi';
import { useGetGroupsTreeQuery } from '@store/api/groupsApi';
import { useLazyGetPatternQuery } from '@store/api/patternsApi';
import { GradesSheetRequest } from '@type/grades';
import materialStyle from '@views/methodist/Materials/MaterialsPage/MaterialsList/materialsList.module.scss';


import style from './Statements.module.scss';
import StatementsList from './StatementsList';

const sheetSchema = yup.object({
    material: yup.object({ label: yup.string().default(''), value: yup.string().default('') }),
    groups: yup.array()
});

export type AddMaterialForm = yup.InferType<typeof sheetSchema>;

export const Statements = () => {

    const navigate = useNavigate();

    const [gradeSheetOptions, setGradeSheetOptions] = useState<GradesSheetRequest>();
    const [beginDate, setBeginDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [groupsTreeCurrent, setGroupsTreeCurrent] = useState<string[]>([]);
    const [materialCurrent, setMaterialCurrent] = useState<string>('');
    const [isSession, setSession] = useState(false);

    const { data: materialAll } = useGetDisciplinesAllQuery({});
    const { data: groupsTree } = useGetGroupsTreeQuery('');
    const {
        data: sheets,
        isFetching: isSheetsFetching,
        error: isSheetError
    } = useGetGradesSheetQuery(gradeSheetOptions ?? skipToken);

    const [getPDFFile] = useLazyGetGradesSheetPDFQuery();

    const [getPattern] = useLazyGetPatternQuery();

    const materialsOptions = useMemo(() => {

        return materialAll ? materialAll.map(item => {
            return {
                label: item.name,
                value: item.uuid
            } as Omit<OptionType, 'value'> & { value: string };
        }) : [];

    }, [materialAll]);

    const groupOptions = useMemo(() => {

        const allOptions = groupsTree
            ? [
                {
                    value: groupsTree.UUID,
                    label: 'Без родительской группы',
                    children: []
                },
                ...transformDataToOptions(groupsTree.childs, {
                    value: 'UUID',
                    label: 'name',
                    children: 'childs'
                })
            ]
            : [];

        return allOptions;
    }, [groupsTree]);

    const methods = useForm<AddMaterialForm>({
        resolver: yupResolver(sheetSchema),
        defaultValues: {
            material: {
                label: 'Материалы',
                value: 'all',
                picture: ''
            } as StringOptionType
        }
    });

    const isDataAlright = useCallback(
        () => materialCurrent && beginDate && endDate && groupsTreeCurrent,
        [materialCurrent, beginDate, endDate, groupsTreeCurrent]
    );

    const onDateSubmit = (rangeDatesToSubmit: IRangeDates) => {
        setBeginDate(rangeDatesToSubmit.startDate);
        setEndDate(rangeDatesToSubmit.endDate);
    };

    const onExportPDFFile = () => {

        if (isDataAlright()) {

            getPattern(materialCurrent).then(res => {

                getPDFFile({
                    beginDate,
                    endDate,
                    extraSessionOnly: isSession,
                    pattern: res.data?.pattern ?? '',
                    uuidsDiscipline: [materialCurrent],
                    uuidsGroup: groupsTreeCurrent
                }).then(
                    response => {
                        if (response.isError) return;
                        window.open(response.data?.path);
                    }
                );
            });

        }

    };

    const onSessionChange = () => {
        setSession(!isSession);
    };
    const goStatementsEditor = () => {
        navigate('/reports/statements/edit', {
            state: {
                uuidDiscipline: materialCurrent
            }
        });
    };

    const { control, watch, setValue } = methods;
    const watchMaterial = watch('material');
    const watchGroup = watch('groups') as string | undefined;

    useEffect(() => {
        setValue('material', materialsOptions[0]);
    }, [materialsOptions]);

    useEffect(() => {
        setMaterialCurrent(watchMaterial?.value || '');
    }, [watchMaterial]);

    useEffect(() => {
        if (!watchGroup) return;

        setGroupsTreeCurrent([watchGroup]);
    }, [watchGroup]);

    useEffect(() => {
        if (isDataAlright()) {

            setGradeSheetOptions({
                beginDate,
                endDate,
                uuidsDiscipline: [materialCurrent],
                uuidsGroup: groupsTreeCurrent,
                extraSessionOnly: isSession
            });
        }
    }, [materialCurrent, beginDate, endDate, groupsTreeCurrent, isSession]);

    return (
        <>
            <Helmet title="Ведомости" />
            <h2 className={style.title}>
                Ведомости
            </h2>

            <div className={style.controls_container}>
                <div className={style.form}>
                    <FormProvider {...methods}>
                        <NewTreeSelectControl
                            className={style.treeSelect}
                            classes={{
                                input: materialStyle['select__input'],
                                inputIcon: materialStyle['select__icon']
                            }}
                            options={groupOptions}
                            name="groups"
                            placeholder="Выберите группу"
                            selectableParent
                        />

                        <SelectStyled
                            className={style.material_filter}
                            control={control}
                            name="material"
                            options={materialsOptions}
                            isMulti={false}
                            isSearchable
                            placeholder="Выбрать дисциплину"
                        />

                        <div className={style.datePicker}>
                            <DPRangeControl
                                onSubmit={onDateSubmit}
                                variant="date"
                                isButton={false}
                            />
                        </div>

                        <div className={style.box}>
                            <Checkbox onChange={onSessionChange} label="Доп. сессия" />
                        </div>
                    </FormProvider>
                </div>

                <div className={style.actions}>
                    <Button size="middle" disabled={!sheets?.rows.length} onClick={onExportPDFFile}>Экспорт в .pdf</Button>
                    <Button size="middle" btnType="secondary" className={style.settings} onClick={goStatementsEditor}>
                        <IconUI typeIcon="settings" />
                    </Button>
                </div>
            </div>

            <div className={style.table}>
                <StatementsList rows={!isSheetError ? sheets?.rows : []} isFetching={isSheetsFetching} />
            </div>

        </>
    );
};

