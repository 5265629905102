import { TreeSelectOption } from '../NewTreeSelect';

interface Scheme<T> {
    value: keyof T;
    label: keyof T;
    children: keyof T;
}

export const transformDataToOptions = <T>(data: T[], scheme: Scheme<T>): TreeSelectOption[] => {
    return data.map(element => ({
        value: element[scheme.value] as unknown as string,
        label: element[scheme.label] as unknown as string,
        children: transformDataToOptions(element[scheme.children] as unknown as T[], scheme) as unknown as TreeSelectOption[]
    }));
};
