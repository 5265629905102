import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Checkbox from '@components/core/inputs/Checkbox';
import InputBase from '@components/core/inputs/InputBase';
import { MenuItem, TableMenu } from '@components/Menu';
import { RoleCheck } from '@components/RoleCheck';
import { IStudentDeadlinesApiResponse } from '@store/api/apiTypes';
import { useChangeUserStatusByUuidMutation, useResetUserPasswordMutation } from '@store/api/usersApi';
import { Role } from '@store/Roles';
import { UserStatus } from '@type/users';

import { ISelectStudent } from '../EditGroup';
import { TTermsModalType } from '../TermsModal/TermsModal';
import style from './edit_group_sidebar.module.scss';

const cx = classNames.bind(style);

interface IProps {
    students: ISelectStudent[] | undefined;
    toggleSelectStudent: (e: React.ChangeEvent<HTMLInputElement>, uuid: string) => void;
    toggleAllSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isAllSelected: boolean;
    setShowSendMessageModal: (flag: boolean) => void;
    setShowChangeGroupModal: (flag: boolean) => void;
    toggleTermsModal: (type: TTermsModalType, studentUuid: string) => void;
    setLocalStudents: (uuid: string[]) => void;
    setCurrentUser: (user: ISelectStudent) => void;
    individualDeadlines: IStudentDeadlinesApiResponse[],
    usersWithExtraSession: string[];
}

const EditGroupSideBar: FC<IProps> = ({
    students,
    toggleSelectStudent,
    toggleAllSelect,
    isAllSelected,
    setShowSendMessageModal,
    setShowChangeGroupModal,
    toggleTermsModal,
    setLocalStudents,
    setCurrentUser,
    individualDeadlines,
    usersWithExtraSession
}) => {
    const navigate = useNavigate();
    const { uuidGroup } = useParams();

    const [searchInputValue, setSearchInputValue] = useState('');
    const [queryStudents, setQueryStudents] = useState<ISelectStudent[] | undefined>(students);
    const [localSelectedUser, setLocalUser] = useState<ISelectStudent>();

    const [resetPassword] = useResetUserPasswordMutation();
    const [changeStatus] = useChangeUserStatusByUuidMutation();

    const searchStudents = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInputValue(e.target.value);
    };

    const sendPassword: (user: ISelectStudent) => void = (user) => {
        resetPassword([user.uuid]);
    };

    const toggleUserStatus = (user: ISelectStudent): void => {
        const status = user.status === UserStatus.Active ? UserStatus.Blocked : UserStatus.Active;

        changeStatus({
            uuids: [user.uuid],
            status
        });
    };

    useEffect(() => {
        if (students) {
            setQueryStudents(students);
        }
    }, [students]);

    useEffect(() => {
        if (students) setQueryStudents(students?.filter(student => (
            student.firstName + student.lastName + student.patronymic
        ).toLowerCase().includes(searchInputValue.toLowerCase())));
    }, [students, searchInputValue]);

    const handleSubscribeOnAdditionalDisciplines = (studentId: string) => {
        navigate( `./../course-subscribe/${uuidGroup}/${studentId}`);
    };

    return (
        <div className={style.sidebar_wrapper}>
            <div className={style.search_wrapper}>
                <InputBase
                    name="search"
                    variant="search"
                    placeholder="Поиск"
                    value={searchInputValue}
                    onChange={searchStudents}
                />
                { !!queryStudents?.length && <Checkbox checked={isAllSelected} label="Пользователь" onChange={toggleAllSelect} /> }
            </div>
            <div className={style.sidebar_students}>
                {
                    queryStudents?.length
                        ?
                        queryStudents.map(student => {
                            return (
                                <div
                                    key={student.uuid}
                                    className={cx(
                                        style.sidebar_student,
                                        student.status === UserStatus.Blocked && style.student_blocked,
                                        localSelectedUser?.uuid === student.uuid && style.student_selected
                                    )}>
                                    <div className={style.sidebar_checkbox}>
                                        <Checkbox
                                            checked={student.isSelect}
                                            onChange={(e) => toggleSelectStudent(e, student.uuid)}
                                        />
                                    </div>
                                    <div className={style.select_container} onClick={() => {
                                        setLocalUser(student);
                                        setCurrentUser(student);
                                    }}>
                                        <div className={style.student_photo}>
                                            {
                                                student.picture?.path
                                                    ?
                                                    <img src={student.picture?.path} alt="Student" />
                                                    :
                                                    <div className={style.student_photo_name}>
                                                        {`${student.firstName.charAt(0)}${student.lastName.charAt(0)}`}
                                                    </div>
                                            }
                                        </div>
                                        <div className={style['student_name_wrapper']}>
                                            <div className={style['student_badge_wrapper']}>
                                                {
                                                    individualDeadlines.find(userDeadlines => (
                                                        userDeadlines.userUUID === student.uuid &&
                                                        userDeadlines.material.length
                                                    )) && (
                                                        <div className={cx(style.badge, style.period)}>Срок сдачи</div>
                                                    )
                                                }
                                                {usersWithExtraSession.includes(student.uuid) && (
                                                    <div className={cx(style.badge, style.additional)}>доп. сессия</div>
                                                )}
                                            </div>

                                            <div className={style.student_name}>
                                                {`${student.lastName} ${student.firstName} ${student.patronymic}`}
                                            </div>
                                        </div>
                                    </div>

                                    <TableMenu>
                                        <MenuItem to={`/users/${student.uuid}/user`}>
                                            Редактировать
                                        </MenuItem>
                                        <RoleCheck roles={Role.Methodist}>
                                            <MenuItem onClick={() => toggleUserStatus(student)}>
                                                {student.status === UserStatus.Active ? 'Заблокировать' : 'Разблокировать'}
                                            </MenuItem>
                                        </RoleCheck>
                                        <MenuItem
                                            onClick={() => {
                                                setLocalStudents([student.uuid]);
                                                setShowSendMessageModal(true);
                                            }}
                                        >
                                            Отправить сообщение
                                        </MenuItem>
                                        <RoleCheck roles={Role.Methodist}>
                                            <MenuItem
                                                onClick={() => {
                                                    setShowChangeGroupModal(true);
                                                    setLocalStudents([student.uuid]);
                                                }}
                                            >
                                                Сменить группу
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                setLocalStudents([student.uuid]);
                                                toggleTermsModal('person', student.uuid);
                                            }}>
                                                Сроки сдачи
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => handleSubscribeOnAdditionalDisciplines(student.uuid)}
                                            >
                                                Подписать на доп. дисциплину
                                            </MenuItem>
                                            <MenuItem onClick={() => sendPassword(student)}>Отправить пароль</MenuItem>
                                            {/* <MenuItem danger>Отчислить</MenuItem> */}
                                        </RoleCheck>
                                    </TableMenu>
                                </div>
                            );
                        })
                        :
                        <div className={style.no_students}>Нет пользователей в группе</div>
                }
            </div>
        </div>
    );
};

export default EditGroupSideBar;
