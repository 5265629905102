
import { FC, HTMLAttributes, useEffect, useRef, useState } from 'react';

import style from './InputAutosize.module.scss';

export interface IInputAutosizeProps extends HTMLAttributes<HTMLInputElement> {
    value: string;
    onEnterKey: () => void;
    onEscapeKey: () => void; 
} 

export const InputAutosize:FC<IInputAutosizeProps> = ({
    defaultValue,
    value,
    onEnterKey,
    onEscapeKey,
    ...props
}) => {

    const [width, setWidth] = useState(0);
    const virtualText = useRef<HTMLDivElement>(null);

    // Events
    const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case 'Enter':
                onEnterKey();
                return;
            case 'Escape':
                onEscapeKey();
                return;
        }
    };

    // Effects
    useEffect(() => {
        if (!virtualText.current) return;
        setWidth(virtualText.current.scrollWidth + 10);
    }, [value]);

    return <div className={style.container}>
        <div className={style.virtual} ref={virtualText}>{value}</div>

        <input 
            value={value}
            onKeyDown={onKeydown}
            autoFocus
            autoComplete='false'
            type={'text'} 
            className={style.input}
            style={{ width }}
            {...props} />
    </div>;
};