import React, { FC } from 'react';

import { Column, Grid } from '@components/core/grid';
import Loader from '@components/core/Loader';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useGetVideoBlockByIdQuery } from '@store/api/videosApi';
import { IVideoLectures } from '@views/student/StudentCourse/Materials/type';
import VideoCard from '@views/student/StudentCourse/Materials/VideoCard/VideoCard';

import style from './VideoLectures.module.scss';

const VideoLectures: FC<IVideoLectures> = ({ videosSection }) => {
    const lgDown = useBreakpoint('lg', 'down');
    const mdDown = useBreakpoint('md', 'down');

    const { uuid, name, videoLectures } = videosSection;
    const videoBlock = useGetVideoBlockByIdQuery(`${uuid}`);

    if (videoBlock.isFetching) return <Loader />;

    return (
        <>
            {
                videoBlock.isSuccess ?
                    (
                        videoBlock.data.videoLectures.length ?
                            <div className={style.root}>
                                {!mdDown && (
                                    <Grid gridTemplateColumns={`repeat(${!lgDown ? 4 : 3}, 1fr)`} columnGap={lgDown ? '16px' : '24px'} rowGap={lgDown ? '24px' : '32px'}>
                                        {
                                            videoBlock.data.videoLectures.map(video => (
                                                <Column key={video.uuid} size={1}>
                                                    <VideoCard video={video} />
                                                </Column>
                                            ))
                                        }
                                    </Grid>
                                )}

                                {mdDown && (
                                    <div className={style.container}>
                                        {
                                            videoBlock.data.videoLectures.map(video => (
                                                <VideoCard key={video.uuid} video={video} />
                                            ))
                                        }
                                    </div>
                                )}
                            </div> : null
                    ) : null
            }
        </>
    );
};

export default VideoLectures;
