import { useNavigate } from 'react-router-dom';

import { Column, Grid } from '@components/core/grid';
import IconUI from '@components/core/IconUI';
import { LinkUI } from '@components/core/LinkUI';
import { NewLoader } from '@components/core/NewLoader';
import { DataList, DataListCell } from '@components/DataList';
import { Helmet } from '@components/Helmet';
import { Pagination, usePagination } from '@components/Pagination';
import { dateConvert } from '@helpers/date';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { CheckedTaskForTeacher } from '@store/api/apiTypes';
import { useLazyGetCheckedTasksForTeacherQuery } from '@store/api/gradesApi';
import { useGradesContext } from '@views/teacher/TeacherMain/TeacherMain';

import style from './verified_works.module.scss';

const getEmptyMessage = () => {
    return (
        <div className={style.empty_message}>
            <div>
                <IconUI typeIcon="teacher-tasks-plug" />
            </div>
            <div className={style.empty_message_header}>
                Проверенных работ нет
            </div>
            <div className={style.empty_message_text}>
                Вы не проверили ещё ни одной работы
            </div>
        </div>
    );
};

const VerifiedWorks = () => {
    const { uuidsDiscipline } = useGradesContext();

    const [ getCheckedTasksForTeacher, { isFetching } ] = useLazyGetCheckedTasksForTeacherQuery();

    const lgUp = useBreakpoint('lg', 'up');
    const navigate = useNavigate();

    const { rows, paginationProps } = usePagination<CheckedTaskForTeacher>({
        cb: async (_page: number, limit: number, start: number) => {
            if (!uuidsDiscipline.length) {
                return { rows: [], total: 0 };
            }
            const { data: tasks } = await getCheckedTasksForTeacher({ uuidsDiscipline, limit: limit, offset: start });
            if(tasks){
                return { rows: tasks.tasks, total: tasks.total };
            }else{
                return { rows: [], total: 0 };
            }
        }
    });

    return (
        <>
            <Helmet title="Преподавание – Проверенные работы" />
            {!uuidsDiscipline.length ?
                <div className={style.empty_message}>{getEmptyMessage()}</div>
                : (
                    isFetching ? <NewLoader className={style.loader} /> :
                        <DataList className={style.list_wrapper}>
                            {
                                lgUp && <Grid gridTemplateColumns="50% 30% 11% 5%" className={style.data_list__header}>
                                    <Column>
                                        <DataListCell className={style.column_label}>Дисциплины</DataListCell>
                                    </Column>
                                    <Column>
                                        <DataListCell className={style.column_label}>Студент</DataListCell>
                                    </Column>
                                    <Column>
                                        <DataListCell className={style.column_label}>Дата проверки</DataListCell>
                                    </Column>
                                    <Column className={style.flex_end}>
                                        <DataListCell className={style.column_label}>Оценка</DataListCell>
                                    </Column>
                                </Grid>
                            }
                            {
                                rows.map((task) => {
                                    return (
                                        <div key={Math.random()} className={style.list_item}>
                                            <Grid className={style.list_item__grid}>
                                                <Column>
                                                    <span className={style.list_item__discipline_name}>{task.nameDiscipline}</span>
                                                    <LinkUI
                                                        className={style.list_item__title_task}
                                                        to={`../grades/${task.uuidUser}/${task.uuidTask}`}
                                                        state={{ isFinished: true }}
                                                    >
                                                        {task.nameUser}
                                                    </LinkUI>
                                                </Column>
                                                <Column>
                                                    <Grid className={style.list_item__info_task_grid}>
                                                        <Column>
                                                            <span className={style.list_item__user_name} onClick={() => navigate(`/user/${task.uuidUser}`)}>
                                                                {task.nameUser}
                                                            </span>
                                                        </Column>
                                                        <Column>
                                                            <div className={style.deadline_wrapper}>
                                                                <div>
                                                                    <span className={style.list_item__date}>{dateConvert(
                                                                        task.submittedAt.replace(/ \+0000 UTC/, '')
                                                                        // todo: раньше тут был updatedAt
                                                                    ).toFormat('dd.LL.yyyy, HH:mm')}</span>
                                                                </div>
                                                            </div>
                                                        </Column>
                                                        <Column className={style.flex_end}>
                                                            <span className={style.list_item__grade}>
                                                                {!lgUp && 'Оценка: '}
                                                                {task.grade}
                                                            </span>
                                                        </Column>
                                                    </Grid>
                                                </Column>
                                            </Grid>
                                        </div>
                                    );
                                })
                            }
                        </DataList>

                )
            }
            <Pagination className={style.pagination} {...paginationProps} />
        </>
    );
};

export default VerifiedWorks;
