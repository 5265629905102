import { FC, ReactNode } from 'react';

import cx from 'classnames';
import { useInView } from 'react-intersection-observer';

import style from './drop_menu.module.scss';

export interface IDropMenuProps {
    className?: string;
    classNameWrapper?: string;
    children?: ReactNode;
}

const DropMenu: FC<IDropMenuProps> = ({ className, classNameWrapper, children, ...props }) => {
    const { ref } = useInView({
        threshold: 1,
        triggerOnce: true,
        onChange: (inView, entry) => {
            const target = entry.target as HTMLElement;

            target.style.opacity = '1';
            if (!inView) {
                target.classList.remove(style['wrapper--position-bottom']);
                target.classList.add(style['wrapper--position-top']);
            }
        }
    });

    return (
        <div
            ref={ref}
            className={cx(style.wrapper, style['wrapper--position-bottom'], classNameWrapper)}
            {...props}
        >
            <ul className={cx(style.root, className)} role={'menu'}>
                {children}
            </ul>
        </div>
    );
};

export { DropMenu };
