import React from 'react';

import { MultiValue, SingleValue } from 'react-select';

import { OptionType } from '@components/core/inputs/SelectStyled/type';

import { HierarchyItem } from '../HierarchyItem';
import styles from './HierarсhyContainer.module.scss';

type TProps = {
    selectedOption: MultiValue<OptionType> | SingleValue<OptionType>;
    selectedOptionHandle: (indexElem: number) => void;
};

const HierarchyContainer = ({ selectedOption, selectedOptionHandle }: TProps) => {

    return Array.isArray(selectedOption) && selectedOption.length ? (
        <div className={styles.wrapper}>
            {selectedOption.map((option, idx) => (
                <React.Fragment key={option.label}>
                    <HierarchyItem options={[option.parent, option.label]} onDeleteClick={() => selectedOptionHandle(idx)} />
                    {idx !== selectedOption.length - 1 && <div className={styles.divider} />}
                </React.Fragment>
            ))}
        </div>
    ) : null;
};

export default HierarchyContainer;
