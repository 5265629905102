import React from 'react';

import { ReactComponent as UserIcon } from '@assets/icons/user-icon.svg';
import { useToggle } from '@hooks/useToggle.hook';
import { User } from '@type/users';
import { CommonSidebar } from '@views/student/StudentCourse/Sidebars/CommonSidebar';
import TeachingStaffItem from '@views/student/StudentCourse/TeachingStaffCard/TeachingStaffItem';


interface TeachersSidebarProps {
    metodists: User[];
    teachers: User[];
    secondTeachers: User[];
}

export const TeachersSidebar: React.FC<TeachersSidebarProps> = ({ metodists, teachers, secondTeachers }) => {
    const [isOpen, { on, off }] = useToggle();

    return (
        <CommonSidebar isOpen={isOpen} on={on} off={off} title="Преподаватели и методисты" icon={<UserIcon />}>
            {secondTeachers.map(teacher => (
                <TeachingStaffItem key={teacher.uuid} user={teacher} onClose={off} isDouble />
            ))}
            {teachers.map(teacher => (
                <TeachingStaffItem key={teacher.uuid} user={teacher} onClose={off} />
            ))}
            {metodists.map(metodist => (
                <TeachingStaffItem key={metodist.uuid} user={metodist} onClose={off} />
            ))}
        </CommonSidebar>
    );
};
