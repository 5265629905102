import { FC, MouseEvent, useEffect, useState } from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import { BottomDrawer } from '@components/BottomDrawer';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { useChat } from '@components/Chat/context';
import IconUI from '@components/core/IconUI';
import Paper from '@components/core/styledWrappers/Paper';
import { MarkdownPreview } from '@components/MarkdownPreview';
import { RoleCheck } from '@components/RoleCheck';
import { dateConvert } from '@helpers/date';
import { getPaths } from '@helpers/findNestedPath';
import { findRootCurrentGroup, makeGroupTreeFlat } from '@helpers/groupTree';
import { useBreakpoint } from '@hooks/useBreakpoint';
import Popover from '@mui/material/Popover';
import { GroupTree } from '@store/api/apiTypes';
import { selectCurrentServiceInformation } from '@store/features/chatSlice';
import { selectApiUser } from '@store/features/userSlice';
import { useAppSelector } from '@store/hooks';
import { Role, roleNames } from '@store/Roles';
import { ChatUser } from '@type/chat';

import style from './Contact.module.scss';

export interface IContactProps {
    uuid: string;

    user: ChatUser;
    uuidSender?: string;

    lastTime?: string;
    lastMessage?: string | null;

    regular?: boolean;
    isHeader?: boolean;

    onSelect?: () => void;

    isNew?: boolean;
}

const emptyFunction = () => null;

const Contact: FC<IContactProps> = ({
    uuid,
    user,
    uuidSender,
    lastTime,
    lastMessage,
    regular,
    isHeader = false,
    onSelect,
    isNew
}) => {
    const smDown = useBreakpoint('sm', 'down');

    const navigate = useNavigate();

    const { onClose } = useChat();

    const me = useAppSelector(selectApiUser);
    const isMethodist = me?.role === Role.Methodist;

    const roomServiceInformation = useAppSelector((state) => selectCurrentServiceInformation(state, uuid));

    const renderDate = () => {
        if (lastTime) {
            return dateConvert(lastTime.replace(/ \+0000 UTC/, '')).toFormat('dd.LL.yyyy');
        }
        return null;
    };

    const handleClick = () => {
        onSelect?.();
    };

    const handleClickAvatar = () => {
        if (isMethodist) {
            navigate(`/users/${user.uuid}/user`);
            onClose();
        }
    };

    const splittedUserName = user.fullName.split(' ');

    const [pathTo, setPathTo] = useState<{ uuid: string, name: string }[]>([]);
    const [rootGroupUuid, setRootGroupUuid] = useState<string>('');

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const { groupsData } = useChat();

    useEffect(() => {
        if (groupsData) {
            //Получение текущей группы студента
            const currentGroup = makeGroupTreeFlat(groupsData.childs).find(g => g.UUID === user.uuidGroup);
            // Нахождение рут группы студента
            currentGroup && setRootGroupUuid(findRootCurrentGroup(currentGroup, groupsData).UUID);
        }
    }, [groupsData, user]);

    useEffect(() => {
        if (groupsData) {
            const pathToGroup = getPaths<GroupTree, 'childs', 'UUID'>(
                groupsData.childs,
                'childs',
                'UUID',
                (group) => group.UUID === (user.uuidGroup ?? rootGroupUuid)
            );

            setPathTo(pathToGroup ?? []);
        }
    }, [groupsData, rootGroupUuid, user]);

    const showPopover = (event: MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'header-chat-popover' : undefined;

    return <div className={cx(style.container, isHeader && style['container__header'])} onClick={handleClick}>
        <div className={style.contactAvatar} onClick={handleClickAvatar}>
            <div className={cx(style.avatar, isHeader && style.header, isMethodist && style.clickable)}>
                {
                    user.avatar ? (
                        <img src={user.avatar} alt="avatar" />
                    ) : (
                        `${splittedUserName[0].charAt(0)}${splittedUserName?.[1]?.charAt(0)}`
                    )
                }
            </div>
        </div>

        <div className={cx(style.contactData, isHeader && style['contactData__header'])}>
            <div className={style.content}>
                <div className={style.contactSubTitle}> {roleNames[user.role]} </div>
                <div className={style.time}>
                    {renderDate()}
                </div>
            </div>
            <div className={cx(style.contactTitle, isHeader && style['contactTitle__header'], regular)}>
                { user.fullName }
                <RoleCheck roles={[Role.Methodist, Role.Teacher]}>
                    {isHeader && user.role === Role.Student && (
                        <>
                            <div
                                className={style.icon}
                                onMouseEnter={!smDown ? showPopover : emptyFunction}
                                onMouseLeave={!smDown ? closePopover : emptyFunction}
                                onClick={smDown ? showPopover : emptyFunction}
                            >
                                <IconUI typeIcon={'instruction'} />
                            </div>
                            {!smDown && (
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={closePopover}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }}
                                    transformOrigin={{
                                        vertical: 0,
                                        horizontal: 'center'
                                    }}
                                    sx={{
                                        pointerEvents: 'none'
                                    }}
                                    disableRestoreFocus
                                    classes={{ paper: style['popover__border-radius__with-shadow'] }}
                                >
                                    <Paper className={style.paper}>
                                        <Breadcrumbs noScroll>
                                            {
                                                pathTo.map((group) => <span className={style.breadcrumbs_item} key={group.uuid}>{group.name}</span>)
                                            }
                                        </Breadcrumbs>
                                    </Paper>
                                </Popover>
                            )}
                            {smDown && (
                                <BottomDrawer open={open} onOpen={showPopover} onClose={closePopover}>
                                    <h3 className={style['drawer-title']}>Форма обучения</h3>
                                    <div className={style['breadcrumbs_container']}>
                                        <Breadcrumbs>
                                            {
                                                pathTo.map((group) => <span className={style.breadcrumbs_item} key={group.uuid}>{group.name}</span>)
                                            }
                                        </Breadcrumbs>
                                    </div>
                                </BottomDrawer>
                            )}
                        </>
                    )}
                </RoleCheck>
            </div>

            {!isHeader && (
                <div className={style.footer}>
                    <div className={style.message}>
                        {me?.uuid === uuidSender ? 'Вы: ' : ''}
                        <MarkdownPreview source={lastMessage} className={style.lastMessage} />
                    </div>
                    {(isNew || Boolean(roomServiceInformation?.notificationCount)) && <div className={style.badge} />}
                </div>
            )}
        </div>
    </div>;
};

export default Contact;
