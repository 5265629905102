import { FC, MouseEvent, useEffect, useRef, useState } from 'react';

import classNames from 'classnames/bind';

import { ReactComponent as CrossSVG } from '@assets/icons/cross.svg';
import FilesList from '@components/core/inputs/FileUpload/FileUploadButton/FilesList';
import { FilePlaceholder } from '@components/core/inputs/FileUpload/parts/FilePlaceholder';
import { IPlaceholderBackendData, TUploadFile } from '@components/core/inputs/FileUpload/type';
import { IStorageFile } from '@store/api/apiTypes';

import { acceptAreaTypes } from '../FileUploadArea/validation';
import { FileCaption } from '../parts/FileCaption/FileCaption';
import { FileButtonStage } from './FileButtonStage';
import style from './fileuploadbutton.module.scss';

const cx = classNames.bind(style);

type FileUploadButtonProps = {
    name?: string;
    onChange?: (file: TUploadFile | IStorageFile | TUploadFile[] | IStorageFile[]) => void;
    value?: TUploadFile | IStorageFile | TUploadFile[] | IStorageFile[];
    helperText?: string | null;
    className?: string;
    accessTypes?: string[];
    children?: React.ReactNode;
    isMulti?: boolean;
    caption?: boolean;
    disabled?: boolean;
    withoutCheckControl?: boolean;
    maxSizeFile?: number;
};

export const FileUploadButton: FC<FileUploadButtonProps> = (
    {
        onChange,
        value,
        className,
        accessTypes,
        children,
        isMulti,
        caption = true,
        withoutCheckControl = false,
        disabled,
        maxSizeFile
    }) => {

    const inputRef = useRef<HTMLInputElement>(null);

    const [api, setApi] = useState<IPlaceholderBackendData>({
        file: null,
        files: [],
        stage: 'default',
        isDragOver: false,
        inputRef,
        acceptTypes: accessTypes || acceptAreaTypes
    });

    const [firstUpdate, setFirstUpdate] = useState(true);

    const deleteFile = (indexFile: number | undefined) => {
        if (api?.inputRef?.current) {
            api.inputRef.current.value = '';
            if (isMulti) {
                setApi((prevState) => ({
                    ...prevState,
                    // @ts-ignore
                    files: prevState.files.filter((_, index) => index !== indexFile)
                }));
            } else {
                setApi((prevState) => ({ ...prevState, file: null, stage: 'default' }));
            }
        }
    };

    const props = {
        api,
        isMulti,
        setApi,
        deleteFile,
        disabled,
        maxSizeFile
    };

    // Set value form
    useEffect(() => {
        if (isMulti) {
            onChange && onChange(api?.files);
        } else {
            onChange && onChange(api?.file);
        }

    }, [api?.files, api?.file, onChange]);

    // Default value form
    useEffect(() => {
        if (firstUpdate) {
            if (isMulti && (Array.isArray(value) && value.length)) {
                setApi({ ...api, files: value as TUploadFile[] | IStorageFile[], stage: 'default' });
                setFirstUpdate(false);
            }
            if (!isMulti && value) {
                setApi({ ...api, file: value as TUploadFile | IStorageFile, stage: 'uploading' });
                setFirstUpdate(false);
            }
            if (!isMulti && !value) {
                setApi({ ...api, file: null, stage: 'default' });
                setFirstUpdate(false);
            }
        }
    }, [value]);

    const containerClick = (event: MouseEvent<HTMLDivElement>) => {
        if (disabled) {
            event.preventDefault();
        }
    };

    return (
        <div
            className={cx('root', (api?.file || api?.apiPreview) && 'root--file', className)}
            role="presentation"
            onClick={containerClick}
        >
            <div className={cx('root__container')}>
                <div className={cx('root__wrapper', disabled && 'root__disabled')}>
                    <div className={cx('root__inner')}>
                        <FilePlaceholder {...props}>
                            <FileButtonStage {...props} />
                        </FilePlaceholder>
                    </div>

                    <div
                        className={cx('root__delete')}
                        role="presentation"
                        onClick={() => deleteFile(undefined)}
                    >
                        <CrossSVG />
                    </div>
                </div>

                {
                    caption && <FileCaption type="inline" size="200">{children}</FileCaption>
                }
            </div>
            {
                api?.files?.length ? (
                    <FilesList withoutCheckControl={withoutCheckControl} files={api?.files as IStorageFile[] | TUploadFile[]} deleteFile={deleteFile} />
                ) : null
            }
        </div>
    );
};
