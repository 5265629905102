import { Outlet } from 'react-router-dom';

import MethodistHeader from '@components/methodist/MethodistHeader';
import Sidebar from '@components/methodist/Sidebar';
import Toolbar from '@components/methodist/Sidebar/Toolbar';

import style from './methodist.module.scss';

const MethodistLayout = () => {
    return (
        <div className={style.layout_container}>
            <MethodistHeader />
            <div className={style.main_layout_wrapper}>
                <Sidebar className={style.sidebar}>
                    <Toolbar />
                </Sidebar>
                <Outlet />
            </div>
        </div>
    );
};

export default MethodistLayout;
